import React from 'react';
import ZTextArea from '../../z-text-area';

/**
 * 
 * @TODO Resolve errors
 *  - highlights
 *  - description
 */

const DetailsView = ({
  highlights,
  highlightsErrors,
  onUpdateHighlights,
  description,
  onUpdateDescription,
}) => {
  return (
    <>
      <h2>Details</h2>
      <ZTextArea 
        allowedFormats={['ITALICS', 'LINKS', 'BULLET_POINTS']} 
        label="Trip Highlights" 
        onUpdate={onUpdateHighlights}
        value={highlights}
        inputKey={'details__highlights'}
        error={
          highlightsErrors && !highlightsErrors.valid ?
          highlightsErrors :
          false
        }
      />
      <ZTextArea 
        allowedFormats={['ITALICS', 'LINKS']} 
        label="Detailed Description" 
        onUpdate={onUpdateDescription}
        value={description}
        inputKey={'details__description'}
      />
    </>
  )
}

export default DetailsView;
