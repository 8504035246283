import { validate } from '../validate/validation';
import ZApiClient from './z-api-client';

const landingPageCategoryValidationRequirements = {
  heroImage: {
    required: true,
    errorMessage: 'A hero image is required.',
  },
  title: {
    required: true,
    unique: true,
    errorMessage: 'Title is required and must be unique.'
  },
  subtitle: {
    required: true,
    errorMessage: 'A subtitle is required.'
  },
  summary: {
    required: true,
    errorMessage: 'A summary is required.'
  },
  metatags: {
    required: true,
    // titleTag
    title: {
      required: true,
      errorMessage: 'The metatag title tag is required.'
    },
    // descriptionTag
    description: {
      required: true,
      errorMessage: 'The metatag description tag is required.'
    },
    // canonicalUrl
    canonical: {
      required: true,
      errorMessage: 'A canonical path is required.'
    }
  }
};

class LandingPageCategory {

  _generateDescription = (locations) => {
    locations = locations || [];
    let locationText = locations.length > 0 ? `${locations[0]} ` : '';
    return `Zicasso: ${locationText} travel & tours by top competing travel agents & tour operators.`
  }

  /**
 * @param {object} metatags
 * @param {string} title
 * Checks if meta title exist.
 * If no meta title present use default rules to build meta title
 */
  _mapMetaTags = (metatags, landingPageCategory) => {
    let {
      title: metaTitle,
      canonical,
      description,
    } = metatags;
    const {
      title,
      locations,
    } = landingPageCategory;
    if (!metaTitle) {
      metaTitle = `${title} | Zicasso`;
    }
    if (!description) {
      description = this._generateDescription(locations);
    }
    return {
      ...canonical && { canonical: canonical },
      description,
      title: metaTitle,
    }
  }

  _getMetaTags = (metatags) => {
    return {
      title: '',
      description: '',
      canonical: '',
      ...metatags,
    }
  };

  _getTuid = () => (
    ZApiClient.getTuidsClient().createTuid()
  );

  _mapFooterIdtoSection = (ids, sections) => {
    if (!ids) {
      return [];
    } else {
      return ids.map((ele) => {
        return { ...sections[ele] };
      });
    }
  }

  _mapSectionIdToSections = (ids, sections, photos) => {
    if (!ids) {
      return [];
    } else {
      return ids.map((ele) => {
        const { photoId, ...rest } = sections[ele];
        const section = {
          ...rest,
          ...photoId && { image: photos[photoId] },
        };
        return section;
      });
    }
  }

  _mapPhotos = (photos) => {
    let ret = {};
    if (!photos || !photos[0]) {
      return ret
    };
    for (const photo of photos) {
      if (photo && !!photo.id) ret[photo.id] = { ...photo };
    }
    return ret;
  }

  _mapSectionsToEntities = async (sections = [], footerLinks) => {
    const sectionIds = [];
    const sectionMap = {};
    const sectionPhotos = [];
    for (const section of sections) {
      let { id, image, photoId } = section;
      if (!id) {
        const tuidRes = await this._getTuid();
        id = tuidRes.id;
      }
      if (image) {
        photoId = image.id;
        sectionPhotos.push({ ...image })
      }
      sectionIds.push(id);
      sectionMap[id] = { ...section, id, photoId };
    };
    const {
      idMap: footerIdMap,
      idList: footerIds,
    } = await this._mapFooterSections(footerLinks);
    return {
      sectionIds,
      footerIds,
      sectionMap: { ...sectionMap, ...footerIdMap },
      sectionPhotos,
    };
  };

  _mapFooterSections = async (sections) => {
    const idList = [];
    const idMap = {};
    if (sections) {
      for (const section of sections) {
        let { id, links } = section;
        if (!id) {
          const { id: resId } = await this._getTuid();
          id = resId;
        }
        if (links) {
          const promiseArr = links.map((link) => this._getIdIfNeeded(link));
          links = await Promise.all(promiseArr);
        }
        idList.push(id);
        idMap[id] = {
          ...section,
          id,
          links,
        }
      }
    }
    return {
      idList,
      idMap,
    }
  }

  _getIdIfNeeded = async (section) => {
    let { id } = section;
    if (!id) {
      const { id: resId } = await this._getTuid();
      id = resId;
      return { ...section, id };
    } else {
      return { ...section };
    }
  }

  /**
   *
   * @param {entity} LandingPageCategory
   * Validates an Landing Page for publishing
   */
  validateForPublish(entity) {
    return validate(entity, landingPageCategoryValidationRequirements);
  }

  /**
   * Accepts the redux state for a landingPage category. (crurrently ).
   * landingPage category state includes 2 fields
   * {
   *  editor:{
   *    isFetching: boolean,
   *    isSaving: boolean,
   *    saved: boolean,
   *    [landingPageCategoryId] : {...},
   *  },
   *  photos:{
   *    [photoId] : {...}
   *  },
   *  sections:{
   *    [sectionId] : {}
   *  }
   * }
   * @param {string} landingPageCategoryId
   * @param {object} landingPageCategoryState
   */
  getLandingPageCategory(landingPageCategoryId, state) {
    const currentLandingPageCategory = state.editor[landingPageCategoryId];
    const {
      breadcrumb,
      breadcrumbTitle,
      callToAction,
      headerPhotoId,
      id,
      locations,
      metatags,
      subtitle,
      summary,
      title,
      footerSectionIds,
      sectionIds,
      landingPageSnippets,
    } = currentLandingPageCategory;

    const ret = {
      breadcrumb,
      breadcrumbTitle,
      callToAction,
      ...headerPhotoId && { heroImage: state.photos[headerPhotoId] },
      id,
      locations,
      metatags: this._mapMetaTags(metatags, currentLandingPageCategory),
      subtitle,
      summary: summary,
      title,
      sections: this._mapSectionIdToSections(sectionIds, state.sections, state.photos),
      footerLinks: this._mapFooterIdtoSection(footerSectionIds, state.sections),
      landingPageSnippets,
    }
    return ret;
  }

  /**
   * Accepts an entity from our backend and returns the following:
   * {
   *  id: 'landingPageCategoryId',
   *  [id] : {mapped landingPageCategory},
   *  photos: {
   *    [photoId] : {...},
   *  },
   *  sections: {
   *    [sectionId] : {...},
   *  },
   * }
   * @param {object} landingPageCategory
   */
  async getState(landingPageCategory) {
    const { heroImage, metatags, sections, footerLinks, } = landingPageCategory;
    const {
      sectionIds,
      footerIds,
      sectionMap,
    } = await this._mapSectionsToEntities(sections, footerLinks);
    const retLandingPageCategory = {
      ...landingPageCategory,
      metatags: this._getMetaTags(metatags),
      summary: landingPageCategory.summary,
      ...heroImage && { headerPhotoId: heroImage.id },
      sectionIds,
      footerSectionIds: footerIds,
    };
    return {
      id: landingPageCategory.id,
      [landingPageCategory.id]: retLandingPageCategory,
      photos: this._mapPhotos([heroImage]),
      sections: sectionMap,
    };
  }
}

export default LandingPageCategory;
