import React from 'react';
import { connect } from 'react-redux';
import FooterLinksView from './footer-links-view';
import {reorderFooterSectionsT} from "../../../../state/actions/shared/section";

const mapSectionIdsToSection = (ids, sections) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = sections[ele];
      return section;
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const {guideId:id} = ownProps;
  const guideEntities = state.guides.guide;
  const {sections, editor} = guideEntities;
  const currentGuide = editor[id]
  const { footerSectionIds } = currentGuide;
  return {
    sections: mapSectionIdsToSection(footerSectionIds, sections),
    guideId: id,
    readOnly: state.versions.readOnly
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {guideId:id} = ownProps;
  return {
    onSectionOrderChange: (list) => {
      const sectionIds = list.map(ele=>ele.id);
      dispatch(reorderFooterSectionsT('guide', id, sectionIds));
    }
  }
}

const FooterLinkRedux = (props) => (
  <FooterLinksView {...props}/>
);

export default connect(mapStateToProps, mapDispatchToProps)(FooterLinkRedux);
