import React from 'react';
import ZInput from '../oryx-input/text';
import styles from './oryx-input-search.module.scss';

const ZInputSearchView = ({
  name,
  onChange,
  onClickClear,
  placeholder,
  searchTerm,
}) => (
  <div className={styles.search}>
    <ZInput
      autoComplete="off"
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      value={searchTerm}
    />
    <div
      aria-label="Clear search"
      className={styles['search__clear-button']}
      onClick={onClickClear}
      onKeyUp={(e) => { if (e.nativeEvent.code === 'Enter') onClickClear(); }}
      role="button"
      tabIndex={0}
    />
  </div>
);

export default ZInputSearchView;
