import React, { useContext } from "react";
import { LocationsProvider, LocationsStore } from "./contexts/locations";
import { CompaniesProvider, CompaniesStore } from "./contexts/companies";

const StoreProvider = ({children}) => (
  <LocationsProvider>
    <CompaniesProvider>
      {children}
    </CompaniesProvider>
  </LocationsProvider>
);

export const useLocationsStore = () => useContext(LocationsStore);
export const useCompaniesStore = () => useContext(CompaniesStore);

export default StoreProvider;
