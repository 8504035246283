import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhotoGalleryView from './photo-gallery-view';

const MAX_PHOTOS = 7;
const PHOTO_DISTANCE = 24;

const PhotoGalleryContainer = ({
  images,
}) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const handleClickGallery = (e) => {
    if ((e.code === 'Enter' || e.type === 'click') && e.target.id) {
      setLightboxIndex(parseInt(e.target.id, 10) + 1);
      setShowLightbox(true);
    }
  };

  return (
    <PhotoGalleryView
      images={images}
      lightboxIndex={lightboxIndex}
      maxPhotos={MAX_PHOTOS}
      onClickGallery={handleClickGallery}
      photoDistance={PHOTO_DISTANCE}
      setShowLightbox={setShowLightbox}
      showLightbox={showLightbox}
    />
  );
};
PhotoGalleryContainer.propTypes = {
  images: PropTypes.array.isRequired,
};

export default PhotoGalleryContainer;
