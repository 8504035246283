import React from 'react';
import ZAutoComplete from '../../../z-autocomplete';
import ZTitleFlag from '../../../z-title-flag';
import ZButton from '../../../z-button';

const TitleFlagView = ({
  flagCode,
  autoComplete,
  autoCompleteOnSelect,
  onTitleFlagChange,
}) => (
  <div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '14px',
      }}
    >
      {flagCode && (
        <ZTitleFlag
          isoCode={flagCode}
          style={{
            marginRight: '16px',
            height: '2.5em',
            width: '2.5em',
          }}
        />
      )}
      { flagCode && (
        <ZButton
          btnStyle={{
            width: "90px",
            height: '40px',
            fontSize: '12px',
            margin: '0px',
          }}
          text="Remove Flag"
          onClick={() => onTitleFlagChange(null)}
        />
      )}
    </div>
    <ZAutoComplete
      autoComplete={autoComplete}
      onSelect={autoCompleteOnSelect}
      returnsObject
      label='Add a Flag by Country'
      style={{marginBottom: '14px'}}
    />
  </div>
);

export default TitleFlagView;
