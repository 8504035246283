import { Path } from '@zicasso/zicasso-sdk/lib/path';
import ZApiClient from '../client/z-api-client';

const pathClient = ZApiClient.getPathsClient();

export const generatePath = (prefix, list, suffix) => {
  const title = suffix.replace(/[^a-zA-Z ]/g, "").split(' ').join('-');
  const path = `${prefix}${title}`;
  if (list && list.length === 0) {
    return path;
  } else {
    return '';
  }
}

/**
 * @TODO This need to do more than check for spaces.
 * This should actually make sure a path is part of a valid url
 * Ex: ///4%#$() is a valid path.
 *
 * @param {string} path
 * @param {string} prefix
 * @param {array} list
 */
export const validatePath = (path, prefix, list) => {
    const pathRegex = /^[/.a-zA-Z0-9-]+$/gm;
    path = path.toLowerCase();
    if (path === prefix) return false;
    if (path.length > 2000) return false;
    if (path.indexOf(' ') > -1) return false;
    if(!pathRegex.test(path)) return false;
    const existing = !!list && list.findIndex(ele => ele.alias === path);
    if (existing !== false && existing > -1) {
      return false;
    }

    return true;
}

export const checkForExistingPath = async (path) => {
  if (!path) return;

  const currentEntity = await pathClient.readPaths({
    alias: path
  });

  const resp = currentEntity.length > 0 ?
    {
      message: 'Path already exists. Please enter a unique path.',
      data: currentEntity
    }
    : undefined;
  return resp;
}

export const createPath = (reqBody) => {
  return pathClient.createPath(new Path(reqBody))
    .then((res) => {
      if (res.httpStatus === 401) return res;
      else return getAllPaths(reqBody.entityId);
    })
    .catch(err => console.error(err))
}

// this method is currently only being used to update the canonical path
// TODO: switch uses of this method to setCanonical()
export const updatePaths = (paths, originalPaths) => {

  const canonicalPath = paths.find(p => !p.redirect);
  return setCanonical(canonicalPath.id);

  // Create array of PUT calls of paths that have been updated
  // const putArray = paths
  //   .filter((ele, i) => ele.redirect !== originalPaths[i].redirect)
  //   .map(ele => pathClient.updatePath(new Path(ele)));

  //   return Promise.all(putArray)
  //     .then((res) => {
  //       if (res.httpStatus === 401) return res;
  //       else return getAllPaths(paths[0].entityId);
  //     })
  //     .catch(err => console.error(err))
}

export const setCanonical = (pathId) => {
  return pathClient.setCanonical(pathId)
    .then((res) => {
      if (res.httpStatus === 401) return res;
      else return res.data;
  })
  .catch(err => console.error(err))
}

export const deletePath = (entityId, pathId) => {
  return pathClient.deletePath(pathId)
    .then((res) => {
      if (res.httpStatus === 401 ) return res;
      if (res.httpStatus === 400) {
        throw new Error(res.message);
      }
      else return getAllPaths(entityId)
    })
}

export const getAllPaths = (id) => (
  pathClient.readPaths({
    entity: id,
    count: true
  })
);
