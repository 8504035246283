import { validate } from '../validate/validation';
import ZApiClient from './z-api-client';

export const landingPageValidationRequirements = {
  title: {
    required: true,
    unique: true,
    errorMessage: 'Title is required and must be unique.'
  },
  locations: {
    required: true,
    errorMessage: 'Please add at least one location.'
  },
  summary: {
    required: true,
    errorMessage: 'A summary is required.'
  },
  callToAction: {
    required: true,
    title: {
      required: true,
      errorMessage: 'A CTA Title is required.'
    },
    steps: {
      required: true,
      min: 3,
      errorMessage: 'All 3 CTA Steps are required'
    }
  },
  heroImage: {
    required: true,
    requiredFields: ['uri'],
    errorMessage: 'A hero image is required.'
  },
  mobileHeroImage: {
    required: true,
    requiredFields: ['uri'],
    errorMessage: 'A mobile hero image is required.'
  },
  itineraries: {
    required: true,
    min: 1,
    errorMessage: 'A minimum of 3 itineraries are required.'
  },
  content: false,
  footerLinks: false,
  paths: {
      required: true,
      min: 1,
      errorMessage: 'At least 1 path is required.'
  },
  metatags: {
      required: true,
      // titleTag
      title: {
          required: true,
          errorMessage: 'The metatag title tag is required.'
      },
      // descriptionTag
      description: {
          required: true,
          errorMessage: 'The metatag description tag is required.'
      },
      // canonicalUrl
      canonical: {
          required: true,
          errorMessage: 'A canonical path is required.'
      }
  }
};

class LandingPage {
  _getTuid  = () => (
    ZApiClient.getTuidsClient().createTuid()
  );

  _mapFooterIdtoSection = (ids, sections) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        return {...sections[ele]} ;
      });
    }
  }

  _mapSectionIdToSections = (ids, sections, photos) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        const { photoId, ...rest } = sections[ele];
        const section = {
          ...rest,
          ...photoId && { image: photos[photoId] },
        };
        return section;
      });
    }
  }

  _mapPhotos = (photos) => {
    let ret = {};
    if(!photos || !photos[0]){
      return ret
    };
    for(const photo of photos){
      if (photo && !!photo.id) ret[photo.id] = {...photo};
    }
    return ret;
  }

  _mapPhotoIdToPhoto = (id, photos) => {
    if(!id){
      return null;
    } else {
      return photos[id];
    }
  }

  _mapSectionsToEntities = async (sections = [], footerLinks) => {
    const sectionIds = [];
    const sectionMap = {};
    const sectionPhotos = [];
    for(const section of sections){
      let {id, image, photoId} = section;
      if(!id){
        const tuidRes = await this._getTuid();
        id = tuidRes.id;
      }
      if(image){
        photoId = image.id;
        sectionPhotos.push({...image})
      }
      sectionIds.push(id);
      sectionMap[id] = {...section, id, photoId};
    };
    const{
      idMap:footerIdMap,
      idList:footerIds,
    } = await this._mapFooterSections(footerLinks);
    return {
      sectionIds,
      footerIds,
      sectionMap : {...sectionMap, ...footerIdMap},
      sectionPhotos,
    };
  };

  _mapFooterSections = async (sections) => {
    const idList = [];
    const idMap = {};
    if(sections){
      for(const section of sections){
        let {id, links} = section;
        if(!id){
          const {id:resId} = await this._getTuid();
          id = resId;
        }
        if(links){
          const promiseArr = links.map((link)=> this._getIdIfNeeded(link));
          links = await Promise.all(promiseArr);
        }
        idList.push(id);
        idMap[id] = {
          ...section,
          id,
          links,
        }
      }
    }
    return {
      idList,
      idMap,
    }
  }

  _getIdIfNeeded = async (section) => {
    let {id} = section;
    if(!id) {
      const {id:resId} = await this._getTuid();
      id = resId;
      return {...section, id};
    } else {
      return {...section};
    }
  }

  _generateDescription = (locations) => {
    locations = locations || [];
    let locationText = locations.length > 0 ? `${locations[0]} ` : '';
    return `Zicasso: ${locationText} travel & tours by top competing travel agents & tour operators.`
  }

  /**
   * @param {object} metatags
   * @param {string} title
   * Checks if meta title exist.
   * If no meta title present use default rules to build meta title
   */
  _mapMetaTags = (metatags, article) => {
    let {
      title:metaTitle,
      canonical,
      description,
    } = metatags;
    const {
      title,
      locations,
    } = article;
    if(!metaTitle){
      metaTitle = `${title} | Zicasso`;
    }
    if(!description){
      description = this._generateDescription(locations);
    }
    return {
      ...canonical && {canonical: canonical},
      description,
      title: metaTitle,
    }
  }

  _getMetaTags = (metatags) => {
    return {
      title: '',
      description: '',
      canonical: '',
      ...metatags,
    }
  };

  /**
   * This function is for legacy support of the existing LCC component.
   * @param {object} content
   */
  _mapLCC = (content) => {
    const ret = {
      title: content ? content.title : '',
      sections: content ? content.sections : [],
    };
    return ret;
  }

  _getCompanyLinkFromNavBarLinks = (arr = []) => {
    const res = arr.find((e) => e.entityType === "CompanyCategory");
    return res || {};
  }

  /**
   *
   * @param {entity} LandingPage
   * Validates an Landing Page for publishing
   */
  validateForPublish (entity){
    return validate(entity, landingPageValidationRequirements);
  }

  /**
   * Accepts the redux state for an article. (crurrently articles.article).
   * article state includes 3 fields
   * {
   *  editor:{
   *    isFetching: boolean,
   *    isSaving: boolean,
   *    saved: boolean,
   *    [articleId] : {...},
   *  },
   *  photos:{
   *    [photoId] : {...}
   *  },
   *  sections:{
   *    [sectionId] : {}
   *  }
   * }
   * @param {string} articleId
   * @param {object} articleState
   */
  getLandingPage(entityId, state){
    const currentLandingPage = state.editor[entityId];
    const {
      id,
      callToAction,
      title,
      summary,
      sectionIds,
      headerPhotoId,
      mobileHeaderPhotoId,
      locations,
      metatags,
      footerSectionIds,
      breadcrumb,
      breadcrumbTitle,
      content,
      itineraries,
      navigationBar,
      helpfulContent,
      faq,
      flagCountryCode,
      headerLinks,
      galleryLinks,
      agentList,
    } = currentLandingPage;

    const useHeaderLinks = headerLinks?.links && headerLinks?.links?.length

    if (helpfulContent && !helpfulContent.title) {
      helpfulContent.title = 'Featured Articles';
    }

    if (faq && !faq.title) {
      faq.title = 'FAQ Section';
    }

    const ret = {
      id,
      callToAction,
      title,
      summary,
      locations,
      breadcrumb,
      content,
      itineraries,
      breadcrumbTitle,
      navigationBar,
      helpfulContent,
      faq,
      ...flagCountryCode && {
        flagCountryCode
      },
      ...headerPhotoId && {
        heroImage: this._mapPhotoIdToPhoto(headerPhotoId, state.photos)
      },
      ...mobileHeaderPhotoId && {
        mobileHeroImage: this._mapPhotoIdToPhoto(mobileHeaderPhotoId, state.photos)
      },
      ...useHeaderLinks && {
        headerLinks: headerLinks
      },
      sections: this._mapSectionIdToSections(sectionIds, state.sections, state.photos),
      metatags: this._mapMetaTags(metatags, currentLandingPage),
      footerLinks: this._mapFooterIdtoSection(footerSectionIds, state.sections),
      ...galleryLinks && {
        galleryLinks: {
          entityType: galleryLinks.entityType,
          title: galleryLinks.title,
          links: galleryLinks.links.map((link) => ({
            ...link,
            image: this._mapPhotoIdToPhoto(link.image.id, state.photos),
          })),
        },
      },
    }

    // Updates agentList to use the company category in the subnav
    if (agentList)  {
      ret.agentList = agentList;
      const updatedAgentList = {...agentList};
      const updatedAgentListlink = {...agentList.links[0]};
      // if no navbar remove agent list
      if(!navigationBar){
        ret.agentList = undefined;
      }
      // If navbar exist check to see if agent list is using the correct company category
      if(navigationBar){
        const companyLink = this._getCompanyLinkFromNavBarLinks(navigationBar.links);
        // If the agentList is using the incorrect company category update it
        if(companyLink.entityId && updatedAgentListlink.entityId !== companyLink.entityId){
          updatedAgentListlink.entityId = companyLink.entityId;
          updatedAgentListlink.uri = companyLink.uri;
          updatedAgentList.links[0] = updatedAgentListlink;
          ret.agentList = updatedAgentList;
        }
        // If the navbar has no company category remove the agent list
        if (!companyLink.entityId) {
          ret.agentList = undefined;
        }
      }
    }

    return ret;
  }

  /**
   * Accepts an entity from our backend and returns the following:
   * {
   *  id: 'landingPageId',
   *  [id] : {mapped landing page},
   *  photos: {
   *    [photoId] : {...},
   *  },
   *  sections: {
   *    [sectionId] : {...},
   *  },
   * }
   * @param {object} landingPage
   */
  async getState(landingPage){
    const {
      sections,
      heroImage,
      mobileHeroImage,
      metatags,
      footerLinks,
      content,
      galleryLinks,
    } = landingPage;
    const {
      sectionIds,
      footerIds,
      sectionMap,
      sectionPhotos
    } = await this._mapSectionsToEntities(sections, footerLinks);
    const retLandingPage = {
      ...landingPage,
      metatags : this._getMetaTags(metatags),
      content : this._mapLCC(content),
      ...heroImage && { headerPhotoId : heroImage.id},
      ...mobileHeroImage && { mobileHeaderPhotoId : mobileHeroImage.id },
      sectionIds,
      footerSectionIds: footerIds,
    };
    const galleryLinkImages = galleryLinks?.links?.length > 0 ?
    galleryLinks.links.map((link) => link.image)
    : [];

    return {
      id: landingPage.id,
      [landingPage.id] : retLandingPage,
      photos : this._mapPhotos([...sectionPhotos, heroImage, mobileHeroImage, ...galleryLinkImages]),
      sections : sectionMap,
    };
  }
}

export default LandingPage;
