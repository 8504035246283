import React from 'react';
import { connect } from 'react-redux';
import CreateContainer from './create-container';
import { createNewArticle } from '../../state/actions/article-editor';
import { createNewCompanyCategory } from '../../state/actions/company-category-editor';
import { createNewLandingPageCategory } from '../../state/actions/landing-page-category-editor';
import { createNewGuide } from '../../state/actions/guide-editor';
import { createNewPage } from '../../state/actions/simple-editor';
import { createNewItinerary } from '../../state/actions/itinerary-editor';
import { createNewLandingPage } from '../../state/actions/landing-page-editor';

const CreateRedux = (props) => (
  <CreateContainer {...props}/>
)

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const match = {...ownProps.match}
  const params = {...match.params};
  const { type } = params;
  let onLoadEvent = null;
  switch (type) {
    case 'article': onLoadEvent = createNewArticle; break;
    case 'companyCategory': onLoadEvent = createNewCompanyCategory; break;
    case 'landingPageCategory': onLoadEvent = createNewLandingPageCategory; break;
    case 'guide': onLoadEvent = createNewGuide; break;
    case 'itinerary': onLoadEvent = createNewItinerary; break;
    case 'landingPage':
    case 'landing_page':
      onLoadEvent = createNewLandingPage;
      break;
    case 'simplePage':
    case 'content':
      onLoadEvent = createNewPage;
      break;
    default: onLoadEvent = () => {}; break;
  }
  return {
    onLoad: () => dispatch(onLoadEvent()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRedux);
