import config from '../../config';
import LocalStorageService from '../local-storage-service';
import { ApiClient } from '@zicasso/zicasso-sdk/lib/client';
import { Articles } from '@zicasso/zicasso-sdk/lib/article';
import { Careers } from '@zicasso/zicasso-sdk/lib/career';
import { Companies, CompanyCategories, CompanySearches } from '@zicasso/zicasso-sdk/lib/company';
import { Contents } from '@zicasso/zicasso-sdk/lib/content';
import { Guides } from '@zicasso/zicasso-sdk/lib/guide';
import { Images } from '@zicasso/zicasso-sdk/lib/image';
import { Itineraries } from '@zicasso/zicasso-sdk/lib/itinerary';
import { LandingPages, LandingPageCategories } from '@zicasso/zicasso-sdk/lib/landing';
import { Locations } from '@zicasso/zicasso-sdk/lib/location';
import { Locations as GeoLocations } from '@zicasso/zicasso-sdk/lib/geoname';
import { NewsSnippets, NewsSources } from '@zicasso/zicasso-sdk/lib/news';
import { Paths } from '@zicasso/zicasso-sdk/lib/path';
import { Publications } from '@zicasso/zicasso-sdk/lib/publication';
import { UsageReferences } from '@zicasso/zicasso-sdk/lib/reference';
import { Tokens, Users } from '@zicasso/zicasso-sdk/lib/user';
import { TripRequests } from '@zicasso/zicasso-sdk/lib/tripRequest';
import { Tuids } from '@zicasso/zicasso-sdk/lib/tuid';
import { ReviewSearches } from '@zicasso/zicasso-sdk/lib/review';
import { PublishableEntitySearches, PublishableEntities } from '@zicasso/zicasso-sdk/lib/publishableEntity';


class ZApiClient {
  static instance = null;
  static articlesClient = null;
  static careersClient = null;
  static companiesClient = null;
  static companyCategoriesClient = null;
  static companySearchClient = null;
  static LandingPageCategories = null;
  static contentsClient = null;
  static guidesClient = null;
  static imagesClient = null;
  static itinerariesClient = null;
  static landingPagesClient = null;
  static locationsClient = null;
  static newsSnippetsClient = null;
  static newsSourcesClient = null;
  static pathsClient = null;
  static publicationsClient = null;
  static referencesClient = null;
  static tokensClient = null;
  static tripRequestsClient = null;
  static tuidsClient = null;
  static usersClient = null;
  static geoNamesClient = null;
  static reviewSearchesClient = null;
  static publishableEntitySearchesClient = null;
  static publishableEntitiesClient = null;

  static populate() {
    const LocalStorage = new LocalStorageService();
    const userToken = LocalStorage.getFromLocalStorage('accessToken');
    const apiUrl = config.SDK_URL;

    ZApiClient.instance = new ApiClient(apiUrl, userToken);
    ZApiClient.articlesClient = new Articles(ZApiClient.instance);
    ZApiClient.careersClient = new Careers(ZApiClient.instance);
    ZApiClient.companiesClient = new Companies(ZApiClient.instance);
    ZApiClient.companyCategoriesClient = new CompanyCategories(ZApiClient.instance);
    ZApiClient.landingPageCategoriesClient = new LandingPageCategories(ZApiClient.instance);
    ZApiClient.companySearchClient = new CompanySearches(ZApiClient.instance);
    ZApiClient.contentsClient = new Contents(ZApiClient.instance);
    ZApiClient.guidesClient = new Guides(ZApiClient.instance);
    ZApiClient.imagesClient = new Images(ZApiClient.instance);
    ZApiClient.itinerariesClient = new Itineraries(ZApiClient.instance);
    ZApiClient.landingPagesClient = new LandingPages(ZApiClient.instance);
    ZApiClient.locationsClient = new Locations(ZApiClient.instance);
    ZApiClient.newsSnippetsClient = new NewsSnippets(ZApiClient.instance);
    ZApiClient.newsSourcesClient = new NewsSources(ZApiClient.instance);
    ZApiClient.pathsClient = new Paths(ZApiClient.instance);
    ZApiClient.publicationsClient = new Publications(ZApiClient.instance);
    ZApiClient.referencesClient = new UsageReferences(ZApiClient.instance);
    ZApiClient.tokensClient = new Tokens(ZApiClient.instance);
    ZApiClient.tripRequestsClient = new TripRequests(ZApiClient.instance);
    ZApiClient.tuidsClient = new Tuids(ZApiClient.instance);
    ZApiClient.usersClient = new Users(ZApiClient.instance);
    ZApiClient.geoNamesClient = new GeoLocations(ZApiClient.instance);
    ZApiClient.reviewSearchesClient = new ReviewSearches(ZApiClient.instance);
    ZApiClient.publishableEntitySearchesClient = new PublishableEntitySearches(ZApiClient.instance);
    ZApiClient.publishableEntitiesClient = new PublishableEntities(ZApiClient.instance);
  }

  static getApiClientToken() {
    if (!ZApiClient.instance) { this.populate(); }
    return this.instance.getToken();
  }

  static setApiClientToken(token) {
    if (!ZApiClient.instance) { this.populate(); }
    return this.instance.setToken(token);
  }

  static getClient() {
    if (!ZApiClient.instance) { this.populate(); }
    return this.instance;
  }

  static getArticlesClient() {
    if (!ZApiClient.articlesClient) { this.populate(); }
    return this.articlesClient;
  }

  static getCompaniesClient() {
    if (!ZApiClient.companiesClient) { this.populate(); }
    return this.companiesClient;
  }

  static getCompanyCategoriesClient() {
    if (!ZApiClient.companyCategoriesClient) { this.populate(); }
    return this.companyCategoriesClient;
  }

  static getCompanySearchClinet() {
    if (!ZApiClient.companySearchClient) { this.populate(); }
    return this.companySearchClient;
  }

  static getLandingPageCategoriesClient() {
    if (!ZApiClient.landingPageCategoriesClient) { this.populate(); }
    return this.landingPageCategoriesClient;
  }

  static getContentsClient() {
    if (!ZApiClient.contentsClient) { this.populate(); }
    return this.contentsClient;
  }

  static getGuidesClient() {
    if (!ZApiClient.guidesClient) { this.populate(); }
    return this.guidesClient;
  }

  static getImagesClient() {
    if (!ZApiClient.imagesClient) { this.populate(); }
    return this.imagesClient;
  }

  static getItinerariesClient() {
    if (!ZApiClient.itinerariesClient) { this.populate(); }
    return this.itinerariesClient;
  }

  static getLandingPagesClient() {
    if (!ZApiClient.landingPagesClient) { this.populate(); }
    return this.landingPagesClient;
  }

  static getLocationsClient() {
    if (!ZApiClient.locationsClient) { this.populate(); }
    return this.locationsClient;
  }

  static getNewsSnippetsClient() {
    if (!ZApiClient.newsSnippetsClient) { this.populate(); }
    return this.newsSnippetsClient;
  }

  static getNewsSourcesClient() {
    if (!ZApiClient.newsSourcesClient) { this.populate(); }
    return this.newsSourcesClient;
  }

  static getPathsClient() {
    if (!ZApiClient.pathsClient) { this.populate(); }
    return this.pathsClient;
  }

  static getPublicationsClient() {
    if (!ZApiClient.publicationsClient) { this.populate(); }
    return this.publicationsClient;
  }

  static getReferencesClient() {
    if (!ZApiClient.referencesClient) { this.populate(); }
    return this.referencesClient;
  }

  static getTokensClient() {
    if (!ZApiClient.tokensClient) { this.populate(); }
    return this.tokensClient;
  }

  static getTripRequestsClient() {
    if (!ZApiClient.tripRequestsClient) { this.populate() }
    return this.tripRequestsClient;
  }

  static getTuidsClient() {
    if (!ZApiClient.tuidsClient) { this.populate(); }
    return this.tuidsClient;
  }

  static getUsersClient() {
    if (!ZApiClient.usersClient) { this.populate(); }
    return this.usersClient;
  }

  static getGeoNamesClient() {
    if (!ZApiClient.geoNamesClient) { this.populate(); }
    return this.geoNamesClient;
  }

  static getCareersClient() {
    if (!ZApiClient.careersClient) { this.populate(); }
    return this.careersClient;
  }
  
  static getReviewSearchesClient() {
    if (!ZApiClient.reviewSearchesClient) { this.populate(); }
    return this.reviewSearchesClient;
  }

  static getPublishableEntitySearchesClient() {
    if (!ZApiClient.publishableEntitySearchesClient) { this.populate(); }
    return this.publishableEntitySearchesClient;
  }

  static getPublishableEntitiesClient() {
    if (!ZApiClient.publishableEntitiesClient) { this.populate(); }
    return this.publishableEntitiesClient;
  }

  static getClientFromType(entityType) {
    switch (entityType) {
      case 'Article':
      case 'Articles':
      case 'article':
      case 'articles':
      case 'ArticleSnippet':
        return ZApiClient.getArticlesClient();
      case 'Content':
      case 'Contents':
      case 'content':
      case 'simplePages':
      case 'simplePage':
        return ZApiClient.getContentsClient();
      case 'Guide':
      case 'Guides':
      case 'guide':
      case 'guides':
      case 'GuideSnippet':
        return ZApiClient.getGuidesClient();
      case 'LandingPage':
      case 'LandingPages':
      case 'landing_page':
      case 'landing_pages':
      case 'landingPage':
      case 'landingPages':
      case 'LandingPageSnippet':
        return ZApiClient.getLandingPagesClient();
      case 'Itinerary':
      case 'Itineraries':
      case 'itinerary':
      case 'itineraries':
      case 'ItinerarySnippet':
        return ZApiClient.getItinerariesClient();
      case 'newsSnippets':
        return ZApiClient.getNewsSnippetsClient();
      case 'CompanyCategory':
      case 'CompanyCategories':
      case 'company_category':
      case 'company_categories':
      case 'companyCategory':
      case 'companyCategories':
        return ZApiClient.getCompanyCategoriesClient();
      case 'LandingPageCategory':
      case 'LandingPageCategories':
      case 'landing_page_category':
      case 'landing_page_categories':
      case 'landingPageCategory':
      case 'landingPageCategories':
        return ZApiClient.getLandingPageCategoriesClient();
      default: return;
    };
  }
}

export default ZApiClient;
