import React from 'react';
import style from './filters.module.scss';

const FilterView = ({
  logo,
  selected,
  onClickNewsSource,
}) => (
  <div
    aria-selected={selected}
    className={style['filter']}
    onClick={onClickNewsSource}
  >
    <img
      alt={logo.alt}
      src={logo.uri}
    />
  </div>
);

export default FilterView;
