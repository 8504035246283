import React, { useState, useRef, useEffect, useCallback } from 'react';
import FooterSearchView from './footer-search-view';
import ZApiClient from '../../../../utils/client/z-api-client';
import {fetchTuids} from "../../../../utils/fetch/tuid";

const entityMap = {
  LTL: 'landing_page',
  ARTICLE: 'article',
  TRAVEL_GUIDE: 'guide',
}

const AutoHideDuration = 3000;

const FooterSearchContainer = ({
  onFooterSearch,
}) => {
  const [entityType, setEntityType] = useState();
  const [titleMap, setTitleMap] = useState();
  const [errorText, setErrorText] = useState();
  const latestEntity = useRef(entityType);
  const latestTitleMap = useRef(titleMap);
  const autoHideError = useRef();

  useEffect(() => {
    latestEntity.current = entityType;
  }, [entityType]);

  useEffect(()=>{
    latestTitleMap.current = titleMap;
  }, [titleMap]);


  const setHideTimer = useCallback(() => {
    if(!errorText){
      return null;
    };
    clearTimeout(autoHideError.current);
    autoHideError.current = setTimeout(()=>{
      setErrorText();
    },AutoHideDuration);

  }, [errorText]);

  useEffect(()=>{
    if(errorText){
      setHideTimer();
    }
    return () =>{
      clearTimeout(autoHideError.current);
    }
  }, [errorText, setHideTimer]);

  const onRadioChange = (e) => {
    const current = e.currentTarget.value;
    const currentEntity = entityMap[current]
    setEntityType(currentEntity);
  }

  const onSearch = (text) => {
    const searchText = text.toLowerCase();
    const currentEntity = latestEntity.current;
    if ( !!currentEntity ) {
      return ZApiClient
      .getClientFromType(currentEntity)
      .getAllTitlesWithIdsByNameFragment(searchText)
      .then((res)=> {
        setTitleMap(res);
        const ret = [];
        for(const key in res){
          ret.push(res[key]);
        }
        return ret;
      });
    } else {
      return Promise.resolve([]);
    }
  }

  const onSelect = (option) => {
    if(!!option){
      const map = latestTitleMap.current;
      const currentEntity = latestEntity.current;
      let id;
      for (let [key, value] of Object.entries(map)) {
        if(value === option) id = key;
      }
      ZApiClient
      .getClientFromType(currentEntity)
      .getFooterLinks(id)
      .then(async (res) => {
        if (res.length < 1) {
          setErrorText('No Footer Links Found for Selected Page');
          return;
        }
        // Fetch an array of IDs, based on the number of footer sections returned
        const newIds = (await fetchTuids(res.length)).map(tuid => tuid.id)
        // Create a new array of sections with updated ID values, based on the tuid IDs fetched
        const updatedRes = res.map((section, index) => ({ ...section, id: newIds[index] }));
        // Call the onFooterSearch function with the updated array of sections
        onFooterSearch(updatedRes);
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }
  


  return (
    <FooterSearchView 
      entityType={entityType}
      onRadioChange={onRadioChange}
      onSearch={onSearch}
      onSelect={onSelect}
      errorText={errorText}
    />
  );
}

export default FooterSearchContainer;
