import React from 'react';
import Metatags from './meta-tags';
import Paths from '../../shared/components/paths';
import FooterLinks from './footer-links';
import FooterSearch from './footer-search';
import Breadcrumbs from './breadcrumbs';
import Countries from './countries';
import ZAddButton from '../../z-button-add';

const OptimizationView = ({
  id,
  onCreateSection,
  onFooterSearch,
  scrollTargetEle,
  onTopSectionClick,
  title,
}) => (
  <>
    <Paths
      entityId={id}
      entityTitle={title}
      entityType="Guide"
      pathPrefix="g/"
    />
    <br/>
    <Metatags
      entityId={id}
    />
    <br/>
    <Countries
      entityId={id}
    />
    <br/>
    <Breadcrumbs
      guideId={id}
    />
    <br/>
    <h3>SEO Footer Links</h3>
    <FooterSearch
      onFooterSearch={onFooterSearch}
    />
    <br/>
    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
      <ZAddButton onClick={onTopSectionClick}>Add Section</ZAddButton>
    </div>
    <br/>
    <FooterLinks
      guideId={id}
    />
    <div ref={scrollTargetEle}/>
    <br/>
    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
      <ZAddButton onClick={onCreateSection}>Add Section</ZAddButton>
    </div>
  </>

);

export default OptimizationView;
