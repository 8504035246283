import React from 'react';
import { connect } from 'react-redux';
import HubPageSectionsContainer from './hub-page-sections-container';
import {
  setLandingPageSnippet
} from '../../../state/actions/landing-page-category-editor';

const HubPageSectionsRedux = (props) => {
  return (
    <HubPageSectionsContainer {...props}/>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const {editor} = state.landingPageCategories;
  const currentEntity = editor[id] || {};
  const snippets = currentEntity.landingPageSnippets || [];
  return {
    snippets,
    errors: {},
    readOnly: state.versions.readOnly,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    updateSnippets : (landingPages) => dispatch(setLandingPageSnippet(landingPages, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HubPageSectionsRedux);
