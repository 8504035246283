import React from 'react';
import SampleSnippet from './sample-snippet';
import ZAutoComplete from '../../z-autocomplete';
import ZDraggableGrid from '../../z-draggable-grid.js/index.js';

const HubPageSectionsView = ({
  snippets,
  autoComplete,
  autoCompleteOnSelect,
  errors,
  onChangePosition,
  onClickEdit,
  onClickPreview,
  onDragEnd,
  onUpdate,
  readOnly,
  removeSnippet,
  addDay,
}) => {
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Hub Page Section</h2>
      </div>
      <ZAutoComplete
        autoComplete={autoComplete}
        onSelect={autoCompleteOnSelect}
        returnsObject={true}
        label='Add a Landing Page by Name'
        style={{marginBottom: '14px'}}
        error={errors.landingPage ? errors.landingPage : null}
      />
      {snippets && snippets.length !== 0 &&
        <ZDraggableGrid onDragEnd={onDragEnd} disabled={readOnly}>
          {snippets.length > 0 && snippets.map((ele, i) => (
            <SampleSnippet 
              key={`${ele.id}-${i}`}
              snippet={{...ele}} 
              snippetLength={snippets.length}
              index={i} 
              onUpdate={onUpdate}
              onChangePosition={onChangePosition}
              inputKey={`${ele.id}-${i}`}
              addDay={addDay}
              removeSnippet={removeSnippet}
              onClickPreview={() => onClickPreview(ele.id, ele.entityType)}
              onClickEdit={() => onClickEdit(ele.id, ele.entityType)}
            />
          ))}
        </ZDraggableGrid>
      }
    </>
  )
}

export default HubPageSectionsView;
