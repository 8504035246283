import React from 'react';
import ZAutoComplete from '../../../z-autocomplete';
import ZChips from '../../../z-chips';

const LocationsView = ({
  locationAutoComplete,
  locations,
  onLocationDelete,
  onLocationSelect,
}) => (
  <div
    style={{
      borderBottom: '2px solid rgba(255, 145, 0,0.5)',
      marginBottom: '14px',
      width: '45%',
      }}>
    <h3>Locations</h3>
    <ZAutoComplete
      autoComplete={locationAutoComplete}
      onSelect={onLocationSelect}
      label='Add a Destination Country'
      style={{marginBottom: '14px'}}
      inputKey={'overview__countries'}
    />
    {locations && locations.length !== 0 &&
      <ZChips
        data={locations.map((ele)=>{return {label: ele}})}
        style={{marginBottom: '14px'}}
        onChange={onLocationDelete}
      />
    }
  </div>
);

export default LocationsView;
