import React from 'react';
import { connect } from 'react-redux';
import HelpfulContentContainer from './helpful-content-container';
import {
  setHCSSnippets,
  setHCSTitle,
} from '../../../state/actions/landing-page-editor';

const HelpfulContentRedux = (props) => {
  return (
    <HelpfulContentContainer {...props}/>
  )
}

const getLinks = (links) => {
  if(!links) return [{},{},{},{}];
  if(links && links.length === 4) return links;
  const ret = [];
  for(let i = 0, max = 4; i < max; i++){
    const current = links[i] ? links[i] : {}
    ret.push(current);
  }
  return ret;
}

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const {editor} = state.landingPages.landingPage;
  const currentEntity = editor[id] || {};
  const currentSubnav = currentEntity.navigationBar || {};
  const helpfulContent = currentEntity.helpfulContent || {};
  const snippets = helpfulContent.links || [];
  return {
    snippets,
    snippetCount: snippets.length || 0,
    contentSectionTitle: helpfulContent.title || '',
    errors: {},
    readOnly: state.versions.readOnly,
    subnavLinks: getLinks(currentSubnav.links),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    updateSnippets : (articles) => dispatch(setHCSSnippets(id, articles)),
    updateSnippetSectionTitle : (title) => dispatch(setHCSTitle(id, title)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpfulContentRedux);
