import React from 'react';
import PropTypes from 'prop-types';
import { Image, Transformation } from 'cloudinary-react';
const ZImageView = ({
  alt,
  className,
  cloudinaryTransformProps,
  cloudinarySrc,
  height,
  id,
  inputKey,
  loading,
  onClick,
  onError,
  onLoad,
  src,
  style,
  title,
  useCloudinary,
  useGatsby,
  width,
}) => (
  <>
    { (useCloudinary && !useGatsby) && (
      <Image
        alt={alt}
        className={className}
        cloudName="zicasso"
        id={id}
        key={inputKey}
        loading={loading}
        onLoad={onLoad}
        onError={onError}
        onClick={onClick}
        publicId={cloudinarySrc}
        responsive={width === 'auto'}
        secure="true"
        style={style}
        title={title}
        type="fetch"
      >
        <Transformation
          aspectRatio={cloudinaryTransformProps.aspectRatio}
          crop={cloudinaryTransformProps.crop}
          effect={cloudinaryTransformProps.effect}
          gravity={cloudinaryTransformProps.gravity}
          height={height}
          fetchFormat="auto"
          quality={cloudinaryTransformProps.quality || 'auto'}
          width={width}
        />
      </Image>
    )}
    { (!useCloudinary && !useGatsby) && (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <img
        alt={alt}
        className={className}
        height={height}
        id={id}
        key={inputKey}
        loading={loading}
        onClick={onClick}
        onError={onError}
        onKeyUp={(e) => { if (e.code === 13) onClick(); }}
        onLoad={onLoad}
        role={onClick ? 'button' : null}
        src={src}
        style={style}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={onClick ? 0 : -1}
        title={title}
        width={width}
      />
    )}
  </>
);
ZImageView.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  cloudinaryTransformProps: PropTypes.shape({
    aspectRatio: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    crop: PropTypes.string,
    effect: PropTypes.string,
    gravity: PropTypes.string,
    quality: PropTypes.string,
  }),
  cloudinarySrc: PropTypes.string,
  gatsbyImage: PropTypes.object,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string,
  inputKey: PropTypes.string,
  loading: PropTypes.string,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  onStartLoad: PropTypes.func,
  quality: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  useCloudinary: PropTypes.bool,
  useGatsby: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
ZImageView.defaultProps = {
  alt: null,
  className: null,
  cloudinaryTransformProps: null,
  cloudinarySrc: null,
  gatsbyImage: null,
  height: null,
  id: null,
  inputKey: null,
  loading: null,
  onClick: null,
  onError: null,
  onLoad: null,
  onStartLoad: () => {},
  quality: null,
  src: null,
  style: null,
  title: null,
  useCloudinary: null,
  useGatsby: null,
  width: null,
};

export default ZImageView;
