import React, { useRef } from 'react';
import ContentView from './content-view';

const ContentContainer = ({
  onSummaryChange,
  onAddSectionClick,
  onRemoveSectionClick,
  onSectionTitleChange,
  article,
  sections,
  onSectionTextChange,
  onSectionOrderChange,
  onChangePhoto,
  onCreateHeaderPhoto,
  onCreateSectionPhoto,
  onDeleteHeaderPhoto,
  onDeleteSectionPhoto,
  photos,
  readOnly,
  sectionIds,
}) => {
  const targetEle = useRef();

  const onTopArticleClick = () => {
    onAddSectionClick();
    const current = targetEle.current;
    const position = (current.getBoundingClientRect().top + window.pageYOffset) + 1500;
    window.scrollTo({
      top: position,
      behavior: 'smooth',
    });
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    let items = reorder(
      article.sectionIds,
      result.oldIndex,
      result.newIndex
    );
    onSectionOrderChange(items);
  }

  const addSection = () => {
    onAddSectionClick();
  }

  const onChangePosition = (oldIndex, newIndex) => {
    let clone = [...sectionIds];
    let elementId = clone[oldIndex];
    clone.splice(oldIndex, 1);
    clone.splice(newIndex, 0, elementId);
    onSectionOrderChange(clone);
  };

  const moveToTop = (oldIndex) => {
    onChangePosition(oldIndex, 0);
  };

  const moveToBottom = (oldIndex) => {
    onChangePosition(oldIndex, sectionIds.length);
  };

  return(
    <ContentView
      addSection={addSection}
      headerPhotoId={article.headerPhotoId}
      id={article.id}
      moveToBottom={moveToBottom}
      moveToTop={moveToTop}
      onAddSectionClick={onAddSectionClick}
      onChangePhoto={onChangePhoto}
      onChangePosition={onChangePosition}
      onCreateHeaderPhoto={onCreateHeaderPhoto}
      onCreateSectionPhoto={onCreateSectionPhoto}
      onDeleteHeaderPhoto={onDeleteHeaderPhoto}
      onDeleteSectionPhoto={onDeleteSectionPhoto}
      onDragEnd={onDragEnd}
      onRemoveSectionClick={onRemoveSectionClick}
      onSectionTextChange={onSectionTextChange}
      onSectionTitleChange={onSectionTitleChange}
      onSummaryChange={onSummaryChange}
      onTopArticleClick={onTopArticleClick}
      photos={photos}
      readOnly={readOnly}
      sections={sections}
      summary={article.summary}
      targetEle={targetEle}
    />
  );
}

export default ContentContainer;
