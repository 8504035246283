import React from 'react';
import PropTypes from 'prop-types';
import styles from './z-modal.module.scss';
import ZModalOverlay from './z-modal-overlay';
import Portal from '../z-portal';

const ZModalView = ({
  children, maxHeight, onOverlayClick, open,
  portalContainer, style, useOverlay, usePortal,
}) => (
  <Portal container={portalContainer} usePortal={usePortal}>
    {(open && useOverlay) && <ZModalOverlay onOverlayClick={onOverlayClick} />}
    <div
      className={`
        ${styles['modal__container']}
        ${!useOverlay ? styles['modal__gray'] : ''}
      `}
      id="targetElement"
      style={{
        ...style,
        maxHeight
      }}
    >
      {children}
    </div>
  </Portal>
);

ZModalView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array,
  ]).isRequired,
  onOverlayClick: PropTypes.func,
  open: PropTypes.bool.isRequired,
  portalContainer: PropTypes.object,
  style: PropTypes.object,
  useOverlay: PropTypes.bool,
  usePortal: PropTypes.bool,
};
ZModalView.defaultProps = {
  onOverlayClick: () => {},
  portalContainer: null,
  style: {},
  useOverlay: false,
  usePortal: true,
};

export default ZModalView;
