import React from 'react';
import ZTextArea from '../../z-text-area';
import Teaser from './teaser';
import TitleFlag from './title-flag';
import GalleryLinks from './gallery-links';
import TravelAgents from './travel-agents';
import style from './content.module.scss';
import { getImagePreviewTransformation } from '../../../utils/image/image-utils';
import {default as HeaderLinks} from './header-links';
import PhotoPreview from '../../shared/components/content-image'

const ContentView = ({
  autoComplete,
  flagCode,
  headerPhotoId,
  id,
  onChangePhoto,
  onCreateHeaderPhoto,
  onDeleteHeaderPhoto,
  onSummaryChange,
  onTitleFlagChange,
  onTitleFlagSelect,
  photos,
  summary,
}) => {
  return (
    <>
      <br/>
      <h2>Header Photo</h2>
      <div className={style['hero-image']}>
        {/** @TODO Resolve Errors */}
        {/* {props.errors.heroImage &&
          <p className={sharedStyle['error']}>{props.errors.heroImage.message}</p>
        } */}
        <PhotoPreview
          entityId={id}
          photoId={headerPhotoId}
          style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
          showInfo
          onChangePhoto={onChangePhoto}
          onCreatePhoto={onCreateHeaderPhoto}
          onRemovePhoto={onDeleteHeaderPhoto}
          photo={photos[headerPhotoId]}
          transformation={getImagePreviewTransformation({ aspectRatio: 3.14 })}
        />
      </div>
      <br/>
      <h2>Country Title Flag</h2>
      <TitleFlag
        flagCode={flagCode}
        autoComplete={autoComplete}
        autoCompleteOnSelect={onTitleFlagSelect}
        onTitleFlagChange={onTitleFlagChange}
      />
      <br/>
      <Teaser
        entityId={id}
      />
      <br/>
      <ZTextArea
        allowedFormats={['LINKS']}
        label="Summary"
        onUpdate={onSummaryChange}
        value={summary}
      />
      <br/>
      <HeaderLinks
        entityId={id}
      />
      <TravelAgents
        entityId={id}
      />
      <br />
      <GalleryLinks
        entityId={id}
      />
    </>
  )
}

export default ContentView;
