import React from 'react';
import PropTypes from 'prop-types';
import styles from './z-input-error-text.module.scss';

const ZInputErrorText = ({
  children, className, style,
}) => (
  <p className={`${styles['error-text']} ${className}`} style={style}>
    {children}
  </p>
);
ZInputErrorText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};
ZInputErrorText.defaultProps = {
  className: '',
  style: null,
};

export default ZInputErrorText;
