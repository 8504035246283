import React from 'react';
import { connect } from 'react-redux';
import ItinerariesContainer from './itineraries-container';
import {
  setItineraries
} from '../../../state/actions/landing-page-editor';

const ItinerariesRedux = (props) => {
  return (
    <ItinerariesContainer {...props}/>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const {editor} = state.landingPages.landingPage;
  const currentEntity = editor[id] || {};
  const itins = currentEntity.itineraries || [];
  return {
    itineraries : itins,
    itineraryCount: currentEntity.itineraryCount || itins.length || 0,
    errors: {},
    readOnly: state.versions.readOnly,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    updateItineraries : (itineraries) => dispatch(setItineraries(id, itineraries)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItinerariesRedux);
