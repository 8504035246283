import React from 'react';
import ZVersions from '../../z-versions';
import ZPublish from '../../z-publish';

const VersionsView = ({
  actions,
  id,
  modalState,
  published,
  onCloseModal,
  onClickUnpublish,
  saved,
  showModal,
  usageReferences,
  publishErrors
}) => (
  <>
    <ZVersions
      actions={actions}
      entityId={id}
      entityType="itineraries"
      onClickUnpublish={onClickUnpublish}
      published={published}
      saved={saved}
    />
    {showModal && (
      <ZPublish
        publishErrors={publishErrors}
        entityId={id}
        entityType="Itinerary"
        modalState={modalState}
        onCloseModal={onCloseModal}
        showModal={showModal}
        usageReferences={usageReferences}
        usageReferencesError={(
          <>
            <h2>This itinerary is being used.</h2>
            <p>
              Please go to the Usage References tab.
              <br/>
              Visit the pages listed and remove it from the itineraries list.
            </p>
          </>
        )}
      />
    )}
  </>
);

export default VersionsView;
