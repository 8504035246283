import React from 'react';
import style from './sample-snippet.module.scss';
import sharedStyle from '../../../shared/styles/shared.module.scss';
import ZInput from '../../../z-input';
import ZPhotoPreview from '../../../z-photo-preview';
import { decodeHTMLEntities } from '../../../../utils/window';
import ZDeleteButton from '../../../z-button-delete';
import ZTextArea from '../../../z-text-area';
import { getImagePreviewTransformation } from '../../../../utils/image/image-utils';

const SampleArticleView = ({
  className,
  snippet,
  snippetLength,
  index,
  onChangePosition,
  removeSnippet,
  onClickPreview,
  onClickEdit,
  previewView,
}) => {
  let image = null;

  if (snippet.thumnailImage) {
    image = snippet.thumnailImage;
  } else if (snippet.image) {
    image = snippet.image;
  } else {
    image = snippet.heroImage;
  }

  return (
    <div className={`${style['sample-snippet']} ${className}`}>
      { !previewView &&
        <div className={style['sample-snippet--left']}>
          <span className={style['sample-snippet--left--current-pos']}>
            {`Position: ${index + 1}`}
          </span>
          <div className={style['sample-snippet--left--move']}>
            <div className={style['sample-snippet--left--pos']}>
              <ZInput
                style={{backgroundColor: '#FFF', padding: '0 10px'}}
                label={`Move to Position: `}
                onUpdate={(e) => {
                  e && onChangePosition(index, isNaN(parseInt(e, 10) - 1) ? index : parseInt(e, 10) - 1);
                }}
              />
            </div>
            <button
              type="button"
              className={`${sharedStyle['button']} ${style['sample-snippet--left--button']}`}
              onClick={() => onChangePosition(index, 0)}
            >
              Move To Top
            </button>
            <button
              type="button"
              className={`${sharedStyle['button']} ${style['sample-snippet--left--button']}`}
              onClick={() => onChangePosition(index, snippetLength,)}
            >
              Move To Bottom
            </button>
          </div>
        </div>
      }
      <div className={style['sample-snippet--right']}>
        <div className={style['sample-snippet--title']}>
          <h3>{decodeHTMLEntities(snippet.title)}</h3>
          <button
            type="button"
            className={`${sharedStyle['button']} ${style['sample-snippet--title--button']}`}
            onClick={onClickEdit}
          >
            Edit
          </button>
          <button
            type="button"
            className={`${sharedStyle['button']} ${style['sample-snippet--title--button']}`}
            onClick={onClickPreview}
          >
            Preview
          </button>
        </div>
        <div
          style={{display: 'flex'}}
        >
          { image && (
            <ZPhotoPreview
              alt={image?.alt}
              data={image}
              gravity={image?.gravity}
              readOnly
              src={image?.uri}
              style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
              title={image?.title}
              simpleView
              transformation={getImagePreviewTransformation({ aspectRatio: 1.5 })}
            />
          )}
          <ZTextArea
            label="Summary"
            value={snippet.summary}
            READ_ONLY
          />
        </div>
        <p className={style['sample-snippet--item-wrapper__note']}>*Visit the page editor to make changes to the image or summary.</p>
      </div>
      { !previewView &&
        <div className={style['sample-snippet--remove']}>
          <ZDeleteButton onClick={() => removeSnippet(index)} />
        </div>
      }
    </div>
  )
}

export default SampleArticleView;
