import React from 'react';
import CountriesView from './countries-view';
import ZApiClient from '../../../../utils/client/z-api-client';

const CountriesContainer = ({
  locations,
  onCountrySelect,
  onCountryDelete,
}) => {

  // Auto Complete functions must be thenable
  const countryAutoComplete = (text) => {
    return ZApiClient
    .getLocationsClient()
    .getNamesByFragment(text, { limit: 10 });
  } 

  // Redundant but needed to pass to z-autocomplete
  const onSelectCountry = (val) => {
    onCountrySelect(val);
  }

  const onDeleteCountry = (arr) => {
    const ret = arr.map((ele)=> ele.label);
    onCountryDelete(ret);
  }

  return (
    <CountriesView
      countryAutoComplete={countryAutoComplete}
      onCountrySelect={onSelectCountry}
      onCountryDelete={onDeleteCountry}
      locations={locations}
    />
  )
}

export default CountriesContainer;
