import React from 'react';
import _ from 'lodash';
import ZModal from '../z-modal';
import ZSpinner from '../z-spinner';
import styles from './z-publish.module.scss';
import sharedStyle from '../shared/styles/shared.module.scss';
import ZInput from '../z-input';

const ZPublishModalView = ({
  canonical,
  confirmBtnDisabled,
  error,
  errors,
  loading,
  manuallyConfirmed,
  maxHeight,
  modalState,
  onClickConfirm,
  onCloseModal,
  pathConfirmed,
  setComment,
  setManuallyConfirmed,
  setSignificant,
  setPathConfirmed,
  showModal,
  significant,
  usageReferences,
  usageReferencesError,
  validationErrors,
}) => (
  <ZModal
    closeable
    maxHeight={maxHeight}
    open={showModal}
    style={{padding: '28px'}}
    toggleModal={onCloseModal}
    useOverlay
  >
    <div className={[sharedStyle['publish__wrapper'], styles['publish__wrapper']]}>
      {modalState === 'Publish' && (
        <>
          <div className={sharedStyle['publish']}>
            <h2>Please review before publishing</h2>
            <div className={styles['publication__comment__wrapper']}>
              <ZInput
                error={errors && errors.title}
                label='Publication Comment'
                onUpdate={setComment}
                inputKey={'publication_comment'}
              />
              <div className={styles['publication__significant__wrapper']}>
                <input 
                  type='checkbox'
                  id='publication_significant' 
                  className={styles['checkbox']} 
                  value='showDate'
                  name='publication_significant'
                  onChange={setSignificant}
                  checked={significant}
                />
                <p>Significant? (Update modified date)</p>
              </div>
            </div>
            {!pathConfirmed && (
              <div className={sharedStyle['confirm-path']}>
                <p className={sharedStyle['subtext']}>
                  {canonical ? 'Please confirm the canonical path: ' : 'Please set a canonical path.'}
                </p>
                <p>{canonical ? canonical.alias : ''}</p>
                <button
                  type="button"
                  className={sharedStyle['button']}
                  onClick={() => setPathConfirmed(true)}
                  disabled={!canonical}
                >
                  Path Reviewed
                </button>
              </div>
            )}
            {loading &&
              <div className={styles.centered}>
                <p className={`${sharedStyle['subtext']} ${styles['loading-text']}`}>
                  Checking for errors
                </p>
                <ZSpinner />
              </div>
            }
            {(!loading && !validationErrors) &&
              <div className={styles.centered}>
                <p className={sharedStyle['subtext']}>
                  This version is complete!
                </p>
              </div>
            }
            {(validationErrors && !loading) &&
              <div className={sharedStyle['validation']}>
                <p className={sharedStyle['subtext']}>
                  The following fields contain errors or are missing.
                </p>
                <p className={sharedStyle['subtext']}>
                  Please review before publishing:
                </p>
                  <ErrorsView errors={errors} />
              </div>
            }
          </div>
        </>
      )}
      {modalState === 'Unpublish' && (
        <div className={sharedStyle['publish']}>
          {usageReferences ? (
            <div className={styles['usage-message']}>
              {usageReferencesError}
            </div>
          ) : (
            <>
              <h2 className={sharedStyle['confirmation']}>You are unpublishing.</h2>
              <div className={styles['publication__comment__wrapper']}>
                <ZInput
                  error={errors && errors.title}
                  label='Publication Comment'
                  onUpdate={setComment}
                  inputKey={'publication_comment'}
                />
              </div>
              <p className={sharedStyle['confirmation']}>Are you sure?</p>
            </>
          )}
        </div>
      )}
      {error && <p className={sharedStyle['error']}>There was an error. Please contact support.</p>}
      { (validationErrors && !loading && !manuallyConfirmed) &&
        <div style={{textAlign: 'center'}}>
          <p className={sharedStyle['confirmation']}>Are you sure you want to publish?</p>
          <button
            type="button"
            className={sharedStyle['button']}
            onClick={() => setManuallyConfirmed(true)}
          >
            Yes
          </button>
        </div>
      }
      <div style={{textAlign: 'center'}}>
        <button
          type="button"
          className={sharedStyle['button']}
          onClick={() => onCloseModal()}
        >
          Cancel
        </button>
        <button
          type="button"
          className={sharedStyle['button']}
          onClick={onClickConfirm}
          disabled={confirmBtnDisabled}
        >
          {modalState}
        </button>
      </div>
    </div>
  </ZModal>
);

const isSkippable = (key) => {
  const map = {
    'day' : true,
    'meta' : true,
    'content' : true,
    'isValid' : true,
  }
  return map[key];
}

const ErrorsView = ({ errors }) => (
  <>
    {Object.keys(errors).map((key, i) => {
      if (!isSkippable(key) && errors[key].message) {
        return (
          <p key={`${key}-${i}`}>{`${_.startCase(key)}: ${errors[key].message}`}</p>
        )
      } else {
        return null
      }
    })}
    {!!errors.day && Object.keys(errors.day).map((key, i) => (
      <p key={`${key}-${i}`}>{`${_.startCase(key)}: ${errors.day[key].message}`}</p>
    ))}
    {!!errors.meta && Object.keys(errors.meta).map((key, i) => (
      <p key={`${key}-${i}`}>{`${_.startCase(key)}: ${errors.meta[key].message}`}</p>
    ))}
  </>
);

export default ZPublishModalView;
