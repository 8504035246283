/* eslint-disable react/no-danger */
import React from 'react';
import CompanyInfo from './info';
import Editable from './editable';
import Locations from './locations';
import ZSpinner from '../../z-spinner';
import OryxButton from '../oryx-shared/oryx-button/type-button';
import ZModal from '../../z-modal';
import styles from './company.module.scss';
import config from '../../../config';
import FetchStatus from '../utils/fetch-status';
import { Error } from './utils';

const CompanyView = ({
  fetchStatus,
  company,
  infoModalState,
  showModal,
  openModal,
  onClickSave,
  onClickSync,
}) => (
  <>
    <div className={styles.company}>
      {fetchStatus === FetchStatus.FETCHING && (
        <div className={styles.spinner}>
          <ZSpinner />
        </div>
      )}
      {fetchStatus === FetchStatus.ERROR && (
        <div className={styles.spinner}>
          <Error>
            There was an error or this company doesn&apos;t exist.
          </Error>
        </div>
      )}
      {company && (
        <div className={styles['company-wrapper']}>
          <div>
            <p className={styles['company-wrapper__created']}>
              {`Partner Since: ${company.partnerSince}`}
            </p>
            <div className={styles['company-wrapper__title']}>
              <h1>
                {`Profile Status: ${company.published ? 'Published' : 'Unpublished'}`}
              </h1>
              {company.nodeId && (
                <a href={`${config.Z3_URL}/node/${company.nodeId}`} target="_blank" rel="noopener noreferrer">
                  (D7 Profile)
                </a>
              )}
              <OryxButton
                style={{
                  width: 'fit-content',
                  padding: '10px 20px',
                  height: '35px',
                  margin: '20px',
                }}
                onClick={onClickSync}
              >
                Sync
              </OryxButton>
            </div>
          </div>
          <CompanyInfo
            name={company.name}
            nodeId={company.nodeId}
            id={company.id}
            country={company.country}
            websites={company.websites}
            priority={company.activityLevel}
          />
          <Editable
            errors={{}}
            id={company.id}
          />
          <Locations
            primaryLocations={company.primaryLocations}
            secondaryLocations={company.secondaryLocations}
          />
          <div className={styles['company-wrapper__submit']}>
            <OryxButton
              style={{
                width: 'fit-content',
                padding: '10px 20px',
                height: '35px',
                margin: '13px 0 20px',
              }}
              onClick={onClickSave}
            >
              Save
            </OryxButton>
          </div>
        </div>
      )}
    </div>
    <ZModal
      maxHeight="90%"
      open={showModal}
      toggleModal={openModal}
      useOverlay
    >
      <div className={styles['save-modal-wrapper']}>
        { !infoModalState.error &&
          <h2>Changes Were Saved</h2>
        }
        { infoModalState.error &&
          <h2>{infoModalState.error}</h2>
        }
        { infoModalState.published &&
          <h3>Page is published</h3>
        }
        { !infoModalState.published &&
          <h3>However all conditions must be met in order to publish</h3>
        }
        <ul>
        { !infoModalState.firstName &&
          <li>First Name must not be blank</li>
        }
        { !infoModalState.activityLevel &&
          <>
            <li>Matching Priority Level must not be <u>Excluded</u></li>
            <li>Matching Priority Level must not be <u>New</u></li>
          </>
        }
        </ul>
      </div>
      <div className={styles.modal__confirm}>
        <OryxButton
          style={{
            width: 'fit-content',
            padding: '10px 20px',
            height: '35px',
            margin: '13px 0 20px',
          }}
          onClick={openModal}
        >
          Ok
        </OryxButton>
      </div>
    </ZModal>
  </>
);

export default CompanyView;
