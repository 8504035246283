export const ADD_LOCATION = (T) => `ADD_LOCATION_${T.toUpperCase()}`;
export const SET_LOCATIONS = (T) => `SET_LOCATIONS_${T.toUpperCase()}`;
export const SET_COUNTRY_LIST = (T) => `SET_COUNTRY_LIST_${T.toUpperCase()}`;
export const SET_FLAG_COUNTRY_CODE = (T) => `SET_FLAG_COUNTRY_CODE_${T.toUpperCase()}`;
export const ADD_COUNTRY = (T) => `ADD_COUNTRY_${T.toUpperCase()}`;
export const SET_COUNTRIES = (T) => `SET_COUNTRIES_${T.toUpperCase()}`;

export const LOCATION_ACTION_NAMES = {};

(() => {
    [
        'article',
        'company_category',
        'landing_page_category',
        'content',
        'guide',
        'itinerary',
        'landing_page'
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        LOCATION_ACTION_NAMES[entityType] = {};
        LOCATION_ACTION_NAMES[entityType]['ADD_LOCATION'] = ADD_LOCATION(entityType);
        LOCATION_ACTION_NAMES[entityType]['SET_LOCATIONS'] = SET_LOCATIONS(entityType);
        LOCATION_ACTION_NAMES[entityType]['SET_COUNTRY_LIST'] = SET_COUNTRY_LIST(entityType);
        LOCATION_ACTION_NAMES[entityType]['SET_FLAG_COUNTRY_CODE'] = SET_FLAG_COUNTRY_CODE(entityType);
        LOCATION_ACTION_NAMES[entityType]['ADD_COUNTRY'] = ADD_COUNTRY(entityType);
        LOCATION_ACTION_NAMES[entityType]['SET_COUNTRIES'] = SET_COUNTRIES(entityType);
    });
})();
