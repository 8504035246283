import React from 'react';
import ItineraryView from '../../../components/itinerary';

const ItineraryId = (props) => {
  return(
    <ItineraryView {...props}></ItineraryView>
  )
}

export default ItineraryId;
