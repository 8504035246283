export const SET_HELPFUL_INFO = (T) => `SET_HELPFUL_INFO_${T.toUpperCase()}`;

export const HELPFUL_INFO_ACTION_NAMES = {};

(() => {
    [
        'guide',
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        HELPFUL_INFO_ACTION_NAMES[entityType] = {};
        HELPFUL_INFO_ACTION_NAMES[entityType]['SET_HELPFUL_INFO'] = SET_HELPFUL_INFO(entityType);
    });
})();
