import {
    RECEIVED_SECTIONS,
    ADD_SECTION,
    REMOVE_SECTION,
    REORDER_SECTIONS,
    SET_SECTION_TITLE,
    SET_SECTION_TEXT,
    ADD_FOOTER_SECTION,
    RECEIVED_FOOTER_SECTIONS,
    SET_SECTION_LINKS,
    ADD_SECTION_LINK,
    REMOVE_SECTION_LINK,
    SET_LINK_TITLE,
    SET_LINK_URI,
    REMOVE_FOOTER_SECTION,
    REORDER_FOOTER_SECTIONS,
    UPDATE_SECTION,
} from './section-action-types';
import {fetchTuid} from "../../../utils/fetch/tuid";

export const receivedSectionsT = (T, data) => ({
    type: RECEIVED_SECTIONS(T),
    sections: data,
});

export const addSectionT = (T, entityId, sectionId, index = null, data = null) => ({
    type: ADD_SECTION(T),
    id: sectionId,
    entityId,
    index,
    data,
});

export const removeSectionT = (T, entityId, sectionId) => ({
    type: REMOVE_SECTION(T),
    id: sectionId,
    entityId,
});

export const reorderSectionsT = (T, entityId, sectionIds) => ({
    type: REORDER_SECTIONS(T),
    entityId,
    sectionIds,
});

export const setSectionTitleT = (T, sectionId, text) => ({
    type: SET_SECTION_TITLE(T),
    id: sectionId,
    value: text,
});

export const setSectionTextT = (T, sectionId, text) => ({
    type: SET_SECTION_TEXT(T),
    id: sectionId,
    value: text,
});

export const addFooterSectionT = (T, entityId, sectionId, index = null) => ({
    type: ADD_FOOTER_SECTION(T),
    id: sectionId,
    entityId,
    index,
});

export const receivedFooterSectionsT = (T, data) => ({
    type: RECEIVED_FOOTER_SECTIONS(T),
    sections: data,
});

export const setSectionLinksT = (T, sectionId, links) => ({
    type: SET_SECTION_LINKS(T),
    id: sectionId,
    list: links,
});

export const addSectionLinkT = (T, sectionId, link) => ({
    type: ADD_SECTION_LINK(T),
    id: sectionId,
    link,
});

export const removeSectionLinkT = (T, sectionId, linkId) => ({
    type: REMOVE_SECTION_LINK(T),
    id: sectionId,
    linkId,
});

export const setLinkTitleT = (T, sectionId, linkId, text) => ({
    type: SET_LINK_TITLE(T),
    id: sectionId,
    linkId,
    value: text,
});

export const setLinkUriT = (T, sectionId, linkId, text) => ({
    type: SET_LINK_URI(T),
    id: sectionId,
    linkId,
    value: text,
});

export const removeFooterSectionT = (T, entityId, sectionId) => ({
    type: REMOVE_FOOTER_SECTION(T),
    id: sectionId,
    entityId,
});

export const reorderFooterSectionsT = (T, entityId, list) => ({
    type: REORDER_FOOTER_SECTIONS(T),
    entityId,
    list
});

export const updateSectionT = (T, section) => ({
    type: UPDATE_SECTION(T),
    id: section.id,
    section,
});

export const createFooterSectionT = (T, entityId, index) => {
    return (dispatch) => {
        return fetchTuid().then(({id}) => {
            dispatch(addFooterSectionT(T, entityId, id, index));
        });
    }
};

export const createSectionT = (T, entityId, index, type, data) => {
    return (dispatch) => {
        return fetchTuid().then(({id}) => {
            if(type === 'FOOTER'){
                dispatch(addFooterSectionT(T, entityId, id, index));
            } else if (type === 'DATA') {
                dispatch(addSectionT(T, entityId, data.id, false, data));
            } else {
                dispatch(addSectionT(T, entityId, id, index));
            }
        });
    }
};

export const createSectionLinkT = (T, sectionId, link) => {
    return (dispatch) => {
        return fetchTuid().then(({id})=>{
            const currentLink = {
                ...link,
                id,
            };
            dispatch(addSectionLinkT(T, sectionId, currentLink));
        });
    }
};

const addIdToEntity = async (entity) => {
    const ret = {...entity};
    if(ret.entityId){
        ret.id = ret.entityId;
    }
    if(!ret.id){
        const {id} = await fetchTuid();
        ret.id = id;
    }
    return ret;
};

const mapFooterSection = async (section) => {
    let {links} = section;
    const mappedLinks = await Promise.all(links.map((link) => addIdToEntity(link)));
    const ret = await addIdToEntity(section);
    ret.links = mappedLinks;
    return ret;
};

// Used for result of footer link search. Handles new id creation
export const addUpstreamFooterSectionsT = (T, entityId, sections) => {
    return dispatch => {
        const promiseArr = sections.map((ele)=> mapFooterSection(ele));
        Promise.all(promiseArr).then((mappedSections)=> {
            const map = mappedSections.reduce((acc, cur) => {
                const {id} = cur;
                acc[id] = cur;
                return acc;
            },{});
            dispatch(receivedFooterSectionsT(T, map));
            for(let i = 0; i < mappedSections.length; i++){
                const currentSection = mappedSections[i];
                dispatch(addFooterSectionT(T, entityId, currentSection.id));
            }
        });
    }
};

