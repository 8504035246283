import React from 'react';
import { connect } from 'react-redux';
import TeaserContainer from './teaser-container';
import {setCTAStepsT, setCTATitleT} from "../../../state/actions/shared/teaser";

const TeaserRedux = (props) => (
  <TeaserContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {articleId:id} = ownProps;
  const article = {...state.articles.article.editor[id]};
  return{
    cta: article.callToAction,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { articleId:id } = ownProps;
  return {
    onCTATitleUpdate: text => dispatch(setCTATitleT('article', text, id)),
    onCTAStepsUpdate: data => dispatch(setCTAStepsT('article', data, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeaserRedux);
