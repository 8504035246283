import React from 'react';
import ZSpinner from '../z-spinner';
import LoginModal from '../../components/login-page';
import authRoutes from '../../routes/public';

const AuthRouterView = ({
  children, loading, loggedIn, loginModal, readOnly,
}) => (
  <>
    { loggedIn && (
      <form style={{paddingTop: '30px'}} onSubmit={(e) => e.preventDefault()}>
        <fieldset disabled={readOnly}>
          {children}
        </fieldset>
      </form>
      )}
    { loggedIn === false && authRoutes }
    { loading && (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ZSpinner />
      </div>
    )}
    { loginModal.open && <LoginModal open useOverlay /> }
  </>
);

export default AuthRouterView;
