import React, {useState} from 'react';
import HeaderLinksView from './header-links-view';

const HeaderLinksContainer = ({
  onHeaderLinksChange,
  currentEntity,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState({});

  const headerLinks = currentEntity.headerLinks || {}; 
  const links = headerLinks.links || [];

  const toggleModal = () => {
    setShowModal((state) => !state);
  }

  const onOpenNew = () => {
    setModalState({});
    toggleModal();
  }

  const onLinkOrderChange = (array) => {
    onHeaderLinksChange({
      ...headerLinks,
      links: array,
    });
  }

  const onLinkChange = (linkState) => {
    if(modalState.id){
      // Editing an existing link
      const editIndex = links.findIndex((e) => e.id === modalState.id);
      const newLinkState = [...links];
      newLinkState.splice(editIndex, 1, linkState);
      const ret = {
        ...headerLinks,
        links: newLinkState,
      }
      onHeaderLinksChange(ret);
    } else {
      // Creating a new link
      const ret = {
        ...headerLinks,
        links: [linkState, ...links]
      }
      onHeaderLinksChange(ret);
    }
  }

  const onClickEdit = (id) => {
    const item = links.find((e) => e.id === id);
    if(item) {
      setModalState(item);
      toggleModal();
    }
  }

  const onCancel = () => {
    setModalState({});
    toggleModal();
  }

  const onDeleteItem = (id) => {
    if (id) {
      const res = links.filter((e) => e.id !== id);
      onHeaderLinksChange({
        ...headerLinks,
        links: res,
      });
      toggleModal();
    }
  }

  const onTitleChnage = (e) => {
    e.preventDefault();
    const {
      target: {value}
    } = e;
    onHeaderLinksChange({
      ...headerLinks,
      title: value,
      links: links,
    });
  }

  const onRemoveAll = (e) => {
    if (window.confirm("Do you really want to remove all links?")) {
      onHeaderLinksChange({});
    }
  }

  return (
    <HeaderLinksView
      toggleModal={toggleModal}
      onOpenNew={onOpenNew}
      onCancel={onCancel}
      showModal={showModal}
      modalState={modalState}
      onLinkChange={onLinkChange}
      onLinkOrderChange={onLinkOrderChange}
      onClickEdit={onClickEdit}
      onDeleteItem={onDeleteItem}
      links={links}
      sectionTitle={headerLinks.title}
      onTitleChnage={onTitleChnage}
      onRemoveAll={onRemoveAll}
    />
  );
};

export default HeaderLinksContainer;
