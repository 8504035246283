import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ZInput from '../../z-input';
import ZTextArea from '../../z-text-area';
import ZToggle from '../../z-toggle';
import ZButton from '../../z-button';
import ZSpinner from '../../z-spinner';
import { decodeHTMLEntities } from '../../../utils/window';
import style from './team-member-editor.module.scss';
import TeamImage from './content-image';

const TeamMemberEditorView = ({
  closeModal,
  displayed,
  error,
  id,
  isSaving,
  onChangeDisplayed,
  onClickSave,
  saveDisabled,
  setDescription,
  setTitle,
  setName,
  description,
  title,
  name,
  image,
  onRemovePhoto,
  onCreatePhoto,
  onChangePhoto,
  onClickDelete,
  deleteError,
}) => (
  <div className={style['editor']}>
    <ZToggle
      checked={displayed}
      label="Displayed"
      onChange={onChangeDisplayed}
      toggleClass={style['editor__toggle']}
    />
    <TeamImage
      entityId={id}
      photo={image}
      photoId={image.id}
      onRemovePhoto={onRemovePhoto}
      onCreatePhoto={onCreatePhoto}
      onChangePhoto={onChangePhoto}
      showInfo={false}
    />
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}>
      <ZInput
        inputId="title"
        label="Title"
        onChange={setTitle}
        placeholder="Enter title here"
        style={{ 
          marginBottom: '20px',
          marginRight: '14px',
          width: '100%',
        }}
        text={decodeHTMLEntities(title)}
      />
      <ZInput
        inputId="name"
        label="Name"
        onChange={setName}
        placeholder="Enter Name here"
        style={{
          marginBottom: '20px',
          width: '100%',
        }}
        text={name}
      />
    </div>
    <ZTextArea
       allowedFormats={[]}
       label="Description"
       onUpdate={setDescription}
       placeholder="Enter description here"
       style={{ marginBottom: '20px' }}
       textAreaStyle={{ maxHeight: '200px', overflow: 'scroll' }}
       value={description}
    />
    {error && (
      <p className={style['error']}>There was an error saving this team member.</p>
    )}
    {deleteError && (
      <p className={style['error']}>There was an error deleting this team member.</p>
    )}
    <div className={style['editor__buttons']}>
      {isSaving ? (
        <ZSpinner />
      ) : (
        <>
          <ZButton
            btnClass={style['editor__button']}
            disabled={saveDisabled}
            onClick={onClickSave}
            text="Save"
          />
          <ZButton
            btnClass={style['editor__button']}
            onClick={closeModal}
            text="Cancel"
          />
          {id !== 'new' && (
            <ZButton
              btnClass={style['editor__button']}
              disabled={saveDisabled}
              onClick={onClickDelete}
              text="Delete"
            />
          )}
        </>
      )}
    </div>
  </div>
);

export default TeamMemberEditorView;
