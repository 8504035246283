import ZApiClient from '../../utils/client/z-api-client';

export const USAGE_REQUEST = 'USAGE_REQUEST';
export const usageRequest = () => ({
  type: USAGE_REQUEST,
});

export const USAGE_SUCCESS = 'USAGE_SUCCESS';
export const usageSuccess = (entityId, references) => ({
  type: USAGE_SUCCESS,
  entityId,
  references,
});

export const USAGE_ERROR = 'USAGE_ERROR';
export const usageError = () => ({
  type: USAGE_ERROR,
});

export const CLEAR_USAGE = 'CLEAR_USAGE';
export const clearUsageReferences = (entityId) => ({
  type: CLEAR_USAGE,
  entityId,
});

export const getUsageReferences = (entityId) => (dispatch) => {
  dispatch(usageRequest());
  ZApiClient
  .getReferencesClient()
  .readAllUsageReferences(entityId)
    .then((res) => {
      dispatch(usageSuccess(entityId, res));
    })
    .catch(() => dispatch(usageError()))
};
