import { push } from 'connected-react-router';
import ArticleClient from '../../../utils/client/article';
import { fetchTuid } from '../../../utils/fetch/tuid';
import {
  receivedPhotosT,
} from '../shared/photo';
import {
  receivedSectionsT
} from '../shared/section';
import {
  getCountriesT
} from "../shared/location";
import {
  fetchEntityVersion,
  addSavedEntityVersion,
  versionSuccess,
} from '../versions';
import ZApiClient from '../../../utils/client/z-api-client';
import { Article } from '@zicasso/zicasso-sdk/lib/article';

import {
  REQUEST_ARTICLE,
  REQUEST_NEW_ARTICLE,
  RECEIVED_ARTICLE,
  ARTICLE_SAVE_REQUESTED,
  ARTICLE_SAVED,
  REQUEST_ARTICLE_FAILED,
} from '../shared/similar-action-types';
import {
  SET_AUTHOR_NAME,
  SET_AUTHOR_LINK,
  SET_TYPE,
  SET_SHOW_AUTHOR,
  SET_SHOW_TOC,
  SET_SHOW_DATE,
} from './action-types';

const articleClient = new ArticleClient();

const requestArticle = () => ({
  type: REQUEST_ARTICLE,
});

export const requestNewArticle = () => ({
  type: REQUEST_NEW_ARTICLE,
});

const receivedArticle = (data) => ({
  type: RECEIVED_ARTICLE,
  receivedAt: Date.now(),
  id: data.id,
  article: data,
});

export const saveRequested = () => ({
  type: ARTICLE_SAVE_REQUESTED,
});

export const articleSaved = (data) => ({
  type: ARTICLE_SAVED,
  data
});

export const articleRequestFailed = () => ({
  type: REQUEST_ARTICLE_FAILED,
});

export const setAuthorName = (text, id) => ({
  type: SET_AUTHOR_NAME,
  value: text,
  id
});

export const setAuthorLink = (text, id) => ({
  type: SET_AUTHOR_LINK,
  value: text,
  id
});

export const setType = (type, id) => ({
  type: SET_TYPE,
  value: type,
  id,
});

export const setShowAuthor = (id, bool) => ({
  type: SET_SHOW_AUTHOR,
  id,
  value: bool,
});

// TOC short for Table of Contents
export const setShowTOC = (id, bool) => ({
  type: SET_SHOW_TOC,
  id,
  value: bool,
});

export const setShowDate = (id, bool) => ({
  type: SET_SHOW_DATE,
  id,
  value: bool,
});

export const redirectToCreate = () => {
  return dispatch => {
    dispatch(push('/create/article'));
  }
};

function shouldFetchArticle(state, id, updateId) {
  const {editor} = state.articles.article;
  if(!editor[id]) {
    return true;
  } else if (editor[id] && editor[id].updateId !== updateId) {
    return true;
  } else {
    return false;
  }
}

/**
 * Pass the result of article client getState method;
 * @param {object} articleState
 */
const receiveArticleState = (articleState) => {
  return dispatch => {
    let {id:currentId} = articleState;
    dispatch(receivedPhotosT('article', articleState.photos));
    dispatch(receivedSectionsT('article', articleState.sections));
    dispatch(receivedArticle(articleState[currentId]));
  }
};

/**
 *
 * @param {string} id
 */
export const fetchArticleIfNeeded = (id, updateId) => {
  return (dispatch, getState) => {
    const { countries } = getState().articles.article.editor;
    const shouldFetch = shouldFetchArticle(getState(), id, updateId);

    if (!countries || countries.length === 0)  {
      dispatch(getCountriesT('article'));
    }
    if(shouldFetch){
      dispatch(fetchArticle(id, updateId));
    }
  }
};

export const fetchArticle = (id, updateId) => {
  return dispatch => {
    dispatch(requestArticle());
    const req = !updateId
      ? ZApiClient.getArticlesClient().getArticle(id)
      : ZApiClient.getArticlesClient().getArticleVersion(id, updateId);
    return req.then((res)=>{
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return articleClient.getState(res);
    }).then((newState)=>{
      dispatch(receiveArticleState(newState));
    }).catch((e)=>{
      if(e.message === 'NOT_FOUND'){
        dispatch(articleRequestFailed());
      }
      console.error('Error Fetching Article', e);
    })
  };
};

export const saveArticle = (articleId) => {
  return (dispatch, getState) => {
    dispatch(saveRequested());
    ZApiClient
    .getArticlesClient()
    .exists(articleId)
    .then((res)=>{
      const shouldCreate = !res;
      const currentState = getState();
      const {article} = currentState.articles;
      const articleEntity = articleClient.getArticle(articleId, article);
      return createOrUpdateArticle(articleEntity, shouldCreate);
    }).then((result)=>{
      dispatch(articleSaved());
      const { id, updateId, userId, updatedAt } = result;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return articleClient.getState(result);
    }).then((newState)=>{
      dispatch(receiveArticleState(newState));
    }).catch((e)=>{
      console.error('Error Saving Article', e);
      console.error(e.stack);
    });
  };
};

export const makeLatest = (id, updateId) => (dispatch) => {
  dispatch(requestArticle());
  return ZApiClient
  .getArticlesClient()
  .getArticleVersion(id, updateId)
    .then((res) => {
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return createOrUpdateArticle(res, false);
    })
    .then((res) => {
      const { id, updateId, userId, updatedAt } = res;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return articleClient.getState(res);
    })
    .then((newState) => {
      dispatch(receiveArticleState(newState));
    })
    .catch((e) => {
      if(e.message === 'NOT_FOUND'){
        dispatch(articleRequestFailed());
      }
      console.error('Error Fetching Article', e);
    });
};

const createOrUpdateArticle = (data, create = true) =>{
  if(create){
    return ZApiClient.getArticlesClient().createArticle(new Article(data));
  } else {
    return ZApiClient.getArticlesClient().updateArticle(new Article(data));
  }
};


export const createNewArticle = () => {
  return dispatch => {
    dispatch(requestNewArticle());
    return fetchTuid()
      .then(val => {
        let {id} = val;
        dispatch(receivedArticle({
          id : id,
          title: null,
          summary: '',
          type: 'EVERGREEN',
          sectionIds: [],
          metatags: {},
        }));
        dispatch(push(`/articles/${id}`));
      })
  }
};

export const fetchArticleVersion = (id, versionId) => (dispatch) => {
  dispatch(fetchEntityVersion('articles', id, versionId))
    .then(() => {
      dispatch(versionSuccess(id, versionId));
    })
    .catch(console.error);
};
