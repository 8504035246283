import React from 'react';
import { connect } from 'react-redux';
import FooterLinksView from './footer-links-view';
import {reorderFooterSectionsT} from "../../../../state/actions/shared/section";

const mapSectionIdsToSection = (ids, sections) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = sections[ele];
      return section;
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const {articleId:id} = ownProps;
  const articleEntities = state.articles.article;
  const {sections} = articleEntities;
  const {editor} = articleEntities;
  const currentArticle = editor[id]
  const { footerSectionIds } = currentArticle;
  return {
    sections: mapSectionIdsToSection(footerSectionIds, sections),
    articleId: id,
    readOnly: state.versions.readOnly,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {articleId:id} = ownProps;
  return {
    onSectionOrderChange: (list) => {
      const sectionIds = list.map(ele=>ele.id);
      dispatch(reorderFooterSectionsT('article', id, sectionIds));
    }
  }
}

const FooterLinkRedux = (props) => (
  <FooterLinksView {...props}/>
);

export default connect(mapStateToProps, mapDispatchToProps)(FooterLinkRedux);
