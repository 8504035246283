import React, { useEffect } from 'react';
import PublishedStatusView from './published-view';

const PublishedStatusContainer = ({
  fetchPublication,
  entityId,
  entityUpdateId,
  isFetching,
  getPublishedStatus,
  publication,
  publicationStatus,
}) => {
  useEffect(() => {
    fetchPublication(entityId);
  }, [entityId, fetchPublication]);

  useEffect(() => {
    if (entityUpdateId && !isFetching) {
      getPublishedStatus(publication, entityId, entityUpdateId)
    }
  }, [entityId, entityUpdateId, getPublishedStatus, isFetching, publication]);

  return (
    <PublishedStatusView
      isFetching={isFetching}
      publicationStatus={publicationStatus}
    />
  );
};

export default PublishedStatusContainer;
