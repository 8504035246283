import React from 'react';
import { connect } from 'react-redux';
import TeaserContainer from './teaser-container';
import {setCTAStepsT, setCTATitleT} from "../../../../state/actions/shared/teaser";

const TeaserRedux = (props) => (
  <TeaserContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const {editor} = state.landingPages.landingPage;
  const currentEntity = editor[id] || {};
  return{
    cta: currentEntity.callToAction,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onCTATitleUpdate: text => dispatch(setCTATitleT('landing_page', text, id)),
    onCTAStepsUpdate: data => dispatch(setCTAStepsT('landing_page', data, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeaserRedux);
