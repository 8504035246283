import ZApiClient from '../../../utils/client/z-api-client';
import {
    ADD_BREADCRUMB,
    DELETE_BREADCRUMB,
    DELETE_BREADCRUMBS,
    DELETE_BREADCRUMB_TITLE,
    REQUEST_BREADCRUMBS,
    SET_BREADCRUMB,
    SET_BREADCRUMBS,
    SET_BREADCRUMBS_ERROR,
    SET_BREADCRUMB_TITLE,
} from './breadcrumb-action-types';

// Used for custom breadcrumbs
/**
 * @param {obj} breadcrumb
 * {
 *    entityId: id
 * }
 * @param {string} id Article Id
 */
export const addBreadcrumbT = (T, breadcrumb, id) => ({
    type: ADD_BREADCRUMB(T),
    data: breadcrumb,
    id,
});

export const deleteBreadcrumbT = (T, id) => ({
    type: DELETE_BREADCRUMB(T),
    id,
});

export const deleteBreadcrumbsT = (T, id) => ({
    type: DELETE_BREADCRUMBS(T),
    id
});

export const setBreadcrumbTitleT = (T, title, id) => ({
    type: SET_BREADCRUMB_TITLE(T),
    title,
    id,
});

export const deleteBreadcrumbTitleT = (T, id) => ({
    type: DELETE_BREADCRUMB_TITLE(T),
    id,
});

export const setBreadcrumbT = (T, breadcrumb, id) => ({
    type: SET_BREADCRUMB(T),
    breadcrumb,
    id,
});

export const setBreadcrumbsT = (T, list, entityId) => ({
    type: SET_BREADCRUMBS(T),
    list,
    entityId,
});

export const setBreadcrumbsErrorT = (T, error) => ({
    type: SET_BREADCRUMBS_ERROR(T),
    error,
});

export const fetchBreadcrumbs = (entityId, entityType) => (dispatch) => {
    dispatch({type: REQUEST_BREADCRUMBS(entityType)});
    ZApiClient
        .getClientFromType(entityType)
        .getBreadcrumbs(entityId)
        .then((res) => {
            if (res.httpStatus) {
                if (res.httpStatus === 404) dispatch(setBreadcrumbsT(entityType, [], entityId));
                else throw new Error();
            }
            dispatch(setBreadcrumbsT(entityType, res, entityId));
        })
        .catch(() => dispatch(setBreadcrumbsErrorT(entityType, true)))
};
