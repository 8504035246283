import React from 'react';
import style from './footer-section.module.scss';
import ZInput from '../../../../z-input';
import ZDraggableGrid from '../../../../z-draggable-grid.js';
import ZDeleteButton from '../../../../z-button-delete';
import { ReactComponent as AddSVG } from '../../../../../assets/logos/add.svg'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const onDragEnd = (result, list) => {
  let items = reorder(
    list,
    result.oldIndex,
    result.newIndex
  );
  return items
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 8 * 2,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : "none",

  // styles we need to apply on draggables
  ...draggableStyle
});

const FooterSectionView = ({
  links,
  index,
  title,
  id,
  onDeleteSection,
  onTitleChange,
  onAddLink,
  onLinkTitleChange,
  onLinkUriChange,
  onLinkOrderChange,
  onDeleteLink,
  readOnly,
}) => (
  <div className={`${style['footer-section']}`}>
    <div className={style['footer-section--header']}>
      <div className={style['footer-section--header--title']}>
        <ZInput
          inputKey={`section-title-${id}`} 
          label={`Section ${index + 1}`}
          onUpdate={onTitleChange}
          style={{backgroundColor: '#FFF', width: '50%'}}
          initialValue={title}
          placeholder="Section Title"
        />
        <ZDeleteButton onClick={onDeleteSection} />
      </div>
      <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
        <button 
          style={{display: 'flex', alignItems: 'center'}}
          type='button' 
          className={style['add-section__btn']} 
          onClick={onAddLink}
        >
          <AddSVG />
          Add Link
        </button>
      </div>
    </div>
    <br/>
    {links.length !== 0 && 
      <p>URL (Please provide a relative path)</p>
    }
    <div className={style['footer-section--link-wrapper']}>

      {links.length !== 0 &&
        <ZDraggableGrid 
          onDragEnd={(result)=>onLinkOrderChange(onDragEnd(result, links))}
          disabled={readOnly}
        >
          { links.map((link) => (
            <div className={style['footer-section--link']} key={`footer-link-${link.id}`}>
              <ZInput 
                inputKey={`footer-uri-${link.id}`}
                label="" 
                onUpdate={v => onLinkUriChange(link.id, v)}
                style={{backgroundColor: '#FFF', width: '45%'}}
                initialValue={link.uri}
                placeholder={`URI`}
              />
              <ZInput 
                inputKey={`footer-title-${link.id}`}
                label="" 
                onUpdate={v => onLinkTitleChange(link.id, v)}
                style={{backgroundColor: '#FFF', width: '45%', marginLeft: "16px"}}
                initialValue={link.title}
                placeholder={'Link Title'}
              />
              <ZDeleteButton onClick={() => onDeleteLink(link.id)} />
            </div>
          ))}
        </ZDraggableGrid>
      }
    </div>
  </div>
);

export default FooterSectionView;
