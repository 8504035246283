import React from 'react';
import { connect } from 'react-redux';
import LoginContainer from './login-container';
import { 
  loginRequest,
  loginError,
  loginSuccess,
  logout,
  setRoles,
 } from '../../state/actions/users';

const LoginModalRedux = ({
  error, 
  loading,  
  loginError,
  loginRequest,
  loginSuccess,
  logout,
  open, 
  setRoles,
  useOverlay, 
}) => (
  <LoginContainer
    error={error}
    loading={loading}
    loginError={loginError}
    loginRequest={loginRequest}
    loginSuccess={loginSuccess}
    logout={logout}
    open={open}
    setRoles={setRoles}
    useOverlay={useOverlay}
  />
);

const mapStateToProps = (state) => {
  const { login } = state.users;
  return ({
    error: login.error,
    loading: login.isFetching,
  });
}
const mapDispatchToProps = (dispatch) => ({
  loginError: (error) => dispatch(loginError(error)),
  loginRequest: () => dispatch(loginRequest()),
  loginSuccess: (username) => dispatch(loginSuccess(username)),
  logout: () => dispatch(logout()),
  setRoles: (roles) => dispatch(setRoles(roles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModalRedux);
