import React from 'react';
import ZSpinner from '../../z-spinner';
import OryxInputSearch from '../oryx-shared/oryx-input-search';
import OryxTooltip from '../oryx-shared/oryx-tooltip';
import { OryxSelect, OryxSelectOption } from '../oryx-shared/oryx-select-input';
import {
  OryxTable,
  OryxTableHeader,
  OryxTableRow,
  OryxTableCell,
} from '../oryx-shared/oryx-table';
import { OryxInputLabel, OryxInputWrapper } from '../oryx-shared/oryx-input/utils';
import ToTopButton from '../oryx-shared/oryx-arrow-button';
import CompaniesFilters from './companies-filters';
import { getFormattedLocations, getFormattedDate } from '../utils/formatting';
import { sortMap } from '../utils/companies';
import FetchStatus from '../utils/fetch-status';
import { ReactComponent as ZicassoSVG } from '../assets/svg/zicasso.svg';
import styles from './companies.module.scss';

const MAX_COUNTRIES_TO_DISPLAY = 3;
const MAX_DESCRIPTION_LENGTH = 50;

const PlaceHolderImage = () => (
  <div
    style={{
      height: '150px',
      maxWidth: '100px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ZicassoSVG/>
  </div>
);

const ProfileImage = ({url}) => (
  <img
    style={{
      height: '150px',
      maxWidth: '150px',
      display: 'inline-block',
    }}
    src={url}
    alt='profile'
  />
);

const Circle = ({
  color,
}) => (
  <div
    style={{
      width: '20px',
      height: '20px',
      border: '1px solid black',
      borderRadius: '100%',
      backgroundColor: color,
      margin: '0 auto',
    }}
  />
)

const matchLevelMap = {
  'PRIORITY_MATCH': 'Priority Match',
  'SECOND_MATCH': '2nd Match',
  'THIRD_OPTION': '3rd Option',
  'LAST_RESORT': 'Last Resort',
  'DO_NOT_MATCH': 'Do Not Match',
  'ON_HOLD': 'On Hold',
  'VIP_HIGH_BUDGET': 'VIP High Budget',
  'REPEAT': 'Repeat',
  'STANDARD': 'Standard',
  'LOW_END': 'Low End',
  'NEW': 'New',
  'EXCLUDED': 'Excluded',
}

const defaultProps = {
  offset: 0,
};

const getTruncatedString = (string, maxLength) => {
  if (string) {
    return string.length > maxLength
      ? `${string.slice(0, maxLength)}...`
      : string;
  }
  return '';
};

const CompaniesView = ({
  companyLocations,
  loadMoreStatus,
  loadStatus,
  offset,
  onChange,
  onSearchClear,
  onClickReset,
  onClickNameSort,
  onFilterChange,
  searchResults,
  searchTerm,
  searchTotal,
  sortOrder,
  onSortChange,
}) => (
  <div className={styles['company-wrapper']}>
    <div className={styles['company-search-row']}>
      <OryxInputSearch
        name="company-search"
        onChange={onChange}
        onClickClear={onSearchClear}
        placeholder="Search by Name, ID, or Country"
        value={searchTerm}
      />
      <OryxInputWrapper
        style={{
          position: 'relative',
          zIndex: 50,
        }}
      >
        <OryxInputLabel inputName="sortOptions">
          Sort By:
        </OryxInputLabel>
        <OryxSelect
          name="sortOptions"
          onChange={onSortChange}
          value={sortOrder}
          style={{appearance: 'none'}}
        >
          { Object.entries(sortMap).map(([value, label]) => (
            <OryxSelectOption key={value} value={value}>
              {label}
            </OryxSelectOption>
          ))}
        </OryxSelect>
      </OryxInputWrapper>
    </div>
    <CompaniesFilters
      companyLocations={companyLocations}
      onReset={onClickReset}
      onFilterChange={onFilterChange}
    />
    {(typeof searchTotal === 'number' && typeof offset === 'number') && (
      <p className={styles['search-total']}>
        Displaying&nbsp;
        {searchTotal > 50 ? Object.entries(searchResults).length : searchTotal}
        &nbsp;out of&nbsp;
        {searchTotal}
        &nbsp;records
      </p>
    )}
    {loadStatus === FetchStatus.ERROR && (
      <p className={styles.error}>
        There was an error, please try again.
      </p>
    )}
    {loadStatus === FetchStatus.FETCHING && (
      <div
        className={styles.spinner}
        style={(typeof searchTotal === 'number' && typeof offset === 'number')
          ? { height: 'calc(100vh - 320px)' }
          : {}}
      >
        <ZSpinner />
      </div>
    )}
    <OryxTable className={styles['company-table']}>
      <OryxTableRow className={styles['company-table-headers']}>
        <OryxTableHeader onSort={onClickNameSort} sortable style={{color: 'red'}}>
          TC ID / Name
        </OryxTableHeader>
        <OryxTableHeader style={{zIndex: 12 }}>
          Profile State
          <OryxTooltip contentClassName={styles['publish__tool-tip']}>
            <>
              <p className={styles.tooltip} style={{ whiteSpace: 'pre-wrap' }}>
                All conditions must be met in order for the page to be published 
              </p>
              <ul>
                <li>First Name <span className={styles.underline}>is not</span> blank</li>
                <li>Matching Priority Level <span className={styles.underline}>is not</span> Excluded</li>
                <li>Matching Priority Level <span className={styles.underline}>is not</span> New</li>
              </ul>
            </>
          </OryxTooltip>
        </OryxTableHeader>
        <OryxTableHeader>
          First Name
        </OryxTableHeader>
        <OryxTableHeader>
          Agency Photo
        </OryxTableHeader>
        <OryxTableHeader>
          Agency Description
        </OryxTableHeader>
        <OryxTableHeader style={{color: 'red'}}>
          Agency Based
        </OryxTableHeader>
        <OryxTableHeader style={{color: 'red'}}>
          Onboarded Date
        </OryxTableHeader>
        <OryxTableHeader style={{color: 'red'}}>
          Specialized Countries
        </OryxTableHeader>
        <OryxTableHeader style={{color: 'red'}}>
          Secondary Countries
        </OryxTableHeader>
        <OryxTableHeader style={{color: 'red', zIndex: 12 }}>
          Matching Priority Level
          <OryxTooltip>
            <>
              <br/>
              <p className={styles.tooltip} style={{ whiteSpace: 'pre-wrap' }}>
                Legacy D7 'Activity Level' Field
              </p>
              <br/>
            </>
          </OryxTooltip>
        </OryxTableHeader>
      </OryxTableRow>
      {searchResults && Object.entries(searchResults).map(([companyId, company]) => (
        <OryxTableRow
          className={styles['company-row']}
          key={companyId}
          overlayText="Updating..."
        >
          <OryxTableCell
            id={`${companyId}-nodename`}
            className={styles['wrap-text']}
          >
            <a
              href={`companies/${companyId}`}
              target='_blank'
              rel="noreferrer"
            >
              {`(${company.nodeId ? company.nodeId : '-' }) ${company.name ? company.name : '-' }`}
            </a>
          </OryxTableCell>
          <OryxTableCell
            id={`${companyId}-published`}
            className={styles['center-text']}
          >
            {company.published ? 'Published' : 'Unpublished'}
            <Circle
              color={company.published ? 'green' : 'red'}
            />
          </OryxTableCell>
          <OryxTableCell id={`${companyId}-firstName`}>
            {company.firstName || '-' }
          </OryxTableCell>
          <OryxTableCell id={`${companyId}-photo`}>
            { company.photo && company.photo.uri ?
              <ProfileImage
                url={company.photo.uri}
              />
              : <PlaceHolderImage/>
            }
          </OryxTableCell>
          <OryxTableCell
            id={`${companyId}-description`}
            className={styles['wrap-text']}
          >
            {company.description ? getTruncatedString(company.description, MAX_DESCRIPTION_LENGTH)
              : '-'}
          </OryxTableCell>
          <OryxTableCell id={`${companyId}-location`}>
            {company.country || '-'}
          </OryxTableCell>
          <OryxTableCell id={`${companyId}-onboarded`}>
            {company.partnerSince ? getFormattedDate(company.partnerSince) : '-'}
          </OryxTableCell>
          <OryxTableCell
            id={`${companyId}-prim-location`}
            className={styles['wrap-text']}
          >
            {(company.primaryLocations && company.primaryLocations.length) ? getFormattedLocations(company.primaryLocations, MAX_COUNTRIES_TO_DISPLAY)
              : '-'}
            { company.primaryLocations?.length > MAX_COUNTRIES_TO_DISPLAY && (
              <OryxTooltip>
                <p className={styles.tooltip} style={{ whiteSpace: 'pre-wrap' }}>
                  {getFormattedLocations(company.primaryLocations)}
                </p>
              </OryxTooltip>
            )}
          </OryxTableCell>
          <OryxTableCell
            id={`${companyId}-sec-location`}
            className={styles['wrap-text']}
          >
            {(company.secondaryLocations && company.secondaryLocations.length) ? getFormattedLocations(company.secondaryLocations, MAX_COUNTRIES_TO_DISPLAY)
              : '-'}
            { company.secondaryLocations?.length > MAX_COUNTRIES_TO_DISPLAY && (
              <OryxTooltip>
                <p className={styles.tooltip} style={{ whiteSpace: 'pre-wrap' }}>
                  {getFormattedLocations(company.secondaryLocations)}
                </p>
              </OryxTooltip>
            )}
          </OryxTableCell>
          <OryxTableCell>
            {company.activityLevel ? matchLevelMap[company.activityLevel] : '-'}
          </OryxTableCell>
        </OryxTableRow>
      ))}
    </OryxTable>
    {loadMoreStatus === FetchStatus.FETCHING && (
      <div className={styles['spinner__load-more']}>
        <ZSpinner />
      </div>
    )}
    <ToTopButton />
  </div>
);


CompaniesView.defaultProps = {
  ...defaultProps,
  onClickReset: () => {},
};

export default CompaniesView;
