
export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = (modalId, closeAction) => ({
  type: OPEN_MODAL,
  modalId,
  closeAction,
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = (modalId) => ({
  type: CLOSE_MODAL,
  modalId,
});

export const onCloseModal = (modalId) => (dispatch, getState) => {
  const { closeAction } = getState().modals[modalId];
  if (!!closeAction) dispatch(closeAction());
  dispatch(closeModal(modalId));
};
