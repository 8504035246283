import React from 'react';
import NewsPage from '../../../components/news-page';

const News = (props) => {
  return(
    <NewsPage {...props}></NewsPage>
  );
}

export default News;
