import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

/**
 * Primary use case is tags search
 * @param {*} props
 * @returns autocomplete input
 */
const TagsInput = ({
  options,
  value,
  onChange,
  disabled,
}) => (
  <Autocomplete
    fullWidth
    disabled={disabled}
    options={options}
    id="tags"
    value={value}
    onChange={(event, newValue) => {
      onChange(newValue);
    }}
    renderInput={(params) => (
      <TextField {...params} label="Search By Tag" variant="standard" />
    )}
  />
);

export default TagsInput;
