import React from 'react';
import ZTextArea from '../../../z-text-area';

/**
 * 
 * @TODO Resolve erros 
 *  - day errors 
 */

const DatesView = ({
  departureNotes,
  departureNotesError,
  onUpdateDeparture,
}) => {
  return (
    <>
      <ZTextArea 
        allowedFormats={[]} 
        label="Departure Dates" 
        value={departureNotes} 
        onUpdate={onUpdateDeparture} 
        inputKey={'overview__departureDates'}
        stripTags
        error={
          departureNotesError && !departureNotesError.valid ? 
          departureNotesError : 
          false
        }
      />
      <br/>
    </>
  )
}

export default DatesView;
