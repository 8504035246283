import {
  CAREERS_REQUEST,
  CAREERS_ERROR,
  CAREERS_RECEIVED,
  CAREER_SAVE_REQUEST,
  SET_CAREER,
  CAREER_SAVE_ERROR,
  SET_DISPLAYED,
  SET_CAREER_TITLE,
  SET_CAREER_LOCATION,
  SET_CAREER_URI,
  CREATE_NEW_CAREER,
} from './action-types';
import ZApiClient from "../../../utils/client/z-api-client";
const CareersClient = ZApiClient.getCareersClient();


const careersRequest = (isFetching) => ({
  type: CAREERS_REQUEST,
  isFetching,
});

const careersError = (error) => ({
  type: CAREERS_ERROR,
  error,
});

const careersReceived = (allCareers) => ({
  type: CAREERS_RECEIVED,
  receivedAt: Date.now(),
  allCareers,
});

export const saveCareerRequest = (id, isSaving) => ({
  type: CAREER_SAVE_REQUEST,
  id,
  isSaving,
});

export const setCareer = (career) => ({
  type: SET_CAREER,
  career,
});

export const saveError = (id, error) => ({
  type: CAREER_SAVE_ERROR,
  id,
  error,
});

export const setDisplayed = (id, displayed) => ({
  type: SET_DISPLAYED,
  id,
  displayed,
});

export const setCareerTitle = (id, title) => ({
  type: SET_CAREER_TITLE,
  id,
  title,
});

export const setCareerLocation = (id, location) => ({
  type: SET_CAREER_LOCATION,
  id,
  location,
});

export const setCareerUri = (id, url) => ({
  type: SET_CAREER_URI,
  id,
  url,
});

export const createNew = () => ({
  type: CREATE_NEW_CAREER,
});

const createOrUpdateCareer = (data, create = true) => {
  if (create) {
    return CareersClient.createCareer(data);
  } else {
    return CareersClient.updateCareer(data);
  }
};

export const fetchCareers = () => (dispatch) => {
  dispatch(careersRequest(true));
  CareersClient.getCareers({ limit: 1000})
    .then((res) => {
      if (res.httpStatus) {
        throw new Error(res.rootCause);
      }
      dispatch(careersReceived(res));
    })
    .catch(() => dispatch(careersError(true)))
    .finally(() => dispatch(careersRequest(false)));
};

export const saveCareer = (careerId, onSaveSuccess) => {
  return (dispatch, getState) => {
    dispatch(saveCareerRequest(careerId, true));
    const shouldCreate = (careerId === 'new');
    const { careers } = getState();
    const careerEntity = careers[careerId];
    createOrUpdateCareer(careerEntity, shouldCreate)
      .then((res) => {
        if (res.httpStatus) {
          throw new Error(res.rootCause);
        }
        dispatch(setCareer(res));
      })
      .then(() => {
        dispatch(fetchCareers());
        if (onSaveSuccess) onSaveSuccess();
      })
      .catch((e) => {
        console.error('Error Saving Career', e);
        console.error(e.stack);
        dispatch(saveError(careerId, true));
      })
      .finally(() => dispatch(saveCareerRequest(careerId, false)));
  }
};

export const deleteCareer = (id) => {
  return CareersClient.deleteCareer(id)
    .then((res) => {
      if (res.httpStatus) {
        return 'career doesnt exist';
      }
      return null;
    })
    .catch((e) => {
      console.error(e);
    });
};
