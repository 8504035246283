import React from 'react';
import ZTextArea from '../../z-text-area';
import Photos from './photos';
import ZChips from '../../z-chips';
import ZAutoComplete from '../../z-autocomplete';

/**
 * 
 * @TODO REFACTOR ERRORS
 *  - places visited 
 *  - countries
 *  - departureNotes
 *  - summary
 */

const ContentView = ({
  id,
  summary,
  summaryError,
  onUpdateSummary,
  autoCompletePlaces,
  onSelectPlace,
  onDeletePlace,
  placesVisited,
  placesVisitedError,
}) => {
  return (
    <>
      <br/>
      <div style={{ borderBottom: '2px solid rgba(255, 145, 0,0.5)' }}>
        <h3>Places Visited</h3>
        <ZAutoComplete 
          autoComplete={autoCompletePlaces}
          onSelect={onSelectPlace}
          label='Places Visited - Add a Location'
          style={{marginBottom: '14px'}}
          allowCustomValues={true}
          inputKey={'overview__placesVisited'}
          error={
            placesVisitedError && !placesVisitedError.valid ? 
            placesVisitedError :
            false
          }
        />
        {placesVisited.length !== 0 && 
          <ZChips 
            data={placesVisited.map((ele)=>{return {label: ele}})} 
            onChange={onDeletePlace} 
            style={{marginBottom: '14px'}}
          />
        }
      </div>
      <br/>
      <ZTextArea 
        allowedFormats={['ITALICS', 'LINKS']} 
        label="Marketing Summary" 
        value={summary} 
        onUpdate={onUpdateSummary} 
        inputKey={'overview__marketingSummary'}
        error={
          summaryError && !summaryError.valid ? 
          summaryError :
          false
        }
      />
      <br/>
      <Photos
        entityId={id}
      />
    </>
  )
}

export default ContentView
