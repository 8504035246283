import React from 'react';
import Career from './career';
import ZSpinner from '../z-spinner';
import ZModal from '../z-modal';
import FilterDisplayed from '../shared/components/filter-displayed';
import CareerEditor from './career-editor';
import sharedStyle from '../shared/styles/shared.module.scss';
import style from './careers.module.scss';

const CareersView = ({
  closeModal,
  displayed,
  editCareerId,
  isFetching,
  careers,
  onClickCreateCareer,
  onClickDisplayed,
  onClickEdit,
}) => (
  <>
    <div className={style['career__wrapper']}>
      <header className={style['career__header']}>
        <h1 className={style['career__title']}>Careers at Zicasso</h1>
        <div className={style['filters']}>
          <button className={style['filters-button']} onClick={onClickCreateCareer} type="button">
            Create New
          </button>
          <FilterDisplayed
            onChange={onClickDisplayed}
            value={displayed}
          />
          { careers && 
            <p className={style['filters__total']}>
              {careers.length} career{careers.length === 1 ? '' : 's'}
            </p>
          }
          
        </div>
      </header>
      <main className={style['career']}>
        <div className={style['careers']}>
          {isFetching && (
            <div className={sharedStyle['spinner']}>
              <ZSpinner/>
            </div>
          )}
          {careers && careers.map((career) => (
            <Career
              key={career.id}
              onClick={() => onClickEdit(career)}
              openInEditor={career.id === editCareerId}
              {...career}
            />
          ))}
        </div>
      </main>
    </div>
    <ZModal
      closeable
      open={!!editCareerId}
      style={{ 
        width: '900px',
        minHeight: '300px',
      }}
      toggleModal={closeModal}
      useOverlay
    >
      <CareerEditor
        id={editCareerId}
        key="careerEditor"
        closeModal={closeModal}
        onClickEdit={onClickEdit}
      />
    </ZModal>
  </>
);

export default CareersView;
