import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import config from '../../../../config';
import styles from './photo-gallery.module.scss';
import ZImage from '../../../z-image';
const imagesEndpoint = config.IMAGES_ENDPOINT;

const PhotoItem = memo(({
  alt,
  gravity,
  index,
  title,
  uri,
}) => {
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  return (
    <ZImage
      alt={alt || ''}
      className={`
        ${styles.photos__item}
        ${loaded ? '' : styles.photos__itemHidden}
        ${error && styles.photos__itemError}
      `}
      cloudinarySrc={error ? imagesEndpoint : uri}
      crop={error ? 'fit' : 'fill'}
      gravity={error ? null : gravity || 'custom'}
      height={105}
      id={`${index}`}
      lazy
      onError={() => setError(true)}
      onLoad={() => setLoaded(true)}
      title={title || null}
      width={105}
    />
  );
});
PhotoItem.propTypes = {
  alt: PropTypes.string,
  gravity: PropTypes.string,
  index: PropTypes.number.isRequired,
  title: PropTypes.string,
  uri: PropTypes.string.isRequired,
};
PhotoItem.defaultProps = {
  alt: null,
  gravity: null,
  title: null,
};
PhotoItem.displayName = 'PhotoItem';

export default PhotoItem;
