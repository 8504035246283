export const SET_META_TITLE = "SET_META_TITLE";
export const setMetaTitle = (text, id) =>({
  type: SET_META_TITLE,
  value: text,
  id
});

export const SET_META_DESCRIPTION = "SET_META_DESCRIPTION";
export const setMetaDescription = (text, id) =>({
  type: SET_META_DESCRIPTION,
  value: text,
  id
});

export const SET_NOINDEX = "SET_NOINDEX";
export const setNoindex = (value, id) => ({
  type: SET_NOINDEX,
  value,
  id,
});

export const SET_NOFOLLOW = "SET_NOFOLLOW";
export const setNofollow = (value, id) => ({
  type: SET_NOFOLLOW,
  value,
  id,
});

export const SET_CANONICAL = "SET_CANONICAL";
export const setCanonical = (text, id) => ({
  type: SET_CANONICAL,
  value: text,
  id,
});

export const SET_META_DATA = "SET_META_DATA";
