import React from 'react';
import styles from './oryx-table.module.scss';

const defaultProps = {
  children: '',
};

const OryxCellPopOut = ({
  children,
  maxChars,
}) => (
  <>
    {children && children.length > maxChars ? (
      <div className={styles.popout__wrapper}>
        <div className={styles.popout__truncated} style={{ maxWidth: `${maxChars * 9}px` }}>
          {children}
        </div>
        <div className={styles.popout} style={{ left: `${maxChars * 10}px` }}>
          {children}
        </div>
      </div>
    ) : children}
  </>
);

OryxCellPopOut.defaultProps = defaultProps;

export default OryxCellPopOut;
