import {getJSON} from './fetchJson'
import config from "../../config";

const baseUrlAPI = config.API_URL;

const getLink = (id, type) => {
  let entityType;
  switch (type) {
    case 'Article':
    case 'Articles':
    case 'article':
    case 'articles':
      entityType = 'article';
      break;
    case 'Guide':
    case 'Guides':
    case 'guide':
    case 'guides':
      entityType = 'guide';
      break;
    case 'LandingPage':
    case 'LandingPages':
    case 'landing_page':
    case 'landing_pages':
    case 'landingPage':
    case 'landingPages':
      entityType = 'landing_page';
      break;
    case 'Itinerary':
    case 'Itineraries':
    case 'itinerary':
    case 'itineraries':
      entityType = 'itinerary';
      break;
    case 'CompanyCategory':
    case 'CompanyCategories':
    case 'company_category':
    case 'company_categories':
    case 'companyCategory':
    case 'companyCategories':
      entityType = 'company_category';
      break;
    case 'LandingPageCategory':
    case 'LandingPageCategories':
    case 'landing_page_category':
    case 'landing_page_categories':
    case 'landingPageCategory':
    case 'landingPageCategories':
      entityType = 'landing_page_category';
      break;
    default:
      break;
  }
  const url = `${baseUrlAPI}/${entityType}_links/${id}`
  return getJSON(url);
}

export default getLink;
