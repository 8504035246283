import {
  LOGO_ERROR,
  LOGO_REQUEST,
  NEWS_SOURCES_ERROR,
  NEWS_SOURCES_REQUEST,
  SET_LOGO,
  SET_NEWS_SOURCE,
  SET_NEWS_SOURCES,
  SET_NEWS_SOURCE_NAMES,
  SET_NAME,
  CREATE_NEW,
  DELETE_LOGO
} from '../actions/news-editor/news-sources';

const initialLogoState = {
  isFetching: false,
  error: false,
};

const logo = (state = initialLogoState, action) => {
  switch(action.type) {
    case LOGO_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case LOGO_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_LOGO:
      return {
        ...state,
        ...action.logo,
      };
    case DELETE_LOGO:
      return {
        ...initialLogoState,
      };
    default:
      return state;
  }
};

const initialNewsSourceState = {
  name: '',
  logo: { ...initialLogoState },
};

const newsSource = (state = initialNewsSourceState, action) => {
  switch(action.type) {
    case SET_NEWS_SOURCE:
      return {
        ...state,
        ...action.newsSource,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case CREATE_NEW:
      return {
        ...initialNewsSourceState,
      };
    case LOGO_REQUEST:
    case LOGO_ERROR:
    case SET_LOGO:
    case DELETE_LOGO:
      return {
        ...state,
        logo: logo(state.logo, action),
      };
    default:
      return state;
  }
};

const newsSources = (
  state = {
    error: false,
    isFetching: false,
    newsSourceNames: {},
    allNewsSources: [],
  },
  action,
) => {
  switch(action.type) {
    case NEWS_SOURCES_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case NEWS_SOURCES_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_NEWS_SOURCES:
      return {
        ...state,
        allNewsSources: action.allNewsSources,
      };
    case SET_NEWS_SOURCE_NAMES:
      return {
        ...state,
        newsSourceNames: action.newsSourceNames,
      };
    case SET_NEWS_SOURCE:
      return {
        ...state,
        [action.newsSource.id]: newsSource(state[action.newsSource.id], action)
      };
    case LOGO_REQUEST:
    case LOGO_ERROR:
    case SET_LOGO:
    case SET_NAME:
    case DELETE_LOGO:
      return {
        ...state,
        [action.newsSourceId]: newsSource(state[action.newsSourceId], action),
      };
    case CREATE_NEW:
      return {
        ...state,
        new: newsSource(null, action),
      };
    default:
      return state;
  }
};

export default newsSources;
