import React from 'react';
import PropTypes from 'prop-types';
import PhotoItem from './photo-gallery-item';
import LightboxCarousel from '../../lightbox';
import styles from './photo-gallery.module.scss';

const PhotoGalleryView = ({
  images,
  lightboxIndex,
  maxPhotos,
  onClickGallery,
  photoDistance,
  setShowLightbox,
  showLightbox,
}) => (
  <>
    <div className={styles.photos__itemsWrapper}>
      {images.slice(0, maxPhotos).map((image, index) => (
        <div
          aria-label="See larger image"
          onClick={onClickGallery}
          onKeyUp={onClickGallery}
          role="button"
          tabIndex={0}
          className={styles.photos__item}
          id={index}
          key={`photo-${image.id || image.uri}`}
        >
          <PhotoItem
            alt={image.alt}
            gravity={image.gravity}
            index={index}
            // eslint-disable-next-line react/no-array-index-key
            key={`photo-${image.uri}-${index}`}
            title={image.title}
            uri={image.uri}
          />
          {index === maxPhotos - 1 && images.length > maxPhotos && (
            <div
              className={`${styles.photos__item} ${styles.photos__itemCover}`}
              style={{
                left: `${(100 + photoDistance) * (maxPhotos - 1)}px`,
              }}
            >
              <p className={styles.photos__itemCoverText}>
                +{images.length - maxPhotos}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
    {showLightbox
      && (
        <LightboxCarousel
          lightboxIndex={lightboxIndex}
          items={images}
          setShowLightbox={setShowLightbox}
        />
      )}
  </>
);
PhotoGalleryView.propTypes = {
  lightboxIndex: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  maxPhotos: PropTypes.number.isRequired,
  onClickGallery: PropTypes.func.isRequired,
  photoDistance: PropTypes.number.isRequired,
  setShowLightbox: PropTypes.func.isRequired,
  showLightbox: PropTypes.bool.isRequired,
};

export default PhotoGalleryView;
