import ZApiClient from '../client/z-api-client';

export const fetchTuid = () => (
  ZApiClient.getTuidsClient().createTuid()
);

/**
 * Fetches multiple Tuids from the API.
 * Will return 10 Tuids by default, if no limit param is provided.
 * 
 * @param {number} [limit] - The maximum number of Tuids to fetch (optional).
 * @returns {Promise<Tuid[]>} A promise that resolves to an array of Tuids.
 */
export const fetchTuids = (limit) => (
  ZApiClient.getTuidsClient().getTuids({ limit })
);
