import {
  getAllPaths,
  createPath,
  deletePath,
} from '../../utils/path/path-utils';
import {
  // getCanonical
  setCanonical as metaTagsSetCanonical
} from './meta-tags';
import ZApiClient from '../../utils/client/z-api-client';

export const SET_PATHS = 'SET_PATHS';
export const DELETE_PATH_ERROR = 'DELETE_PATH_ERROR'
export const CLEAR_PATH_ERROR = 'CLEAR_PATH_ERROR'

export const setPaths = (entityId, paths) => ({
  type: SET_PATHS,
  entityId,
  paths,
});

export const fetchPaths = (id) => {
  return dispatch => {
    return getAllPaths(id).then((res) => {
      dispatch(setPaths(id, res));
    }).catch((e) => {
      console.error('Error Fetching Paths', e);
      console.error(e.stack);
    });
  }
};

export const createNewPath = (id, type, newPath, redirect = true) => {
  return dispatch => {
    const body = {
      entityId: id,
      entityType: type,
      alias: newPath,
      redirect: redirect
    };
    return createPath(body)
      .then((paths)=>{
        dispatch(setPaths(id, paths, type));
      });
  }
};

export const deleteSelectedPath = (entityId, pathId, entityType) => {
  return dispatch => {
    return deletePath(entityId, pathId)
      .then((paths) => {
        dispatch(setPaths(entityId, paths, entityType));
    })
    .catch(() => {
      dispatch(deletePathError())
    })
  }
};

export const deletePathError = () => ({
  type: 'DELETE_PATH_ERROR',
  error: true,
});

export const clearPathError = () => ({
  type: 'CLEAR_PATH_ERROR',
  error: false,
});

export const setCanonical = (entityId, pathId, entityType) => {
  return dispatch => {
    return ZApiClient.getPathsClient().setCanonical(pathId)
    .then((paths) => {
      dispatch(setPaths(entityId, paths, entityType));
      const canonicalPath = paths.find(p => !p.redirect);
      const canonical = `https://www.zicasso.com/${canonicalPath.alias}`;
      dispatch(metaTagsSetCanonical(canonical, entityId));
    })
    .catch(err => console.error(err))
  }
};
