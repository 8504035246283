export const decodeHTMLEntities = (text) => {
  if (text && text.includes('&')) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  } else {
    return text;
  }
};

export const navigate = (params) => {
  const baseUrl = new URL(window.location.href);
  window.open(`${baseUrl.origin}/${params}`);
};

export const onCopy = (text) => {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = text;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
};


