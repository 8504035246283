import React from 'react';
import { connect } from 'react-redux';
import FaqContainer from './faq-section-container';
import {
  setFAQSections,
  setFAQTitle,
} from '../../../state/actions/landing-page-editor';

const FaqSectionRedux = (props) => {
  return (
    <FaqContainer {...props}/>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const {editor} = state.landingPages.landingPage;
  const currentEntity = editor[id] || {};
  const faqSection = currentEntity.faq || {};
  const sections = faqSection.sections || [];
  return {
    sections,
    sectionCount: sections.length || 0,
    faqTitle: faqSection.title || '',
    faqErrors: {},
    readOnly: state.versions.readOnly,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    updateFaqs : (sections) => dispatch(setFAQSections(id, sections)),
    updateTitle : (title) => dispatch(setFAQTitle(id, title)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqSectionRedux);
