import ZApiClient from '../client/z-api-client';

export const fetchEntityPublications = (entityId, offset = 0, limit = 100) => {
  const params = {
    entity: entityId,
    limit,
    offset,
    reverse: true,
  }
  return ZApiClient.getPublicationsClient().readPublications(params);
};

export const fetchEntityPublication = (publicationId) => (
  ZApiClient.getPublicationsClient().readPublication(publicationId)
);
