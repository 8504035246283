export const ADD_HEADER_THUMB = (T) => `ADD_HEADER_THUMB_${T.toUpperCase()}`;
export const DISABLE_SAVE = (T) => `DISABLE_SAVE_${T.toUpperCase()}`;
export const ENABLE_SAVE = (T) => `ENABLE_SAVE_${T.toUpperCase()}`;
export const PHOTO_ERROR = (T) => `PHOTO_ERROR_${T.toUpperCase()}`;

export const PHOTO_RECEIVED = (T) => `PHOTO_RECEIVED_${T.toUpperCase()}`;
export const PHOTO_REQUEST = (T) => `PHOTO_REQUEST_${T.toUpperCase()}`;
export const RECEIVED_PHOTOS = (T) => `RECEIVED_PHOTOS_${T.toUpperCase()}`;
export const REMOVE_GALLERY_PHOTO = (T) => `REMOVE_GALLERY_PHOTO_${T.toUpperCase()}`;

export const REMOVE_HEADER_PHOTO = (T) => `REMOVE_HEADER_PHOTO_${T.toUpperCase()}`;
export const REMOVE_HEADER_THUMB = (T) => `REMOVE_HEADER_THUMB_${T.toUpperCase()}`;
export const REMOVE_MOBILE_HEADER_PHOTO = (T) => `REMOVE_MOBILE_HEADER_PHOTO_${T.toUpperCase()}`;
export const REMOVE_SECTION_PHOTO = (T) => `REMOVE_SECTION_PHOTO_${T.toUpperCase()}`;

export const SET_GALLERY = (T) => `SET_GALLERY_${T.toUpperCase()}`;
export const SET_GALLERY_PHOTO = (T) => `SET_GALLERY_PHOTO_${T.toUpperCase()}`;
export const SET_HEADER_PHOTO = (T) => `SET_HEADER_PHOTO_${T.toUpperCase()}`;
export const SET_MOBILE_HEADER_PHOTO = (T) => `SET_MOBILE_HEADER_PHOTO_${T.toUpperCase()}`;

export const SET_SECTION_PHOTO = (T) => `SET_SECTION_PHOTO_${T.toUpperCase()}`;
export const UPDATE_PHOTO = (T) => `UPDATE_PHOTO_${T.toUpperCase()}`;

export const PHOTO_ACTION_NAMES = {};

(() => {
    [
        'article',
        'company_category',
        'landing_page_category',
        'content',
        'guide',
        'itinerary',
        'landing_page'
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        PHOTO_ACTION_NAMES[entityType] = {};

        PHOTO_ACTION_NAMES[entityType]['ADD_HEADER_THUMB'] = ADD_HEADER_THUMB(entityType);
        PHOTO_ACTION_NAMES[entityType]['DISABLE_SAVE'] = DISABLE_SAVE(entityType);
        PHOTO_ACTION_NAMES[entityType]['ENABLE_SAVE'] = ENABLE_SAVE(entityType);
        PHOTO_ACTION_NAMES[entityType]['PHOTO_ERROR'] = PHOTO_ERROR(entityType);

        PHOTO_ACTION_NAMES[entityType]['PHOTO_RECEIVED'] = PHOTO_RECEIVED(entityType);
        PHOTO_ACTION_NAMES[entityType]['PHOTO_REQUEST'] = PHOTO_REQUEST(entityType);
        PHOTO_ACTION_NAMES[entityType]['RECEIVED_PHOTOS'] = RECEIVED_PHOTOS(entityType);
        PHOTO_ACTION_NAMES[entityType]['REMOVE_GALLERY_PHOTO'] = REMOVE_GALLERY_PHOTO(entityType);

        PHOTO_ACTION_NAMES[entityType]['REMOVE_HEADER_PHOTO'] = REMOVE_HEADER_PHOTO(entityType);
        PHOTO_ACTION_NAMES[entityType]['REMOVE_HEADER_THUMB'] = REMOVE_HEADER_THUMB(entityType);
        PHOTO_ACTION_NAMES[entityType]['REMOVE_MOBILE_HEADER_PHOTO'] = REMOVE_MOBILE_HEADER_PHOTO(entityType);
        PHOTO_ACTION_NAMES[entityType]['REMOVE_SECTION_PHOTO'] = REMOVE_SECTION_PHOTO(entityType);

        PHOTO_ACTION_NAMES[entityType]['SET_GALLERY'] = SET_GALLERY(entityType);
        PHOTO_ACTION_NAMES[entityType]['SET_GALLERY_PHOTO'] = SET_GALLERY_PHOTO(entityType);
        PHOTO_ACTION_NAMES[entityType]['SET_HEADER_PHOTO'] = SET_HEADER_PHOTO(entityType);
        PHOTO_ACTION_NAMES[entityType]['SET_MOBILE_HEADER_PHOTO'] = SET_MOBILE_HEADER_PHOTO(entityType);

        PHOTO_ACTION_NAMES[entityType]['SET_SECTION_PHOTO'] = SET_SECTION_PHOTO(entityType);
        PHOTO_ACTION_NAMES[entityType]['UPDATE_PHOTO'] = UPDATE_PHOTO(entityType);
    });
})();
