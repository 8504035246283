import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PhotoPreviewView from './content-photo-preview-view';
import useDebounce from '../../../../hooks/use-debounce';
import Validate from '../../../../utils/validate';

const PhotoPreviewContainer = ({
  entityId,
  photo,
  photoId,
  onRemovePhoto,
  onChangePhoto,
  onCreatePhoto,
  showInfo,
  style,
}) => {
  const placeHolder = 'https://res.cloudinary.com/zicasso/image/upload/v1545258210/Logo-%20Marketing%20Materials/PNG/Zicasso_Logo_no_sub_black_small.png';
  const [formValues, setFormValues] = useState(photo);
  const [fileName, setFileName] = useState();
  const [validUri, setValidUri] = useState(true);
  const loadedRef = useRef(false);
  const changedRef = useRef(false);
  const [debouncedValue] = useDebounce(formValues, 500, changedRef);

  useEffect(() => {
    const { sourceType, sourceUri } = photo;
    if (!!sourceUri && sourceType === 'Cloudinary') {
      const splitFile = sourceUri.endsWith('.jpg')
        ? sourceUri.slice(0, -4).split('/')
        : sourceUri.split('/');
      const { length } = splitFile;
      setFileName(`${splitFile[length-2]}/${splitFile[length-1]}`);
    }
  }, [photo]);

  useEffect(() => {
    if (formValues.id !== photoId) {
      setFormValues(photo)
    }
  }, [photoId, formValues, photo]);

  useEffect(() => {
    if (!photoId && loadedRef.current) {
      onCreatePhoto(entityId, debouncedValue);
      changedRef.current = false;
    } else if (!!photoId && changedRef.current) {
      onChangePhoto(debouncedValue);
      changedRef.current = false;
    };
  }, [debouncedValue, entityId, photoId, onCreatePhoto, onChangePhoto]);

  useEffect(() => {
    if (!!formValues.uri) {
      const valid = Validate.imageUrl(formValues.uri);
      setValidUri(valid);
    } else {
      setValidUri(true);
    }
  }, [formValues.uri]);

  const onChange = (label, text) => {
    setFormValues((f) => ({
      ...f,
      [label]: text,
    }));
    changedRef.current = true;
  }

  const removePhoto = () => {
    onRemovePhoto(entityId, photoId);
    changedRef.current = false;
    loadedRef.current = false;
  }

  const onLoad = () => {
    const { alt, title, uri } = formValues;
    if (!!uri && validUri) {
      loadedRef.current = true;
      if (changedRef.current) changedRef.current = !!alt || !!title;
    }
  }

  return (
    <PhotoPreviewView 
      alt={formValues.alt}
      fileName={fileName}
      gravity={formValues.gravity}
      info={formValues}
      onChange={onChange}
      onLoad={onLoad}
      photoId={photoId}
      placeHolder={placeHolder}
      readOnly={!!formValues.sourceUri}
      removePhoto={removePhoto}
      showDelete={!!formValues.sourceUri}
      showInfo={showInfo}
      src={formValues.uri} 
      style={{ ...style }} 
      title={formValues.title}
      validUri={validUri}
    />
  );
}
PhotoPreviewContainer.propTypes = {
  photo: PropTypes.object,
  photoId: PropTypes.string,
  onRemovePhoto: PropTypes.func.isRequired,
  onChangePhoto: PropTypes.func.isRequired,
  onCreatePhoto: PropTypes.func.isRequired,
  showInfo: PropTypes.bool,
  style: PropTypes.object,
};
PhotoPreviewContainer.defaultProps = {
  photo: {},
  photoId: undefined,
  showInfo: true,
  style: {},
};

export default PhotoPreviewContainer;
