import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PhotoPreviewView from './content-photo-preview-view';
import useDebounce from '../../../../hooks/use-debounce';
import Validate from '../../../../utils/validate';

const PhotoPreviewContainer = ({
  entityId,
  isCCP,
  photo,
  photoId,
  onRemovePhoto,
  onChangePhoto,
  onCreatePhoto,
  showInfo,
  setUseParentImage,
  style,
  transformation,
}) => {
  const placeHolder = 'https://res.cloudinary.com/zicasso/image/upload/v1545258210/Logo-%20Marketing%20Materials/PNG/Zicasso_Logo_no_sub_black_small.png';
  const [formValues, setFormValues] = useState(photo);
  const [fileName, setFileName] = useState();
  const [validUri, setValidUri] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const changedRef = useRef(false);
  const [debouncedValue] = useDebounce(formValues, 500);

  useEffect(() => {
    const { sourceType, sourceUri } = photo;
    if (!!sourceUri && (sourceType === 'Cloudinary')) {
      const splitFile = sourceUri.endsWith('.jpg')
        ? sourceUri.slice(0, -4).split('/')
        : sourceUri.split('/');
      const { length } = splitFile;
      setFileName(`${splitFile[length-2]}/${splitFile[length-1]}`);
    }
  }, [photo]);

  useEffect(() => {
    if(validUri && changedRef.current && debouncedValue.uri) {
      if (!photoId) {
        onCreatePhoto(entityId, debouncedValue);
        setIsLoading(true);
        changedRef.current = false;
      } else if (!!photoId) {
        onChangePhoto(debouncedValue);
        changedRef.current = false;
      };
    }
  }, [debouncedValue, entityId, photoId, onCreatePhoto, onChangePhoto, validUri]);

  // This useEffect is used to update the state when a user manually deletes
  // the text from the URL input field.
  useEffect(() => {
      if (debouncedValue.uri === '') {
        changedRef.current = false;
        setValidUri(true);
        // This logic removes a undefined photo entry when a URL is invalid and
        // a user removes the url text from the input field.
        photo.error && onRemovePhoto(entityId, photoId);
      }
  }, [debouncedValue.uri, entityId, onRemovePhoto, photo.error, photoId])

  useEffect(() => {
    if(photo.error) {
      setIsLoading(false);
    }
  }, [photo.error])

  const checkIfValidURI= (uri) => {
    const encodedURI = encoder(uri)
    if (Validate.imageUrl(encodedURI)) {
      setFormValues((prev) => ({
        ...prev,
        uri: encodedURI
      }))
      setValidUri(true)
    } else {
      setValidUri(false)
    }
  };
  
  const onChange = (label) => {
    return (
      (text) => {
        setFormValues((f) => ({
          ...f,
          [label]: text,
        }));
        changedRef.current = true;
        if (label === 'uri') {
          checkIfValidURI(text);
        }
      }
    )
  }

  const encoder = (uri) => {
    const decoded = decodeURI(uri)
    return encodeURI(decoded);
  }

  const removePhoto = () => {
    onRemovePhoto(entityId, photoId);
    changedRef.current = false;
  }

  const onLoad = () => {
    if (formValues.id !== photoId) {
      setFormValues(photo);
    }
    setIsLoading(false)
  }

  const handleClickDelete = () => {
    removePhoto();
    setUseParentImage(true);
  }

  return (
    <PhotoPreviewView
      alt={formValues.alt}
      error={photo.error}
      fileName={fileName}
      gravity={formValues.gravity}
      isCCP={isCCP}
      info={formValues}
      isFetching={isLoading}
      onClickDelete={handleClickDelete}
      onChange={onChange}
      onLoad={onLoad}
      photoId={photoId}
      placeHolder={placeHolder}
      readOnly={!!formValues.sourceUri}
      removePhoto={removePhoto}
      showDelete={!!formValues.sourceUri}
      showInfo={showInfo}
      src={photo.uri}
      styles={{ ...style }}
      title={formValues.title}
      transformation={transformation}
      validUri={validUri}
    />
  );
}
PhotoPreviewContainer.propTypes = {
  isCCP: PropTypes.bool,
  photo: PropTypes.object,
  photoId: PropTypes.string,
  onRemovePhoto: PropTypes.func.isRequired,
  onChangePhoto: PropTypes.func.isRequired,
  onCreatePhoto: PropTypes.func.isRequired,
  showInfo: PropTypes.bool,
  useParentImage: PropTypes.bool,
  style: PropTypes.object,
};
PhotoPreviewContainer.defaultProps = {
  isCCP: false,
  photo: {},
  photoId: undefined,
  showInfo: true,
  useParentImage: false,
  style: {},
};

export default PhotoPreviewContainer;
