import {
    createImage,
    updateImage
} from '../../../utils/image/image-utils';
import {
    ADD_HEADER_THUMB,
    DISABLE_SAVE,
    ENABLE_SAVE,
    PHOTO_ERROR,
    PHOTO_RECEIVED,
    PHOTO_REQUEST,
    RECEIVED_PHOTOS,
    REMOVE_GALLERY_PHOTO,
    REMOVE_HEADER_PHOTO,
    REMOVE_HEADER_THUMB,
    REMOVE_MOBILE_HEADER_PHOTO,
    REMOVE_SECTION_PHOTO,
    SET_GALLERY,
    SET_GALLERY_PHOTO,
    SET_HEADER_PHOTO,
    SET_MOBILE_HEADER_PHOTO,
    SET_SECTION_PHOTO,
    UPDATE_PHOTO,
} from './photo-action-types';

export const disableSaveT = (T, message = null) => ({
    type: DISABLE_SAVE(T),
    message,
});

export const enableSaveT = (T) => ({
    type: ENABLE_SAVE(T),
});

export const receivedPhotosT = (T, data) => ({
    type: RECEIVED_PHOTOS(T),
    photos: data,
});

export const photoRequestT = (T, photoId) => ({
    type: PHOTO_REQUEST(T),
    photoId,
});

export const photoReceivedT = (T, photoId) => ({
    type: PHOTO_RECEIVED(T),
    photoId,
});

export const photoErrorT = (T, photoId) => ({
    type: PHOTO_ERROR(T),
    photoId,
});

export const setHeaderPhotoT = (T, entityId, data) => ({
    type: SET_HEADER_PHOTO(T),
    entityId,
    photo: data,
});

export const removeHeaderPhotoT = (T, entityId, photoId) => ({
    type: REMOVE_HEADER_PHOTO(T),
    entityId,
    photoId,
});

export const setSectionPhotoT = (T, sectionId, data) => ({
    type: SET_SECTION_PHOTO(T),
    sectionId,
    photo: data,
});

export const removeSectionPhotoT = (T, sectionId, photoId) => ({
    type: REMOVE_SECTION_PHOTO(T),
    sectionId,
    photoId,
});

export const updatePhotoDataT = (T, photo) => ({
    type: UPDATE_PHOTO(T),
    photo,
});

export const setGalleryPhotoT = (T, entityId, photo, index) => ({
    type: SET_GALLERY_PHOTO(T),
    photo,
    entityId,
    index,
});

export const removeGalleryPhotoT = (T, entityId, photoId) => ({
    type: REMOVE_GALLERY_PHOTO(T),
    entityId,
    photoId,
});

export const setGalleryT = (T, entityId, list) => ({
    type: SET_GALLERY(T),
    entityId,
    list,
});

export const removeHeaderThumbT = (T, entityId) => ({
    type: REMOVE_HEADER_THUMB(T),
    entityId,
});

export const addHeaderThumbT = (T, entityId, photo) => ({
    type: ADD_HEADER_THUMB(T),
    entityId,
    photo,
});

export const setMobileHeaderPhotoT = (T, entityId, data) => ({
    type: SET_MOBILE_HEADER_PHOTO(T),
    entityId,
    photo: data,
});

export const removeMobileHeaderPhotoT = (T, entityId, photoId) => ({
    type: REMOVE_MOBILE_HEADER_PHOTO(T),
    entityId,
    photoId,
});

/**
 *
 * @param {string} type
 * @param {object} data
 */
export const updatePhotoT = (T, data) => {
    return dispatch => {
        dispatch(disableSaveT(T,'Saving Photo'));
        return updateImage(data).then((photo) => {
            dispatch(updatePhotoDataT(T, photo));
            dispatch(enableSaveT(T));
        });
    };
};

// regex to check for 400, 500, or 600 status code.
const badStatusCode = /(?!([23]0[0-9]))\d{3}/;

/**
 *
 * @param {string} type
 * @param {object} data
 */
export const createPhotoT = (T, entityId, type, data, index) => {
  return dispatch => {
        dispatch(disableSaveT(T, 'Saving Photo'));
        return createImage(data).then((photo) => {
            if (badStatusCode.test(photo.httpStatus)) {
                throw new Error('Error fetching URL');
            }
            dispatch(photoReceivedT(T, photo.id));
            if (type === 'header') {
                dispatch(setHeaderPhotoT(T, entityId, {...data, ...photo}));
            }
            if (type === 'mobileHeader') {
                dispatch(setMobileHeaderPhotoT(T, entityId, {...data, ...photo}));
            }
            if (type === 'section') {
                dispatch(setSectionPhotoT(T, entityId, {...data, ...photo}));
            }
            if (type === 'gallery') {
                dispatch(setGalleryPhotoT(T, entityId, {...data, ...photo}, index));
            }
            dispatch(enableSaveT(T));
        })
            .catch(() => dispatch(photoErrorT(T, data.id)));
    };
};
