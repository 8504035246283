import React, { useState } from 'react';
import ZAutoComplete from '../../../../z-autocomplete';
import ZApiClient from '../../../../../utils/client/z-api-client';
import getLink from '../../../../../utils/fetch/links';
import ZButton from '../../../../z-button';
import ZRadioGroup from '../../../../z-radio-group';
import style from './editor.module.scss';

const entityOptions = [
  {
    label: 'Landing Page',
    value: 'LandingPage',
  },
  {
    label: 'Itinerary',
    value: 'Itinerary',
  },
  {
    label: 'Guide',
    value: 'Guide',
  },
  {
    label: 'Article',
    value: 'Article',
  },
  {
    label: 'Agency Category',
    value: 'CompanyCategory',
  },
];

const AddGalleryLink = ({
  closeModal,
  onSelectLink,
  setFetchStatus,
}) => {
  const [searchEntityType, setSearchEntityType] = useState('LandingPage');

  const onSearch = (text) => {
    const searchTerm = text.toLowerCase();
    return ZApiClient
      .getClientFromType(searchEntityType)
      .getAllTitlesWithIdsByNameFragment(searchTerm);
  };

  const autoCompleteOnSelect = (val) => {
    const [entityId] = Object.entries(val)[0];
    setFetchStatus('loading');
    getLink(entityId, searchEntityType)
      .then((res) => {
        const { summary, id, ...rest } = res;
        onSelectLink(rest);
        setFetchStatus();
      })
      .catch(() => setFetchStatus('error'));
  };

  const onChangeRadio = (e) => {
    setSearchEntityType(e.target.value);
  };

  return (
    <>
      <ZAutoComplete
        autoComplete={onSearch}
        onSelect={autoCompleteOnSelect}
        returnsObject
        label='Add a link'
        style={{ marginBottom: '14px' }}
      />
      <ZRadioGroup
        name="searchEntity"
        onChange={onChangeRadio}
        options={entityOptions}
        value={searchEntityType}
      >
        Entity Type:
      </ZRadioGroup>
      <div className={style['editor__row']}>
        <ZButton
          btnClass={style['editor__button']}
          onClick={closeModal}
          text="Cancel"
        />
      </div>
    </>
  );
};

export default AddGalleryLink;
