import { OPEN_MODAL, CLOSE_MODAL } from '../actions/modals';

const modals = (state = {
  open: false,
}, action) => {
  switch(action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        [action.modalId]: {
          open: true,
          closeAction: action.closeAction,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [action.modalId]: { open: false },
      };
    default:
      return state;
  }
};

export default modals;
