import ZApiClient from "../client/z-api-client";
import LocalStorageService from "../local-storage-service";
import config from "../../config";

const tripRequestsClient = ZApiClient.getTripRequestsClient();

const LocalStorage = new LocalStorageService();

const unauthorizedRedirect = (destination) => {
  const dest = destination ? `?destination=/${destination}` : '';
  window.location.href = `/login${dest}`;
};
  
export const getTripRequestAnnouncement = () => (
  tripRequestsClient.getTripRequestAnnouncement()
    .then((res) => {
      if ('httpStatus' in res) {
        if (res.httpStatus === 401) unauthorizedRedirect('/trip-request-settings');
        throw new Error('Error creating trip request');
      }
      return res 
    })
);

export const postTripRequestAnnouncement = (trfAnnouncement) => {
  const accessToken = LocalStorage.getFromLocalStorage('accessToken');

  return fetch(`${config.API_URL}/trip_request_announcement`, {
    credentials: 'include',
    headers: {
    authorization: `Bearer ${accessToken}`,
    },
    method: "Put",
    body: JSON.stringify(trfAnnouncement),
  })
    .then((res) => res.json())
    .then((json) => {
    if ('httpStatus' in json) {
        if (json.httpStatus === 401) unauthorizedRedirect('/trip-request-settings');
    }
    return json;
    })
    .catch((e) => console.warn(e));
};

