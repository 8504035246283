import { connect } from 'react-redux';
import EditorContainer from './editor-container';
import {
  createPhotoT,
  removeGalleryPhotoT,
  updatePhotoT,
} from '../../../../../state/actions/shared/photo';

const mapStateToProps = (state, ownProps) => {
  const photoId = ownProps.galleryLink?.image?.id;
  return ({
    disabledMessage: state.landingPages.landingPage.editor.disabledMessage,
    photo: state.landingPages.landingPage.photos[photoId],
    photoId,
    saveDisabled: state.landingPages.landingPage.editor.saveDisabled,
  });
};

const mapDispatchToProps = (dispatch) => ({
  createPhoto: (entityId, imageData, index) => dispatch(createPhotoT('landing_page', entityId, 'gallery', imageData, index)),
  removePhoto: (entityId, imageId) => dispatch(removeGalleryPhotoT('landing_page', entityId, imageId)),
  updatePhoto: (imageData) => dispatch(updatePhotoT('landing_page', imageData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorContainer);


