import React from 'react';
import ZToggle from '../../../z-toggle';
import ZInput from '../../../z-input';
import ZModal from '../../../z-modal';
import ZDraggableContainer from '../../../z-draggable-new';
import GalleryLinkEditor from './editor';
import { AddGalleryLink, ConfirmDelete, GalleryLink } from './gallery-links-items';
import style from './gallery-links.module.scss';

const GalleryLinkView = ({
  closeModal,
  editLinkIndex,
  entityId,
  links,
  onAddLink,
  onChangeLink,
  onChangeToggle,
  onClickConfirmDelete,
  onClickGalleryLink,
  onDragEnd,
  onRemoveLink,
  setGalleryLinksTitle,
  showDeleteConfirmation,
  title,
  useGalleryLinks,
}) => (
  <section className={style.gallery}>
    {useGalleryLinks && (
      <ZInput
        label='Gallery Section Title'
        onChange={setGalleryLinksTitle}
        placeholder="Please enter a title"
        text={title}
        wrapperClass={style['gallery__title']}
      />
    )}
    <ZToggle
      checked={useGalleryLinks}
      label={`${useGalleryLinks ? 'Delete' : 'Add'} Gallery Section`}
      onChange={onChangeToggle}
      style={{ width: '280px', marginLeft: 'auto' }}
      toggleClass={style['gallery__toggle']}
    />
    {useGalleryLinks && (
      <>
        <ZDraggableContainer isGrid onDragEnd={onDragEnd}>
          {links.map((link, i) => (
            <GalleryLink
              index={i}
              key={link.entityId}
              link={link}
              onClickGalleryLink={onClickGalleryLink}
              onRemoveLink={onRemoveLink}
            />
          ))}
        </ZDraggableContainer>
        <AddGalleryLink
          lastIndex={links.length}
          onClickGalleryLink={onClickGalleryLink}
        />
      </>
    )}
    <ZModal
      closeable
      open={editLinkIndex !== undefined || showDeleteConfirmation}
      style={{
        width: 'fit-content',
        minWidth: '500px',
      }}
      toggleModal={closeModal}
      useOverlay
    >
      {editLinkIndex && (
        <GalleryLinkEditor
          closeModal={closeModal}
          entityId={entityId}
          galleryLink={links[editLinkIndex]}
          index={editLinkIndex}
          onAddLink={onAddLink}
          onChangeLink={onChangeLink}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmDelete
          closeModal={closeModal}
          onClickConfirmDelete={onClickConfirmDelete}
        />
      )}
    </ZModal>
  </section >
);

export default GalleryLinkView;
