import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import AuthRouter from '../auth';
import Header from '../header';
import routes from '../../routes/private';
import './app.scss';

function App( { history } ) {

  return (
    <ConnectedRouter history={history}>\
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <AuthRouter>
          { routes }
        </AuthRouter>
      </ThemeProvider>
    </ConnectedRouter>
  );
}


export default App;
