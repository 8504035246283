import React from 'react';
import Day from './day';
import ZDraggableGrid from '../../z-draggable-grid.js';
import ZAddButton from '../../z-button-add';
import sharedStyle from '../../shared/styles/shared.module.scss';

/**
 * 
 * @TODO Resolve erros 
 *  - day errors 
 */

const CustomItineraryView = ({
  tripDuration,
  days,
  dayErrors,
  onDragEnd,
  readOnly,
  updateDay,
  addDay,
  onDurationChange,
  onDeleteDay,
}) => {
  return (
    <>
      <br/>
      <h2>Customizable Itinerary</h2>
      { (dayErrors && !dayErrors.valid) && 
        <p className={sharedStyle['error']}>{dayErrors.message}</p>
      }
      {/* {props.errors.day && 
        <p className={sharedStyle['error']}>{'Please see errors below.'}</p>
      } */}
      <p>{`Total Duration: ${tripDuration}`}</p>
      <div>
        <ZAddButton onClick={addDay}>Add Day</ZAddButton>
      </div>
      {days.length !== 0 &&
        <ZDraggableGrid onDragEnd={onDragEnd} disabled={readOnly}>
          { days.map((ele, index) => (
            <Day 
              key={ele.id}
              id={ele.id}
              day={{...ele}} 
              // errors={props.errors.day ? props.errors.day : {}}
              index={index} 
              onUpdate={updateDay} 
              inputKey={ele.id}
              addDay={addDay}
              removeDay={onDeleteDay}
              onDurationChange={onDurationChange}
            />
          ))}
        </ZDraggableGrid>
      }
    </>
  )
}

export default CustomItineraryView;
