import React from 'react';
import style from './paths.module.scss';
import sharedStyle from '../../../shared/styles/shared.module.scss';
import ZInput from '../../../z-input';
import ZTable from '../../../z-table';
import ZModal from '../../../z-modal';
import ZDeleteButton from '../../../z-button-delete';
import ZSpinner from '../../../z-spinner';

const PathsView = ({
  btnText,
  entityPaths,
  error,
  fetching,
  newPath,
  onAddPath,
  onCancelDelete,
  onConfirmDelete,
  onCloseModal,
  onPathChange,
  onSelectCanonicalPathId,
  onSelectPathToDelete,
  onUpdatePaths,
  pathErrors,
  pathToDelete,
  selectedCanoncialPathId,
  showModal,
  toggleShowModal,
}) => (
  <>
    <h3>Url Paths & Redirects</h3> 
    <div className={style['path-editor']}>
      { entityPaths && entityPaths.length === 0 ? 
        <p>Suggested Path:</p> : <p>Create a new path:</p>
      }
      <ZInput
        onChange={onPathChange} 
        text={newPath} 
        error={(pathErrors && !!newPath) ? pathErrors : false}
        style={{flexGrow:'2', padding: '0 14px'}} 
        inputKey={'admin__addPath'}
      />
      <button 
        type="button" 
        className={sharedStyle['button']} 
        onClick={onAddPath}
      >
        {btnText}
      </button>
    </div>
    { entityPaths && entityPaths.length > 0 && (
      <>
        {fetching && (
          <div className={style['loading']}>
            <ZSpinner />
          </div>
        )}
        <ZTable 
          data={entityPaths} 
          headers={['alias', 'redirect', 'pingCount', 'lastPing']} 
          style={{padding: '14px 7px'}}
        />
        <div style={{display:'flex', justifyContent: 'center'}}>
          <button 
            className={[`${sharedStyle.button} ${sharedStyle.editPathButton}`]} 
            type='button' 
            onClick={toggleShowModal}
            style={{width: '140px'}}
          >
            Edit Canonical or Delete Path
          </button>
        </div>
      </>
    )}
    <ZModal
      closeable
      open={showModal}
      style={{padding: '28px'}}
      toggleModal={toggleShowModal}
      useOverlay
    >
      {!pathToDelete &&
        <>
          <div className={style['modal__heading']}>
            <h2>Change Canonical Path</h2>
            <p>The selected alias is the canonical path</p>
          {(!!error) &&
            <p className={sharedStyle['error']}>{'You cannot delete the last path on a published itinerary.'}</p>
          }
          </div>
          {entityPaths && entityPaths.map((path, i) => {
            return (
              <div className={style['checkbox-item']} key={path.id}>
                <input 
                  type='radio'
                  id='pathRedirect'
                  name="pathRedirect"
                  value={path.id}
                  checked={selectedCanoncialPathId === path.id} 
                  onChange={onSelectCanonicalPathId} 
                  className={style['checkbox']}
                />
                <label htmlFor='pathRedirect'>{path.alias}</label>
                <ZDeleteButton
                  disabled={fetching}
                  onClick={onSelectPathToDelete} 
                  value={path.id} 
                  className={style['delete']}
                />
              </div>
            )
          })}
          <div className={sharedStyle['modal__buttons']} style={{display: 'flex', justifyContent: 'center'}}>
            <button 
              type="button" 
              className={sharedStyle['button']} 
              disabled={fetching}
              onClick={onUpdatePaths}
            >
              Update Path
            </button>
            <button 
              type="button" 
              className={sharedStyle['button']} 
              disabled={fetching}
              onClick={onCloseModal}
            >
              Close
            </button>
          </div>
        </>
      }
      {
        /**
         * @TOOD ADD last Path check 
         */
      }
      {pathToDelete && 
        <>
          <h3>Are you sure?</h3>
          {/* {(props.published && articlePaths.length === 1) &&
            <p className={sharedStyle['error']}>{'You cannot delete the last path on a published itinerary.'}</p>
          } */}
          <div style={{display: 'flex'}}>
            <button 
              type="button" 
              disabled={fetching}
              onClick={onConfirmDelete} 
              className={sharedStyle['button']} 
              // disabled={props.published && props.paths.length === 1}
            >
              Confirm Delete
            </button>
            <button 
              type="button" 
              disabled={fetching}
              onClick={onCancelDelete} 
              className={sharedStyle['button']}
            >
              Cancel
            </button>
          </div>
        </>
      }
      {fetching && (
        <div className={style['loading']}>
          <ZSpinner />
        </div>
      )}
    </ZModal>
  </>
);

export default PathsView;
