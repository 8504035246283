import React, {useState, useEffect, forwardRef, useImperativeHandle}  from 'react';
import TextField from '@mui/material/TextField';
import useDebounce from "../../../../hooks/use-debounce";

const getLabel = (entityType) => {
  const test = entityType?.toLowerCase();
  switch (test) {
    case 'article':
      return '...Loading Articles';
    case 'guide':
      return '...Loading Guides';
    case 'itinerary':
      return '...Loading Itineraries';
    case 'landingpage':
      return '...Loading Landing Pages'
    case 'companycategory':
      return '...Loading Agency Categories';
    case 'content':
      return '...Loading Content Pages';
    default:
      return '...Loading';
  }
}

/**
 * Example of parent reset function 
 * const onResetClick = () => {
 *   searchInputRef.current.setTerm('');
 * }
 */

const SearchInput = forwardRef(({
  receivedAll,
  // onChange should be a memoized function. See react useCallback hook.
  onChange,
  entityType,
}, ref) => {
  const [value, setValue] = useState('');
  const [deboucedTerm] = useDebounce(value, 500);
  
  // Hands imperative control to the ref provider.
  // Allows parent componet to set the value of the input if needed, i.e. a reset function;
  useImperativeHandle(ref, () => ({
    setValue,
  }));
 
  useEffect(() => {
    onChange(deboucedTerm);
  }, [deboucedTerm, onChange]);

  return (
    <TextField
      type="search"
      fullWidth
      variant="standard" 
      id="search"
      label={receivedAll ? "Search by name" : getLabel(entityType)}
      value={value || ''}
      onChange={(e) => setValue(e.target.value)}
    />
  )
});

export default SearchInput;
