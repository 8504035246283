import React, { useState, useEffect } from 'react';
import {
  generatePath,
  validatePath,
  checkForExistingPath,
} from '../../../../utils/path/path-utils';
import PathsView from './paths-view';

const PathsContainer = ({
  entityId,
  entityPaths,
  entityTitle,
  error,
  fetchPaths,
  onAddNewPath,
  onConfirmPathEdits,
  onDeletePath,
  pathPrefix,
  onClearError,
}) => {
  const defaultBtnText = 'Create Path';

  const [btnText, setBtnText] = useState(defaultBtnText);
  const [fetching, setFetching] = useState(false);
  const [newPath, setNewPath] = useState('');
  const [pathErrors, setPathErrors] = useState(false);
  const [pathToDelete, setPathToDelete] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedCanoncialPathId, setSelectedCanonicalPathId] = useState();

  useEffect(() => {
    if (!entityPaths) {
      fetchPaths();
    }
  }, [fetchPaths, entityPaths]);

  useEffect(()=>{
    if(!!entityPaths && entityPaths.length === 0){
      const suffix = entityTitle || entityId;
      const tempPath = generatePath(pathPrefix, entityPaths, suffix.toLowerCase())
      setNewPath(tempPath);
    } else if (!!entityPaths && entityPaths.length > 0) {
      setSelectedCanonicalPathId(entityPaths.find((p) => !p.redirect).id);
    }
  }, [entityPaths, entityTitle, entityId, pathPrefix]);

  const toggleShowModal = () => (setShowModal(!showModal));

  const onPathChange = (text) => {
    setNewPath(text)
    if(pathErrors){
      setPathErrors();
    }
  }

  const onAddPath = async () => {
    setBtnText('validating...');
    const valid = validatePath(newPath, pathPrefix, entityPaths);
    if(!valid){
      setPathErrors({message: 'Invalid Path Format or Path Already Exist'});
      setBtnText(defaultBtnText);
      return;
    }
    const exists = await checkForExistingPath(newPath);
    if(!!exists){
      setPathErrors({message: 'Path Already Exist'});
      setBtnText(defaultBtnText);
      return;
    }
    setBtnText('Adding Path...');
    setFetching(true);
    await onAddNewPath(newPath);
    setFetching(false);
    setBtnText(defaultBtnText);
    setNewPath('');
  }

  const onSelectPathToDelete = (val) => {
    const pathId = val.currentTarget.value;
    setPathToDelete(pathId);
  }

  const onSelectCanonicalPathId = (val) => {
    const pathId = val.currentTarget.value;
    setSelectedCanonicalPathId(pathId);
  }

  const onCancelDelete = () => {
    setPathToDelete();
  }

  const onConfirmDelete = async () => {
    setFetching(true);
    await onDeletePath(pathToDelete);
    setFetching(false);
    setPathToDelete();
  }

  const onUpdatePaths = async () => {
    setFetching(true);
    await onConfirmPathEdits(selectedCanoncialPathId);
    setFetching(false);
    if (error) onClearError();
    toggleShowModal();
  }

  const onCloseModal = () => {
    if (error) onClearError();
    toggleShowModal();
  }

  return (
    <PathsView
      btnText={btnText}
      entityPaths={entityPaths}
      error={error}
      fetching={fetching}
      newPath={newPath}
      onAddPath={onAddPath}
      onCancelDelete={onCancelDelete}
      onConfirmDelete={onConfirmDelete}
      onCloseModal={onCloseModal}
      onPathChange={onPathChange}
      onSelectCanonicalPathId={onSelectCanonicalPathId}
      onSelectPathToDelete={onSelectPathToDelete}
      onUpdatePaths={onUpdatePaths}
      pathErrors={pathErrors}
      pathToDelete={pathToDelete}
      selectedCanoncialPathId={selectedCanoncialPathId}
      showModal={showModal}
      toggleShowModal={toggleShowModal}
    />
  )
}

export default PathsContainer;
