import { useEffect, useState } from 'react';
import { logout } from '../state/actions/users';
import LocalStorageService from '../utils/local-storage-service';
import useApiClient from './use-api-client';
import ZApiClient from '../utils/client/z-api-client';

const LocalStorage = new LocalStorageService();

const useLogout = (dispatch, triggerLogout = true) => {
  const apiClient = useApiClient();
  const [logoutNow, setLogoutNow] = useState(triggerLogout);

  const removeToken = () => {
    LocalStorage.removeFromLocalStorage('accessToken');
    LocalStorage.removeFromLocalStorage('email');
    ZApiClient.setApiClientToken(null);
  };
  
  useEffect(() => {
    const removeAuthenticatedTeamMember = () => apiClient.authClient.logout()
      .catch(console.error)
      .finally(() => {
        removeToken();
        dispatch(logout());
      });

    if (logoutNow) {
      const accessToken = LocalStorage.getFromLocalStorage('accessToken');

      if (accessToken) {
        removeAuthenticatedTeamMember();
      } else {
        removeToken();
        dispatch(logout());
      }
    }
  }, [dispatch, logoutNow]);

  return [setLogoutNow];
};

export default useLogout;
