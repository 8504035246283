import React from 'react';
import ZInput from '../../../z-input';
import ZTextArea from '../../../z-text-area';
import ZAddButton from '../../../z-button-add';
import style from './faq.module.scss';
import { decodeHTMLEntities } from '../../../../utils/window';
import ZDeleteButton from '../../../z-button-delete';

const FaqView = ({
  focusTitle,
  id,
  onAddClick,
  onDeleteClick,
  onTextChange,
  onTitleChange,
  showConfirmation,
  text,
  title,
  toggleConfirmation,
}) => {
  return (
    <div className={style['faq']}>
      {!showConfirmation && 
        <>
          <div style={{width: '95%', maxWidth: '95%'}}>
            <ZInput 
              style={{marginBottom: '28px'}} 
              label='Title' 
              className={style['faq-title']} 
              onChange={onTitleChange} 
              focus={focusTitle} 
              text={decodeHTMLEntities(title)} 
              inputKey={`${id}__title`}/>
            <ZTextArea 
              allowedFormats={['ITALICS','BOLD', 'LINKS', 'BULLET_POINTS']} 
              label="Description" 
              onUpdate={onTextChange} 
              value={text} 
              inputKey={`${id}__description`}
              openInternalLinksInNewTab
            ></ZTextArea>
          </div>
          <div 
            style={{
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              width: '5%', 
              maxWidth: '5%'
            }}
          >
            <ZDeleteButton onClick={toggleConfirmation} />
            <ZAddButton onClick={onAddClick} />
          </div>
        </>
      }
      {showConfirmation && 
        <div>
          <h3 className={style['confirmation']}>Are you sure?</h3>
          <button 
            className={style['confirmation__button']}
            onClick={onDeleteClick} 
            type="button" 
          >
            Delete
          </button>
          <button 
            className={style['confirmation__button']}
            onClick={toggleConfirmation} 
            type="button" 
          >
            Cancel
          </button>
        </div>
      }
    </div>
  )
} 

export default FaqView;
