import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import CompanyCategoryContainer from './company-category-container';
import { toggleReadOnly } from '../../state/actions/versions';
import { validateTitleT } from '../../state/actions/shared/error';
import {
  fetchCompanyCategoryIfNeeded,
  saveCompanyCategory,
} from '../../state/actions/company-category-editor';

const mapStateToProps = (state, ownProps) => {
  const { companyCategories, published, versions } = state;
  const { id, updateId: paramUpdateId } = ownProps?.match?.params;
  const { editor, errors } = companyCategories;
  const {
    isFetching,
    notFound,
    saved,
    saveDisabled,
    disabledMessage,
  } = editor;

  return {
    currentUpdateId: editor[id]?.updateId,
    disabledMessage,
    entity: editor[id],
    errors: errors[id],
    id,
    isFetching,
    notFound,
    paramUpdateId,
    readOnly: versions.readOnly,
    saved,
    saveDisabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match } = ownProps;
  const { params } = match;
  const { id } = params;
  return {
    onCreateNew: () => dispatch(push('/create/companyCategory')),
    onLoad: (updateId) => dispatch(fetchCompanyCategoryIfNeeded(id, updateId)),
    onSave: () => dispatch(saveCompanyCategory(id)),
    onTitleChange : text => dispatch(validateTitleT('company_category', text, id)),
    onToggleReadOnly: () => dispatch(toggleReadOnly()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCategoryContainer);
