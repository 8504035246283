import LocalStorageService from '../../../utils/local-storage-service';
import ZApiClient from '../../../utils/client/z-api-client';
import config from '../../../config';

const companiesClient = ZApiClient.getCompaniesClient();
const companySearchClient = ZApiClient.getCompanySearchClinet();

export const defaultSearch = {
  filters: {
    activityLevels: ['PRIORITY_MATCH', 'SECOND_MATCH', 'THIRD_OPTION', 'LAST_RESORT', 'DO_NOT_MATCH'],
  },
  sortOrders: [
    {
      field: 'updatedAt',
      order: 'DESC',
    }
  ],
};

export const sortMap = {
  relevance: 'Relavance',
  newToOld: 'New to Old',
  oldToNew: 'Old to New',
  nameAToZ: 'Name A to Z',
  nameZToA: 'Name Z to A',
}

export const sortOrderOptions = {
  newToOld: {
    field: 'updatedAt',
    order: 'DESC',
  },
  oldToNew: {
    field: 'updatedAt',
    order: 'ASC',
  },
  nameAToZ: {
    field: 'name',
    order: 'ASC',
  },
  nameZToA: {
    field: 'name',
    order: 'DESC',
  }
};

export const matchLevelMap = {
  'PRIORITY_MATCH': 'Priority Match',
  'SECOND_MATCH': '2nd Match',
  'THIRD_OPTION': '3rd Option',
  'LAST_RESORT': 'Last Resort',
  'DO_NOT_MATCH': 'Do Not Match',
  'ON_HOLD': 'On Hold',
  'VIP_HIGH_BUDGET': 'VIP High Budget',
  'REPEAT': 'Repeat',
  'STANDARD': 'Standard',
  'LOW_END': 'Low End',
  'NEW': 'New',
  'EXCLUDED': 'Excluded',
}

const unauthorizedRedirect = (destination) => {
  const dest = destination ? `?destination=/${destination}` : '';
  window.location.href = `/login${dest}`;
};

export const fetchCompany = (id) => (
  companiesClient.getCompanyById(id)
    .then((res) => {
      if ('httpStatus' in res) {
        if (res.httpStatus === 401) unauthorizedRedirect(`companies/${id}`);
        throw new Error('Error fetching companies');
      }
      return res;
    })
);

export const createCompanySearch = (params) => (
  companySearchClient.createCompanySearch(params).then((res) => {
    if ('httpStatus' in res) {
      if (res.httpStatus === 401) unauthorizedRedirect('companies');
    }
    return res;
  })
);

export const fetchCompanySearch = (id, offset) => (
  companySearchClient.readCompanySearch(
    id,
    {
      ...offset && { offset },
      limit: 50,
    },
  )
    .then((res) => {
      if ('httpStatus' in res) {
        if (res.httpStatus === 401) unauthorizedRedirect(`companies?search=${id}`);
        throw new Error('Error fetching company search');
      }
      return res;
    })
);

export const mapCompanyArrayToObject = (companies) => {
  const arrayByIds = {};
  for (let i = 0; i < companies.length; i += 1) {
    const company = companies[i];
    arrayByIds[company.id] = company;
  }
  return arrayByIds;
};

export const saveCompany = (company) => {
  return companiesClient.updateCompany(company);
}

export const syncCompany = (d7NodeId)=> {
  const LocalStorage = new LocalStorageService();
  const userToken = LocalStorage.getFromLocalStorage('accessToken');
  return new Promise((resolve) => {
    fetch(
      `${config.SDK_URL}/d7/companies/${d7NodeId}?import=true`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => resolve(res.json()));
  })
}

/**
 * 
 * @param sortOption 
 *  {
 *    field: 'updatedAt', fistName
 *  }
 * @returns string
 */
export const getCompanySortValue = (sortOption) => {
  let res = 'relevance';
  if (sortOption) {
    for (const [sortValue, sortObject] of Object.entries(sortOrderOptions)) {
      if (sortOption.field === sortObject.field && sortOption.order === sortObject.order) {
        res = sortValue;
      }
    }
  }
  return res;
}

export const getCompanySortOrders = (sortOrder) => {
  const res = [];
  if (sortOrder !== 'relevance') {
    res.push(sortOrderOptions[sortOrder]);
  }
  return res;
}
