import React from 'react';
import { connect } from 'react-redux';
import {
  setType,
} from '../../../../state/actions/article-editor';
import TypeContainer from './type-container';
import {setExpirationDateIsValidT, setExpirationDateT} from "../../../../state/actions/shared/expiration";

const TypeRedux = (props) => (
  <TypeContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {articleId:id} = ownProps;
  const articleEntities = state.articles.article;
  const {editor} = articleEntities;
  const currentArticle = editor[id]
  const { type, expirationDate } = currentArticle;
  return({
    type,
    expirationDate
  });
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {articleId:id} = ownProps;
  return({
    onTypeChange: (type) => dispatch(setType(type, id)),
    onDateChange: (date) => dispatch(setExpirationDateT('article', date, id)),
    setExpirationDateIsValid: (boolean) => dispatch(setExpirationDateIsValidT('article', !boolean)),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeRedux);
