import { push } from 'connected-react-router';
import { LandingPageCategory } from '@zicasso/zicasso-sdk/lib/landing';
import { fetchTuid } from '../../../utils/fetch/tuid';
import LandingPageCategoryClient from "../../../utils/client/landing-page-category";
import ZApiClient from '../../../utils/client/z-api-client';
import { getLandingPageSnippet } from '../../../utils/fetch/landing-page';
import {receivedPhotosT} from '../shared/photo';
import {
  fetchEntityVersion,
  addSavedEntityVersion,
  versionSuccess,
} from '../versions';
import {
  fetchEntityVersion as UTILS_fetchEntityVersion,
} from '../../../utils/versions/versions-utils';

import {
  REQUEST_LANDING_PAGE_CATEGORY,
  REQUEST_NEW_LANDING_PAGE_CATEGORY,
  RECEIVED_LANDING_PAGE_CATEGORY,
  LANDING_PAGE_CATEGORY_SAVE_REQUESTED,
  LANDING_PAGE_CATEGORY_SAVED,
  REQUEST_LANDING_PAGE_CATEGORY_FAILED,
} from '../shared/similar-action-types';
import {
  SET_LANDING_PAGE_SNIPPET,
} from './action-types';
import {
  receivedSectionsT
} from '../shared/section';

const landingPageCategoryClient = new LandingPageCategoryClient();

export const requestLandingPageCategory = () => ({
  type: REQUEST_LANDING_PAGE_CATEGORY,
});

export const requestNewLandingPageCategory = () => ({
  type: REQUEST_NEW_LANDING_PAGE_CATEGORY,
});

export const receivedLandingPageCategory = (entity) => ({
  type: RECEIVED_LANDING_PAGE_CATEGORY,
  receivedAt: Date.now(),
  id: entity.id,
  entity,
});

export const saveRequested = () => ({
  type: LANDING_PAGE_CATEGORY_SAVE_REQUESTED,
});

export const landingPageCategorySaved = (data) => ({
  type: LANDING_PAGE_CATEGORY_SAVED,
  data
});

export const landingPageCategoryRequestFailed = () => ({
  type: REQUEST_LANDING_PAGE_CATEGORY_FAILED,
});

export const setLandingPageSnippet = (landingPageSnippets, id) => {
  return {
    type: SET_LANDING_PAGE_SNIPPET,
    landingPageSnippets,
    id,
  };
};

const createOrUpdateLandingPageCategory = (data, create = true) =>{
  if(create){
    return ZApiClient.getLandingPageCategoriesClient().createLandingPageCategory(new LandingPageCategory(data));
  } else {
    return ZApiClient.getLandingPageCategoriesClient().updateLandingPageCategory(new LandingPageCategory(data));
  }
};

function shouldFetchLandingPageCategory(state, id, updateId) {
  const { editor } = state.landingPageCategories;
  if (!editor[id]) {
    return true
  } else if (editor[id] && editor[id].updateId !== updateId) {
    return true
  } else {
    return false;
  }
}

/**
 * Pass the result of article client getState method;
 * @param {object} landingPageState
 */
 const receiveLandingPageCategoryState = (landingPageCategoryState) => {
  return dispatch => {
    let { id } = landingPageCategoryState;
    dispatch(receivedPhotosT('landing_page_category', landingPageCategoryState.photos));
    dispatch(receivedSectionsT('landing_page_category', landingPageCategoryState.sections));
    dispatch(receivedLandingPageCategory(landingPageCategoryState[id]));
  }
};

export const fetchParentLandingPageSnippet = (landingPageId, landingPageCategoryId) => (dispatch) => (
  getLandingPageSnippet(landingPageId)
    .then((res) => {
      if (res.httpStatus) throw new Error('Error fetching landing page snippet');
      dispatch(setLandingPageSnippet(res, landingPageCategoryId));
      if (res.heroImage) {
        dispatch(receivedPhotosT('landing_page_category', { [res.heroImage.id]: res.heroImage }));
      }
    })
);

export const createNewLandingPageCategory = () => {
  return dispatch => {
    dispatch(requestNewLandingPageCategory());
    return fetchTuid()
      .then(val => {
        let { id } = val;
        dispatch(receivedLandingPageCategory({ id }));
        dispatch(push(`/landing-page-categories/${id}`));
      })
      .catch(console.error);
  }
};

export const fetchLandingPageCategory = (id, updateId) => {
  return dispatch => {
    dispatch(requestLandingPageCategory());

    const req = !updateId
      ? ZApiClient.getLandingPageCategoriesClient().getLandingPageCategoryById(id)
      : ZApiClient.getLandingPageCategoriesClient().getLandingPageCategoryVersion(id, updateId);

    return req.then((res) => {
      if(res.httpStatus && res.httpStatus === 404) {
        throw new Error('NOT_FOUND');
      }
      if (res.breadcrumb?.entityId) {
        dispatch(fetchParentLandingPageSnippet(res.breadcrumb.entityId, id));
      }
      return landingPageCategoryClient.getState(res);
    }).then((newState) => {
      dispatch(receiveLandingPageCategoryState(newState));
    }).catch((e)=>{
      if(e.message === 'NOT_FOUND'){
        dispatch(landingPageCategoryRequestFailed());
      }
      console.error('Error Fetching Landing Page Category', e);
    })
  };
};

/**
 *
 * @param {string} id
 */
 export const fetchLandingPageCategoryIfNeeded = (id, updateId) => {
  return (dispatch, getState) => {
    const shouldFetch = shouldFetchLandingPageCategory(getState(), id, updateId);

    if (shouldFetch) {
      dispatch(fetchLandingPageCategory(id, updateId));
    }
  }
};

export const saveLandingPageCategory = (landingPageCategoryId) => {
  return (dispatch, getState) => {
    dispatch(saveRequested());
      ZApiClient
      .getLandingPageCategoriesClient()
      .exists(landingPageCategoryId)
      .then((res) => {
        const shouldCreate = !res;
        const { landingPageCategories } = getState();
        const entity = landingPageCategoryClient.getLandingPageCategory(landingPageCategoryId, landingPageCategories);

        return createOrUpdateLandingPageCategory(entity, shouldCreate);
      })
      .then((result) => {
        dispatch(landingPageCategorySaved());
        const { id, updateId, userId, updatedAt } = result;
        const newVersion = { id, updateId, userId, updatedAt };

        dispatch(addSavedEntityVersion(id, newVersion));
        return landingPageCategoryClient.getState(result);
      })
      .then((newState)=>{
        dispatch(receiveLandingPageCategoryState(newState));
      })
      .catch((e)=>{
        console.error('Error Saving Landing Page Category', e);
        console.error(e.stack);
      });
  };
};

export const fetchLandingPageCategoryVersion = (id, versionId) => (dispatch) => {
  dispatch(fetchEntityVersion('landingPageCategories', id, versionId))
    .then(() => {
      dispatch(versionSuccess(id, versionId));
    })
    .catch(console.error);
};

export const validateEntityVersion = (entityId, versionId, paths) => {
  return UTILS_fetchEntityVersion('landingPageCategories', entityId, versionId).then((res) => {
    const entityToValidate = {
      ...res,
      ...paths && { paths }
    };
    return landingPageCategoryClient.validateForPublish(entityToValidate);
  })
};

export const makeLatest = (id, updateId) => (dispatch) => {
  dispatch(requestLandingPageCategory());

  return ZApiClient
    .getLandingPageCategoriesClient()
    .getLandingPageCategoryVersion(id, updateId)
      .then((res) => {
        if(res.httpStatus && res.httpStatus === 404){
          throw new Error('NOT_FOUND');
        }
        return createOrUpdateLandingPageCategory(res, false);
      })
      .then((res) => {
        const { id, updateId, userId, updatedAt } = res;
        const newVersion = { id, updateId, userId, updatedAt };
        dispatch(addSavedEntityVersion(id, newVersion));
        return landingPageCategoryClient.getState(res);
      })
      .then((newState) => {
        dispatch(receiveLandingPageCategoryState(newState));
      })
      .catch((e) => {
        if(e.message === 'NOT_FOUND'){
          dispatch(landingPageCategoryRequestFailed());
        }
        console.error('Error Fetching Landing Page Category', e);
      });
};
