import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReviewItem from '../review-item';
import styles from './reviews-list.module.scss';

const ReviewsListView = ({
  hideTCLink,
  reviews = [],
}) => (
  <>
    {useMemo(() => (
      reviews.slice(0, reviews.length).map((review) => (
        <div className={styles['review']} key={review.id}>
          <ReviewItem
            hideTCLink={hideTCLink}
            isSnippet
            review={review}
          />
        </div>
      ))
    ), [hideTCLink, reviews])}
  </>
);
ReviewsListView.propTypes = {
  hideTCLink: PropTypes.bool,
  reviews: PropTypes.array.isRequired,
};
ReviewsListView.defaultProps = {
  hideTCLink: false,
};

export default ReviewsListView;
