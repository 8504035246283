import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArticleEditorView from './article-editor-view';
import sharedStyle from '../shared/styles/shared.module.scss';
import ZSpinner from '../z-spinner';
import ErrorModal from './error-modal';
import { decodeHTMLEntities } from '../../utils/utils';

const defaultBtnText = 'Save';

const ArticleEditorContainer = ( {
  article,
  currentUpdateId,
  disabledSaveForDate,
  errors,
  isFetching,
  notFound,
  onCreateNew,
  onLoad,
  onSave,
  onTitleChange,
  onToggleReadOnly,
  paramUpdateId,
  readOnly,
  saved,
} ) => {
  const [openToast, setOpenToast] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);
  const [disableSave, setDisableSave] = useState(false);
  const [btnText, setBtnText] = useState(defaultBtnText);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  const { id } = useParams();

  useEffect(()=> {
    onLoad(paramUpdateId);
  }, [onLoad, paramUpdateId]);

  useEffect(() => {
    if (!!paramUpdateId) {
      onToggleReadOnly();
      return () => onToggleReadOnly();
    }
  }, [paramUpdateId, onToggleReadOnly]);

  useEffect(()=>{
    if(!saveSuccess && saved){
      setSaveSuccess(true);
      setOpenToast(true);
      setBtnText(defaultBtnText);
      setSaveBtnDisabled(false);
    }
  },[saveSuccess, saved]);

  useEffect(() => {
    if (!!errors) {
      const { length } = Object.keys(errors);
      if (length > 0) setDisableSave(true);
      else setDisableSave(false);
    }
  }, [errors])

  useEffect(() => {
    if (article?.title) document.title = `a: ${decodeHTMLEntities(article.title)}`;
  }, [article?.title]);

  const onToastClose = () => {
    setOpenToast(false);
  }

  const onSaveClick = () => {
    setSaveSuccess(false);
    setBtnText('...Saving Article');
    setSaveBtnDisabled(true);
    onSave();
  }

  if(isFetching){
    return (
      <div className={sharedStyle['spinner']}>
        <ZSpinner/>
      </div>
    );
  } else if(notFound) {
    return (
      <ErrorModal
        id={id}
        onCreateNew={onCreateNew}
      />
    );
  } else {
    return (
      <ArticleEditorView
        btnText={btnText}
        disableSave={disableSave}
        disabledSaveForDate={disabledSaveForDate}
        errors={errors}
        id={id}
        onSave={onSaveClick}
        onTitleChange={onTitleChange}
        onToastClose={onToastClose}
        openToast={openToast}
        readOnly={readOnly}
        saved={saved}
        title={article.title || ''}
        saveBtnDisabled={saveBtnDisabled}
        updateId={currentUpdateId}
      />
    );
  }
}

export default ArticleEditorContainer;
