export const SET_CTA_TITLE = (T) => `SET_CTA_TITLE_${T.toUpperCase()}`;
export const SET_CTA_STEPS  = (T) => `SET_CTA_STEPS_${T.toUpperCase()}`;

export const TEASER_ACTION_NAMES = {};

(() => {
    [
        'article',
        'company_category',
        'landing_page_category',
        'content',
        'guide',
        'itinerary',
        'landing_page'
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        TEASER_ACTION_NAMES[entityType] = {};
        TEASER_ACTION_NAMES[entityType]['SET_CTA_TITLE'] = SET_CTA_TITLE(entityType);
        TEASER_ACTION_NAMES[entityType]['SET_CTA_STEPS'] = SET_CTA_STEPS(entityType);
    });
})();
