import React from 'react';
import ZPagesView from './z-pages-view';

class ZPagesContainer extends React.Component{
  constructor(props){
    super(props);
    // handles edge case when only one page is passed as a child
    this.children = this.setChildren(this.props.children);
    this.tabsArray = this.mapTabs(this.children);
    this.state={
      activeTab: this.children[0].props.tabName,
    }
    
  }

  setChildren = children => {
    if(!Array.isArray(children)){
      return [children];
    } else {
      return children;
    }
  }

  setActive = event => {
    let newTab = event.target.getAttribute('tab');
    if(newTab !== this.state.activeTab){
      this.setState({...this.state, activeTab: newTab});
    }
  }

  mapTabs = (children) =>{
    let tabsArr = children.map((ele)=>{
      const { tabName } = ele.props;
      if (this.props.readOnly
        && (tabName.toLowerCase().includes('versions')
        || tabName.toLowerCase().includes('publications'))) {
        return null;
      }
      return tabName;
    })
    return tabsArr;
  }

  render() {
    return (
      <ZPagesView tabs={this.tabsArray} activeTab={this.state.activeTab} setActive={this.setActive}>
        {this.children.map((ele)=>{
          return (ele.props.tabName === this.state.activeTab) ? ele : null
        })}
      </ZPagesView>
    )
  }
}

export default ZPagesContainer;
