import React from 'react';
import Content from './content';
import Optimization from './optimization';
import Publications from './publications';
import ZInput from '../z-input';
import ZPages from '../z-pages';
import ZPage from '../z-page';
import ZToast from '../z-toast';
import FloatingSave from '../shared/components/floating-save/floating-save-view';
import Versions from './versions';
import UsageReferences from '../usage-references';
import PublishedStatus from '../shared/components/published-status';
import pageStyle from '../shared/styles/page.module.scss';
import { decodeHTMLEntities } from '../../utils/window';
import sharedStyle from '../shared/styles/shared.module.scss';
import Config from '../../config';

const baseUrlWeb = `${Config.Z3_URL}`;

const GuideEditorView = ({
  btnText,
  disableSave,
  id,
  onSave,
  onTitleChange,
  onToastClose,
  openToast,
  readOnly,
  saved,
  title,
  saveBtnDisabled,
  updateId,
}) => (
  <>
    <div className={pageStyle['landing-page']}>
      <h1>
        <ZInput
          label='Title'
          onChange={onTitleChange}
          text={decodeHTMLEntities(title)}
          style={{margin: '14px 28px', fontSize: '22px'}}
          inputKey={'landing-page__title'}
        />
      </h1>
      <div className={pageStyle['landing-page__header']} style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <PublishedStatus entityId={id} entityUpdateId={updateId} />
          <h2>{ saved ? 'Saved' : 'Not Saved' }</h2>
        </div>
        {readOnly && <h3 className={sharedStyle['read-only']}>Read Only View</h3>}
        <div className={pageStyle['preview-wrapper']}>
          <a
            className={`${pageStyle['preview-button']} ${sharedStyle['button']}`}
            href={`${baseUrlWeb}/dynamic?type=Guide&id=${id}`}
            target='_blank'
            rel="noopener noreferrer"
          >
            Preview
          </a>
        </div>
      </div>
      <ZPages readOnly={readOnly}>
        <ZPage tabName='Creation' key={`${1}-Creation`}>
          <Content
            guideId={id}
          />
        </ZPage>
        <ZPage tabName='Optimization' key={`${2}-Optimization`}>
          <Optimization
            guideId={id}
          />
        </ZPage>
        <ZPage tabName="Usage References" key={`${3}-usageReferences`} >
          <UsageReferences entityId={id} />
        </ZPage>
        <ZPage tabName='Versions' key={`${4}-Versions`}>
          <Versions
            guideId={id}
            onSave={onSave}
          />
        </ZPage>
        <ZPage tabName='Publications' key={`${5}-Publications`}>
          <Publications />
        </ZPage>
      </ZPages>
    </div>
    <ZToast
      open={openToast}
      onClose={onToastClose}
      message={'Guide Saved!'}
    />
    <FloatingSave
      onClick={onSave}
      disabled={!title || disableSave || saveBtnDisabled}
      text={btnText}
    />
  </>
);

export default GuideEditorView;
