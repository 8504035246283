import React, { forwardRef } from 'react';
import styles from './oryx-select.module.scss';

const defaultProps = {
  disabled: false,
  selectClassName: '',
  style: {},
  valid: true,
  wrapperClassName: '',
}

const OryxSelectView = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function ZSelectView({
    children,
    disabled,
    name,
    onChange,
    selectClassName,
    style,
    value,
    valid,
    wrapperClassName,
  }, ref) {
    return (
      <div className={`${styles.select__wrapper} ${wrapperClassName}`}>
        <select
          className={`
            ${styles.select}
            ${selectClassName}
            ${valid ? '' : styles['select--invalid']}
          `}
          disabled={disabled}
          id={name}
          name={name}
          onChange={onChange}
          ref={ref}
          style={style}
          value={value}
        >
          {children}
        </select>
      </div>
    );
  },
);

OryxSelectView.defaultProps = defaultProps;

export default OryxSelectView;
