import React, {useState, useEffect} from 'react';
import { Stack, Button, Typography, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SvgIcon from '@mui/material/SvgIcon';
import ZApiClient from '../../../../utils/client/z-api-client';
import { ReactComponent as GlobeIcon } from '../../../../assets/inline/globe.svg';
import ZTitleFlag from '../../../z-title-flag';
import {
  decodeHTMLEntities
} from '../../../../utils/window';
import { fetchTuid } from '../../../../utils/fetch/tuid';

const EMPTY_OPTION = {
  label: '',
};

const SELECT_OPTIONS = [
  {label: 'Article', value: 'Article'},
  {label: 'Guide', value: 'Guide'},
  {label: 'Itinerary', value: 'Itinerary'},
  {label: 'Landing Page', value: 'LandingPage'},
];

const getEntityOptions = (obj, type) => {
  let ret = [];
  for(const key in obj){
    let temp = {
      label: decodeHTMLEntities(obj[key]),
      entityId: key,
      type,
    };
    ret.push(temp);
  }
  return ret;
}

const getPageTypeOption = (type) => {
  if (!type) {return EMPTY_OPTION};
  const ret = SELECT_OPTIONS.find((e) => e.value === type);
  return ret ? ret : EMPTY_OPTION;
}

const getIsoCodes = (callBack) => {
  return ZApiClient
  .getGeoNamesClient()
  .getCountries({ sorted: true, fields: ['country,isoCode'] })
  .then((res) => {
    const countries = res.reduce(
      (options, fullCountry) => {
        const { isoCode, country } = fullCountry;
        if (isoCode && country) {
          // eslint-disable-next-line no-param-reassign
          options.push({
            label: country,
            code: isoCode,
          });
        }
        return options;
      },
      [],
    );
    callBack(countries);
  })
}

const onSearch = async (text, entity, setState) => {
  const searchText = text.toLowerCase();
  if (!!entity) {
    return ZApiClient
    .getClientFromType(entity)
    .getAllTitlesWithIdsByNameFragment(searchText)
    .then((res)=> {
      setState(getEntityOptions(res, entity));
    });
  } 
}

const getCanonical = (id) => {
  if (id) {
   return ZApiClient
    .getPathsClient()
    .readPaths({entity: id})
    .then((res) => {
      return res.filter((ele) => !ele.redirect)
    }).then(([canonical]) => {
      return canonical?.alias;
    })
  } else {
    return Promise.resolve();
  }
}

const getTitles = async (id, type) => {
  const params = {fields: ['title']};
  switch (type) {
    case 'Article':
      return ZApiClient
        .getArticlesClient()
        .getArticle(id, params);
    case 'Content':
      return ZApiClient
        .getContentsClient()
        .getContent(id, params);
    case 'Guide':
      return ZApiClient
        .getGuidesClient()
        .getGuide(id, params); 
    case 'Itinerary':
      return ZApiClient
        .getItinerariesClient()
        .getItinerary(id, params);
    case 'LandingPage':
      return ZApiClient
        .getLandingPagesClient()
        .getLandingPage(id, params);
    default:
      return Promise.resolve(null);
  }
}


const LinkEditor = ({
  editorState,
  onLinkChange,
  onClose,
  onDelete,
  onCancel,
}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(EMPTY_OPTION);
  const [isoCode, setIsoCode] = useState(editorState.flagCountryCode);
  const [pageType, setPageType] = useState(getPageTypeOption(editorState.entityType));
  const [fetchedOptions, setFetchedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    getIsoCodes((options) => {
      if (editorState.flagCountryCode) {
        const item = options.find((e) => e.code === editorState.flagCountryCode);
        item ? setSelectedCountryOption(item) : setSelectedCountryOption(EMPTY_OPTION);
      }
      setCountryOptions(options);
    });
  }, [editorState]);

  useEffect(() => {
    if (editorState?.entityId) {
      const {
        entityType,
        entityId,
      } = editorState;
      getTitles(entityId, entityType)
        .then(({ title }) => {
          setSearchTerm(title);
          setSearchValue({
            type: entityType,
            label: decodeHTMLEntities(title),
            entityId,
          })
        })
    }
  }, [editorState]);

  const onCountrySelect = (value) => {
    if (value) {
      setSelectedCountryOption(value);
      setIsoCode(value.code);
    } else {
      setSelectedCountryOption(EMPTY_OPTION);
      setIsoCode();
    }
  }

  const onRemoveFlag = () => {
    setSelectedCountryOption();
    setIsoCode();
  }

  const onEntitySelect = (value) => {
    if (value) {
      setPageType(value);
    } else {
      setPageType(EMPTY_OPTION);
    }
  }

  const getLinkData = async () => {
    const canonicalAlias = await getCanonical(searchValue.entityId)
    const {id} = await fetchTuid();
    const ret = {
      id,
      title: selectedCountryOption.label,
      flagCountryCode: isoCode,
      entityType: searchValue.type,
      entityId: searchValue.entityId,
      uri: `/${canonicalAlias}`,
    }
    onLinkChange(ret);
    onClose();
  }

  useEffect(() => {
    if (searchTerm === '') {
      setFetchedOptions(searchValue ? [searchValue] : []);
      return undefined;
    } 
    onSearch(searchTerm, pageType.value, setFetchedOptions);
  }, [searchValue, searchTerm, pageType]);

  return (
    <>
      <Stack direction='row' alignItems="end" sx={{marginBottom: '16px'}}>
        <Autocomplete
          sx={{
            m: 1,
            width: 300,
            marginBottom: '16px',
          }}
          disabled={countryOptions.length === 0}
          options={countryOptions}
          autoHighlight
          getOptionLabel={(option) => option.label}
          id="countries"
          value={selectedCountryOption}
          onChange={(event, newValue) => {
            onCountrySelect(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select a Country" variant="standard"/>
          )}
        />
        { isoCode && (
          <Box>
            <ZTitleFlag
              isoCode={isoCode}
              style={{ 
                marginRight: '16px',
                height: '2.5em',
                width: '2.5em',
              }}
            />
            <Button 
              variant="contained"
              size="small"
              color="secondary"
              type="button"
              onClick={() => onRemoveFlag()}
            >
              Remove Flag
            </Button>
          </Box>
        )}
        { !isoCode && (
          <SvgIcon component={GlobeIcon} inheritViewBox />
        )}
      </Stack>
      <Stack direction='row' alignItems="end" sx={{marginBottom: '16px'}}>
        <Box>
          <Typography variant="h3">Select a Page to Link</Typography>
          <Autocomplete
            sx={{
              m: 1,
              width: 300,
            }}
            options={SELECT_OPTIONS}
            autoHighlight
            id="entities"
            value={pageType}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              onEntitySelect(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select a Page Type"/>
            )}
          />
        </Box>
        <Autocomplete
          sx={{
            m: 1,
            width: 300,
          }}
          filterOptions={(x) => x}
          options={fetchedOptions}
          disabled={!pageType.value}
          autoHighlight
          autoComplete
          filterSelectedOptions
          id="search"
          value={searchValue}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => {
            setFetchedOptions(newValue ? [newValue, ...fetchedOptions] : fetchedOptions);
            setSearchValue(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search By Title" fullWidth variant="standard"/>
          )}
        />
      </Stack>
      <Stack direction='row' justifyContent="space-between">
        <Button
          disabled={!isoCode || !searchValue}
          variant="contained"
          size="small"
          color="secondary"
          type="button"
          onClick={getLinkData}
        >
          {(editorState && editorState.entityId) ? 'Update' : 'Add'}
        </Button>
        { (editorState && editorState.entityId) && (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            type="button"
            onClick={() => onDelete(editorState.id)}
          >
            Delete
          </Button>
        )}
        <Button 
          variant="contained"
          size="small"
          color="secondary"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Stack>
    </>
  )
}

export default LinkEditor;
