import React from 'react';
import style from './z-input.module.scss';
import { decodeHTMLEntities } from '../../utils/window';
import Config from '../../config';
const baseUrlWeb = `${Config.Z3_URL}`;

function ZInputView (props){
  const inputClass = (props.text && props.text.length !== 0) ? 'z-input__label--focused': 'z-input__label';
  const inputId = `z-input-${props.inputKey ? props.inputKey : Date.now() }`
  return(
    <div className={`${style['z-input__wrapper']} ${props.wrapperClass}`} style={props.style}>
      <label
        htmlFor={inputId}
        className={`${style[inputClass]} ${props.labelClass}`}
      >
        {props.label}
      </label>
      <input
        ref={input => {if (input && props.focus){ input.focus()}}}
        value={decodeHTMLEntities(props.text)}
        placeholder={props.placeholder ? props.placeholder : undefined}
        type={(props.type) ? props.type : "text"}
        className={`${style['z-input']} ${(props.className)? props.className:''}`}
        id={inputId}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        autoComplete={props.autoComplete ? props.autoComplete : 'off'}
        readOnly={props.readOnly || props.disabled}
        onPaste={props.onPaste}
      />
      {props.error &&
        <div  className={style['error']}>
          <p>{props.error.message}</p>
          {props.error.data &&
            <p className={style['error__subtext']}>{'Existing page(s): '}
              {props.error.data.map(ele => {
                return (<a href={`${baseUrlWeb}/dynamic?type=${ele.type}&id=${ele.id}`} target='_blank' rel="noopener noreferrer" key={ele.id}>{ele.id}</a>)
                })
              }
            </p>
          }
        </div>
      }
    </div>
  )
}

export default ZInputView;
