import React from 'react';
import style from './z-publications-row.module.scss';

const ZPublicationsRow = (props) => {
  const rowData = mapRowData(props.legend, props.data);
  return (
    <div
      className={`${style['z-publications-row']}`}
      style={props.rowStyle}
    >
      {rowData.map((ele, i) => {
        return (
          <span
            className={style['z-publications-row__item']} 
            style={props.rowItemStyle}
            key={`${ele}-${i}`}
          >
            {ele === true && <b>true</b>}
            {ele === false && <p>false</p>}
            {typeof ele !== 'boolean' && <div dangerouslySetInnerHTML={{ __html: ele }}/>}
          </span>
        )
      })}
    </div>
  )
};

const mapRowData = (legend, data) => {
  let resp = legend.map((ele) => data[ele]);
  return resp;
};

export default ZPublicationsRow;
