import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchItineraryIfNeeded,
  saveEntity,
} from '../../state/actions/itinerary-editor';
import ItineraryContainer from './itinerary-container';
import { toggleReadOnly } from '../../state/actions/versions';
import {setTitleT} from "../../state/actions/shared/title";


const ItineraryRedux = (props) => {

  useEffect(()=> {
    if(props.readOnly) props.toggleReadOnly();
  }, [props.readOnly]);

  return (
    <ItineraryContainer {...props}/>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { params } = match;
  const { id, updateId: paramUpdateId  } = params;
  const { errors } = state;
  const { editor } = state.itineraries.itinerary;
  const readOnly = !!paramUpdateId;
  const currentEntity = editor[id] || {};
  const currentErrors = errors[id] || {};
  const {
    isFetching,
    saved,
    saveDisabled,
    disabledMessage,
  } = editor;
  const {
    isValid,
    title: titleErrors
  } = currentErrors;
  return {
    currentUpdateId: currentEntity.updateId,
    disabledMessage,
    entity: currentEntity,
    id,
    isFetching,
    isValid,
    paramUpdateId,
    readOnly,
    saved,
    saveDisabled,
    titleErrors,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match } = ownProps;
  const { params } = match;
  const { id } = params;
  return {
    onLoad: (updateId) => dispatch(fetchItineraryIfNeeded(id, updateId)),
    onSave: () => dispatch(saveEntity(id)),
    onTitleChange : text => dispatch(setTitleT('itinerary', text, id)),
    toggleReadOnly: () => dispatch(toggleReadOnly()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryRedux);
