import React from 'react';
import PriceView from './price-view';

const PriceContainer = ({
  startingPrice,
  priceComments,
  priceCommentsErrors,
  includedNotes,
  includedNotesErrors,
  onUpdateStatingPrice,
  onUpdatePriceComments,
  onUpdateIncludedNotes,
  showPrice,
  onChangeShowPrice,
}) => {
  
  const priceIntToString = (n) => {
    let s = n.toString().replace(/,/g,'');
    if (s.length <= 3) return s;
    const v = s.split('').reverse().reduce((acc, cur, i) => {
      if(i !== 0 && i % 3 === 0){
        return [...acc, ',', cur]
      } else {
        return [...acc, cur]
      }
    }, []).reverse().join('');
    return v;
  }

  const priceStringToInt = (s) => {
    if(typeof s === 'number') return s;
    const valueString = s.split('').reduce((acc, cur) => {
      if(cur !== ',') {
        const str = acc + cur;
        return str
      }
      return acc;
    }, '')
    const priceInt = parseInt(valueString, 10);
    return priceInt;
  }

  const onPriceUpdate = (val) => {
    onUpdateStatingPrice(priceStringToInt(val));
  }

  const onShowPriceToggle = (event) => {
    onChangeShowPrice(event.target.checked);
  }

  return (
    <PriceView
      startingPrice={priceIntToString(startingPrice)}
      onUpdateStatingPrice={onPriceUpdate}
      priceComments={priceComments}
      priceCommentsErrors={priceCommentsErrors}
      onUpdatePriceComments={onUpdatePriceComments}
      includedNotes={includedNotes}
      includedNotesErrors={includedNotesErrors}
      onUpdateIncludedNotes={onUpdateIncludedNotes}
      showPrice={showPrice}
      onShowPriceToggle={onShowPriceToggle}
    />
  )
} 

export default PriceContainer;
