import React from 'react';
import styles from './oryx-table.module.scss';

const oryxTableCellDefaultProps = {
  className: '',
  style: {},
  id: null,
};

const oryxTableRowDefaultProps = {
  className: '',
  overlayText: '',
  useOverlay: false,
  style: {},
};

const oryxTableHeaderDefaultProps = {
  onSort: () => {},
  sortable: false,
  style: {},
};

const OryxTableHeader = ({
  children,
  onSort,
  sortable,
  style,
}) => (
  <th
    className={`
      ${styles.table__header}
      ${sortable ? styles['table__header--sortable'] : ''}
    `}
    onClick={sortable ? onSort : undefined}
    style={style}
    tabIndex={sortable ? 0 : -1}
  >
    {children}
    {sortable && <span className={styles['table__header--sort']}>&#8597;</span>}
  </th>
);
OryxTableHeader.defaultProps = oryxTableHeaderDefaultProps;

const OryxTableRow = ({
  className,
  children,
  overlayText,
  useOverlay,
  style,
}) => (
  <tr
    className={`
      ${styles.table__row}
      ${className}
    `}
    style={style}
  >
    {children}
    { useOverlay && (
      <td className={styles.table__row__overlay}>
        <p>
          {overlayText}
        </p>
      </td>
    )}
  </tr>
);
OryxTableRow.defaultProps = oryxTableRowDefaultProps;

const OryxTableCell = ({
  id,
  className,
  children,
  style,
}) => (
  <td
    id={id}
    className={`
      ${styles.table__cell}
      ${className}
    `}
    style={style}
  >
    {children}
  </td>
);
OryxTableCell.defaultProps = oryxTableCellDefaultProps;

export {
  OryxTableHeader,
  OryxTableRow,
  OryxTableCell,
};
