import React from 'react';
import ZInput from '../../../z-input';
import ZTextArea from '../../../z-text-area';
import PhotoPreview from '../../../shared/components/content-image'
import ZArrow from '../../../z-arrow';
import style from './section.module.scss';
import ZDeleteButton from '../../../z-button-delete';
import ZAddButton from '../../../z-button-add';
import sharedStyle from '../../../shared/styles/shared.module.scss';
import { getImagePreviewTransformation } from '../../../../utils/image/image-utils';

const Section = ({
  id,
  index,
  moveToBottom,
  moveToTop,
  onAddSectionBelow,
  onChangePhoto,
  onChangePosition,
  onCreatePhoto,
  onDeleteClick,
  onRemovePhoto,
  onTextChange,
  onTitleChange,
  onToggleDropdown,
  order,
  photo,
  showPhoto,
  text,
  title,
}) => {
  return (
    <div className={`${style['day']}`}>
      <div style={{width: '95%', maxWidth: '95%'}}>
        <div className={style['day-number']}>
          <p>Section: {order}</p>
          <div className={style['day__move-buttons']}>
            <ZInput
                style={{backgroundColor: '#FFF', padding: '0 10px', width: '190px', marginLeft: '20px'}}
                label={`Move to Position: `}
                labelClass={style['day__position']}
                onUpdate={(e) => {
                  e && onChangePosition(index, isNaN(parseInt(e, 10) - 1) ? index : parseInt(e, 10) - 1);
                }}
              />
            <button type="button" className={`${sharedStyle['button']} ${style['day__move-button']}`} onClick={() => moveToTop(index)}>Move To Top</button>
            <button type="button" className={`${sharedStyle['button']} ${style['day__move-button']}`} onClick={() => moveToBottom(index)}>Move To Bottom</button>
          </div>
        </div>
        <ZInput
          style={{marginBottom: '28px'}}
          label='Title (Optional)'
          className='day-title'
          onChange={onTitleChange}
          text={title || ''}
          inputKey={`${id}__title`}
        />
        <div className={style['dropdown__wrapper']} onClick={onToggleDropdown}>
          <ZArrow direction={showPhoto ? 'down' : 'right'}/>
          <p style={{marginLeft: '14px'}}>Section Photo (optional)</p>
        </div>
        {showPhoto &&
          <PhotoPreview
            entityId={id}
            key={photo.id}
            photoId={photo.id}
            style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
            showInfo
            onChangePhoto={onChangePhoto}
            onCreatePhoto={onCreatePhoto}
            onRemovePhoto={onRemovePhoto}
            photo={photo}
            type="section"
            transformation={getImagePreviewTransformation({ aspectRatio: 1.5 })}
          />
        }
        <br/>
        <ZTextArea
          allowedFormats={['ITALICS', 'LINKS', 'BOLD', 'ORDERED_LIST', 'BULLET_POINTS']}
          onUpdate={onTextChange}
          value={text}
          inputKey={`${id}__description`}
        />
      </div>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '5%', maxWidth: '5%'}}>
        <ZDeleteButton onClick={onDeleteClick} />
        <ZAddButton onClick={onAddSectionBelow} />
      </div>
    </div>
  )
}

export default Section;
