import React from 'react';
import style from './header-image.module.scss';
import ZButton from '../../../z-button';
import ZImage from '../../../z-image';

const placeHolder = 'https://res.cloudinary.com/zicasso/image/upload/v1545258210/Logo-%20Marketing%20Materials/PNG/Zicasso_Logo_no_sub_black_small.png';

const PhotoPreviewView = ({
  alt,
  onLoad,
  setUseParentImage,
  src,
}) => (
  <div className={`${style['photo-preview']}`}>
    <div className={`${style['photo-preview__photo']}`}>
      <ZImage
        alt={alt}
        onLoad={onLoad}
        cloudinarySrc={src || placeHolder}
        width={300}
        aspectRatio={3.14}
        crop="fill"
      />
    </div>
    <div className={style['photo-preview__landing-page']}>
      <p>Landing Page Header Image</p>
      <ZButton
        btnClass={style['photo-preview__landing-page--button']}
        text="Add Custom Header Image"
        onClick={() => setUseParentImage(false)}
      />
    </div>
  </div>
);

export default PhotoPreviewView;
