import React from 'react';
import ZAutoComplete from '../../z-autocomplete';
import ZDeleteButton from '../../z-button-delete';
import ZSpinner from '../../z-spinner';
import style from './parent.module.scss';
import sharedStyle from '../../shared/styles/shared.module.scss';

const ParentView = ({
  breadcrumb,
  loading,
  error,
  onDelete,
  onSearch,
  onSelect,
}) => (
  <div>
    <div className={style['parent']}>
      <p>
        Parent Landing Page:
        <br />
        <span className={style['parent-title']} dangerouslySetInnerHTML={{ __html: breadcrumb.title }} />
      </p>
      {breadcrumb.entityId && (<ZDeleteButton onClick={onDelete} />)}
    </div>
    {loading ? (
      <ZSpinner />
    ) : (
      <ZAutoComplete
        autoComplete={onSearch}
        inputKey='breadcrumb_parent'
        onSelect={onSelect}
        placeholder={`Search to ${breadcrumb.title ? 'Change' : 'Add a'} Landing Page Parent`}
        returnsObject
        style={{ marginBottom: '14px', marginTop: '42px' }}
      />
    )}
    {error && (
      <p className={sharedStyle['error']}>There was an error, please try again</p>
    )}
  </div>
);

export default ParentView;
