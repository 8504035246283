import React from 'react';
import sharedStyle from '../shared/styles/shared.module.scss';
import { ReactComponent as AddSVG } from '../../assets/logos/add_photo.svg';

const ZAddButton = ({
  children, onClick, style, value,
}) => (
  <button
    className={sharedStyle['svg__btn']}
    onClick={onClick}
    type="button"
    style={style}
    value={value}
  >
    <AddSVG/>
    {children}
  </button>
);

export default ZAddButton;
