import React from 'react';
import ZRadioGroup from '../../../z-radio-group';
import style from './filter-displayed.module.scss';

const FilterDisplayedView = ({
  onChange,
  value,
}) => (
  <ZRadioGroup
    className={style['displayed']}
    name="displayed"
    onChange={onChange}
    options={['yes', 'no', 'all']}
    value={value}
  >
    Displayed:
  </ZRadioGroup>
);

export default FilterDisplayedView;
