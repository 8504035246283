import { connect } from 'react-redux';
import LocationsContainer from './locations-container';
import {addLocationT, setLocationsT} from "../../../../state/actions/shared/location";

const mapStateToProps = (state, ownProps) => {
  const {entityId} = ownProps;

  return {
    locations: state.landingPageCategories.editor[entityId]?.locations,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    addLocation: (location) => dispatch(addLocationT('landing_page_category', location, id)),
    setLocations: (locations) => dispatch(setLocationsT('landing_page_category', locations, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsContainer);
