import React, { useEffect, useState } from 'react';
import ItineraryView from './itinerary-view';
import sharedStyle from '../shared/styles/shared.module.scss';
import ZSpinner from '../z-spinner';
import { decodeHTMLEntities } from '../../utils/utils';

const defaultBtnText = 'Save'

const ItineraryEditorContainer = ({
  currentUpdateId,
  disabledMessage,
  entity,
  id,
  //  isDraft,
  isFetching,
  isValid,
  onLoad,
  onSave,
  onTitleChange,
  //  onValidate,
  paramUpdateId,
  readOnly,
  saved,
  saveDisabled,
  titleErrors,
}) => {

  const [openToast, setOpenToast] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);
  const [btnText, setBtnText] = useState(defaultBtnText);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  useEffect(()=>{
    if(!saveSuccess && saved){
      setSaveSuccess(true);
      setOpenToast(true);
      setBtnText(defaultBtnText);
      setSaveBtnDisabled(false);
    }
  },[saveSuccess, saved]);

  useEffect(()=> {
    onLoad(paramUpdateId);
  }, [onLoad, paramUpdateId]);


  useEffect(() => {
    if (entity?.title) document.title = `i: ${decodeHTMLEntities(entity.title)}`;
  }, [entity?.title]);

  const onToastClose = () => {
    setOpenToast(false);
  }

  const onSaveClick = () => {
    setSaveSuccess(false);
    setSaveBtnDisabled(true);
    setBtnText('...Saving Itinerary');
    onSave();
  }

  if(isFetching){
    return (
      <div className={sharedStyle['spinner']}>
        <ZSpinner/>
      </div>
    )
  } else {
    return (
      <ItineraryView
        btnText={btnText}
        disabledMessage={disabledMessage}
        id={id}
        isValid={isValid}
        onSave={onSaveClick}
        onTitleChange={onTitleChange}
        onToastClose={onToastClose}
        openToast={openToast}
        readOnly={readOnly}
        saved={saved}
        saveDisabled={saveDisabled}
        title={entity.title || ''}
        titleErrors={titleErrors}
        saveBtnDisabled={saveBtnDisabled}
        updateId={currentUpdateId}
      />
    )
  }
}

export default ItineraryEditorContainer;
