import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GuideEditorView from './guide-editor-view';
import sharedStyle from '../shared/styles/shared.module.scss';
import ZSpinner from '../z-spinner';
import ErrorModal from './error-modal';
import { decodeHTMLEntities } from '../../utils/utils';

const defaultBtnText = 'Save';

const GuideEditorContainer = ( {
  currentUpdateId,
  guide,
  isFetching,
  notFound,
  onCreateNew,
  onLoad,
  onSave,
  onTitleChange,
  onToggleReadOnly,
  paramUpdateId,
  readOnly,
  saved,
} ) => {
  const [openToast, setOpenToast] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);
  const [btnText, setBtnText] = useState(defaultBtnText);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  const { id } = useParams();

  useEffect(()=> {
    onLoad(paramUpdateId);
  }, [onLoad, paramUpdateId]);

  useEffect(() => {
    if (!!paramUpdateId) {
      onToggleReadOnly();
      return () => onToggleReadOnly();
    }
  }, [paramUpdateId, onToggleReadOnly]);

  useEffect(()=>{
    if(!saveSuccess && saved){
      setSaveSuccess(true);
      setOpenToast(true);
      setBtnText(defaultBtnText);
      setSaveBtnDisabled(false);
    }
  },[saveSuccess, saved])


  useEffect(() => {
    if (guide?.title) document.title = `g: ${decodeHTMLEntities(guide.title)}`;
  }, [guide?.title]);

  const onToastClose = () => {
    setOpenToast(false);
  }

  const onSaveClick = () => {
    setSaveSuccess(false);
    setBtnText('...Saving Guide')
    setSaveBtnDisabled(true);
    onSave();
  }


  if(isFetching){
    return (
      <div className={sharedStyle['spinner']}>
        <ZSpinner/>
      </div>
    );
  } else if(notFound) {
    return (
      <ErrorModal
        id={id}
        onCreateNew={onCreateNew}
      />
    );
  } else {
    return (
      <GuideEditorView
        btnText={btnText}
        disableSave={false}
        id={id}
        onSave={onSaveClick}
        onTitleChange={onTitleChange}
        onToastClose={onToastClose}
        openToast={openToast}
        readOnly={readOnly}
        saved={saved}
        title={guide.title}
        saveBtnDisabled={saveBtnDisabled}
        updateId={currentUpdateId}
      />
    );
  }
}

export default GuideEditorContainer;
