import { useState, useCallback } from 'react';
import {
  getDownloadableFile, 
  downloadTextFile,
} from '../utils/fetch/accumulate-snippets-tsv';


const useDownloadTsv = (entityType) => {
  const [awaitingDownload, setAwaitingDownload] = useState(false);

  const memoOnClick = useCallback( 
    async () => {
      setAwaitingDownload(true);
      const res = await getDownloadableFile(entityType);
      setAwaitingDownload(false);
      downloadTextFile(res.link);
   },
   [entityType],
  )

  return [awaitingDownload, memoOnClick];
}

export default useDownloadTsv;
