import React, { useEffect, useState } from 'react';
import NewsView from './news-page-view';

const NewsContainer = ({
  createNew,
  isFetching,
  newsSnippets,
  setNewsSnippet,
  setNewsSource,
  onLoad,
  news,
}) => {
  const [filteredSnippets, setFilteredSnippets] = useState();
  const [showFilters, setShowFilters] = useState();
  const [editNewsSnippetId, setEditNewsSnippetId] = useState();
  const [editNewsSourceId, setEditNewsSourceId] = useState();
  const [filterSourceId, setFilterSourceId] = useState();
  const [displayed, setDisplayed] = useState('all');
  const [valid, setValid] = useState('yes');
  const [screenSize, setScreenSize] = useState('desktop');

  useEffect(() => {
    // get all news snippets and sources
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    if (!filterSourceId && displayed === 'all' && valid === 'all') {
      setFilteredSnippets();
    } else {
      const filtered = newsSnippets.filter((snippet) => {
        const matchesDisplayed = displayed === 'all' || snippet.displayed === (displayed === 'yes');
        const matchesSource = !filterSourceId || snippet.newsSource?.id === filterSourceId;
        const matchesValid = valid === 'all' || snippet.valid === (valid === 'yes');

        return matchesDisplayed && matchesSource && matchesValid;
      });
      setFilteredSnippets(filtered);
    }
  }, [displayed, filterSourceId, newsSnippets, valid]);

  const onClickNewsSourceFilter = (newsSourceId) => {
    setFilterSourceId(newsSourceId)
  };

  const onClickDisplayed = (e) => {
    const { value } = e.target;
    setDisplayed(value)
  };

  const onClickValid = (e) => {
    const { value } = e.target;
    setValid(value);
  }

  const onClickScreenSize = (e) => {
    const { value } = e.target;
    setScreenSize(value);
  }

  const toggleShowFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const onClickEdit = (newsSnippetOrNewsSource) => {
    if (newsSnippetOrNewsSource.className === 'NewsSnippet') {
      setEditNewsSnippetId(newsSnippetOrNewsSource.id);
      setNewsSnippet(newsSnippetOrNewsSource);
    }
    if (newsSnippetOrNewsSource.className === 'NewsSource') {
      setEditNewsSourceId(newsSnippetOrNewsSource.id);
      setNewsSource(newsSnippetOrNewsSource)
    }
  };

  const closeModal = (type) => {
    if (type === 'newsSnippet') setEditNewsSnippetId();
    else if (type === 'newsSource') setEditNewsSourceId();
    else setEditNewsSnippetId(); setEditNewsSourceId();
  };

  const onClickCreateSnippet = () => {
    setEditNewsSnippetId('new');
    createNew();
  }

  return (
    <NewsView
      closeModal={closeModal}
      displayed={displayed}
      editNewsSnippetId={editNewsSnippetId}
      editNewsSourceId={editNewsSourceId}
      isFetching={isFetching}
      newsSnippets={filteredSnippets || newsSnippets}
      onClickCreateSnippet={onClickCreateSnippet}
      onClickDisplayed={onClickDisplayed}
      onClickEdit={onClickEdit}
      onClickNewsSourceFilter={onClickNewsSourceFilter}
      onClickScreenSize={onClickScreenSize}
      onClickValid={onClickValid}
      screenSize={screenSize}
      setEditNewsSourceId={setEditNewsSourceId}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
      valid={valid}
    />
  );
};

export default NewsContainer;
