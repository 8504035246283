import React from 'react';
import style from './meta-tags.module.scss';
import ZInput from '../../../z-input';
import ZRadioGroup from '../../../z-radio-group';

const indexOptions = [
  {
    label: 'Index',
    value: false,
  },
  {
    label: 'No Index',
    value: true,
  }
];

const followOptions = [
  {
    label: 'Follow',
    value: false,
  },
  {
    label: 'No Follow',
    value: true,
  }
]
;
const MetatagsView = ({
  title,
  metatags,
  onMetaTitleChange,
  onMetaDescriptionChange,
  onMetaNoindexChange,
  onMetaNofollowChange,
}) => (
  <div className={style['meta-tags']}>
    <h3>Meta Tags</h3>
    <ZInput
      label={'Title Tag'}
      onChange={onMetaTitleChange}
      text={metatags.title}
      style={{marginBottom: '28px'}}
      inputKey={'admin__titleTag'}
      error={!title ? {message: 'Add a title and Save to Generate A title'} : false }
    />
    <ZInput
      label='Description Tag'
      onChange={onMetaDescriptionChange}
      text={metatags.description}
      style={{marginBottom: '28px'}}
      inputKey={'admin__descriptionTag'}
    />
    <ZInput
      label='Canonical Url*'
      readOnly={true}
      text={metatags.canonical ? metatags.canonical : 'Add a Path Above and Save'}
      inputKey={'admin__canonicalUrl'}
    />
    <div className={style['advanced']}>
      <ZRadioGroup
        name="index"
        onChange={(e) => onMetaNoindexChange(e.target.value === 'true')}
        options={indexOptions}
        value={metatags.noindex}
      >
        Indexing:
      </ZRadioGroup>
      <ZRadioGroup
        name="follow"
        onChange={(e) => onMetaNofollowChange(e.target.value === 'true')}
        options={followOptions}
        value={metatags.nofollow}
        >
          Following:
        </ZRadioGroup>
    </div>
    <p className={style['meta-tags__note']}>
      *Edit or update the canonical url in the Url & Paths section above.
    </p>
  </div>
);

export default MetatagsView;
