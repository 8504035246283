import { combineReducers } from 'redux'
import sectionsT from './sections';
import photosT from './photos';
import metatags from './meta-data';
import { addAtIndex, addIfMissing } from '../utils';
import {
  SET_TYPE,
  SET_SUB_NAV,
  DELETE_SUB_NAV,
  SET_ITINERARIES,
  DISABLE_SAVE,
  ENABLE_SAVE,
  SET_HCS_SNIPPETS,
  SET_HCS_TITLE,
  SET_FAQ_TITLE,
  SET_FAQ_SECTIONS,
  SET_TITLE_FLAG,
  SET_HEADER_LINKS,
  SET_GALLERY_LINKS,
  SET_GALLERY_LINKS_TITLE,
  DELETE_GALLERY_LINKS_SECTION,
  SET_AGENT_LIST,
} from '../actions/landing-page-editor/action-types';
import {
  RECEIVED_LANDING_PAGE,
  REQUEST_LANDING_PAGE,
  REQUEST_LANDING_PAGE_FAILED,
  REQUEST_NEW_LANDING_PAGE,
  LANDING_PAGE_SAVED,
  LANDING_PAGE_SAVE_REQUESTED,
} from '../actions/shared/similar-action-types';
import{
  SET_META_DATA,
  SET_META_DESCRIPTION,
  SET_META_TITLE,
  SET_NOFOLLOW,
  SET_NOINDEX,
  SET_CANONICAL,
} from '../actions/meta-tags';
import {
  BREADCRUMB_ACTION_NAMES
} from "../actions/shared/breadcrumb-action-types";
import {
  ERROR_ACTION_NAMES,
} from '../actions/shared/error-action-types';
import {
  EXPIRATION_ACTION_NAMES
} from '../actions/shared/expiration-action-types';
import {
  LOCATION_ACTION_NAMES
} from '../actions/shared/location-action-types';
import {
  PHOTO_ACTION_NAMES,
} from '../actions/shared/photo-action-types';
import {
  SECTION_ACTION_NAMES,
} from '../actions/shared/section-action-types';
import {
  TEASER_ACTION_NAMES,
} from '../actions/shared/teaser-action-types';
import {
  TITLE_ACTION_NAMES
} from '../actions/shared/title-action-types';
import {
  SET_REVIEW_SEARCH,
} from '../actions/reviews';

const landingPage = (
  state = {
    title: null,
    summary: '',
    sectionIds: [],
    metatags: {},
    footerSectionIds: [],
    content: {},
    navigationBar: {},
    itineraries: [],
    helpfulContent: {},
    faq: {
      title: '',
      sections: [],
    },
  },
  action
) => {
  switch (action.type) {
    case RECEIVED_LANDING_PAGE:
      const newState = {
        ...state,
        ...action.entity,
      };
      if (state.agentList && !action.entity?.agentList) {
        delete newState.agentList;
      }
      return newState;
    case TITLE_ACTION_NAMES.LANDING_PAGE.SET_TITLE:
      return {
        ...state,
        title: action.value,
      };
    case TITLE_ACTION_NAMES.LANDING_PAGE.SET_SUMMARY:
      return {
        ...state,
        summary: action.value,
      };
    case SECTION_ACTION_NAMES.LANDING_PAGE.ADD_SECTION:
      return {
        ...state,
        sectionIds: addAtIndex(state.sectionIds, action.id, action.index),
      };
    case SECTION_ACTION_NAMES.LANDING_PAGE.REMOVE_SECTION:
      return {
        ...state,
        sectionIds : state.sectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.LANDING_PAGE.ADD_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds: addAtIndex(state.footerSectionIds, action.id, action.index)
      };
    case SECTION_ACTION_NAMES.LANDING_PAGE.REMOVE_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds : state.footerSectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.LANDING_PAGE.REORDER_FOOTER_SECTIONS:
      return {
        ...state,
        footerSectionIds : [...action.list],
      };
    case PHOTO_ACTION_NAMES.LANDING_PAGE.SET_HEADER_PHOTO:
      return {
        ...state,
        headerPhotoId : action.photo.id,
      };
    case PHOTO_ACTION_NAMES.LANDING_PAGE.REMOVE_HEADER_PHOTO: {
      let {headerPhotoId, heroImage, ...rest} = state;
      return {
        ...rest
      };
    }
    case PHOTO_ACTION_NAMES.LANDING_PAGE.SET_MOBILE_HEADER_PHOTO:
      return {
        ...state,
        mobileHeaderPhotoId: action.photo.id
      };
    case PHOTO_ACTION_NAMES.LANDING_PAGE.REMOVE_MOBILE_HEADER_PHOTO: {
      let {mobileHeaderPhotoId, mobileHeroImage, ...rest} = state;
      return {
        ...rest
      }
    }
    case SET_META_DATA:
    case SET_META_TITLE:
    case SET_META_DESCRIPTION:
    case SET_NOFOLLOW:
    case SET_NOINDEX:
    case SET_CANONICAL:
      return {
        ...state,
        metatags: metatags(state.metatags, action)
      };
    case TEASER_ACTION_NAMES.LANDING_PAGE.SET_CTA_TITLE:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          title: action.value,
        },
      };
    case TEASER_ACTION_NAMES.LANDING_PAGE.SET_CTA_STEPS:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          steps: action.value,
        },
      };
    case LOCATION_ACTION_NAMES.LANDING_PAGE.ADD_LOCATION:
      return {
        ...state,
        locations: addIfMissing(action.value, state.locations),
      };
    case LOCATION_ACTION_NAMES.LANDING_PAGE.SET_LOCATIONS:
      return {
        ...state,
        locations: [...action.list]
      };
    case SET_TYPE:
      return {
        ...state,
        type: action.value,
      };
    case EXPIRATION_ACTION_NAMES.LANDING_PAGE.SET_EXPIRATION_DATE:
      return {
        ...state,
        expirationDate: action.value,
      };
    case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.SET_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle : action.title,
      };
    case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.ADD_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.data
      };
    case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.DELETE_BREADCRUMBS:
      return {
        ...state,
        breadcrumb: null
      };
    case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.DELETE_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle : null,
      };
    case SET_SUB_NAV:
      return {
        ...state,
        navigationBar: {
          ...state.navigationBar,
          links: action.data,
        },
      };
    case DELETE_SUB_NAV:
      return {
        ...state,
        navigationBar: {},
      };
    case SET_ITINERARIES:
      return {
        ...state,
        itineraries: action.data
      };
    case SET_HCS_SNIPPETS:
      return {
        ...state,
        helpfulContent: {
          ...state.helpfulContent,
          links: action.data
        },
      };
    case SET_HCS_TITLE:
      return {
        ...state,
        helpfulContent: {
          ...state.helpfulContent,
          title: action.data
        },
      };
    case SET_FAQ_SECTIONS:
      return {
        ...state,
        faq: {
          ...state.faq,
          sections: action.data
        },
      };
    case SET_FAQ_TITLE:
      return {
        ...state,
        faq: {
          ...state.faq,
          title: action.data
        },
      };
    case SET_TITLE_FLAG:
      return {
        ...state,
        flagCountryCode: action.data,
      };
    case SET_GALLERY_LINKS:
      return {
        ...state,
        galleryLinks: {
          ...state.galleryLinks,
          links: action.links,
        }
      }
    case SET_GALLERY_LINKS_TITLE:
      return {
        ...state,
        galleryLinks: {
          ...state.galleryLinks,
          title: action.title,
        }
      };
    case DELETE_GALLERY_LINKS_SECTION:
      const { galleryLinks, ...remaining } = state;
      return {
        ...remaining,
      };
    case PHOTO_ACTION_NAMES.LANDING_PAGE.SET_GALLERY_PHOTO:
      const linkToUpdate = { ...state.galleryLinks.links[action.index] };
      linkToUpdate.image = { ...action.photo };
      const updatedLinks = [...state.galleryLinks.links];
      updatedLinks[action.index] = linkToUpdate;
      return {
        ...state,
        galleryLinks: {
          ...state.galleryLinks,
          links: updatedLinks,
        },
      };
    case SET_REVIEW_SEARCH:
      return {
        ...state,
        reviewSearch: action.data,
      };
    case SET_HEADER_LINKS:
      if (!state.headerLinks?.title && !action.data?.title) {
        return {
          ...state,
          headerLinks:{
            title: "Top Destinations",
            links: action.data.links,
          }
        }
      }
      return {
        ...state,
        headerLinks: action.data,
      };
    case SET_AGENT_LIST:
      return {
        ...state,
        agentList: action.data,
      };
    default:
      return state;
  }
};

const editor = (
  state = {
    isFetching: true,
    isSaving: false,
    saved: true,
    notFound: false,
    saveDisabled: false,
    disabledMessage: null,
  },
  action ) => {
    switch(action.type){
      case REQUEST_LANDING_PAGE:
        return {
          ...state,
          isFetching: true,
        };
      case REQUEST_LANDING_PAGE_FAILED:
        return {
          ...state,
          isFetching: false,
          notFound: true,
        };
      case REQUEST_NEW_LANDING_PAGE:
        return{
          ...state,
          isFetching: true,
          saved: false,
        };
      case LANDING_PAGE_SAVE_REQUESTED:
        return {
          ...state,
          isSaving: true,
          saved: false,
        };
      case LANDING_PAGE_SAVED:
        return {
          ...state,
          isSaving: false,
          saved: true,
        };
      case LOCATION_ACTION_NAMES.LANDING_PAGE.SET_COUNTRY_LIST:
        return {
          ...state,
          countries: action.list,
        };
      case PHOTO_ACTION_NAMES.LANDING_PAGE.DISABLE_SAVE:
        return {
          ...state,
          saveDisabled: true,
          disabledMessage: action.message,
        };
      case PHOTO_ACTION_NAMES.LANDING_PAGE.ENABLE_SAVE:
        return {
          ...state,
          saveDisabled: false,
          disabledMessage: null,
        };
      case RECEIVED_LANDING_PAGE:
        return{
          ...state,
          isFetching: false,
          notFound: false,
          lastUpdate: action.receivedAt,
          disabledSaveForDate: false,
          [action.id] : landingPage({
              ...state[action.id],
              ...action.entity
            }, action),
        };
      case TITLE_ACTION_NAMES.LANDING_PAGE.SET_TITLE:
      case TITLE_ACTION_NAMES.LANDING_PAGE.SET_SUMMARY:
      case SET_META_DATA:
      case SET_META_TITLE:
      case SET_META_DESCRIPTION:
      case SET_NOFOLLOW:
      case SET_NOINDEX:
      case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.DELETE_BREADCRUMBS:
      case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.ADD_BREADCRUMB:
      case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.DELETE_BREADCRUMB_TITLE:
      case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.SET_BREADCRUMB_TITLE:
      case SET_CANONICAL:
      case LOCATION_ACTION_NAMES.LANDING_PAGE.ADD_LOCATION:
      case LOCATION_ACTION_NAMES.LANDING_PAGE.SET_LOCATIONS:
      case SET_TYPE:
      case EXPIRATION_ACTION_NAMES.LANDING_PAGE.SET_EXPIRATION_DATE:
      case SET_SUB_NAV:
      case DELETE_SUB_NAV:
      case SET_ITINERARIES:
      case SET_HCS_SNIPPETS:
      case SET_HCS_TITLE:
      case SET_FAQ_SECTIONS:
      case SET_FAQ_TITLE:
      case SET_TITLE_FLAG:
      case SET_HEADER_LINKS:
      case SET_GALLERY_LINKS:
      case SET_GALLERY_LINKS_TITLE:
      case DELETE_GALLERY_LINKS_SECTION:
      case SET_AGENT_LIST:
        return {
          ...state,
          saved: false,
          [action.id] : landingPage(state[action.id], action),
        };
      case TEASER_ACTION_NAMES.LANDING_PAGE.SET_CTA_TITLE:
      case TEASER_ACTION_NAMES.LANDING_PAGE.SET_CTA_STEPS:
        return {
          ...state,
          [action.id] : landingPage(state[action.id], action),
        };
      case SECTION_ACTION_NAMES.LANDING_PAGE.ADD_SECTION:
      case SECTION_ACTION_NAMES.LANDING_PAGE.REMOVE_SECTION:
      case SECTION_ACTION_NAMES.LANDING_PAGE.ADD_FOOTER_SECTION:
      case SECTION_ACTION_NAMES.LANDING_PAGE.REMOVE_FOOTER_SECTION:
      case SECTION_ACTION_NAMES.LANDING_PAGE.REORDER_FOOTER_SECTIONS:
      case PHOTO_ACTION_NAMES.LANDING_PAGE.SET_HEADER_PHOTO:
      case PHOTO_ACTION_NAMES.LANDING_PAGE.REMOVE_HEADER_PHOTO:
      case PHOTO_ACTION_NAMES.LANDING_PAGE.SET_MOBILE_HEADER_PHOTO:
      case PHOTO_ACTION_NAMES.LANDING_PAGE.REMOVE_MOBILE_HEADER_PHOTO:
      case PHOTO_ACTION_NAMES.LANDING_PAGE.SET_GALLERY_PHOTO:
        return {
          ...state,
          saved: false,
          [action.entityId] : landingPage(state[action.entityId], action)
        };
      case SET_REVIEW_SEARCH: {
        if (action.entityType === 'landingPage') {
          return {
            ...state,
            [action.entityId] : landingPage(state[action.entityId], action)
          }
        }
        return state;
      }
      case EXPIRATION_ACTION_NAMES.LANDING_PAGE.EXPIRATION_DATE_IS_VALID:{
        return {
          ...state,
          disabledSaveForDate: action.value,
        }
      }
      default:
        return state;
    }
};

const errors = (state = {}, action) => {
  switch(action.type) {
    case ERROR_ACTION_NAMES.LANDING_PAGE.SET_TITLE_ERROR:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          title: action.error,
        }
      };
    case ERROR_ACTION_NAMES.LANDING_PAGE.CLEAR_TITLE_ERROR:
      if (state[action.id]) {
        const { title, ...remaining } = state[action.id];
        return {
          ...state,
          [action.id]: remaining,
        }
      }
      return state;
    default:
      return state;
  }
};

const landingPageEditor = combineReducers({
  editor,
  sections: sectionsT('landing_page'),
  photos: photosT('landing_page'),
  errors,
});
export default landingPageEditor;
