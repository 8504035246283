import { connect } from 'react-redux';
import NewsSourceEditorContainer from './news-source-editor-container';
import {
  saveNewsSource,
  setName,
  createLogo,
  deleteLogo,
  updateLogo,
} from '../../../state/actions/news-editor/news-sources';
import { setSource } from '../../../state/actions/news-editor/news-snippets';

const mapStateToProps = (state, ownProps) => ({
  error: state.news.newsSources.error,
  isFetching: state.news.newsSources.isFetching,
  newsSource: state.news.newsSources[ownProps.id] || {},
});

const mapDispatchToProps = (dispatch) => ({
  createLogo: (id, val) => dispatch(createLogo(id, val)),
  saveNewsSource: (id, onSuccess) => dispatch(saveNewsSource(id, onSuccess)),
  deleteLogo: (id, val) => dispatch(deleteLogo(id, val)),
  setName: (id, val) => dispatch(setName(id, val)),
  setSnippetSource: (snippetId, source) => dispatch(setSource(snippetId, source)),
  updateLogo: (id, val) => dispatch(updateLogo(id, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsSourceEditorContainer);
