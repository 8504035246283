import { useEffect } from 'react';
import { ZApiClient, Environment } from '@marageti/z4-sdk/lib/client';
import LocalStorageService from '../utils/local-storage-service';

/**
 * To use locally running services, set the `env` argument to `Environment.local` to use both
 * the Traveler Service and Photo Service locally.
 *
 * Alternatively, you can use the boolean arguments `useLocalTApi` and `useLocalPhotosService` to
 * specify which service is running locally. The local URLs can be found in the `DefaultApiClient` file.
 *
 *  new ZApiClient(
 *    env: Environment,
 *    token?: string,
 *    axiosConfig?: AxiosRequestConfig,
 *    useLocalTApi?: boolean,
 *    useLocalPhotosService?: boolean
 *  );
 */

const environments = {
  dev: Environment.dev,
  staging: Environment.staging,
  qa: Environment.qa,
  prod: Environment.prod,
  local: Environment.local,
};

const localStorage = new LocalStorageService();
const env = process.env.REACT_APP_STAGE ? environments[process.env.REACT_APP_STAGE] : Environment.dev;
const apiClientZ4 = new ZApiClient(env, localStorage.getFromLocalStorage('accessToken') || '');

const useApiClient = () => {
  const token = localStorage.getFromLocalStorage('accessToken') || '';
  useEffect(() => {
    const currentToken = apiClientZ4.instance.getAuthToken();
    if (currentToken !== token) {
      apiClientZ4.instance.setAuthToken(token || '');
    }
  }, [token]);
  return apiClientZ4;
};

export default useApiClient;
