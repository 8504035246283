import React from 'react';
import PropTypes from 'prop-types';
import styles from './z-modal.module.scss';

const ZModalOverlay = ({
  onOverlayClick, onTouchMove, onTouchStart,
}) => (
  <div
    className={styles['modal-overlay']}
    id="z-modal-overlay"
    label="overlay"
    onClick={onOverlayClick}
    onKeyUp={(e) => { if (e.keyCode === 13) onOverlayClick(e); }}
    onTouchMove={onTouchMove}
    onTouchStart={onTouchStart}
    role="button"
    tabIndex={0}
  />
);

ZModalOverlay.propTypes = {
  onOverlayClick: PropTypes.func,
  onTouchMove: PropTypes.func,
  onTouchStart: PropTypes.func,
};

ZModalOverlay.defaultProps = {
  onOverlayClick: () => {},
  onTouchMove: () => {},
  onTouchStart: () => {},
};

export default ZModalOverlay;
