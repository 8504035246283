import React from 'react';
import { ReactComponent as CheckmarkSVG } from '../../../assets/logos/checkmark.svg';
import style from './z-versions-row.module.scss';
import sharedStyle from '../../shared/styles/shared.module.scss';

const ZVersionsRow = (props) => {
  const rowData = mapRowData(props.legend, props.data);
  const publishedRow = !!rowData[props.selectFieldIndex];
  return (
    <div
      className={`${style['z-versions-row']} ${publishedRow ? style['z-versions-row__selected'] : ''}`}
      style={props.rowStyle}
    >
      {rowData.map((ele, i) => {
        if (i !== props.selectFieldIndex) {
          return (
            <span
              className={style['z-versions-row__item']} 
              style={props.rowItemStyle}
              key={`${ele}-${i}`}
            >
              {ele ? <div dangerouslySetInnerHTML={{ __html: ele }}/> : '-'}
            </span>
          )
        } else {
          return (
            <span
              className={`
                ${style['z-versions-row__item']}
                ${ele ? style['z-versions-row__item__selected'] : ''}
              `} 
              style={props.rowItemStyle}
              key={`${ele}-${i}`}
            >
              {ele
                ? <><CheckmarkSVG className={style.checkmark} /> Published</>
                : 'Draft'
              }
            </span>
          )
        }
      })}
      {!!props.actions &&
        <span className={style['z-versions-row__actions']} style={props.rowItemStyle}>
          {Object.keys(props.actions).map((action, i) => {
            const disableAction = (action === 'publish' && publishedRow) 
              || (action === 'editor view' && props.index === 0)
              || (action === 'make latest' && props.index === 0)
            return !disableAction && (
              <button
                className={[`${sharedStyle['button']} ${style['button']}`]}
                disabled={disableAction}
                key={`${action}-${i}`}
                onClick={props.onActionClick(action, props.data)}
                type='button'
              >
                {action}
              </button>
          )})}
          {publishedRow && (
              <button
              className={[`${sharedStyle['button']} ${style['button']}`]}
              key="unpublish-action"
              onClick={() => props.onClickUnpublish(props.data)}
              type='button'
            >
              unpublish
            </button>
          )}
        </span>
      } 
    </div>
  )
};

const mapRowData = (legend, data) => {
  let resp = legend.map((ele) => data[ele]);
  return resp;
};

export default ZVersionsRow;
