import React from 'react';
import { connect } from 'react-redux';
import ZTextAreaContainer from './z-text-area-container';

const ZTextAreaRedux = (props) => (
  <ZTextAreaContainer {...props} />
);

const mapStateToProps = (state) => ({
  readOnly: state.versions.readOnly,
});

export default connect(mapStateToProps)(ZTextAreaRedux);
