export const SET_ERROR = 'SET_ERROR';
export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const SET_GEO_COUNTRIES = 'SET_GEO_COUNTRIES';
export const setGeoCountries = (countries) => ({
  type: SET_GEO_COUNTRIES,
  countries,
});

export const SET_TRF_COUNTRIES = 'SET_TRF_COUNTRIES';
export const setTRFCountries = (countries) => ({
  type: SET_TRF_COUNTRIES,
  countries,
});

export const SET_SERVED_LOCATIONS_MAP = 'SET_SERVED_LOCATIONS_MAP';
export const setServedLocationsMap = (servedLocationsMap) => ({
  type: SET_SERVED_LOCATIONS_MAP,
  servedLocationsMap,
});

export const SET_SERVED_LOCATIONS_MAP_REVERSE = 'SET_SERVED_LOCATIONS_MAP_REVERSE';
export const setServedLocationsMapReverse = (servedLocationsMapReverse) => ({
  type: SET_SERVED_LOCATIONS_MAP_REVERSE,
  servedLocationsMapReverse,
});

export const SET_SERVED_LOCATIONS_OPTIONS = 'SET_SERVED_LOCATIONS_OPTIONS';
export const setServedLocationsOptions = (servedLocationsOptions) => ({
  type: SET_SERVED_LOCATIONS_OPTIONS,
  servedLocationsOptions,
});

export const SET_COMPANY_LOCATIONS = 'SET_COMPANY_LOCATIONS';
export const setCompanyLocations = (locations) => ({
  type: SET_COMPANY_LOCATIONS,
  locations,
})
