import { push } from 'connected-react-router';
import ItineraryClient from '../../../utils/client/itinerary';
import ZApiClient from '../../../utils/client/z-api-client';
import { Itinerary } from '@zicasso/zicasso-sdk/lib/itinerary';
import {
  receivedPhotosT,
} from '../shared/photo';
import {
  setErrors,
} from './errors'
import {
  fetchEntityVersion,
  addSavedEntityVersion,
  versionSuccess,
} from '../versions';
import { fetchTuid } from '../../../utils/fetch/tuid';
import {getCountriesT} from "../shared/location";
import {receivedSectionsT, receivedFooterSectionsT} from "../shared/section";

import {
  REQUEST_ITINERARY,
  REQUEST_NEW_ITINERARY,
  RECEIVED_ITINERARY,
  ITINERARY_SAVE_REQUESTED,
  ITINERARY_SAVED,
  ITINERARY_REQUEST_FAILED,
} from '../shared/similar-action-types';
import {
  ENABLE_SAVE,
  DISABLE_SAVE,
  SET_DEPARTURE_NOTES,
  ADD_PLACE_VISITED,
  SET_PLACES_VISITED,
  SET_HIGHLIGHTS,
  SET_DESCRIPTION,
  SET_PRICE,
  SET_PRICE_COMMENTS,
  SET_INCLUDED_NOTES,
  SET_COMPANIES,
  SET_COMPANY,
  UNSET_COMPANY,
  SET_DAYS,
  REMOVE_DAY,
  ADD_DAY,
  SET_SHOW_PRICE,
} from './action-types';


const itineraryClient = new ItineraryClient();

export const disableSave = (message = null) => ({
  type: DISABLE_SAVE,
  message,
});

export const enableSave = () => ({
  type: ENABLE_SAVE,
});

const requestItinerary = () => ({
  type: REQUEST_ITINERARY,
});

const requestNewItinerary = () => ({
  type: REQUEST_NEW_ITINERARY,
});

const receivedItinerary = (data) => ({
  type: RECEIVED_ITINERARY,
  receivedAt: Date.now(),
  id: data.id,
  itinerary: data,
});

export const saveRequested = () => ({
  type: ITINERARY_SAVE_REQUESTED,
});

export const itinerarySaved = () => ({
  type: ITINERARY_SAVED,
});

export const itineraryRequestFailed = () => ({
  type: ITINERARY_REQUEST_FAILED,
});

export const setDepartureNotes = ( text, id) => ({
  type: SET_DEPARTURE_NOTES,
  value: text,
  id,
});

export const addPlace = (place, id) => ({
  type: ADD_PLACE_VISITED,
  value: place,
  id,
});

export const setPlacesVisited = (list, id) => ({
  type: SET_PLACES_VISITED,
  list,
  id,
});

export const setHighlights = (text, id) => ({
  type: SET_HIGHLIGHTS,
  value: text,
  id,
});

export const setDescription = (text, id) => ({
  type: SET_DESCRIPTION,
  value: text,
  id,
});

export const setPrice = (value, id) => ({
  type: SET_PRICE,
  value,
  id,
});

export const setPriceComments = (text, id) => ({
  type: SET_PRICE_COMMENTS,
  value: text,
  id,
});

export const setIncludedNotes = (text, id ) => ({
  type: SET_INCLUDED_NOTES,
  value: text,
  id,
});

export const setCompanies = (map) => ({
  type: SET_COMPANIES,
  map,
});

export const setCompany = (data, id) => ({
  type: SET_COMPANY,
  data,
  id,
});

export const removeCompany = (id) => ({
  type: UNSET_COMPANY,
  id,
});

export const setDays = (entityId, dayIds) => ({
  type: SET_DAYS,
  id: entityId,
  list: dayIds,
});

export const removeDay = (entityId, dayId) => ({
  type: REMOVE_DAY,
  id: entityId,
  dayId,
});

export const addDay = (entityId, dayId, index) => ({
  type: ADD_DAY,
  id: entityId,
  dayId,
  index,
});

export const setShowPrice = (bool, id) => ({
  type: SET_SHOW_PRICE,
  id,
  data: bool
});

export const createNewItinerary = () => {
  return dispatch => {
    dispatch(requestNewItinerary());
    return fetchTuid()
      .then(val => {
        let {id} = val;
        dispatch(receivedItinerary({
          id: id,
          title: null,
          summary: '',
          sectionIds: [],
          metatags: {},
          dayIds:[],
        }));
        dispatch(push(`/itineraries/${id}`));
      })
  }
};

const shouldFetchItinerary = (state, id, updateId) => {
  const {editor} = state.itineraries.itinerary;
  if(!editor[id]) {
    return true;
  } else if (editor[id] && editor[id].updateId !== updateId) {
    return true;
  } else {
    return false;
  }
};

const receivedItineraryState = (itineraryState) => {
  return dispatch => {
    let {id:currentId} = itineraryState;
    dispatch(receivedItinerary(itineraryState[currentId]));
    dispatch(receivedPhotosT('itinerary', itineraryState.photos));
    dispatch(receivedSectionsT('itinerary', itineraryState.days));
    dispatch(receivedFooterSectionsT('itinerary', itineraryState.sections));
  }
};

export const getCompanies = () => {
  return dispatch => {
    ZApiClient
      .getCompaniesClient()
      .getCompanies({
        limit: 1000,
        published: true,
        fields: "id,name"
      })
      .then((companies)=> {
        const includedTCs = {};
        if(Array.isArray(companies)) {
          companies.forEach(ele => {
            includedTCs[ele.id] = ele.name
          });
        }
        dispatch(setCompanies(includedTCs));
      })
  }
};

export const fetchItinerary = (id, updateId) => {
  return dispatch => {
    dispatch(requestItinerary());

    const req = !!updateId
    ? ZApiClient.getItinerariesClient().getItineraryVersion(id, updateId)
    : ZApiClient.getItinerariesClient().getItinerary(id);
    return req.then((res)=> {
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return itineraryClient.getState(res);
    }).then((newState) => {
      dispatch(receivedItineraryState(newState));
    }).catch((e)=>{
      if(e.message === 'NOT_FOUND'){
        dispatch(itineraryRequestFailed());
      }
      console.error('Error Fetching Itinerary', e);
    })
  }
};

export const fetchItineraryIfNeeded = (id, updateId) => {
  return (dispatch, getState) => {
    const { countries } = getState().itineraries.itinerary.editor;
    const shouldFetch = shouldFetchItinerary(getState(), id, updateId);

    if (!countries || countries.length === 0)  {
      dispatch(getCountriesT('itinerary'));
    }
    dispatch(setErrors(id));
    if(shouldFetch) {
      dispatch(fetchItinerary(id, updateId));
    }
  }
};

export const fetchItineraryVersion = (id, versionId) => {
  return dispatch => (
    dispatch(fetchEntityVersion('itineraries', id, versionId))
    .then((res) => {
      dispatch(versionSuccess(id, versionId));
      return res;
    })
    .catch(console.error)
  )
};

const createOrUpdateEntity = (data, create = true) => {
  if(create){
    return ZApiClient.getItinerariesClient().createItinerary(new Itinerary(data));
  } else {
    return ZApiClient.getItinerariesClient().updateItinerary(new Itinerary(data));
  }
};

export const saveEntity = (entityId) => {
  return (dispatch, getState) => {
    dispatch(saveRequested());
    ZApiClient
    .getItinerariesClient()
    .exists(entityId)
    .then((res)=> {
      const shouldCreate = !res;
      const currentState = getState();
      const { itinerary } = currentState.itineraries;
      const itineraryEntity = itineraryClient.getItinerary(entityId, itinerary);
      return createOrUpdateEntity(itineraryEntity, shouldCreate);
    }).then((result)=> {
      dispatch(itinerarySaved());
      const { id, updateId, userId, updatedAt } = result;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return itineraryClient.getState(result);
    }).then((newState)=> {
      dispatch(receivedItineraryState(newState));
    }).catch((e)=>{
      console.error('Error Saving Itinerary', e);
      console.error(e.stack);
    });
  }
};

export const makeLatest = (id, updateId) => (dispatch) => {
  dispatch(requestItinerary());
  return ZApiClient
  .getItinerariesClient()
  .getItineraryVersion(id, updateId)
    .then((res) => {
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return createOrUpdateEntity(res, false);
    })
    .then((res) => {
      const { id, updateId, userId, updatedAt } = res;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return itineraryClient.getState(res);
    })
    .then((newState) => {
      dispatch(receivedItineraryState(newState));
    })
    .catch((e) => {
      if(e.message === 'NOT_FOUND'){
        dispatch(itineraryRequestFailed());
      }
      console.error('Error Fetching Itinerary', e);
    });
};

export const validateEntityVersion = (entityId, versionId) => (dispatch, getState) => {
  const paths = getState().paths[entityId];
  return dispatch(fetchItineraryVersion(entityId, versionId))
    .then((res) => {
      const entityToValidate = {
        ...res,
        ...paths && { paths }
      };
      return itineraryClient.validateForPublish(entityToValidate);
    })
};
