import React from 'react';
import { connect } from 'react-redux';
import HomeView from './home-view';

const HomeRedux = (props) => (
  <HomeView {...props}/>
)

const mapStateToProps = (state) => ({
  username: state.users.user.username,
})

export default connect(mapStateToProps)(HomeRedux);
