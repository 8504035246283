import React from 'react';
import ZTableRow from './z-table-row';
import style from './z-table.module.scss'
import startCase from 'lodash/startCase';

const ZTableView = (props) => (
  <div style={{maxWidth: '100%', ...props.style}}>
    <div 
      className={style['ztable__header-wrapper']} 
      style={props.stickyHeader
         ? { top: '78px', position: 'sticky', backgroundColor: '#ffffff', zIndex: 200 } 
         : null
       }
    >
      {props.headers.map((ele, i) => (
        <span
          onClick={() => props.onHeaderClick(i)}
          className={style['ztable__header-item']}
          style={{ backgroundColor: props.selectedHeaderIndex === i ? '#eee' : 'white'}}
          key={`${ele}-${i}`}
        >
          <>
          {startCase(ele)}
          { props.sortable && (
            <>
              { props.selectedHeaderIndex === i && (
                <>
                  { props.isChronological && <span>&#8593;</span> }
                  { !props.isChronological && <span>&#8595;</span> }
                </>
              )}
              { props.selectedHeaderIndex !== i && <span>&#8597;</span> }
            </>
          )}
          </>
        </span>
      ))}
      {/*
      {props.actions && Object.keys(props.actions).map((key) => (
        <span className={style['ztable__header-actions']} key={key} />
      ))}
      */}
    </div>
    <div 
      className={style['ztable__row-wrapper']} 
      style={{ ...props.rowWrapperStyle, ...props.stickyHeader&&{maxHeight: '100%', overflow: 'visible'}}}
    >
      {props.children}
      {props.rows && props.rows.map((ele, i) => (
        <ZTableRow
          actions={props.actions}
          data={ele} 
          key={`${ele[0]}-${i}`} 
          legend={props.headers} 
          onActionClick={props.onActionClick} 
          rowItemStyle={props.rowItemStyle}
          rowStyle={props.rowStyle}
          style={props.style} 
        />
      ))}
    </div>
  </div>
);

export default ZTableView;
