import React, { memo } from 'react';
import styles from './career.module.scss';

const CareerView = memo(({
  onClick,
  openInEditor,
  id,
  location,
  title,
  url,
}) => (
  <div aria-selected={openInEditor}>
    <div className={styles['position']} onClick={onClick}>
      <h3>{title}</h3>
      <span>{location}</span>
      { url && (
        <a
          className={styles['fake-link']}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          key={id}
        >
          {url}
        </a>
      )}
    </div>
  </div>
));

export default CareerView;
