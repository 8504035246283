import React from 'react';
import { fetchTuid } from '../../../utils/fetch/tuid';
import CustomItineraryView from './custom-itinerary-view';

const CustomItineraryContainer = ({
  days,
  dayErrors,
  readOnly,
  reorderDays,
  updateDay,
  durationChange,
}) => {

  /** Calculates computed values for days and ensures the start and end day are consistent */
  const mapDays = (dayList) => {
    let totalDuration = 0;
    return dayList.map((ele, i)=>{
      if(i === 0){
        ele.startDay = 1;
        ele.endDay = ele.duration;
      } else {
        ele.startDay = totalDuration + 1;
        ele.endDay = totalDuration + ele.duration;
      }
      ele.dayNumber = (ele.startDay === ele.endDay) ? `${ele.endDay}` : `${ele.startDay}-${ele.endDay}`;
      totalDuration += ele.duration;
      return ele;
    });
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    let items = reorder(
      days,
      result.oldIndex,
      result.newIndex
    );
    const newDayList = mapDays(items);
    const newSections = newDayList.reduce((prev, cur) => {
      prev[cur.id] = {...cur};
      return prev;
    }, {});
    const dayIds = newDayList.map(ele => ele.id);
    reorderDays(dayIds, newSections);
  }

  const getTotalDuration = (days) => {
    return days.reduce((prev, cur) => {
      return prev + cur.duration;
    }, 0);
  }

  const onDurationChange = (newDay, index) => {
    const dayList = [...days];
    dayList.splice(index, 1, newDay);
    const newDayList = mapDays(dayList)
    const newSections = newDayList.reduce((prev, cur) => {
      prev[cur.id] = {...cur};
      return prev;
    }, {});
    durationChange(newSections);
  }

  const onDeleteDay = (index) => {
    const dayList = [...days];
    dayList.splice(index, 1);
    const newDayList = mapDays(dayList);
    const newSections = newDayList.reduce((prev, cur) => {
      prev[cur.id] = {...cur};
      return prev;
    }, {});
    const dayIds = newDayList.map(ele => ele.id);
    reorderDays(dayIds, newSections);
  }

  const addDay = (index) => {
    const dayList = [...days];
    fetchTuid().then(({id})=> {
      let defaultDay = {
        id:id,
        duration :  1,
        title: '',
        description: '',
        included : [],
        locations: [],
        meals: [],
      }
      dayList.splice(index, 0, defaultDay);
      const newDayList = mapDays(dayList);
      const newSections = newDayList.reduce((prev, cur) => {
        prev[cur.id] = {...cur};
        return prev;
      }, {});
      const dayIds = newDayList.map(ele => ele.id);
      reorderDays(dayIds, newSections);
    });
  }
  
  return (
    <CustomItineraryView 
      days={days}
      dayErrors={dayErrors}
      updateDay={updateDay}
      tripDuration={getTotalDuration(days)}
      onDragEnd={onDragEnd}
      onDurationChange={onDurationChange}
      onDeleteDay={onDeleteDay}
      readOnly={readOnly}
      addDay={addDay}
    />
  )
}

export default CustomItineraryContainer;
