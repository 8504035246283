import React from 'react';
import ZTable from '../z-table';
import ZSpinner from '../z-spinner';
import sharedStyle from '../shared/styles/shared.module.scss';

const UsageReferencesView = ({
  publicationReferenceActions,
  usageReferenceActions,
  error,
  loading,
  onClickRefresh,
  publicationReferences,
  usageReferences,
}) => (
  <>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h2>Pages that reference this page:</h2>
      <button
        className={sharedStyle['button']}
        onClick={onClickRefresh}
        style={{ width: '100px'}}
        type="button"
      >
        Refresh
      </button>
    </div>
    {loading && (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ZSpinner />
      </div>
    )}
    {error && (
      <p>There was an error loading usage references.</p>
    )}
    <h2>Usage references:</h2>
    {!loading && usageReferences && 
      <ZTable 
        data={usageReferences} 
        headers={['entityTitle', 'entityId', 'entityName']} 
        actions={usageReferenceActions}
      />
    }
    <h2>Publication references:</h2>
    {!loading && publicationReferences && 
      <ZTable 
        data={publicationReferences} 
        headers={['parentEntityId', 'parentEntityUpdateId', 'entityName']} 
        actions={publicationReferenceActions}
      />
    }
  </>
);

export default UsageReferencesView;
