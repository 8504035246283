import React from 'react';
import { connect } from 'react-redux';
import {
  createPhotoT, removeHeaderPhotoT, updatePhotoT
} from '../../../state/actions/shared/photo';
import ContentContainer from './content-container';
import {setSummaryT} from "../../../state/actions/shared/title";
import {createSectionT, removeSectionT, reorderSectionsT} from "../../../state/actions/shared/section";
import {setEssentialInfoT} from "../../../state/actions/shared/essential-info"
import {setHelpfulInfoT} from "../../../state/actions/shared/helpful-info"

const ContentRedux = (props) => (
  <ContentContainer {...props}/>
);

const mapSectionIdToSection = (ids, sections, photos) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = {...sections[ele]};
      section.photo = photos[section.photoId] || {} ;
      return section;
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { guideId:id } = ownProps;
  const guide = state.guides.guide.editor[id] || {};
  const sectionState = state.guides.guide.sections;
  const photoState = state.guides.guide.photos;
  const { sectionIds } = guide || [];
  return{
    guide: guide,
    sections: mapSectionIdToSection(sectionIds, sectionState, photoState),
    photos: photoState,
    readOnly: state.versions.readOnly,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { guideId:id } = ownProps;
  return {
    onSummaryChange: text => dispatch(setSummaryT('guide', text, id)),
    onAddSectionClick: (article) => dispatch(createSectionT('guide', id, false, 'DATA', article)),
    onRemoveSectionClick: (sectionId) => dispatch(removeSectionT('guide', id, sectionId)),
    onSectionOrderChange: (sectionIds) => dispatch(reorderSectionsT('guide', id, sectionIds)),
    onDeleteHeaderPhoto: (entityId, headerPhotoId) => dispatch(removeHeaderPhotoT('guide', entityId, headerPhotoId)),
    onCreateHeaderPhoto: (entityId, imageData) => dispatch(createPhotoT('guide', entityId, 'header', imageData)),
    onChangePhoto: (imageData) => dispatch(updatePhotoT('guide', imageData)),
    onEssentialInfoChange: (text) => dispatch(setEssentialInfoT('guide', text, id)),
    onHelpfulInfoChange: (text) => dispatch(setHelpfulInfoT('guide', text, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentRedux);
