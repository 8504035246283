import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCompany, saveCompany, syncCompany } from '../utils/companies';
import { useCompaniesStore } from '../oryx-state';
import CompanyView from './company-view';
import FetchStatus from '../utils/fetch-status';

const validateCompany = (company) => {
  const ret = {
    published: false,
    firstName: false,
    activityLevel: true,
  }
  if (company.firstName) ret.firstName = true;
  if (!company.activityLevel) ret.activityLevel = false;
  if (company.activityLevel && company.activityLevel === 'NEW') ret.activityLevel = false;
  if (company.activityLeve && company.activityLevel === 'EXCLUDED') ret.activityLevel = false;
  if (ret.firstName && ret.activityLevel) ret.published = true;
  return ret;
}

const CompanyContainer = () => {
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.FETCHING);
  const [showModal, setShowModal] = useState(false);
  const [infoModalState, setInfoModalState] = useState({
    published: false,
    firstName: false,
    activityLevel: false,
    error: '',
  });

  const {
    companiesById,
    setCompanyById,
  } = useCompaniesStore();

  const { id } = useParams();

  useEffect(() => {
    fetchCompany(id)
      .then((res) => {
        setCompanyById(id, res);
        setFetchStatus(FetchStatus.SUCCESS);
      })
      .catch(() => {
        setFetchStatus(FetchStatus.ERROR);
      })
  }, [id]);

  const openModal = () => {
    setShowModal((state) => !state);
  }

  const onClickSave = () => {
    const company = companiesById[id];
    const {
      firstName,
      activityLevel,
      published,
    } = validateCompany(company);
    saveCompany(company)
      .then((res) => {
        if ('httpStatus' in res) throw new Error ('Error saving company');
        setCompanyById(id, res);
        setInfoModalState({
          error: '',
          firstName,
          activityLevel,
          published,
        })
        setShowModal(true);
      })
      .catch((e) => {
        console.warn('error saving company');
        setInfoModalState({
          ...infoModalState,
          error: `There was an error saving company: ${companiesById[id].name}`
        })
      })
  }

  const onClickSync = () => {
    const nodeId = companiesById[id].nodeId
    if (nodeId) {
      syncCompany(nodeId)
        .then((res) => {
          setCompanyById(id, res);
        })
        .catch(console.error);
    }
  }

  return (
    <CompanyView
      fetchStatus={fetchStatus}
      company={companiesById[id]}
      infoModalState={infoModalState}
      showModal={showModal}
      openModal={openModal}
      onClickSave={onClickSave}
      onClickSync={onClickSync}
    />
  );
};

export default CompanyContainer;
