import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { navigate } from '../../utils/window';
import {
  createArticleTermSearch,
  getSearch,
  getArticleTags,
  createFilterObject,
} from '../../utils/fetch/search';
import useInfiniteScroll from '../../hooks/use-infinite-scroll';
import useEntityCollection from '../../hooks/use-entity-collection';
import {
  SearchBoilerplate,
  SearchInput,
  AdvancedSearchInputs,
  SearchResults,
  TagsInput,
} from '../shared/components/search';
import {
  SortDropdown,
  MultipleSelectDropdown,
  defaultSort,
  getOptionsFromKeys,
  publicationStatuses as publicationOptions,
} from '../shared/components/filters';
import useDownloadTsv from '../../hooks/use-download-tsv';
import ResultCard from '../shared/components/result-card';
import ViewsChart from '../shared/components/view-chart';
import ZSpinner from '../z-spinner';

const onCreateClick = () => navigate('create/article');

const ArticlesContainer = () => {
  const [loading, setLoading] = useState(false);
  const [receivedAll, setReceivedAll] = useState(false);
  const [awaitingDownload, onClickExport] = useDownloadTsv('Article');
  const [term, setTerm] = useState('');
  const [searchRes, setSearchRes] = useState();
  const [entities, setEntites] = useState([]);
  const [sortvalue, setSortValue] = useState(defaultSort);
  const [tagValue, setTagValue] = useState(null);
  const [publicationStatus, setPublicationStatus] = useState([]);
  const results = useEntityCollection(entities);
  const searchInputRef = useRef();
  const tagsRef = useRef();

  useEffect(() => {
    if (!tagsRef.current) {
      getArticleTags()
        .then((res) => {
          tagsRef.current = getOptionsFromKeys(res.counts);
        });
      }
  }, []);

  useEffect(() => {
    //  Set initial search term if passed via uri
    var url = new URL(decodeURI(window.location.href));
    const urlParams = new URLSearchParams(url.search);
    if (searchInputRef.current) {
      searchInputRef.current.setValue(urlParams.get('term'));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const sort = sortvalue.field && !term ? [sortvalue] : [];
    const tags = tagValue ? tagValue : "";
    const filters = [];
    const pubFilter = createFilterObject(publicationStatus, 'publicationStatus');
    if (publicationStatus.length) filters.push(pubFilter);
    createArticleTermSearch(term, sort, tags, filters)
      .then((res) => {
        const {
          entities,
          ...rest
        } = res;
        setLoading(false);
        setSearchRes(rest);
        setEntites(entities)
        setReceivedAll(true);
      })
  }, [term, sortvalue, tagValue, publicationStatus]);

  const handleGetMoreResults = () => {
    if (searchRes?.id && searchRes.offset + 20 < searchRes.totalCount) {
      setLoading(true);
      getSearch(searchRes.id, searchRes.offset + 20)
        .then((res) => {
          const {
            entities,
            ...rest
          } = res;
          setLoading(false);
          setSearchRes(rest);
          setEntites((state) => [...state, ...entities]);
        })
    }
  };

  useInfiniteScroll(loading, handleGetMoreResults);

  const memo_onSearch = useCallback((val) => {
    setTerm(val);
    if (val) setSortValue(defaultSort);
  }, []);

  const onSortChange = (val) => {
    setSortValue(val || defaultSort);
  }

  const onTagsChange = (val) => {
    setTagValue(val);
  }

  const onStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setPublicationStatus(value);
  }

  return(
    <SearchBoilerplate
      title="Articles Admin"
      onCreateNew={onCreateClick}
      onExportClick={onClickExport}
      awaitingDownload={awaitingDownload}
      receivedAll={receivedAll}
    >
      <AdvancedSearchInputs
        primaryInputs={
          <SearchInput
            entityType='Article'
            receivedAll={receivedAll}
            onChange={memo_onSearch}
            ref={searchInputRef}
          />
        }
      >
        <Stack direction="row" spacing={1} marginBottom={1} sx={{width: '100%', alignItems: 'center'}}>
          <TagsInput
            disabled={!tagsRef.current?.length}
            options={tagsRef.current || []}
            onChange={onTagsChange}
            value={tagValue}
          />
          <MultipleSelectDropdown
            label="Publication Status"
            options={publicationOptions}
            value={publicationStatus}
            onChange={onStatusChange}
          />
          <SortDropdown
            disabled={!!term}
            onChange={onSortChange}
            value={sortvalue}
          />
        </Stack>
      </AdvancedSearchInputs>
      <SearchResults
        resultText={`(${results.length}) ${!receivedAll ? '(still fetching...)' : '' }`}
      >
        <>
          {results.map((item) => (
            <ResultCard
              entityType={'Article'}
              key={item.id}
              title={item.title}
              image={item.heroImage || {}}
              id={item.id}
              publishedVersionId={item.publishedVersionId}
              publicationStatus={item.publicationStatus}
              publishedVersionAt={item.publishedVersionAt}
              summary={item.summary}
              viewUri={item.viewUri}
              canonicalPath={item.canonicalPath}
              wordCount={item.wordCount}
              imageCount={item.imageCount}
              tagCount={item.tagCount}
              tags={item.locations}
              updatedAt={item.updatedAt}
              customContent={
                <ViewsChart id={item.id}/>
              }
            />
          ))}
          { loading &&
            <div
              style={{
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <ZSpinner/>
            </div>
          }
        </>
      </SearchResults>
    </SearchBoilerplate>
  );
};


export default ArticlesContainer;
