import LocalStorageService from '../local-storage-service';
const LocalStorage = new LocalStorageService();

export const getJSON = (url) => fetchJSON('GET', url);
export const putJSON = (url, reqBody) => fetchJSON('PUT', url, reqBody);
export const postJSON = (url, reqBody) => fetchJSON('POST', url, reqBody);
export const patchJSON = (url, reqBody) => fetchJSON('PATCH', url, reqBody);
export const deleteJSON = (url) => fetchJSON('DELETE', url);

function fetchJSON(method, url, reqBody) {
  const hasBody = (method === 'PUT' || method === 'POST' || method === 'PATCH');
  const userToken = LocalStorage.getFromLocalStorage('accessToken');

  const options = {
    method: method,
    headers: commonRequestHeaders(userToken),
    credentials: 'include',
  };
  if (hasBody) options.body = JSON.stringify(reqBody);

  return fetch(url, options)
    .then((res) => res.json())
    .catch((e)=>{
      console.error(e);
      return {error: 'Failed to fetch.'};
    });
}

export function commonRequestHeaders(bearerToken) {
  return {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept' : 'application/json;charset=UTF-8',
    ...bearerToken && { 'Authorization': 'Bearer ' + (bearerToken) },
  }
}
