export const DISABLE_SAVE = 'DISABLE_SAVE';
export const ENABLE_SAVE = 'ENABLE_SAVE';
export const SET_TYPE ='SET_TYPE';
export const SET_SUB_NAV = 'SET_SUB_NAV';
export const DELETE_SUB_NAV = 'DELETE_SUB_NAV';
export const SET_ITINERARIES = 'SET_ITINERARIES';
export const SET_HCS_SNIPPETS = 'SET_HCS_SNIPPETS';
export const SET_HCS_TITLE = 'SET_HCS_TITLE';
export const SET_TITLE_FLAG = 'SET_TITLE_FLAG';
export const SET_HEADER_LINKS = 'SET_HEADER_LINKS'
export const SET_GALLERY_LINKS = 'SET_GALLERY_LINKS';
export const SET_GALLERY_LINKS_TITLE = 'SET_GALLERY_LINKS_TITLE';
export const DELETE_GALLERY_LINKS_SECTION = 'DELETE_GALLERY_LINKS_SECTION';
export const SET_AGENT_LIST = 'SET_AGENT_LIST';
export const SET_FAQ_TITLE = "SET_FAQ_TITLE";
export const SET_FAQ_SECTIONS = "SET_FAQ_SECTIONS";
