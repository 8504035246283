import ZApiClient from '../client/z-api-client';

export const fetchEntityVersions = (entityType, entityId, offset, limit = 100) => {
  const params = {
    fields: 'id,createdAt,updateId,updatedAt,userId',
    limit,
    reverse: true,
  }
  if (offset) params['offset'] = offset;

  let client = ZApiClient.getClientFromType(entityType);
  switch (entityType) {
    case 'articles':
      return client.getArticleVersions(entityId, params);
    case 'companyCategories':
      return client.getCompanyCategoryVersions(entityId, params);
    case 'landingPageCategories':
      return client.getLandingPageCategoryVersions(entityId, params);
    case 'content':
    case 'contents':
      return client.getContentVersions(entityId, params);
    case 'guides':
      return client.getGuideVersions(entityId, params);
    case 'landing_pages':
    case 'landingPages':
      return client.getLandingPageVersions(entityId, params);
    case 'itineraries':
      return client.getItineraryVersions(entityId, params);
    default: break;
  };
};

export const fetchEntityVersion = (entityType, entityId, versionId) => {
  let client = ZApiClient.getClientFromType(entityType);
  switch (entityType) {
    case 'articles':
      return client.getArticleVersion(entityId, versionId);
    case 'companyCategories':
      return client.getCompanyCategoryVersion(entityId, versionId);
    case 'landingPageCategories':
      return client.getLandingPageCategoryVersion(entityId, versionId);  
    case 'content':
    case 'contents':
      return client.getContentVersion(entityId, versionId);
    case 'guides':
      return client.getGuideVersion(entityId, versionId);
    case 'landing_pages':
    case 'landingPages':
      return client.getLandingPageVersion(entityId, versionId);
    case 'itineraries':
      return client.getItineraryVersion(entityId, versionId);
    default: break;
  };
};
