import React  from "react";
import Button from '@mui/material/Button';

export const SearchResults = ({
  resultText,
  children,
}) => (
  <>
    <div>
      <p>
        Results: <span style={{color: 'gray'}}>{resultText}</span>
      </p>
      <br />
    </div>
    <>
      {children}
    </>
  </>
);

export const SearchBoilerplate = ({
  title,
  onCreateNew,
  onResetClick,
  onExportClick,
  receivedAll,
  children,
}) => (
  <div>
    <h1>{title}</h1>
    <div style={{display:'flex', justifyContent:'space-between',marginBottom:'28px',width:'100%'}}>
      { onCreateNew && (
        <div>
          <Button
            variant="contained"
            size="small" 
            color="secondary" 
            type="button"
            onClick={onCreateNew}
          >
            Create a New Page
          </Button>
        </div>
      )}
      {(onExportClick || onResetClick) && (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          { onExportClick && (
            <Button
              variant="contained"
              size="small" 
              color="secondary" 
              type="button"
              onClick={onExportClick}
            >
              Export File
            </Button>
          )}
          <br/>
          { onResetClick && (
            <Button
              variant="contained"
              size="small" 
              color="secondary" 
              type="button"
              disabled={!receivedAll}
              onClick={onResetClick}
            >
              Reset
            </Button>
          )}
        </div>
      )}
    </div>
    {children}
  </div>
);
