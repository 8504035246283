export const validate = (object, validator) => {
  const errors = {
    isValid: true,
  };
  // Iteratates over the validation requirements
  for (let key in validator) {
    let ret = {
      valid: true,
    }
    const min = validator[key].min ? validator[key].min : null;
    if (validator[key] && key !== 'required' && key !== 'min' && key !== 'errorMessage') {
      // Check that field exists and meets minimum requirement
      // Unique check is happening in actions prior to PUT call
      if (!object[key] || object[key].length === 0 || (min && object[key].length < min)) {
        ret = {
          valid: false,
          message: validator[key].errorMessage
        };
        errors.isValid = false;
      } else if (object[key] === Object(object[key]) && validator[key].requiredFields) {
        validator[key].requiredFields.forEach(field => {
          if (!object[key][field]) {
            ret = { 
              valid: false,
              message: validator[key].errorMessage
            };
            errors.isValid = false;
          }
        });
      } else if (min && Array.isArray(object[key])) {
        object[key].forEach((item, i) => {
          if (!item && i < min && !errors[key]) {
            ret = { 
              valid: false,
              message: validator[key].errorMessage
            };
            errors.isValid = false;
          } else if (item === Object(item) && key === 'sections') {
            let empty = Object.keys(item).length === 0;
            Object.values(item).forEach(val => {
              empty = val === '' ? true : empty;
            });
            if (empty){
              ret = { 
                valid: false,
                message: validator[key].errorMessage 
              };
              errors.isValid = false;
            } 
          }
        });
      } 
    }
    errors[key] = ret;
  }
  return errors;
}

export const validationErrorsPresent = (errors) => {
  return Object.keys(errors).length > 0;
}

