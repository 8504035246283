import React from 'react';
import { Switch, Route } from 'react-router-dom';
import StoreProvider from '../oryx-state';
import Companies from '../oryx-companies';
import Company from '../oryx-company';

const CompaniesPage = () => (
  <Companies />
);

const CompanyPage = () => (
  <Company />
);

const OryxEntry = () => (
  <StoreProvider>
    <Switch>
      <Route path="/companies/:id" render={CompanyPage}/>
      <Route path="/companies" render={CompaniesPage}/>
    </Switch>
  </StoreProvider>
);

export default OryxEntry
