import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TripRequestsView from './companies-view';
import { useCompaniesStore, useLocationsStore } from '../oryx-state';
import { getCompanyLocations } from '../oryx-state/contexts/locations';
import {
  createCompanySearch,
  fetchCompanySearch,
  defaultSearch,
  getCompanySortValue,
  getCompanySortOrders,
} from '../utils/companies';
import FetchStatus from '../utils/fetch-status';
import { extractParams } from '../../../utils/utils';
import useInfiniteScroll from '../../../hooks/use-infinite-scroll';

const CompaniesContainer = () => {
  const history = useHistory();
  const location = useLocation();

  const query = extractParams(location.search);

  const {
    filters,
    sortOrder,
    searchResults,
    searchResultsById,
    setCompanySearchResults,
    setCompanyFilters,
    setSortOrder,
  } = useCompaniesStore();

  const {
    companyLocations,
    setCompanyLocations,
  } = useLocationsStore();

  const [searchTerm, setSearchTerm] = useState('');
  const [displayedSearch, setDisplayedSearch] = useState({});
  const [loadStatus, setLoadStatus] = useState(FetchStatus.IDLE);
  const [loadMoreStatus, setLoadMoreStatus] = useState(FetchStatus.IDLE);
  const loading = loadStatus === FetchStatus.FETCHING || loadMoreStatus === FetchStatus.FETCHING;
  const shouldSearch = useRef(false);

  useEffect(() => {
    getCompanyLocations()
      .then((res) => {
        setCompanyLocations(res);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyLocations]);

  useEffect(() => {
    if (searchResultsById && Object.keys(searchResultsById).length > 0) {
      setDisplayedSearch(searchResultsById);
    }
  }, [searchResultsById]);

  // Primary useEffect for search on filter, sort, or text change.
  useEffect(() => {
    if (shouldSearch.current) {
      // Sets shouldSearch.current to false to prevent
      // search echo when we add the new search id to the url
      shouldSearch.current = false;
      // If string contains white space (i.e two words) don't add fuzzy (~) character
      const trimmedTerm = searchTerm.trim();
      const addFuzzy = !/\s/g.test(trimmedTerm) && trimmedTerm.length > 0;
      const params = {
        ...defaultSearch.filters,
        ...filters,
        sortOrders: getCompanySortOrders(sortOrder),
        fullTextSearch: addFuzzy ? `${trimmedTerm}~` : trimmedTerm,
        limit: 50,
      };
      createCompanySearch(params)
        .then((res) => {
          if ('companies' in res) {
            setCompanySearchResults(res);
            setLoadStatus(FetchStatus.SUCCESS);
            // This triggers a location change thus running the location useEffect bollow
            history.push(`/companies?search=${res.id}`);
          }
        })
        .catch(() => setLoadStatus(FetchStatus.ERROR));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchTerm, sortOrder]);

  useEffect(() => {
    // If searchResults.id doesn't equal the id in the query
    // then we can assume the user is navigating url history.
    // If !query?.search we can assume this is just /companies
    // and we shoud exicute an initial search.
    if (searchResults?.id !== query?.search || !query?.search) {
      // if search id exist fetch it
      if (query?.search) {
        shouldSearch.current = false;
        setLoadStatus(FetchStatus.FETCHING);
        fetchCompanySearch(query.search)
          .then((res) => {
            if ('companies' in res) {
              const {
                published,
                firstNameExists,
                photoExists,
                descriptionExists,
                primaryLocations,
                secondaryLocations,
                activityLevels,
                fullTextSearch,
                sortOrders,
              } = res;
              setSearchTerm(fullTextSearch?.replace(/~/g, '') || '');
              setCompanyFilters({
                published,
                firstNameExists,
                photoExists,
                descriptionExists,
                primaryLocations,
                secondaryLocations,
                activityLevels,
              });
              setSortOrder(getCompanySortValue(sortOrders[0]))
              setCompanySearchResults(res);
              setLoadStatus(FetchStatus.SUCCESS);
            }
          })
          .catch(() => setLoadStatus(FetchStatus.ERROR));
      } else {
        // Laod the default search
        setLoadStatus(FetchStatus.FETCHING);
        shouldSearch.current = false;
        const params = {
          ...defaultSearch.filters,
          sortOrders: defaultSearch.sortOrders,
          limit: 50,
        };
        createCompanySearch(params)
          .then((res) => {
            if ('companies' in res) {
              const {
                published,
                firstNameExists,
                photoExists,
                descriptionExists,
                primaryLocations,
                secondaryLocations,
                activityLevels,
                sortOrders,
              } = res;
              setCompanyFilters({
                published,
                firstNameExists,
                photoExists,
                descriptionExists,
                primaryLocations,
                secondaryLocations,
                activityLevels,
              });
              setSearchTerm('');
              setSortOrder(getCompanySortValue(sortOrders[0]));
              setCompanySearchResults(res);
              setLoadStatus(FetchStatus.SUCCESS);
              history.push(`/companies?search=${res.id}`);
            }
          })
          .catch(() => setLoadStatus(FetchStatus.ERROR));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleGetMoreResults = () => {
    if (searchResults?.id && searchResults.offset + 50 < searchResults.companyCount) {
      setLoadMoreStatus(FetchStatus.FETCHING);
      fetchCompanySearch(searchResults.id, searchResults.offset + 50)
        .then((res) => {
          if ('companies' in res) {
            setCompanySearchResults({
              ...res,
              companies: [...searchResults.companies, ...res.companies],
            });
            setLoadMoreStatus(FetchStatus.SUCCESS);
          }
        })
        .catch(() => setLoadMoreStatus(FetchStatus.ERROR));
    }
  };

  useInfiniteScroll(loading, handleGetMoreResults);

  const handleClickReset = () => {
    setSearchTerm('');
    setCompanyFilters(defaultSearch.filters);
    setSortOrder(getCompanySortValue(defaultSearch.sortOrders[0]));
    setLoadMoreStatus(FetchStatus.IDLE);
    setLoadStatus(FetchStatus.IDLE);
  };

  // Used to prevent search term echo on load
  const onSearchChange = (val) => {
    setSearchTerm(val);
    if (val !== searchTerm) {
      shouldSearch.current = true;
      if (sortOrder !== 'relevance') setSortOrder('relevance');
    }
  };

  const onSearchClear = () => {
    setSearchTerm('');
    shouldSearch.current = true;
  };

  const onFilterChange = () => {
    shouldSearch.current = true;
  };

  const handleClickNameSort = () => {
    shouldSearch.current = true;
    if (sortOrder !== 'nameAToZ') {
      setSortOrder('nameAToZ');
    } else {
      setSortOrder('nameZToA');
    }
  };

  const onSortChange = (e) => {
    shouldSearch.current = true;
    setSortOrder(e.target.value);
  };

  return (
    <TripRequestsView
      companyLocations={companyLocations}
      loadStatus={loadStatus}
      loadMoreStatus={loadMoreStatus}
      offset={searchResults?.offset}
      onChange={onSearchChange}
      onSearchClear={onSearchClear}
      onClickReset={handleClickReset}
      onClickNameSort={handleClickNameSort}
      onFilterChange={onFilterChange}
      searchResults={displayedSearch}
      searchTerm={searchTerm}
      searchTotal={searchResults?.companyCount}
      sortOrder={sortOrder}
      onSortChange={onSortChange}
    />
  );
};

export default CompaniesContainer;
