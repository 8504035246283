import React, {useState, useEffect, useRef} from 'react';
import ZTableView from './z-table-view';

const ZTableContainer = ({
  data,
  actions,
  style,
  rowStyle,
  rowItemStyle,
  rowWrapperStyle,
  children,
  headers,
  keys,
  selectedHeaderIndex,
  stickyHeader,
  onSort,
  isChronological,
}) => {
  const [tableHeaders, setTableHeaders] = useState(headers);
  const [tableKeys, setTableKeys] = useState(keys);

  useEffect(()=>{
    const mapTableHeaders = (arr) => {
      let resp = [];
      let keyMap = {}
      if(arr && Array.isArray(arr)){
        for(let i = 0, l = arr.length; i < l && i < 10; i++){
          if(typeof arr[i] === 'object' && arr[i] !== null && !Array.isArray(arr[i])){
            let keyArr = Object.keys(arr[i]);
            keyArr.forEach((ele)=>{
              if(!keyMap[ele]){
                resp.push(ele);
                keyMap[ele] = ele;
              }
            })
          }
        }
      }
      return resp;
    }
    if(!headers && !headers.length > 0){
      setTableHeaders(mapTableHeaders(data));
    } else {
      setTableHeaders(headers);
    }

    setTableKeys(keys);
  }, [data, headers, keys]);

  const onActionClick = (action, data) => () => {
    actions[action](data);
  }

  const onHeaderClick = (headerIndex) => {
    if (tableKeys && tableKeys.length > headerIndex && !!onSort) {
      onSort(tableKeys[headerIndex], headerIndex);
    }
  };

  return (
    <ZTableView
      headers={tableHeaders}
      onHeaderClick={onHeaderClick}
      rows={data}
      actions={actions}
      onActionClick={onActionClick}
      style={style || {}}
      rowStyle={rowStyle || {}}
      rowItemStyle={rowItemStyle || {}}
      rowWrapperStyle={rowWrapperStyle || {}}
      selectedHeaderIndex={selectedHeaderIndex}
      stickyHeader={stickyHeader}
      sortable={!!onSort}
      isChronological={isChronological}
    >
      {children}
    </ZTableView>
  )
}

export default ZTableContainer;
