import React from 'react';
import ZInput from '../../../z-input';
import ZSpinner from '../../../z-spinner';
import style from './content-photo-preview.module.scss';
import ZDeleteButton from '../../../z-button-delete';
import { ReactComponent as CopySVG } from '../../../../assets/logos/copy.svg';
import { onCopy } from '../../../../utils/window';
import ZAddButton from '../../../z-button-add';
import ZButton from '../../../z-button';
import GravityDropdownView from '../../../gravity-dropdown';
import ZImage from '../../../z-image';

const PhotoPreviewView = (
  { 
    alt,
    error,
    fileName,
    gravity,
    isCCP,
    info,
    isFetching,
    onChange,
    onClickDelete,
    onLoad,
    photoId,
    placeHolder,
    readOnly,
    removePhoto,
    showDelete,
    showInfo,
    src,
    styles,
    title,
    transformation,
    validUri
  }) => {
  return (
    <div className={`${style['photo-preview']}`} style={styles}>
      <div className={`${style['photo-preview__photo']}`}>
        <ZImage
          alt={alt}
          onLoad={onLoad}
          cloudinarySrc={!!src && validUri ? src : placeHolder}
          {...transformation}
        />
      </div>
      <div className={removePhoto ? `${style['photo-preview__input']}` : `${style['photo-preview__input']} ${style['photo-preview__input--hero']}`}>
        <ZInput
          error={(!validUri ? { message: 'Please use an image from the Photo App.' } : error && { message: 'Invalid URL.' })}
          label={!!src ? '' : 'Paste a Url'}
          style={{width: '100%', marginBottom: '54px', wordBreak: 'break-all'}}
          text={info.uri || ''}
          onChange={onChange('uri')}
          inputKey={`${photoId}__url`}
          readOnly={readOnly}
        />
        {isFetching &&  <div className={style['spinner']}><ZSpinner /></div>}
        {!isFetching && (
          <>
            <div className={`${style['photo-preview__meta']}`}>
              <ZInput
                label='Alt Text'
                text={alt || ''}
                style={{width: '100%', marginRight: '7px', borderLeft: '1px solid #bdbdbd'}}
                onChange={onChange('alt')}
                inputKey={`${photoId}__alt`}
              />
              <ZInput
                label='Title Text'
                text={title || ''}
                style={{width: '100%', borderLeft: '1px solid #bdbdbd'}}
                onChange={onChange('title')}
                inputKey={`${photoId}__title`}
              />
              { gravity && (
                <GravityDropdownView
                  gravity={gravity}
                  imgSrc={src}
                  onChange={onChange}
                  photoId={photoId}
                  readOnly={readOnly}
                />
              )}
            </div>
            {showInfo &&
              <div className={`${style['info']}`}>
                <p className={`${style['info__field']}`}>{`Date Added: ${info.createdAt || ''}`}</p>
                <p className={`${style['info__field']}`}>
                  {`Dimensions (H x W): ${info.height && info.width ? `${info.height} x ${info.width}` : ''}`}
                </p>
                <p className={`${style['info__field']}`}>
                  Source:
                  <br/>
                  <a href={info.sourceUri} target="_blank" rel="noopener noreferrer">{info.sourceUri}</a>
                </p>
                {info.sourceType === 'Cloudinary' &&
                  <p className={`${style['info__copy']}`} onClick={() => onCopy(fileName)}>
                      Copy File Name <CopySVG/>
                  </p>
                }
              </div>
            }
          </>
        )}
        {isCCP &&
        <ZButton
          btnClass={style['photo-preview__landing-page--button']}
          btnStyle={{ marginLeft: 'auto'}}
          text={`${showDelete ? 'Delete & ' : ''}Use Landing Page Image`}
          onClick={onClickDelete}
        />
      }
      </div>
      {!isCCP &&
        <div className={style['photo-preview__remove']}>
          {showDelete &&
            <>
              <ZAddButton onClick={removePhoto}>Change</ZAddButton>
              <ZDeleteButton style={{width: '90px'}} onClick={removePhoto}>Delete</ZDeleteButton>
            </>
          }
        </div>
      }
    </div>
  )
}

export default PhotoPreviewView;
