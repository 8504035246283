import React, { useState, useEffect, } from 'react';
import SubnavView from './subnav-view';

const SubnavContainer = ({
  id,
  entityUri,
  updateSubnav,
  subnavLinks,
  deleteSubnav,
}) => {
  const [tabLinks, setTablinks] = useState(subnavLinks);
  const [showDelete, setShowDelete] = useState(false);
  const host = tabLinks[0];
  const [navErrors, setNavErrors] = useState(!host || !host.entityId);
  
  useEffect(()=> {
    if(subnavLinks && subnavLinks.length > 0) {
      setTablinks(subnavLinks);
      setShowDelete(true);
    } else {
      setTablinks([{},{},{},{},{}]);
      setShowDelete(false);
    }
  }, [subnavLinks]);

  // Used to find current list entity by index of entityId.
  const getCurrent = (indexOrId) => {
    const isIndex = (typeof indexOrId === 'number');
    let current, currentIndex;
    if(isIndex){
      currentIndex = indexOrId;
      current = {...tabLinks[indexOrId]};
    } else {
      for (let i = 0; i < tabLinks.length; i++) {
        const ele = tabLinks[i];
        if(!!currentIndex) return;
        if(ele.entityId === indexOrId){
          current = {...ele};
          currentIndex = i;
        }
      }
    }
    return {
      index: currentIndex,
      current,
    }
  }
  
  const updateLinkItem = (tabLink, indexOrId) => {
    const { index, current } = getCurrent(indexOrId);
    const newList = [...tabLinks];
    // If adding text to first tab(host)
    const newLink = {...current, ...tabLink}
    if(index === 0 && !newLink.entityId){
      newLink.entityId = id;
      newLink.entityType = 'LandingPage';
      newLink.uri = entityUri;
      setNavErrors(false);
    }
    newList.splice(index, 1, newLink);
    return newList;
  }

  const deleteLinkItem = (index) => {
    const newList = [...tabLinks];
    newList.splice(index, 1, {});
    return newList;
  }

  const onAdd = (tabLink, indexOrId) => {
    const updatedList = updateLinkItem(tabLink, indexOrId);
    const host = updatedList[0];
    if(!host.entityId){
      setNavErrors(true);
    } else {
      updateSubnav(updatedList);
    }
  }

  const onDelete = (index) => {
    const updatedList = deleteLinkItem(index);
    updateSubnav(updatedList);
  }

  return (
    <SubnavView
      tabLinks={tabLinks}
      navErrors={navErrors}
      onAdd={onAdd}
      onDelete={onDelete}
      showDelete={showDelete}
      deleteSubnav={deleteSubnav}
    />
  )
};

export default SubnavContainer;
