import React from 'react';
import { connect } from 'react-redux';
import ZVersionsContainer from './z-versions-container';
import { fetchEntityVersions } from '../../state/actions/versions';
import { fetchPaths } from '../../state/actions/paths';

const VersionsRedux = (props) => (
  <ZVersionsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => ({
  versions: state.versions[ownProps.entityId],
  loading: state.versions.isFetching,
  error: state.versions.error,
  paths: state.paths[ownProps.entityId],
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaths: (id) => dispatch(fetchPaths(id)),
  onFetchVersions: (type, id) => dispatch(fetchEntityVersions(type, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VersionsRedux);
