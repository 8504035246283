import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LandingPageView from './landing-page-view';
import sharedStyle from '../shared/styles/shared.module.scss';
import ZSpinner from '../z-spinner';
import ErrorModal from './error-modal';
import { decodeHTMLEntities } from '../../utils/utils';

const defaultBtnText = 'Save';

const LandingPageContainer =({
  currentUpdateId,
  disabledMessage,
  entity,
  errors,
  isFetching,
  notFound,
  onCreateNew,
  onLoad,
  onSave,
  onTitleChange,
  onToggleReadOnly,
  paramUpdateId,
  readOnly,
  saved,
  saveDisabled,
}) => {
  const [openToast, setOpenToast] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);
  const [btnText, setBtnText] = useState(defaultBtnText);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  const { id } = useParams();

  useEffect(()=> {
    onLoad(paramUpdateId);
  }, [onLoad, paramUpdateId]);

  useEffect(() => {
    if (!!paramUpdateId) {
      onToggleReadOnly();
      return () => onToggleReadOnly();
    }
  }, [paramUpdateId, onToggleReadOnly]);

  useEffect(()=>{
    if(!saveSuccess && saved){
      setSaveSuccess(true);
      setOpenToast(true);
      setBtnText(defaultBtnText);
      setSaveBtnDisabled(false);
    }
  },[saveSuccess, saved]);

  useEffect(() => {
    if (entity?.title) document.title = `lp: ${decodeHTMLEntities(entity.title)}`;
  }, [entity?.title]);

  const onToastClose = () => {
    setOpenToast(false);
  }

  const onSaveClick = () => {
    setSaveSuccess(false);
    setSaveBtnDisabled(true);
    setBtnText('...Saving Page')
    onSave();
  }

  const validateFaq = (faq) => {
    return faq.sections.every((section) => {
      return section.title && section.text;
    });
  }

  if(isFetching){
    return (
      <div className={sharedStyle['spinner']}>
        <ZSpinner/>
      </div>
    );
  } else if(notFound) {
    return (
      <ErrorModal
        id={id}
        onCreateNew={onCreateNew}
      />
    );
  } else {
    return (
      <LandingPageView
        btnText={btnText}
        disabledMessage={disabledMessage}
        errors={errors}
        id={id}
        onSave={onSaveClick}
        onTitleChange={onTitleChange}
        onToastClose={onToastClose}
        openToast={openToast}
        readOnly={readOnly}
        saved={saved}
        saveDisabled={saveDisabled}
        title={entity.title || ''}
        saveBtnDisabled={saveBtnDisabled}
        updateId={currentUpdateId}
        validFaqData={entity?.faq?.sections ? validateFaq(entity.faq) : true}
      />
    );
  }
}

export default LandingPageContainer;
