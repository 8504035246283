import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import OptimizationContainer from './optimization-container';
import {addUpstreamFooterSectionsT, createSectionT} from "../../../state/actions/shared/section";

const mapSectionIdsToSection = (ids, sections) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = sections[ele];
      return section;
    });
  }
}

const OptimizationRedux = (props) => {
  const { id } = useParams();
  return (
    <OptimizationContainer
      title={props.entities[id]?.title}
      {...props}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const entities = state.landingPageCategories.editor;
  const sections = state.landingPageCategories.sections;
  const currentLandingPageCategory = entities[id];
  const { footerSectionIds, title } = currentLandingPageCategory;
  return {
    entities,
    id,
    title,
    footerSections: mapSectionIdsToSection(footerSectionIds, sections)
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId: id } = ownProps;
  return {
    onCreateSection: () => dispatch(createSectionT('landing_page_category', id, false, 'FOOTER')),
    onFooterSearch: (sections) => dispatch(addUpstreamFooterSectionsT('landing_page_category', id, sections)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptimizationRedux);
