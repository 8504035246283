import React, { useState } from 'react';
import style from './header.module.scss';
import { ReactComponent as ZicassoLogo } from '../../assets/logos/zicasso.svg';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';


const AppBar = styled(MuiAppBar)(({ theme,}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function NavHeader({
  loggedIn
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (bool) => {
    setOpen(bool);
  }
  

  const MenuList = () => (
    <Box
      sx={{ width: 250,}}
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding key='itineraries'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/itineraries"
          >
            <span className={style['text']}>
              Itineraries
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='articles'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/articles"
          >
            <span className={style['text']}>
              Articles
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='guides'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/guides"
          >
            <span className={style['text']}>
              Guides
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='landing-pages'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/landing-pages"
          >
            <span className={style['text']}>
              Landing Pages
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='company-categories'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/company-categories"
          >
            <span className={style['text']}>
              Agency Categories
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='landing-page-categories'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/landing-page-categories"
          >
            <span className={style['text']}>
              Hub Pages
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='simple-pages'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/simple-pages"
          >
            <span className={style['text']}>
              Simple Pages
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='news'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/news"
          >
            <span className={style['text']}>
              News
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='team'>
          <ListItemButton
            className={style['link']}
            LinkComponent={Link} 
            to="/team"
          >
            <span className={style['text']}>
              Team
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='careers'>
          <ListItemButton
            className={style['link']} 
            LinkComponent={Link} 
            to="/careers"
          >
            <span className={style['text']}>
              Careers
            </span>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding key='trip-request-settings'>
          <ListItemButton 
            className={style['link']} 
            LinkComponent={Link} 
            to="/trip-request-settings"
          >
            <span className={style['text']}>
              TRF Settings
            </span>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding key='agencies'>
          <ListItemButton
            className={style['link']} 
            LinkComponent={Link} 
            to="/companies"
          >
            <span className={style['text']}>
              Agencies
            </span>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            disabled={!loggedIn}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, zIndex: theme.zIndex.appBar + 1}}>
            <ZicassoLogo
              style={{height: "50px", marginRight: "50px", cursor: "pointer"}}
              onClick={() => window.location.href = "/"}
            />
          </Box>
          {loggedIn && (
            <Button
              color="secondary"
              aria-label="logout"
              edge="end"
            >
              <Link to="/logout">
                <Typography variant="buttonText" noWrap color="secondary">
                  Logout
                </Typography>
              </Link>
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => toggleDrawer(false)}
      >
        <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MenuList
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </Box>
  );
}
