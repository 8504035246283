import React from 'react';
import { Label, Wrapper } from '../utils';
import styles from './locations.module.scss'

const defaultProps = {
  primaryLocations: [],
  secondaryLocations: [],
};

const LocationView = ({
  primaryLocations,
  secondaryLocations,
}) => (
  <div className={styles.location}>
    <div className={styles.column}>
      <Wrapper>
        <div className={styles.location__lists}>
          <Label inputName="primaryCountry">
            Primary Countries:
          </Label>
          <div>
            { primaryLocations.map((location) => <p key={location.id}>{location.name}</p>) }
          </div>
        </div>
      </Wrapper>
    </div>
    <div className={styles.column}>
      <Wrapper>
        <div className={styles.location__lists}>
          <Label inputName="secondaryCountry">
            Secondary Countries:
          </Label>
          <div>
            { secondaryLocations.map((location) => <p key={location.id}>{location.name}</p>) }
          </div>
        </div>
      </Wrapper>
    </div>
  </div>
);
LocationView.defaultProps = defaultProps;

export default LocationView;
