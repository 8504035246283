import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import VersionsView from './versions-view';
import { navigate } from '../../../utils/window';
import { getPublicationReferences, getUsageReferences } from '../../../utils/fetch/usage-references';
import Config from '../../../config';
const baseUrlWeb = Config.Z3_URL;

const VersionsContainer = ({
  makeLatest,
  onFetchVersion,
  paths,
  published,
  saved,
}) => {
  const [modalState, setModalState] = useState();
  const [showModal, setShowModal] = useState(false);
  const [usageReferences, setUsageReferences] = useState(false);
  const { id } = useParams();

  const onClickPreview = (data) => {
    const { version } = data;
    const url = `${baseUrlWeb}/dynamic?type=Content&id=${id}&updateId=${version}`;
    window.open(url);
  };

  const onClickPublish = (data) => {
    setModalState('Publish');
    setShowModal(true);
    onFetchVersion(id, data.version);
  };

  const onClickUnpublish = async (data) => {
    try {
      const usageReferences = await getUsageReferences(id);
      const publicationReferences = await getPublicationReferences(id);
      if ((usageReferences && usageReferences.length > 0)
        || (publicationReferences && publicationReferences.length > 0)) {
        setUsageReferences(true);
      }
      else {
        setUsageReferences(false);
      }
    } catch (err) {
      console.warn("Failed to fetch usage references", err);
    }
        
    setModalState('Unpublish');
    setShowModal(true);
    onFetchVersion(id, data.version);
  };

  const onClickMakeLatest = async (data) => {
    makeLatest(id, data.version);
  };

  const onClickEditorView = (data) => {
    const { version } = data;
    navigate(`simple-pages/${id}/${version}`);
  };

  const onCloseModal = () => {
    setModalState('');
    setShowModal(false);
  };

  const actions = {
    'editor view': onClickEditorView,
    'make latest': onClickMakeLatest,
    preview: onClickPreview,
    publish: onClickPublish,
  };

  return (
    <VersionsView
      actions={actions}
      id={id}
      modalState={modalState}
      onCloseModal={onCloseModal}
      onClickUnpublish={onClickUnpublish}
      paths={paths}
      published={published}
      saved={saved}
      setModalState={setModalState}
      setShowModal={setShowModal}
      showModal={showModal}
      usageReferences={usageReferences}
    />
  );
};

export default VersionsContainer;
