import React from 'react';

const ZPageView = (props) => {
  const children = props.children;
  return(
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default ZPageView;
