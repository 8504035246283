import React, {useEffect, useRef, useState} from 'react';
import HubPageSectionsView from './hub-page-sections-view';
import { decodeHTMLEntities, navigate } from '../../../utils/window';
import Config from '../../../config';
import ZApiClient from '../../../utils/client/z-api-client';
const baseUrlWeb = Config.Z3_URL;

const HubPageSectionsContainer = ({
  snippets,
  contentSectionTitle,
  updateSnippets,
  errors,
  readOnly,
}) => {
  const snippetRef = useRef(snippets);
  const [customSnippets, setCustomSnippets] = useState([]);
  
  useEffect(() => {
    snippetRef.current = snippets;
  }, [snippets]);

  useEffect(() => {
    setCustomSnippets(snippets);
  }, [snippets]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    let items = reorder(
      customSnippets,
      result.oldIndex,
      result.newIndex
    );
    updateSnippets([...items]);
  }

  const onUpdate = (snippet, index) => {
    let clone = [...customSnippets];
    clone[index] = snippet;
    updateSnippets([...clone]);
  }

  const onChangePosition = (oldIndex, newIndex) => {    
    let clone = [...customSnippets];
    let element = clone[oldIndex];
    clone.splice(oldIndex, 1);
    clone.splice(newIndex, 0, element);
    updateSnippets([...clone]);
  }

  const removeSnippet = (index) => {
    if (window && window.confirm('Are you sure you want to remove this section?')) {
      let clone = [...customSnippets];
      clone.splice(index, 1);
      updateSnippets([...clone]);
    }
  }

  const autoComplete = (text) => {
    return ZApiClient
    .getLandingPagesClient()
    .getAllTitlesWithIdsByNameFragment(text, { limit: 10, published: true })
      .then((res)=> Object.keys(res)
        .filter(key => {
          for (let i = 0; i < snippetRef.current.length; i++) {
              if ( snippetRef.current[i].id === key ) {
                return false;
              }
            }
          return key;
        })
        .reduce((obj, key) => {
          obj[key] = decodeHTMLEntities(res[key]);
          return obj;
        }, {}));
  }

  const autoCompleteOnSelect = (val) => {
    if (Object.keys(val ? val : {}).length > 0) {
      const [id,] = Object.keys(val);
      return ZApiClient
      .getLandingPagesClient()
      .getSnippet(id)
      .then((res)=> {
        let clone = [...snippetRef.current];
        clone.push(res);
        updateSnippets(clone);
      });
    }
  }

  const onClickPreview = (id, entity) => {
    switch (entity) {
      case "LandingPage":
      case "landingPage":
      case "landing-page":
      case "LandingPageSnippet":
        window.open(`${baseUrlWeb}/dynamic?type=LandingPage&id=${id}`);
        break;
      default:
        break;
    }
  }

  const onClickEdit = (id, entity) => {
    switch (entity) {
      case "LandingPage":
      case "landingPage":
      case "landing-page":
      case "LandingPageSnippet":
        navigate(`landing-pages/${id}`);
        break;
      default:
        break;
    }
  }

  return (
    <HubPageSectionsView
      autoComplete={autoComplete}
      autoCompleteOnSelect={autoCompleteOnSelect}
      snippets={snippets} 
      contentSectionTitle={contentSectionTitle}
      onUpdate={onUpdate}
      onChangePosition={onChangePosition}
      onDragEnd={onDragEnd}
      removeSnippet={removeSnippet}
      errors={errors}
      onClickEdit={onClickEdit}
      onClickPreview={onClickPreview}
      readOnly={readOnly}
    />
  );
}

export default HubPageSectionsContainer;
