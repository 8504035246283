/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import ErrorText from '../z-input-error-text';
import SubText from '../z-input-subtext';
import styles from './z-select-input.module.scss';
import {ReactComponent as DownArrowIcon} from '../../assets/inline/expand_more-24px.svg';

const ZSelectInputView = ({
  focus,
  inputClass,
  inputRef,
  listClassName,
  optionClass,
  options,
  placeholder,
  setFocus,
  setValue,
  subText,
  valid,
  validationErrorText,
  value,
  wrapperClass,
}) => (
  <div className={`${styles['z-select__wrapper']} ${wrapperClass}`}>
    <div
      className={styles['z-select__input--wrapper']}
      onClick={() => setFocus(!focus)}
      onKeyUp={(e) => { if (e.code === 'Enter') setFocus(!focus); }}
      tabIndex={0}
      role="textbox"
    >
      <div className={`${styles['z-select__input--arrow']} ${focus ? styles['z-select__input--arrow-open'] : ''}`}>
        <DownArrowIcon
          height={24}
          width={24}
        />
      </div>
      <input
        className={
            `${styles['text']}
            ${styles['z-input']}
            ${styles['z-select__input']}
            ${!(valid === false) ? styles['z-select__input--valid'] : styles['z-select__input--invalid']}
            ${inputClass}`
        }
        placeholder={placeholder}
        readOnly
        ref={inputRef}
        spellCheck="false"
        type="text"
        value={value}
      />
    </div>
    <ul
      className={`
        ${styles['z-input']}
        ${styles['z-select__options']}
        ${listClassName}
      `}
      style={{ display: !focus && 'none' }}>
      { options && options.map((option, i) => (
        <React.Fragment key={`option-${i}`}>
          { option.link
          && (
            <a href={option.link}>
              <li
                className={
                  `${option.text === value
                    ? styles['z-select__options--selected']
                    : styles['z-select__options--unselected']}
                  ${styles.text}`
                }
                key={`option-${i}`}
                onClick={() => setValue(option.text)}
                onKeyUp={(e) => { if (e.code === 'Enter') setValue(option.text); }}
                tabIndex={0}
                role="option"
                aria-selected={option.text === value}
              >
                {option.text}
              </li>
            </a>
          )}
          { !option.link
          && (
            <li
              className={
                  `${styles['text']}
                  ${option.text === value && styles['z-select__options--selected']}
                  ${optionClass}`
              }
              key={`option-${i}`}
              onClick={() => setValue(option.text)}
              onKeyUp={(e) => { if (e.code === 'Enter') setValue(option.text); }}
              tabIndex={0}
              role="option"
              aria-selected={option.text === value}
            >
              {option.text}
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
    { !(valid === false)
      ? (
        <>
          { !!subText && <SubText>{subText}</SubText>}
        </>
      )
      : (
        <>
          { !!validationErrorText && <ErrorText>{validationErrorText}</ErrorText>}
        </>
      )}
  </div>
);
ZSelectInputView.propTypes = {
  focus: PropTypes.bool.isRequired,
  inputClass: PropTypes.string,
  inputRef: PropTypes.object.isRequired,
  listClassName: PropTypes.string,
  optionClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ),
  placeholder: PropTypes.string,
  setFocus: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  subText: PropTypes.string,
  valid: PropTypes.bool,
  validationErrorText: PropTypes.string,
  value: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
};
ZSelectInputView.defaultProps = {
  inputClass: null,
  listClassName: null,
  optionClass: null,
  options: null,
  placeholder: null,
  subText: null,
  valid: null,
  validationErrorText: null,
  wrapperClass: null,
};

export default ZSelectInputView;
