import { combineReducers } from 'redux';
import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_ROLES,
} from '../actions/users';

const login = (state = {
 isFetching: false,
 error: undefined,
}, action) => {
  switch(action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        loggedIn: true,
        loggedInSince: action.receivedAt,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case LOGOUT:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        loggedIn: false,
      };
    default:
      return state;
  }
};

const user = (state = {
  username: null,
  roles: null,
}, action) => {
  switch(action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        username: action.username,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    default:
      return state;
  }
};

const users = combineReducers({
  user,
  login,
});

export default users;
