import React, { useState, useEffect } from 'react';
import ZSpinner from '../../../../z-spinner';
import getLink from '../../../../../utils/fetch/links';
import EditLink from './edit-link';
import AddLink from './add-link';
import style from './editor.module.scss';

const GalleryLinkEditor = ({
  closeModal,
  createPhoto,
  disabledMessage,
  entityId,
  galleryLink,
  index,
  onAddLink,
  onChangeLink,
  photo,
  photoId,
  saveDisabled,
  removePhoto,
  updatePhoto,
}) => {
  const [fetchStatus, setFetchStatus] = useState();
  const [originalSnippet, setOriginalSnippet] = useState();

  useEffect(() => {
    if (galleryLink?.entityId) {
      setFetchStatus('loading');
      getLink(galleryLink.entityId, galleryLink.entityType)
        .then((res) => {
          if (res.entityId === galleryLink.entityId) {
            setOriginalSnippet(res);
            setFetchStatus();
          }
          else throw new Error();
        })
        .catch(() => setFetchStatus('error'));
    }
  }, [galleryLink?.entityId, galleryLink?.entityType]);

  const onSelectLink = (link) => {
    setOriginalSnippet(link);
    onAddLink(link, index);
  };

  return (
    <div className={style.editor}>
      {fetchStatus === 'loading' ? (
        <ZSpinner className={style['editor__spinner']} />
      ) : (
        <>
          {galleryLink ? (
            <EditLink
              closeModal={closeModal}
              createPhoto={createPhoto}
              disabledMessage={disabledMessage}
              entityId={entityId}
              galleryLink={galleryLink}
              index={index}
              onChangeLink={onChangeLink}
              originalSnippet={originalSnippet}
              photo={photo}
              photoId={photoId}
              removePhoto={removePhoto}
              saveDisabled={saveDisabled}
              updatePhoto={updatePhoto}
            />
          ) : (
            <AddLink
              closeModal={closeModal}
              onSelectLink={onSelectLink}
              setFetchStatus={setFetchStatus}
            />
          )}
        </>
      )}
    </div>
  );
};

export default GalleryLinkEditor;
