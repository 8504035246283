import ZApiClient from './z-api-client';

class Article {

  _getTuid  = () => (
    ZApiClient.getTuidsClient().createTuid()
  );

  _mapFooterIdtoSection = (ids, sections) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        return {...sections[ele]} ;
      });
    }
  }

  _mapSectionIdToSections = (ids, sections, photos) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        const { photoId, ...rest } = sections[ele];
        const section = {
          ...rest,
          ...photoId && { image: photos[photoId] },
        };
        return section;
      });
    }
  }

  _mapPhotos = (photos) => {
    let ret = {};
    if(!photos || !photos[0]){
      return ret
    };
    for(const photo of photos){
      if (photo && !!photo.id) ret[photo.id] = {...photo};
    }
    return ret;
  }

  _mapHeaderIdToPhoto = (id, photos) => {
    if(!id){
      return null;
    } else {
      return photos[id];
    }
  }

  _mapSectionsToEntities = async (sections = [], footerLinks) => {
    const sectionIds = [];
    const sectionMap = {};
    const sectionPhotos = [];
    for(const section of sections){
      let {id, image, photoId} = section;
      if(!id){
        const tuidRes = await this._getTuid();
        id = tuidRes.id;
      }
      if(image){
        photoId = image.id;
        sectionPhotos.push({...image})
      }
      sectionIds.push(id);
      sectionMap[id] = {...section, id, photoId};
    };
    const{
      idMap:footerIdMap,
      idList:footerIds,
    } = await this._mapFooterSections(footerLinks);
    return {
      sectionIds,
      footerIds,
      sectionMap : {...sectionMap, ...footerIdMap},
      sectionPhotos,
    };
  };

  _mapFooterSections = async (sections) => {
    const idList = [];
    const idMap = {};
    if(sections){
      for(const section of sections){
        let {id, links} = section;
        if(!id){
          const {id:resId} = await this._getTuid();
          id = resId;
        }
        if(links){
          const promiseArr = links.map((link)=> this._getIdIfNeeded(link));
          links = await Promise.all(promiseArr);
        }
        idList.push(id);
        idMap[id] = {
          ...section,
          id,
          links,
        }
      }
    }
    return {
      idList,
      idMap,
    }
  }

  _getIdIfNeeded = async (section) => {
    let {id} = section;
    if(!id) {
      const {id:resId} = await this._getTuid();
      id = resId;
      return {...section, id};
    } else {
      return {...section};
    }
  }

  _generateDescription = (locations) => {
    locations = locations || [];
    let locationText = locations.length > 0 ? `${locations[0]} ` : '';
    return `Zicasso: ${locationText}travel & tours by top competing travel agents & tour operators.`
  }

  /**
   * @param {object} metatags
   * @param {string} title
   * Checks if meta title exist.
   * If no meta title present use default rules to build meta title
   */
  _mapMetaTags = (metatags, article) => {
    let {
      title:metaTitle,
      canonical,
      description,
    } = metatags;
    const {
      title,
      locations,
    } = article;
    if(!metaTitle){
      metaTitle = `${title} | Zicasso`;
    }
    if(!description){
      description = this._generateDescription(locations);
    }
    return {
      ...canonical && {canonical: canonical},
      description,
      title: metaTitle,
    }
  }

  _getMetaTags = (metatags) => {
    return {
      title: '',
      description: '',
      canonical: '',
      ...metatags,
    }
  };

  /**
   * Accepts the redux state for an article. (crurrently articles.article).
   * article state includes 3 fields
   * {
   *  editor:{
   *    isFetching: boolean,
   *    isSaving: boolean,
   *    saved: boolean,
   *    [articleId] : {...},
   *  },
   *  photos:{
   *    [photoId] : {...}
   *  },
   *  sections:{
   *    [sectionId] : {}
   *  }
   * }
   * @param {string} articleId
   * @param {object} articleState
   */
  getArticle(articleId, state){
    const currentArticle = state.editor[articleId];
    const {
      id,
      authorName,
      authorLink,
      callToAction,
      title,
      summary,
      sectionIds,
      headerPhotoId,
      locations,
      metatags,
      footerSectionIds,
      type,
      expirationDate,
      breadcrumb,
      breadcrumbTitle,
      showAuthor,
      showDate,
      includeToC,
    } = currentArticle;
    const expiration = {
      expirationDate: type === 'TOPICAL' ? expirationDate : null
    };

    const ret = {
      id,
      authorName,
      authorLink,
      callToAction,
      title,
      summary,
      locations,
      type,
      breadcrumb,
      breadcrumbTitle,
      showAuthor,
      showDate,
      includeToC,
      ...expiration,
      ...headerPhotoId && { heroImage: this._mapHeaderIdToPhoto(headerPhotoId, state.photos) },
      sections: this._mapSectionIdToSections(sectionIds, state.sections, state.photos),
      metatags: this._mapMetaTags(metatags, currentArticle),
      footerLinks: this._mapFooterIdtoSection(footerSectionIds, state.sections),
    }
    return ret;
  }


  /**
   *
   * Accepts an article entity from our backend and returns the following:
   * {
   *  id: 'articleId',
   *  [id] : {mapped article},
   *  photos: {
   *    [photoId] : {...},
   *  },
   *  sections: {
   *    [sectionId] : {...},
   *  },
   * }
   * @param {object} article
   */
  async getState(article){
    const {sections, heroImage, metatags, footerLinks, type} = article;
    const {
      sectionIds,
      footerIds,
      sectionMap,
      sectionPhotos
    } = await this._mapSectionsToEntities(sections, footerLinks);
    const retArticle = {
      ...article,
      ...!type && {type: 'EVERGREEN'},
      metatags : this._getMetaTags(metatags),
      ...heroImage && {headerPhotoId : heroImage.id},
      sectionIds,
      footerSectionIds: footerIds,
    };
    return {
      id: article.id,
      [article.id] : retArticle,
      photos : this._mapPhotos([...sectionPhotos, heroImage]),
      sections : sectionMap,
    };
  }

}

export default Article;
