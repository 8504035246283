import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthRouterView from './auth-view';
import { setDocumentTitle } from '../../utils/utils';
import useApiClient from '../../hooks/use-api-client';
import LocalStorageService from '../../utils/local-storage-service';
import { getMatchingUser, hasAuthorizedRole } from '../../utils/auth';
import { setRoles, loginSuccess } from '../../state/actions/users';
import useLogout from '../../hooks/use-logout';

const LocalStorageClient = new LocalStorageService();

const AuthRouterRedux = ({
  children,
  loading,
  loggedIn,
  loginModal,
  readOnly,
  loginSuccess,
  dispatch
}) => {
  const apiClient = useApiClient();
  const location = useLocation();
  const [setLogoutNow] = useLogout(dispatch, false);
  
  useEffect(() => {
    const accessToken = LocalStorageClient.getFromLocalStorage('accessToken');
    const isAuthorized = () => {
      if (accessToken) {
        // Check token status and get user
        apiClient.authClient
          .getToken(accessToken)
          .then((data) => apiClient.peopleClient.getTeamMember(data.email))
          .then((data) => {
            const matchingUser = getMatchingUser(data.email, data.userInfo);
            if (!matchingUser || !hasAuthorizedRole(matchingUser.roles)) {
              throw new Error('Not Authorized');
            }
            setRoles(matchingUser.roles);
            loginSuccess(data.email);
          })
          .catch((e) => {
            console.log(e);
            setLogoutNow(true);
          });
      } else {
        setLogoutNow(true);
      }
    };

    isAuthorized();
  }, []);

  useEffect(() => {
    setDocumentTitle(location.pathname);
  }, [location.pathname]);

  return (
    <AuthRouterView
    loggedIn={loggedIn}
    loading={loading}
    loginModal={loginModal}
    readOnly={readOnly}
    >
      {children}
    </AuthRouterView>
  )
}

const mapStateToProps = (state) => ({
  loggedIn: state.users.login.loggedIn,
  loading: state.users.login.isFetching,
  loginModal: state.modals.loginModal || {},
  readOnly: state.versions.readOnly,
});

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (username) => dispatch(loginSuccess(username)),
  setRoles: (roles) => dispatch(setRoles(roles)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRouterRedux);
