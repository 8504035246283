import React from 'react';
import { connect } from 'react-redux';
import VersionsContainer from './versions-container';
import {
  fetchLandingPageCategoryVersion,
  makeLatest,
  validateEntityVersion,
 } from '../../../state/actions/landing-page-category-editor';

const VersionsRedux = (props) => (
  <VersionsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const pathMap = state.landingPageCategories.paths || {};
  const currentPaths = pathMap[id] || [];
  return{
    paths: currentPaths,
    saved: state.landingPageCategories.editor.saved,
    published: state.published[id] && state.published[id].current,
  }
};

const mapDispatchToProps = (dispatch) => ({
  makeLatest: (id, version) => dispatch(makeLatest(id, version)),
  onFetchVersion: (id, version) => dispatch(fetchLandingPageCategoryVersion(id, version)),
  onValidateEntityVersion: (id, versionId, paths) => validateEntityVersion(id, versionId, paths),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionsRedux);
