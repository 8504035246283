import ZApiClient from '../client/z-api-client';
import { Image } from '@zicasso/zicasso-sdk/lib/image';

export const createImage = (obj) => {
  const reqBody = {
    sourceUri: obj.uri,
    ...obj.alt && { alt: obj.alt },
    ...obj.title && { title: obj.title }
  };
  return ZApiClient
    .getImagesClient()
    .createImage(new Image(reqBody));
}

export const updateImage = (reqBody) => {
  return ZApiClient
    .getImagesClient()
    .updateImage(new Image(reqBody));
}

export const getImagePreviewTransformation = ({
  aspectRatio,
  crop = 'fill',
  effect,
  gravity,
  height,
  width = 300,
}) => ({
  crop,
  width,
  ...aspectRatio && { aspectRatio },
  ...effect && { effect },
  ...gravity && { gravity },
  ...height && { height },
});
