import {createImage, updateImage} from '../../../utils/image/image-utils';
import ZApiClient from '../../../utils/client/z-api-client';
import { NewsSource } from '@zicasso/zicasso-sdk/lib/news'

export const NEWS_SOURCES_REQUEST = 'NEWS_SOURCES_REQUEST';
export const newsSourcesRequest = (isFetching) => ({
  type: NEWS_SOURCES_REQUEST,
  isFetching,
});

export const SET_NEWS_SOURCES = 'SET_NEWS_SOURCES';
export const setNewsSources = (allNewsSources) => ({
  type: SET_NEWS_SOURCES,
  allNewsSources,
});

export const NEWS_SOURCES_ERROR = 'NEWS_SOURCES_ERROR';
export const newsSourcesError = (isFetching) => ({
  type: NEWS_SOURCES_ERROR,
  isFetching,
});

export const SET_NEWS_SOURCE = 'SET_NEWS_SOURCE';
export const setNewsSource = (newsSource) => ({
  type: SET_NEWS_SOURCE,
  newsSource,
});

export const SET_NEWS_SOURCE_NAMES = 'SET_NEWS_SOURCE_NAMES';
export const setNewsSourceNames = (newsSource) => ({
  type: SET_NEWS_SOURCE_NAMES,
  newsSource,
});

export const LOGO_ERROR = 'LOGO_ERROR';
export const logoError = (error) => ({
  type: LOGO_ERROR,
  error,
});

export const LOGO_REQUEST = 'LOGO_REQUEST';
export const logoRequest = (isFetching) => ({
  type: LOGO_REQUEST,
  isFetching,
});

export const SET_LOGO = 'SET_LOGO';
export const setLogo = (newsSourceId, logo) => ({
  type: SET_LOGO,
  newsSourceId,
  logo,
});

export const DELETE_LOGO = 'DELETE_LOGO';
export const deleteLogo = (newsSourceId, logo) => ({
  type: DELETE_LOGO,
  newsSourceId,
  logo,
});

export const SET_NAME = 'SET_NAME';
export const setName = (newsSourceId, name) => ({
  type: SET_NAME,
  newsSourceId,
  name,
});

export const CREATE_NEW = 'CREATE_NEW_SOURCE';
export const createNew = () => ({
  type: CREATE_NEW,
});

export const fetchNewsSources = () => (dispatch) => {
  dispatch(newsSourcesRequest(true));
  return ZApiClient.getNewsSourcesClient()
    .getNewsSources({ limit: 500 })
    .then((res) => {
      const sortedSources = res.sort((a, b) => a.name < b.name ? -1 : 1);
      dispatch(setNewsSources(sortedSources));
    })
    .catch(() => dispatch(newsSourcesError(true)))
    .finally(() => dispatch(newsSourcesRequest(false)));
};


export const fetchNewsSourceNameByFragment = (fragment) => (dispatch) => {
  dispatch(newsSourcesRequest(true));
  return ZApiClient.getNewsSourcesClient()
    .getNamesByFragment(fragment)
    .then((res) => {
      dispatch(setNewsSourceNames(res));
      return res;
    })
    .catch(() => dispatch(newsSourcesError(true)))
    .finally(() => dispatch(newsSourcesRequest(false)));
};

export const fetchNewsSource = (newsSourceId) => (dispatch) => {
  dispatch(newsSourcesRequest(true));
  return ZApiClient.getNewsSourcesClient()
    .getNewsSource(newsSourceId)
    .then((res) => {
      dispatch(setNewsSource(res));
      return res;
    })
    .catch(() => dispatch(newsSourcesError(true)))
    .finally(() => dispatch(newsSourcesRequest(false)));
};

export const saveNewsSource = (newsSourceId, onSaveSuccess) => (dispatch, getState) => {
  dispatch(newsSourcesRequest(true));
  const shouldCreate = newsSourceId === 'new';
  const { newsSources } = getState().news;
  const newsSourceEntity = newsSources[newsSourceId];

  createOrUpdateNewsSource(newsSourceEntity, shouldCreate)
    .then((response) => {
      if (response.httpStatus) {
        throw new Error (response.rootCause);
      }
      dispatch(setNewsSource(response));
      return response
    })
    .then((res) => {
      dispatch(fetchNewsSources());
      if (onSaveSuccess) onSaveSuccess(res);
    })
    .catch(() => dispatch(newsSourcesError(true)))
    .finally(() => dispatch(newsSourcesRequest(false)));
};

const createOrUpdateNewsSource = (data, create = true) =>{
  if(create){
    return ZApiClient.getNewsSourcesClient().createNewsSource(new NewsSource(data));
  } else {
    return ZApiClient.getNewsSourcesClient().updateNewsSource(new NewsSource(data));
  }
};

/**
 *
 * @param {string} newsSourceId
 * @param {object} data
 */
export const createLogo = (newsSourceId, data) => {
  return dispatch => {
    dispatch(logoRequest(true));
    return createImage(data)
      .then((photo) => {
        dispatch(setLogo(newsSourceId, photo));
      })
      .catch(() => dispatch(logoError(true)))
      .finally(() => dispatch(logoRequest(false)));
  };
};

/**
 *
 * @param {string} newsSourceId
 * @param {object} data
 */
export const updateLogo = (newsSourceId, data) => {
  return dispatch => {
    dispatch(logoRequest(true));
    return updateImage(data)
      .then((photo)=>{
        dispatch(setLogo(newsSourceId, photo));
      })
      .catch(() => dispatch(logoError(true)))
      .finally(() => dispatch(logoRequest(false)));
  };
};
