import React from 'react';
import FooterSection from './footer-section';
import ZDraggableGrid from '../../../z-draggable-grid.js';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const onDragEnd = (result, list) => {
  let items = reorder(
    list,
    result.oldIndex,
    result.newIndex
  );
  return items
}

const FooterLinksView = ({
  sections,
  articleId,
  onSectionOrderChange,
  readOnly,
}) => (
  <>
  { sections &&
    <ZDraggableGrid 
      onDragEnd={(result)=>onSectionOrderChange(onDragEnd(result, sections))}
      disabled={readOnly}
    >
        {sections.map((ele, i) => (
          <FooterSection 
            key={`footer-section-${ele.id}`}
            index={i} 
            inputKey={`footer-section-input-${ele.id}`}
            links={ele.links || []}
            title={ele.title}
            hash={ele.hash}
            id={ele.id}
            articleId={articleId}
          />
        ))}
    </ZDraggableGrid>
  }
  </>
);

export default FooterLinksView;
