import React from 'react';
import ZAutoComplete from '../../../z-autocomplete';
import style from './news-source.module.scss';

const NewsSnippetEditorView = ({
  fetchNewsSourceNames,
  onClickCreateSource,
  onClickEdit,
  onSelect,
  newsSource,
}) => (
  <div className={style['source']}>
    {newsSource && (
      <img
        alt={newsSource.logo?.alt}
        src={newsSource.logo?.uri}
        width={100}
      />
    )}
    <div className={style['source__actions']}>
      <ZAutoComplete
        autoComplete={fetchNewsSourceNames}
        onSelect={onSelect}
        returnsObject
        label="Select news source"
        placeholder={newsSource.name}
      />
      <button
        className={style['source__button']}
        onClick={() => onClickEdit(newsSource)}
        type="button"
      >
        Edit
      </button>
      <button
        className={style['source__button']}
        onClick={onClickCreateSource}
        type="button"
      >
        Create New
      </button>
    </div>
  </div>
);

export default NewsSnippetEditorView;
