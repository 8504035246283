import {
  VERSIONS_REQUEST,
  VERSIONS_SUCCESS,
  VERSIONS_ERROR,
  VERSIONS_LOAD_MORE_SUCCESS,
  VERSION_ERROR,
  VERSION_REQUEST,
  VERSION_SUCCESS,
  ADD_SAVED_ENTITY_VERSION,
  TOGGLE_READ_ONLY,
} from '../actions/versions';
import { PUBLISHED_CURRENT_SUCCESS, PUBLISHED_STATUS_SUCCESS } from '../actions/published';

const version = (state = {
  isFetching: false,
  error: false,
}, action) => {
  switch(action.type) {
    case VERSION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        errors: {},
      };
    case VERSION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        updateId: action.updateId,
        errors: action.errors,
        lastUpdated: action.receivedAt,
      };
    case VERSION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case PUBLISHED_CURRENT_SUCCESS:
    case PUBLISHED_STATUS_SUCCESS:
      return {
        isFetching: false,
        error: false,
      };
    default:
      return state;
  }
};

const versions = (state = {
  isFetching: false,
  error: false,
  readOnly: false,
}, action) => {
  const { entityId, json, receivedAt, offset, newVersion } = action;
  switch(action.type) {
    case VERSIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case VERSIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        [entityId]: {
          items: [...json],
          offset,
          lastUpdated: receivedAt,
        }
      };
    case VERSIONS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        [entityId]: {
          ...state[entityId],
          items: [...state[entityId].items, ...json],
          offset: offset,
          lastUpdated: receivedAt,
        },
      };
    case ADD_SAVED_ENTITY_VERSION:
      if (entityId in state) {
        return {
          ...state,
          [entityId]: {
            ...state[entityId],
            items: [newVersion, ...state[entityId].items],
          }
        }
      }
      return state;
    case VERSIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case VERSION_REQUEST:
    case VERSION_SUCCESS:
    case VERSION_ERROR:
      return {
        ...state,
        [entityId]: {
          ...state[entityId],
          versionToPublish: version(state[entityId].versionToPublish, action),
        }
      };
    case TOGGLE_READ_ONLY:
      return {
        ...state,
        readOnly: !state.readOnly
      };
    default:
      return state;
  }
};

export default versions;
