import { connect } from 'react-redux';
import BreadcrumbsContainer from './breadcrumbs-container';
import {fetchBreadcrumbs, setBreadcrumbsT} from '../../../../state/actions/shared/breadcrumbs';

const mapStateToProps = (state, ownProps) => ({
  breadcrumbList: state.breadcrumbs[ownProps.entityId]?.breadcrumbList,
  error: state.breadcrumbs.error,
  isFetching: state.breadcrumbs.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBreadcrumbs: (entityId, entityType) => dispatch(fetchBreadcrumbs(entityId, entityType)),
  setBreadcrumbs: (entityType, breadcrumbs, entityId) => dispatch(setBreadcrumbsT(entityType, breadcrumbs, entityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsContainer);
