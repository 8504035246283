import React from 'react';
import ContentView from './content-view';
import ZApiClient from '../../../utils/client/z-api-client';

const ContentContainer = ({
  id,
  itinerary,
  summaryError,
  onUpdateSummary,
  onPlacesDelete,
  onPlacesSelect,
  placesVisited,
  placesVisitedError,
}) => {

  const onDeletePlace = (arr) => {
    const ret = arr.map((ele)=> ele.label);
    onPlacesDelete(ret);
  }

  const onSelectPlace = (val) => {
    onPlacesSelect(val);
  }

  const placesAutoComplete = (text) => {
    let searchText = text.toLowerCase();
    return ZApiClient
    .getLocationsClient()
    .getNamesByFragment(searchText, { limit: 10 });
  }
  

  return (
    <ContentView
      id={id}
      summary={itinerary.summary}
      onUpdateSummary={onUpdateSummary}
      summaryError={summaryError}
      autoCompletePlaces={placesAutoComplete}
      onSelectPlace={onSelectPlace}
      onDeletePlace={onDeletePlace}
      placesVisited={placesVisited}
      placesVisitedError={placesVisitedError}
    />
  )
}

export default ContentContainer;
