import React from 'react';
import ZTextArea from '../../../z-text-area';
import ZInput from '../../../z-input';
import ZToggle from '../../../z-toggle';

/**
 * 
 * @TODO Resolve Price Errors
 *  - starting price
 *  - price comments
 *  - included notes
 */

const PriceView = ({
  startingPrice,
  onUpdateStatingPrice,
  priceComments,
  priceCommentsErrors,
  onUpdatePriceComments,
  includedNotes,
  includedNotesErrors,
  onUpdateIncludedNotes,
  showPrice,
  onShowPriceToggle,
}) => {
  return (
    <>
      <h2>Price</h2>
      <ZToggle
        label='Show Price Tab'
        checked={showPrice}
        onChange={onShowPriceToggle}
        style={{minWidth: '240px', marginBottom: '28px'}}
      />
      <ZInput 
        label='Starting Price (PPPD)' 
        text={startingPrice} 
        onChange={onUpdateStatingPrice} 
        inputKey={'startingPrice'}
        type={'text'}
        stripTags
        style={{marginBottom: '28px'}}
      />
      <ZTextArea 
        allowedFormats={[]} 
        label="Price Comments" 
        value={priceComments} 
        inputKey={'priceComments'}
        onUpdate={onUpdatePriceComments}
        stripTags
        error={
          priceCommentsErrors && !priceCommentsErrors.valid ?
          priceCommentsErrors :
          false
        }
      />
      <ZTextArea 
        allowedFormats={['ITALICS', 'LINKS', 'BULLET_POINTS']} 
        label="Whats Included" 
        value={includedNotes} 
        inputKey={'whatsIncluded'}
        onUpdate={onUpdateIncludedNotes}
        stripTags
        error={
          includedNotesErrors && !includedNotesErrors.valid ?
          includedNotesErrors :
          false
        }
      />
    </>
  )
}

export default PriceView;
