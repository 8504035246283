import React from 'react';
import { connect } from 'react-redux';
import {
  setShowAuthor,
  setShowTOC,
  setShowDate,
} from '../../../../state/actions/article-editor';
import AppearanceView from './appearance-view';

const AppearanceRedux = (props) => (
  <AppearanceView {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const articleEntities = state.articles.article;
  const {editor} = articleEntities;
  const currentArticle = editor[id]
  const { showAuthor, showDate, includeToC } = currentArticle;
  return {
    showAuthor,
    showDate,
    includeToC,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return ({
    onShowAuthorChange: (bool) => dispatch(setShowAuthor(id, bool)),
    onShowDateChange: (bool) => dispatch(setShowDate(id, bool)),
    onIncludeToCChange: (bool) => dispatch(setShowTOC(id, bool)),
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(AppearanceRedux);
