import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './login';

const publicRoutes = (
  <Switch>
    <Route path="/login" render={Login} />
    <Redirect to="/login" />
  </Switch>
);

export default publicRoutes;
