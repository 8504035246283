import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PhotoPreviewView from './logo-view';
import useDebounce from '../../../../hooks/use-debounce';
import Validate from '../../../../utils/validate';

const PhotoPreviewContainer = ({
  createLogo,
  logo,
  logoId,
  newsSourceId,
  deleteLogo,
  showInfo,
  updateLogo,
}) => {
  const placeHolder = 'https://res.cloudinary.com/zicasso/image/upload/v1545258210/Logo-%20Marketing%20Materials/PNG/Zicasso_Logo_no_sub_black_small.png';
  const [formValues, setFormValues] = useState(logo);
  const [fileName, setFileName] = useState();
  const [validUri, setValidUri] = useState(true);
  const loadedRef = useRef(false);
  const changedRef = useRef(false);
  const [debouncedValue] = useDebounce(formValues, 500, changedRef);

  useEffect(() => {
    const { sourceType, sourceUri } = logo;
    if (!!sourceUri && sourceType === 'Cloudinary') {
      const splitFile = sourceUri.endsWith('.jpg')
        ? sourceUri.slice(0, -4).split('/')
        : sourceUri.split('/');
      const { length } = splitFile;
      setFileName(`${splitFile[length-2]}/${splitFile[length-1]}`);
    }
  }, [logo]);

  useEffect(() => {
    if (formValues.id !== logoId) {
      setFormValues(logo)
    }
  }, [logoId, formValues, logo]);

  useEffect(() => {
    if (!logoId && loadedRef.current) {
      createLogo(newsSourceId, debouncedValue);
      changedRef.current = false;
    } else if (!!logoId && changedRef.current) {
      updateLogo(newsSourceId, debouncedValue);
      changedRef.current = false;
    };
  }, [debouncedValue, newsSourceId, logoId, createLogo, updateLogo]);

  useEffect(() => {
    if (!!formValues.uri) {
      const valid = Validate.imageUrl(formValues.uri);
      setValidUri(valid);
    } else {
      setValidUri(true);
    }
  }, [formValues.uri]);

  const onChange = (label) => (text) => {
    setFormValues((f) => ({
      ...f,
      [label]: text,
    }));
    changedRef.current = true;
  }

  const removePhoto = () => {
    deleteLogo(newsSourceId, null);
    changedRef.current = false;
    loadedRef.current = false;
  }

  const onLoad = () => {
    const { alt, title, uri } = formValues;
    if (!!uri && validUri) {
      loadedRef.current = true;
      if (changedRef.current) changedRef.current = !!alt || !!title;
    }
  }

  return (
    <PhotoPreviewView
      alt={formValues.alt}
      fileName={fileName}
      gravity={formValues.gravity}
      info={formValues}
      isFetching={logo.isFetching}
      onChange={onChange}
      onLoad={onLoad}
      logoId={logoId}
      placeHolder={placeHolder}
      readOnly={!!formValues.sourceUri}
      removePhoto={removePhoto}
      showDelete={!!formValues.sourceUri}
      showInfo={showInfo}
      src={formValues.uri}
      title={formValues.title}
      validUri={validUri}
    />
  );
}
PhotoPreviewContainer.propTypes = {
  logo: PropTypes.object,
  logoId: PropTypes.string,
  deleteLogo: PropTypes.func.isRequired,
  updateLogo: PropTypes.func.isRequired,
  createLogo: PropTypes.func.isRequired,
  showInfo: PropTypes.bool,
};
PhotoPreviewContainer.defaultProps = {
  logo: {},
  logoId: undefined,
  showInfo: true,
};

export default PhotoPreviewContainer;
