import { connect } from 'react-redux';
import {
  fetchCareers,
  setCareer,
  createNew,
} from '../../state/actions/careers-editor';
import CareersContainer from './careers-container';

const mapStatetoProps = (state) => ({
  isFetching: state.careers?.isFetching,
  careers: state.careers?.allCareers || [],
});

const mapDispatchToProps = (dispatch) => ({
  createNew: () => dispatch(createNew()),
  onLoad: () => dispatch(fetchCareers()),
  setCareer: (career) => dispatch(setCareer(career)),
});


export default connect(mapStatetoProps, mapDispatchToProps)(CareersContainer);
