import React from 'react';
import Breadcrumbs from './breadcrumbs';
import Countries from './countries';
import Paths from '../../shared/components/paths';
import Metatags from './meta-tags';
import TravelCompany from './travel-company';
import FooterLinks from './footer-links';
import FooterSearch from './footer-search';
import ZAddButton from '../../z-button-add';


const OptimizationView = ({
  entityId,
  title,
  onFooterSearch,
  scrollTargetEle,
  onTopSectionClick,
}) => {
  return (
    <>
      <h2>Admin Settings</h2>
      <TravelCompany
        entityId={entityId}
      />
      <br/>
      <Breadcrumbs
        entityId={entityId}
      />
      <br/>
      <Countries
        entityId={entityId}
      />
      <br/>
      <Paths
        entityId={entityId}
        entityTitle={title}
        entityType="Itinerary"
        pathPrefix="luxury-vacation-tours/"
      />
      <br/>
      <Metatags
        entityId={entityId}
      />
      <br/>
      <h3>SEO Footer Links</h3>
      <FooterSearch
        onFooterSearch={onFooterSearch}
      />
      <br/>
      <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
        <ZAddButton onClick={onTopSectionClick}>Add Section</ZAddButton>
      </div>
      <br/>
      <FooterLinks
        entityId={entityId}
      />
      <div ref={scrollTargetEle}/>
      <br/>
    </>
  )
}

export default OptimizationView;
