import React from 'react';
import CountriesView from './countries-view';

const CountriesContainer = ({
  countries,
  locations,
  onCountrySelect,
  onCountryDelete,
}) => {

  // Auto Complete functions must be thenable
  const countryAutoComplete = (text) => {
    let searchText = text.toLowerCase();
    const res = countries.filter((country) => {
      let test = country.toLowerCase();
      return test.startsWith(searchText);
    })
    return Promise.resolve(res);
  } 

  // Redundant but needed to pass to z-autocomplete
  const onSelectCountry = (val) => {
    onCountrySelect(val);
  }

  const onDeleteCountry = (arr) => {
    const ret = arr.map((ele)=> ele.label);
    onCountryDelete(ret);
  }

  return (
    <CountriesView
      countryAutoComplete={countryAutoComplete}
      onCountrySelect={onSelectCountry}
      onCountryDelete={onDeleteCountry}
      locations={locations}
    />
  )
}

export default CountriesContainer;
