import React from 'react';
import PropTypes from 'prop-types';
import styles from './lightbox.module.scss';
import { ReactComponent as RightArrow } from '../../../assets/inline/right-arrow.svg';
import { ReactComponent as Close } from '../../../assets/inline/close_icon.svg';
import LightBoxItem from './lightbox-item-view';
import { mathTrunc } from '../../../utils/utils';

const LightboxCarouselView = ({
  allItems,
  disableAnimation,
  handleZoomPlus,
  handleZoomMinus,
  index,
  items,
  next,
  prev,
  setIndex,
  onClickClose,
  thumbnailMargin,
  thumbnailWidth,
  zoomScale,
}) => (
  <div className={styles['overlay']}>
    <div className={styles['wrapper']}>
      <div className={styles['wrapper--top']}>
        <ZoomButton
          handleZoomMinus={handleZoomMinus}
          handleZoomPlus={handleZoomPlus}
          zoomScale={zoomScale}
        />
        <CloseButton onClickClose={onClickClose} />
      </div>
      <div className={styles['wrapper--content']}>
        <div
          className={`
            ${styles['overlay--content']}
            ${disableAnimation ? styles['overlay--content--disabled'] : ''}
          `}
          style={{
            width: `${100 * allItems.length}vw`,
            maxWidth: `calc(100% * ${allItems.length})`,
            left: `${-100 * (index)}%`,
          }}
        >
          {allItems && Array.isArray(allItems) && allItems.map((item, i) => (
            <LightBoxItem
              className={styles['overlay--image']}
              image={item}
              index={i}
              // eslint-disable-next-line react/no-array-index-key
              key={`overlay-image-${i}`}
              zoom={zoomScale}
            />
          ))}
        </div>
      </div>
      <div className={styles['wrapper--bottom']}>
        <div className={styles['overlay--left--overlay']} />
        <div className={styles['overlay--right--overlay']} />
        <div
          aria-label="Go to the previous image"
          className={styles['overlay--left']}
          onClick={prev}
          onKeyUp={(e) => { if (e.keyCode === 13) prev(); }}
          role="button"
          tabIndex={0}
        >
          <div className={styles['arrow--wrapper']} tabIndex={-1}>
            <RightArrow
              height={16}
              style={{ transform: 'scale(-1, 1)' }}
              width={16}
            />
          </div>
        </div>
        <div
          className={styles['overlay--content--thumbs']}
          style={{
            left: `calc(50% - ${thumbnailWidth / 2}px - (${(index - 1) * (thumbnailWidth + thumbnailMargin)}px))`,
          }}
        >
          {allItems && Array.isArray(allItems) && allItems.map((item, i) => (
            (i !== 0 && i !== allItems.length - 1) && (
              <LightBoxItem
                className={`${styles['overlay--thumbnail']} ${i === index ? styles['overlay--thumbnail--selected'] : null}`}
                image={item}
                index={i}
                // eslint-disable-next-line react/no-array-index-key
                key={`overlay-image-small-${i}`}
                setIndex={setIndex}
              />
            )
          ))}
        </div>
        <div
          aria-label="Go to the next image"
          className={styles['overlay--right']}
          onClick={next}
          onKeyUp={(e) => { if (e.keyCode === 13) next(); }}
          role="button"
          tabIndex={0}
        >
          <div className={styles['arrow--wrapper']} tabIndex={-1}>
            <RightArrow
              height={16}
              width={16}
            />
          </div>
        </div>
      </div>
      <div className={styles['wrapper--bottom-label']}>
        <p>
          {index % items.length === 0 ? items.length : index % items.length}
          /
          {items.length}
        </p>
      </div>
    </div>
  </div>
);
LightboxCarouselView.propTypes = {
  allItems: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      uri: PropTypes.string.isRequired,
    }),
  ),
  disableAnimation: PropTypes.bool,
  handleZoomPlus: PropTypes.func.isRequired,
  handleZoomMinus: PropTypes.func.isRequired,
  index: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.object,
  ),
  next: PropTypes.func,
  onClickClose: PropTypes.func.isRequired,
  prev: PropTypes.func,
  setIndex: PropTypes.func.isRequired,
  thumbnailMargin: PropTypes.number.isRequired,
  thumbnailWidth: PropTypes.number.isRequired,
  zoomScale: PropTypes.number.isRequired,
};
LightboxCarouselView.defaultProps = {
  allItems: [],
  disableAnimation: false,
  index: null,
  items: [],
  next: null,
  prev: null,
};

const CloseButton = ({ onClickClose }) => (
  <div
    className={styles['close--wrapper']}
    onClick={onClickClose}
    onKeyUp={(e) => { if (e.keyCode === 13) onClickClose(); }}
    role="button"
    tabIndex={0}
  >
    <Close
      className={styles['close']}
      width={14}
      height={14}
    />
  </div>
);
CloseButton.propTypes = {
  onClickClose: PropTypes.func.isRequired,
};

const ZoomButton = ({
  handleZoomMinus,
  handleZoomPlus,
  zoomScale,
}) => (
  <div className={styles['zoom--wrapper']}>
    <span
      style={{ cursor: 'pointer' }}
      onClick={handleZoomMinus}
      onKeyUp={(e) => { if (e.code === 'Enter') handleZoomMinus(); }}
      role="button"
      tabIndex={0}
    >
      -
    </span>
    <span>
      {`${mathTrunc(zoomScale * 100)}%`}
    </span>
    <span
      style={{ cursor: 'pointer' }}
      onClick={handleZoomPlus}
      onKeyUp={(e) => { if (e.code === 'Enter') handleZoomPlus(); }}
      role="button"
      tabIndex={0}
    >
      +
    </span>
  </div>
);
ZoomButton.propTypes = {
  handleZoomPlus: PropTypes.func.isRequired,
  handleZoomMinus: PropTypes.func.isRequired,
  zoomScale: PropTypes.number.isRequired,
};

export default LightboxCarouselView;
