/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const PortalContainer = ({ children, container, usePortal }) => {
  const [parentNode, setParentNode] = useState();
  useEffect(() => {
    const containerNode = container || document.body;
    setParentNode(containerNode);
  }, [container]);

  return usePortal && parentNode ? createPortal(children, parentNode) : children;
};

PortalContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  container: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
  ]),
  usePortal: PropTypes.bool,
};
PortalContainer.defaultProps = {
  usePortal: true,
};

export default PortalContainer;
