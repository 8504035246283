import React, { useRef } from 'react';
import OptimizationView from './optimization-view';

const OptimizationContainer = ({
  id,
  onCreateSection,
  onFooterSearch,
  title,
}) => {
  const scrollTargetEle = useRef();

  const onTopSectionClick = () => {
    onCreateSection();
    const current = scrollTargetEle.current;
    const position = (current.getBoundingClientRect().top + window.pageYOffset) + 1500;
    window.scrollTo({
      top: position,
      behavior: 'smooth',
    });
  }

  return (
    <OptimizationView
      entityId={id}
      onCreateSection={onCreateSection}
      onTopSectionClick={onTopSectionClick}
      onFooterSearch={onFooterSearch}
      scrollTargetEle={scrollTargetEle}
      title={title}
    />
  )
}

export default OptimizationContainer;
