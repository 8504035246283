import {
  TEAM_MEMBERS_REQUEST,
  TEAM_MEMBERS_ERROR,
  TEAM_MEMBERS_RECEIVED,
  TEAM_MEMBER_SAVE_REQUEST,
  SET_TEAM_MEMBER,
  TEAM_MEMBER_SAVE_ERROR,
  SET_JOB_TITLE,
  SET_TEAM_MEMBER_NAME,
  SET_TEAM_MEMBER_TEXT,
  SET_TEAM_MEMBER_PHOTO,
  SET_DISPLAYED,
  CREATE_NEW_TEAM_MEMBER,
} from '../actions/team-editor';

const initialTeamMemberState = {
  isSaving: false,
  error: false,
  displayed: true,
  name: '',
  title: '',
  description: '',
  image: {},
};

const teamMember = (state = initialTeamMemberState, action) => {
  switch (action.type) {
    case TEAM_MEMBER_SAVE_REQUEST:
      return {
        ...state,
        isSaving: action.isSaving,
      };
    case TEAM_MEMBER_SAVE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_TEAM_MEMBER:
      return {
        ...state,
        ...action.teamMember
      };
    case SET_DISPLAYED:
      return {
        ...state,
        displayed: action.displayed,
      };
    case SET_JOB_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case SET_TEAM_MEMBER_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_TEAM_MEMBER_TEXT:
      return {
        ...state,
        description: action.text,
      };
    case SET_TEAM_MEMBER_PHOTO:
      return {
        ...state,
        image: action.photo,
      };
    case CREATE_NEW_TEAM_MEMBER:
      return {
        ...initialTeamMemberState,
      };
    default:
      return state;
  }
};

const teamMembers = (
  state = {
    isFetching: false,
    error: false,
    isSaving: false,
    allTeamMembers: [],
  },
  action
) => {
  switch (action.type) {
    case TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case TEAM_MEMBERS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case TEAM_MEMBERS_RECEIVED: 
      return {
        ...state,
        allTeamMembers: action.allTeamMembers,
      };
    case SET_TEAM_MEMBER:
      return {
        ...state,
        [action.teamMember.id]: teamMember(state[action.teamMember.id], action),
      };
    case TEAM_MEMBER_SAVE_ERROR:
    case TEAM_MEMBER_SAVE_REQUEST:
    case SET_JOB_TITLE:
    case SET_TEAM_MEMBER_NAME:
    case SET_TEAM_MEMBER_TEXT:
    case SET_TEAM_MEMBER_PHOTO:
    case SET_DISPLAYED:
      return {
        ...state,
        [action.id]: teamMember(state[action.id], action),
      };
    case CREATE_NEW_TEAM_MEMBER:
      return {
        ...state,
        new: teamMember(null, action),
      };
    default:
      return state;
  }
};

export default teamMembers;
