import React from 'react';
import ZInputView from './z-input-view';
import _ from 'lodash';

export default class ZInputContainer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      text: props.initialValue || ''
    }
    this.update = (this.props.onUpdate) ? this.props.onUpdate : () => {};
    this.debounceUpdate = _.debounce(()=>{
      this.update(this.state.text);
    }, 400)
  }

  onValueChange = (event) => {
    if(this.props.onChange){
      this.props.onChange(event.target.value)
    } else {
      this.setState({
        ...this.state,
        text: event.target.value
      }, this.debounceUpdate)
    }
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (this.props.onEnter){
        this.props.onEnter(e);
      }
    }
  }

  render() {
    let props ={
      ...this.state,
      ...this.props
    }
    return (
      <ZInputView 
        {...props} 
        onChange={this.onValueChange} 
        onKeyDown={this.onKeyDown} 
        key={props.inputKey}></ZInputView>
    )
  }
}