import React, { useEffect, useState } from 'react';
import LandingPageCategoryView from './landing-page-category-view';
import ZSpinner from '../z-spinner';
import ErrorModal from './error-modal';
import sharedStyle from '../shared/styles/shared.module.scss';
import { decodeHTMLEntities } from '../../utils/utils';

const defaultBtnText = 'Save';

const LandingPageCategoryContainer = ({
  currentUpdateId,
  disabledMessage,
  entity,
  errors,
  id,
  isFetching,
  notFound,
  onCreateNew,
  onLoad,
  onSave,
  onTitleChange,
  onToggleReadOnly,
  paramUpdateId,
  readOnly,
  saved,
  saveDisabled,
}) => {
  const [openToast, setOpenToast] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);
  const [btnText, setBtnText] = useState(defaultBtnText);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  useEffect(()=> {
    onLoad(paramUpdateId);
  }, [onLoad, paramUpdateId]);

  useEffect(() => {
    if (paramUpdateId) {
      onToggleReadOnly();
      return () => onToggleReadOnly();
    }
  }, [paramUpdateId, onToggleReadOnly]);

  useEffect(()=>{
    if (!saveSuccess && saved) {
      setSaveSuccess(true);
      setOpenToast(true);
      setBtnText(defaultBtnText);
      setSaveBtnDisabled(false);
    }
  }, [saveSuccess, saved]);

  useEffect(() => {
    if (entity?.title) document.title = `lpc: ${decodeHTMLEntities(entity.title)}`;
  }, [entity?.title]);

  const onToastClose = () => {
    setOpenToast(false);
  }

  const onSaveClick = () => {
    setSaveSuccess(false);
    setBtnText('...Saving Page')
    setSaveBtnDisabled(true);
    onSave();
  }

  if (isFetching) {
    return (
      <div className={sharedStyle['spinner']}>
        <ZSpinner/>
      </div>
    );
  } else if (notFound) {
    return (
      <ErrorModal
        id={id}
        onCreateNew={onCreateNew}
      />
    );
  } else {
    return (
      <LandingPageCategoryView
        btnText={btnText}
        disabledMessage={disabledMessage}
        errors={errors}
        id={id}
        onSave={onSaveClick}
        onTitleChange={onTitleChange}
        onToastClose={onToastClose}
        openToast={openToast}
        readOnly={readOnly}
        saved={saved}
        saveDisabled={saveDisabled}
        title={entity.title}
        saveBtnDisabled={saveBtnDisabled}
        subtitle={entity.subtitle}
        updateId={currentUpdateId}
      />
    );
  }
};

export default LandingPageCategoryContainer;
