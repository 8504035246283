import { connect } from 'react-redux';
import GalleryLinksContainer from './gallery-links-container';
import {
  deleteGalleryLinkSection,
  setGalleryLinks,
  setGalleryLinksTitle,
} from '../../../../state/actions/landing-page-editor';
import { receivedPhotosT } from '../../../../state/actions/shared/photo';

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const {editor} = state.landingPages.landingPage;

  return ({
    galleryLinkCount: editor[id]?.galleryLinkCount || 0,
    links: editor[id]?.galleryLinks?.links || [],
    title: editor[id]?.galleryLinks?.title || '',
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return ({
    deleteGalleryLinkSection: () => dispatch(deleteGalleryLinkSection(id)),
    receivedPhoto: (photo) => dispatch(receivedPhotosT('landing_page', { [photo.id]: photo })),
    setGalleryLinks : (galleryLinks) => dispatch(setGalleryLinks(id, galleryLinks)),
    setGalleryLinksTitle: (title) => dispatch(setGalleryLinksTitle(id, title)),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryLinksContainer);
