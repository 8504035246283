import { validate } from '../validate/validation';
import ZApiClient from './z-api-client';

const defaultSummary = '<p>At Zicasso, we excel at helping you get your vacation planned. Not just any vacation, but an exceptional one filled with inspiring and life-enriching experiences.</p><p>Our approach is different. We don’t plan your vacation ourselves. We’re experts at finding and matching travelers to travel specialists from the top 10% of the industry.</p><p>Each has been meticulously screened and has the expertise and local connections to handle every element of your vacation.</p>';

const companyCategoryValidationRequirements = {
  breadcrumb: {
    required: true,
    errorMessage: 'A breadcrumb (parent) is required.',
  },
  heroImage: {
    required: true,
    errorMessage: 'A hero image is required.',
  },
  title: {
    required: true,
    unique: true,
    errorMessage: 'Title is required and must be unique.'
  },
  subtitle: {
    required: true,
    errorMessage: 'A subtitle is required.'
  },
  summary: {
    required: true,
    errorMessage: 'A summary is required.'
  },
  paths: {
    required: true,
    min: 1,
    errorMessage: 'At least 1 path is required.'
  },
  metatags: {
    required: true,
    // titleTag
    title: {
        required: true,
        errorMessage: 'The metatag title tag is required.'
    },
    // descriptionTag
    description: {
        required: true,
        errorMessage: 'The metatag description tag is required.'
    },
    // canonicalUrl
    canonical: {
        required: true,
        errorMessage: 'A canonical path is required.'
    }
}
};

class CompanyCategory {

  _generateDescription = (locations) => {
    locations = locations || [];
    let locationText = locations.length > 0 ? `${locations[0]} ` : '';
    return `Zicasso: ${locationText} travel & tours by top competing travel agents & tour operators.`
  }

  /**
 * @param {object} metatags
 * @param {string} title
 * Checks if meta title exist.
 * If no meta title present use default rules to build meta title
 */
  _mapMetaTags = (metatags, companyCategory) => {
    let {
      title: metaTitle,
      canonical,
      description,
    } = metatags;
    const {
      title,
      locations,
    } = companyCategory;
    if (!metaTitle) {
      metaTitle = `${title} | Zicasso`;
    }
    if (!description) {
      description = this._generateDescription(locations);
    }
    return {
      ...canonical && { canonical: canonical },
      description,
      title: metaTitle,
    }
  }

  _getMetaTags = (metatags) => {
    return {
      title: '',
      description: '',
      canonical: '',
      ...metatags,
    }
  };

  _getTuid  = () => (
    ZApiClient.getTuidsClient().createTuid()
  );

  _mapFooterIdtoSection = (ids, sections) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        return {...sections[ele]} ;
      });
    }
  }

  _mapSectionIdToSections = (ids, sections, photos) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        const { photoId, ...rest } = sections[ele];
        const section = {
          ...rest,
          ...photoId && { image: photos[photoId] },
        };
        return section;
      });
    }
  }

  _mapPhotos = (photos) => {
    let ret = {};
    if (!photos || !photos[0]) {
      return ret
    };
    for (const photo of photos) {
      if (photo && !!photo.id) ret[photo.id] = { ...photo };
    }
    return ret;
  }

  _mapSectionsToEntities = async (sections = [], footerLinks) => {
    const sectionIds = [];
    const sectionMap = {};
    const sectionPhotos = [];
    for(const section of sections){
      let {id, image, photoId} = section;
      if(!id){
        const tuidRes = await this._getTuid();
        id = tuidRes.id;
      }
      if(image){
        photoId = image.id;
        sectionPhotos.push({...image})
      }
      sectionIds.push(id);
      sectionMap[id] = {...section, id, photoId};
    };
    const{
      idMap:footerIdMap,
      idList:footerIds,
    } = await this._mapFooterSections(footerLinks);
    return {
      sectionIds,
      footerIds,
      sectionMap : {...sectionMap, ...footerIdMap},
      sectionPhotos,
    };
  };

  _mapFooterSections = async (sections) => {
    const idList = [];
    const idMap = {};
    if(sections){
      for(const section of sections){
        let {id, links} = section;
        if(!id){
          const {id:resId} = await this._getTuid();
          id = resId;
        }
        if(links){
          const promiseArr = links.map((link)=> this._getIdIfNeeded(link));
          links = await Promise.all(promiseArr);
        }
        idList.push(id);
        idMap[id] = {
          ...section,
          id,
          links,
        }
      }
    }
    return {
      idList,
      idMap,
    }
  }

  _getIdIfNeeded = async (section) => {
    let {id} = section;
    if(!id) {
      const {id:resId} = await this._getTuid();
      id = resId;
      return {...section, id};
    } else {
      return {...section};
    }
  }

/**
 *
 * @param {entity} LandingPage
 * Validates an Landing Page for publishing
 */
  validateForPublish(entity) {
    return validate(entity, companyCategoryValidationRequirements);
  }

  /**
   * Accepts the redux state for a company category. (crurrently ).
   * company category state includes 2 fields
   * {
   *  editor:{
   *    isFetching: boolean,
   *    isSaving: boolean,
   *    saved: boolean,
   *    [companyCategoryId] : {...},
   *  },
   *  photos:{
   *    [photoId] : {...}
   *  },
   *  sections:{
   *    [sectionId] : {}
   *  }
   * }
   * @param {string} companyCategoryId
   * @param {object} companyCategoryState
   */
  getCompanyCategory(companyCategoryId, state) {
    const currentCompanyCategory = state.editor[companyCategoryId];
    const {
      breadcrumb,
      breadcrumbTitle,
      callToAction,
      flagCountryCode,
      headerPhotoId,
      id,
      keywordsExclude,
      keywordsInclude,
      locations,
      metatags,
      subtitle,
      summary,
      title,
      type,
      footerSectionIds,
      sectionIds,
    } = currentCompanyCategory;

    const ret = {
      breadcrumb,
      breadcrumbTitle,
      callToAction,
      flagCountryCode,
      ...headerPhotoId && { heroImage: state.photos[headerPhotoId] },
      id,
      keywordsExclude,
      keywordsInclude,
      locations,
      metatags: this._mapMetaTags(metatags, currentCompanyCategory),
      subtitle,
      summary: summary || defaultSummary,
      title,
      type,
      sections: this._mapSectionIdToSections(sectionIds, state.sections, state.photos),
      footerLinks: this._mapFooterIdtoSection(footerSectionIds, state.sections),
    }
    return ret;
  }

  /**
   * Accepts an entity from our backend and returns the following:
   * {
   *  id: 'companyCategoryId',
   *  [id] : {mapped companyCategory},
   *  photos: {
   *    [photoId] : {...},
   *  },
   *  sections: {
   *    [sectionId] : {...},
   *  },
   * }
   * @param {object} companyCategory
   */
  async getState(companyCategory) {
    const { heroImage, metatags, sections, footerLinks, } = companyCategory;
    const {
      sectionIds,
      footerIds,
      sectionMap,
    } = await this._mapSectionsToEntities(sections, footerLinks);
    const retCompanyCategory = {
      ...companyCategory,
      metatags: this._getMetaTags(metatags),
      summary: companyCategory.summary || defaultSummary,
      ...heroImage && { headerPhotoId: heroImage.id },
      sectionIds,
      footerSectionIds: footerIds,
    };
    return {
      id: companyCategory.id,
      [companyCategory.id]: retCompanyCategory,
      photos: this._mapPhotos([heroImage]),
      sections : sectionMap,
    };
  }
}

export default CompanyCategory;
