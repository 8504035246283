import React from 'react';
import { connect } from 'react-redux';
import MetatagsView from './meta-tags-view';
import {
  setMetaTitle,
  setMetaDescription,
} from '../../../../state/actions/meta-tags';

const MetatagsRedux = (props) => (
  <MetatagsView {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const { entityId:id } = ownProps;
  const guideEntities = state.guides.guide;
  const { editor } = guideEntities;
  const currentEntity = editor[id] || {};
  const { metatags, title } = currentEntity;
  return {
    id,
    title,
    metatags,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onMetaTitleChange: (text) => dispatch(setMetaTitle(text, id)),
    onMetaDescriptionChange: (text) => dispatch(setMetaDescription(text, id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MetatagsRedux);
