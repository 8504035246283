import { combineReducers } from 'redux';
import sectionsT from './sections';
import photosT from './photos';
import metatags from './meta-data';
import {
  SET_DEPARTURE_NOTES,
  ADD_PLACE_VISITED,
  SET_PLACES_VISITED,
  SET_HIGHLIGHTS,
  SET_DESCRIPTION,
  SET_PRICE,
  SET_PRICE_COMMENTS,
  SET_INCLUDED_NOTES,
  SET_COMPANIES,
  SET_COMPANY,
  UNSET_COMPANY,
  SET_DAYS,
  ENABLE_SAVE,
  DISABLE_SAVE,
  SET_SHOW_PRICE,
} from '../actions/itinerary-editor/action-types';
import {
  REQUEST_ITINERARY,
  REQUEST_NEW_ITINERARY,
  RECEIVED_ITINERARY,
  ITINERARY_SAVED,
} from '../actions/shared/similar-action-types';
import{
  SET_META_DATA,
  SET_META_DESCRIPTION,
  SET_META_TITLE,
  SET_NOFOLLOW,
  SET_NOINDEX,
  SET_CANONICAL,
} from '../actions/meta-tags';
import {
  BREADCRUMB_ACTION_NAMES
} from "../actions/shared/breadcrumb-action-types";
import {
  LOCATION_ACTION_NAMES
} from '../actions/shared/location-action-types';
import {
  PHOTO_ACTION_NAMES,
} from '../actions/shared/photo-action-types';
import {
  SECTION_ACTION_NAMES,
} from '../actions/shared/section-action-types';
import {
  TITLE_ACTION_NAMES
} from '../actions/shared/title-action-types';

const addAtIndex = (arr = [], ele, i) => {
  if(!i){
    return [...arr, ele];
  } else if (i > arr.length) {
    return [...arr, ele];
  } else {
    let res =  [...arr];
    res.splice(i, 0, ele);
    return res;
  }
};

const addIfMissing = (ele, arr = []) => {
  if(arr.indexOf(ele) === -1){
    return [...arr, ele];
  } else {
    return [...arr];
  }
};

const removeId = (id, list) => (
  list.filter(ele => ele !== id)
);

const itinerary = (
  state = {
    title: null,
    summary: '',
    sectionIds: [],
    metatags: {},
    dayIds:[],
    showPrice: false,
    footerSectionIds: [],
  },
  action
) => {
  switch(action.type){
    case TITLE_ACTION_NAMES.ITINERARY.SET_TITLE:
      return {
        ...state,
        title: action.value
      };
    case TITLE_ACTION_NAMES.ITINERARY.SET_SUMMARY:
      return {
        ...state,
        summary : action.value,
      };
    case SET_DEPARTURE_NOTES:
      return {
        ...state,
        departureNotes: action.value,
      };
    case LOCATION_ACTION_NAMES.ITINERARY.ADD_COUNTRY:
      return {
        ...state,
        countries: addIfMissing(action.value, state.countries || []),
      };
    case LOCATION_ACTION_NAMES.ITINERARY.SET_COUNTRIES:
      return {
        ...state,
        countries: [...action.list],
      };
    case ADD_PLACE_VISITED:
      return {
        ...state,
        placesVisited: addIfMissing(action.value, state.placesVisited || []),
      };
    case SET_PLACES_VISITED:
      return {
        ...state,
        placesVisited: [...action.list],
      };
    case SET_HIGHLIGHTS:
      return {
        ...state,
        highlights: action.value,
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        description: action.value,
      };
    case SET_PRICE:
      return {
        ...state,
        price: action.value,
      };
    case SET_PRICE_COMMENTS:
      return {
        ...state,
        priceComments: action.value,
      };
    case SET_INCLUDED_NOTES:
      return {
        ...state,
        includedNotes: action.value,
      };
    case BREADCRUMB_ACTION_NAMES.ITINERARY.ADD_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.data
      };
    case BREADCRUMB_ACTION_NAMES.ITINERARY.DELETE_BREADCRUMB:
      return {
        ...state,
        breadcrumb: null
      };
    case BREADCRUMB_ACTION_NAMES.ITINERARY.SET_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle: action.value,
      };
    case BREADCRUMB_ACTION_NAMES.ITINERARY.DELETE_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle : null,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.data,
      };
    case UNSET_COMPANY:
      delete state.company;
      return {
        ...state,
      };
    case PHOTO_ACTION_NAMES.ITINERARY.SET_HEADER_PHOTO:
      return {
        ...state,
        headerPhotoId: action.photo.id,
      };
    case PHOTO_ACTION_NAMES.ITINERARY.REMOVE_HEADER_PHOTO:
      delete state.headerPhotoId;
      return {
        ...state,
      };
    case PHOTO_ACTION_NAMES.ITINERARY.SET_GALLERY:
      return {
        ...state,
        photoIds: action.list,
      };
    case PHOTO_ACTION_NAMES.ITINERARY.REMOVE_GALLERY_PHOTO:
      return {
        ...state,
        photoIds : removeId(action.photoId, state.photoIds),
      };
    case PHOTO_ACTION_NAMES.ITINERARY.ADD_HEADER_THUMB:
      return {
        ...state,
        thumbnailId : action.photo.id,
      };
    case PHOTO_ACTION_NAMES.ITINERARY.REMOVE_HEADER_THUMB:
      delete state.thumbnailId;
      return {
        ...state,
      };
    case PHOTO_ACTION_NAMES.ITINERARY.SET_GALLERY_PHOTO:
      return {
        ...state,
        photoIds : addAtIndex(state.photoIds, action.photo.id, action.index),
      };
    case SET_DAYS:
      return {
        ...state,
        dayIds: [...action.list],
      };
    case SET_META_DATA:
    case SET_META_TITLE:
    case SET_META_DESCRIPTION:
    case SET_NOFOLLOW:
    case SET_NOINDEX:
    case SET_CANONICAL:
      return {
        ...state,
        metatags: metatags(state.metatags, action)
      };
    case SET_SHOW_PRICE:
      return {
        ...state,
        showPrice: action.data,
      };
    case SECTION_ACTION_NAMES.ITINERARY.ADD_SECTION:
      return {
        ...state,
        sectionIds: addAtIndex(state.sectionIds, action.id, action.index),
      };
    case SECTION_ACTION_NAMES.ITINERARY.REMOVE_SECTION:
      return {
        ...state,
        sectionIds: state.sectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.ITINERARY.REORDER_SECTIONS:
      return {
        ...state,
        sectionIds : [...action.sectionIds],
      };
    case SECTION_ACTION_NAMES.ITINERARY.ADD_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds: addAtIndex(state.footerSectionIds, action.id, action.index)
      };
    case SECTION_ACTION_NAMES.ITINERARY.REMOVE_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds : state.footerSectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.ITINERARY.REORDER_FOOTER_SECTIONS:
      return {
        ...state,
        footerSectionIds : [...action.list],
      };
    default:
      return state
  }
};

const editor = (
  state = {
    isFetching: true,
    isSaving: false,
    saved: true,
    notFound: false,
    saveDisabled: false,
    disabledMessage: null,
  },
  action
) => {
  switch(action.type){
    case REQUEST_ITINERARY:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_NEW_ITINERARY:
      return {
        ...state,
        isFetching: true,
        saved: false,
      };
    case RECEIVED_ITINERARY:
      return {
        ...state,
        isFetching: false,
        notFound: false,
        lastUpdate: action.receivedAt,
        [action.id] : itinerary({
          ...state[action.id],
          ...action.itinerary
        }, action),
      };
    case ITINERARY_SAVED:
      return {
        ...state,
        saved: true,
      };
    case LOCATION_ACTION_NAMES.ITINERARY.SET_COUNTRY_LIST:
      return {
        ...state,
        countries: action.list,
      };
    case SET_COMPANIES:
      return {
        ...state,
        travelCompanies: action.map,
      };
    case DISABLE_SAVE:
      return {
        ...state,
        saveDisabled: true,
        disabledMessage: action.message,
      };
    case ENABLE_SAVE:
      return {
        ...state,
        saveDisabled: false,
        disabledMessage: null,
      };
    case TITLE_ACTION_NAMES.ITINERARY.SET_SUMMARY:
    case TITLE_ACTION_NAMES.ITINERARY.SET_TITLE:
    case LOCATION_ACTION_NAMES.ITINERARY.ADD_COUNTRY:
    case LOCATION_ACTION_NAMES.ITINERARY.SET_COUNTRIES:
    case SET_DEPARTURE_NOTES:
    case ADD_PLACE_VISITED:
    case SET_PLACES_VISITED:
    case SET_HIGHLIGHTS:
    case SET_DESCRIPTION:
    case SET_PRICE:
    case SET_PRICE_COMMENTS:
    case SET_INCLUDED_NOTES:
    case BREADCRUMB_ACTION_NAMES.ITINERARY.ADD_BREADCRUMB:
    case BREADCRUMB_ACTION_NAMES.ITINERARY.DELETE_BREADCRUMB:
    case BREADCRUMB_ACTION_NAMES.ITINERARY.SET_BREADCRUMB_TITLE:
    case BREADCRUMB_ACTION_NAMES.ITINERARY.DELETE_BREADCRUMB_TITLE:
    case SET_META_DATA:
    case SET_META_DESCRIPTION:
    case SET_META_TITLE:
    case SET_NOFOLLOW:
    case SET_NOINDEX:
    case SET_CANONICAL:
    case SET_COMPANY:
    case UNSET_COMPANY:
    case SET_DAYS:
    case SET_SHOW_PRICE:
      return {
        ...state,
        saved: false,
        [action.id] : itinerary(state[action.id], action)
      };
    case PHOTO_ACTION_NAMES.ITINERARY.SET_GALLERY:
    case PHOTO_ACTION_NAMES.ITINERARY.REMOVE_GALLERY_PHOTO:
    case PHOTO_ACTION_NAMES.ITINERARY.REMOVE_HEADER_THUMB:
    case PHOTO_ACTION_NAMES.ITINERARY.SET_GALLERY_PHOTO:
    case PHOTO_ACTION_NAMES.ITINERARY.ADD_HEADER_THUMB:
    case PHOTO_ACTION_NAMES.ITINERARY.SET_HEADER_PHOTO:
    case PHOTO_ACTION_NAMES.ITINERARY.REMOVE_HEADER_PHOTO:
    case SECTION_ACTION_NAMES.ITINERARY.ADD_SECTION:
    case SECTION_ACTION_NAMES.ITINERARY.REMOVE_SECTION:
    case SECTION_ACTION_NAMES.ITINERARY.ADD_FOOTER_SECTION:
    case SECTION_ACTION_NAMES.ITINERARY.REMOVE_FOOTER_SECTION:
    case SECTION_ACTION_NAMES.ITINERARY.REORDER_SECTIONS:
    case SECTION_ACTION_NAMES.ITINERARY.REORDER_FOOTER_SECTIONS:
      return {
        ...state,
        saved: false,
        [action.entityId] : itinerary(state[action.entityId], action)
      };
    default:
      return state
  }
};

const itineraryEditor = combineReducers({
  editor,
  photos: photosT('itinerary'),
  sections: sectionsT('itinerary'),
});

export default itineraryEditor;
