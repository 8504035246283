import React from 'react';
import NewsSourceEditorView from './news-source-editor-view';

const NewsSourceEditorContainer = ({
  createLogo,
  closeModal,
  error,
  id,
  isFetching,
  newsSnippetId,
  newsSource: {
    name,
    logo,
  },
  saveNewsSource,
  deleteLogo,
  setName,
  setSnippetSource,
  updateLogo,
}) => {
  const onSuccess = (source) => {
    closeModal();
    setSnippetSource(newsSnippetId, source);
  }

  const handleClickSave = () => {
    saveNewsSource(id, onSuccess);
  };

  return (
    <NewsSourceEditorView
      createLogo={createLogo}
      closeModal={closeModal}
      deleteLogo={deleteLogo}
      error={error}
      isFetching={isFetching}
      logo={logo}
      name={name}
      newsSourceId={id}
      onClickSave={handleClickSave}
      saveDisabled={!name || !logo.id}
      setName={setName}
      updateLogo={updateLogo}
    />
  );
};

export default NewsSourceEditorContainer;
