export const SET_TITLE  = (T) => `SET_TITLE_${T.toUpperCase()}`;
export const SET_SUB_TITLE  = (T) => `SET_SUB_TITLE_${T.toUpperCase()}`;
export const SET_SUMMARY  = (T) => `SET_SUMMARY_${T.toUpperCase()}`;

export const TITLE_ACTION_NAMES = {};

(() => {
    [
        'article',
        'company_category',
        'landing_page_category',
        'content',
        'guide',
        'itinerary',
        'landing_page'
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        TITLE_ACTION_NAMES[entityType] = {};
        TITLE_ACTION_NAMES[entityType]['SET_TITLE'] = SET_TITLE(entityType);
        TITLE_ACTION_NAMES[entityType]['SET_SUB_TITLE'] = SET_SUB_TITLE(entityType);
        TITLE_ACTION_NAMES[entityType]['SET_SUMMARY'] = SET_SUMMARY(entityType);
    });
})();
