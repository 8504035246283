import React from 'react';
import style from './meta-tags.module.scss';
import ZInput from '../../../z-input';

const MetatagsView = ({
  title,
  metatags,
  onMetaTitleChange,
  onMetaDescriptionChange,
}) => (
  <div className={style['meta-tags']}>
    <h3>Meta Tags</h3>
    <ZInput
      label={'Title Tag'}
      onChange={onMetaTitleChange}
      text={metatags.title}
      style={{marginBottom: '28px'}}
      inputKey={'admin__titleTag'}
      error={!title ? {message: 'Add a title and Save to Generate A title'} : false }
    />
    <ZInput
      label='Description Tag'
      onChange={onMetaDescriptionChange}
      text={metatags.description}
      style={{marginBottom: '28px'}}
      inputKey={'admin__descriptionTag'}
      error={!metatags.description ? {message: 'Add A Country Bellow and Save to Generate a Description'} : false }
    />
    <ZInput
      label='Canonical Url*'
      readOnly={true}
      text={metatags.canonical ? metatags.canonical : 'Add a Path Above and Save'}
      inputKey={'admin__canonicalUrl'}
    />
    <p className={style['meta-tags__note']}>
      *Edit or update the canonical url in the Url & Paths section above.
    </p>
  </div>
);

export default MetatagsView;
