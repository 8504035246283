import {
  NEWS_SNIPPETS_ERROR,
  NEWS_SNIPPETS_RECEIVED,
  NEWS_SNIPPETS_REQUEST,
  NEWS_SNIPPET_SAVE_ERROR,
  NEWS_SNIPPET_SAVE_REQUEST,
  SET_NEWS_SNIPPET,
  SET_DISPLAYED,
  SET_PUBLICATION_DATE,
  SET_SOURCE,
  SET_TEASER,
  SET_TITLE,
  SET_URI,
  CREATE_NEW,
} from '../actions/news-editor/news-snippets';

const formatDate = (date) => {
  if (!date) return '';
  date.setHours(0, 0, 0, 0);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

const initialSnippetState = {
  isSaving: false,
  error: false,
  displayed: true,
  publicationDate: formatDate(new Date()),
  newsSource: {},
  teaser: '',
  title: '',
  uri: '',
};

const newsSnippet = (state = initialSnippetState, action) => {
  switch(action.type) {
    case NEWS_SNIPPET_SAVE_REQUEST:
      return {
        ...state,
        isSaving: action.isSaving,
      };
    case NEWS_SNIPPET_SAVE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_NEWS_SNIPPET:
      return {
        ...state,
        ...action.newsSnippet,
      };
    case SET_DISPLAYED:
      return {
        ...state,
        displayed: action.displayed,
      };
    case SET_PUBLICATION_DATE:
      return {
        ...state,
        publicationDate: formatDate(action.publicationDate),
      };
    case SET_SOURCE:
      return {
        ...state,
        newsSource: action.newsSource,
      };
    case SET_TEASER:
      return {
        ...state,
        teaser: action.teaser,
      };
    case SET_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case SET_URI:
      return {
        ...state,
        uri: action.uri,
      };
    case CREATE_NEW:
      return {
        ...initialSnippetState,
      };
    default:
      return state;
  }
};

const newsSnippets = (
  state = {
    isFetching: false,
    error: false,
    isSaving: false,
    allNewsSnippets: [],
  },
  action
) => {
  switch(action.type) {
    case NEWS_SNIPPETS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case NEWS_SNIPPETS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case NEWS_SNIPPETS_RECEIVED:
      return {
        ...state,
        allNewsSnippets: action.allNewsSnippets,
      };
    case SET_NEWS_SNIPPET:
      return {
        ...state,
        [action.newsSnippet.id]: newsSnippet(state[action.newsSnippet.id], action),
      };
    case NEWS_SNIPPET_SAVE_ERROR:
    case NEWS_SNIPPET_SAVE_REQUEST:
    case SET_DISPLAYED:
    case SET_PUBLICATION_DATE:
    case SET_SOURCE:
    case SET_TEASER:
    case SET_TITLE:
    case SET_URI:
      return {
        ...state,
        [action.id]: newsSnippet(state[action.id], action),
      };
    case CREATE_NEW:
      return {
        ...state,
        new: newsSnippet(null, action),
      };
    default:
      return state;
  }
};

export default newsSnippets;
