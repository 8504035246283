import { push } from 'connected-react-router';
import { CompanyCategory } from '@zicasso/zicasso-sdk/lib/company';
import { fetchTuid } from '../../../utils/fetch/tuid';
import CompanyCategoryClient from "../../../utils/client/company-category";
import ZApiClient from '../../../utils/client/z-api-client';
import { getLandingPageSnippet } from '../../../utils/fetch/landing-page';
import {receivedPhotosT} from '../shared/photo';
import {
  fetchEntityVersion,
  addSavedEntityVersion,
  versionSuccess,
} from '../versions';
import {
  fetchEntityVersion as UTILS_fetchEntityVersion,
} from '../../../utils/versions/versions-utils';

import {
  REQUEST_COMPANY_CATEGORY,
  REQUEST_NEW_COMPANY_CATEGORY,
  RECEIVED_COMPANY_CATEGORY,
  COMPANY_CATEGORY_SAVE_REQUESTED,
  COMPANY_CATEGORY_SAVED,
  REQUEST_COMPANY_CATEGORY_FAILED,
} from '../shared/similar-action-types';
import {
  SET_KEYWORDS_INCLUDE,
  SET_KEYWORDS_EXCLUDE,
  SET_LANDING_PAGE_SNIPPET,
} from './action-types';
import {
  receivedSectionsT
} from '../shared/section';

const companyCategoryClient = new CompanyCategoryClient();

export const requestCompanyCategory = () => ({
  type: REQUEST_COMPANY_CATEGORY,
});

export const requestNewCompanyCategory = () => ({
  type: REQUEST_NEW_COMPANY_CATEGORY,
});

export const receivedCompanyCategory = (entity) => ({
  type: RECEIVED_COMPANY_CATEGORY,
  receivedAt: Date.now(),
  id: entity.id,
  entity,
});

export const saveRequested = () => ({
  type: COMPANY_CATEGORY_SAVE_REQUESTED,
});

export const companyCategorySaved = (data) => ({
  type: COMPANY_CATEGORY_SAVED,
  data
});

export const companyCategoryRequestFailed = () => ({
  type: REQUEST_COMPANY_CATEGORY_FAILED,
});

export const setKeywordsInclude = (keywordsInclude, id) => ({
  type: SET_KEYWORDS_INCLUDE,
  keywordsInclude,
  id,
});

export const setKeywordsExclude = (keywordsExclude, id) => ({
  type: SET_KEYWORDS_EXCLUDE,
  keywordsExclude,
  id,
});

const setLandingPageSnippet = (landingPageSnippet, id) => ({
  type: SET_LANDING_PAGE_SNIPPET,
  landingPageSnippet,
  id,
});

const createOrUpdateCompanyCategory = (data, create = true) =>{
  if(create){
    return ZApiClient.getCompanyCategoriesClient().createCompanyCategory(new CompanyCategory(data));
  } else {
    return ZApiClient.getCompanyCategoriesClient().updateCompanyCategory(new CompanyCategory(data));
  }
};

function shouldFetchCompanyCategory(state, id, updateId) {
  const { editor } = state.companyCategories;
  if (!editor[id]) {
    return true
  } else if (editor[id] && editor[id].updateId !== updateId) {
    return true
  } else {
    return false;
  }
}

/**
 * Pass the result of article client getState method;
 * @param {object} landingPageState
 */
 const receiveCompanyCategoryState = (companyCategoryState) => {
  return dispatch => {
    let { id } = companyCategoryState;
    dispatch(receivedPhotosT('company_category', companyCategoryState.photos));
    dispatch(receivedSectionsT('company_category', companyCategoryState.sections));
    dispatch(receivedCompanyCategory(companyCategoryState[id]));
  }
};

export const fetchParentLandingPageSnippet = (landingPageId, companyId) => (dispatch) => (
  getLandingPageSnippet(landingPageId)
    .then((res) => {
      if (res.httpStatus) throw new Error('Error fetching landing page snippet');
      dispatch(setLandingPageSnippet(res, companyId));
      if (res.heroImage) {
        dispatch(receivedPhotosT('company_category', { [res.heroImage.id]: res.heroImage }));
      }
    })
);

export const createNewCompanyCategory = () => {
  return dispatch => {
    dispatch(requestNewCompanyCategory());
    return fetchTuid()
      .then(val => {
        let { id } = val;
        dispatch(receivedCompanyCategory({ id }));
        dispatch(push(`/company-categories/${id}`));
      })
      .catch(console.error);
  }
};

export const fetchCompanyCategory = (id, updateId) => {
  return dispatch => {
    dispatch(requestCompanyCategory());

    const req = !updateId
      ? ZApiClient.getCompanyCategoriesClient().getCompanyCategoryById(id)
      : ZApiClient.getCompanyCategoriesClient().getCompanyCategoryVersion(id, updateId);

    return req.then((res) => {
      if(res.httpStatus && res.httpStatus === 404) {
        throw new Error('NOT_FOUND');
      }
      if (res.breadcrumb?.entityId) {
        dispatch(fetchParentLandingPageSnippet(res.breadcrumb.entityId, id));
      }
      return companyCategoryClient.getState(res);
    }).then((newState) => {
      dispatch(receiveCompanyCategoryState(newState));
    }).catch((e)=>{
      if(e.message === 'NOT_FOUND'){
        dispatch(companyCategoryRequestFailed());
      }
      console.error('Error Fetching Company Category', e);
    })
  };
};

/**
 *
 * @param {string} id
 */
 export const fetchCompanyCategoryIfNeeded = (id, updateId) => {
  return (dispatch, getState) => {
    const shouldFetch = shouldFetchCompanyCategory(getState(), id, updateId);

    if (shouldFetch) {
      dispatch(fetchCompanyCategory(id, updateId));
    }
  }
};

export const saveCompanyCategory = (companyCategoryId) => {
  return (dispatch, getState) => {
    dispatch(saveRequested());
      ZApiClient
      .getCompanyCategoriesClient()
      .exists(companyCategoryId)
      .then((res) => {
        const shouldCreate = !res;
        const { companyCategories } = getState();
        const entity = companyCategoryClient.getCompanyCategory(companyCategoryId, companyCategories);

        return createOrUpdateCompanyCategory(entity, shouldCreate);
      })
      .then((result) => {
        dispatch(companyCategorySaved());
        const { id, updateId, userId, updatedAt } = result;
        const newVersion = { id, updateId, userId, updatedAt };

        dispatch(addSavedEntityVersion(id, newVersion));
        return companyCategoryClient.getState(result);
      })
      .then((newState)=>{
        dispatch(receiveCompanyCategoryState(newState));
      })
      .catch((e)=>{
        console.error('Error Saving Company Category', e);
        console.error(e.stack);
      });
  };
};

export const fetchCompanyCategoryVersion = (id, versionId) => (dispatch) => {
  dispatch(fetchEntityVersion('companyCategories', id, versionId))
    .then(() => {
      dispatch(versionSuccess(id, versionId));
    })
    .catch(console.error);
};

export const validateEntityVersion = (entityId, versionId, paths) => {
  return UTILS_fetchEntityVersion('companyCategories', entityId, versionId).then((res) => {
    const entityToValidate = {
      ...res,
      ...paths && { paths }
    };
    return companyCategoryClient.validateForPublish(entityToValidate);
  })
};

export const makeLatest = (id, updateId) => (dispatch) => {
  dispatch(requestCompanyCategory());

  return ZApiClient
    .getCompanyCategoriesClient()
    .getCompanyCategoryVersion(id, updateId)
      .then((res) => {
        if(res.httpStatus && res.httpStatus === 404){
          throw new Error('NOT_FOUND');
        }
        return createOrUpdateCompanyCategory(res, false);
      })
      .then((res) => {
        const { id, updateId, userId, updatedAt } = res;
        const newVersion = { id, updateId, userId, updatedAt };
        dispatch(addSavedEntityVersion(id, newVersion));
        return companyCategoryClient.getState(res);
      })
      .then((newState) => {
        dispatch(receiveCompanyCategoryState(newState));
      })
      .catch((e) => {
        if(e.message === 'NOT_FOUND'){
          dispatch(companyCategoryRequestFailed());
        }
        console.error('Error Fetching Company Category', e);
      });
};
