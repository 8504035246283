import {
    EXPIRATION_DATE_IS_VALID,
    SET_EXPIRATION_DATE,
} from './expiration-action-types';

export const setExpirationDateIsValidT = (T, boolean) => ({
    type: EXPIRATION_DATE_IS_VALID(T),
    value: boolean,
});

export const setExpirationDateT = (T, date, id) => ({
    type: SET_EXPIRATION_DATE(T),
    value: date,
    id,
});
