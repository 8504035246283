import { validate } from '../validate/validation';
import ZApiClient from './z-api-client';

const itineraryValidationRequirements = {
  title: {
    required: true,
    errorMessage: 'Title is required and must be unique.'
  },
  placesVisited: {
    required: true,
    min: 1,
    errorMessage: 'Please add at least 1 location to places visited. '
  },
  countries: {
    required: true,
    min: 1,
    errorMessage: 'A minimum of 1 destination country is required.'
  },
  // departureDates
  departureNotes: {
    required: true,
    errorMessage: 'Departure dates field is required.'
  },
  // marketingSummary
  // summary: {
  //   required: true,
  //   errorMessage: 'Marketing summary is required.'
  // },
  days: {
    required: true,
    min: 5,
    errorMessage: 'A minimum of 5 days are required.',
    // Unsure how to handle day errors.
    // I might validate those in the component.
    // day: {
    //     duration: {
    //         required: true,
    //         default: 1,
    //         errorMessage: 'Itinerary days must have a duration of 1 or more.'
    //     },
    //     location: false,
    //     title: {
    //         required: true,
    //         errorMessage: 'Each day must have a title.'
    //     },
    //     description: {
    //         required: true,
    //         errorMessage: 'Each day must have a description.'
    //     },
    //     included: false,
    //     meals: false
    // }
  },
  // tripHighlights
  highlights: {
    required: true,
    errorMessage: 'Trip highlights are required.'
  },
  priceComments: {
    required: true,
    errorMessage: 'Price comments are required.'
  },
  // whatsIncluded
  includedNotes: {
    required: true,
    errorMessage: 'Price notes on what\'s included are required'
  },
  // hero
  heroImage: {
    required: true,
    requiredFields: ['uri'],
    errorMessage: 'A hero image is required.'
  },
  // gallery
  images: {
    required: true,
    min: 5,
    errorMessage: 'A minimum of 5 gallery images are required.'
  },
  // breadcrumb: {
  //     required: true,
  //     requiredFields: ['entityId', 'entityType'],
  //     errorMessage: 'A landing page parent breadcrumb is required.'
  // },
  paths: {
    required: true,
    min: 1,
    errorMessage: 'At least 1 path is required.'
  },
  metatags: {
    required: true,
    // titleTag
    title: {
      required: true,
      errorMessage: 'The meta tag title tag is required.'
    },
    // descriptionTag
    description: {
      required: true,
      errorMessage: 'The meta tag description tag is required.'
    },
    // canonicalUrl
    canonical: {
      required: true,
      errorMessage: 'A canonical path is required.'
    }
  }
};

class Itinerary {

  _getTuid  = () => (
    ZApiClient.getTuidsClient().createTuid()
  );

  _mapIdToEntity = (id, entites) => {
    if(!id){
      return null;
    } else {
      return entites[id];
    }
  }

  _mapIdsToEntity = (ids, entites) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele)=> {
        return entites[ele]
      })
    }
  }

  _generateDescription = (locations) => {
    locations = locations || [];
    let locationText = locations.length > 0 ? `${locations[0]} ` : '';
    return `Zicasso: ${locationText} travel & tours by top competing travel agents & tour operators.`
  }

    /**
   * @param {object} metatags
   * @param {string} title
   * Checks if meta title exist.
   * If no meta title present use default rules to build meta title
   */
  _mapMetaTags = (metatags, itinerary) => {
    let {
      title:metaTitle,
      canonical,
      description,
    } = metatags;
    const {
      title,
      countries,
    } = itinerary;
    if(!metaTitle){
      metaTitle = `${title} | Zicasso`;
    }
    if(!description){
      description = this._generateDescription(countries);
    }
    return {
      ...canonical && {canonical: canonical},
      description,
      title: metaTitle,
    }
  }

  _getMetaTags = (metatags) => {
    return {
      title: '',
      description: '',
      canonical: '',
      ...metatags,
    }
  };

  _mapPhotos = (photos) => {
    let photoMap = {};
    if(!photos || !photos[0]){
      return photoMap;
    };
    for(const photo of photos){
      if (photo && !!photo.id) {
        photoMap[photo.id] = {...photo};
      }
    }
    return photoMap;
  }

  _mapGallery = (photos) => {
    let photoIds = [];
    if(!photos || !photos[0]){
      return photoIds
    };
    for(const photo of photos){
      if (photo && !!photo.id) {
        photoIds.push(photo.id);
      }
    }
    return photoIds;
  }

  _mapDays = async (days) => {
    const idList = [];
    const idMap = {};
    for(const day of days) {
      let {id} = day;
      if(!id) {
        const {id:resId} = await this._getTuid();
        id = resId;
      }
      idList.push(id);
      idMap[id] = {
        id,
        ...day
      }
    }
    return {
      idList,
      idMap,
    }
  }

  _mapFooterIdtoSection = (ids, sections) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        return {...sections[ele]} ;
      });
    }
  }

  _mapSectionIdToSections = (ids, sections, photos) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        const { photoId, ...rest } = sections[ele];
        const section = {
          ...rest,
          ...photoId && { image: photos[photoId] },
        };
        return section;
      });
    }
  }

  _mapSectionsToEntities = async (sections = [], footerLinks) => {
    const sectionIds = [];
    const sectionMap = {};
    const sectionPhotos = [];
    for(const section of sections){
      let {id, image, photoId} = section;
      if(!id){
        const tuidRes = await this._getTuid();
        id = tuidRes.id;
      }
      if(image){
        photoId = image.id;
        sectionPhotos.push({...image})
      }
      sectionIds.push(id);
      sectionMap[id] = {...section, id, photoId};
    };
    const{
      idMap:footerIdMap,
      idList:footerIds,
    } = await this._mapFooterSections(footerLinks);
    return {
      sectionIds,
      footerIds,
      sectionMap : {...sectionMap, ...footerIdMap},
      sectionPhotos,
    };
  };

  _mapFooterSections = async (sections) => {
    const idList = [];
    const idMap = {};
    if(sections){
      for(const section of sections){
        let {id, links} = section;
        if(!id){
          const {id:resId} = await this._getTuid();
          id = resId;
        }
        if(links){
          const promiseArr = links.map((link)=> this._getIdIfNeeded(link));
          links = await Promise.all(promiseArr);
        }
        idList.push(id);
        idMap[id] = {
          ...section,
          id,
          links,
        }
      }
    }
    return {
      idList,
      idMap,
    }
  }

  _getIdIfNeeded = async (section) => {
    let {id} = section;
    if(!id) {
      const {id:resId} = await this._getTuid();
      id = resId;
      return {...section, id};
    } else {
      return {...section};
    }
  }


  /**
   *
   * @param {itinerary} entity
   * Validates an Itinerary for publishing
   */
  validateForPublish (entity){
    return validate(entity, itineraryValidationRequirements);
  }

  /**
   *
   * @param {string} entityId
   * @param {obj} entityState
   * entityState = {
   *  editor : reduxEntityEditor,
   *  photos: photosReduxStore,
   *  sections: sectionsReduxStore,
   *  paths: pathsReduxStore,
   * }
   */
  getItinerary (entityId, entityState){
    const {editor, photos, sections } = entityState;
    const currentEntity = editor[entityId];

    const {
      id,
      title,
      summary,
      departureNotes,
      countries,
      placesVisited,
      highlights,
      description,
      price,
      priceComments,
      includedNotes,
      breadcrumb,
      breadcrumbTitle,
      company,
      headerPhotoId,
      photoIds,
      thumbnailId,
      dayIds,
      metatags,
      showPrice,
      footerSectionIds,
      sectionIds,
    } = currentEntity;
    const ret = {
      id,
      title,
      summary,
      departureNotes,
      countries,
      placesVisited,
      highlights,
      description,
      price: parseInt(price, 10),
      priceComments,
      includedNotes,
      breadcrumb,
      breadcrumbTitle,
      company,
      showPrice,
      ...headerPhotoId && { heroImage : this._mapIdToEntity(headerPhotoId, photos) },
      ...thumbnailId && {thumbnailImage : this._mapIdToEntity(thumbnailId, photos)},
      images : this._mapIdsToEntity(photoIds, photos),
      days : this._mapIdsToEntity(dayIds, sections),
      metatags: this._mapMetaTags(metatags, currentEntity),
      sections: this._mapSectionIdToSections(sectionIds, sections, photos),
      footerLinks: this._mapFooterIdtoSection(footerSectionIds, sections),
    }
    return ret;
  }

  async getState(itinerary){
    const {
      metatags,
      images,
      heroImage,
      thumbnailImage,
      days,
      priceComments,
      includedNotes,
      departureNotes,
      sections, 
      footerLinks,
    } = itinerary;
    const {
      sectionIds,
      footerIds,
      sectionMap,
    } = await this._mapSectionsToEntities(sections, footerLinks);
    const imageArray = images || [];
    const { idMap:dayMap, idList:dayIds} = await this._mapDays(days);
    const defualts = {
      ...(!priceComments) && {
        priceComments: 'This trip is <strong><em>customizable</em></strong> for your <strong><em>private travel.</em></strong>',
      },
      ...(!includedNotes) && {
        includedNotes: 'The starting price is based on travel during the low season for a minimum of two travelers staying in shared 3-star accommodations. Please inquire for a <strong>custom trip quote</strong> based on your travel preferences and travel dates.',
      },
      ...(!departureNotes) && {
        departureNotes: 'Dates are customizable for private departures.',
      }
    }
    const retItinerary = {
      ...itinerary,
      ...defualts,
      metatags : this._getMetaTags(metatags),
      photoIds : this._mapGallery(imageArray),
      dayIds,
      ...heroImage && {headerPhotoId : heroImage.id},
      ...thumbnailImage && { thumbnailId : thumbnailImage.id},
      sectionIds,
      footerSectionIds: footerIds,
    };
    return{
      id : itinerary.id,
      [itinerary.id] : retItinerary,
      photos: this._mapPhotos([...imageArray, heroImage, thumbnailImage]),
      days: dayMap,
      sections : sectionMap,
    }
  }

}

export default Itinerary;
