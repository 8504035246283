import React, { useEffect, useState } from 'react';
import useDebounce from '../../../../hooks/use-debounce';
import ZInputSearchView from './oryx-input-search-view';

const ZInputSearchContainer = ({
  name,
  onChange,
  onClickClear,
  placeholder,
  value,
}) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (typeof value === 'string' && value !== searchTerm) setSearchTerm(value);
  }, [value]);

  useEffect(() => {
    onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    setSearchTerm(inputValue);
    if (inputValue === '') onClickClear();
  };

  const handleClickClear = () => {
    setSearchTerm('');
    onClickClear();
  };

  return (
    <ZInputSearchView
      name={name}
      onChange={handleChange}
      onClickClear={handleClickClear}
      placeholder={placeholder}
      searchTerm={searchTerm}
    />
  );
};

export default ZInputSearchContainer;
