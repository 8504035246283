import React, { useState, useEffect } from 'react';
import SectionView from './section-view'
const SectionContainer = ({
  id, 
  title, 
  text, 
  order,
  index,
  onSectionTitleChange,
  onSectionTextChange,
  onRemoveSectionClick,
  onAddSectionClick,
  onChangePhoto,
  onCreatePhoto,
  onRemovePhoto,
  photo,
}) => {
  const [showPhoto, setShowPhoto ] = useState(false);

  const onToggleDropdown = () => {
    setShowPhoto(!showPhoto);
  }

  useEffect(()=>{
    if(!!photo.id){
      setShowPhoto(true);
    }
  }, [photo]);

  const onTitleChange = text => {
    onSectionTitleChange(id, text);
  }

  const onTextChange = text => {
    onSectionTextChange(id, text);
  }

  const onDeleteClick = () => {
    onRemoveSectionClick(id);
  }

  const onAddSectionBelow = () => {
    onAddSectionClick(index + 1);
  }

  return(
    <SectionView
      showPhoto={showPhoto}
      onToggleDropdown={onToggleDropdown}
      onTitleChange={onTitleChange}
      onTextChange={onTextChange}
      onDeleteClick={onDeleteClick}
      onAddSectionBelow={onAddSectionBelow}
      title={title}
      text={text}
      order={order}
      id={id}
      onChangePhoto={onChangePhoto}
      onCreatePhoto={onCreatePhoto}
      onRemovePhoto={onRemovePhoto}
      photo={photo}
    />
  )
}

export default SectionContainer;
