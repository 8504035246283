import React from 'react';
import ZButton from '../../../z-button';
import ZImage from '../../../z-image';
import ZDeleteButton from '../../../z-button-delete';
import { ReactComponent as PlusSign } from '../../../../assets/inline/plus_sign.svg';
import { ReactComponent as RightArrow } from '../../../../assets/inline/right-arrow.svg';
import style from './gallery-links.module.scss';

const GalleryLink = ({
  index,
  link,
  onClickGalleryLink,
  onRemoveLink,
}) => (
  <div
    className={style.gallery__item}
    key={link.entityId}
    id={index}
    onClick={onClickGalleryLink}
    role="button"
  >
    <ZImage
      alt={link.image?.alt}
      aspectRatio={1.76}
      className={style['gallery__item--image']}
      cloudinarySrc={link.image?.uri}
      crop="fill"
      gravity={link.image?.gravity}
      lazy
      width={418}
    />
    <p className={style['gallery__item--text']}>
      <span dangerouslySetInnerHTML={{ __html: link.title}} />
      <RightArrow className={style['gallery__item--icon']} />
    </p>
    <div className={style['gallery__item--overlay']} />
    <ZDeleteButton buttonClass={style['gallery__item--delete']} onClick={onRemoveLink} />
  </div>
);

const ConfirmDelete = ({
  closeModal,
  onClickConfirmDelete,
}) => (
  <div className={style.delete}>
    <p>Are you sure?</p>
    <p>Deleted link sections can be found in previous versions</p>
    <div className={style['delete__row']}>
      <ZButton
        btnClass={style['delete__button']}
        onClick={closeModal}
        text="Cancel"
      />
      <ZButton
        btnClass={style['delete__button']}
        onClick={onClickConfirmDelete}
        text="Delete"
      />
    </div>
  </div>
);

const AddGalleryLink = ({
  onClickGalleryLink,
  lastIndex,
}) => (
  <div
    className={style['gallery__add-link']}
    key="add-link"
    id={lastIndex}
    onClick={onClickGalleryLink}
    role="button"
  >
    Add Link
    <PlusSign height={20} width={20} />
  </div>
);

export {
  AddGalleryLink,
  ConfirmDelete,
  GalleryLink,
};
