import {
  CAREERS_REQUEST,
  CAREERS_ERROR,
  CAREERS_RECEIVED,
  CAREER_SAVE_ERROR,
  CAREER_SAVE_REQUEST,
  SET_CAREER,
  SET_CAREER_TITLE,
  SET_CAREER_URI,
  SET_CAREER_LOCATION,
  SET_DISPLAYED,
  CREATE_NEW_CAREER
} from '../actions/careers-editor/action-types';

const initialCareerState = {
  isSaving: false,
  error: false,
  displayed: false,
  title: '',
  location: '',
  url: '',
};

const career = (state = initialCareerState, action) => {
  switch (action.type) {
    case CAREER_SAVE_REQUEST:
      return {
        ...state,
        isSaving: action.isSaving,
      };
    case CAREER_SAVE_ERROR: 
      return {
        ...state,
        error: action.error,
      };
    case SET_CAREER:
      return {
        ...state,
        ...action.career,
      };
    case SET_DISPLAYED:
      return {
        ...state,
        displayed: action.displayed,
      };
    case SET_CAREER_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case SET_CAREER_LOCATION:
      return {
        ...state,
        location: action.location,
      };
    case SET_CAREER_URI:
      return {
        ...state,
        url: action.url,
      };
    case CREATE_NEW_CAREER:
      return {
        ...initialCareerState,
      };
    default:
      return state;
  }
};

const careers = (
  state = {
    isFetching: false,
    error: false,
    isSaving: false,
    allCareers: [],
  },
  action
) => {
  switch (action.type) {
    case CAREERS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case CAREERS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CAREERS_RECEIVED:
      return {
        ...state,
        allCareers: action.allCareers,
      };
    case SET_CAREER:
      return {
        ...state,
        [action.career.id]: career(state[action.career.id], action),
      };
    case CAREER_SAVE_REQUEST:
    case CAREER_SAVE_ERROR:
    case SET_DISPLAYED:
    case SET_CAREER_TITLE:
    case SET_CAREER_LOCATION:
    case SET_CAREER_URI:
      return {
        ...state,
        [action.id]: career(state[action.id], action),
      };
    case CREATE_NEW_CAREER:
      return {
        ...state,
        new: career(null, action),
      };
    default:
      return state;
  }
};

export default careers;
