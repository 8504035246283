import {
  useReducer,
} from 'react';
import {
  SET_ERROR,
  SET_IS_FETCHING,
  SET_ALL_COMPANIES,
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH,
  SET_SEARCH_RESULT_BY_ID,
  SET_SEARCH_RESULTS_BY_ID,
  SET_COMPANY_BY_ID,
  SET_COMPANIES_BY_ID,
  SET_COMPANY_FILTERS,
  SET_SEARCH_TERM,
  SET_SORT_ORDER,
  SET_DESCRIPTION,
  SET_FIRST_NAME,
  SET_PHOTO,
  SET_PROFILE_STATUS,
  SET_FIRST_NAME_EXISTS,
  SET_PHOTO_EXISTS,
  SET_DESCRIPTION_EXISTS,
  SET_PRIMARY_LOCATIONS,
  SET_SECONDARY_LOCATIONS,
  SET_ACTIVITY_LEVELS,
} from './actions';

export const initialCompanytState = {
  entityType: 'Company',
  id: '',
  updateId: '',
  nodeId: 0,
  brand: '',
  uri: '',
  name: '',
  websites: [],
  excluded: false,
  partnerSince: '',
  activityLevel: '',
  country: '',
  countryCode: '',
  primaryLocations: [],
  secondaryLocations: [],
  published: false,
  firstName: '',
  description: '',
  photo: {},
};

const initialState = {
  error: false,
  searchTerm: '',
  isFetching: false,
  allCompanies: {
    companies: [],
    offset: '',
  },
  searchResults: null,
  searchResultsById: {},
  company: {},
  companiesById: {},
  filters: {
    primaryLocations: [],
    secondaryLocations: [],
    activityLevels: ['PRIORITY_MATCH', 'SECOND_MATCH', 'THIRD_OPTION', 'LAST_RESORT', 'DO_NOT_MATCH'],
  },
  sortOrder: 'newToOld',
};

const companyReducer = (
  state = initialCompanytState,
  action,
) => {
  switch (action.type) {
    case SET_COMPANY_BY_ID:
      return {
        ...state,
        ...action.company,
      };
    case SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.data,
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        description: action.data,
      }
    case SET_PHOTO:
      return {
        ...state,
        photo: action.data,
      }
    default:
      return state;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SET_ALL_COMPANIES: {
      return {
        ...state,
        allCompanies: {
          companies: action.companies,
          offset: action.offset,
        },
      };
    }
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.searchResults,
      };
    case SET_SEARCH_RESULT_BY_ID:
      return {
        ...state,
        searchResultsById: {
          [action.companyId]: action.company,
        },
      };
    case SET_SEARCH_RESULTS_BY_ID:
      return {
        ...state,
        searchResultsById: action.searchResultsById,
      };
    case CLEAR_SEARCH:
      if (state.searchResults) {
        return {
          ...state,
          searchResults: null,
          searchResultsById: {},
        };
      }
      return state;
    case SET_COMPANIES_BY_ID:
      return {
        ...state,
        companiesById: action.companies,
      };
    case SET_COMPANY_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.data,
      };
    case SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.data,
      };
    case SET_PROFILE_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          published: action.data,
        },
      };
    case SET_FIRST_NAME_EXISTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          firstNameExists: action.data,
        },
      };
    case SET_PHOTO_EXISTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          photoExists: action.data,
        },
      };
    case SET_DESCRIPTION_EXISTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          descriptionExists: action.data,
        },
      };
    case SET_PRIMARY_LOCATIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          primaryLocations: [...action.data],
        },
      };
    case SET_SECONDARY_LOCATIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          secondaryLocations: [...action.data],
        },
      };
    case SET_ACTIVITY_LEVELS:
      return {
        ...state,
        filters: {
          ...state.filters,
          activityLevels: [...action.data],
        },
      };
    case SET_COMPANY_BY_ID:
    case SET_DESCRIPTION:
    case SET_FIRST_NAME:
    case SET_PHOTO:
      return {
        ...state,
        companiesById: {
          ...state.companiesById,
          [action.companyId]: companyReducer(state.companiesById[action.companyId], action),
        },
      };
    default:
      return state;
  }
};

const useCompaniesReducer = () => {
  const [companies, dispatchCompanies] = useReducer(reducer, initialState);

  return [companies, dispatchCompanies];
};

export default useCompaniesReducer;
