import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ZImageView from './z-image-view';
import ZLazyImage from './z-lazy-image';
import { getIOSupport } from '../../utils/utils';

const ZImageContainer = ({
  alt,
  angle,
  aspectRatio,
  className,
  cloudinarySrc,
  crop,
  effect,
  gatsbyImage,
  gravity,
  height,
  id,
  inputKey,
  lazy,
  onClick,
  onError,
  onLoad,
  onStartLoad,
  quality,
  src,
  style,
  title,
  width,
}) => {
  const [useLazy, setUseLazy] = useState(lazy);
  const clTransformData = {
    angle,
    aspectRatio,
    effect,
    crop,
    gravity,
    quality,
  };
  const isCloudinary = !!cloudinarySrc;
  const isGatsby = !!gatsbyImage;

  useEffect(() => {
    if (lazy) {
      // eslint-disable-next-line no-undef
      const localWindow = (typeof window !== 'undefined' && !!window);
      const supportsIO = localWindow && getIOSupport();
      setUseLazy(supportsIO && lazy);
    }
  }, [lazy]);

  return (
    <>
      { !useLazy && (
        <ZImageView
          alt={alt}
          className={className}
          cloudinaryTransformProps={clTransformData}
          cloudinarySrc={cloudinarySrc}
          gatsbyImage={gatsbyImage}
          height={height}
          id={id}
          inputKey={inputKey || `z-image-${cloudinarySrc || src}`}
          onClick={onClick}
          onError={onError}
          onLoad={onLoad}
          onStartLoad={onStartLoad}
          src={src}
          style={style}
          title={title}
          useCloudinary={isCloudinary}
          useGatsby={isGatsby}
          width={width}
        />
      )}
      { useLazy && (
        <ZLazyImage
          alt={alt}
          className={className}
          cloudinaryTransformProps={clTransformData}
          cloudinarySrc={cloudinarySrc}
          gatsbyImage={gatsbyImage}
          height={height}
          id={id}
          inputKey={inputKey || `z-image-${cloudinarySrc || src}`}
          onClick={onClick}
          onError={onError}
          onLoad={onLoad}
          onStartLoad={onStartLoad}
          src={src}
          style={style}
          title={title}
          useCloudinary={isCloudinary}
          useGatsby={isGatsby}
          width={width}
        />
      )}
    </>
  );
};
ZImageContainer.propTypes = {
  alt: PropTypes.string,
  angle: PropTypes.string,
  aspectRatio: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  cloudinarySrc: PropTypes.string,
  crop: PropTypes.string,
  effect: PropTypes.string,
  gatsbyImage: PropTypes.object,
  gravity: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string,
  inputKey: PropTypes.string,
  lazy: PropTypes.bool,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  onStartLoad: PropTypes.func,
  quality: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
ZImageContainer.defaultProps = {
  alt: null,
  angle: null,
  aspectRatio: null,
  className: null,
  cloudinarySrc: null,
  crop: null,
  effect: null,
  gatsbyImage: null,
  gravity: null,
  height: null,
  id: null,
  inputKey: null,
  lazy: null,
  onClick: null,
  onError: null,
  onLoad: null,
  onStartLoad: null,
  quality: null,
  src: null,
  style: null,
  title: null,
  width: null,
};

export default ZImageContainer;
