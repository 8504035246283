import React from 'react';
import sharedStyle from '../shared/styles/shared.module.scss';
import { ReactComponent as DeleteSVG } from '../../assets/logos/delete.svg';

const ZDeleteButton = ({
  buttonClass, children, onClick, style, value,
}) => (
  <button
    className={`${sharedStyle['svg__btn']} ${buttonClass}`}
    onClick={onClick}
    type="button"
    style={style}
    value={value}
  >
    <DeleteSVG />
    {children}
  </button>
);

export default ZDeleteButton;
