import React from 'react';
import {
  Label,
  Wrapper,
} from '../utils';
import OryxTextArea from '../../oryx-shared/oryx-textarea';
import OryxInputText from '../../oryx-shared/oryx-input/text';
import OryxButton from '../../oryx-shared/oryx-button/type-button';
import ZModal from '../../../z-modal';
import styles from './editable.module.scss';
import { decodeHTMLEntities, replaceNewLine } from '../../../../utils/utils';
import { ReactComponent as ZicassoSVG } from '../../assets/svg/zicasso.svg';

const today = new Date();
today.setHours(0, 0, 0, 0);
today.setDate(today.getDate() + 14);

const defaultProps = {
  firstName: '',
  description: '',
  photo: undefined,
};

const EditableView = ({
  firstName,
  description,
  photo,
  handleChange,
  showModal,
  openModal,
  modalState,
  onModalChange,
  onSubmitPhoto,
  onCancel,
  counts,
}) => (
  <>
    <div className={styles.required}>
      <div className={styles.required__column}>
        <Wrapper>
          <Label inputName="firstName">
            First Name
          </Label>
          <OryxInputText
            name='firstName'
            onChange={handleChange}
            value={firstName}
          />
        </Wrapper>
        <Wrapper>
          <Label inputName="photo">
            Agency Photo
          </Label>
          {!photo &&
            <div className={styles.placeholder}>
              <ZicassoSVG/>
            </div>
          }
          {photo &&
            <img
              style={{
                maxHeight: '200px',
                maxWidth: '200px'
              }}
              src={photo.uri}
              alt=''
            />
          }
          <OryxButton
            style={{
              width: 'fit-content',
              padding: '10px 20px',
              height: '35px',
              margin: '13px 0 20px',
            }}
            onClick={openModal}
          >
            Add Photo
          </OryxButton>
        </Wrapper>
      </div>
      <div className={styles.required__column}>
        <Wrapper>
          <Label inputName="companyDescription">
            Agency Description
          </Label>
          <OryxTextArea
            name="companyDescription"
            onChange={handleChange}
            rows={15}
            valid={true}
            value={replaceNewLine(decodeHTMLEntities(description))}
          />
        </Wrapper>
        <p>{`${counts[0]} chars / ${counts[1]} words`}</p>
      </div>
    </div>
    <ZModal
      maxHeight='80%'
      open={showModal}
      toggleModal={openModal}
      useOverlay
    >
      <div>
        <div className={styles['modal-image-wrapper']}>
          { !modalState.sourceUri &&
            <div className={styles.placeholder}>
              <ZicassoSVG/>
            </div>
          }
          { modalState.sourceUri &&
            <img
              src={modalState.sourceUri}
              alt=''
              style={{
                maxHeight: '200px',
                maxWidth: '200px'
              }}
            />
          }
        </div>
        <div className={styles.modalInputs}>
          <Wrapper>
            <Label inputName="source">
              Paste Url
            </Label>
            <OryxInputText
              name='source'
              onChange={onModalChange}
              value={modalState.sourceUri}
            />
          </Wrapper>
          <Wrapper>
            <Label inputName="alt">
              Alt Text
            </Label>
            <OryxInputText
              name='alt'
              onChange={onModalChange}
              value={modalState.alt}
            />
          </Wrapper>
          <Wrapper>
            <Label inputName="title">
              Title Text
            </Label>
            <OryxInputText
              name='title'
              onChange={onModalChange}
              value={modalState.title}
            />
          </Wrapper>
        </div>
        <div className={styles['modal-buttons']}>
          <OryxButton
            style={{
              width: 'fit-content',
              padding: '10px 20px',
              height: '35px',
              margin: '13px 0 20px',
            }}
            onClick={onSubmitPhoto}
          >
            Submit
          </OryxButton>
          <OryxButton
            style={{
              width: 'fit-content',
              padding: '10px 20px',
              height: '35px',
              margin: '13px 0 20px',
            }}
            onClick={onCancel}
          >
            Cancel
          </OryxButton>
        </div>
      </div>
    </ZModal>
  </>
);

EditableView.defaultProps = defaultProps;

export default EditableView;
