import React from 'react';
import style from './footer-search.module.scss';
import sharedStyle from '../../../shared/styles/shared.module.scss';
import ZAutoComplete from '../../../z-autocomplete';

const FooterSearchView = ({
  onRadioChange,
  entityType,
  onSearch,
  onSelect,
  errorText,
}) => (
  <div className={style['footer-search-wrapper']}>
    <div className={style['checkbox-wrapper']}>
      <div className={style['checkbox-item']}>
        <input 
          type='radio' 
          id='article' 
          className={style['checkbox']} 
          value='ARTICLE'
          name='entity'
          onChange={onRadioChange}
        />
        <label 
          htmlFor='article'
        >
          Magazine Articles
        </label>
      </div>
      <div className={style['checkbox-item']}>
        <input 
          type='radio' 
          id='travelGuide' 
          className={style['checkbox']} 
          value='TRAVEL_GUIDE'
          name='entity'
          onChange={onRadioChange}
        />
        <label 
          htmlFor='travelGuide'
        >
          Travel Guide
        </label>
      </div>
      <div className={style['checkbox-item']}>
        <input 
          type='radio' 
          id='landingPage' 
          className={style['checkbox']} 
          value='LTL'
          name='entity'
          onChange={onRadioChange}
        />
        <label 
          htmlFor='landingPage'
        >
          LTL
        </label>
       
      </div>
    </div>
    <ZAutoComplete
      autoComplete={ onSearch }
      onSelect={ onSelect }
      label={entityType ? 'Search By Title' : 'Please Select a Page Type'}
      style={{marginBottom: '14px'}}
      inputKey={'entity_search'}
    />
    {errorText &&
      <p className={sharedStyle['error']}>{errorText}</p>
    }
  </div>
);

export default FooterSearchView;
