import { connect } from 'react-redux';
import CareerEditorContainer from './career-editor-container';
import {
  saveCareer,
  setDisplayed,
  setCareerTitle,
  setCareerLocation,
  setCareerUri,
  fetchCareers,
  deleteCareer,
} from '../../../state/actions/careers-editor';

const mapStateToProps = (state, ownProps) => ({
  career: state.careers[ownProps.id] || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  saveCareer: () => dispatch(saveCareer(ownProps.id, ownProps.closeModal)),
  setDisplayed: (val) => dispatch(setDisplayed(ownProps.id, val)),
  setLocation: (val) => dispatch(setCareerLocation(ownProps.id, val)),
  setTitle: (val) => dispatch(setCareerTitle(ownProps.id, val)),
  setUrl: (val) => dispatch(setCareerUri(ownProps.id, val)),
  deleteCareer: () => deleteCareer(ownProps.id),
  fetchCareers: () => dispatch(fetchCareers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CareerEditorContainer);
