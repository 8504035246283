import React from 'react';
import style from './z-toast.module.scss';

const ZToastView = ({message}) => (
  <div className={`${style['toast-wrapper']} ${style['success']}`}>
    <p style={{margin: 0}}>{message}</p>
  </div>
);

export default ZToastView;
