import {createImage} from '../../../utils/image/image-utils';
import {
  updatePhotoDataT, setGalleryPhotoT, addHeaderThumbT,
} from '../shared/photo';

export const addGalleryImage = (data, entityId, index) => {
  return dispatch => {
    createImage(data).then((res)=> {
      dispatch(updatePhotoDataT('itinerary', res));
      dispatch(setGalleryPhotoT('itinerary', entityId, res, index));
    })
  }
};

export const addThumbnail = (data, entityId) => {
  return dispatch => {
    createImage(data).then((res)=> {
      dispatch(addHeaderThumbT('itinerary', entityId, res));
    })
  }
};
