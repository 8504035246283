// Refactor entity editors before removing
import articles from './articles';
import companyCategories from './company-category-editor';
import landingPageCategories from './landing-page-category-editor';
import guides from './guides';
import pages from './simple-pages';
import itineraries from './itineraries';
import landingPages from './landing-pages';

import breadcrumbs from './breadcrumbs';
import users from './users';
import versions from './versions';
import modals from './modals';
import paths from './paths';
import publications from './publications';
import published from './published';
import publicationReferences from './publication-references';
import usageReferences from './usage-references';
import entities from './entities';
import errors from './errors';
import news from './news';
import teamMembers from './team-members';
import careers from './careers';

export default {
  articles,
  breadcrumbs,
  companyCategories,
  landingPageCategories,
  guides,
  landingPages,
  modals,
  published,
  publicationReferences,
  usageReferences,
  users,
  versions,
  pages,
  paths,
  publications,
  entities,
  itineraries,
  errors,
  news,
  teamMembers,
  careers,
};
