import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chip, Divider } from '@mui/material';
import Config from '../../../../config';
import { onCopy } from '../../../../utils/window';
import { getFormattedDate, decodeHTMLEntities } from '../../../../utils/utils';

const baseUrlWeb = Config.Z3_URL;

/**
 * accepts PascalCase and camelCase entity names
 * @param {string} entityType
 */
export const getEditLink = (entityType) => {
  const test = entityType.toLowerCase();
  switch (test) {
    case 'article':
      return '/articles';
    case 'guide':
      return '/guides';
    case 'itinerary':
      return '/itineraries';
    case 'landingpage':
      return '/landing-pages'
    case 'landingpagecategory':
      return '/landing-page-categories'
    case 'companycategory':
      return '/company-categories';
    case 'content':
      return '/simple-pages';
    default:
      return '/';
  }
}

const getPreviewLink = (entityType) => {
  const test = entityType.toLowerCase();
  let type = '';
  switch (test) {
    case 'article':
      type = 'Article';
      break
    case 'guide':
      type = 'Guide';
      break
    case 'itinerary':
      type = 'Itinerary';
      break
    case 'landingpage':
      type = 'LandingPage';
      break
    case 'landingpagecategory':
      type = 'LandingPageCategory';
      break
    case 'companycategory':
      type = 'CompanyCategory';
      break;
    case 'content':
      type = 'Content';
      break;
    default:
      break;
  }
  return `${baseUrlWeb}/dynamic?type=${type}`
}

const getViewUri = (canonicalPath) => (
  `${baseUrlWeb}${canonicalPath}`
);

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const PublicationStatus = styled((props) => {
  const { publicationStatus, ...other } = props;
  return(
    <Typography variant="subtitle1" component="span" {...other}>
      {publicationStatus || 'Unpublished'}
    </Typography>
  );
})(({theme, publicationStatus}) => {
  switch (publicationStatus) {
    case 'PUBLISHED':
      return {
        color: 'green',
        borderBottom: '1px solid green',
      }
    case 'DRAFT':
      return {
        color: theme.palette.secondary.main,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      }
    default:
      return {
        color: 'red',
        borderBottom: '1px solid red',
      }
  }
});

const CountsTable = ({
  wordCount,
  imageCount,
  tagCount,
}) => (
  <Box sx={{flex: '0 1 auto', width: '200px'}}>
    <TableContainer component={Paper}>
      <Table  size="small" aria-label="count table">
        <TableHead>
          <TableRow>
            <TableCell>Counts</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key='word'>
            <TableCell component="th" scope="row">
              Words:
            </TableCell>
            <TableCell align="right">{wordCount ? wordCount : 0}</TableCell>
          </TableRow>
          <TableRow key='images'>
            <TableCell component="th" scope="row">
              Images:
            </TableCell>
            <TableCell align="right">{imageCount ? imageCount : 0}</TableCell>
          </TableRow>
          <TableRow key='tags'>
            <TableCell component="th" scope="row">
              Tags:
            </TableCell>
            <TableCell align="right">{tagCount ? tagCount : 0}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

const DefaultShowMore = ({
  tags,
  summary,
}) => (
  <>
    { (tags && !!tags.length) && (
      <>
        <Stack direction="row" spacing={1} marginBottom={1}>
          <Typography component="span" variant="subtitle2">Tags:</Typography>
          <TagList tags={tags}/>
        </Stack>
        <Divider/>
      </>
    )}
    { summary && (
      <>
        <Typography paragraph>Summary:</Typography>
        <Typography paragraph fontSize={16} dangerouslySetInnerHTML={{ __html: summary}}/>
      </>
    )}
  </>
)

const TagList = ({tags}) => (
  tags.map((tag) => <Chip color="secondary" key={tag} label={tag}/> )
);

const ResultCard = ({
  title,
  image,
  id,
  publishedVersionId,
  publicationStatus,
  publishedVersionAt,
  wordCount,
  imageCount,
  tagCount,
  tags,
  summary,
  canonicalPath,
  updatedAt,
  overrideDefaultContent,
  customContent,
  entityType,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  return (
    <Card
      sx={{ marginBottom: '24px' }}
    >
      <Box sx={{ display: 'flex', flex: '1 0 auto', flexDirection: 'row', justifyContent: 'space-between' }}>
        <CardMedia
          component="img"
          height={150}
          sx={{ width: '175px' }}
          image={image.uri || '/zicasso-icon.png'}
          loading="lazy"
          alt={`${image.alt}`}
          width={175}
        />
        <CardContent sx={{ flex: '1 0 0', display: 'flex', flexDirection: 'column'}}>
          <Typography component="div" color="secondary" variant="h5">
            {decodeHTMLEntities(title)}
          </Typography>
          { canonicalPath && (
            <Typography variant="subtitle1" component="span">
              Canonical Url:&nbsp;
              {canonicalPath}
            </Typography>
          )}
          <Stack direction="row" spacing={2}>
            <PublicationStatus
              publicationStatus={publicationStatus}
            />
            <Typography variant="subtitle1" color="secondary" component="span">
              Last Published:&nbsp;
              {publishedVersionAt ? getFormattedDate(publishedVersionAt) : 'Never'}
            </Typography>
          </Stack>
          <Typography variant="subtitle2" component="span">
            Last Updated:&nbsp;
            {updatedAt ? getFormattedDate(updatedAt) : 'Never'}
          </Typography>
        </CardContent>
        <CountsTable
          wordCount={wordCount}
          imageCount={imageCount}
          tagCount={tagCount}
        />
      </Box>
      <CardActions disableSpacing sx={{borderTop: '1px solid #ededed'}}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            target='_blank'
            type="button"
            href={`${getEditLink(entityType)}/${id}`}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            target='_blank'
            type="button"
            href={
              publishedVersionId ? `${getPreviewLink(entityType)}&id=${id}&updateId=${publishedVersionId}`
              : `${getPreviewLink(entityType)}&id=${id}`
            }
          >
            Preview
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            type="button"
            onClick={() => onCopy(getViewUri(canonicalPath))}
          >
            Get Link
          </Button>
        </Stack>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          { !overrideDefaultContent &&
            <DefaultShowMore
              tags={tags}
              summary={summary}
            />
          }
          { (!overrideDefaultContent && customContent) &&
            <Divider/>
          }
          <>
            {customContent}
          </>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default ResultCard;
