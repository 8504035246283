import React from 'react';
import styles from './z-toggle.module.scss';

const ZToggleView = ({
  checked,
  label,
  onChange,
  toggleClass,
  style
}) => (
  <label 
    className={`${styles['toggle']} ${toggleClass}`}
    style={style}
  >
    {label}
    <input type="checkbox" onChange={onChange} checked={checked} />
    <span className={styles['toggle__slider']} />
  </label>
);

export default ZToggleView;
