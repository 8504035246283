import {
  SET_PATHS,
  DELETE_PATH_ERROR,
  CLEAR_PATH_ERROR,
} from '../actions/paths';

const path = (
  state = [],
  action,
) => {
  switch(action.type) {
    case SET_PATHS:
      return [
        ...action.paths,
      ];
    default:
      return state;
  }
};

const paths = (
  state = {},
  action
) => {
  switch (action.type) {
    case SET_PATHS:
      return {
        ...state,
        [action.entityId] : path(state[action.entityId], action),
      };
    case DELETE_PATH_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_PATH_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default paths;
