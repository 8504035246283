import React from 'react';
import { connect } from 'react-redux';
import ZPublishModalContainer from './z-publish-container';
import { unpublishEntity, publishEntityVersion } from '../../state/actions/published';

const ZPublishModalRedux = ({
  entityId,
  entityType,
  modalState,
  onCloseModal,
  onPublish,
  onUnpublish,
  paths,
  saved,
  showModal,
  usageReferences,
  usageReferencesError,
  versionToPublish,
  publishErrors,
}) => (
  <ZPublishModalContainer
    // errors is for legacy implimentations
    errors={versionToPublish.errors}
    // publishErrors will be the new error object
    publishErrors={publishErrors}
    entityId={entityId}
    entityType={entityType}
    modalState={modalState}
    onCloseModal={onCloseModal}
    onPublish={onPublish}
    onUnpublish={onUnpublish}
    paths={paths}
    saved={saved}
    showModal={showModal}
    usageReferences={usageReferences}
    usageReferencesError={usageReferencesError}
    versionToPublish={versionToPublish}
  />
);

const mapStateToProps = (state, ownProps) => {
  const { entityId } = ownProps;
  return {
    paths: state.paths[entityId],
    versionToPublish: state.versions[entityId].versionToPublish || {},
  }
};

const mapDispatchToProps = (dispatch) => ({
  onPublish: (entityId, entityType, versionId) => dispatch(publishEntityVersion(entityId, entityType, versionId)),
  onUnpublish: (entityId, entityType) => dispatch(unpublishEntity(entityId, entityType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZPublishModalRedux);
