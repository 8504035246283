import React from 'react';
import { connect } from 'react-redux';
import {
  fetchGuideIfNeeded,
  saveGuide,
  redirectToCreate,
 } from '../../state/actions/guide-editor';
import {
  setTitleT
} from "../../state/actions/shared/title";
import { toggleReadOnly } from '../../state/actions/versions';
import GuideEditorContainer from './guide-editor-container';

const GuideEditorRedux = (props) => (
  <GuideEditorContainer {...props}/>
)

const mapStateToProps = (state, ownProps) => {
  // All this to avoid property of undefined does not exist errors.
  const { match } = ownProps;
  const { params } = match;
  const { id, updateId: paramUpdateId  } = params;
  const { editor } = state.guides.guide;
  const currentGuide = editor[id] || {}
  const { isFetching, notFound, isSaving, saved } = editor;
  return {
    currentUpdateId: currentGuide.updateId,
    guide: currentGuide,
    isFetching,
    isSaving,
    notFound,
    paramUpdateId,
    readOnly: state.versions.readOnly,
    saved,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match } = ownProps
  const { params } = match;
  const { id } = params;
  return {
    onCreateNew: () => dispatch(redirectToCreate()),
    onLoad: (updateId) => dispatch(fetchGuideIfNeeded(id, updateId)),
    onSave: () => dispatch(saveGuide(id)),
    onTitleChange : text => dispatch(setTitleT('guide', text, id)),
    onToggleReadOnly: () => dispatch(toggleReadOnly()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuideEditorRedux);
