export const SET_EXPIRATION_DATE = (T) => `SET_EXPIRATION_DATE_${T.toUpperCase()}`;
export const EXPIRATION_DATE_IS_VALID = (T) => `EXPIRATION_DATE_IS_VALID_${T.toUpperCase()}`;

export const EXPIRATION_ACTION_NAMES = {};

(() => {
    [
        'article',
        'company_category',
        'landing_page_category',
        'content',
        'guide',
        'itinerary',
        'landing_page'
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        EXPIRATION_ACTION_NAMES[entityType] = {};
        EXPIRATION_ACTION_NAMES[entityType]['SET_EXPIRATION_DATE'] = SET_EXPIRATION_DATE(entityType);
        EXPIRATION_ACTION_NAMES[entityType]['EXPIRATION_DATE_IS_VALID'] = EXPIRATION_DATE_IS_VALID(entityType);
    });
})();
