import React, { useEffect, useState, useCallback } from 'react';
import {
  createGenericSearchTerm,
  createGenericSearchTermCounts,
} from '../../utils/fetch/search';
import { styled } from '@mui/material/styles';
import useEntityCollection from '../../hooks/use-entity-collection';
import {
  SearchBoilerplate,
  SearchInput,
  SearchResults,
} from '../shared/components/search';
import ZSpinner from '../z-spinner';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { getEditLink } from '../shared/components/result-card/result-card-view';
import { Typography } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ResultTab = ({
  entityCounts,
  entityType,
  expandedEntityType,
  loading,
  onEntitySelected,
  results,
  term,
  title,
}) => {
  const toggleExpanded = () => {
    if (expandedEntityType === entityType) {
      onEntitySelected(null);
    } else {
      onEntitySelected(entityType)
    }
  };
  return (
    <Card>
      <Box sx={{ display: 'flex', flex: '1 0 auto', flexDirection: 'row', justifyContent: 'space-between' }}>
        <CardContent>
          <Typography variant="h3" style={{margin: 0}}>
            {title} - {entityCounts[entityType] | 0}
          </Typography>
        </CardContent>
        <CardActions>
          <ExpandMore
            expand={expandedEntityType === entityType}
            onClick={toggleExpanded}
            aria-expanded={expandedEntityType === entityType}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      </Box>
      <Collapse in={expandedEntityType === entityType} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h3" style={{margin: '16px 0'}}>
            Top Results - <a style={{fontSize: 16}} href={`${getEditLink(entityType)}?term=${term}`}>See All</a>
          </Typography>
          { loading &&
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
          >
            <ZSpinner/>
          </div>
          }
          {results.filter((i)=>i.entityType === entityType).map((item) => (
            <div key={item.title}>
              <a style={{fontSize: 16}} href={`${getEditLink(entityType)}/${item.id}`}
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
              />
            </div>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
};

const HomeView = ({username}) => {
  const [loading, setLoading] = useState(false);
  const [receivedAll, setReceivedAll] = useState(false);
  const [term, setTerm] = useState('');
  const [entities, setEntities] = useState([]);
  const results = useEntityCollection(entities);
  const [entitySelected, setEntitySelected] = useState(false);
  const [entityCounts, setEntityCounts] = useState({});
  const [totalEntityCount, setTotalEntityCount] = useState(0);
  const [expandedEntityType, setExpandedEntityType] = useState();

  useEffect(() => {
    const updateSearchCounts = async () => {
      const { counts } = await createGenericSearchTermCounts(term);
      setEntityCounts(counts);
      setTotalEntityCount(Object.values(counts).reduce((a, b) => a + b, 0));
    }
    const updateSearchResults = async () => {
      if (entitySelected) {
        const { entities } = await createGenericSearchTerm(entitySelected, term)
        setEntities(entities);
      }
    }
    const updateSearchAsync = async () => {
      setLoading(true);
      setExpandedEntityType(entitySelected);
      setEntities([]);
      await updateSearchCounts();
      await updateSearchResults();
      setReceivedAll(true);
      setLoading(false);
    }
    updateSearchAsync();
  }, [entitySelected, term]);

  const onEntitySelected = (et) => {
    setEntitySelected(et);
  };

  const memo_onSearch = useCallback((val) => {
    setTerm(val);
  }, []);

  const resultTabs = [
    {entityType: 'LandingPageCategory', title: 'Hub Page'},
    {entityType: 'CompanyCategory', title: 'Agency Category'},
    {entityType: 'Article'},
    {entityType: 'Guide'},
    {entityType: 'Itinerary'},
    {entityType: 'LandingPage', title: 'Landing Page'},
    {entityType: 'Content', title: 'Simple Content'},
  ]

  return(
    <div>
      <div style={{width: '80%', margin: '16px auto', border: '1px solid lightgray', borderRadius: '4px', padding: '16px'}}>
        <div>
          <Typography variant="h1">
            Home
          </Typography>
          <Typography variant="p">
            Logged in as: {username}
          </Typography>
        </div>
      </div>
      <div style={{width: '80%', margin: '0 auto', border: '1px solid lightgray', borderRadius: '4px', padding: '16px'}}>
        <SearchBoilerplate
          title="Search all entities"
          receivedAll={receivedAll}
        >
          <SearchInput
            receivedAll={receivedAll}
            onChange={memo_onSearch}
          />
          <SearchResults
            resultText={`(${totalEntityCount}) ${!receivedAll ? '(still fetching...)' : '' }`}
          >
            <>
              {resultTabs.map((e) => (
                  <ResultTab
                      entityCounts={entityCounts}
                      entityType={e.entityType}
                      expandedEntityType={expandedEntityType}
                      onEntitySelected={onEntitySelected}
                      key={e.entityType}
                      loading={loading}
                      results={results}
                      term={term}
                      title={e.title || e.entityType}
                  />
              ))
              }
            </>
          </SearchResults>
        </SearchBoilerplate>
      </div>
    </div>
  );
};

export default HomeView;
