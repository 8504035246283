import React from 'react';
import PhotoEdit from './photo-edit';
import ZPhotoPreview from '../../../z-photo-preview';
import style from './photos.module.scss';
import sharedStyle from '../../../shared/styles/shared.module.scss';
import ZModal from '../../../z-modal';
import ZDraggableGrid from '../../../z-draggable-grid.js/index.js';
import ZAddButton from '../../../z-button-add';
import ZImage from '../../../z-image';
import { getImagePreviewTransformation } from '../../../../utils/image/image-utils';

const thumbnailTransformation = getImagePreviewTransformation({ aspectRatio: 1.8, effect: 'sharpen:70' });

/**
 *
 * @TODO Resolve Photo errors
 *  - hero image
 *  - image gallery
 *  - delete errors
 */

const PhotosView = ({
  heroImage,
  heroImageErrors,
  thumbnailPhoto,
  showThumbnail,
  onAddThumbnail,
  gallery,
  galleryErrors,
  onDragEnd,
  readOnly,
  addPhotoAtIndex,
  onToggleCreateModal,
  showCreate,
  showConfirmation,
  onToggleConfirmation,
  deletePhoto,
  onUpdateImage,
  onUpdateHeader,
  onSubmitNewImage,
  awaitThumbnail,
}) => {
  return (
    <>
      <h2>Photos</h2>
      <div className={style['hero-image']}>
        <h3>Hero Image</h3>
        { (heroImageErrors && !heroImageErrors.valid) &&
          <p className={sharedStyle['error']}>{heroImageErrors.message}</p>
        }
        <ZPhotoPreview
          key={heroImage.id}
          alt={heroImage.alt}
          data={heroImage}
          gravity={heroImage.gravity || 'auto'}
          onUpdate={onUpdateHeader}
          showInfo
          src={heroImage.uri}
          style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
          title={heroImage.title}
          transformation={getImagePreviewTransformation({ aspectRatio: 2.3 })}
        />
      </div>
      { showThumbnail && (
        <div className={style['hero-image']}>
          <h3>Thumbnail Image</h3>
          <ZPhotoPreview
            key={thumbnailPhoto.id}
            alt={thumbnailPhoto.alt}
            data={thumbnailPhoto}
            gravity={thumbnailPhoto.gravity || 'auto'}
            onUpdate={onUpdateImage}
            removePhoto={() => onToggleConfirmation(null) }
            showInfo
            src={thumbnailPhoto.uri}
            style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
            title={thumbnailPhoto.title}
            transformation={thumbnailTransformation}
          />
        </div>
      )}
      { !showThumbnail && (
        <div className={style['hero-thumbnail']}>
          {gallery?.[0]?.uri && (
            <>
              <ZImage
                cloudinarySrc={gallery[0].uri}
                style={{ width: '300px' }}
                {...thumbnailTransformation}
              />
              <p>First Gallery Image with Sample Itinerary Transformation</p>
            </>
          )}
          <button
            type={"button"}
            style={{ ...buttonStyle, width: 'fit-content'}}
            onClick={onAddThumbnail}>
             {awaitThumbnail ? '...Creating Thumbnail' : 'Add Custom Thumbnail Image' }
          </button>
          <br/>
          <br/>
        </div>
      )}
        <div className={style['photo-gallery']}>
          <h3>Gallery</h3>
          { (galleryErrors && !galleryErrors.valid) &&
            <p className={sharedStyle['error']}>{galleryErrors.message}</p>
          }
          { gallery.length > 0 &&
            <ZDraggableGrid onDragEnd={onDragEnd} disabled={readOnly}>
              {gallery.map((ele, i)=>(
                <div key={ele.id}>
                  <ZPhotoPreview
                    alt={ele.alt}
                    data={ele}
                    gravity={ele.gravity}
                    index={i}
                    onUpdate={onUpdateImage}
                    readOnly={{src: true}}
                    removePhoto={() => onToggleConfirmation(ele.id)}
                    showInfo
                    src={ele.uri}
                    style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
                    title={ele.title}
                    transformation={getImagePreviewTransformation({
                      aspectRatio: 1.55,
                      ...!ele.gravity && { gravity: 'custom' },
                    })}
                  />
                    {(i < gallery.length - 1) &&
                      <div className={style['photo-gallery__add-at-index']}>
                        <ZAddButton
                          onClick={addPhotoAtIndex}
                          value={i}
                        >Add photo here</ZAddButton>
                      </div>
                    }
                </div>
                ))
              }
            </ZDraggableGrid>
          }
          <button
            type="button"
            style={buttonStyle}
            onClick={onToggleCreateModal}
          >
            Add A New Photo
          </button>
        </div>
      <ZModal
        closeable
        open={showCreate}
        style={{padding: '28px'}}
        toggleModal={onToggleCreateModal}
        useOverlay
      >
        <PhotoEdit
          onToggleModal={onToggleCreateModal}
          onSubmitNewImage={onSubmitNewImage}
        />
      </ZModal>
      <ZModal
        open={showConfirmation}
        toggleModal={onToggleConfirmation}
        style={{padding: '28px'}}
        closeable
        useOverlay
      >
        <h2 style={{color: 'red', textAlign: 'center'}}>Are you sure?</h2>
        <p style={{ textAlign: 'center'}}>This will delete the image from this itinerary permanently.</p>
        {/* {props.errors.imagesDelete &&
          <p className={sharedStyle['error']}>{props.errors.imagesDelete.message}</p>
        } */}
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <button
            type="button"
            style={{...buttonStyle, marginLeft: '0'}}
            onClick={deletePhoto}
            // disabled={props.errors.imagesDelete}
          >
            Submit
          </button>
          <button
            type="button"
            style={{...buttonStyle}}
            onClick={onToggleConfirmation}
          >
            Cancel
          </button>
        </div>
      </ZModal>
    </>
  )
}

const buttonStyle = {
  maxHeight: '40px',
  height: '40px',
  marginLeft: '50px',
  backgroundColor: '#faa02f',
  borderColor: '#ed9300',
  textAlign: 'center',
  borderRadius: '2px',
  cursor: 'pointer',
  boxShadow: '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)'
}

export default PhotosView;
