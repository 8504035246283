import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from './type.module.scss';


const TypeView = ({
  onRadioChange,
  articleType,
  date,
  inputColor,
  onDateInputChange,
}) => (
  <>
    <br/>
    <h3>Article Type</h3>
    <div className={style['type-wrapper']}>
      <div className={style['checkbox-wrapper']}>
        <div className={style['checkbox-item']}>
          <input 
            type='checkbox'
            id='evergreen' 
            className={style['checkbox']} 
            value='EVERGREEN'
            name='type'
            onChange={onRadioChange}
            checked={articleType === 'EVERGREEN'}
          />
          <label 
            htmlFor='evergreen'
          >
            Evergreen
          </label>
        </div>
        <div className={style['checkbox-item']}>
          <input 
            type='checkbox'
            id='topical' 
            className={style['checkbox']} 
            value='TOPICAL'
            name='type'
            onChange={onRadioChange}
            checked={articleType === 'TOPICAL'}
          />
          <label 
            htmlFor='topical'
          >
            Topical
          </label>
        </div>
        <div className={style['date-wrapper']}>
          <label
            style={{
              color: inputColor,
            }}
            htmlFor='expirationDate'
          >
            Expiration date
          </label>
          <DatePicker
            id='expirationDate'
            showPopperArrow={false}
            selected={date ? new Date(date) : ""}
            onChange={onDateInputChange}
            minDate={new Date()}
            dateFormat="MMMM d, yyyy"
            disabled={articleType === 'EVERGREEN'}
          />
        </div>
      </div>
    </div>
  </>
);

export default TypeView;
