import React from 'react';
import style from './z-pages.module.scss'

const ZPagesView = (props) => {
  return(
    <div className={style['pages']}>
      <div className={style['tab-container']}>
        {props.tabs.map((ele, i)=>{
          return <span 
            className={style[`tab-item${(ele === props.activeTab) ? '--active' : ''}`]} 
            tab={ele}
            onClick={props.setActive}
            key={`${ele}-${i}`}
            >{ele}</span>
        })}
      </div>
      <div className={style['page-container']}>
        {props.children}
      </div>
    </div>
  );
}

export default ZPagesView
