import React from 'react';
import { connect } from 'react-redux';
import {
  fetchPageIfNeeded,
  savePage,
  redirectToCreate,
 } from '../../state/actions/simple-editor';
 import { toggleReadOnly } from '../../state/actions/versions';
import SimpleEditorContainer from './simple-editor-container';
import {setTitleT} from "../../state/actions/shared/title";

const SimpleEditorRedux = (props) => (
  <SimpleEditorContainer {...props}/>
)

const mapStateToProps = (state, ownProps) => {
  // All this to avoid property of undefined does not exist errors.
  const { match } = ownProps;
  const { params } = match;
  const { id, updateId: paramUpdateId  } = params;
  const { editor } = state.pages.page;
  const currentPage = editor[id] || {};
  const { isFetching, notFound, saved } = editor;
  return {
    currentUpdateId: currentPage.updateId,
    isFetching,
    notFound,
    page: currentPage,
    paramUpdateId,
    readOnly: state.versions.readOnly,
    saved,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match } = ownProps;
  const { params } = match;
  const { id } = params;
  return {
    onCreateNew: () => dispatch(redirectToCreate()),
    onLoad: (updateId) => dispatch(fetchPageIfNeeded(id, updateId)),
    onSave: () => dispatch(savePage(id)),
    onTitleChange : text => dispatch(setTitleT('content', text, id)),
    onToggleReadOnly: () => dispatch(toggleReadOnly()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleEditorRedux);
