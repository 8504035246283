import React, { useEffect, useState } from 'react';
import ZApiClient from '../../../../utils/client/z-api-client';
import { useCompaniesStore } from '../../oryx-state';
import { decodeHTMLEntities } from '../../../../utils/utils';
import EditableView from './editable-view';

// takes a string and returns [charCount, wordCount]
const getWordCounts = (str) => {
  const decoded = decodeHTMLEntities(str);
  const chars = decoded.length;
  let words = 0;
  if (decoded.trim()) {
    words = decoded.trim().split(/\s+/).length;
  }
  return [chars, words];
}

const imagesClient = ZApiClient.getImagesClient();

const defaultProps = {
  errors: {},
};

const defaultModalState = {
  sourceUri: '',
  title: '',
  alt: '',
};

const EditableContainer = ({
  id,
}) => {

  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState(defaultModalState);

  const {
    companiesById,
    setPhoto,
    setDescription,
    setFirstName,
  } = useCompaniesStore();

  const {
    firstName,
    description,
    photo,
  } = companiesById[id] || {};

  useEffect(() => {
    if (photo && photo.id) {
      const {
        sourceUri,
        title,
        alt,
      } = photo;
      setModalState({
        sourceUri,
        title,
        alt,
      });
    }
  }, [photo]);

  const openModal = () => {
    setShowModal((state) => !state);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'firstName': setFirstName(id, value); break;
      case 'companyDescription': setDescription(id, value); break;
      default: break;
    }
  };

  const onModalChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'source': {
        setModalState((state) => ({...state, sourceUri: value}));
        break;
      }
      case 'alt': {
        setModalState((state) => ({ ...state, alt: value }));
        break;
      }
      case 'title': {
        setModalState((state) => ({ ...state, title: value}));
        break;
      }
      default:
        break;
    }
  }

  const onSubmitPhoto = () => {
    imagesClient.createImage(modalState)
      .then((res) => {
        setPhoto(id, res);
        setShowModal(false);
      })
      .catch(() => {
        console.warn('Error Adding Photo');
      })
  }

  const onCancel = () => {
    if (photo.id) {
      setModalState({
        sourceUri: photo.sourceUri,
        alt: photo.alt,
        title: photo.title,
      });
    } else {
      setModalState(defaultModalState);
    }
    setShowModal(false);
  }

  return (
    <EditableView
      firstName={firstName}
      description={description}
      photo={photo}
      handleChange={handleChange}
      showModal={showModal}
      openModal={openModal}
      modalState={modalState}
      onModalChange={onModalChange}
      onSubmitPhoto={onSubmitPhoto}
      onCancel={onCancel}
      counts={getWordCounts(description)}
    />
  );
};

EditableContainer.defaultProps = defaultProps;

export default EditableContainer;
