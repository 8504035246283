import { openModal } from './modals';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const loginSuccess = (username) => ({
  type: LOGIN_SUCCESS,
  username,
  receivedAt: Date.now(),
});

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const loginError = (error) => ({
  type: LOGIN_ERROR,
  error,
});

export const LOGOUT = 'LOGOUT';
export const logout = () => ({
  type: LOGOUT,
});

export const SET_ROLES = 'SET_ROLES';
export const setRoles = (roles) => ({
  type: SET_ROLES,
  roles,
});

export const openLoginModal = (closeAction) => (dispatch) => {
  dispatch(openModal('loginModal', closeAction));
};
