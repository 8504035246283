import React from 'react';
import { connect } from 'react-redux';
import {
  setShowTOC,
  setShowDate,
} from '../../../../state/actions/simple-editor';
import AppearanceView from './appearance-view';

const AppearanceRedux = (props) => (
  <AppearanceView {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const pageEntities = state.pages.page;
  const {editor} = pageEntities;
  const currentPage = editor[id]
  const { showDate, includeToC } = currentPage;
  return {
    showDate,
    includeToC,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return ({
    onShowDateChange: (bool) => dispatch(setShowDate(id, bool)),
    onIncludeToCChange: (bool) => dispatch(setShowTOC(id, bool)),
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(AppearanceRedux);
