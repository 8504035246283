import React, { useState, useEffect } from 'react';
import ZSelectView from './z-select-view';
import _ from 'lodash';

const ZSelectContainer = ({
  error,
  initialValue,
  inputKey,
  label,
  onChange,
  onUpdate,
  options,
  style,
}) => {
  const [text, setText] = useState(initialValue || '');
  let update = (onUpdate) ? onUpdate : () => {};
  let debounceUpdate = _.debounce(() => {
    update(text);
  }, 400);

  useEffect(() => {
    if (text) { debounceUpdate(); }
  }, [debounceUpdate, text]);

  const onValueChange = (event) => {
    onChange(event.target.value);
    setText(event.target.value);
  }

  return (
    <ZSelectView 
      error={error}
      inputKey={inputKey}
      label={label}
      onChange={onValueChange} 
      options={options}
      style={style}
      text={text}
    />
  );
};

export default ZSelectContainer;
