import React, { useEffect, useState } from 'react';
import ZVersionsView from './z-versions-view';

const ZVersionsContainer = ({
  actions, entityId, entityType, fetchPaths, loading, onFetchVersions,
  onClickUnpublish, paths, published, saved, versions,
}) => {
  const [items, setItems] = useState();
  const [offset, setOffset] = useState();
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (!versions) onFetchVersions(entityType, entityId);
  }, [entityId, entityType, onFetchVersions, versions]);

  useEffect(() => {
    if (!paths) fetchPaths(entityId);
  }, [entityId, fetchPaths, paths]);

  useEffect(() => {
    if (loadMore) {
      onFetchVersions(entityType, entityId);
      setLoadMore(false);
    }
  }, [loadMore, entityId, entityType, onFetchVersions]);

  useEffect(() => {
    if (versions) {
      const versionsSummary = versions.items.map((item) => ({
          published: published && (item.updateId === published.entityUpdateId && published.publish),
          timestamp: new Date(item.updatedAt).toLocaleString(),
          version: item.updateId,
          user: item.userId,
        }));
      setItems(versionsSummary);
      setOffset(versions.offset);
    }
  }, [versions, published]);

  const onClickLoadMore = (e) => {
    setLoadMore(true);
  };

  const onActionClick = (action, data) => () => {
    actions[action](data);
  };

  return (
    <ZVersionsView
      actions={actions}
      items={items}
      loading={loading}
      offset={offset}
      onActionClick={onActionClick}
      onClickLoadMore={onClickLoadMore}
      onClickUnpublish={onClickUnpublish}
      published={!!published}
      saved={saved}
    />
  );
};

export default ZVersionsContainer;
