import React from 'react';
import { connect } from 'react-redux';
import VersionsContainer from './versions-container';
import { fetchArticleVersion, makeLatest } from '../../../state/actions/article-editor';

const VersionsRedux = (props) => (
  <VersionsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {articleId:id} = ownProps;
  return{
    saved: state.articles.article.editor.saved,
    published: state.published[id] && state.published[id].current,
  }
};

const mapDispatchToProps = (dispatch) => ({
  makeLatest: (id, version) => dispatch(makeLatest(id, version)),
  onFetchVersion: (id, version) => dispatch(fetchArticleVersion(id, version)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionsRedux);
