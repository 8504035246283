import React from 'react';
import ZInput from '../z-input';
import ZPages from '../z-pages';
import ZPage from '../z-page';
import ZToast from '../z-toast';
import FloatingSave from '../shared/components/floating-save/floating-save-view';
import Content from './content';
import Optimization from './optimization';
import Publications from './publications';
import UsageReferences from '../usage-references';
import Versions from './versions';
import ParentLandingPage from './parent-landing-page';
import Reviews from './reviews';
import PublishedStatus from '../shared/components/published-status';
import style from './company-category.module.scss';
import sharedStyle from '../shared/styles/shared.module.scss';
import pageStyle from '../shared/styles/page.module.scss';
import Config from '../../config';

const baseUrlWeb = `${Config.Z3_URL}`;

const CompanyCategoryView = ({
  btnText,
  disabledMessage,
  errors,
  id,
  onSave,
  onTitleChange,
  onToastClose,
  openToast,
  readOnly,
  saved,
  saveDisabled,
  title,
  saveBtnDisabled,
  subtitle,
  updateId,
}) => (
  <>
    <div className={pageStyle['company-category']}>
      <div className={pageStyle['company-category__header']} style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <PublishedStatus entityId={id} entityUpdateId={updateId} />
          <h2>{saved ? 'Saved' : 'Not Saved'}</h2>
        </div>
        {readOnly && <h3 className={sharedStyle['read-only']}>Read Only View</h3>}
        <div className={pageStyle['preview-wrapper']}>
          <a
            className={`${pageStyle['preview-button']} ${sharedStyle['button']}`}
            href={`${baseUrlWeb}/dynamic?type=CompanyCategory&id=${id}`}
            target='_blank'
            rel="noopener noreferrer"
          >
            Preview
          </a>
        </div>
      </div>
      <div className={style['title-wrapper']}>
        <h1>
          <ZInput
            error={errors && errors.title}
            label='Title'
            onUpdate={onTitleChange}
            initialValue={title}
            style={{ margin: '14px 28px', fontSize: '22px' }}
            inputKey={'landingPage__title'}
          />
        </h1>
        <ParentLandingPage id={id} />
      </div>
      <ZPages readOnly={readOnly}>
        <ZPage tabName='Creation' key={`${1}-Creation`}>
          <Content
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Optimization' key={`${2}-Optimization`}>
          <Optimization
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Usage References' key={`${4}-UsageReferences`}>
          <UsageReferences
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Versions' key={`${5}-Versions`}>
          <Versions
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Publications' key={`${6}-Publications`}>
          <Publications />
        </ZPage>
        <ZPage tabName='Review Preview' key={`${7}-reviews`}>
          <Reviews
            entityId={id}
          />
        </ZPage>
      </ZPages>
      <div className={style['action-container']}>
        {(!title || !subtitle) &&
          <p className={[`${sharedStyle.error} ${style['action-container__message']}`]}>
            A title and subtitle are required to save.
          </p>
        }
        {disabledMessage &&
          <p className={style[`action-container__save`]}>
            {disabledMessage}
          </p>
        }
      </div>
    </div>
    <ZToast
      open={openToast}
      onClose={onToastClose}
      message={'Company Category Saved!'}
    />
    <FloatingSave
      onClick={onSave}
      disabled={!title || !subtitle || saveDisabled || saveBtnDisabled}
      text={btnText}
    />
  </>
);

export default CompanyCategoryView;
