import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';

const FloatingSave = ({
  onClick,
  disabled,
  text,
}) => (
  <Fab
    disabled={disabled}
    onClick={onClick}
    variant="extended" 
    size="medium" 
    color="secondary" 
    aria-label="save"
    sx={{
      margin: '0px',
      top: 'auto',
      right: '20px',
      bottom: '20px',
      left: 'auto',
      position: 'fixed',
    }}
  >
    <SaveIcon sx={{ mr: 1 }} />
    {text || 'Save'}
  </Fab>
);

export default FloatingSave;
