import { RoleName } from '@marageti/z4-sdk/lib/auth';

export const authorizedRoles = [
  RoleName.ADMIN,
  RoleName.MARKETING,
  RoleName.PHOTO,
];

export const hasAuthorizedRole = (roles) => {
  if (!roles) return false;
  for (let i = 0; i < authorizedRoles.length; i += 1) {
    if (roles.includes(authorizedRoles[i])) return true;
  }
  return false;
};

export const getMatchingUser = (email, userInfo) => (
  userInfo.find((user) => user.email === email)
);
