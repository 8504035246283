import React from 'react';
import { connect } from 'react-redux';
import FooterSectionView from './footer-section-view';
import {
  createSectionLinkT,
  removeFooterSectionT,
  removeSectionLinkT,
  setLinkTitleT, setLinkUriT,
  setSectionLinksT,
  setSectionTitleT
} from "../../../../../state/actions/shared/section";

const FooterSectionRedux = (props) => (
  <FooterSectionView {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {id:sectionId, index:sectionIndex} = ownProps;
  const currentEntities = state.landingPageCategories;
  const {sections} = currentEntities;
  const currentSection = sections[sectionId];
  const {title, links} = currentSection || {};
  return{
    index: sectionIndex,
    title,
    links: links || [],
    id: sectionId,
    readOnly: state.versions.readOnly,
  }
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId, id:sectionId} = ownProps;
  const defaultLink = {title: '', uri: ''};
  return {
    onDeleteSection: () => dispatch(removeFooterSectionT('landing_page_category', entityId, sectionId)),
    onTitleChange: (text) => dispatch(setSectionTitleT('landing_page_category', sectionId, text)),
    onAddLink: () => dispatch(createSectionLinkT('landing_page_category', sectionId, defaultLink)),
    onLinkTitleChange: (linkId, text) => dispatch(setLinkTitleT('landing_page_category', sectionId, linkId, text)),
    onLinkUriChange : (linkId, text) => dispatch(setLinkUriT('landing_page_category', sectionId, linkId, text)),
    onLinkOrderChange: (list) => dispatch(setSectionLinksT('landing_page_category', sectionId, list)),
    onDeleteLink: (linkId) => dispatch(removeSectionLinkT('landing_page_category', sectionId, linkId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterSectionRedux);
