import ZApiClient from '../../utils/client/z-api-client';

export const PUBLICATION_REFERENCE_REQUEST = 'PUBLICATION_REFERENCE_REQUEST';
export const publicationReferenceRequest = () => ({
  type: PUBLICATION_REFERENCE_REQUEST,
});

export const PUBLICATION_REFERENCE_SUCCESS = 'PUBLICATION_REFERENCE_SUCCESS';
export const publicationReferenceSuccess = (entityId, references) => ({
  type: PUBLICATION_REFERENCE_SUCCESS,
  entityId,
  references,
});

export const PUBLICATION_REFERENCES_ERROR = 'PUBLICATION_REFERENCES_ERROR';
export const publicationReferencesError = () => ({
  type: PUBLICATION_REFERENCES_ERROR,
});

export const CLEAR_PUBLICATION_REFERENCES = 'CLEAR_PUBLICATION_REFERENCES';
export const clearPublicationReferences = (entityId) => ({
  type: CLEAR_PUBLICATION_REFERENCES,
  entityId,
});

export const getPublicationReferences = (entityId) => (dispatch) => {
  dispatch(publicationReferenceRequest());
  ZApiClient
  .getPublicationsClient()
  .readPublicationReferences({ child: entityId })
    .then((res) => {
      dispatch(publicationReferenceSuccess(entityId, res));
    })
    .catch(() => dispatch(publicationReferencesError()))
};
