import { connect } from 'react-redux';
import {
  createPhotoT, updatePhotoT, removeHeaderPhotoT,
} from '../../../state/actions/shared/photo';
import ContentContainer from './content-container';
import {setSubtitleT, setSummaryT} from "../../../state/actions/shared/title";
import {setFlagCountryCodeT} from "../../../state/actions/shared/location";

const mapStateToProps = (state, ownProps) => {
  const { entityId } = ownProps;
  return {
    currentEntity: state.companyCategories.editor[entityId],
    photos: state.companyCategories.photos,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onChangePhoto: (imageData) => dispatch(updatePhotoT('company_category', imageData)),
    onCreateHeaderPhoto: (entityId, imageData) => dispatch(createPhotoT('company_category', entityId, 'header', imageData)),
    onDeleteHeaderPhoto: (entityId, headerPhotoId) => dispatch(removeHeaderPhotoT('company_category', entityId, headerPhotoId)),
    onFlagCountryCodeChange: (isoCode) => dispatch(setFlagCountryCodeT('company_category', isoCode, id)),
    onSubtitleChange: (subtitle) => dispatch(setSubtitleT('company_category', subtitle, id)),
    onSummaryChange: (text) => dispatch(setSummaryT('company_category', text, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
