import React from 'react';
import { connect } from 'react-redux';
import HeaderView from './header-view';

const HeaderRedux = (props) => (
  <HeaderView {...props} />
);

const mapStateToProps = (state) => ({
  loggedIn: state.users.login.loggedIn,
});

export default connect(mapStateToProps)(HeaderRedux);
