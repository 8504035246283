import React from 'react';
import { connect } from 'react-redux';
import {
  setReviewSearch
} from '../../../state/actions/reviews';
import ReviewsContainer from './reviews-container';

const ReviewsRedux = (props) => (
  <ReviewsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const { entityId:id } = ownProps;
  const { editor } = state.landingPages.landingPage;
  const currentEntity = editor[id] || {};
  return {
    id: currentEntity.id,
    updateId: currentEntity.updateId,
    reviewSearch: currentEntity.reviewSearch,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    setReviewSearch: (reviewSearch) => dispatch(setReviewSearch(id, 'landingPage', reviewSearch)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsRedux);
