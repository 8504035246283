import React from 'react';
import style from './sample-itinerary.module.scss';
import sharedStyle from '../../../shared/styles/shared.module.scss';
import ZInput from '../../../z-input/';
import ZPhotoPreview from '../../../z-photo-preview';
import { decodeHTMLEntities } from '../../../../utils/window';
import ZDeleteButton from '../../../z-button-delete';
import Config from '../../../../config';
import { getImagePreviewTransformation } from '../../../../utils/image/image-utils';

const oryxUrl = Config.ORYX_ENDPOINT;

const SampleItineraryView = (props) => {
  let image = null;

  if (props.itinerary.thumnailImage) {
    image = props.itinerary.thumnailImage;
  } else if (props.itinerary.image) {
    image = props.itinerary.image;
  } else {
    image = props.itinerary.heroImage;
  }

  return (
    <div className={`${style['sample-itinerary']}`}>
      <div className={style['sample-itinerary--left']}>
        <span className={style['sample-itinerary--left--current-pos']}>
          {`Position: ${props.index + 1}`}
        </span>
        <div className={style['sample-itinerary--left--move']}>
          <div className={style['sample-itinerary--left--pos']}>
            <ZInput
              style={{backgroundColor: '#FFF', padding: '0 10px'}}
              label={`Move to Position: `}
              onUpdate={(e) => {
                e && props.onChangePosition(props.index, isNaN(parseInt(e, 10) - 1) ? props.index : parseInt(e, 10) - 1);
              }}
            />
          </div>
          <button type="button" className={`${sharedStyle['button']} ${style['sample-itinerary--left--button']}`} onClick={() => props.onChangePosition(props.index, 0)}>Move To Top</button>
          <button type="button" className={`${sharedStyle['button']} ${style['sample-itinerary--left--button']}`} onClick={() => props.onChangePosition(props.index, props.itinerariesLength)}>Move To Bottom</button>
        </div>
      </div>
      <div className={style['sample-itinerary--right']}>
        <div className={style['sample-itinerary--title']}>
          <h3>{decodeHTMLEntities(props.itinerary.title)}</h3>
          <button type="button" className={`${sharedStyle['button']} ${style['sample-itinerary--title--button']}`} onClick={props.onClickEdit}>Edit</button>
          <button type="button" className={`${sharedStyle['button']} ${style['sample-itinerary--title--button']}`} onClick={props.onClickPreview}>Preview</button>
        </div>
        <div className={style['sample-itinerary--company']}>
          <div>
            {props.itinerary.company && (
              <a
                href={`${oryxUrl}/companies/${props.itinerary.company.id}`}
                rel="noreferrer"
                target="_blank"
              >
                {props.itinerary.company.name}
              </a>
            )}
          </div>
          {(props.itinerary.startingPrice && props.itinerary.duration) && (
            <p>
              {/* starting price plus $5, divided by duration */}
              Price (PPPD): ${(props.itinerary.startingPrice + 5) / props.itinerary.duration}
            </p>
          )}
        </div>
        <ZPhotoPreview
          alt={image ? image.alt : null}
          data={image}
          gravity={image.gravity}
          readOnly={{title: true, alt: true, src: true}}
          src={image ? image.uri : null}
          style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
          title={image ? image.title : null}
          transformation={getImagePreviewTransformation({ aspectRatio: 1.8, effect: 'sharpen:70' })}
        />
        <p className={style['sample-itinerary--item-wrapper__note']}>*Visit the itinerary page to make changes to the image.</p>
      </div>
      <div className={style['sample-itinerary--remove']}>
        <ZDeleteButton onClick={props.removeItinerary(props.index)} />
      </div>
    </div>
  )
}

export default SampleItineraryView;
