import React, { forwardRef } from 'react';
import styles from './oryx-textarea.module.scss';

const defaultProps = {
  autoComplete: 'on',
  disabled: false,
  inputClass: '',
  placeholder: '',
  rows: 5,
  spellCheck: true,
  style: {},
  tabIndex: 0,
  valid: true,
  onFocus: () => {},
};

const OryxTextAreaView = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function zInputTextarea({
    autoComplete,
    disabled,
    inputClass,
    name,
    onChange,
    onFocus,
    placeholder,
    rows,
    spellCheck,
    style,
    tabIndex,
    valid,
    value,
  }, ref) {
    return (
      <textarea
        autoComplete={autoComplete}
        className={`
          ${styles.input}
          ${valid ? '' : styles['input-invalid']}
          ${inputClass}
        `}
        disabled={disabled}
        id={name}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        ref={ref}
        rows={rows}
        spellCheck={spellCheck}
        style={style}
        tabIndex={tabIndex}
        value={value}
      />
    );
  },
);
OryxTextAreaView.defaultProps = defaultProps;

export default OryxTextAreaView;
