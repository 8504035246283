import { connect } from 'react-redux';
import TeaserContainer from './teaser-container';
import {setCTAStepsT, setCTATitleT} from "../../../../state/actions/shared/teaser";

const mapStateToProps = (state, ownProps) => {
  const { entityId } = ownProps;
  return {
    cta: state.landingPageCategories.editor[entityId]?.callToAction,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId } = ownProps;
  return {
    onCTATitleUpdate: (text) => dispatch(setCTATitleT('landing_page_category', text, entityId)),
    onCTAStepsUpdate: (data) => dispatch(setCTAStepsT('landing_page_category', data, entityId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeaserContainer);
