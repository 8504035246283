import React, { useState } from 'react';
import GalleryLinksView from './gallery-links-view';

const GalleryLinkContainer =  ({
  deleteGalleryLinkSection,
  entityId,
  galleryLinkCount,
  links,
  receivedPhoto,
  setGalleryLinks,
  setGalleryLinksTitle,
  title,
}) => {
  const [useGalleryLinks, setUseGalleryLinks] = useState(title || galleryLinkCount > 0);
  const [editLinkIndex, setEditLinkIndex] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onChangeToggle = () => {
    if (useGalleryLinks) setShowDeleteConfirmation(true);
    else {
      setGalleryLinksTitle('Top Destinations');
      setGalleryLinks([]);
      setUseGalleryLinks(true);
    }
  };

  const onClickGalleryLink = (e) => {
    setEditLinkIndex(e.target.id);
  };

  const closeModal = () => {
    setEditLinkIndex();
    setShowDeleteConfirmation(false);
  };

  const onAddLink = (link) => {
    setGalleryLinks([...links, link]);
    receivedPhoto(link.image);
  };

  const onRemoveLink = (e) => {
    e.stopPropagation();
    const updatedLinks = [...links];
    updatedLinks.splice(e.target.parentElement.id, 1);
    setGalleryLinks(updatedLinks);
  };

  const onDragEnd = (oldIndex, newIndex, arrayMove) => {
    setGalleryLinks(arrayMove(links, oldIndex, newIndex));
  };

  const onChangeLink = (link, i) => {
    const updatedLinks = [...links];
    updatedLinks[i] = link;
    setGalleryLinks(updatedLinks);
  };

  const onClickConfirmDelete = () => {
    deleteGalleryLinkSection();
    setUseGalleryLinks(false);
    closeModal();
  };

  return (
    <GalleryLinksView
      closeModal={closeModal}
      editLinkIndex={editLinkIndex}
      entityId={entityId}
      galleryLinkCount={galleryLinkCount}
      links={links}
      onAddLink={onAddLink}
      onChangeLink={onChangeLink}
      onChangeToggle={onChangeToggle}
      onClickConfirmDelete={onClickConfirmDelete}
      onClickGalleryLink={onClickGalleryLink}
      onDragEnd={onDragEnd}
      onRemoveLink={onRemoveLink}
      setGalleryLinksTitle={setGalleryLinksTitle}
      showDeleteConfirmation={showDeleteConfirmation}
      title={title}
      useGalleryLinks={useGalleryLinks}
    />
  );
};

export default GalleryLinkContainer;
