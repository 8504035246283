import React from 'react';
import ZInput from '../../../z-input';
import ZTextArea from '../../../z-text-area';
import PhotoPreview from '../../../shared/components/content-image'
import ZArrow from '../../../z-arrow';
import style from './section.module.scss';
import ZDeleteButton from '../../../z-button-delete';
import ZAddButton from '../../../z-button-add';

const Section = ({
  showPhoto, 
  onToggleDropdown, 
  onTitleChange, 
  onTextChange,
  onDeleteClick,
  onAddSectionBelow,
  title, 
  text, 
  order, 
  id,
  onChangePhoto,
  onCreatePhoto,
  onRemovePhoto,
  photo,
}) => {
  return (
    <div className={`${style['day']}`}>
      <div style={{width: '95%', maxWidth: '95%'}}>
        <div className={style['day-number']}>
          <p>Section: {order}</p>
        </div>
        <ZInput 
          style={{marginBottom: '28px'}} 
          label='Title (Optional)' 
          className='day-title' 
          onChange={onTitleChange} 
          text={title || ''} 
          inputKey={`${id}__title`}
        />
        <div className={style['dropdown__wrapper']} onClick={onToggleDropdown}>
          <ZArrow direction={showPhoto ? 'down' : 'right'}/>
          <p style={{marginLeft: '14px'}}>Section Photo (optional)</p>
        </div>
        {showPhoto && 
          <PhotoPreview 
            entityId={id}
            key={photo.id}
            photoId={photo.id}
            style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
            showInfo
            onChangePhoto={onChangePhoto}
            onCreatePhoto={onCreatePhoto}
            onRemovePhoto={onRemovePhoto}
            photo={photo}
            type="section"
          />
        }
        <br/>
        <ZTextArea 
          allowedFormats={['ITALICS', 'LINKS', 'BOLD', 'ORDERED_LIST', 'BULLET_POINTS']} 
          onUpdate={onTextChange} 
          value={text} 
          inputKey={`${id}__description`}
        />
      </div>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '5%', maxWidth: '5%'}}>
        <ZDeleteButton onClick={onDeleteClick} />
        <ZAddButton onClick={onAddSectionBelow} />
      </div>
    </div>
  )
}

export default Section;
