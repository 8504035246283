import React from 'react';
import style from './breadcrumbs.module.scss';
import ZAutoComplete from '../../../z-autocomplete';
import ZInput from '../../../z-input';
import ZSpinner from '../../../z-spinner';

const BreadcrumbView = ({
  breadcrumbList,
  breadcrumbTitle,
  currentTitle,
  customBreadcrumbList,
  customBreadcrumbTitle,
  error,
  isFetching,
  onSearch,
  onSelect,
  onDeleteBreadcrumb,
  onDeleteBreadcrumbTitle,
  setCustomBreadcrumbTitle,
  showBreadcrumbSearch,
  updateBreadcrumbTitle,
  useCustom,
}) => (
  <div className={style['breadcrumb-wrapper']}>
      <h3>Breadcrumbs</h3>
    <br/>
    <div className={style['breadcrumb__display']}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <p>Current Breadcrumb title: {breadcrumbTitle || currentTitle}</p>
      </div>
      <br/>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{ whiteSpace: 'nowrap' }}>Current Breadcrumb List:</p>
        <div style={{display: 'flex', flexWrap: 'wrap' }}>
         {isFetching ? (
           <ZSpinner />
          ) : (
            <>
              <BreadcrumbFormatter
                list={customBreadcrumbList || breadcrumbList}
                breadcrumbTitle={breadcrumbTitle}
              />
              { (useCustom && showBreadcrumbSearch) &&
                <>( Custom Parent )</>
              }
              { breadcrumbTitle &&
                <>( Custom Title )</>
              }
            </>
          )}
          {(error && !isFetching) && (
            <p>Error getting breadcrumbs</p>
          )}
        </div>
      </div>
      <br/>
      {showBreadcrumbSearch && (
        <div>
          {(useCustom) &&
            <button
              type="button"
              className={style['button']}
              onClick={onDeleteBreadcrumb}
            >
              Delete Custom Breadcrumb Parent
            </button>
          }
          <br/><br/>
          <ZAutoComplete
            label='Search to find a Custom Breadcrumb Parent'
            style={{marginBottom: '28px'}}
            autoComplete={onSearch}
            onSelect={onSelect}
            returnsObject
            inputKey={'breadcrumb_parent'}
          />
        </div>
      )}
      <div>
        <h3>Set Manual Breadcrumb Title</h3>
        <br/>
        <ZInput
          label='Enter Manual Title'
          type='text'
          inputKey={`manual_title`}
          onChange={setCustomBreadcrumbTitle}
          text={customBreadcrumbTitle}
        />
        <br/>
        { customBreadcrumbTitle &&
          <button
            type="button"
            className={style['button']}
            onClick={updateBreadcrumbTitle}
          >
            Update Title
          </button>
        }
        { !customBreadcrumbTitle && breadcrumbTitle &&
          <button
            type="button"
            className={style['button']}
            onClick={onDeleteBreadcrumbTitle}
          >
            Delete Manual Title
          </button>
        }
      </div>
    </div>
  </div>
);

const BreadcrumbFormatter = ({
  list, breadcrumbTitle
}) => (
  <div className={style['breadcrumbs']}>
    { list && list.length > 0 && list.map((crumb, i) => (
      <React.Fragment key={`bc-${crumb.title}`}>
        { i !== (list.length - 1)
        && (
          <>
            <p
              className={style['breadcrumbs__crumb']}
              dangerouslySetInnerHTML={{ __html: crumb.title }}
            />
            <span className={style['breadcrumbs__arrow']}>
              &nbsp;&#187;&nbsp;
            </span>
          </>
        )}
        { i === (list.length - 1)
        && (
          <p
            className={`
              ${style['breadcrumbs__crumb']}
              ${style['breadcrumbs__crumbLast']}
            `}
            dangerouslySetInnerHTML={{ __html: breadcrumbTitle || crumb.title }}
          />
        )}
      </React.Fragment>
    ))}
  </div>
)

export default BreadcrumbView;
