import React from 'react';
import { connect } from 'react-redux';
import MetatagsView from './meta-tags-view';
import {
  setMetaTitle,
  setMetaDescription,
  setNoindex,
  setNofollow
} from '../../../../state/actions/meta-tags';

const MetatagsRedux = (props) => (
  <MetatagsView {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const { entityId:id } = ownProps;
  const pageEntities = state.pages.page;
  const { editor } = pageEntities;
  const currentPage = editor[id] || {};
  const { metatags, title } = currentPage;
  return {
    title,
    metatags,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onMetaTitleChange: (text) => dispatch(setMetaTitle(text, id)),
    onMetaDescriptionChange: (text) => dispatch(setMetaDescription(text, id)),
    onMetaNoindexChange: (value) => dispatch(setNoindex(value, id)),
    onMetaNofollowChange: (value) => dispatch(setNofollow(value, id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MetatagsRedux);
