import React from 'react';
import { connect } from 'react-redux';
import {
  setHeaderLinks
} from '../../../../state/actions/landing-page-editor';
import HeaderLinksContainer from './header-links-container';

const HeaderLinksRedux = (props) => (
  <HeaderLinksContainer {...props} />
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const {editor} = state.landingPages.landingPage;
  const currentEntity = editor[id] || {};
  return {
    currentEntity,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onHeaderLinksChange: (headerLinks) => dispatch(setHeaderLinks(id, headerLinks)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinksRedux);
