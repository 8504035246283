import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from './content-container';
import {setSummaryT} from "../../../state/actions/shared/title";
import {createPhotoT, removeHeaderPhotoT, removeSectionPhotoT, updatePhotoT} from "../../../state/actions/shared/photo";
import {
  createSectionT,
  removeSectionT,
  reorderSectionsT,
  setSectionTextT,
  setSectionTitleT
} from "../../../state/actions/shared/section";

const ContentRedux = (props) => (
  <ContentContainer {...props}/>
);

const mapSectionIdToSection = (ids, sections, photos) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = {...sections[ele]};
      section.photo = photos[section.photoId] || {} ;
      return section;
    });
  }
};

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const entity = state.pages.page.editor[id] || {};
  const sectionState =  state.pages.page.sections || {};
  const photoState = state.pages.page.photos || {};
  const {sectionIds} = entity;
  return{
    entity,
    sections: mapSectionIdToSection(sectionIds, sectionState, photoState),
    photos: state.pages.page.photos,
    readOnly: state.versions.readOnly,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onSummaryChange: text => dispatch(setSummaryT('content', text, id)),
    onAddSectionClick: (index = null) => dispatch(createSectionT('content', id, index)),
    onRemoveSectionClick: (sectionId) => dispatch(removeSectionT('content', id, sectionId)),
    onSectionTitleChange: (sectionId, text) => dispatch(setSectionTitleT('content', sectionId, text)),
    onSectionTextChange: (sectionId, text) => dispatch(setSectionTextT('content', sectionId, text)),
    onSectionOrderChange: (sectionIds) => dispatch(reorderSectionsT('content', id, sectionIds)),
    onChangePhoto: (imageData) => dispatch(updatePhotoT('content', imageData)),
    onCreateHeaderPhoto: (entityId, imageData) => dispatch(createPhotoT('content', entityId, 'header', imageData)),
    onCreateSectionPhoto: (sectionId, imageData) => dispatch(createPhotoT('content', sectionId, 'section', imageData)),
    onDeleteHeaderPhoto: (entityId, headerPhotoId) => dispatch(removeHeaderPhotoT('content', entityId, headerPhotoId)),
    onDeleteSectionPhoto: (sectionId, photoId) => dispatch(removeSectionPhotoT('content', sectionId, photoId)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentRedux);
