import React from 'react';
import { connect } from 'react-redux';
import {
  setTitleFlag,
} from '../../../state/actions/landing-page-editor';
import ContentContainer from './content-container';
import {setSummaryT} from "../../../state/actions/shared/title";
import {
  createPhotoT,
  removeHeaderPhotoT,
  updatePhotoT
} from "../../../state/actions/shared/photo";

const ContentRedux = (props) => (
  <ContentContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const currentEntity = {...state.landingPages.landingPage.editor[id]};
  return{
    currentEntity,
    photos: state.landingPages.landingPage.photos,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onChangePhoto: (imageData) => dispatch(updatePhotoT('landing_page', imageData)),
    onCreateHeaderPhoto: (entityId, imageData) => dispatch(createPhotoT('landing_page', entityId, 'header', imageData)),
    onDeleteHeaderPhoto: (entityId, headerPhotoId) => dispatch(removeHeaderPhotoT('landing_page', entityId, headerPhotoId)),
    onSummaryChange: text => dispatch(setSummaryT('landing_page', text, id)),
    onTitleFlagChange: (isoCode) => dispatch(setTitleFlag(id, isoCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentRedux);
