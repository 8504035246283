import React, { useEffect, useState } from 'react';
import ZChips from '../../z-chips';
import OryxMultiSelect from '../oryx-shared/oryx-multiselect';
import OryxButton from '../oryx-shared/oryx-button/type-button';
import { useCompaniesStore } from '../oryx-state';
import styles from './companies.module.scss';


const profileStatusOptions = [
  {
    name: 'Published',
    value: 'true',
    displayValue: 'Published',
    checked: false,
    type: 'profile',
  },
  {
    name: 'Unpublished',
    value: 'false',
    displayValue: 'Unpublished',
    checked: false,
    type: 'profile',
  },
];

const nameOptions = [
  {
    name: 'Included',
    value: 'true',
    displayValue: 'Included',
    checked: false,
    type: 'name',
  },
  {
    name: 'Missing',
    value: 'false',
    displayValue: 'Missing',
    checked: false,
    type: 'name',
  },
];

const photoOptions = [
  {
    name: 'Included',
    value: 'true',
    displayValue: 'Included',
    checked: false,
    type: 'photo',
  },
  {
    name: 'Missing',
    value: 'false',
    displayValue: 'Missing',
    checked: false,
    type: 'photo',
  },
];

const descriptionOptions = [
  {
    name: 'Included',
    value: 'true',
    displayValue: 'Included',
    checked: false,
    type: 'description',
  },
  {
    name: 'Missing',
    value: 'false',
    displayValue: 'Missing',
    checked: false,
    type: 'description',
  },
];

const matchingOptions = [
  {
    name: 'Priority Match',
    value: 'PRIORITY_MATCH',
    displayValue: 'Priority Match',
    checked: false,
    type: 'activity',
  },
  {
    name: '2nd Match',
    value: 'SECOND_MATCH',
    displayValue: '2nd Match',
    checked: false,
    type: 'activity',
  },
  {
    name: '3rd Match',
    value: 'THIRD_OPTION',
    displayValue: '3rd Match',
    checked: false,
    type: 'activity',
  },
  {
    name: 'Last Resort',
    value: 'LAST_RESORT',
    displayValue: 'Last Resort',
    checked: false,
    type: 'activity',
  },
  {
    name: 'Do Not Match',
    value: 'DO_NOT_MATCH',
    displayValue: 'Do Not Match',
    checked: false,
    type: 'activity',
  },
  {
    name: 'Excluded',
    value: 'EXCLUDED',
    displayValue: 'Excluded',
    checked: false,
    type: 'activity',
  },
  {
    name: 'New',
    value: 'NEW',
    displayValue: 'New',
    checked: false,
    type: 'activity',
  },
];

const getSelectedOptions = (arr) => (
  arr.reduce((acc, cur) => {
    if (cur.checked && cur.type !== 'GROUP_LABEL') {
      return [...acc, cur.value];
    }
    return acc;
  }, [])
);

const generateLabel = (type, initialVal) => {
  let res;
  switch (type) {
    case 'published': {
      res = initialVal;
      break;
    }
    case 'firstNameExists': {
      res = `First Name: ${initialVal}`;
      break;
    }
    case 'photoExists': {
      res = `Photo: ${initialVal}`;
      break;
    }
    case 'descriptionExists': {
      res = `Description: ${initialVal}`;
      break;
    }
    case 'primaryLocations': {
      res = `Specialized Country: ${initialVal}`;
      break;
    }
    case 'secondaryLocations': {
      res = `Secondary Country: ${initialVal}`;
      break;
    }
    case 'activityLevels': {
      res = `Matching Priority: ${initialVal}`;
      break;
    }
    default:
      break;
  }
  return res;
};

const mapOptionsToChips = (type, options) => {
  const ret = [];
  for (let i = 0; i < options.length; i++) {
    const cur = options[i];
    if(cur.checked){
      ret.push({
        value: cur.value,
        label: generateLabel(type, cur.name),
        type,
      });
    }
  }
  return ret;
}

const getActivityOptions = (options, filters) => {
  if (!filters) return options;
  let res = options.map((option) => {
    const cur = {...option};
    cur.checked = filters.includes(cur.value);
    return cur;
  });
  return res;
}

const getLocationOptions = (type, countryName, filters) => {
  let res = [];
  for (let i = 0; i < countryName.length; i++) {
    const location = countryName[i];
    res.push({
      name: location,
      value: location,
      displayValue: location,
      checked: filters.includes(location),
      group: 'all',
      type,
    })
  }
  return res;
}


// Since the value property of a <input> is stringified if its a boolean
// this maps our boolean filters to an option with value='true'/'false'.
const getOptionsFromBoolFilter = (options, filter) => {
  if (typeof filter !== 'boolean') {
    return options;
  }
  const ret = options.map((option) => {
    const cur = {...option};
    if(cur.value === `${filter}`) {
      cur.checked = true;
    }
    return cur;
  });
  return ret;
}


const CompaniesFiltersView = ({
  companyLocations,
  onReset,
  onFilterChange,
}) => {
  const [profileStatus, setProfileStatusOptions] = useState(profileStatusOptions);
  const [firstName, setNameOptions] = useState(nameOptions);
  const [photo, setPhotoOptions] = useState(photoOptions);
  const [description, setonDescriptionOptions] = useState(descriptionOptions);
  const [matchingLevel, setMatchingLevelOptions] = useState(matchingOptions);
  const [primaryLocation, setPrimaryLocationOptions] = useState([]);
  const [secondaryLocation, setSecondaryLocationOptions] = useState([]);
  const [activeChips, setActiveChips] = useState([]);

  const {
    filters,
    setProfileStatus,
    setFirstNameExists,
    setDescriptionExists,
    setPhotoExists,
    setPrimaryLocations,
    setSecondaryLocations,
    setActivityLevels,
  } = useCompaniesStore();

  useEffect(() => {
    const {
      published,
      firstNameExists,
      photoExists,
      descriptionExists,
      primaryLocations,
      secondaryLocations,
      activityLevels,
    } = filters;
    if (companyLocations && companyLocations.length) {
      // Profile Options and chips
      const profileOptions = getOptionsFromBoolFilter(profileStatusOptions, published);
      setProfileStatusOptions(profileOptions)
      const profileChips = mapOptionsToChips('published', profileOptions);
      // First Name Options and chips
      const firstNameOptions = getOptionsFromBoolFilter(nameOptions ,firstNameExists)
      setNameOptions(firstNameOptions);
      const nameChips = mapOptionsToChips('firstNameExists', firstNameOptions);
      // Photo Options and chips
      const photoExistOptions = getOptionsFromBoolFilter(photoOptions, photoExists);
      setPhotoOptions(photoExistOptions);
      const photoChips = mapOptionsToChips('photoExists', photoExistOptions);
      // Description Option and chips
      const descriptionExistsOptions = getOptionsFromBoolFilter(descriptionOptions, descriptionExists);
      setonDescriptionOptions(descriptionExistsOptions);
      const descriptionChips = mapOptionsToChips('descriptionExists', descriptionExistsOptions);
      // Primary Location Option and chips
      const primaryLocationOptions = getLocationOptions('primary', companyLocations, primaryLocations || []);
      setPrimaryLocationOptions(primaryLocationOptions);
      const primaryLocationChips = mapOptionsToChips('primaryLocations', primaryLocationOptions);
      // Secondary Location Option and chips
      const secondaryLocationOptions = getLocationOptions('secondary', companyLocations, secondaryLocations || []);
      setSecondaryLocationOptions(secondaryLocationOptions);
      const secondaryLocationChips = mapOptionsToChips('secondaryLocations', secondaryLocationOptions);
      // Matching Levels
      const matchingLevelOptions = getActivityOptions(matchingOptions, activityLevels);
      setMatchingLevelOptions(matchingLevelOptions);
      const matchingLevelChips = mapOptionsToChips('activityLevels', matchingLevelOptions);
      // @ts-ignore
      const mergedChips = [
        ...profileChips,
        ...nameChips,
        ...photoChips,
        ...descriptionChips,
        ...primaryLocationChips,
        ...secondaryLocationChips,
        ...matchingLevelChips,
      ];
      setActiveChips(mergedChips);
    }
  }, [filters, companyLocations]);

  const onProfileChange = (arr) => {
    const res = getSelectedOptions(arr);
    let filterRes = null;
    if(res.length){
      filterRes = (res[0] === 'true')
    }
    setProfileStatus(filterRes);
    onFilterChange();
  };

  const onNameStatusChange = (arr) => {
    const res = getSelectedOptions(arr);
    let filterRes = null;
    if(res.length){
      filterRes = (res[0] === 'true')
    }
    setFirstNameExists(filterRes);
    onFilterChange();
  };

  const onPhotoStatusChange = (arr) => {
    const res = getSelectedOptions(arr);
    let filterRes = null;
    if(res.length){
      filterRes = (res[0] === 'true');
    }
    setPhotoExists(filterRes);
    onFilterChange();
  };

  const onDescriptionStatusChange = (arr) => {
    const res = getSelectedOptions(arr);
    let filterRes = null;
    if(res.length){
      filterRes = (res[0] === 'true');
    }
    setDescriptionExists(filterRes);
    onFilterChange();
  };

  const onPrimaryLocationChange = (arr) => {
    const res = getSelectedOptions(arr);
    setPrimaryLocations(res);
    onFilterChange();
  }

  const onSecondaryLocationChange = (arr) => {
    const res = getSelectedOptions(arr);
    setSecondaryLocations(res);
    onFilterChange();
  }

  const onMatchingLevelChange = (arr) => {
    const res = getSelectedOptions(arr);
    setActivityLevels(res);
    onFilterChange();
  }

  const onResetFilters = () => {
    onReset();
    onFilterChange();
  };

  const onChipChange = (arr) => {
    const result = arr.reduce((acc, cur) => {
      let currentValue = [cur.value];
      if (cur.value.includes(',')) {
        currentValue = cur.value.split(',');
      }
      acc[cur.type] = [...acc[cur.type], ...currentValue];
      return acc;
    },
    {
      published: [],
      firstNameExists: [],
      photoExists: [],
      descriptionExists: [],
      primaryLocations: [],
      secondaryLocations: [],
      activityLevels: [],
    });
    const {
      published,
      firstNameExists,
      photoExists,
      descriptionExists,
      primaryLocations,
      secondaryLocations,
      activityLevels,
    } = result;
    setProfileStatus(published.length ? published[0] === "true" : null);
    setFirstNameExists(firstNameExists.length ? firstNameExists[0] === "true" : null);
    setPhotoExists(photoExists.length ? photoExists[0] === "true" : null);
    setDescriptionExists(descriptionExists.length ? descriptionExists[0] === "true" : null);
    setPrimaryLocations(primaryLocations);
    setSecondaryLocations(secondaryLocations);
    setActivityLevels(activityLevels);
    onFilterChange();
  };

  return (
    <>
      <div className={styles['company__filter-wrapper']}>
        <div className={styles.company__filters}>
          <OryxMultiSelect
            key="profile"
            label="Profile Status"
            onChange={onProfileChange}
            options={profileStatus}
            className={styles['company__filter-small']}
            itemClass={styles['company__filter-item']}
          />
          <OryxMultiSelect
            key="name"
            label="First Name"
            onChange={onNameStatusChange}
            options={firstName}
            className={styles['company__filter-small']}
            itemClass={styles['company__filter-item']}
          />
          <OryxMultiSelect
            key="photo"
            label="Photo"
            onChange={onPhotoStatusChange}
            options={photo}
            className={styles['company__filter-small']}
            itemClass={styles['company__filter-item']}
          />
          <OryxMultiSelect
            key="description"
            label="Description"
            onChange={onDescriptionStatusChange}
            options={description}
            className={styles['company__filter-small']}
            itemClass={styles['company__filter-item']}
          />
          <OryxMultiSelect
            key="primaryLocation"
            label="Specialized Countries"
            onChange={onPrimaryLocationChange}
            options={primaryLocation}
            typeahead="name"
            itemClass={styles['company__filter-item']}
          />
          <OryxMultiSelect
            key="secondaryLocatoin"
            label="Secondary Countries"
            onChange={onSecondaryLocationChange}
            options={secondaryLocation}
            typeahead="name"
            itemClass={styles['company__filter-item']}
          />
          <OryxMultiSelect
            key="matchingLevel"
            label="Matching Priority Level"
            onChange={onMatchingLevelChange}
            options={matchingLevel}
            itemClass={styles['company__filter-item']}
          />
        </div>
        <OryxButton
          onClick={onResetFilters}
          style={{
            width: '100px',
            flex: '0 1 auto',
            margin: '20px 8px 0 8px',
          }}
        >
          Reset
        </OryxButton>
      </div>
      <div className={styles['company__chips-container']}>
        <ZChips
          data={activeChips}
          onChange={onChipChange}
        />
      </div>
    </>
  );
};

export default CompaniesFiltersView;
