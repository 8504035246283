import React, { useState, useEffect } from 'react';
import PhotosView from './photos-view';
// Import will be used
import { itineraryValidationRequirements } from '../../../../utils/validation';

const PhotosContainer = ({
  galleryPhotos,
  galleryErrors,
  headerPhoto,
  heroImageErrors,
  readOnly,
  updatePhoto,
  onListChange,
  onDeleteGalleryImage,
  onDeleteThumbnail,
  thumbnailPhoto,
  addGalleryImage,
  addThumbnail,
  updateHeaderPhoto,
}) => {
  const [showThumbnail, setShowThumbnail] = useState(!!thumbnailPhoto);
  const [showConfirmation, toggleConfirmation] = useState(false);
  const [showCreate, toggleCreate ] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);
  const [indexToAdd, setIndexToAdd] = useState();
  const [createThumbnail, setCreateThumbnail ] = useState(false);
  const [awaitThumbnail, setAwaitThumbnail ] = useState(false);

  useEffect(()=> {
    if(thumbnailPhoto){
      let { id } =  thumbnailPhoto;
      if(id){
        setShowThumbnail(true);
        setAwaitThumbnail(false);
      } 
    } else {
      setShowThumbnail(false);
    }
  }, [thumbnailPhoto]);

  const onToggleCreateModal= () => {
    toggleCreate((state) => !state);
  }

  const onToggleConfirmation = (photoId) => {
    toggleConfirmation((state) => !state);
    if(photoId){
      setIdToDelete(photoId);
    }
  }

  const onUpdateImage = (data) => {
    updatePhoto(data);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (result) => {
    const items = reorder(
      galleryPhotos,
      result.oldIndex,
      result.newIndex
    );
    const ret = items.map((ele)=> ele.id);
    onListChange(ret);
  }

  const deletePhoto = () => {
    if(!!idToDelete){
      onDeleteGalleryImage(idToDelete);
      setIdToDelete(false);
    } else {
      onDeleteThumbnail();
    }
    toggleConfirmation((state) => !state);
  }

  const onAddThumbnail = () => {
    setCreateThumbnail(true);
    toggleCreate(true);
  }

  const addPhotoAtIndex = (e) => {
    const index = e.currentTarget.value;
    setIndexToAdd(index);
    toggleCreate(true);
  }


  const onSubmitNewImage = (imgObj) => {
    if(createThumbnail){
      addThumbnail(imgObj);
      setAwaitThumbnail(true);
    } else {
      addGalleryImage(imgObj, indexToAdd);
    }
    setIndexToAdd(null);
    toggleCreate(false);
    setCreateThumbnail(false);
  }

  /**
   * @TODO Refactor the bellow error logic
   */
  // toggleConfirmation = (index = null, type = null) => () => {
  //   if (type === 'heroThumb') {
  //     this.setState({
  //       ...this.state,
  //       showConfirmation: !this.state.showConfirmation,
  //       toDelete: 'heroThumb'
  //     });

  //     return;
  //   }

  //   const { published, photos, errors, imagesDelete } = this.props;
  //   const { images } = itineraryValidationRequirements;

  //   if (!!published && photos.gallery.length <= images.min && !errors.imagesDelete) {
  //     this.props.dispatch(update({ errors: {
  //       ...errors,
  //       imagesDelete: {
  //         message: `${images.errorMessage} Please unpublish or add an image in order to delete this image.`
  //       }
  //     }}));
  //   } else if (imagesDelete) {
  //     const { imagesDelete, ...remainingErrors } = errors;
  //     this.props.dispatch(update({ errors: remainingErrors }));
  //   }
  //   this.setState({
  //     ...this.state,
  //     showConfirmation: !this.state.showConfirmation,
  //     toDelete: index
  //   });
  // }
  

  return (
    <PhotosView
      heroImage={headerPhoto}
      heroImageErrors={heroImageErrors}
      gallery={galleryPhotos}
      galleryErrors={galleryErrors}
      onUpdateImage={onUpdateImage}
      onUpdateHeader={updateHeaderPhoto}
      onToggleCreateModal={onToggleCreateModal}
      showCreate={showCreate}
      onToggleConfirmation={onToggleConfirmation}
      showConfirmation={showConfirmation}
      readOnly={readOnly}
      onDragEnd={onDragEnd}
      deletePhoto={deletePhoto}
      thumbnailPhoto={thumbnailPhoto || {}}
      showThumbnail={showThumbnail}
      onAddThumbnail={onAddThumbnail}
      addPhotoAtIndex={addPhotoAtIndex}
      onSubmitNewImage={onSubmitNewImage}
      awaitThumbnail={awaitThumbnail}
    />
  )
}

export default PhotosContainer;
