/* eslint-disable import/prefer-default-export */

export const getFormattedLocations = (locations, max = null) => {
  let finalString = '';
  if (locations) {
    for (let i = 0; i < locations.length; i += 1) {
      finalString += locations[i].name;

      //  Add commas where needed
      if (max && (i + 1) >= max) { break; }
      if (locations.length > 1 && i < (locations.length - 1)) {
        finalString += ',\n';
      }
    }

    //  If supplied max and length is greater
    if (max && locations?.length > max) {
      finalString += '+';
    }
  }

  return finalString;
};

export const getFormattedDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  return `${month}/${day}/${year}`;
};

