const dev = {
  ADMIN_URL: 'http://localhost:3000',
  API_URL: 'https://api-qa.zicasso.com/v2',
  AUTH_API_URL: 'https://api-qa.zicasso.com/v2/',
  D7_URL: 'https://d7-qa.zicasso.com',
  SDK_URL: 'https://api-qa.zicasso.com',
  Z3_URL: 'https://www-qa.zicasso.com',
  IMAGES_ENDPOINT: 'https://images-qa.zicasso.com/',
  ORYX_ENDPOINT: 'https://oryx-qa.zicasso.com',
  Z4_API: 'https://tapi-qa.zicasso.com/v3',
  ENV: 'dev',
  GOOGLE_CLIENT_ID: '914813737628-gb5aqgfqt1lb1l7nh16it6d9f4gh7obc.apps.googleusercontent.com',
}

const staging = {
  ADMIN_URL: 'https://admin-staging.zicasso.com',
  API_URL: 'https://api-staging.zicasso.com/v2',
  AUTH_API_URL: 'https://api-staging.zicasso.com/v2/',
  D7_URL: 'https://d7-staging.zicasso.com',
  SDK_URL: 'https://api-staging.zicasso.com',
  Z3_URL: 'https://www-staging.zicasso.com',
  IMAGES_ENDPOINT: 'https://images-staging.zicasso.com/',
  ORYX_ENDPOINT: 'https://oryx-staging.zicasso.com',
  Z4_API: 'https://tapi-staging.zicasso.com/v3',
  ENV: 'staging',
  GOOGLE_CLIENT_ID: '914813737628-gb5aqgfqt1lb1l7nh16it6d9f4gh7obc.apps.googleusercontent.com',
}

const prod = {
  ADMIN_URL: 'https://admin.zicasso.com',
  API_URL: 'https://api.zicasso.com/v2',
  AUTH_API_URL: 'https://api.zicasso.com/v2/',
  D7_URL: 'https://d7.zicasso.com',
  SDK_URL: 'https://api.zicasso.com',
  Z3_URL: 'https://www.zicasso.com',
  IMAGES_ENDPOINT: 'https://images.zicasso.com/',
  ORYX_ENDPOINT: 'https://oryx.zicasso.com',
  Z4_API: 'https://tapi.zicasso.com/v3',
  ENV: 'prod',
  GOOGLE_CLIENT_ID: '914813737628-gb5aqgfqt1lb1l7nh16it6d9f4gh7obc.apps.googleusercontent.com',
}

const qa = {
  ADMIN_URL: 'https://admin-qa.zicasso.com',
  API_URL: 'https://api-qa.zicasso.com/v2',
  AUTH_API_URL: 'https://api-qa.zicasso.com/v2/',
  D7_URL: 'https://d7-qa.zicasso.com',
  SDK_URL: 'https://api-qa.zicasso.com',
  Z3_URL: 'https://www-qa.zicasso.com',
  IMAGES_ENDPOINT: 'https://images-qa.zicasso.com/',
  ORYX_ENDPOINT: 'https://oryx-qa.zicasso.com',
  Z4_API: 'https://tapi-qa.zicasso.com/v3',
  ENV: 'qa',
  GOOGLE_CLIENT_ID: '914813737628-gb5aqgfqt1lb1l7nh16it6d9f4gh7obc.apps.googleusercontent.com',
}

let config;

if (process.env.REACT_APP_STAGE === 'prod') {
  config = prod;
} else if (process.env.REACT_APP_STAGE === 'staging'){
  config = staging;
} else if (process.env.REACT_APP_STAGE === 'qa') {
  config = qa;
} else {
  config = dev;
}

export default {
  ...config
}
