/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CarouselProvider from '../carousel-provider';
import LightboxCarousel from './lightbox-carousel-view';
import { disableBodyScroll, enableBodyScroll } from '../../../utils/body-scroll-lock';

const THUMBNAIL_MARGIN = 16;
const THUMBNAIL_WIDTH = 100;

const LightBoxContainer = ({
  items,
  lightboxIndex,
  setShowLightbox,
}) => {
  const [zoomScale, setZoomScale] = useState(0.8);

  useEffect(() => {
    disableBodyScroll();
    const handleKeyboard = (event) => {
      //  Escape key
      if (event.keyCode === 27) {
        setShowLightbox(false);
      }
    };
    document.addEventListener('keydown', handleKeyboard);
    return () => {
      document.removeEventListener('keydown', handleKeyboard);
      enableBodyScroll();
    };
  }, [setShowLightbox]);


  const handleClickClose = () => setShowLightbox(false);

  const handleZoomPlus = () => {
    if (zoomScale < 1.0) {
      setZoomScale((z) => z + 0.1);
    }
  };

  const handleZoomMinus = () => {
    if (zoomScale > 0.6) {
      setZoomScale((z) => z - 0.1);
    }
  };

  return (
    <CarouselProvider
      items={items}
      keyboardNavigation
      lockScroll
      startIndex={lightboxIndex}
    >
      <LightboxCarousel
        handleZoomPlus={handleZoomPlus}
        handleZoomMinus={handleZoomMinus}
        onClickClose={handleClickClose}
        thumbnailMargin={THUMBNAIL_MARGIN}
        thumbnailWidth={THUMBNAIL_WIDTH}
        zoomScale={zoomScale}
      />
    </CarouselProvider>
  );
};
LightBoxContainer.propTypes = {
  currentBreakpoint: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  lightboxIndex: PropTypes.number.isRequired,
  setShowLightbox: PropTypes.func.isRequired,
};

export default LightBoxContainer;
