import React, { useEffect, useState } from 'react';
import ZAutoCompleteView from './z-autocomplete-view';
import useDebounce from '../../hooks/use-debounce';

const ZAutoCompleteContainer = ({
  allowCustomValues,
  autoComplete,
  disabled,
  error,
  inputKey,
  label,
  onSelect,
  placeholder,
  returnsObject,
  style,
}) => {
  const [inputText, setInputText] = useState('');
  const [options, setOptions] = useState([]);
  const [activeOption, setActiveOption] = useState(0);
  const [debouncedInputText] = useDebounce(inputText, 200);

  useEffect(() => {
    if (debouncedInputText) {
      autoComplete(debouncedInputText)
        .then((res) => {
          setOptions(res);
          setActiveOption(0);
        })
    } else {
      setOptions([]);
      setActiveOption(0);
    }
  }, [debouncedInputText]);

  const onInputChange = (text) => {
    setInputText(text);
  };

  const onOptionSelect = (val) => (e) => {
    setInputText('');
    setOptions([]);
    onSelect(val);
  };

  const onKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      let val;
      if (!returnsObject) {
        val = allowCustomValues && (options.length === 0 || inputText.toLowerCase() !== options[0].toLowerCase())
          ? inputText
          : options[activeOption];
      } else {
        const selectedOption = Object.keys(options)[activeOption];
        val = { [selectedOption]: options[selectedOption] }
      }
      setInputText('');
      setOptions([]);
      setActiveOption(0);
      onSelect(val);
    } else if (e.code === 'ArrowDown') {
      if (activeOption === options.length - 1) return;
      setActiveOption((prevActive) => prevActive + 1);
    } else if (e.code === 'ArrowUp') {
      if (activeOption === 0) return;
      setActiveOption((prevActive) => prevActive - 1);
    }
  };

  return (
    <ZAutoCompleteView
      activeOption={activeOption}
      disabled={disabled}
      error={error}
      inputKey={inputKey}
      inputText={inputText}
      label={label}
      onChange={onInputChange}
      onKeyDown={onKeyDown}
      onOptionSelect={onOptionSelect}
      options={options}
      placeholder={placeholder}
      returnsObject={returnsObject}
      style={style}
    />
  );
}

export default ZAutoCompleteContainer;
