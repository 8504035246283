import React from 'react';
import { fetchTuid } from '../../../utils/fetch/tuid';
import FaqSectionView from './faq-section-view';

const FaqSectionContainer = ({
  faqErrors,
  faqTitle,
  readOnly,
  sections,
  updateFaqs,
  updateTitle,
}) => {

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    const items = reorder(
      sections,
      result.oldIndex,
      result.newIndex,
    );
    updateFaqs(items);
  }

  const onDeleteFaq = (index) => {
    const faqList = [...sections];
    faqList.splice(index, 1);
    updateFaqs(faqList);
  }

  const addFaq = (index) => {
    const faqList = [...sections];
    fetchTuid().then(({id})=> {
      let defaultFaq = {
        id:id,
        title: '',
        text: ''
      }
      faqList.splice(index, 0, defaultFaq);
      updateFaqs(faqList);
    });
  }

  const updateFaq = (update) => {
    const updatedSections = sections.map(section => {
      if (section.id === update.id) {
        return {
          ...section,
          title: update.title !== undefined ? update.title : section.title,
          text: update.text !== undefined ? update.text : section.text,
        };
      }
      return section;
    });
  
    if (JSON.stringify(updatedSections) !== JSON.stringify(sections)) {
      updateFaqs(updatedSections);
    }
  }
   

  return (
    <FaqSectionView 
      addFaq={addFaq}
      faqErrors={faqErrors}
      faqs={sections}
      faqSectionTitle={faqTitle}
      onDeleteFaq={onDeleteFaq}
      onDragEnd={onDragEnd}
      readOnly={readOnly}
      updateFaq={updateFaq}
      updateTitle={updateTitle}
    />
  )
}

export default FaqSectionContainer;
