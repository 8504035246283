import React from 'react';
import { connect } from 'react-redux';
import OptimizationContainer from './optimization-container';
import {addUpstreamFooterSectionsT, createSectionT} from "../../../state/actions/shared/section";

const mapSectionIdsToSection = (ids, sections) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = sections[ele];
      return section;
    });
  }
}

const OptimizationRedux = (props) => (
  <OptimizationContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {articleId:id} = ownProps;
  const articleEntities = state.articles.article;
  const { sections, editor} = articleEntities;
  const currentArticle = editor[id];
  const { footerSectionIds, title } = currentArticle;
  return {
    id,
    footerSections: mapSectionIdsToSection(footerSectionIds, sections),
    title,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {articleId:id} = ownProps;
  return {
    onCreateSection: () => dispatch(createSectionT('article', id, false, 'FOOTER')),
    onFooterSearch: (sections) => dispatch(addUpstreamFooterSectionsT('article', id, sections)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptimizationRedux);
