import {
  BREADCRUMB_ACTION_NAMES,
} from '../actions/shared/breadcrumb-action-types';

const breadcrumbs = (
  state = {
    isFetching: false,
    error: false,
  },
  action,
) => {
  switch(action.type) {
    case BREADCRUMB_ACTION_NAMES.ARTICLE.SET_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.SET_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.LANDING_PAGE_CATEGORY.SET_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.CONTENT.SET_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.GUIDE.SET_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.ITINERARY.SET_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.SET_BREADCRUMBS:
      return {
        ...state,
        isFetching: false,
        [action.entityId]: {
          breadcrumbList: action.list,
        },
      };
    case BREADCRUMB_ACTION_NAMES.ARTICLE.REQUEST_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.REQUEST_BREADCRUMBS:
      case BREADCRUMB_ACTION_NAMES.LANDING_PAGE_CATEGORY.REQUEST_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.CONTENT.REQUEST_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.GUIDE.REQUEST_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.ITINERARY.REQUEST_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.REQUEST_BREADCRUMBS:
      return {
        ...state,
        isFetching: true,
      };
    case BREADCRUMB_ACTION_NAMES.ARTICLE.SET_BREADCRUMBS_ERROR:
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.SET_BREADCRUMBS_ERROR:
      case BREADCRUMB_ACTION_NAMES.LANDING_PAGE_CATEGORY.SET_BREADCRUMBS_ERROR:
    case BREADCRUMB_ACTION_NAMES.CONTENT.SET_BREADCRUMBS_ERROR:
    case BREADCRUMB_ACTION_NAMES.GUIDE.SET_BREADCRUMBS_ERROR:
    case BREADCRUMB_ACTION_NAMES.ITINERARY.SET_BREADCRUMBS_ERROR:
    case BREADCRUMB_ACTION_NAMES.LANDING_PAGE.SET_BREADCRUMBS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default: return state;
  }
};

export default breadcrumbs;
