import React from 'react';
import style from './oryx-table.module.scss';

const defaultProps = {
  className: '',
};

const OryxTableView = ({
  className,
  children,
}) => {
  const [head, ...body] = children;
  return (
    <table className={`${className} ${style.table}`}>
      <thead className={style.table__head}>
        {head}
      </thead>
      <tbody className={style.table__body}>
        {body}
      </tbody>
    </table>
  );
};

OryxTableView.defaultProps = defaultProps;

export default OryxTableView;
