import React from 'react';
import NewsSnippet from './news-snippet';
import ZSpinner from '../z-spinner';
import ZModal from '../z-modal';
import FilterDisplayed from '../shared/components/filter-displayed';
import ZRadioGroup from '../z-radio-group';
import FiltersSource from './filters-news-source';
import NewsSnippetEditor from './news-snippet-editor';
import NewsSourceEditor from './news-source-editor';
import sharedStyle from '../shared/styles/shared.module.scss';
import style from './news-page.module.scss';

const NewsView = ({
  closeModal,
  displayed,
  editNewsSnippetId,
  editNewsSourceId,
  isFetching,
  newsSnippets,
  onClickCreateSnippet,
  onClickDisplayed,
  onClickEdit,
  onClickNewsSourceFilter,
  onClickScreenSize,
  onClickValid,
  screenSize,
  setEditNewsSourceId,
  showFilters,
  toggleShowFilters,
  valid,
}) => (
  <>
    <div className={style['news__wrapper']}>
      <header className={style['news__header']}>
        <h1 className={style['news__title']}>Zicasso in the News</h1>
        <div className={style['filters']}>
          <button className={style['filters-button']} onClick={onClickCreateSnippet} type="button">
            Create New
          </button>
          <FilterDisplayed
            onChange={onClickDisplayed}
            value={displayed}
          />
          <ZRadioGroup
            className={style['filters__radio']}
            name="valid"
            onChange={onClickValid}
            options={['yes', 'no', 'all']}
            value={valid}
          >
            Valid:
          </ZRadioGroup>
          <button className={style['filters-button']} onClick={toggleShowFilters} type="button">
            Filters
            <div className={style['filters-button__arrow']} />
          </button>
          <ZRadioGroup
            className={style['filters__radio']}
            name="device"
            onChange={onClickScreenSize}
            options={['desktop', 'tablet', 'mobile']}
            value={screenSize}
          >
            Device:
          </ZRadioGroup>
          <p className={style['filters__total']}>{newsSnippets.length} article{newsSnippets.length === 1 ? '' : 's'}</p>
        </div>
      </header>
      <main className={`${style['news']} ${style[screenSize]}`}>
        <div className={style['snippets']}>
          {isFetching && (
            <div className={sharedStyle['spinner']}>
              <ZSpinner/>
            </div>
          )}
          {newsSnippets.map((snippet) => (
            <NewsSnippet
              key={snippet.id}
              onClick={() => onClickEdit(snippet)}
              openInEditor={snippet.id === editNewsSnippetId}
              screenSize={screenSize}
              {...snippet}
            />
          ))}
        </div>
      </main>
      <FiltersSource
        onClickNewsSourceFilter={onClickNewsSourceFilter}
        showFilters={showFilters}
        toggleShowFilters={toggleShowFilters}
      />
    </div>
    <ZModal
      closeable
      open={!!editNewsSnippetId}
      style={{ width: '770px', minHeight: '662px' }}
      toggleModal={closeModal}
      useOverlay
    >
      <NewsSnippetEditor
        id={editNewsSnippetId}
        key="newsSnippetEditor"
        closeModal={closeModal}
        onClickEdit={onClickEdit}
        setEditNewsSourceId={setEditNewsSourceId}
      />
      {editNewsSourceId && (
        <NewsSourceEditor
          closeModal={() => closeModal('newsSource')}
          id={editNewsSourceId}
          key="newsSourceEditor"
          newsSnippetId={editNewsSnippetId}
        />
      )}
    </ZModal>
  </>
);

export default NewsView;
