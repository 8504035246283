import React from 'react';
import Metatags from './meta-tags';
import Paths from '../../shared/components/paths';
import Breadcrumbs from './breadcrumbs';
import Appearancce from './appearance';

const OptimizationView = ({
  entityId,
  title,
}) => (
  <>
    <Paths
      entityId={entityId}
      entityTitle={title}
      entityType="Content"
      pathPrefix=""
    />
    <br/>
    <Metatags
      entityId={entityId}
    />
    <br/>
    <Breadcrumbs
      entityId={entityId}
    />
    <Appearancce
      entityId={entityId}
    />
    <br/>
  </>

);

export default OptimizationView;
