import React, { useRef } from 'react';
import ContentView from './content-view';


const ContentContainer = ({
  onSummaryChange, 
  onAddSectionClick, 
  onRemoveSectionClick,
  onSectionTitleChange, 
  entity,
  sections,
  onSectionTextChange,
  onSectionOrderChange,
  onChangePhoto,
  onCreateHeaderPhoto,
  onCreateSectionPhoto,
  onDeleteHeaderPhoto,
  onDeleteSectionPhoto,
  photos,
  readOnly,
}) => {
  const targetEle = useRef();

  const onTopArticleClick = () => {
    onAddSectionClick();
    const current = targetEle.current;
    const position = (current.getBoundingClientRect().top + window.pageYOffset) + 1500;
    window.scrollTo({
      top: position,
      behavior: 'smooth',
    });
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    let items = reorder(
      entity.sectionIds,
      result.oldIndex,
      result.newIndex
    );
    
    onSectionOrderChange(items);
  }

  const addSection = () => {
    onAddSectionClick();
  }

  return(
    <ContentView
      id={entity.id}
      onSummaryChange={onSummaryChange}
      onAddSectionClick={onAddSectionClick}
      summary={entity.summary}
      sections={sections}
      headerPhotoId={entity.headerPhotoId}
      onSectionTitleChange={onSectionTitleChange}
      onSectionTextChange={onSectionTextChange}
      onRemoveSectionClick={onRemoveSectionClick}
      onDragEnd={onDragEnd}
      addSection={addSection}
      onTopArticleClick={onTopArticleClick}
      targetEle={targetEle}
      onChangePhoto={onChangePhoto}
      onCreateHeaderPhoto={onCreateHeaderPhoto}
      onCreateSectionPhoto={onCreateSectionPhoto}
      onDeleteHeaderPhoto={onDeleteHeaderPhoto}
      onDeleteSectionPhoto={onDeleteSectionPhoto}
      photos={photos}
      readOnly={readOnly}
    />
  );
}

export default ContentContainer;
