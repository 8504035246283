// Inspiration from react-infinite-scroll-hook
// https://github.com/onderonur/react-infinite-scroll-hook
import {
  useEffect,
  useRef,
  useState,
} from 'react';

const useInfiniteScroll = (loading, onLoadMore) => {
  const [atBottom, setAtBottom] = useState(false);
  const scrollPositionAtLastLoad = useRef(0);

  const loadMore = !loading && atBottom;

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight } = document.documentElement;

      if (scrollTop + window.innerHeight + 500 >= scrollHeight
         && Math.abs(scrollTop - scrollPositionAtLastLoad.current) > 600) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (loadMore) {
      onLoadMore();
      scrollPositionAtLastLoad.current = document.documentElement.scrollTop;
    }
  }, [loadMore]);
};

export default useInfiniteScroll;