import React from 'react';
import TeamMember from './team-member';
import ZSpinner from '../z-spinner';
import ZModal from '../z-modal';
import FilterDisplayed from '../shared/components/filter-displayed';
import TeamMemberEditor from './team-member-editor';
import sharedStyle from '../shared/styles/shared.module.scss';
import style from './team-page.module.scss';

const TeamPageView = ({
  closeModal,
  displayed,
  editTeamMemberId,
  isFetching,
  teamMembers,
  onClickCreateTeamMember,
  onClickDisplayed,
  onClickEdit,
}) => (
  <>
    <div className={style['team__wrapper']}>
      <header className={style['team__header']}>
        <h1 className={style['team__title']}>Our Team</h1>
        <div className={style['filters']}>
          <button className={style['filters-button']} onClick={onClickCreateTeamMember} type="button">
            Create New
          </button>
          <FilterDisplayed
            onChange={onClickDisplayed}
            value={displayed}
          />
          { teamMembers && 
            <p className={style['filters__total']}>
              {teamMembers.length} team member{teamMembers.length === 1 ? '' : 's'}
            </p>
          }
          
        </div>
      </header>
      <main className={style['team']}>
        <div className={style['team-members']}>
          {isFetching && (
            <div className={sharedStyle['spinner']}>
              <ZSpinner/>
            </div>
          )}
          {teamMembers && teamMembers.map((teamMember) => (
            <TeamMember
              key={teamMember.id}
              onClick={() => onClickEdit(teamMember)}
              openInEditor={teamMember.id === editTeamMemberId}
              {...teamMember}
            />
          ))}
        </div>
      </main>
    </div>
    <ZModal
      closeable
      open={!!editTeamMemberId}
      style={{ 
        width: '900px',
        minHeight: '662px',
      }}
      toggleModal={closeModal}
      useOverlay
    >
      <TeamMemberEditor
        id={editTeamMemberId}
        key="teamMemberEditor"
        closeModal={closeModal}
        onClickEdit={onClickEdit}
      />
    </ZModal>
  </>
);

export default TeamPageView;
