import React from 'react';
import ZInput from '../z-input';
import styles from './z-autocomplete.module.scss';

const ZAutoCompleteView = ({
  activeOption,
  disabled,
  error,
  inputKey,
  inputText,
  label,
  onChange,
  onKeyDown,
  onOptionSelect,
  options,
  placeholder,
  returnsObject,
  style,
}) => (
  <div key={inputKey}>
    <div style={{width: '100%', position: 'relative', ...style}} onKeyDown={onKeyDown}>
      <ZInput
        label={label}
        text={inputText}
        onChange={onChange}
        inputKey={inputKey}
        error={error}
        placeholder={placeholder}
        disabled={disabled}
      />
      { returnsObject ?
        <>
        {(options && Object.keys(options).length > 0) &&
          <div className={`${styles['options-container']}`}>
            {Object.keys(options).map((key,i) => {
              return (
                <div className={activeOption === i ? [`${styles.options} ${styles.activeOption}`]: styles['options']}
                    key={`${options[key]}-${i}`}
                    onClick={onOptionSelect({ [key]: options[key] })}>
                    {options[key]}
                </div>
              )
            })}
          </div>
        }
        </>
      :
        <>
        {(options && options.length > 0) &&
          <div className={`${styles['options-container']}`}>
            {options.map((ele, i)=>{
              return (
                <div
                  className={activeOption === i ? [`${styles.options} ${styles.activeOption}`]: styles['options']}
                  key={`${ele}-${i}`}
                  onClick={onOptionSelect(ele)}>{ele}</div>
              )
            })}
          </div>
        }
        </>
      }
    </div>
  </div>
);

export default ZAutoCompleteView;
