import { useEffect, useState } from "react";

/**
 * Takes a list of entities and dedups then.
 * Entites must include an id.
 * @param {object[]} entities 
 * @returns {array}
 */
const useEntityCollection = (entities) => {
  const [dedupEntites, setDedupEntites] = useState([]);

  useEffect(() => {
    const seen = {};
    const res = [];
    for (let i = 0; i < entities.length; i++) {
      const element = entities[i];
      if (!seen[element.id]){
        seen[element.id] = true;
        res.push(element);
      }
    }
    setDedupEntites(res);
  }, [entities]);

  return dedupEntites;
}

export default useEntityCollection;
