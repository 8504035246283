import React from 'react';
import ContentView from './content-view';
import { decodeHTMLEntities } from '../../../utils/window';
import ZApiClient from '../../../utils/client/z-api-client';

const ContentContainer = ({
  onSummaryChange, 
  onAddSectionClick, 
  onRemoveSectionClick,
  guide, 
  sections,
  onSectionOrderChange,
  readOnly,
  photos,
  onChangePhoto,
  onCreateHeaderPhoto,
  onDeleteHeaderPhoto,
  onEssentialInfoChange,
  onHelpfulInfoChange,
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if(!readOnly){
      let items = reorder(
        guide.sectionIds,
        result.oldIndex,
        result.newIndex
      );
      
      onSectionOrderChange(items);
    }
  }

  const autoComplete = (text) => {
    return ZApiClient
    .getArticlesClient()
    .getAllTitlesWithIdsByNameFragment(text, { limit: 10, published: true })
      .then((res)=> Object.keys(res)
        .filter(key => {
            if (guide.articles) {
              for (let i = 0; i < guide.articles.length; i++) {
                if ( guide.articles[i].id === key ) {
                  return false;
                }
              }
            }
          return key;
        })
        .reduce((obj, key) => {
          obj[key] = decodeHTMLEntities(res[key]);
          return obj;
        }, {}));
  }

  const autoCompleteOnSelect = (val) => {
    if (Object.keys(val ? val : {}).length > 0) {
      ZApiClient
      .getArticlesClient()
      .getSnippet(Object.keys(val)[0])
        .then((res)=> {
          onAddSectionClick(res);
        });
    }
  }

  const onChangeEssentialInfo = (e) => {
    e.preventDefault();
    const {
      target: {value}
    } = e;
    onEssentialInfoChange(value);
  }

  const onChangeHelpfulInfo = (e) => {
    e.preventDefault();
    const {
      target: {value}
    } = e;
    onHelpfulInfoChange(value);
  }

  return(
    <>
    { guide && (
      <ContentView
        id={guide.id}
        autoComplete={autoComplete}
        autoCompleteOnSelect={autoCompleteOnSelect}
        sections={sections}
        summary={guide.summary}
        onSummaryChange={onSummaryChange}
        onRemoveSectionClick={onRemoveSectionClick}
        onDragEnd={onDragEnd}
        readOnly={readOnly}
        photos={photos}
        headerPhotoId={guide.headerPhotoId}
        onChangePhoto={onChangePhoto}
        onCreateHeaderPhoto={onCreateHeaderPhoto}
        onDeleteHeaderPhoto={onDeleteHeaderPhoto}
        essentialInfo={guide.primaryLinksTitle}
        onChangeEssentialInfo={onChangeEssentialInfo}
        helpfulInfo={guide.helpfulContentTitle}
        onChangeHelpfulInfo={onChangeHelpfulInfo}
      />
    )}
    </>
  );
}

export default ContentContainer;
