import React from 'react';
import { connect } from 'react-redux';
import CustomItineraryContainer from './custom-itinerary-container';
import {
  setDays,
} from '../../../state/actions/itinerary-editor';
import {receivedSectionsT, updateSectionT} from "../../../state/actions/shared/section";

const mapIdsToDays = (idList, sectionState) => {
  const ret = [];
  for(const id of idList){
    if(sectionState[id]) ret.push(sectionState[id]);
  }
  return ret;
}

const CustomItineraryRedux = (props) => {
  return (
    <CustomItineraryContainer {...props}/>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const { errors } = state;
  const { editor, sections } = state.itineraries.itinerary;
  const currentEntity = editor[id] || {};
  const currentErrors = errors[id] || {};
  const { dayIds } = currentEntity;
  const {
    days: dayErrors,
  } = currentErrors;
  const mappedDays = mapIdsToDays(dayIds, sections);
  return {
    days: mappedDays,
    dayErrors,
    readOnly: state.versions.readOnly,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    reorderDays: (dayIds, daySections) => {
      dispatch(setDays(id, dayIds));
      dispatch(receivedSectionsT('itinerary', daySections));
    },
    updateDay: (day) => dispatch(updateSectionT('itinerary', day)),
    durationChange: (sections) => dispatch(receivedSectionsT('itinerary', sections)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomItineraryRedux);
