export const CLEAR_TITLE_ERROR = (T) => `CLEAR_TITLE_ERROR_${T.toUpperCase()}`;
export const SET_TITLE_ERROR = (T) => `SET_TITLE_ERROR_${T.toUpperCase()}`;

export const ERROR_ACTION_NAMES = {};

(() => {
    [
        'article',
        'company_category',
        'landing_page_category',
        'content',
        'guide',
        'itinerary',
        'landing_page'
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        ERROR_ACTION_NAMES[entityType] = {};
        ERROR_ACTION_NAMES[entityType]['CLEAR_TITLE_ERROR_'] = CLEAR_TITLE_ERROR(entityType);
        ERROR_ACTION_NAMES[entityType]['SET_TITLE_ERROR'] = SET_TITLE_ERROR(entityType);
    });
})();
