import React from 'react';
import styles from './oryx-arrow-button.module.scss';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const ZToTopButton = () => (
  <div
    aria-label="Go back to top of results"
    className={styles.button}
    onClick={scrollToTop}
    onKeyDown={(e) => { if (e.code === 'Enter') scrollToTop(); }}
    role="button"
    tabIndex={0}
  />
);

export default ZToTopButton;
