import { push } from 'connected-react-router';
import GuideClient from '../../../utils/client/guide';
import { fetchTuid } from '../../../utils/fetch/tuid';
import {
  receivedPhotosT,
} from '../shared/photo';
import {
  receivedSectionsT
} from '../shared/section';
import {
  getCountriesT
} from "../shared/location";
import {
  fetchEntityVersion,
  addSavedEntityVersion,
  versionSuccess,
} from '../versions';
import ZApiClient from '../../../utils/client/z-api-client';
import { Guide } from '@zicasso/zicasso-sdk/lib/guide';

import {
  REQUEST_GUIDE,
  REQUEST_NEW_GUIDE,
  RECEIVED_GUIDE,
  GUIDE_SAVE_REQUESTED,
  GUIDE_SAVED,
  REQUEST_GUIDE_FAILED,
} from '../shared/similar-action-types';

const guideClient = new GuideClient();

const requestGuide = () => ({
  type: REQUEST_GUIDE,
});

export const requestNewGuide = () => ({
  type: REQUEST_NEW_GUIDE,
});

const receivedGuide = (data) => ({
  type: RECEIVED_GUIDE,
  receivedAt: Date.now(),
  id: data.id,
  guide: data,
});

export const saveRequested = () => ({
  type: GUIDE_SAVE_REQUESTED,
});

export const guideSaved = () => ({
  type: GUIDE_SAVED,
});

export const guideRequestFailed = () => ({
  type: REQUEST_GUIDE_FAILED,
});

export const redirectToCreate = () => {
  return dispatch => {
    dispatch(push('/create/guide'));
  }
};

function shouldFetchGuide(state, id, updateId) {
  const {editor} = state.guides.guide;
  if(!editor[id]) {
    return true;
  } else if (editor[id] && editor[id].updateId !== updateId) {
    return true;
  } else {
    return false;
  }
}

/**
 * Pass the result of guide client getState method;
 * @param {object} guideState
 */
const receiveGuideState = (guideState) => {
  return dispatch => {
    let {id:currentId} = guideState;
    dispatch(receivedPhotosT('guide', guideState.photos));
    dispatch(receivedSectionsT('guide', guideState.sections));
    dispatch(receivedGuide(guideState[currentId]));
  }
};

/**
 *
 * @param {string} id
 */
export const fetchGuideIfNeeded = (id, updateId) => {
  return (dispatch, getState) => {
    const { countries } = getState().guides.guide.editor;
    const shouldFetch = shouldFetchGuide(getState(), id, updateId);

    if (!countries || countries.length === 0)  {
      dispatch(getCountriesT('guide'));
    }
    if(shouldFetch){
      dispatch(fetchGuide(id, updateId));
    }
  }
};

export const fetchGuide = (id, updateId) => {
  return dispatch => {
    dispatch(requestGuide());
    const req = !updateId
      ? ZApiClient.getGuidesClient().getGuide(id)
      : ZApiClient.getGuidesClient().getGuideVersion(id, updateId);

    return req.then((res)=>{
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return guideClient.getState(res);
    }).then((newState)=>{
      dispatch(receiveGuideState(newState));
    }).catch((e)=>{
      if(e.message === 'NOT_FOUND'){
        dispatch(guideRequestFailed());
      }
      console.error('Error Fetching Guide', e);
    })
  };
};

export const saveGuide = (guideId) => {
  return (dispatch, getState) => {
    dispatch(saveRequested());
    ZApiClient
    .getGuidesClient()
    .exists(guideId)
    .then((res)=>{
      const shouldCreate = !res;
      const currentState = getState();
      const {guide} = currentState.guides;
      const guideEntity = guideClient.getGuide(guideId, guide);
      return createOrUpdateGuide(guideEntity, shouldCreate);
    }).then((result)=>{
      dispatch(guideSaved());
      const { id, updateId, userId, updatedAt } = result;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return guideClient.getState(result);
    }).then((newState)=>{
       dispatch(receiveGuideState(newState));
    }).catch((e)=>{
      console.error('Error Saving Guide', e);
      console.error(e.stack);
    });
  };
};

export const makeLatest = (id, updateId) => (dispatch) => {
  dispatch(requestGuide());
  return ZApiClient
  .getGuidesClient()
  .getGuideVersion(id, updateId)
    .then((res) => {
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return createOrUpdateGuide(res, false);
    })
    .then((res) => {
      const { id, updateId, userId, updatedAt } = res;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return guideClient.getState(res);
    })
    .then((newState) => {
      dispatch(receiveGuideState(newState));
    })
    .catch((e) => {
      if(e.message === 'NOT_FOUND'){
        dispatch(guideRequestFailed());
      }
      console.error('Error Fetching Guide', e);
    });
};

const createOrUpdateGuide = (data, create = true) =>{
  if(create){
    return ZApiClient.getGuidesClient().createGuide(new Guide(data));
  } else {
    return ZApiClient.getGuidesClient().updateGuide(new Guide(data));
  }
};


export const createNewGuide = () => {
  return dispatch => {
    dispatch(requestNewGuide());
    return fetchTuid()
      .then(val => {
        let {id} = val;
        dispatch(receivedGuide({
          primaryLinksTitle: '',
          helpfulContentTitle: '',
          id : id,
          title: null,
          summary: '',
          sectionIds: [],
          metatags: {},
          themes: []
        }));
        dispatch(push(`/guides/${id}`));
      })
  }
};

export const fetchGuideVersion = (id, versionId) => (dispatch) => {
  dispatch(fetchEntityVersion('guides', id, versionId))
    .then(() => {
      dispatch(versionSuccess(id, versionId));
    })
    .catch(console.error);
};
