import React from 'react';
import ZTextArea from '../../../z-text-area';
import ZInput from '../../../z-input';
import style from './teaser.module.scss';

const TeaserView = ({
  cta,
  onCTATitleUpdate,
  onCTAStepsUpdate,
}) => {
  return (
    <>
      <h2>CTA Teaser</h2>
      <div className={style['cta-box']}>
        <br/>
        <ZInput 
          label="Cta Box Title" 
          onUpdate={onCTATitleUpdate}
          initialValue={cta.title}
          error={null}
        />
        <ol>
          { [1,2,3].map((d,i) => (
            <li
              key={`teaser-text-${i}`}
            >
             <ZTextArea 
               allowedFormats={['BOLD', 'ITALICS']} 
               onUpdate={(v)=>onCTAStepsUpdate(v,i)}
               value={cta.steps[i]}
               stripTags
             />
           </li>
          ))}
        </ol>
      </div>
    </>
  )
};

export default TeaserView;
