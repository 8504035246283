import React from 'react';
import ZInput from '../z-input';
import style from './z-photo-preview.module.scss';
import { ReactComponent as CopySVG } from '../../assets/logos/copy.svg';
import { onCopy } from '../../utils/window';
import ZDeleteButton from '../z-button-delete';
import GravityDropdownView from '../gravity-dropdown';
import ZImage from '../z-image';

const placeHolder = 'https://res.cloudinary.com/zicasso/image/upload/v1545258210/Logo-%20Marketing%20Materials/PNG/Zicasso_Logo_no_sub_black_small.png';

const ZPhotoPreviewView = ({
  alt,
  column,
  gravity,
  info,
  isValid,
  onChange,
  photoId,
  readOnly,
  removePhoto,
  showInfo,
  src,
  styles,
  title,
  simpleView,
  transformation,
}) => {
  const imgSRC = isValid ? src : placeHolder;
  const columnStyle = column ? style['photo-preview-column'] : '';

  return (
    <div
      className={`${style['photo-preview']} ${columnStyle}`}
      style={styles}
    >
      <div className={`${style['photo-preview__photo']}`}>
        <ZImage
          alt={alt}
          angle={transformation.angle}
          aspectRatio={transformation.aspectRatio}
          cloudinarySrc={imgSRC}
          crop={transformation.crop}
          effect={transformation.effect}
          gravity={gravity}
          height={transformation.height}
          quality={transformation.quality}
          width={transformation.width}
        />
      </div>
      <div
        className={removePhoto ? `${style['photo-preview__input']}` : `${style['photo-preview__input']} ${style['photo-preview__input--hero']}`}
        style={simpleView ? {display: 'none'} : {}}
      >
        <ZInput
          error={(src && !isValid) && { message: 'Please use an image from the Photo App.' }}
          label="Paste A Url"
          style={{width: '100%', marginBottom: '54px'}}
          text={src}
          onChange={onChange('uri')}
          readOnly={readOnly && readOnly.src ? readOnly.src : false}
          inputKey={`${photoId}__url`}
        />
        <div className={`${style['photo-preview__meta']}`}>
          <ZInput
            label='Alt Text'
            text={alt}
            style={{width: '100%', marginRight: '7px', borderLeft: '1px solid #bdbdbd'}}
            onChange={onChange('alt')}
            inputKey={`${photoId}__alt`}
            readOnly={readOnly && readOnly.alt ? readOnly.alt : false}
          />
          <ZInput
            label='Title Text'
            text={title}
            style={{width: '100%', borderLeft: '1px solid #bdbdbd'}}
            onChange={onChange('title')}
            inputKey={`${photoId}__title`}
            readOnly={readOnly && readOnly.title ? readOnly.title : false}
          />
          { gravity && (
            <GravityDropdownView
              gravity={gravity}
              imgSrc={imgSRC}
              onChange={onChange}
              photoId={photoId}
              readOnly={readOnly}
            />
          )}
        </div>
        {showInfo &&
          <div className={`${style['info']}`}>
            <p className={`${style['info__field']}`}>{`Date Added: ${info.createdAt}`}</p>
            <p className={`${style['info__field']}`}>{`Dimensions (H x W): ${info.height} x ${info.width}`}</p>
            <p className={`${style['info__field']}`}>
              Source:
              <br/>
              <a href={info.sourceUri} target="_blank" rel="noopener noreferrer">{info.sourceUri}</a>
            </p>
            {info.sourceType === 'Cloudinary' &&
              <p className={`${style['info__copy']}`} onClick={() => onCopy(info.fileName)}>
                  Copy File Name <CopySVG/>
              </p>
            }
          </div>
        }
      </div>
      {removePhoto &&
        <div className={style['photo-preview--remove']}>
          <ZDeleteButton onClick={removePhoto} />
        </div>
      }
    </div>
  )
}

export default ZPhotoPreviewView;
