import React from 'react';
import { connect } from 'react-redux';
import SubnavContainer from './subnav-container';
import {
  updateSubnav,
  deleteSubNav
} from '../../../state/actions/landing-page-editor';

const getUriFromUrl = (str) => {
  if(str && str.indexOf('www') !== -1){
    const url = new URL(str)
    return url.pathname
  } else {
    return '/'
  }
}

const getLinks = (links) => {
  if(!links) return [{},{},{},{},{}];
  if(links && links.length === 5) return links;
  const [nonCCP, CCP] = links.reduce((acc, cur) => {
    if(cur.entityType !== 'CompanyCategory') acc[0].push(cur);
    if(cur.entityType === 'CompanyCategory') acc[1] = cur;
    return acc;
  }, [[],{}]);
  const ret = [];
  for(let i = 0, max = 4; i < max; i++){
    const current = nonCCP[i] ? nonCCP[i] : {}
    ret.push(current);
  }
  ret.push(CCP);
  return ret;
}

const mapStateToProps = (state, ownProps) =>{
  const {entityId:id} = ownProps;
  const { editor } = state.landingPages.landingPage;
  const currentEntity = editor[id] || {};
  const currentSubnav = currentEntity.navigationBar || {};
  const metatags = currentEntity.metatags || {};
  const entityUri = getUriFromUrl(metatags.canonicalUrl);
  return {
    id,
    entityUri,
    subnavLinks: getLinks(currentSubnav.links),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    updateSubnav : (list) => dispatch(updateSubnav(id, list)),
    deleteSubnav : () => dispatch(deleteSubNav(id)),
  }
}

const SubnavRedux = (props) => (
  <SubnavContainer
    {...props}
  />
);

export default connect(mapStateToProps, mapDispatchToProps)(SubnavRedux);
