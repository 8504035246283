export default class LocalStorageService {

  supportsLocalStorage() {
    return !!(typeof window !== 'undefined' && window.localStorage);
  };

  hasExpired(key) {
    if (this.supportsLocalStorage()) {
      let item;
      try {
        item = JSON.parse(window.localStorage.getItem(key));
      } catch (err) {
        item = window.localStorage.getItem(key);
      }

      if (item && item.ttl && (item.ttl < Date.now())) {
        return true;
      }
    }

    return false;
  };

  getFromLocalStorage(key, initialVal) {
    try {
      if (!this.supportsLocalStorage()) {
        return initialVal;
      }

      let item;
      try {
        item = JSON.parse(window.localStorage.getItem(key));
      } catch (err) {
        item = window.localStorage.getItem(key);
      }

      return (item && item.data) || initialVal;
    } catch (err) {
      console.error(err);
      return initialVal;
    }
  };

  addToLocalStorage(key, val, ttl = null) {
    try {
      if (this.supportsLocalStorage()) {
        window.localStorage.setItem(key, JSON.stringify({
          ttl: Date.now() + (ttl || (((60 * 60 * 24) * 30) * 1000)),
          data: val,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  removeFromLocalStorage(key) {
    if (this.supportsLocalStorage()) {
      window.localStorage.removeItem(key);
    }
  };

  RefreshLocalStorage()  {
    //  Remove all expired items
    if (this.supportsLocalStorage()) {
      Object.keys(window.localStorage).forEach((key) => {
        if (this.hasExpired(key)) {
          this.removeFromLocalStorage(key);
        }
      });
    }
    return null;
  };
}
