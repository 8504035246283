import React from 'react';
import DetailsView from './details-view';

const DetailsContainer = ({
  itinerary,
  highlightsErrors,
  onUpdateHighlights,
  onUpdateDescription,
}) => {
  return (
    <DetailsView
      highlights={itinerary.highlights}
      highlightsErrors={highlightsErrors}
      onUpdateHighlights={onUpdateHighlights}
      description={itinerary.description}
      onUpdateDescription={onUpdateDescription}
    />
  )
}

export default DetailsContainer;
