import React from 'react';
import style from './z-table-row.module.scss';
import sharedStyle from '../../shared/styles/shared.module.scss';

const ZTableRow = (props) => {
  const rowData = mapRowData(props.legend, props.data);
  return (
    <div className={style['z-table-row']} style={props.rowStyle}>
      {rowData.map((ele, i) => (
        <span
          className={style['z-table-row__item']} 
          style={props.rowItemStyle}
          key={`${ele}-${i}`}
        >
          {ele ? <div dangerouslySetInnerHTML={{ __html: ele }}/> : '-'}
        </span>
      ))}
      {props.actions &&  
        <span className={style['z-table-row__actions']} style={props.rowItemStyle}>
          {Object.keys(props.actions).map((action, i) => (
            <button
              type='button'
              onClick={props.onActionClick(action, props.data)}
              key={`${action}-${i}`}
              className={[`${sharedStyle['button']} ${style['button']}`]}
              style={{ maxHeight: '25px', maxWidth: '80px', margin: '7px 10px' }}
            >
              {action}
            </button>
          ))}
        </span>
      } 
    </div>
  )
};

const mapRowData = (legend, data) => {
  let resp = legend.map((ele) => data[ele]);
  return resp;
};

export default ZTableRow;
