import {
  fetchEntityPublications as feps,
} from '../../utils/publications/publications-utils';

export const PUBLICATIONS_REQUEST = 'PUBLICATIONS_REQUEST';
export const publicationsRequest = () => ({
  type: PUBLICATIONS_REQUEST,
});

export const PUBLICATIONS_SUCCESS = 'PUBLICATIONS_SUCCESS';
export const publicationsSuccess = (entityId, json, offset) => ({
  type: PUBLICATIONS_SUCCESS,
  entityId,
  json,
  offset,
  receivedAt: Date.now(),
});

export const PUBLICATIONS_ERROR = 'PUBLICATIONS_ERROR';
export const publicationsError = () => ({
  type: PUBLICATIONS_ERROR,
});

export const PUBLICATIONS_LOAD_MORE_SUCCESS = 'PUBLICATIONS_LOAD_MORE_SUCCESS';
export const publicationsLoadMoreSuccess = (entityId, json, offset) => ({
  type: PUBLICATIONS_LOAD_MORE_SUCCESS,
  entityId,
  json,
  offset,
  receivedAt: Date.now(),
});

export const PUBLICATION_REQUEST = 'PUBLICATION_REQUEST';
export const PUBLICATION_SUCCESS = 'PUBLICATION_SUCCESS';
export const PUBLICATION_ERROR = 'PUBLICATION_ERROR';
export const TOGGLE_READ_ONLY = 'TOGGLE_READ_ONLY';

export const fetchEntityPublications = (entityId, limit = 50) => (dispatch, getState) => {
  const { publications } = getState();
  const offset = publications[entityId] ? publications[entityId].offset : null;

  dispatch(publicationsRequest(entityId));
  return feps(entityId, offset, limit)
    .then((json) => {
      if (json.httpStatus >= 400) dispatch(publicationsError(entityId));
      else {
        const { length } = json;
        if (length > 0) {
          const { updateId, id } = json[length - 1];
          let newOffset = length === limit && id !== updateId ? updateId : null;
          if (!offset) dispatch(publicationsSuccess(entityId, json, newOffset));
          if (!!offset) dispatch(publicationsLoadMoreSuccess(entityId, json, newOffset));
        } else {
          dispatch(publicationsSuccess(entityId, json, null));
        }
      }
    })
    .catch((e) => {
      console.error(e);
      dispatch(publicationsError(entityId));
    });
};
