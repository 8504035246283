import React from 'react';
import { connect } from 'react-redux';
import CountriesContainer from './countries-container';
import {addLocationT, setLocationsT} from "../../../../state/actions/shared/location";

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const guideEntities = state.guides.guide;
  const { editor } = guideEntities;
  const { countries } = editor;
  const currentEntity = editor[id];
  const {title, locations } = currentEntity;
  return {
    id,
    title,
    countries,
    locations
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    onCountrySelect: (country) => dispatch(addLocationT('guide', country, id)),
    onCountryDelete: (countryList) => dispatch(setLocationsT('guide', countryList, id)),
  }
}

const CountriesRedux = (props) => (
  <CountriesContainer {...props} /> 
);

export default connect(mapStateToProps, mapDispatchToProps)(CountriesRedux);
