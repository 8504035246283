import React from 'react';
import ZAutoComplete from '../../../z-autocomplete';
import ZChips from '../../../z-chips';

const CountriesView = ({
  countryAutoComplete,
  onCountrySelect,
  onCountryDelete,
  locations,
}) => (
  <div style={{ borderBottom: '2px solid rgba(255, 145, 0,0.5)', marginBottom: '14px' }}>
    <h3>Countries</h3>
    <ZAutoComplete
      autoComplete={countryAutoComplete}
      onSelect={onCountrySelect}
      label='Add a Destination Country'
      style={{marginBottom: '14px'}}
      inputKey={'overview__countries'}
    />
    {locations && locations.length !== 0 && 
      <ZChips 
        data={locations.map((ele)=>{return {label: ele}})} 
        style={{marginBottom: '14px'}} 
        onChange={onCountryDelete}
      />
    }
  </div>
);

export default CountriesView;
