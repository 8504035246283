import { connect } from 'react-redux';
import {
  fetchNewsSnippets,
  setNewsSnippet,
  createNew,
 } from '../../state/actions/news-editor/news-snippets';
import {
  fetchNewsSources,
  setNewsSource,
 } from '../../state/actions/news-editor/news-sources';
import NewsContainer from './news-page-container';

const mapStateToProps = (state) => ({
  isFetching: state.news?.newsSnippets.isFetching,
  newsSnippets: state.news?.newsSnippets.allNewsSnippets,
  news: state.news
});

const mapDispatchToProps = (dispatch) => ({
  createNew: () => dispatch(createNew()),
  onLoad: () => {
    dispatch(fetchNewsSnippets());
    dispatch(fetchNewsSources());
  },
  setNewsSnippet: (newsSnippet) => dispatch(setNewsSnippet(newsSnippet)),
  setNewsSource: (newsSource) => dispatch(setNewsSource(newsSource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsContainer);
