import React from 'react';
import { connect } from 'react-redux';
import {
  fetchItineraryVersion,
  makeLatest,
  validateEntityVersion,
} from '../../../state/actions/itinerary-editor';
import VersionsContainer from './versions-container';

const VersionsRedux = (props) => (
  <VersionsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const { editor } = state.itineraries.itinerary;
  const currentEntity = editor[id] || {};
  const {updateId:versionId} = currentEntity;

  return {
    saved: state.articles.article.editor.saved,
    published: state.published[id] && state.published[id].current,
    currentVersion: versionId,
  }
};

const mapDispatchToProps = (dispatch) => ({
  makeLatest: (id, version) => dispatch(makeLatest(id, version)),
  onFetchVersion: (id, version) => dispatch(fetchItineraryVersion(id, version)),
  validateEntityVersion: (id, version) => dispatch(validateEntityVersion(id, version)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionsRedux);
