import React, { useState, useEffect, useMemo } from 'react';
import ZPublishModalView from './z-publish-view';
import { validationErrorsPresent } from '../../utils/validation';

const ZPublishModalContainer = ({
  errors,
  publishErrors,
  entityId,
  entityType,
  modalState,
  onCloseModal,
  onPublish,
  onUnpublish,
  paths,
  showModal,
  usageReferences,
  usageReferencesError,
  versionToPublish,
}) => {
  const canonical = useMemo(() => {
    if (paths) return paths.find((path) => path.redirect === false);
    return ''
  }, [paths]);
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false);
  const [comment, setComment] = useState();
  const [significant, setSignificant] = useState(true);
  const [manuallyConfirmed, setManuallyConfirmed] = useState(false);
  const [pathConfirmed, setPathConfirmed] = useState(false);
  const [validationErrors, setValidationErrors] = useState();
  const [useLegacy, setUseLegacy ] = useState(true);

  useEffect(() => {
    // Handles guides, articles, and simple pages.
    if (errors && !publishErrors) {
      const errorsPresent = validationErrorsPresent(errors);
      if (validationErrors !== errorsPresent) setValidationErrors(errorsPresent);
    // Handles Landing Pages and Itinerareis.
    } else if (!errors && publishErrors) {
      setUseLegacy(false);
      if(publishErrors
        && Object.keys(publishErrors).length > 0
        && !publishErrors.isValid){
        setValidationErrors(true)
      } else {
        setValidationErrors(false);
      }
    }
  }, [errors, validationErrors, publishErrors]);

  useEffect(() => {
    if (errors) {
      const errorsPresent = validationErrorsPresent(errors);
      if (validationErrors !== errorsPresent) setValidationErrors(errorsPresent);
    }
  }, [errors, validationErrors]);

  useEffect(() => {
    let disableConfirm
    if (modalState === 'Publish') {
      disableConfirm = (
        validationErrors ||
        !pathConfirmed ||
        versionToPublish.isFetching ||
        versionToPublish.error
      );
    } else if (modalState === 'Unpublish') {
      disableConfirm = usageReferences;
    }
    if (manuallyConfirmed && pathConfirmed) {
      setConfirmBtnDisabled(false);
    } else if (confirmBtnDisabled !== disableConfirm) {
      setConfirmBtnDisabled(disableConfirm);
    }
  },
  [
    validationErrors,
    pathConfirmed,
    versionToPublish,
    confirmBtnDisabled,
    modalState,
    usageReferences,
    publishErrors,
    manuallyConfirmed,
  ]);

  const closeModal = () => {
    setManuallyConfirmed(false);
    onCloseModal();
  }

  const onClickConfirm = () => {
    if (modalState === 'Unpublish') onUnpublish({
      entityId,
      entityType,
      entityUpdateId: versionToPublish.updateId,
      comment,
    });
    if (modalState === 'Publish') onPublish({
      entityId,
      entityType,
      entityUpdateId: versionToPublish.updateId,
      comment,
      significant,
    });
    closeModal();
  };

  return (
    <ZPublishModalView
      canonical={canonical}
      confirmBtnDisabled={confirmBtnDisabled}
      error={versionToPublish.error}
      errors={useLegacy ? errors : publishErrors }
      loading={versionToPublish.isFetching}
      manuallyConfirmed={manuallyConfirmed}
      maxHeight={modalState === 'Unpublish' ? '500px' : '75vh'}
      modalState={modalState}
      onClickConfirm={onClickConfirm}
      onCloseModal={closeModal}
      pathConfirmed={pathConfirmed}
      setComment={setComment}
      setPathConfirmed={setPathConfirmed}
      setSignificant={() => setSignificant((i) => !i)}
      showModal={showModal}
      significant={significant}
      usageReferences={usageReferences}
      usageReferencesError={usageReferencesError}
      validationErrors={validationErrors}
      setManuallyConfirmed={setManuallyConfirmed}
    />
  )
};

export default ZPublishModalContainer;
