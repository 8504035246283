import React from 'react';
import { connect } from 'react-redux';
import {
  fetchLandingPageIfNeeded,
  saveLandingPage,
  redirectToCreate,
} from "../../state/actions/landing-page-editor";
import { validateTitleT } from '../../state/actions/shared/error';
import { toggleReadOnly } from '../../state/actions/versions';
import LandingPageContainer from './landing-page-container';

const LandingPageRedux = (props) => (
  <LandingPageContainer {...props}/>
)

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { params } = match;
  const { id, updateId: paramUpdateId  } = params;
  const { editor, errors } = state.landingPages.landingPage;
  const currentEntity = editor[id] || {};
  const {
    isFetching,
    notFound,
    saved,
    saveDisabled,
    disabledMessage,
  } = editor;
  return {
    currentUpdateId: currentEntity.updateId,
    disabledMessage,
    entity: currentEntity,
    errors: errors[id],
    isFetching,
    notFound,
    paramUpdateId,
    readOnly: state.versions.readOnly,
    saved,
    saveDisabled,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match } = ownProps;
  const { params } = match;
  const { id } = params;
  return {
    onCreateNew: () => dispatch(redirectToCreate()),
    onLoad: (updateId) => dispatch(fetchLandingPageIfNeeded(id, updateId)),
    onSave: () => dispatch(saveLandingPage(id)),
    onTitleChange : text => dispatch(validateTitleT('landing_page', text, id)),
    onToggleReadOnly: () => dispatch(toggleReadOnly()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageRedux);
