import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Stack } from '@mui/material';
import ZModal from '../z-modal';
import ZModalH2 from '../z-modal/heading';
import styles from './login.module.scss';
import GoogleLogin from './google-sso-button';

const LoginModalView = ({
  error,
  loading,
  onSubmit, 
  open, 
  useOverlay,
}) => (
  <ZModal
    disableScrollLock
    maxHeight="600px"
    open={open}
    useOverlay={useOverlay}
  >
    <form className={styles['login-form']}>
      <Stack spacing={3}>
        <ZModalH2>Login</ZModalH2>
        <GoogleLogin onSuccess={onSubmit} />
        {error && (
          <Alert severity='error'>
            <AlertTitle>
              There was an error logging in.
            </AlertTitle>
            {`${error?.code}: ${error?.message}`}
          </Alert>
        )}
      </Stack>
    </form>
  </ZModal>
);
LoginModalView.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
LoginModalView.defaultProps = {
  error: undefined,
  useOverlay: null,
};

export default LoginModalView;
