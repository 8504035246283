import React from 'react';
import style from './z-select.module.scss';

function ZSelectView ({
  error,
  inputKey,
  label,
  onChange,
  options,
  style: propStyle,
  text,
}){
  const inputClass = (text && text.length !== 0) ? 'z-input__label--focused': 'z-input__label';
  const inputId = `z-select-${inputKey ? inputKey : Date.now() }`
  return(
    <div className={`${style['z--select--wrapper']}`} style={propStyle}>
      <label 
        htmlFor={inputId} 
        className={style[inputClass]}>
          {label}
      </label>
      <br/>
      <select 
        className={style['z--select']} 
        id={inputId} 
        onChange={onChange} 
        value={text}
      >
        { options && options.map(option => {
          return (
            <option 
              className={style['z--select--option']}
              key={option} 
              value={option} 
            >
              {option}
            </option>
          );
          })
        }
      </select>

      {error && 
        <div  className={style['error']}>
          <p>{error.message}</p>
        </div>
      }
    </div>
  )
};

export default ZSelectView;
