import React from 'react';
import TeaserView from './teaser-view';

const TeaserContainer = ({
  cta,
  onCTATitleUpdate,
  onCTAStepsUpdate,
}) => {
  const _onCTAStepsUpdate = (text, index) => {
    let remappedData = cta ? cta.steps : [];
    remappedData[index] = text;
    onCTAStepsUpdate(remappedData);
  };

  return cta ? (
      <TeaserView
        cta={cta}
        onCTATitleUpdate={onCTATitleUpdate}
        onCTAStepsUpdate={_onCTAStepsUpdate}
      />
    ) : (
      <p><br/>Requires save.</p>
    );
}

export default TeaserContainer;
