import React from 'react';
import ZTextArea from '../../z-text-area';
import PhotoPreview from '../../shared/components/content-image';
import LandingHeaderImage from './header-image/landing-page-header-view';
import Teaser from './teaser';
import TitleFlag from './title-flag';
import ZInput from '../../z-input';
import style from './content.module.scss';
import { getImagePreviewTransformation } from '../../../utils/image/image-utils';

const ContentView = ({
  autoComplete,
  flagCountryCode,
  headerPhotoId,
  id,
  landingPageHeaderPhotoId,
  onChangePhoto,
  onCreateHeaderPhoto,
  onDeleteHeaderPhoto,
  onFlagCountryCodeChange,
  onSubtitleChange,
  onSummaryChange,
  onTitleFlagSelect,
  photos,
  setUseParentImage,
  subtitle,
  useParentImage,
  summary,
}) => {
  return (
    <>
      <br />
      <h2>Header Photo</h2>
      <div className={style['hero-image']}>
        {useParentImage ? (
          <LandingHeaderImage
            alt={photos[landingPageHeaderPhotoId]?.alt}
            setUseParentImage={setUseParentImage}
            src={photos[landingPageHeaderPhotoId]?.uri}
          />
        ) : (
          <PhotoPreview
            entityId={id}
            photoId={headerPhotoId}
            style={{ marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px' }}
            showInfo
            isCCP
            onChangePhoto={onChangePhoto}
            onCreatePhoto={onCreateHeaderPhoto}
            onRemovePhoto={onDeleteHeaderPhoto}
            photo={photos[headerPhotoId]}
            setUseParentImage={setUseParentImage}
            transformation={getImagePreviewTransformation({width: 300, aspectRatio: 3.14, crop: "fill" })}
          />
        )}
      </div>
      <div className={style['flag-row']}>
        <div>
          <h2>Country Title Flag</h2>
          <TitleFlag
            flagCode={flagCountryCode}
            autoComplete={autoComplete}
            autoCompleteOnSelect={onTitleFlagSelect}
            onTitleFlagChange={onFlagCountryCodeChange}
            style={{ width: '300px' }}
          />
        </div>
        <ZInput
          label='Subtitle'
          onUpdate={onSubtitleChange}
          initialValue={subtitle}
          style={{ margin: '14px', flex: 1 }}
          inputKey={'landingPage__subtitle'}
        />
      </div>
      <br />
      <ZTextArea
        allowedFormats={['LINKS']}
        label="Summary"
        onUpdate={onSummaryChange}
        value={summary}
      />
      <br />
      <Teaser
        entityId={id}
      />
      <br />
    </>
  )
}

export default ContentView;
