import React from 'react';
import { connect } from 'react-redux';
import {
  fetchArticleIfNeeded,
  saveArticle,
  redirectToCreate,
} from '../../state/actions/article-editor';
import { validateTitleT } from '../../state/actions/shared/error';
import { toggleReadOnly } from '../../state/actions/versions';
import ArticleEditorContainer from './article-editor-container';

const ArticleEditorRedux = (props) => (
  <ArticleEditorContainer {...props}/>
)

const mapStateToProps = (state, ownProps) => {
  // All this to avoid property of undefined does not exist errors.
  const { id, updateId: paramUpdateId } = ownProps?.match?.params;
  const { editor, errors } = state.articles.article;
  const currentArticle = state.articles.article.editor[id] || {};
  const { isFetching, notFound, isSaving, saved, disabledSaveForDate } = editor;

  return {
    article: currentArticle,
    currentUpdateId: currentArticle.updateId,
    disabledSaveForDate,
    errors: errors[id],
    isFetching,
    isSaving,
    notFound,
    paramUpdateId,
    readOnly: state.versions.readOnly,
    saved,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match } = ownProps
  const { params } = match;
  const { id } = params;
  return {
    onCreateNew: () => dispatch(redirectToCreate()),
    onLoad: (updateId) => dispatch(fetchArticleIfNeeded(id, updateId)),
    onSave: () => dispatch(saveArticle(id)),
    onTitleChange : text => dispatch(validateTitleT('article', text, id)),
    onToggleReadOnly: () => dispatch(toggleReadOnly()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEditorRedux);
