import React from 'react';
import ZTextArea from '../../z-text-area';
import ZInput from '../../z-input';
import style from './teaser.module.scss';

const TeaserView = ({
  cta,
  onCTATitleUpdate,
  onCTAStepsUpdate,
}) => {
  return (
    <>
      <h2>Country CTA Teaser</h2>
      <div className={style['cta-box']}>
        {/* errors.callToAction &&
          <p className={sharedStyle['error']}>{errors.callToAction.message}</p>
        */}
        <br/>
        <ZInput 
          label="Cta Box Title" 
          onUpdate={onCTATitleUpdate}
          initialValue={cta.title}
          error={/*(errors.cta && errors.cta.title) ? errors.cta.title :*/ null}
        />
        <ol>
          {/* (errors.cta && errors.cta.steps) &&
            <p className={sharedStyle['error']}>{errors.cta.steps.message}</p>
          */}
          { [1,2,3].map((d,i) => (
            <li
              key={`teaser-text-${i}`}
            >
             <ZTextArea 
               allowedFormats={['BOLD', 'ITALICS']} 
               onUpdate={(v)=>onCTAStepsUpdate(v,i)}
               value={cta.steps[i]}
               stripTags
             />
           </li>
          ))}
        </ol>
      </div>
    </>
  )
};

export default TeaserView;
