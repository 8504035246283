import { combineReducers } from 'redux'
import sectionsT from './sections';
import photosT from './photos';
import metatags from './meta-data';
import { addAtIndex, addIfMissing } from '../utils';
import {
  ENABLE_SAVE,
  DISABLE_SAVE,
  SET_KEYWORDS_EXCLUDE,
  SET_KEYWORDS_INCLUDE,
  SET_LANDING_PAGE_SNIPPET,
} from '../actions/company-category-editor/action-types';
import {
  SET_META_DATA,
  SET_META_DESCRIPTION,
  SET_META_TITLE,
  SET_NOFOLLOW,
  SET_NOINDEX,
  SET_CANONICAL,
} from '../actions/meta-tags';
import {
  REQUEST_COMPANY_CATEGORY,
  REQUEST_COMPANY_CATEGORY_FAILED,
  REQUEST_NEW_COMPANY_CATEGORY,
  RECEIVED_COMPANY_CATEGORY,
  COMPANY_CATEGORY_SAVED,
  COMPANY_CATEGORY_SAVE_REQUESTED,
} from '../actions/shared/similar-action-types';
import {
  BREADCRUMB_ACTION_NAMES
} from "../actions/shared/breadcrumb-action-types";
import {
  ERROR_ACTION_NAMES,
} from '../actions/shared/error-action-types';
import {
  LOCATION_ACTION_NAMES
} from '../actions/shared/location-action-types';
import {
  PHOTO_ACTION_NAMES,
} from '../actions/shared/photo-action-types';
import {
  TEASER_ACTION_NAMES,
} from '../actions/shared/teaser-action-types';
import {
  SECTION_ACTION_NAMES,
} from '../actions/shared/section-action-types';
import {
  TITLE_ACTION_NAMES
} from '../actions/shared/title-action-types';
import {
  SET_REVIEW_SEARCH
} from '../actions/reviews';

const errors = (state = {}, action) => {
  switch(action.type) {
    case ERROR_ACTION_NAMES.COMPANY_CATEGORY.SET_TITLE_ERROR:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          title: action.error,
        }
      };
    case ERROR_ACTION_NAMES.COMPANY_CATEGORY.CLEAR_TITLE_ERROR:
      if (state[action.id]) {
        const { title, ...remaining } = state[action.id];
        return {
          ...state,
          [action.id]: remaining,
        }
      }
      return state;
    default:
      return state;
  }
};

const companyCategory = (
  state = {
    breadcrumb: {},
    breadcrumbTitle: '',
    callToAction: null,
    title: null,
    subtitle: '',
    summary: '',
    flagCountryCode: null,
    keywordsExclude: [],
    keywordsInclude: [],
    locations: [],
    metatags: {},
    landingPageSnippet: {},
    sectionIds: [],
    footerSectionIds: [],
  },
  action
) => {
  switch (action.type) {
    case RECEIVED_COMPANY_CATEGORY:
      return {
        ...state,
        ...action.entity,
      };
    case TITLE_ACTION_NAMES.COMPANY_CATEGORY.SET_TITLE:
      return {
        ...state,
        title: action.value,
      };
    case TITLE_ACTION_NAMES.COMPANY_CATEGORY.SET_SUB_TITLE:
      return {
        ...state,
        subtitle: action.value,
      };
    case TITLE_ACTION_NAMES.COMPANY_CATEGORY.SET_SUMMARY:
      return {
        ...state,
        summary: action.value,
      };
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.ADD_SECTION:
      return {
        ...state,
        sectionIds: addAtIndex(state.sectionIds, action.id, action.index),
      };
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.REMOVE_SECTION:
      return {
        ...state,
        sectionIds: state.sectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.REORDER_SECTIONS:
      return {
        ...state,
        sectionIds : [...action.sectionIds],
      };
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.ADD_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds: addAtIndex(state.footerSectionIds, action.id, action.index)
      };
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.REMOVE_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds : state.footerSectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.REORDER_FOOTER_SECTIONS:
      return {
        ...state,
        footerSectionIds : [...action.list],
      };
    case PHOTO_ACTION_NAMES.COMPANY_CATEGORY.SET_HEADER_PHOTO:
      return {
        ...state,
        headerPhotoId: action.photo.id,
      };
    case PHOTO_ACTION_NAMES.COMPANY_CATEGORY.REMOVE_HEADER_PHOTO: {
      let { headerPhotoId, heroImage, ...rest } = state;
      return {
        ...rest
      };
    }
    case LOCATION_ACTION_NAMES.COMPANY_CATEGORY.SET_FLAG_COUNTRY_CODE:
      return {
        ...state,
        flagCountryCode: action.flagCountryCode,
      };
    case SET_KEYWORDS_EXCLUDE:
      return {
        ...state,
        keywordsExclude: action.keywordsExclude,
      };
    case SET_KEYWORDS_INCLUDE:
      return {
        ...state,
        keywordsInclude: action.keywordsInclude,
      };
    case SET_META_DATA:
    case SET_META_TITLE:
    case SET_META_DESCRIPTION:
    case SET_NOFOLLOW:
    case SET_NOINDEX:
    case SET_CANONICAL:
      return {
        ...state,
        metatags: metatags(state.metatags, action)
      };
    case TEASER_ACTION_NAMES.COMPANY_CATEGORY.SET_CTA_TITLE:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          title: action.value,
        },
      };
    case TEASER_ACTION_NAMES.COMPANY_CATEGORY.SET_CTA_STEPS:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          steps: action.value,
        },
      };
    case LOCATION_ACTION_NAMES.COMPANY_CATEGORY.ADD_LOCATION:
      return {
        ...state,
        locations: addIfMissing(action.value, state.locations),
      };
    case LOCATION_ACTION_NAMES.COMPANY_CATEGORY.SET_LOCATIONS:
      return {
        ...state,
        locations: [...action.list]
      };
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.SET_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.breadcrumb,
      };
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.SET_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle: action.title,
      };
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.DELETE_BREADCRUMB:
      return {
        ...state,
        breadcrumb: {},
      };
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.DELETE_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle: null,
      };
    case SET_LANDING_PAGE_SNIPPET:
      return {
        ...state,
        landingPageSnippet: action.landingPageSnippet,
      };
    case SET_REVIEW_SEARCH:
      return {
        ...state,
        reviewSearch: action.data,
      };
    default:
      return state;
  }
};

const editor = (
  state = {
    isFetching: true,
    isSaving: false,
    saved: true,
    notFound: false,
    saveDisabled: false,
    disabledMessage: null,
    countries: [],
  },
  action
) => {
  switch(action.type){
    case REQUEST_COMPANY_CATEGORY:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_NEW_COMPANY_CATEGORY:
      return {
        ...state,
        isFetching: true,
        saved: false,
      };
    case RECEIVED_COMPANY_CATEGORY:
      return {
        ...state,
        isFetching: false,
        notFound: false,
        lastUpdate: action.receivedAt,
        [action.id] : companyCategory(state[action.id], action),
      };
    case REQUEST_COMPANY_CATEGORY_FAILED:
      return {
        ...state,
        isFetching: false,
        notFound: true,
      };
    case COMPANY_CATEGORY_SAVE_REQUESTED:
      return {
        ...state,
        isSaving: true,
        saved: false,
      };
    case COMPANY_CATEGORY_SAVED:
      return {
        ...state,
        saved: true,
      };
    case DISABLE_SAVE:
      return {
        ...state,
        saveDisabled: true,
        disabledMessage: action.message,
      };
    case ENABLE_SAVE:
      return {
        ...state,
        saveDisabled: false,
        disabledMessage: null,
      };
    case TITLE_ACTION_NAMES.COMPANY_CATEGORY.SET_TITLE:
    case TITLE_ACTION_NAMES.COMPANY_CATEGORY.SET_SUB_TITLE:
    case TITLE_ACTION_NAMES.COMPANY_CATEGORY.SET_SUMMARY:
    case LOCATION_ACTION_NAMES.COMPANY_CATEGORY.SET_FLAG_COUNTRY_CODE:
    case SET_KEYWORDS_EXCLUDE:
    case SET_KEYWORDS_INCLUDE:
    case SET_META_DATA:
    case SET_META_TITLE:
    case SET_META_DESCRIPTION:
    case SET_NOFOLLOW:
    case SET_NOINDEX:
    case SET_CANONICAL:
    case TEASER_ACTION_NAMES.COMPANY_CATEGORY.SET_CTA_TITLE:
    case TEASER_ACTION_NAMES.COMPANY_CATEGORY.SET_CTA_STEPS:
    case LOCATION_ACTION_NAMES.COMPANY_CATEGORY.ADD_LOCATION:
    case LOCATION_ACTION_NAMES.COMPANY_CATEGORY.SET_LOCATIONS:
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.SET_BREADCRUMB:
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.SET_BREADCRUMB_TITLE:
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.DELETE_BREADCRUMB:
    case BREADCRUMB_ACTION_NAMES.COMPANY_CATEGORY.DELETE_BREADCRUMB_TITLE:
      return {
        ...state,
        saved: false,
        [action.id] : companyCategory(state[action.id], action)
      };
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.ADD_SECTION:
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.REMOVE_SECTION:
    case PHOTO_ACTION_NAMES.COMPANY_CATEGORY.SET_HEADER_PHOTO:
    case PHOTO_ACTION_NAMES.COMPANY_CATEGORY.REMOVE_HEADER_PHOTO:
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.ADD_FOOTER_SECTION:
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.REMOVE_FOOTER_SECTION:
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.REORDER_SECTIONS:
    case SECTION_ACTION_NAMES.COMPANY_CATEGORY.REORDER_FOOTER_SECTIONS:
      return {
        ...state,
        saved: false,
        [action.entityId] : companyCategory(state[action.entityId], action)
      };
    case SET_LANDING_PAGE_SNIPPET:
      return {
        ...state,
        [action.id] : companyCategory(state[action.id], action)
      };
    case SET_REVIEW_SEARCH: {
      if (action.entityType === 'companyCategory') {
        return {
          ...state,
          [action.entityId] : companyCategory(state[action.entityId], action)
        }
      }
      return state;
    }
    default:
      return state
  }
};

const companyCategoryEditor = combineReducers({
  editor,
  sections: sectionsT('company_category'),
  photos: photosT('company_category'),
  errors,
});

export default companyCategoryEditor;
