import { connect } from 'react-redux';
import PublishedStatusContainer from './published-container';
import { fetchPublication, getPublishedStatus } from '../../../../state/actions/published';

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.published.isFetching,
  publication: state.published[ownProps.entityId]?.current,
  publicationStatus: state.published[ownProps.entityId]?.status?.publicationStatus,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPublication: (entityId) => dispatch(fetchPublication(entityId)),
  getPublishedStatus: (publication, entityId, entityUpdateId) => dispatch(getPublishedStatus(publication, entityId, entityUpdateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishedStatusContainer);
