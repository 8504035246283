import {
  CLEAR_PUBLICATION_REFERENCES,
  PUBLICATION_REFERENCES_ERROR,
  PUBLICATION_REFERENCE_REQUEST,
  PUBLICATION_REFERENCE_SUCCESS,
} from '../actions/publication-references';

const publicationReferences = (state = {
  isFetching: false,
  error: false,
}, action) => {
  switch(action.type) {
    case PUBLICATION_REFERENCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
      case PUBLICATION_REFERENCE_SUCCESS:
        return {
          ...state,
          isFetching: false,
          error: false,
          [action.entityId]: action.references,
        };
      case PUBLICATION_REFERENCES_ERROR:
        return {
          ...state,
          isFetching: false,
          error: true,
        };
      case CLEAR_PUBLICATION_REFERENCES:
        return {
          [action.entityId]: null,
        };
    default:
        return state;
  }
};

export default publicationReferences;

