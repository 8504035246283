export const CAREERS_REQUEST = 'CAREERS_REQUEST';
export const CAREERS_ERROR = 'CAREERS_ERROR';
export const CAREERS_RECEIVED = 'CAREERS_RECEIVED';
export const CAREER_SAVE_REQUEST = 'CAREER_SAVE_REQUEST';
export const SET_CAREER = 'SET_CAREER';
export const CAREER_SAVE_ERROR = 'CAREER_SAVE_ERROR';
export const SET_DISPLAYED = 'SET_CAREER_DISPLAYED';
export const SET_CAREER_TITLE = 'SET_CAREER_TITLE';
export const SET_CAREER_LOCATION = 'SET_CAREER_LOCATION';
export const SET_CAREER_URI = 'SET_CAREER_URI';
export const CREATE_NEW_CAREER = 'CREATE_NEW_CAREER';
