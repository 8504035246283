import {
  useReducer,
} from 'react';
import {
  SET_ERROR,
  SET_GEO_COUNTRIES,
  SET_TRF_COUNTRIES,
  SET_SERVED_LOCATIONS_MAP,
  SET_SERVED_LOCATIONS_MAP_REVERSE,
  SET_SERVED_LOCATIONS_OPTIONS,
  SET_COMPANY_LOCATIONS,
} from './actions';

const initialState = {
  error: false,
  countries: {},
  trfCountries: {},
  companyLocations: [],
  servedLocationsMap: {},
  servedLocationsMapReverse: {},
  servedLocationsOptions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_GEO_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    case SET_TRF_COUNTRIES:
      return {
        ...state,
        trfCountries: action.countries,
      };
    case SET_SERVED_LOCATIONS_MAP:
      return {
        ...state,
        servedLocationsMap: action.servedLocationsMap,
      };
    case SET_SERVED_LOCATIONS_MAP_REVERSE:
      return {
        ...state,
        servedLocationsMapReverse: action.servedLocationsMapReverse,
      };
    case SET_SERVED_LOCATIONS_OPTIONS:
      return {
        ...state,
        servedLocationsOptions: action.servedLocationsOptions,
      };
    case SET_COMPANY_LOCATIONS:
      return {
        ...state,
        companyLocations: action.locations,
      };
    default:
      return state;
  }
};

const useLocationsReducer = () => {
  const [locations, dispatchLocations] = useReducer(reducer, initialState);

  return [locations, dispatchLocations];
};

export default useLocationsReducer;
