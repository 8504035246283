import React from 'react';
import TeamPage from '../../../components/team-page';

const Team = (props) => {
  return(
    <TeamPage {...props}/>
  )
}

export default Team;
