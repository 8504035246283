import React from 'react';
import Switch from '@mui/material/Switch';
import CompanyCatergoryCard from './company-category-card';
import TextField from '@mui/material/TextField';
import { Stack, Typography, Alert, AlertTitle } from '@mui/material';

const LinkTextEditor = ({
  path,
  text, 
  onChange
}) => (
  <Stack direction='row' spacing={1} alignItems="center" sx={{marginBottom: '8px'}}>
    <TextField
      variant="standard" 
      id="button-link"
      label="Button Link Uri"
      value={path}
      disabled
    />
    <TextField
      sx={{ marginBottom: '24px', maxWidth: '50%' }}
      fullWidth
      variant="standard" 
      id="button-title"
      label="Customize Button Text"
      value={text}
      onChange={onChange}
    />
  </Stack>
)

const TravelAgentsView = ({
  missMatchError,
  errors,
  currentAgentLayout,
  currentCCP,
  useAgencies,
  onToggleAgencies,
  sectionTitle,
  onTitleChange, 
  buttonText,
  onButtonTextChange,
}) => (
  <>
    <Typography variant='h3' sx={{marginBottom: '8px'}}>Travel Agents</Typography>
    { missMatchError && (
      <Typography variant='body' color="error" sx={{marginBottom: '8px', whiteSpace: 'pre-line'}}>
        {missMatchError}
      </Typography>
    )}
    { currentAgentLayout && (
      <Alert severity="error" sx={{marginBottom: '16px'}}>
        <AlertTitle>ACTION REQUIRED: Synchronization Issue Detected</AlertTitle>
        <Typography variant='body' sx={{ marginBottom: '8px', whiteSpace: 'pre-wrap' }}>
          { "Mismatch detected between the Agency Category Page in the subnavigation and the one in the travel agents section.\nThis may prevent proper unpublishing of the ACP.\n\nTo Resolve:\n1. Save the current page.\n2. Republish the page to synchronize the Agency Category Pages."}
        </Typography>
      </Alert>
    )}
    <div style={{marginBottom: '16px'}}>
      { errors && 
        <h4>{errors}</h4>
      }
      { currentAgentLayout && (
        <> 
           { currentCCP && 
              <CompanyCatergoryCard
                id={currentCCP.id}
                title={currentCCP.title}
                image={currentCCP.image}
                canonicalPath={currentCCP.canonicalPath}
              />
            }
          <Stack direction='row' spacing={1} alignItems="center" sx={{marginBottom: '8px'}}>
            <Switch
              color="secondary"
              checked={useAgencies}
              onChange={onToggleAgencies}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>Use Associated Travel Agents</Typography>
          </Stack>
          <Typography sx={{marginBottom: '8px'}}>{currentAgentLayout}</Typography>
          { (useAgencies && currentCCP) && (
            <>
              <TextField
                sx={{ marginBottom: '24px', maxWidth: '50%' }}
                fullWidth
                variant="standard" 
                id="section-title"
                label="Section Title"
                value={sectionTitle}
                onChange={onTitleChange}
              />
              <LinkTextEditor
                path={currentCCP.canonicalPath}
                text={buttonText}
                onChange={onButtonTextChange}
              />
            </>
          )}
        </>
      )}
    </div>
  </>
);

export default TravelAgentsView;
