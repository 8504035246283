import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import BreadcrumbsView from './breadcrumbs-view';
import { decodeHTMLEntities } from '../../../../utils/window';
import { getParentBreadcrumb } from '../../../../utils/breadcrumbs';
import ZApiClient from '../../../../utils/client/z-api-client';

const BreadcrumbsContainer = ({
  breadcrumb,
  breadcrumbList,
  breadcrumbTitle,
  entityId,
  entityTitle,
  entityType,
  entityUpdateId,
  error,
  fetchBreadcrumbs,
  isFetching,
  onSelectBreadcrumb,
  onDeleteBreadcrumb,
  onSetBreadcrumbTitle,
  onDeleteBreadcrumbTitle,
  searchEntities,
  setBreadcrumbs,
}) => {
  const currentTitle = useMemo(() => {
    if (breadcrumbTitle) return decodeHTMLEntities(breadcrumbTitle);
    if (breadcrumbList && breadcrumbList.length > 0 && !isFetching) {
      return decodeHTMLEntities(breadcrumbList[breadcrumbList.length - 1].title);
    }
    return '';
  }, [breadcrumbTitle, breadcrumbList, isFetching]);

  const [titleMap, setTitleMap] = useState({});
  const [customBreadcrumbList, setCustomBreadcrumbList ] = useState();
  const [customBreadcrumbTitle, setCustomBreadcrumbTitle] = useState();
  const latestTitleMap = useRef(titleMap);

  useEffect(() => {
    // Fetch breadcrumbs on load and on save if on this tab
    fetchBreadcrumbs(entityId, entityType);
  }, [fetchBreadcrumbs, entityUpdateId, entityId, entityType]);

  useEffect(()=>{
    latestTitleMap.current = titleMap;
  }, [titleMap]);

  useEffect(() => {
    if (!breadcrumb) {
      setCustomBreadcrumbList(null);
    } else if (breadcrumbList?.findIndex((bc) => bc.entityId === breadcrumb.entityId) === -1) {
      const first = breadcrumbList[0];
      const last = breadcrumbList[breadcrumbList.length - 1];

      setCustomBreadcrumbList((prevList) => {
        if (prevList?.findIndex((bc) => bc.entityId === breadcrumb.entityId) === -1) {
          return [first, breadcrumb, last];
        }
        return prevList;
      });
    }
  }, [breadcrumb, breadcrumbList]);

  const cleanTitles = (obj) => {
    let ret = {};
    for(const key in obj){
      ret[key] = decodeHTMLEntities(obj[key]);
    }
    return ret;
  };

  const getEntityMap = (obj, type) => {
    let ret = {};
    for(const key in obj){
      let temp = {
        title: obj[key],
        type: type,
      };
      ret[key] = temp;
    }
    return ret;
  };

  const getSearchCalls = (text) => {
    return searchEntities.map((ele)=> {
      return ZApiClient
      .getClientFromType(ele)
      .getAllTitlesWithIdsByNameFragment(text)
      .then((res)=> {
        setTitleMap((prevState) =>{ return {...prevState, ...getEntityMap(res, ele)} })
        return cleanTitles(res);
      });
    })
  };

  const onSearch = async (text) => {
    const searchText = text.toLowerCase();
    const titles = await Promise.all(getSearchCalls(searchText));
    const merged = Object.assign(...titles);
    return merged;
  };

  const onSelect = (obj) => {
    const current = Object.entries(obj);
    const [id] = current[0];
    const currentMap = latestTitleMap.current

    if (currentMap[id]) {
      const { type } = currentMap[id];

      getParentBreadcrumb(`${type}s`, id).then((res) => {
        const customParent = res[res.length - 1];
        onSelectBreadcrumb({
          ...customParent
        });

        const last = breadcrumbList[breadcrumbList.length - 1];
        const ret = [...res, last];
        setCustomBreadcrumbList(ret);
      })
      .catch(console.error);
    }
  };

  const updateTitle = () => {
    onSetBreadcrumbTitle(customBreadcrumbTitle);
    setCustomBreadcrumbTitle('');
  };

    const onDeleteTitle = () => {
    onDeleteBreadcrumbTitle();
    const bcs = [...breadcrumbList];
    bcs[bcs.length - 1].title = entityTitle;
    setBreadcrumbs(entityType, bcs, entityId);
  }

  return (
    <BreadcrumbsView
      breadcrumbList={breadcrumbList}
      breadcrumbTitle={breadcrumbTitle}
      currentTitle={currentTitle}
      customBreadcrumbList={customBreadcrumbList}
      customBreadcrumbTitle={customBreadcrumbTitle}
      error={error}
      isFetching={isFetching}
      onSearch={onSearch}
      onSelect={onSelect}
      onDeleteBreadcrumb={onDeleteBreadcrumb}
      onDeleteBreadcrumbTitle={onDeleteTitle}
      setCustomBreadcrumbTitle={setCustomBreadcrumbTitle}
      showBreadcrumbSearch={!!searchEntities}
      updateBreadcrumbTitle={updateTitle}
      useCustom={!!breadcrumb}
    />
  )
}

export default BreadcrumbsContainer;
