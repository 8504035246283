import React from 'react';
import { connect } from 'react-redux';
import {
  createPhotoT, updatePhotoT, removeGalleryPhotoT, setGalleryT, removeHeaderThumbT,
} from '../../../../state/actions/shared/photo';
import {
  addGalleryImage,
  addThumbnail,
} from '../../../../state/actions/itinerary-editor/photo'
import PhotosContainer from './photos-container';

const mapIdsToImages = (idList, photoState) => {
  const ret = [];
  for(const id of idList){
    if(photoState[id]) ret.push(photoState[id]);
  }
  return ret;
}

const isCloudinary = (str) => {
  const test  = str.toLowerCase();
  const containsCloudinary = test.indexOf('cloudinary') !== -1;
  return containsCloudinary;
}

const PhotosRedux = (props) => {
  return(
    <PhotosContainer {...props}/>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const { errors } = state;
  const { editor, photos } = state.itineraries.itinerary;
  const currentEntity = editor[id] || {};
  const currentErrors = errors[id] || {};
  const { headerPhotoId, photoIds = [], thumbnailId } = currentEntity;
  const {
    images: galleryErrors,
    heroImage: heroImageErrors,
  } = currentErrors;
  return {
    galleryPhotos: mapIdsToImages(photoIds, photos),
    headerPhoto: photos[headerPhotoId] || {},
    thumbnailPhoto: photos[thumbnailId],
    readOnly: state.versions.readOnly,
    galleryErrors,
    heroImageErrors,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    updatePhoto: (photo) => dispatch(updatePhotoT('itinerary', photo)),
    updateHeaderPhoto : (photo) => {
      if(photo.id && !isCloudinary(photo.uri)){
        dispatch(updatePhotoT('itinerary', photo));
      } else {
        dispatch(createPhotoT('itinerary', id, 'header', photo));
      }
    },
    onListChange: (list) => dispatch(setGalleryT('itinerary', id, list)),
    onDeleteGalleryImage: (photoId) => dispatch(removeGalleryPhotoT('itinerary', id, photoId)),
    addGalleryImage: (photo, index) => dispatch(addGalleryImage(photo, id, index)),
    addThumbnail: (photo) => dispatch(addThumbnail(photo, id)),
    onDeleteThumbnail: () => dispatch(removeHeaderThumbT('itinerary', id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotosRedux);
