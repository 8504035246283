import React, { useEffect, useState } from 'react';
import NewsSnippetEditorView from './news-snippet-editor-view';
import urlRegex from '../../../utils/validate/urlRegex';

const NewsSnippetEditorContainer = ({
  closeModal,
  id,
  newsSnippet: {
    displayed,
    error,
    isSaving,
    newsSource,
    publicationDate,
    teaser,
    title,
    uri,
  },
  onClickEdit,
  saveNewsSnippet,
  setDisplayed,
  setEditNewsSourceId,
  setPublicationDate,
  setTeaser,
  setTitle,
  setUri,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [validUri, setValidUri] = useState();

  useEffect(() => {
    const validUri = urlRegex().test(uri);
    setDisabled(!newsSource.id || !title || !teaser || !validUri || !publicationDate);
    setValidUri(uri ? validUri : undefined);
  }, [newsSource.id, publicationDate, title, teaser, uri]);

  const handleChangeDate = (date) => {
    setPublicationDate(date);
  };

  const handleChangeDisplayed = (e) => {
    setDisplayed(e.target.checked);
  };

  const handleClickSave = () => {
    saveNewsSnippet();
  };

  return (
    <NewsSnippetEditorView
      closeModal={closeModal}
      saveDisabled={disabled}
      displayed={displayed}
      error={error}
      id={id}
      isSaving={isSaving}
      onChangeDate={handleChangeDate}
      onChangeDisplayed={handleChangeDisplayed}
      onClickEdit={onClickEdit}
      onClickSave={handleClickSave}
      publicationDate={publicationDate}
      setEditNewsSourceId={setEditNewsSourceId}
      setTeaser={setTeaser}
      setTitle={setTitle}
      setUri={setUri}
      teaser={teaser}
      title={title}
      uri={uri}
      validUri={validUri}
    />
  );
};

export default NewsSnippetEditorContainer;
