import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#444',
    },
    secondary: {
      main: '#ef940a',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Open Sans',
    h1: {
      fontSize: 28,
    },
    h2: {
      fontSize: 22,
    },
    h3: {
      fontSize: 18,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 14,
    },
    subtitle2: {
      fontSize: 14,
    }
  },
});

export default theme;
