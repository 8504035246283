import React from 'react';
import Faq from './faq';
import ZDraggableGrid from '../../z-draggable-grid.js/index.js';
import ZAddButton from '../../z-button-add';
import sharedStyle from '../../shared/styles/shared.module.scss';
import ZInput from '../../z-input';

const FaqSectionView = ({
  addFaq,
  faqErrors,
  faqs,
  faqSectionTitle,
  onDeleteFaq,
  onDragEnd,
  readOnly,
  updateFaq,
  updateTitle,
}) => {
  return (
    <>
      <br/>
      <h2>FAQ Section</h2>
      <div>
        <ZInput
          initialValue={faqSectionTitle}
          label='FAQ Section Title'
          style={{marginBottom: '14px'}}
          onUpdate={updateTitle}
        />
      </div>
      { (faqErrors && !faqErrors.valid) && 
        <p className={sharedStyle['error']}>{faqErrors.message}</p>
      }
      <div>
        <ZAddButton onClick={addFaq}>Add FAQ Item</ZAddButton>
      </div>
      {faqs.length !== 0 &&
        <ZDraggableGrid onDragEnd={onDragEnd} disabled={readOnly}>
          { faqs.length > 0 && faqs.map((ele, index) => (
            <Faq 
              addFaq={addFaq}
              faq={{...ele}} 
              id={ele.id}
              index={index} 
              inputKey={`${ele.id}-${index}`}
              key={`${ele.id}-${index}`}
              onUpdate={updateFaq}
              removeFaq={onDeleteFaq}
            />
          ))}
        </ZDraggableGrid>
      }
    </>
  )
}

export default FaqSectionView;
