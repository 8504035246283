import React from 'react';
import ZInput from '../../../z-input';
import styles from './content-photo-preview.module.scss';
import ZDeleteButton from '../../../z-button-delete';
import { ReactComponent as CopySVG } from '../../../../assets/logos/copy.svg';
import { onCopy } from '../../../../utils/window';

const PhotoPreviewView = ({
  style,
  alt,
  title,
  info,
  validUri,
  src,
  fileName,
  placeHolder,
  removePhoto,
  onLoad,
  onChange,
  photoId,
  readOnly,
  showInfo,
  showDelete,
}) => {
  return (
    <div className={`${styles['photo-preview']}`} style={style}>
      <div className={`${styles['photo-preview__photo']}`}>
        <img
          alt={alt}
          onLoad={onLoad}
          src={!!info.uri && validUri ? src : placeHolder}
        />
      </div>
      <div className={removePhoto ? `${styles['photo-preview__input']}` : `${styles['photo-preview__input']} ${styles['photo-preview__input--hero']}`}>
        <ZInput
          error={!validUri && { message: 'Please use an image from the Photo App.' }}
          label={!!src ? '' : 'Paste a Url'}
          style={{width: '100%', marginBottom: '54px', wordBreak: 'break-all'}}
          text={src || ''} 
          onChange={(text) => onChange('uri', text)}
          inputKey={`${photoId}__url`}
          readOnly={readOnly}
        />
        <div className={`${styles['photo-preview__meta']}`}>
          <ZInput 
            label='Alt Text'
            text={alt || ''} 
            style={{width: '100%', marginRight: '7px', borderLeft: '1px solid #bdbdbd'}} 
            onChange={(text) => onChange('alt', text)}
            inputKey={`${photoId}__alt`}
          />
          <ZInput 
            label='Title Text' 
            text={title || ''} 
            style={{width: '100%', borderLeft: '1px solid #bdbdbd'}} 
            onChange={(text) => onChange('title', text)}
            inputKey={`${photoId}__title`}
          />
        </div>
        {showInfo && 
          <div className={`${styles['info']}`}>
            <p className={`${styles['info__field']}`}>{`Date Added: ${info.createdAt || ''}`}</p>
            <p className={`${styles['info__field']}`}>
              {`Dimensions (H x W): ${info.height && info.width ? `${info.height} x ${info.width}` : ''}`}
            </p>
            <p className={`${styles['info__field']}`}>
              Source: 
              <br/>
              <a href={info.sourceUri} target="_blank" rel="noopener noreferrer">{info.sourceUri}</a>
            </p>
            {info.sourceType === 'Cloudinary' &&
              <p className={`${styles['info__copy']}`} onClick={() => onCopy(fileName)}>
                  Copy File Name <CopySVG/>
              </p>
            }
          </div>
        }
      </div>
      <div className={styles['photo-preview__remove']}>
        {showDelete && 
          <>
            <ZDeleteButton style={{width: '90px'}} onClick={removePhoto}>Delete</ZDeleteButton>
          </>
        }
      </div>
    </div>
  )
}

export default PhotoPreviewView;
