import { decodeHTMLEntities } from '../window/index';
import ZApiClient from '../client/z-api-client';

export const searchLandingPages = (text) => (
  ZApiClient.getLandingPagesClient().getAllTitlesWithIdsByNameFragment(text)
    .then(options => {
      Object.keys(options).map((key) => options[key] = decodeHTMLEntities(options[key]));
      return options;
    })
);

export const getLandingPageSnippet = (id) => (
  ZApiClient.getLandingPagesClient().getLandingPageSnippet(id)
);
