import React from 'react';
import { connect } from 'react-redux';
import ZPublicationsContainer from './z-publications-container';
import { fetchEntityPublications } from '../../state/actions/publications';

const ZPublicationsRedux = (props) => (
  <ZPublicationsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => ({
  publications: state.publications[ownProps.entityId],
  loading: state.publications.isFetching,
  error: state.publications.error,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchPublications: (id) => dispatch(fetchEntityPublications(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZPublicationsRedux);
