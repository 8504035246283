import ZApiClient from './z-api-client';

class Guide {

  _getTuid  = () => (
    ZApiClient.getTuidsClient().createTuid()
  );

  _mapIdsToEntity = (ids, entites) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele)=> {
        return entites[ele]
      })
    }
  }

  _mapFooterIdtoSection = (ids, sections) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        return {...sections[ele]} ;
      });
    }
  }

  _mapFooterSections = async (sections) => {
    const idList = [];
    const idMap = {};
    if(sections){
      for(const section of sections){
        let {id, links} = section;
        if(!id){
          const {id:resId} = await this._getTuid();
          id = resId;
        }
        if(links){
          const promiseArr = links.map((link)=> this._getIdIfNeeded(link));
          links = await Promise.all(promiseArr);
        }
        idList.push(id);
        idMap[id] = {
          ...section,
          id,
          links,
        }
      }
    }
    return {
      idList,
      idMap,
    }
  }

  _getIdIfNeeded = async (section) => {
    let {id} = section;
    if(!id) {
      const {id:resId} = await this._getTuid();
      id = resId;
      return {...section, id};
    } else {
      return {...section};
    }
  }

  _mapSectionIdToSections = (ids, sections, photos) => {
    if(!ids){
      return [];
    } else {
      return ids.map((ele) => {
        const section = {...sections[ele]};
        section.image = photos[section.photoId] || {} ;
        return section;
      });
    }
  }

  _mapPhotos = (photos) => {
    let ret = {};
    if(!photos || !photos[0]){
      return ret
    };
    for(const photo of photos){
      ret[photo.id] = {...photo};
    }
    return ret;
  }

  _mapSectionsToEntities = async (sections, footerLinks) => {
    const sectionIds = [];
    const sectionMap = {};
    const sectionPhotos = [];
    if (sections) {
      for(const section of sections){
        let {id, heroImage, photoId} = section;
        if(!id){
          const tuidRes = await this._getTuid();
          id = tuidRes.id;
        }
        if(heroImage){
          photoId = heroImage.id;
          sectionPhotos.push({...heroImage})
        }
        sectionIds.push(id);
        sectionMap[id] = {...section, id, photoId};
      };
    }
    const{
      idMap:footerIdMap,
      idList:footerIds,
    } = await this._mapFooterSections(footerLinks);
    return {
      sectionIds,
      footerIds,
      sectionMap : {...sectionMap, ...footerIdMap},
      sectionPhotos,
    };
  };

  _generateDescription = (locations) => {
    locations = locations || [];
    let locationText = locations.length > 0 ? `${locations[0]} ` : '';
    return `Zicasso: ${locationText}travel & tours by top competing travel agents & tour operators.`
  }

  /**
   * @param {object} metatags
   * @param {object} enitity (Guide, LTL, Article, Itinerary)
   * Checks if meta title exist.
   * If no meta title present use default rules to build meta title
   */
  _mapMetaTags = (metatags, enitiy) => {
    let {
      title:metaTitle,
      canonical,
      description,
    } = metatags;
    const {
      title,
      locations,
    } = enitiy;
    if(!metaTitle){
      metaTitle = `${title} | Zicasso`;
    }
    if(!description){
      description = this._generateDescription(locations);
    }
    return {
      ...canonical && {canonical: canonical},
      description,
      title: metaTitle,
    }
  }

  _getMetaTags = (metatags) => {
    return {
      title: '',
      description: '',
      canonical: '',
      ...metatags,
    }
  };

  /**
   * Accepts the redux state for a guide. (crurrently guides.guide).
   * guide state includes 3 fields
   * {
   *  editor:{
   *    isFetching: boolean,
   *    isSaving: boolean,
   *    saved: boolean,
   *    [guideId] : {...},
   *  },
   *  photos:{
   *    [photoId] : {...}
   *  },
   *  sections:{
   *    [sectionId] : {}
   *  }
   * }
   * @param {string} entityId
   * @param {object} entityState
   */
  getGuide(entityId, entityState){
    const {editor, photos, sections} = entityState;
    const currentEntity = editor[entityId];
    const {
      id,
      title,
      summary,
      sectionIds,
      locations,
      metatags,
      footerSectionIds,
      breadcrumb,
      breadcrumbTitle,
      headerPhotoId,
      primaryLinksTitle,
      helpfulContentTitle,
    } = currentEntity;
    const ret = {
      id,
      title,
      summary,
      locations,
      breadcrumb,
      breadcrumbTitle,
      ...headerPhotoId && { heroImage : this._mapIdsToEntity([headerPhotoId], photos)[0] },
      metatags: this._mapMetaTags(metatags, currentEntity),
      articles: this._mapSectionIdToSections(sectionIds, sections, photos),
      footerLinks: this._mapFooterIdtoSection(footerSectionIds, sections),
      primaryLinksTitle,
      helpfulContentTitle,
    }
    return ret;
  }


  /**
   * Accepts an entity from our backend and returns the following:
   * {
   *  id: 'guideId',
   *  [id] : {mapped guide},
   *  photos: {
   *    [photoId] : {...},
   *  },
   *  sections: {
   *    [sectionId] : {...},
   *  },
   * }
   * @param {object} guide
   */
  async getState(guide){
    const {
      articles,
      metatags,
      footerLinks,
      heroImage,
      primaryLinksTitle,
      helpfulContentTitle,
    } = guide;
    const {
      sectionIds,
      sectionMap,
      footerIds,
      sectionPhotos
    } = await this._mapSectionsToEntities(articles, footerLinks);
    const retGuide = {
      ...guide,
      metatags: this._getMetaTags(metatags),
      sectionIds,
      footerSectionIds: footerIds,
      ...heroImage && {headerPhotoId : heroImage.id},
      primaryLinksTitle,
      helpfulContentTitle,
    };
    return {
      id: guide.id,
      [guide.id] : retGuide,
      photos : this._mapPhotos([...sectionPhotos, heroImage]),
      sections : sectionMap,
    };
  }

}

export default Guide;
