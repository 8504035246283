/* eslint-disable no-undef */
import React, {
  useState,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import ZSelectInputView from './z-select-input-view';

const ZSelectInputContainer = React.forwardRef(({
  name,
  initialValue,
  inputClass,
  listClassName,
  onValidate,
  optionClass,
  options,
  subText,
  placeholder,
  wrapperClass,
}, _ref) => {
  const [value, setValue] = useState(initialValue || '');
  const [valid, setValid] = useState(null);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    const closeMenu = () => {
      setFocus(false);
      if (inputRef.current) { inputRef.current.blur(); }
    };
    if (focus) {
      window.addEventListener('click', closeMenu, { passive: true });
    }
    return () => window.removeEventListener('click', closeMenu);
  }, [focus]);

  useImperativeHandle(_ref, () => ({
    getValid() {
      if (valid === null) setValid(false);
      return valid;
    },
  }));

  const updateValid = (_value, _valid) => {
    setValue(_value);
    setValid(_valid);
    onValidate(name, _value, _valid);
  };

  const setNewValue = (_value) => {
    //  Since selection, always true
    updateValid(_value, true);
    //  Adjust focus
    setFocus(false);
    if (inputRef.current) { inputRef.current.blur(); }
  };

  const setNewFocus = (_value) => {
    //  Adjust focus
    setFocus(_value);
    if (inputRef.current) { inputRef.current.focus(); }
  };

  return (
    <ZSelectInputView
      focus={focus}
      inputClass={inputClass}
      inputRef={inputRef}
      listClassName={listClassName}
      optionClass={optionClass}
      options={options}
      placeholder={placeholder}
      setValue={setNewValue}
      setFocus={setNewFocus}
      subText={subText}
      valid={valid}
      value={value}
      wrapperClass={wrapperClass}
    />
  );
});
ZSelectInputContainer.propTypes = {
  initialValue: PropTypes.string,
  inputClass: PropTypes.string,
  listClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onValidate: PropTypes.func.isRequired,
  optionClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ),

  placeholder: PropTypes.string,
  subText: PropTypes.string,
  wrapperClass: PropTypes.string,
};
ZSelectInputContainer.defaultProps = {
  initialValue: null,
  inputClass: null,
  listClassName: null,
  optionClass: null,
  options: null,
  subText: null,
  placeholder: null,
  wrapperClass: null,
};
ZSelectInputContainer.displayName = 'ZSelectInputContainer';

export default ZSelectInputContainer;
