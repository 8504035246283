import { connect } from 'react-redux';
import PathsContainer from './paths-container';
import {
  createNewPath,
  deleteSelectedPath,
  fetchPaths,
  setCanonical,
  clearPathError,
} from '../../../../state/actions/paths';

const mapStateToProps = (state, ownProps) => {
  const { entityId } = ownProps;

  return {
    entityPaths: state.paths[entityId],
    error: state.paths?.error || null,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id, entityType } = ownProps;

  return {
    fetchPaths: () => dispatch(fetchPaths(id)),
    onAddNewPath: (path) => dispatch(createNewPath(id, entityType, path)),
    onDeletePath: (pathId) => dispatch(deleteSelectedPath(id, pathId, entityType)),
    onConfirmPathEdits: (pathId) => dispatch(setCanonical(id, pathId, entityType)),
    onClearError: () => dispatch(clearPathError())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PathsContainer);
