import React from 'react';
import style from './z-spinner.module.scss';

const ZSpinnerView = ({className = ''}) => (
  <div className={`${style["lds-ellipsis"]} ${className}`}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export const ZSpinnerLight = () => (
  <ZSpinnerView className={style["light"]}/>
);


export default ZSpinnerView;
