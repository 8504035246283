import {
    ADD_LOCATION,
    SET_LOCATIONS,
    SET_COUNTRY_LIST,
    SET_FLAG_COUNTRY_CODE,
    ADD_COUNTRY,
    SET_COUNTRIES
} from './location-action-types';
import ZApiClient from "../../../utils/client/z-api-client";

export const addLocationT = (T, country, id) => ({
    type: ADD_LOCATION(T),
    value: country,
    id,
});

export const setLocationsT = (T, list, id) => ({
    type: SET_LOCATIONS(T),
    list,
    id
});

const setCountryListT = (T, list) => ({
    type: SET_COUNTRY_LIST(T),
    list
});

export const setFlagCountryCodeT = (T, flagCountryCode, id) => ({
    type: SET_FLAG_COUNTRY_CODE(T),
    flagCountryCode,
    id,
});

export const addCountryT = (T, country, id) => ({
    type: ADD_COUNTRY(T),
    value: country,
    id,
});

export const setCountriesT = (T, list, id) => ({
    type: SET_COUNTRIES(T),
    list,
    id,
});

export const getCountriesT = (T) => {
    return ( dispatch ) => {
        ZApiClient
            .getLocationsClient()
            .getLocationNames({
                served: true,
            })
            .then((res)=>{
                let arr = [];
                for(const key in res){
                    arr.push(res[key]);
                }
                dispatch(setCountryListT(T, arr));
            });
    }
};
