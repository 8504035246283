import React, { useState } from 'react';
import FiltersView from './filters-view';

const FiltersContainer = ({
  isFetching,
  newsSources,
  onClickNewsSourceFilter,
  showFilters,
  toggleShowFilters,
}) => {
  const [selectedFilter, setSelectedFilter] = useState();

  const onClickNewsSource = (newsSourceId) => {
    onClickNewsSourceFilter(newsSourceId);
    setSelectedFilter(newsSourceId);
  }

  const onClickReset = () => {
    onClickNewsSourceFilter();
    setSelectedFilter();
  };

  return (
    <FiltersView
      isFetching={isFetching}
      newsSources={newsSources}
      onClickNewsSource={onClickNewsSource}
      onClickReset={onClickReset}
      selectedFilter={selectedFilter}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
    />
  );
};

export default FiltersContainer;
