/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './z-button.module.scss';

const ZButton = ({
  btnClass, btnStyle, disabled, onClick, text, textClass, textStyle, type,
}) => (
  <button
    className={`
      ${styles['button']}
      ${btnClass}
      ${disabled ? styles['button__disabled'] : ''}
    `}
    disabled={disabled}
    onClick={onClick}
    onKeyDown={(e) => { if (e.keyCode === 13) onClick(e); }}
    style={{ ...btnStyle }}
    type={type || "button"}
    tabIndex={0}
  >
    <span
      className={`${styles['button__label']} ${textClass}`}
      style={{ ...textStyle }}
      tabIndex={-1}
    >
      {text}
    </span>
  </button>
);
ZButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  btnStyle: PropTypes.object,
  btnClass: PropTypes.string,
  textStyle: PropTypes.object,
  textClass: PropTypes.string,
};

ZButton.defaultProps = {
  disabled: false,
  btnClass: null,
  btnStyle: {},
  textClass: null,
  textStyle: {},
  type: 'button',
};

export default ZButton;
