import { connect } from 'react-redux';
import NewsSnippetEditorContainer from './news-snippet-editor-container';
import {
  saveNewsSnippet,
  setDisplayed,
  setPublicationDate,
  setTeaser,
  setTitle,
  setUri,
} from '../../../state/actions/news-editor/news-snippets';

const mapStateToProps = (state, ownProps) => ({
    newsSnippet: state.news.newsSnippets[ownProps.id] || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  saveNewsSnippet: () => dispatch(saveNewsSnippet(ownProps.id, ownProps.closeModal)),
  setDisplayed: (val) => dispatch(setDisplayed(ownProps.id, val)),
  setPublicationDate: (val) => dispatch(setPublicationDate(ownProps.id, val)),
  setTeaser: (val) => dispatch(setTeaser(ownProps.id, val)),
  setTitle: (val) => dispatch(setTitle(ownProps.id, val)),
  setUri: (val) => dispatch(setUri(ownProps.id, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsSnippetEditorContainer);
