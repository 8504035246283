export const REQUEST_ARTICLE = 'REQUEST_ARTICLE';
export const REQUEST_NEW_ARTICLE = 'REQUEST_NEW_ARTICLE';
export const RECEIVED_ARTICLE = 'RECEIVED_ARTICLE';
export const ARTICLE_SAVE_REQUESTED = 'ARTICLE_SAVE_REQUESTED';
export const ARTICLE_SAVED = 'ARTICLE_SAVED';
export const REQUEST_ARTICLE_FAILED = 'REQUEST_ARTICLE_FAILED';

export const REQUEST_COMPANY_CATEGORY = 'REQUEST_COMPANY_CATEGORY';
export const REQUEST_NEW_COMPANY_CATEGORY = 'REQUEST_NEW_COMPANY_CATEGORY';
export const RECEIVED_COMPANY_CATEGORY = 'RECEIVED_COMPANY_CATEGORY';
export const COMPANY_CATEGORY_SAVE_REQUESTED = 'COMPANY_CATEGORY_SAVE_REQUESTED';
export const COMPANY_CATEGORY_SAVED = 'COMPANY_CATEGORY_SAVED';
export const REQUEST_COMPANY_CATEGORY_FAILED = 'REQUEST_COMPANY_CATEGORY_FAILED';

export const REQUEST_LANDING_PAGE_CATEGORY = 'REQUEST_LANDING_PAGE_CATEGORY';
export const REQUEST_NEW_LANDING_PAGE_CATEGORY = 'REQUEST_NEW_LANDING_PAGE_CATEGORY';
export const RECEIVED_LANDING_PAGE_CATEGORY = 'RECEIVED_LANDING_PAGE_CATEGORY';
export const LANDING_PAGE_CATEGORY_SAVE_REQUESTED = 'LANDING_PAGE_CATEGORY_SAVE_REQUESTED';
export const LANDING_PAGE_CATEGORY_SAVED = 'LANDING_PAGE_CATEGORY_SAVED';
export const REQUEST_LANDING_PAGE_CATEGORY_FAILED = 'REQUEST_LANDING_PAGE_CATEGORY_FAILED';

export const REQUEST_GUIDE = 'REQUEST_GUIDE';
export const REQUEST_NEW_GUIDE = 'REQUEST_NEW_GUIDE';
export const RECEIVED_GUIDE = 'RECEIVED_GUIDE';
export const GUIDE_SAVE_REQUESTED = 'GUIDE_SAVE_REQUESTED';
export const GUIDE_SAVED = 'GUIDE_SAVED';
export const REQUEST_GUIDE_FAILED = 'REQUEST_GUIDE_FAILED';

export const REQUEST_ITINERARY = 'REQUEST_ITINERARY';
export const REQUEST_NEW_ITINERARY = 'REQUEST_NEW_ITINERARY';
export const RECEIVED_ITINERARY = 'RECEIVED_ITINERARY';
export const ITINERARY_SAVE_REQUESTED = 'ITINERARY_SAVE_REQUESTED';
export const ITINERARY_SAVED = 'ITINERARY_SAVED';
export const ITINERARY_REQUEST_FAILED = 'ITINERARY_REQUEST_FAILED';

export const REQUEST_LANDING_PAGE = 'REQUEST_LANDING_PAGE';
export const REQUEST_NEW_LANDING_PAGE = 'REQUEST_NEW_LANDING_PAGE';
export const RECEIVED_LANDING_PAGE = 'RECEIVED_LANDING_PAGE';
export const LANDING_PAGE_SAVE_REQUESTED = 'LANDING_PAGE_SAVE_REQUESTED';
export const LANDING_PAGE_SAVED = 'LANDING_PAGE_SAVED';
export const REQUEST_LANDING_PAGE_FAILED = 'REQUEST_LANDING_PAGE_FAILED';

export const REQUEST_PAGE = 'REQUEST_PAGE';
export const REQUEST_NEW_PAGE = 'REQUEST_NEW_PAGE';
export const RECEIVED_PAGE = 'RECEIVED_PAGE';
export const PAGE_SAVE_REQUESTED = 'PAGE_SAVE_REQUESTED';
export const PAGE_SAVED = 'PAGE_SAVED';
export const REQUEST_PAGE_FAILED = 'REQUEST_PAGE_FAILED';
