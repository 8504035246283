export const validate = (object, validator) => {
    const errors = {};
    for (let key in validator) {
      const min = validator[key].min ? validator[key].min : null;
      if (validator[key] && key !== 'required' && key !== 'min' && key !== 'errorMessage') {
        // Check that field exists and meets minimum requirement
        // Unique check is happening in actions prior to PUT call
        if (!object[key] || object[key].length === 0 || (min && object[key].length < min)) {
          errors[key] = {
              message: validator[key].errorMessage
            };
        } else if (object[key] === Object(object[key]) && validator[key].requiredFields) {
            validator[key].requiredFields.forEach(field => {
                if (!object[key][field]) {
                    errors[key] = {
                        message: validator[key].errorMessage
                    };
                }
            });
        } else if (min && Array.isArray(object[key])) {
            object[key].forEach((item, i) => {
                if (!item && i < min && !errors[key]) {
                    errors[key] = {
                        message: validator[key].errorMessage
                    };
                } else if (item === Object(item) && key === 'sections') {
                    let empty = Object.keys(item).length === 0;
                    Object.values(item).forEach(val => {
                        empty = val === '' ? true : empty;
                    });
                    if (empty) errors[key] = { message: validator[key].errorMessage };
                }
            });
        }
      }
    }
    return Object.keys(errors).length > 0 ? errors : null;
}

export const validationErrorsPresent = (errors) => {
  return Object.keys(errors).length > 0;
}

export const itineraryValidationRequirements = {
    title: {
        required: true,
        unique: true,
        errorMessage: 'Title is required and must be unique.'
    },
    placesVisited: false,
    // destinations
    countries: {
      required: true,
      min: 1,
      errorMessage: 'A minimum of 1 destination country is required.'
  },
    // departureDates
    departureNotes: {
        required: true,
        errorMessage: 'Departure dates field is required.'
    },
    // marketingSummary
    summary: false,
    days: {
        required: true,
        min: 5,
        errorMessage: 'A minimum of 5 days are required.',
        day: {
            duration: {
                required: true,
                default: 1,
                errorMessage: 'Itinerary days must have a duration of 1 or more.'
            },
            location: false,
            title: {
                required: true,
                errorMessage: 'Each day must have a title.'
            },
            description: {
                required: true,
                errorMessage: 'Each day must have a description.'
            },
            included: false,
            meals: false
        }
    },
    // tripHighlights
    highlights: {
        required: true,
        errorMessage: 'Trip highlights are required.'
    },
    // detailedDescription
    description: false,
    // startingPrice
    price: false,
    priceComments: {
        required: true,
        errorMessage: 'Price comments are required.'
    },
    // whatsIncluded
    includedNotes: {
        required: true,
        errorMessage: 'Price notes on what\'s included are required'
    },
    // hero
    heroImage: {
        required: true,
        requiredFields: ['uri'],
        errorMessage: 'A hero image is required.'
    },
    // heroThumb
    thumbnailImage: false,
    // gallery
    images: {
        required: true,
        min: 5,
        alt: false,
        title: false,
        errorMessage: 'A minimum of 5 gallery images are required.'
    },
    // travelCompany
    company: false,
    paths: {
        required: true,
        min: 1,
        errorMessage: 'At least 1 path is required.'
    },
    metatags: {
        required: true,
        // titleTag
        title: {
            required: true,
            errorMessage: 'The meta tag title tag is required.'
        },
        // descriptionTag
        description: {
            required: true,
            errorMessage: 'The meta tag description tag is required.'
        },
        // canonicalUrl
        canonical: {
            required: true,
            errorMessage: 'A canonical path is required.'
        }
    }
};

export const landingPageValidationRequirements = {
    title: {
        required: true,
        unique: true,
        errorMessage: 'Title is required and must be unique.'
    },
    locations: false,
    summary: false,
    callToAction: {
        required: true,
        title: {
            required: true,
            errorMessage: 'A CTA Title is required.'
        },
        steps: {
            required: true,
            min: 3,
            errorMessage: 'All 3 CTA Steps are required'
        }
    },
    heroImage: {
        required: true,
        requiredFields: ['uri'],
        errorMessage: 'A hero image is required.'
    },
    mobileHeroImage: {
        required: true,
        requiredFields: ['uri'],
        errorMessage: 'A mobile hero image is required.'
    },
    itineraries: {
        required: true,
        min: 1,
        errorMessage: 'A minimum of 3 itineraries are required.'
    },
    content: false,
    footerLinks: false,
    paths: {
        required: true,
        min: 1,
        errorMessage: 'At least 1 path is required.'
    },
    metatags: {
        required: true,
        // titleTag
        title: {
            required: true,
            errorMessage: 'The metatag title tag is required.'
        },
        // descriptionTag
        description: {
            required: true,
            errorMessage: 'The metatag description tag is required.'
        },
        // canonicalUrl
        canonical: {
            required: true,
            errorMessage: 'A canonical path is required.'
        }
    }
};

export const landingPageOptionalRequirements = {
    content: {
        // Main LCC Title
        title: {
            required: true,
            errorMessage: 'Please add a main LCC title.'
        },
        sections: {
            required: true,
            min: 1,
            errorMessage: 'If there is LCC at least 1 section is required.',
            // Tab Title
            title: {
                required: true,
                errorMessage: 'Each LCC tab must have a title.'
            },
            // List of sections can include title, subtitle, text, or image
            sections: {
                required: true,
                min: 1,
                errorMessage: 'Each LCC tab must have at least one content section with content.'
            }
        }
    }
}
