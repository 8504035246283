import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Config from '../../../../config';
import { onCopy } from '../../../../utils/window';
import { decodeHTMLEntities } from '../../../../utils/utils';

const baseUrlWeb = Config.Z3_URL;

const CompanyCategoryCard = ({
  title,
  id,
  image,
  canonicalPath,
}) => (
  <Card
      sx={{ marginBottom: '24px', maxWidth: '50%' }}
    >
      <Box sx={{ display: 'flex', flex: '1 0 auto', flexDirection: 'row', justifyContent: 'space-between' }}>
        <CardMedia
          component="img"
          height={150}
          sx={{ width: '175px' }}
          image={image?.uri || '/zicasso-icon.png'}
          loading="lazy"
          alt={`${image?.alt}`}
          width={175}
        />
        <CardContent sx={{ flex: '1 0 0', display: 'flex', flexDirection: 'column'}}>
          <Typography component="div" color="secondary" variant="h5">
            {decodeHTMLEntities(title)}
          </Typography>
          { canonicalPath && (
            <Typography variant="subtitle1" component="span">
              Canonical Url:&nbsp;
              {canonicalPath}
            </Typography>
          )}
        </CardContent>
      </Box>
      <CardActions disableSpacing sx={{borderTop: '1px solid #ededed'}}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            target='_blank'
            type="button"
            href={
              `${baseUrlWeb}/dynamic?type=CompanyCategory&id=${id}`
            }
          >
            Preview
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            type="button"
            onClick={() => onCopy(`${baseUrlWeb}${canonicalPath}`)}
          >
            Get Link
          </Button>
        </Stack>
      </CardActions>
    </Card>
)

export default CompanyCategoryCard;
