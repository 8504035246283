import { useEffect } from 'react';

const CreateContainer = ({onLoad}) => {
  useEffect(()=> {
    onLoad();
  }, [onLoad]);

  return null;
}

export default CreateContainer;
