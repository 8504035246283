import React, { useRef, useState, useEffect } from 'react';
import {
  decodeHTMLEntities
} from '../../../utils/window';
import shallowCompare from '../../../utils/shallow-compare';
import SubnavTabView from './subnav-tab-view';
import ZApiClient from '../../../utils/client/z-api-client';
import { getCompanyCategoryTitles } from '../../../utils/fetch/company-category';

const entityTypeMap = {
  guide : 'Guide',
  article: 'Article',
  landing_page: 'LandingPage',
  company_categories: 'CompanyCategory',
};

const typeEntityMap = {
  Guide : 'guide',
  Article : 'article',
  LandingPage : 'landing_page',
  CompanyCategory: 'company_categories',
};

const SubnavTabContainer = ({
  index,
  tab,
  onAdd,
  navErrors,
  isHost,
  onDelete,
}) => {
  const [localTab, setLocalTab] = useState({});
  const [deleteBtn, toggleDeleteBtn] = useState(false);
  const [entityType, setEntityType] = useState();
  const [inputPlaceholder, setInputPlaceholder] = useState(tab.uri ? tab.uri : 'Add new tab link');
  const [titleMap, setTitleMap] = useState();
  const latestEntity = useRef(entityType);
  const latestTitleMap = useRef(titleMap);
  const functionRef = useRef(setLocalTab);

  useEffect(()=> {
    if(tab.entityId){
      setLocalTab(tab);
      setEntityType(typeEntityMap[tab.entityType]);
      toggleDeleteBtn(true);
    }
  }, [tab]);

  useEffect(() => {
    if (index === 1 ) setEntityType('guide');
    if (index > 1 && index < 4) setEntityType('article');
    if (index === 4) setEntityType('company_categories');
  }, [index])

  /**
   * All the bellow useEffect are need to pass current values
   * into the closure created by ZAutocomplete prop to state binding.
   */
  useEffect(() => {
    latestEntity.current = entityType;
  }, [entityType]);

  useEffect(()=>{
    latestTitleMap.current = titleMap;
  }, [titleMap]);

  const shortenStr = (str, limit = 20) => {
    const trimmed = str.trim();
    return trimmed.slice(0, limit);
  }

  const cleanTitles = (obj) => {
    let ret = {};
    for(const key in obj){
      ret[key] = decodeHTMLEntities(obj[key]);
    }
    return ret;
  }

  const getEntityMap = (obj, type) => {
    let ret = {};
    for(const key in obj){
      let temp = {
        title: obj[key],
        type: type,
      };
      ret[key] = temp;
    }
    return ret;
  }

  // Auto comoplete function must be thenable
  const onSearch = async (text) => {
    const searchText = text.toLowerCase();
    const currentEntity = latestEntity.current;
    if ( !!currentEntity && currentEntity !== 'company_categories' ) {
      return ZApiClient
      .getClientFromType(currentEntity)
      .getAllTitlesWithIdsByNameFragment(searchText)
      .then((res)=> {
        setTitleMap((prevState) =>{ return {...prevState, ...getEntityMap(res, currentEntity)} })
        return cleanTitles(res);
      });
    } else if ( !!currentEntity && currentEntity === 'company_categories' ) {
      return getCompanyCategoryTitles(text)
      .then((res) => {
        setTitleMap((prevState) =>{ return {...prevState, ...getEntityMap(res, currentEntity)} })
        return cleanTitles(res);
      })
    } else {
      return Promise.resolve({});
    }
  }

  const onSelect = (obj) => {
    const current = Object.entries(obj);
    const [id, title] = current[0];
    const currentMap = latestTitleMap.current
    const currentEntity = currentMap[id];
    if(currentEntity){
      ZApiClient
      .getPathsClient()
      .readPaths({ entity: id })
      .then((res) => {
        return res.filter((ele)=> !ele.redirect)
      }).then(([canonical]) => {
        /**
         * @TODO Add help text for no canonical
         */
        if(canonical){
          setInputPlaceholder(canonical.alias);
          functionRef.current({
            title : currentEntity.type === 'company_categories' ? 'Travel Agents' : shortenStr(title),
            shortTitle: currentEntity.type === 'company_categories' ? 'Travel Agents' : shortenStr(title),
            entityId: id,
            uri: canonical.alias,
            entityType: entityTypeMap[currentEntity.type]
          });
        }
      })
    }
  }

  const onDesktopTitleChange = (text) => {
    if(text.length <= 20){
      setLocalTab(current => {
        return({
          ...current,
          title: text,
        })
      });
    }
  }

  const onMobileTitleChange = (text) => {
    if(text.length <= 20){
      setLocalTab(current =>{
        return({
          ...current,
          shortTitle: text,
        })
      });
    }
  }

  const onTitleChange = (text, field) => {
    const methods = {
      mobile: (text) => onMobileTitleChange(text),
      desktop: (text) => onDesktopTitleChange(text),
    }
    methods[field](text);
  }

  const onAddClick = () => {
    toggleDeleteBtn(true);
    onAdd(localTab, index);
  }

  const onDeleteClick = () => {
    setLocalTab({
      title: '',
      shortTitle: '',
    });
    setInputPlaceholder('');
    toggleDeleteBtn(false);
    onDelete(index);
  }

  const onUpdateClick = () => {
    onAdd(localTab, index);
  }

  const onEntityChange = (e) => {
    const val = e.currentTarget.value;
    if(entityType !== 'Add new tab link'){
      setInputPlaceholder('Add new tab link');
    }
    setEntityType(val);
  }

  return (
    <SubnavTabView
      index={index}
      currentEntity={entityType}
      setEntityType={setEntityType}
      onEntityChange={onEntityChange}
      onSearch={onSearch}
      onSelect={onSelect}
      inputPlaceholder={inputPlaceholder}
      title={localTab.title}
      shortTitle={localTab.shortTitle}
      onTitleChange={onTitleChange}
      onAddClick={onAddClick}
      onDeleteClick={onDeleteClick}
      onUpdateClick={onUpdateClick}
      disableAdd={ !isHost && navErrors }
      deleteBtn={deleteBtn}
      isHost={isHost}
    />
  )
};

export default SubnavTabContainer;
