import React, { useEffect, useRef, useState } from 'react';
import ZApiClient from '../../../utils/client/z-api-client';
import ContentView from './content-view';

const ContentContainer = ({
  currentEntity,
  onChangePhoto,
  onCreateHeaderPhoto,
  onDeleteHeaderPhoto,
  onFlagCountryCodeChange,
  onSubtitleChange,
  onSummaryChange,
  photos,
}) => {
  const [useParentImage, setUseParentImage] = useState(!currentEntity.heroImage);
  const isoCodesRef = useRef({});

  useEffect(()=> {
    const getIsoCodes = () => {
      return ZApiClient
        .getGeoNamesClient()
        .getCountries({ sorted: true, fields: ['country,isoCode'] })
        .then((res) => {
          const countries = res.reduce(
            (countriesMap, fullCountry) => {
              const { isoCode, country } = fullCountry;
              if (isoCode && country) {
                // eslint-disable-next-line no-param-reassign
                countriesMap[isoCode] = country;
              }
              return countriesMap;
            },
            {},
          );
          return countries;
        });
    }

    async function setCodes () {
      const isCodeMap = await getIsoCodes();
      isoCodesRef.current = isCodeMap;
    }

    setCodes();
  }, []);

  const autoComplete = (text) => {
    return new Promise((resolve) => {
      const allOptions = isoCodesRef.current;
      let options = {}
      for (const option in allOptions) {
        if (allOptions.hasOwnProperty(option)) {
          const test = allOptions[option].toLowerCase();
          if (test.includes(text.toLowerCase())) {
            options[option] = allOptions[option];
          }
        }
      }
      return resolve(options);
    });
  }

  const onTitleFlagSelect = (option) => {
    const [isoCode] = Object.keys(option);
    onFlagCountryCodeChange(isoCode);
  }

  return(
    <ContentView
      autoComplete={autoComplete}
      flagCountryCode={currentEntity.flagCountryCode}
      headerPhotoId={currentEntity.headerPhotoId}
      id={currentEntity.id}
      landingPageHeaderPhotoId={currentEntity.landingPageSnippet?.heroImage?.id}
      onChangePhoto={onChangePhoto}
      onCreateHeaderPhoto={onCreateHeaderPhoto}
      onDeleteHeaderPhoto={onDeleteHeaderPhoto}
      onFlagCountryCodeChange={onFlagCountryCodeChange}
      onSubtitleChange={onSubtitleChange}
      onSummaryChange={onSummaryChange}
      onTitleFlagSelect={onTitleFlagSelect}
      photos={photos}
      setUseParentImage={setUseParentImage}
      useParentImage={useParentImage}
      subtitle={currentEntity.subtitle}
      summary={currentEntity.summary}
    />
  );
}

export default ContentContainer;
