import ZApiClient from "../client/z-api-client";

export const getUsageReferences = (entityId) => {
  return ZApiClient.getReferencesClient().readAllUsageReferences(entityId);
};

export const getPublicationReferences = (entityId) => {
  return ZApiClient.getPublicationsClient().readPublicationReferences({
    child: entityId,
  });
};
