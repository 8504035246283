import {
  SET_ERRORS 
} from '../errors';

const initialErrorState = {
  title : {
    valid: true,
  },
  placesVisited:{
    valid: true,
  },
  countries:{
    valid: true,
  },
  departureNotes:{
    valid: true,
  },
  summary:{
    valid: true,
  },
  days:{
    valid: true,
  },
  highlights:{
    valid: true,
  },
  description:{
    valid: true,
  },
  price: {
    valid: true,
  },
  priceComments: {
    valid: true,
  },
  includedNotes: {
    valid: true,
  },
  heroImage:{
    valid: true,
  },
  thumbnail: {
    valid: true,
  },
  images: {
    valid: true,
  },
};

export const setErrors = (id, errors = initialErrorState) => ({
  type: SET_ERRORS,
  id,
  errors,
});
