import React from 'react';
import ZInput from '../../../z-input';
import style from './author.module.scss';

const AuthorView = ({
  authorName,
  authorLink,
  onAuthorNameChange,
  onAuthorLinkChange,
}) => (
  <>
    <br/>
    <h3>Article Author</h3>
    <div className={style['author-wrapper']}>
      <div className={style['inputs-wrapper']}>
        <div className={style['input-item-wrapper']}>
          <ZInput
            label={`Author Name`}
            onChange={onAuthorNameChange}
            text={authorName}
            style={{marginBottom: '28px', width: '100%'}}
            inputKey={'author_name'}
            error={!authorName ? {message: 'Add an author name'} : false }
          />
        </div>
        <div className={style['input-item-wrapper']}>
          <ZInput
            label={`Author Link`}
            onChange={onAuthorLinkChange}
            text={authorLink}
            style={{marginBottom: '28px', width: '100%'}}
            inputKey={'author_link'}
            error={!authorLink ? {message: 'Add an author link'} : false }
          />
        </div>
      </div>
    </div>
  </>
);

export default AuthorView;
