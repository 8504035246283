import React from 'react';
import NewsSourceView from './news-source-view';

const NewsSourceContainer = ({
  createNew,
  fetchNewsSource,
  fetchNewsSourceNames,
  newsSource,
  onClickEdit,
  setEditNewsSourceId,
  setSnippetSource,
}) => {
  const handleSelect = (source) => {
    fetchNewsSource(Object.keys(source)[0])
      .then(setSnippetSource);
  };

  const onClickCreateSource = () => {
    setEditNewsSourceId('new');
    createNew();
  }

  return (
    <NewsSourceView
      fetchNewsSourceNames={fetchNewsSourceNames}
      newsSource={newsSource}
      onClickCreateSource={onClickCreateSource}
      onClickEdit={onClickEdit}
      onSelect={handleSelect}
    />
  );
};

export default NewsSourceContainer;
