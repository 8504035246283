export const ADD_FOOTER_SECTION = (T) => `ADD_FOOTER_SECTION_${T.toUpperCase()}`;
export const ADD_SECTION = (T) => `ADD_SECTION_${T.toUpperCase()}`;
export const ADD_SECTION_LINK = (T) => `ADD_SECTION_LINK_${T.toUpperCase()}`;
export const RECEIVED_FOOTER_SECTIONS = (T) => `RECEIVED_FOOTER_SECTIONS_${T.toUpperCase()}`;

export const RECEIVED_SECTIONS = (T) => `RECEIVED_SECTIONS_${T.toUpperCase()}`;
export const REMOVE_FOOTER_SECTION = (T) => `REMOVE_FOOTER_SECTION_${T.toUpperCase()}`;
export const REMOVE_SECTION = (T) => `REMOVE_SECTION_${T.toUpperCase()}`;
export const REMOVE_SECTION_LINK = (T) => `REMOVE_SECTION_LINK_${T.toUpperCase()}`;

export const REORDER_FOOTER_SECTIONS = (T) => `REORDER_FOOTER_SECTIONS_${T.toUpperCase()}`;
export const REORDER_SECTIONS = (T) => `REORDER_SECTIONS_${T.toUpperCase()}`;
export const SET_LINK_TITLE = (T) => `SET_LINK_TITLE_${T.toUpperCase()}`;
export const SET_LINK_URI = (T) => `SET_LINK_URI_${T.toUpperCase()}`;

export const SET_SECTION_LINKS = (T) => `SET_SECTION_LINKS_${T.toUpperCase()}`;
export const SET_SECTION_TEXT = (T) => `SET_SECTION_TEXT_${T.toUpperCase()}`;
export const SET_SECTION_TITLE = (T) => `SET_SECTION_TITLE_${T.toUpperCase()}`;
export const UPDATE_SECTION = (T) => `UPDATE_SECTION_${T.toUpperCase()}`;

export const SECTION_ACTION_NAMES = {};

(() => {
    [
        'article',
        'company_category',
        'landing_page_category',
        'content',
        'guide',
        'itinerary',
        'landing_page'
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        SECTION_ACTION_NAMES[entityType] = {};

        SECTION_ACTION_NAMES[entityType]['ADD_FOOTER_SECTION'] = ADD_FOOTER_SECTION(entityType);
        SECTION_ACTION_NAMES[entityType]['ADD_SECTION'] = ADD_SECTION(entityType);
        SECTION_ACTION_NAMES[entityType]['ADD_SECTION_LINK'] = ADD_SECTION_LINK(entityType);
        SECTION_ACTION_NAMES[entityType]['RECEIVED_FOOTER_SECTIONS'] = RECEIVED_FOOTER_SECTIONS(entityType);

        SECTION_ACTION_NAMES[entityType]['RECEIVED_SECTIONS'] = RECEIVED_SECTIONS(entityType);
        SECTION_ACTION_NAMES[entityType]['REMOVE_FOOTER_SECTION'] = REMOVE_FOOTER_SECTION(entityType);
        SECTION_ACTION_NAMES[entityType]['REMOVE_SECTION'] = REMOVE_SECTION(entityType);
        SECTION_ACTION_NAMES[entityType]['REMOVE_SECTION_LINK'] = REMOVE_SECTION_LINK(entityType);

        SECTION_ACTION_NAMES[entityType]['REORDER_FOOTER_SECTIONS'] = REORDER_FOOTER_SECTIONS(entityType);
        SECTION_ACTION_NAMES[entityType]['REORDER_SECTIONS'] = REORDER_SECTIONS(entityType);
        SECTION_ACTION_NAMES[entityType]['SET_LINK_TITLE'] = SET_LINK_TITLE(entityType);
        SECTION_ACTION_NAMES[entityType]['SET_LINK_URI'] = SET_LINK_URI(entityType);

        SECTION_ACTION_NAMES[entityType]['SET_SECTION_LINKS'] = SET_SECTION_LINKS(entityType);
        SECTION_ACTION_NAMES[entityType]['SET_SECTION_TEXT'] = SET_SECTION_TEXT(entityType);
        SECTION_ACTION_NAMES[entityType]['SET_SECTION_TITLE'] = SET_SECTION_TITLE(entityType);
        SECTION_ACTION_NAMES[entityType]['UPDATE_SECTION'] = UPDATE_SECTION(entityType);
    });
})();
