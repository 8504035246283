import React, { useEffect, useState, useRef } from 'react';
import ZPhotoPreviewView from './z-photo-preview-view';
import Validate from '../../utils/validate';
import useDebounce from '../../hooks/use-debounce';

const ZPhotoPreviewContainer = ({
  alt,
  class: className,
  column,
  data,
  gravity,
  onUpdate,
  readOnly,
  removePhoto,
  showInfo,
  simpleView,
  src: uri,
  style,
  title,
  transformation,
}) => {
  const index = data?.sourceType === 'Cloudinary' ? data.sourceUri.indexOf('upload/') : '';
  const defaultId = !data.id && Date.now();
  const info = {
    createdAt: data.createdAt || '',
    fileName: data?.sourceType === 'Cloudinary' ? data.sourceUri.substring(index + 7) : '',
    height: data.height || '',
    sourceType: data.sourceType || '',
    sourceUri: data.sourceUri || '',
    width: data.width || '',
  };

  const [imageFields, setImageFields] = useState({
    ...alt && { alt },
    ...gravity && { gravity },
    ...title && { title },
    ...uri && { uri },
  });
  const [isValid, setIsValid] = useState(true);
  const [debouncedImageFields] = useDebounce(imageFields, 500);
  const initialLoadRef = useRef(true);

  useEffect(() => {
    if (!initialLoadRef.current) {
      const reqObj = {
        ...data,
        ...debouncedImageFields,
      };
      if (isValid && onUpdate) {
        onUpdate(reqObj, index);
      }
    } else {
      initialLoadRef.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedImageFields, isValid, index]);

  const onChange = (label) => (text) => {
    setImageFields((prev) => ({
      ...prev,
      [label]: text,
    }));
    if (label === 'uri') setIsValid(Validate.imageUrl(text));
  };

  return (
    <ZPhotoPreviewView
      alt={imageFields.alt}
      class={className}
      column={column}
      gravity={imageFields.gravity}
      info={info}
      isValid={isValid}
      onChange={onChange}
      photoId={data.id || defaultId}
      readOnly={readOnly}
      removePhoto={removePhoto}
      showInfo={showInfo}
      src={imageFields.uri}
      style={style}
      title={imageFields.title}
      simpleView={simpleView}
      transformation={transformation || {}}
    />
  );
};

export default ZPhotoPreviewContainer;
