/* eslint-disable react/no-array-index-key */
import React from 'react';
import styles from './z-chips.module.scss';

const defaultProps = {
  style: {},
};

const ZChips = ({
  onChange,
  data,
  style,
}) => {
  const onClick = (val) => {
    const arr = [...data];
    const index = arr.map((e) => e.label).indexOf(val);
    const resp = [...arr.slice(0, index), ...arr.slice(index + 1)];
    onChange(resp);
  };

  return (
    <div className={`${styles['z-chip__container']}`} style={{ ...style }}>
      {data.map((ele, i) => (
        <span className={`${styles['z-chip']}`} key={`${ele.label}-${i}`}>
          {ele.label}
          <button
            className={`${styles['z-chip__close']}`}
            onClick={() => onClick(ele.label)}
            type="button"
          >
            X
          </button>
        </span>
      ))}
    </div>
  );
};
ZChips.defaultProps = defaultProps;
export default ZChips;
