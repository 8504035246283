import React from 'react';
import { connect } from 'react-redux';
import {
  addPlace,
  setPlacesVisited,
} from '../../../state/actions/itinerary-editor';
import ContentContainer from './content-container';
import {setSummaryT} from "../../../state/actions/shared/title";

const ContentRedux = (props) => (
  <ContentContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId: id} = ownProps;
  const { errors } = state;
  const { editor } = state.itineraries.itinerary;
  const currentEntity = editor[id] || {};
  const currentErrors = errors[id] || {};
  const {
    placesVisited: placesVisitedError,
    summary: summaryError,
  } = currentErrors;
  return {
    id,
    itinerary : currentEntity,
    summaryError,
    placesVisited : currentEntity.placesVisited || [],
    placesVisitedError,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId: id} = ownProps;
  return {
    onUpdateSummary: (text) => dispatch(setSummaryT('itinerary', text, id)),
    onPlacesSelect: (place) => dispatch(addPlace(place, id)),
    onPlacesDelete: (placesList) => dispatch(setPlacesVisited(placesList, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentRedux);
