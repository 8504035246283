import React, { useState, useMemo } from 'react';
import { searchLandingPages } from '../../../utils/fetch/landing-page';
import { getParentBreadcrumb } from '../../../utils/breadcrumbs';
import ParentView from './parent-view';

const ParentContainer = ({
  breadcrumb,
  deleteBreadcrumb,
  fetchParentLandingPageSnippet,
  landingPageSnippet,
  setBreadcrumb,
}) => {
  const [error, setError] = useState(false);
  const loading = useMemo(() => (
    !!breadcrumb.entityId && breadcrumb.entityId !== landingPageSnippet?.id
  ), [breadcrumb, landingPageSnippet]);

  const onSearch = (text) => {
    setError(false);
    return searchLandingPages(text.toLowerCase());
  };

  const onSelect = (option) => {
    const [entityId] = Object.entries(option)[0];

    if (entityId && entityId !== 'undefined') {
      getParentBreadcrumb('LandingPage', entityId)
        .then((res) => {
          if (res.httpStatus) throw new Error('Error fetching breadcrumb');
          setBreadcrumb(res[res.length - 1]);
          fetchParentLandingPageSnippet(res[res.length - 1].entityId);
        })
        .catch(() => setError(true));
      }
  };

  const onDelete = () => {
    deleteBreadcrumb();
  };

  return (
    <ParentView
      breadcrumb={breadcrumb}
      loading={loading}
      error={error}
      onDelete={onDelete}
      onSearch={onSearch}
      onSelect={onSelect}
    />
  );
};

export default ParentContainer;
