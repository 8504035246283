export const ADD_BREADCRUMB  = (T) => `ADD_BREADCRUMB_${T.toUpperCase()}`;
export const DELETE_BREADCRUMB = (T) => `DELETE_BREADCRUMB_COMPANY_${T.toUpperCase()}`;
export const DELETE_BREADCRUMBS = (T) => `DELETE_BREADCRUMBS_${T.toUpperCase()}`;
export const DELETE_BREADCRUMB_TITLE = (T) => `DELETE_BREADCRUMB_TITLE_${T.toUpperCase()}`;
export const REQUEST_BREADCRUMBS = (T) => `REQUEST_BREADCRUMBS_${T.toUpperCase()}`;
export const SET_BREADCRUMB = (T) => `SET_BREADCRUMB_${T.toUpperCase()}`;
export const SET_BREADCRUMBS = (T) => `SET_BREADCRUMBS_${T.toUpperCase()}`;
export const SET_BREADCRUMBS_ERROR = (T) => `SET_BREADCRUMBS_ERROR_${T.toUpperCase()}`;
export const SET_BREADCRUMB_TITLE = (T) => `SET_BREADCRUMB_TITLE_${T.toUpperCase()}`;

export const BREADCRUMB_ACTION_NAMES = {};

(() => {
    [
        'article',
        'company_category',
        'landing_page_category',
        'content',
        'guide',
        'itinerary',
        'landing_page'
    ].forEach((entityType) => {
        entityType = entityType.toUpperCase();
        BREADCRUMB_ACTION_NAMES[entityType] = {};
        BREADCRUMB_ACTION_NAMES[entityType]['ADD_BREADCRUMB'] = ADD_BREADCRUMB(entityType);
        BREADCRUMB_ACTION_NAMES[entityType]['DELETE_BREADCRUMB'] = DELETE_BREADCRUMB(entityType);
        BREADCRUMB_ACTION_NAMES[entityType]['DELETE_BREADCRUMBS'] =  DELETE_BREADCRUMBS(entityType);
        BREADCRUMB_ACTION_NAMES[entityType]['DELETE_BREADCRUMB_TITLE'] = DELETE_BREADCRUMB_TITLE(entityType);
        BREADCRUMB_ACTION_NAMES[entityType]['REQUEST_BREADCRUMBS'] = REQUEST_BREADCRUMBS(entityType);
        BREADCRUMB_ACTION_NAMES[entityType]['SET_BREADCRUMB'] = SET_BREADCRUMB(entityType);
        BREADCRUMB_ACTION_NAMES[entityType]['SET_BREADCRUMBS'] = SET_BREADCRUMBS(entityType);
        BREADCRUMB_ACTION_NAMES[entityType]['SET_BREADCRUMBS_ERROR'] = SET_BREADCRUMBS_ERROR(entityType);
        BREADCRUMB_ACTION_NAMES[entityType]['SET_BREADCRUMB_TITLE'] = SET_BREADCRUMB_TITLE(entityType);
    });
})();
