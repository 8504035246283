/* eslint-disable no-use-before-define */
import React, { useState, useRef, useEffect, useMemo }from 'react';
import Editor from '@draft-js-plugins/editor';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createCounterPlugin from '@draft-js-plugins/counter';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import {
  EditorState, 
  RichUtils, 
  getDefaultKeyBinding, 
  CompositeDecorator, 
  convertFromHTML,
  ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {
  ItalicButton,
  BoldButton,
  UnorderedListButton,
  OrderedListButton } from '@draft-js-plugins/buttons';
import createLinkPlugin from '@draft-js-plugins/anchor';
import './rich-editor.scss';
import sharedStyle from '../shared/styles/shared.module.scss';
import urlRegex from '../../utils/validate/urlRegex';
import useDebounce from '../../hooks/use-debounce';

const getContentBlock = (value) => {
  let contentBlock;
  const blocksFromHTML = convertFromHTML(value);
  contentBlock = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return contentBlock;
}

const fixHttpZicassoLinks = (url) => {
 if (url.startsWith('http:')) return url.replace('http:', 'https:')
 return url;
};

const ZTextAreaContainer = ({
    allowedFormats,
    className,
    disableTextCounter,
    error,
    inputKey,
    label,
    onUpdate = () => {},
    placeholder,
    readOnly,
    READ_ONLY, 
    stripTags,
    style,
    textAreaStyle,
    value,
    openInternalLinksInNewTab
  }) => {

  const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
  ]);

  const contentBlock = value ? getContentBlock(value) : null;

  const [editorState, setEditorState] = useState(contentBlock ? () => EditorState.createWithContent(contentBlock, decorator) : () => EditorState.createEmpty(decorator));
  const [editorContentHtml, setEditorContentHtml] = useState();
  const [debouncedInputText] = useDebounce(editorContentHtml, 300);
  const [httpLinkError, setHttpLinkError] = useState(false);

  const [plugins, InlineToolbar, CharCounter, WordCounter, LinkButton] = useMemo(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugin()
    const counterPlugin = createCounterPlugin()
    const linkPlugin = createLinkPlugin({
      placeholder: 'https://... or /luxury...',
      validateUrl: (e) => {
        if (e.startsWith('http:')) {
          setHttpLinkError(true);
          return false;
        }
        setHttpLinkError(false);
        if (e.startsWith('/')) return true;
        return urlRegex().test(e);
      },
      theme: {
        inputInvalid: sharedStyle['error']
      },
    });
    return [
      [inlineToolbarPlugin, counterPlugin, linkPlugin], 
      inlineToolbarPlugin.InlineToolbar, 
      counterPlugin.CharCounter, 
      counterPlugin.WordCounter, 
      linkPlugin.LinkButton,
    ];
  },[]);

  const editorRef = useRef();

  useEffect(() => {
      if (debouncedInputText) {
        const contentChanged = value !== debouncedInputText.replace(/(\r\n|\n|\r)/gm, '');
        const emptyEditor 
          = debouncedInputText === '<p><br></p>'
          || debouncedInputText === '<p>&nbsp;</p>' 
          || debouncedInputText === '<p></p>' 
          || debouncedInputText === '';
        const emptyValue = value === undefined || value === '';

        const shouldUpdate = (!emptyEditor || !emptyValue) && contentChanged;
        if(shouldUpdate) {
          let text = debouncedInputText.replace(/(\r\n|\n|\r)/gm, '');
          text = (text === '<p><br></p>' || text === '<p>&nbsp;</p>' || text === '<p></p>') ? '' : text;
          onUpdate(text);
        };
      }
  },[debouncedInputText, onUpdate, value])

  const mapAllowedStyles = (arr) => {
    let resp = [];
    if(!Array.isArray(arr)){
      return resp
    }
    arr.forEach((ele)=>{
      switch (ele) {
        case 'BOLD':
          resp.push(BoldButton);
          break;
        case 'ITALICS':
          resp.push(ItalicButton);
          break;
        case 'BULLET_POINTS':
          resp.push(UnorderedListButton);
          break;
        case 'ORDERED_LIST':
          resp.push(OrderedListButton);
          break;
        case 'LINKS':
          resp.push(LinkButton);
          break;
        default:
          break;
      }
    });
    return resp;
  };

  const allowedTextFormats = mapAllowedStyles(allowedFormats);

  const onChange = (state) => {
    const options = {
      entityStyleFn: ( entity ) => {
        if ( entity.get('type').toLowerCase() === 'link' ) {
          const data = entity.getData();
          const internal = data.url.indexOf('zicasso') !== -1 || data.url.indexOf('.') === -1;
          const target = (openInternalLinksInNewTab && internal) || !internal ? '_blank' : undefined;

          return {
            element: 'a',
            attributes: {
              href: !internal ? data.url : fixHttpZicassoLinks(data.url),
              target: target
            }
          };
        }
      }
    };
    setEditorState(state);
    let updatedContentHtml = stateToHTML(state.getCurrentContent(), options);

    if (stripTags && updatedContentHtml.startsWith('<p>') && updatedContentHtml.endsWith('</p>')) {
      updatedContentHtml = updatedContentHtml.slice(3, -4);
    };
    setEditorContentHtml(updatedContentHtml);
  };

  const onFocus = () => {
    editorRef.current?.focus();
  };

  /**
   * Boiler plate for enabling keyCommands
   */
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  /**
   * Adds tab to the known list of keyCommands
   * Allows tab to create a new paragraph
   */
  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  // const { InlineToolbar } = inlineToolbarPlugin;
  // const { CharCounter, WordCounter } = counterPlugin;

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let RichEditorClassName = 'RichEditor-editor';
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      RichEditorClassName += ' RichEditor-hidePlaceholder';
    }
  };

  return (
    <div key={inputKey} style={style}>
      {label &&
        <span>{label}</span>
      }
      {error &&
        <p className={sharedStyle['error']}>{error.message}</p>
      }
      {httpLinkError &&
        <p className={sharedStyle['error']}>Please use a secure &#40;https&#41; link</p>
      }
      <div className={`RichEditor-root ${className ? className : ''}`} style={{ marginBottom: '28px', ...textAreaStyle }}>
        <div className={RichEditorClassName} style={(allowedTextFormats.richEditor) ? {position: 'relative', margin: '0', padding: '0'} : {borderTop: 'none', position: 'relative', margin: '0', padding: '0'}} onClick={onFocus}>
          <Editor
            blockStyleFn={getBlockStyle}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder={placeholder ? placeholder : ''}
            readOnly={readOnly || READ_ONLY}
            ref={editorRef}
            spellCheck={true}
            plugins={plugins}
          />
          <InlineToolbar>
            {(externalProps) => (
              (allowedTextFormats.map((Button, i) =>
                <Button key={i} {...externalProps} />
              ))
            )}
          </InlineToolbar>
        </div>
      </div>
      { !disableTextCounter &&
        <div className={'textCounter'}
          style={{ marginBottom: '20px', marginTop: '-15px', color: 'rgba(0, 0, 0, 0.5)', fontSize: '0.8em' }}
        >
          <CharCounter/> chars / <WordCounter/> words
        </div>
      }
    </div>
  )
}

/** Function for adding styles to specific blocks (Blocks like <ul>) */
function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    case 'unstyled': return 'z-text-area-block__unstyled';
    default: return null;
  }
}

/**
 *  Function used by the compositeDecorator to find enties in the
 *  contentState that match the type 'Link'
 */
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

/**
 * Used in conjunction with the findLinkEntities to decide how to render
 * entities that are found by findLinkEntities, required by the compositeDecorator
 */
const Link = ({children, contentState, entityKey, openInternalLinksInNewTab}) => {
  const {url} = contentState.getEntity(entityKey).getData();
  const internal = url.indexOf('zicasso') !== -1 || url.indexOf('.') === -1;

  const target = (openInternalLinksInNewTab && internal) || !internal ? '_blank' : undefined;
  const rel = (openInternalLinksInNewTab && internal) || !internal ? 'noopener noreferrer' : undefined;

  return (
    <a href={url} target={target} rel={rel}>
      {children}
    </a>
  );
};

export default ZTextAreaContainer;
