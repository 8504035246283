export const SET_ERROR = 'SET_ERROR';
export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const setIsFetching = (isFetching) => ({
  type: SET_IS_FETCHING,
  isFetching,
});

export const SET_ALL_COMPANIES = 'SET_ALL_COMPANIES';
export const setAllCompanies = (companies, offset) => ({
  type: SET_ALL_COMPANIES,
  companies,
  offset,
});

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const setSearchResults = (searchResults) => ({
  type: SET_SEARCH_RESULTS,
  searchResults,
});

export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});

export const SET_SEARCH_RESULT_BY_ID = 'SET_SEARCH_RESULT_BY_ID';
export const setSearchResultById = (companyId, company) => ({
  type: SET_SEARCH_RESULT_BY_ID,
  company,
  companyId,
});

export const SET_SEARCH_RESULTS_BY_ID = 'SET_SEARCH_RESULTS_BY_ID';
export const setSearchResultsById = (searchResultsById) => ({
  type: SET_SEARCH_RESULTS_BY_ID,
  searchResultsById,
});

export const SET_COMPANY_BY_ID = 'SET_COMPANY_BY_ID';
export const setCompanyById = (companyId, company) => ({
  type: SET_COMPANY_BY_ID,
  company,
  companyId,
});

export const SET_COMPANIES_BY_ID = 'SET_COMPANIES_BY_ID';
export const setCompaniesById = (companies) => ({
  type: SET_COMPANIES_BY_ID,
  companies,
});

// Filters, sort, search
export const SET_COMPANY_FILTERS = 'SET_COMPANY_FILTERS';
export const setCompanyFilters = (filters) => ({
  type: SET_COMPANY_FILTERS,
  data: filters,
});

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const setSearchTerm = (term) => ({
  type: SET_SEARCH_TERM,
  data: term,
});

export const SET_SORT_ORDER = 'SET_SORT_ORDER';
export const setSortOrder = (sortOrder) => ({
  type: SET_SORT_ORDER,
  data: sortOrder,
});

export const SET_PROFILE_STATUS = 'SET_PROFILE_STATUS';
export const setProfileStatus = (published) => ({
  type: SET_PROFILE_STATUS,
  data: published,
});

export const SET_FIRST_NAME_EXISTS = 'SET_FIRST_NAME_EXISTS';
export const setFirstNameExists = (exists) => ({
  type: SET_FIRST_NAME_EXISTS,
  data: exists,
});

export const SET_PHOTO_EXISTS = 'SET_PHOTO_EXISTS';
export const setPhotoExists = (exists) => ({
  type: SET_PHOTO_EXISTS,
  data: exists,
});

export const SET_DESCRIPTION_EXISTS = 'SET_DESCRIPTION_EXISTS';
export const setDescriptionExists = (exists) => ({
  type: SET_DESCRIPTION_EXISTS,
  data: exists,
});

export const SET_PRIMARY_LOCATIONS = 'SET_PRIMARY_LOCATIONS';
export const setPrimaryLocations = (locations) => ({
  type: SET_PRIMARY_LOCATIONS,
  data: locations,
});

export const SET_SECONDARY_LOCATIONS = 'SET_SECONDARY_LOCATIONS';
export const setSecondaryLocations = (locations) => ({
  type: SET_SECONDARY_LOCATIONS,
  data: locations,
});

export const SET_ACTIVITY_LEVELS = 'SET_ACTIVITY_LEVELS';
export const setActivityLevels = (activityLevels) => ({
  type: SET_ACTIVITY_LEVELS,
  data: activityLevels,
});

// Editable Fields
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const setFirstName = (companyId, name) => ({
  type: SET_FIRST_NAME,
  companyId,
  data: name,
});

export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const setDescription = (companyId, description) => ({
  type: SET_DESCRIPTION,
  companyId,
  data: description,
});

export const SET_PHOTO = 'SET_PHOTO';
export const setPhoto = (companyId, photo) => ({
  type: SET_PHOTO,
  companyId,
  data: photo,
});
