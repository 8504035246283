import React from 'react';

const PublishedStatusView = ({
  isFetching,
  publicationStatus,
}) => (
  <>
    {isFetching && <h2>Updating...</h2>}
    {!isFetching && <h2>{publicationStatus}</h2>}
  </>
);

export default PublishedStatusView;
