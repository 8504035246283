import React, { useState } from 'react'
import FaqView from './faq-view';

const FaqContainer = ({
  addFaq,
  faq,
  index,
  onUpdate,
  removeFaq,
}) => {
  const [showConfirmation, setShowConfirmation ] = useState(false);

  const onTitleChange = (val) => {
    onUpdate({
      id: faq.id,
      title: val,
    });
  }

  const onTextChange = (val) => {
    onUpdate({
      id: faq.id,
      text: val,
    })
  }

  const toggleConfirmation = () => {
    setShowConfirmation((state)=> !state);
  }

  const onDeleteClick = () => {
    removeFaq(index);
  }

  const onAddClick = () => {
    addFaq(index + 1);
  }

  return (
    <FaqView
      id={faq.id}
      onAddClick={onAddClick}
      onDeleteClick={onDeleteClick}
      onTextChange={onTextChange}
      onTitleChange={onTitleChange}
      showConfirmation={showConfirmation}
      text={faq.text}
      title={faq.title}
      toggleConfirmation={toggleConfirmation}
    />
  )
}

export default FaqContainer;
