import { connect } from 'react-redux';
import {
  fetchTeamMembers,
  setTeamMember,
  createNew,
} from '../../state/actions/team-editor';
import TeamContainer from './team-page-container';

const mapStateToProps = (state) => ({
  isFetching: state.teamMembers?.isFetching,
  teamMembers: state.teamMembers?.allTeamMembers || [],
});

const mapDispatchToProps = (dispatch) => ({
  createNew: () => dispatch(createNew()),
  onLoad: () => dispatch(fetchTeamMembers()),
  setTeamMember: (teamMember) => dispatch(setTeamMember(teamMember)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamContainer);
