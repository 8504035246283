import React from 'react';
import Author from './author';
import Metatags from './meta-tags';
import Paths from '../../shared/components/paths';
import FooterLinks from './footer-links';
import FooterSearch from './footer-search';
import ArticleType from './type';
import Breadcrumbs from './breadcrumbs';
import Countries from './countries';
import Appearancce from './appearance';
import ZAddButton from '../../z-button-add';

const OptimizationView = ({
  id,
  onCreateSection,
  onFooterSearch,
  scrollTargetEle,
  onTopSectionClick,
  title
}) => (
  <>
    <ArticleType
      articleId={id}
    />
    <Paths
      entityId={id}
      entityTitle={title}
      entityType="Article"
      pathPrefix="a/"
    />
    <br/>
    <Metatags
      entityId={id}
    />
    <br/>
    <Countries
      entityId={id}
    />
    <br/>
    <Breadcrumbs
      entityId={id}
    />
    <Author
      articleId={id}
    />
    <Appearancce
      entityId={id}
    />
    <br/>
    <h3>SEO Footer Links</h3>
    <FooterSearch
      onFooterSearch={onFooterSearch}
    />
    <br/>
    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
      <ZAddButton onClick={onTopSectionClick}>Add Section</ZAddButton>
    </div>
    <br/>
    <FooterLinks
      articleId={id}
    />
    <div ref={scrollTargetEle}/>
    <br/>
    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
      <ZAddButton onClick={onCreateSection}>Add Section</ZAddButton>
    </div>
  </>

);

export default OptimizationView;
