import React, { useState, forwardRef } from 'react';
import Grid from '@mui/material/Grid';
import {
  closestCenter,
  DndContext,
  DragOverlay,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DragHandle } from '../../assets/inline/drag-handle.svg';
import styles from './z-draggable.module.scss';

const GridItem = forwardRef(({ children, ...props }, ref) => (
  <Grid item ref={ref} {...props}>
    {children}
  </Grid>
));

const Item = forwardRef(({ children, ...props }, ref) => (
  <div ref={ref} {...props}>
    {children}
  </div>
));

const SortableItem = ({
  children,
  id,
  isGrid,
}) => {
  const {
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = { transform: CSS.Transform.toString(transform), transition };

  return isGrid ? (
    <GridItem className={styles.item} id={id} ref={setNodeRef} style={style}>
      {children}
      <button
        className={styles['item__drag-handle']}
        type="button"
        {...listeners}
      >
        <DragHandle />
      </button>
    </GridItem>
  ) : (
    <Item className={styles.item} id={id} ref={setNodeRef} style={style}>
      {children}
      <button
        className={styles['item__drag-handle']}
        type="button"
        {...listeners}
      >
        <DragHandle />
      </button>
    </Item>
  );
};

const ZSortableContainer = ({
  isGrid,
  children,
  onDragEnd,
}) => {
  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = active.id - 1;
      const newIndex = over.id - 1;
      onDragEnd(oldIndex, newIndex, arrayMove);
    }

    setActiveId(null);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={children}
      >
        {isGrid ? (
          <Grid container rowSpacing={1} columnSpacing={1}>
            {children.map((child, index) => (
              <SortableItem key={index} id={index + 1} isGrid>
                {child}
              </SortableItem>
            ))}
          </Grid>
        ) : (
          children.map((child, index) => (
            <SortableItem key={index} id={index + 1}>
              {child}
            </SortableItem>
          ))
        )}
      </SortableContext>
      <DragOverlay>
        {activeId !== null ? children[activeId - 1] : null}
      </DragOverlay>
    </DndContext>
  );
};

export default ZSortableContainer;
