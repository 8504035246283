import React, { useState } from 'react';
import KeywordsView from './keywords-view';

const KeywordsContainer = ({
  keywords,
  setKeywords,
  title,
}) => {
  const [newKeyword, setNewKeyword] = useState('');

  const onAddKeyword = () => {
    const updated = [...new Set([...keywords, newKeyword])];
    setKeywords(updated);
    setNewKeyword('');
  };

  const onDeleteKeyword = (arr) => {
    const updated = arr.map((k) => k.label);
    setKeywords(updated);
  }

  return (
    <KeywordsView
      keywords={keywords}
      newKeyword={newKeyword}
      onAddKeyword={onAddKeyword}
      onDeleteKeyword={onDeleteKeyword}
      setNewKeyword={setNewKeyword}
      title={title}
    />
  )
}

export default KeywordsContainer;
