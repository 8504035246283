import React from 'react';
import PropTypes from 'prop-types';
import ZSelectInput from '../../z-select-input';
import styles from './reviews-sort.module.scss';

const ReviewsSortView = ({
  changeSortOrder,
  initalValue
}) => (
  <ZSelectInput
    inputClass={styles['sort__input']}
    listClassName={styles['sort__list']}
    name="reviewSearchSort"
    options={[
      { text: 'Featured reviews first' },
      { text: 'Newest reviews first' },
      { text: 'Oldest reviews first' },
    ]}
    initialValue={initalValue}
    onValidate={changeSortOrder}
    optionClass={styles['sort__option']}
    wrapperClass={styles['sort']}
  />
);
ReviewsSortView.propTypes = {
  changeSortOrder: PropTypes.func.isRequired,
};

export default ReviewsSortView;
