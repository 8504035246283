import React from 'react';
import style from './sample-article.module.scss';
import sharedStyle from '../../../shared/styles/shared.module.scss';
import ZInput from '../../../z-input';
import { decodeHTMLEntities } from '../../../../utils/window';
import Config from '../../../../config';
import ZDeleteButton from '../../../z-button-delete';
import zImage from '../../../z-image';
import ZImage from '../../../z-image';

const SampleArticleView = ({
  article,
  articlesLength,
  index,
  onChangePosition,
  removeArticle
}) => {
  const image = article.heroImage;
  const baseUrlWeb = `${Config.Z3_URL}`;

  return (
    <div className={`${style['sample-article']}`}>
      <div className={style['sample-article--left']}>
        <span className={style['sample-article--left--current-pos']}>
          {`Position: ${index + 1}`}
        </span>
        <div className={style['sample-article--left--pos']}>
          <ZInput
            style={{backgroundColor: '#FFF', padding: '0 10px'}}
            label={`New position:`}
            onUpdate={(e) => {
              e && onChangePosition({
                oldIndex: index,
                newIndex: (isNaN(parseInt(e, 10) - 1) ? index : parseInt(e, 10) - 1)
              });
            }}
          />
        </div>
        <div className={style['sample-article--left--change']}>
          <span className={style['sample-article--left--change--text']} onClick={() => onChangePosition({ oldIndex: index, newIndex: 0})}>Move To Top</span>
          <span className={style['sample-article--left--change--text']} onClick={() => onChangePosition({ oldIndex: index, newIndex: articlesLength})}>Move To Bottom</span>
        </div>
        <div className={style['sample-article--remove']}>
          <ZDeleteButton onClick={() => removeArticle(article.id)} />
        </div>
      </div>
      <div className={style['sample-article--right']}>
        <div className={style['sample-article--image']}>
          { image && (
            <ZImage
              alt={image.alt}
              cloudinarySrc={image.uri}
              aspectRatio={1.76}
              crop="fill"
            />
          )}
        </div>
        <div className={style['sample-article--title']}>
          <h3>{decodeHTMLEntities(article.title)}</h3>
          <a href={`/articles/${article.id}`} target='_blank'
            rel="noopener noreferrer">
            <button type="button" className={`${sharedStyle['button']} ${style['sample-article--title--button']}`}>Edit</button>
          </a>
          <a href={`${baseUrlWeb}/dynamic?type=Article&id=${article.id}`} target='_blank'
            rel="noopener noreferrer">
            <button type="button" className={`${sharedStyle['button']} ${style['sample-article--title--button']}`}>Preview</button>
          </a>
        </div>
        <div dangerouslySetInnerHTML={{
          __html: article.summary
        }}/>
        <p className={style['sample-article--item-wrapper__note']}>*Visit the article page to make changes to the image.</p>
      </div>
    </div>
  )
}

export default SampleArticleView;
