import React from 'react';
import FilterView from './filter-view';
import ZSpinner from '../../z-spinner';
import ZButton from '../../z-button';
import sharedStyle from '../../shared/styles/shared.module.scss';
import style from './filters.module.scss';

const FiltersView = ({
  isFetching,
  newsSources,
  onClickNewsSource,
  onClickReset,
  selectedFilter,
  showFilters,
  toggleShowFilters,
}) => (
  <div
    className={`
      ${style['filters']}
      ${typeof showFilters === 'boolean' && `${style[showFilters ? 'filters__show' : 'filters__hide']}`}
    `}>
    <div className={style['buttons']}>
      <button
        className={`
          ${style['buttons__close']}
          ${style[showFilters ? '' : 'buttons__close--hide']}
        `}
        onClick={toggleShowFilters}
        type="button"
      />
      <ZButton onClick={onClickReset} text="Reset" btnClass={style['buttons__reset']} />
    </div>
    {isFetching && (
      <div className={sharedStyle['spinner']}>
        <ZSpinner />
      </div>
    )}
    <div className={style['filters__sources']}>
      {newsSources && newsSources.map((source) => (
        <FilterView
          key={source.id}
          logo={source.logo}
          selected={selectedFilter === source.id}
          onClickNewsSource={() => onClickNewsSource(source.id)}
        />
      ))}
    </div>
  </div>
);

export default FiltersView;
