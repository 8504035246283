import React, { useEffect, useState } from 'react';
import ZPublicationsView from './z-publications-view';

const ZPublicationsContainer = ({
  entityId,
  loading,
  onFetchPublications,
  publications,
}) => {
  const [items, setItems] = useState();
  const [offset, setOffset] = useState();
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (!publications) onFetchPublications(entityId);
  }, [entityId, onFetchPublications, publications]);

  useEffect(() => {
    if (loadMore) {
      onFetchPublications(entityId);
      setLoadMore(false);
    }
  }, [loadMore, entityId, onFetchPublications]);

  useEffect(() => {
    if (publications) {
      const publicationsSummary = publications.items.map((item) => ({
          canonicalAlias: item.canonicalAlias,
          comment: item.comment,
          createdAt: new Date(item.createdAt).toLocaleString(),
          entityUpdateId: item.entityUpdateId,
          publish: item.publish,
          publicationId: item.id,
          significant: item.significant,
          status: item.status,
          userId: item.userId,
        }));
      setItems(publicationsSummary);
      setOffset(publications.offset);
    }
  }, [publications]);

  const onClickLoadMore = (e) => {
    setLoadMore(true);
  };

  return (
    <ZPublicationsView
      items={items}
      loading={loading}
      offset={offset}
      onClickLoadMore={onClickLoadMore}
    />
  );
};

export default ZPublicationsContainer;
