import React, { memo } from 'react';
import style from './news-snippet.module.scss';
import { getFormattedDate } from '../../../utils/utils';

const NewsSnippetView = memo(({
  newsSource,
  onClick,
  openInEditor,
  publicationDate,
  screenSize,
  teaser,
  title,
  uri,
}) => (
  <div className={`${style['card']} ${style[screenSize]}`} onClick={onClick}>
    <div className={style['card__logo']}>
      {newsSource.logo && (
        <img
          alt={newsSource.logo.alt}
          src={newsSource.logo.uri}
          width={100}
        />
      )}
    </div>
    <p className={style['card__publication']}>{getFormattedDate(`${publicationDate}T00:00:00`)}</p>
    <h2 className={style['card__title']}>{title}</h2>
    <p className={style['card__teaser']} dangerouslySetInnerHTML={{ __html: teaser }} />
    <a className={style['card__link']} href={uri} target="_blank" rel="noreferrer noopener">
      Read article
      <div className={style['card__link-arrow']} />
    </a>
  </div>
));

export default NewsSnippetView;
