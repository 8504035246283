import React from 'react';
import style from './keywords.module.scss';
import ZChips from '../../../z-chips';
import ZInput from '../../../z-input';

const KeywordsView = ({
  keywords,
  newKeyword,
  onAddKeyword,
  onDeleteKeyword,
  setNewKeyword,
  title,
}) => (
  <div className={style['keywords__wrapper']}>
    <div className={style['keywords']}>
      <h3>{title}</h3>
      <div className={style['keywords__add']}>
        <ZInput
          label='Add new keyword'
          type='text'
          inputKey={'admin__new-keyword'}
          text={newKeyword}
          onChange={setNewKeyword}
        />
        <button
          type="button"
          className={style['button']}
          onClick={onAddKeyword}
          onKeyDown={onAddKeyword}
          disabled={!newKeyword || keywords.findIndex(ele => ele.toLowerCase() === newKeyword.toLowerCase()) !== -1}
        >
            Add
          </button>
      </div>
    </div>
    {keywords && keywords.length !== 0 &&
      <ZChips
        data={keywords.map((label) => ({ label }))}
        style={{marginBottom: '28px'}}
        onChange={onDeleteKeyword}
      />
    }
  </div>
);

export default KeywordsView;
