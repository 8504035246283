import React, { useEffect, useState } from 'react';
import TeamView from './team-page-view';

const TeamContainer = ({
  isFetching,
  teamMembers,
  onLoad,
  createNew,
  setTeamMember,
}) => {
  const [filteredTeamMembers, setFilteredTeamMembers] = useState();
  const [editTeamMemberId, setEditTeamMemberId] = useState();
  const [displayed, setDisplayed] = useState('all');

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    if (displayed === 'all') {
      setFilteredTeamMembers(teamMembers);
    } else {
      const filtered = teamMembers.filter((teamMember) => {
        const matchesDisplayed = displayed === 'all' || teamMember.displayed === (displayed === 'yes');
        return matchesDisplayed;
      });
      setFilteredTeamMembers(filtered);
    }
  }, [displayed, teamMembers]);

  const onClickDisplayed = (e) => {
    const { value } = e.target;
    setDisplayed(value)
  };

  const onClickEdit = (teamMember) => {
    setEditTeamMemberId(teamMember.id);
    setTeamMember(teamMember);
  };

  const closeModal = () => {
    setEditTeamMemberId();
  };

  const onClickCreateTeamMember = () => {
    setEditTeamMemberId('new');
    createNew();
  }


  return (
    <TeamView
      closeModal={closeModal}
      displayed={displayed}
      editTeamMemberId={editTeamMemberId}
      isFetching={isFetching}
      teamMembers={filteredTeamMembers}
      onClickCreateTeamMember={onClickCreateTeamMember}
      onClickDisplayed={onClickDisplayed}
      onClickEdit={onClickEdit}
    />
  )
};

export default TeamContainer;
