import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from './content-container';
import {setSummaryT} from "../../../state/actions/shared/title";
import {createPhotoT, removeHeaderPhotoT, removeSectionPhotoT, updatePhotoT} from "../../../state/actions/shared/photo";
import {
  createSectionT,
  removeSectionT,
  reorderSectionsT,
  setSectionTextT,
  setSectionTitleT
} from "../../../state/actions/shared/section";

const ContentRedux = (props) => (
  <ContentContainer {...props}/>
);

const mapSectionIdToSection = (ids, sections, photos) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = {...sections[ele]};
      section.photo = photos[section.photoId] || {} ;
      return section;
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const {articleId:id} = ownProps;
  const article = {...state.articles.article.editor[id]};
  const sectionState = { ...state.articles.article.sections};
  const photoState = { ...state.articles.article.photos};
  const {sectionIds} = article;
  return{
    article,
    sectionIds,
    sections: mapSectionIdToSection(sectionIds, sectionState, photoState),
    photos: state.articles.article.photos,
    readOnly: state.versions.readOnly,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { articleId:id } = ownProps;
  return {
    onSummaryChange: text => dispatch(setSummaryT('article', text, id)),
    onAddSectionClick: (index = null) => dispatch(createSectionT('article', id, index)),
    onRemoveSectionClick: (sectionId) => dispatch(removeSectionT('article', id, sectionId)),
    onSectionTitleChange: (sectionId, text) => dispatch(setSectionTitleT('article', sectionId, text)),
    onSectionTextChange: (sectionId, text) => dispatch(setSectionTextT('article', sectionId, text)),
    onSectionOrderChange: (sectionIds) => dispatch(reorderSectionsT('article', id, sectionIds)),
    onChangePhoto: (imageData) => dispatch(updatePhotoT('article', imageData)),
    onCreateHeaderPhoto: (entityId, imageData) => dispatch(createPhotoT('article', entityId, 'header', imageData)),
    onCreateSectionPhoto: (sectionId, imageData) => dispatch(createPhotoT('article', sectionId, 'section', imageData)),
    onDeleteHeaderPhoto: (entityId, headerPhotoId) => dispatch(removeHeaderPhotoT('article', entityId, headerPhotoId)),
    onDeleteSectionPhoto: (sectionId, photoId) => dispatch(removeSectionPhotoT('article', sectionId, photoId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentRedux);
