import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getViewsById } from '../../../../utils/fetch/views';

const makeDateUsable = (str) => {
  const year = str.slice(0,4);
  const month = str.slice(4,6);
  const day = str.slice(-2);
  return `${year}-${month}-${day}`;
}

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  });
  return formattedDate;
}

const ViewsChart = ({
  id,
}) => {
  const [data, setData] = useState();

  useEffect(() => {
    /**
     * View data is returned in following format:
     * {
     *    "20201105": 2,
     *    "20201106": 4,
     * }
     * this and helper functions are used to turn the key into a JS usable date
     * @param {date<string>: count<number>} viewData
     * @returns [{date: 11/11/2020 viewCount: 1}]
     */
    const formatViewData = (viewData) => {
      const res = [];
      for (const day in viewData) {
        if (Object.hasOwnProperty.call(viewData, day)) {
          const viewCount = viewData[day];
          res.push({
            viewCount,
            date: formatDate(makeDateUsable(day)),
          });
        }
      };
      return res;
    }

    getViewsById(id).then((res) => {
      setData(formatViewData(res));
    })
  }, [id]);

  return  (
    <>
      <Typography variant='subtitle1'>Views By Day</Typography>
      { (data && !!data.length) && (
        <div
          style={{
            width: '100%',
            height: '400px',
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="viewCount" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
      { (data && !data?.length) && (
          <Typography variant='subtitle1' color={'red'}>No Views Found</Typography>
      )}
    </>
  )
}

export default ViewsChart;
