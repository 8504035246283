import React from 'react';
import ZTextArea from '../../z-text-area';
import PhotoPreview from '../../shared/components/content-image';
import LandingHeaderImage from './header-image/landing-page-header-view';
import Teaser from './teaser';
import ZInput from '../../z-input';
import style from './content.module.scss';
import { getImagePreviewTransformation } from '../../../utils/image/image-utils';

const ContentView = ({
  autoComplete,
  headerPhotoId,
  id,
  landingPageHeaderPhotoId,
  onChangePhoto,
  onCreateHeaderPhoto,
  onDeleteHeaderPhoto,
  onSubtitleChange,
  onSummaryChange,
  photos,
  setUseParentImage,
  subtitle,
  useParentImage,
  summary,
}) => {
  return (
    <>
      <br />
      <h2>Header Photo</h2>
      <div className={style['hero-image']}>
          <PhotoPreview
          entityId={id}
          photoId={headerPhotoId}
          style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
          showInfo
          onChangePhoto={onChangePhoto}
          onCreatePhoto={onCreateHeaderPhoto}
          onRemovePhoto={onDeleteHeaderPhoto}
          photo={photos[headerPhotoId]}
          transformation={getImagePreviewTransformation({ aspectRatio: 3.14 })}
        />
      </div>
      <div className={style['flag-row']}>
        <ZInput
          label='Subtitle'
          onUpdate={onSubtitleChange}
          initialValue={subtitle}
          style={{ margin: '14px', flex: 1 }}
          inputKey={'landingPage__subtitle'}
        />
      </div>
      <br />
      <ZTextArea
        allowedFormats={['LINKS']}
        label="Summary"
        onUpdate={onSummaryChange}
        value={summary}
      />
      <br />
      <Teaser
        entityId={id}
      />
    </>
  )
}

export default ContentView;
