import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Articles from './articles';
import ArticleEditor from './articleEditor';
import Guides from './guides';
import CompanyCategories from './companyCategories';
import GuideEditor from './guideEditor';
import CompanyCategoryEditor from './companyCategoryEditor';
import LandingPageCategoryEditor from './landingPageCategoryEditor';
import LandingPageCategories from './landingPageCategories';
import Create from './create';
import Home from './home';
import Itineraries from './itineraries';
import ItineraryId from './itineraryId';
import LandingPages from './landingPages';
import LandingPageId from './landingPageId';
import NewsPage from './news';
import SimplePages from './simplePages';
import SimpleEditor from './simpleEditor';
import Logout from './logout';
import TeamPage from './team';
import TrfSettingsPage from './trf-settings';
import CareersPage from './careers';
import OryxEntry from '../../components/oryx/oryx-routes';

const privateRoutes = (
  <Switch>
    <Route exact path="/" render={Home} />
    <Route path='/create/:type' render={Create} />
    <Route path='/article' render={ArticleEditor} />
    <Route path='/articles/:id/:updateId?' render={ArticleEditor} />
    <Route path='/articles' render={Articles} />
    <Route path='/company-categories/:id/:updateId?' render={CompanyCategoryEditor} />
    <Route path='/company-categories' render={CompanyCategories} />
    <Route path='/landing-page-categories/:id/:updateId?' render={LandingPageCategoryEditor} />
    <Route path='/landing-page-categories' render={LandingPageCategories} />
    <Route path='/guide' render={GuideEditor} />
    <Route path='/guides/:id/:updateId?' render={GuideEditor} />
    <Route path='/guides' render={Guides} />
    <Route path='/itineraries/:id/:updateId?' render={ItineraryId} />
    <Route path="/itineraries" render={Itineraries} />
    <Route path='/landing-pages/:id/:updateId?' render={LandingPageId} />
    <Route path="/landing-pages" render={LandingPages} />
    <Route path="/news" render={NewsPage} />
    <Route path="/simple-pages/:id/:updateId?" render={SimpleEditor} />
    <Route path="/simple-pages" render={SimplePages} />
    <Route path="/logout" render={Logout} />
    <Route path="/team" render={TeamPage} />
    <Route path='/trip-request-settings' render={TrfSettingsPage} />
    <Route path="/careers" render={CareersPage} />
    <Route path="/companies" render={OryxEntry} />
    <Redirect to="/" />
  </Switch>
);

export default privateRoutes;
