import React from 'react';
import { connect } from 'react-redux';
import TravelAgentsContainer from './travel-agents-container';
import { setAgentList } from '../../../../state/actions/landing-page-editor'

const TravelAgentsRedux = (props) => (
  <TravelAgentsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const currentEntity = {...state.landingPages.landingPage.editor[id]};
  return{
    currentEntity,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onChange: (agentList) => dispatch(setAgentList(id, agentList)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelAgentsRedux);
