import React from 'react';
import { connect } from 'react-redux';
import OptimizationContainer from './optimization-container';
import {addUpstreamFooterSectionsT, createFooterSectionT} from "../../../state/actions/shared/section";

const mapSectionIdsToSection = (ids, sections) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = sections[ele];
      return section;
    });
  }
}

const OptimizationRedux = (props) => (
  <OptimizationContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const editorEntities = state.landingPages.landingPage;
  const { sections, editor} = editorEntities;
  const currentEntity = editor[id];
  const { footerSectionIds, title } = currentEntity;
  return {
    id,
    footerSections: mapSectionIdsToSection(footerSectionIds, sections),
    title,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    onCreateSection: () => dispatch(createFooterSectionT('landing_page', id, false)),
    onFooterSearch: (sections) => dispatch(addUpstreamFooterSectionsT('landing_page', id, sections)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OptimizationRedux);
