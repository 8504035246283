import React from 'react';
import { OryxInputError, OryxInputLabel, OryxInputWrapper } from '../oryx-shared/oryx-input/utils';
import styles from './company.module.scss';

const defaultErrorProps = {
  className: '',
};

const Error = ({
  children,
  className,
}) => (
  <OryxInputError className={className}>
    {children}
  </OryxInputError>
);
Error.defaultProps = defaultErrorProps;

const defaultLabelProps = {
  className: '',
  inputName: '',
};

const Label = ({
  children,
  className,
  inputName = '',
}) => (
  <OryxInputLabel className={`${styles.label} ${className}`} inputName={inputName}>
    {children}
  </OryxInputLabel>
);
Label.defaultProps = defaultLabelProps;

const defaultWrapperProps = {
  className: '',
};

const Wrapper = ({
  children,
  className,
}) => (
  <OryxInputWrapper className={`${styles.wrapper} ${className}`}>
    {children}
  </OryxInputWrapper>
);
Wrapper.defaultProps = defaultWrapperProps;

const FlexWrapper = ({
  children,
  className = '',
}) => (
  <OryxInputWrapper className={`${styles['wrapper--flex']} ${className}`}>
    {children}
  </OryxInputWrapper>
);
FlexWrapper.defaultProps = defaultWrapperProps;

export {
  Error,
  FlexWrapper,
  Label,
  Wrapper,
};
