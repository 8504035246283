import React, { createContext } from 'react';
import {
  setServedLocationsMap,
  setServedLocationsMapReverse,
  setServedLocationsOptions,
  setCompanyLocations,
} from './actions';
import useLocationsReducer from './use-locations-reducer';
import LocalStorageService from '../../../../../utils/local-storage-service';
import config from '../../../../../config';

const LocalStorage = new LocalStorageService();

export const LocationsStore = createContext({});

const unauthorizedRedirect = (destination) => {
  const dest = destination ? `?destination=/${destination}` : '';
  window.location.href = `/login${dest}`;
};

export const getCompanyLocations = () => {
  const accessToken = LocalStorage.getFromLocalStorage('accessToken');

  return fetch(`${config.API_URL}/company_locations`, {
    credentials: 'include',
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => res.json())
    .then((json) => {
      if ('httpStatus' in json) {
        if (json.httpStatus === 401) unauthorizedRedirect('companies');
      }
      return json;
    })
    .catch((e) => console.warn(e));
}

export const LocationsProvider = ({ children }) => {
  const [locations, dispatchLocations] = useLocationsReducer();

  const value = {
    ...locations,
    setServedLocationsMap: (map) => dispatchLocations(setServedLocationsMap(map)),
    setServedLocationsMapReverse: (reverseMap) => (
      dispatchLocations(setServedLocationsMapReverse(reverseMap))),
    setServedLocationsOptions: (options) => (
      dispatchLocations(setServedLocationsOptions(options))),
    setCompanyLocations: (locations) => (dispatchLocations(setCompanyLocations(locations)))
  };

  return (
    <LocationsStore.Provider value={value}>
      {children}
    </LocationsStore.Provider>
  );
};
