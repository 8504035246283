import React, { useEffect, useRef, useState } from 'react';
import ZApiClient from '../../../utils/client/z-api-client';
import ZSpinner from '../../z-spinner';
import sharedStyle from '../../shared/styles/shared.module.scss';
import ReviewsPreview from '../../reviews-preview';
import { combineArraysOfObjectsDeduped } from '../../../utils/utils';
const LandingPagesClient = ZApiClient.getLandingPagesClient();
const reviewSearchesClient = ZApiClient.getReviewSearchesClient();
const DEFAULT_OFFSET = 10;
const SORT_MAP = {
  relevance: 'Featured reviews first',
  newToOld: 'Newest reviews first',
  oldToNew: 'Oldest reviews first',
}

const ReviewsContainer = ({
  id,
  updateId,
  reviewSearch,
  setReviewSearch,
}) => {
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [fetchStatus, setFetchStatus] = useState('LOADING');
  const [selectedStarLevel, setSelectedStarLevel] = useState(null);
  const [sortOrder, setSortOrder] = useState('relevance');
  const [currentReviewSearch, setCurrentReviewSearch] = useState(reviewSearch || {});
  const shouldFetchReviews = useRef(!reviewSearch);

  useEffect(() => {
    if(shouldFetchReviews.current){
      setFetchStatus('LOADING');
      LandingPagesClient.getRelatedReviews(id)
        .then((res) => {
          if (res.httpStatus) {
            setFetchStatus('ERROR');
          } else {
            shouldFetchReviews.current = false;
            setReviewSearch({
              ...res,
              reviews: combineArraysOfObjectsDeduped(res?.reviews), 
            });
          }
        })
        .catch((e) => {
          console.error('error fetching reviews: ', e);
        });
    }
  }, [setReviewSearch, updateId, id]);

  useEffect(() => {
    if (reviewSearch?.id) {
      setCurrentReviewSearch(reviewSearch);
      setFetchStatus();
    }
  }, [reviewSearch]);

  const loadMoreResults = () => {
    const id = currentReviewSearch.id;
    const params = {
      offset: currentReviewSearch.offset + DEFAULT_OFFSET,
      sort: sortOrder,
      ...currentReviewSearch?.postSearchRatingFilter && {rating: currentReviewSearch.postSearchRatingFilter}
    }
    reviewSearchesClient.getReviewSearch(id, params)
      .then((res) => {
        const newReviews = res.reviews || [];
        if (res.reviews.length < DEFAULT_OFFSET) {
          setShowLoadMore(false);
        }
        setCurrentReviewSearch((state) => ({
          ...state,
          ...res,
          reviews: combineArraysOfObjectsDeduped(state.reviews, newReviews),
        }));
      });
  }

  const changeSortOrder = (name, updatedSort) => {
    let newSort;
    switch (updatedSort) {
      case 'Featured reviews first': newSort = 'relevance'; break;
      case 'Newest reviews first': newSort = 'newToOld'; break;
      case 'Oldest reviews first': newSort = 'oldToNew'; break;
      default: break;
    }
    setSortOrder(newSort);
    const id = currentReviewSearch.id;
    const params = {
      offset: 0,
      sort: newSort,
      ...currentReviewSearch?.postSearchRatingFilter && {rating: currentReviewSearch.postSearchRatingFilter}
    }
    reviewSearchesClient.getReviewSearch(id, params)
      .then((res) => {
        setShowLoadMore(true);
        setCurrentReviewSearch((state) => ({
          ...state,
          ...res,
        }));
      });
  }

  const onSelectStarLevel = (val) => {
    const id = currentReviewSearch.id;
    const params = {
      offset: 0,
      sort: 'relevance',
      rating: val,
    }

    reviewSearchesClient.getReviewSearch(id, params)
      .then((res) => {
        setSortOrder('relevance');
        setShowLoadMore(true);
        setSelectedStarLevel(val);
        setCurrentReviewSearch((state) => ({
          ...state,
          ...res,
        }));
      });
  }

  const clearRating = () => {
    setCurrentReviewSearch(reviewSearch);
    setSortOrder('relevance');
    setShowLoadMore(true);
    setSelectedStarLevel(null);
  }

  const onClickRefresh = () => {
    setFetchStatus('LOADING');
    LandingPagesClient.getRelatedReviews(id)
      .then((res) => {
        if (res.httpStatus) {
          setFetchStatus('ERROR');
        } else {
          shouldFetchReviews.current = false;
          setReviewSearch(res);
          setSortOrder('relevance');
          setShowLoadMore(true);
          setSelectedStarLevel(null);
        }
      })
      .catch((e) => {
        console.error('error fetching reviews: ', e);
      });
  }

  if (fetchStatus === 'LOADING') {
    return (
      <div className={sharedStyle['spinner']}>
        <ZSpinner/>
      </div>
    );
  }

  if (fetchStatus === 'ERROR') {
    return (
      <div className={`${sharedStyle['spinner']} ${sharedStyle['error']}`}>
        There was an error loading reviews
      </div>
    )
  }

  return (
    <ReviewsPreview
      changeSortOrder={changeSortOrder}
      clearRating={clearRating}
      filteredSearches={{}}
      loadMoreReviews={loadMoreResults}
      offset={0}
      reviewSearch={{
        ...currentReviewSearch,
        averageRating: reviewSearch.averageRating,
        reviewCount: reviewSearch.reviewCount,
      }}
      selectedStarLevel={selectedStarLevel}
      setSelectedStarLevel={onSelectStarLevel}
      showShowMoreButton={showLoadMore}
      sortOrder={sortOrder}
      initalSortValue={SORT_MAP[sortOrder]}
      onClickRefresh={onClickRefresh}
    />
  )
};

export default ReviewsContainer;
