import React from 'react';
import styles from './trf-settings.module.scss';
import ZSpinner from '../z-spinner';
import ZTextArea from '../z-text-area';
import { Button } from '@mui/material';


const TrfSettingsView = ({
  announcementText,
  showAnnouncement,
  shouldSave,
  isSaving,
  onCheckboxChange,
  onTextChange,
  onSave,
}) => (
  <div>
    <h1>
      TRF Settings
    </h1>
    <div className={styles['inputs-wrapper']}>
        <ZTextArea
            allowedFormats={['LINKS']} 
            label="Announcement Banner Text"
            onUpdate={onTextChange}
            value={announcementText}
        />
        <div>
            <input 
                type='checkbox'
                id='showAnnouncement' 
                className={styles['checkbox']} 
                name='showAnnouncement'
                onChange={onCheckboxChange}
                checked={showAnnouncement}
            />
            <label htmlFor='showAnnouncement'>
                Show Announcement
            </label>
        </div>
    </div>
    <div>
      {!isSaving && (
        <Button
            variant="contained"
            disabled={!shouldSave}
            size="small" 
            color="secondary" 
            type="button"
            onClick={onSave} 
        >
            Save
        </Button>
      )}
        {isSaving && (
            <ZSpinner />
        )}
    </div>
  </div>
);

export default TrfSettingsView;

