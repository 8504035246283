import React, { useState, useCallback } from 'react';
import PhotoPreview from '../../../../shared/components/content-image';
import ZInput from '../../../../z-input';
import ZButton from '../../../../z-button';
import ZSpinner from '../../../../z-spinner';
import { getImagePreviewTransformation } from '../../../../../utils/image/image-utils';
import style from './editor.module.scss';

const camelCaseToSpaced = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const GalleryLinkEditor = ({
  closeModal,
  createPhoto,
  disabledMessage,
  entityId,
  galleryLink,
  index,
  onChangeLink,
  photo,
  photoId,
  removePhoto,
  saveDisabled,
  updatePhoto,
}) => {
  const [title, setTitle] = useState(galleryLink.title);
  const [imageToDelete, setImageToDelete] = useState();

  const onRemovePhoto = () => {
    setImageToDelete(photo);
    onChangeLink({ ...galleryLink, image: {}}, index);
  };

  const onCreatePhoto = useCallback((id, photo) => {
    createPhoto(id, photo, index);
  }, [createPhoto, index]);

  const onClickDone = () => {
    if (imageToDelete) removePhoto(entityId, imageToDelete.id);
    onChangeLink({ ...galleryLink, title }, index);
    closeModal();
  };

  const onClickCancel = () => {
    if (imageToDelete) {
      onChangeLink({ ...galleryLink, image: imageToDelete }, index);
    }
    closeModal();
  };

  return (
    <>
      <PhotoPreview
        entityId={entityId}
        onChangePhoto={updatePhoto}
        onCreatePhoto={onCreatePhoto}
        onRemovePhoto={onRemovePhoto}
        photo={photo}
        photoId={photoId}
        showInfo={false}
        transformation={getImagePreviewTransformation({ aspectRatio: 1.76 })}
      />
      {(saveDisabled && disabledMessage === 'Saving Photo') && (
        <ZSpinner className={style['editor__image-saving']} />
      )}
      <ZInput
        label='Link Title'
        onUpdate={setTitle}
        initialValue={title}
        style={{ margin: '20px 0' }}
      />
      <div className={style['editor__row']}>
        <p>
          <span className={style.label}>Link:</span>
          {galleryLink.uri}
        </p>
        <p>
          <span className={style.label}>Type:</span>
          {camelCaseToSpaced(galleryLink.entityType)}
        </p>
      </div>
      <div className={style['editor__row']}>
        <ZButton
          btnClass={style['editor__button']}
          onClick={onClickCancel}
          text="Cancel"
        />
        <ZButton
          btnClass={style['editor__button']}
          disabled={!title || !photoId}
          onClick={onClickDone}
          text="Done"
        />
      </div>
    </>
  );
};

export default GalleryLinkEditor;
