
import React from 'react';
import { connect } from 'react-redux';
import {
  setHighlights,
  setDescription,
} from '../../../state/actions/itinerary-editor';
import DetailsContainer from './details-container';

const DetailsRedux = (props) => {
  return ( <DetailsContainer {...props}/> );
}

const mapStateToProps = (state, ownProps) => {
  const {entityId: id} = ownProps;
  const { editor } = state.itineraries.itinerary;
  const { errors } = state;
  const currentEntity = editor[id] || {};
  const currentErrors = errors[id] || {};
  const {
    highlights: highlightsErrors
  } = currentErrors;
  return {
    itinerary : currentEntity,
    highlightsErrors,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId: id} = ownProps;
  return {
    onUpdateHighlights : (text) => dispatch(setHighlights(text, id)),
    onUpdateDescription: (text) => dispatch(setDescription(text, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsRedux);
