import Config from '../config';

const baseUrlWeb = `${Config.Z3_URL}`;

/**
 * Generic function that takes a string and shortens
 * it to the closest whole word.
 *
 * @param {string} s - string you want to shorten
 * @param {number} maxChars - max length in chars
 * @returns Array with ['short string', 'rest of string' ]
 */
export const shortenStringsToWholeWords = (s, maxChars = null) => {
  //  Decode html entities first
  s = decodeHTMLEntities(s);

  const splitStr = s ? s.split(' ') : [];
  let shortStr = '';
  let restStr = '';

  splitStr.forEach((part) => {
    if (shortStr.length <= maxChars || !maxChars) {
      shortStr += `${part} `;
    } else {
      restStr += `${part} `;
    }
  });

  return [
    shortStr.trim(),
    restStr.trim(),
  ];
};

/**
 * This function converts numbers into ush strings
 */
export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

/**
 * Generic function that takes a url search string
 * and returns an object with the parameters as attributes
 *
 * @param {string} str - URL search string (eg: ?key=value)
 * @returns Object with { key = name : value = value }
 */
export const parseURLParams = (str) => {
  if (!str || str.length <= 0) { return {}; }

  //  Remove "?" at beginning if present
  if (str[0] === '?') { str = str.substring(1); }

  //  Split on '&'
  const objParams = {};
  str.split('&').forEach((p) => {
    //  Split again on '='
    const parts = p.split('=');
    objParams[parts[0]] = parts[1];
  });
  return objParams;
};

/**
 * Decodes HTML entities
 */
export const decodeHTMLEntities = (text) => {
  if (text && text.indexOf('&') > -1 && typeof window !== 'undefined') {
    const textArea = window.document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  return text;
};

/**
 * Calc src based on desktop or mobile props
 */
export const calcSrc = (currentBreakpoint, heroImage, mobileHeroImage) => {
  const bp = currentBreakpoint === 'desktop' ? heroImage : mobileHeroImage;
  return bp && bp.uri ? bp.uri : null;
};

/**
 * Calc image object based on desktop or mobile props
 */
export const calcImage = (currentBreakpoint, heroImage, mobileHeroImage) => {
  const bp = currentBreakpoint === 'desktop' ? heroImage : mobileHeroImage;
  return bp && bp.uri ? bp : null;
};

export const mathTrunc = Math.trunc || function(x) {
  return x < 0 ? Math.ceil(x) : Math.floor(x);
};

//  Gets Intersection Observer compatibility
export const getIOSupport = () => 'IntersectionObserver' in window;

export const isIE11 = () => {
  if (typeof window !== 'undefined' && window) {
    const ua = window.navigator.userAgent;
    const trident = ua.indexOf('Trident/');
    return trident > -1;
  }
  return false;
};

export const extractParams = (str) => {
  //  Remove the initial '?'
  const withoutQ = str.substring(1, str.length);

  //  If there was something after the '?'
  if (withoutQ.length > 0) {
    //  Split up all params

    const parts = withoutQ.split('&').reduce((acc, cur) => {
      //  Return object with name/value pairs
      const [name, value] = cur.split('=');
      acc[name] = value;
      return acc;
    }, {});
    return parts;
  }
  return null;
};

export const z3Routes = {
  404: true,
  about: true,
  account: true,
  activate: true,
  contact: true,
  'contact/thank-you': true,
  login: true,
  logout: true,
  'our-promises': true,
  register: true,
  reset: true,
  team: true,
  'trip-request': true,
  'trip-request/thank-you': true,
};

export const checkIsTravelCompany = (obj) => (
  !!Object.values(obj).find((val) => val === 'Travel Agent' || val === 'Travel Company Manager')
);

export const getGoogleClientId = () => {
  try {
    // eslint-disable-next-line no-undef
    const tracker = window.ga.getAll()[0];
    return tracker.get('clientId');
  } catch (err) {
    return null;
  }
};

export const getFormattedDate = (dateString, timeZone) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    timeZone,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return formattedDate;
};

export const combineArraysOfObjectsDeduped = (...arrays) => (
  [].concat(...arrays).filter((value, index, array) => (
    index === array.findIndex((obj) => obj.id === value.id)
  )));

export const capitalizeAll = (string) => (
  string.split(' ').map((word) => word[0].toUpperCase() + word.substring(1)).join(' ')
);

// Set title based on path. Capitalize entity search pages, lowercase editor pages
export const setDocumentTitle = (path) => {
  const splitPath = path.replaceAll('/', ' ').trim().split(' ');
  const title = splitPath[0].replaceAll('-', ' ');
  if (title) {
    document.title = splitPath[1]
      ? title
      : capitalizeAll(title);
  }
};

export const replaceNewLine = (text) => {
  const newline = String.fromCharCode(13, 10);
  return text.replaceAll('\\n', newline);
}
