import React, { useState } from 'react';
import TypeView from './type-view';

const inputColor = {
  READ_ONLY : '#c7c6c5',
}

const TypeContainer = ({
  type,
  expirationDate,
  onTypeChange,
  onDateChange,
  setExpirationDateIsValid,
})=>{
  const [inputStatus, setInputStatus] = useState(type === 'EVERGREEN' ? 'READ_ONLY' : '');

  const onRadioChange = (e) => {
    const type = e.currentTarget.value;
    setInputStatus(type === 'EVERGREEN' ? 'READ_ONLY' : '');
    onTypeChange(type);
    if(type === 'TOPICAL' && !expirationDate){
      setExpirationDateIsValid(false);
    }
  }

  const onDateInputChange = (e) => {
    const selectedData = new Date(e);
    const isoString = selectedData.toISOString();
    const calendarDate = isoString.slice(0, isoString.indexOf('T'));
    setExpirationDateIsValid(true);
    onDateChange(calendarDate)
  }

  return (
    <TypeView
      articleType={type}
      onRadioChange={onRadioChange}
      date={type === 'EVERGREEN' ? '' : expirationDate}
      inputColor={inputColor[inputStatus]}
      onDateInputChange={onDateInputChange}
    />
  )
};

export default TypeContainer;
