/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import LogoutContainer from './logout-container';

const LoginModalRedux = ({ dispatch }) => (
  <LogoutContainer dispatch={dispatch} />
);

export default connect()(LoginModalRedux);
