import React, { useEffect, useState, useMemo } from 'react';
import TravelAgentsView from './travel-agents-view';
import ZApiClient from '../../../../utils/client/z-api-client';

const CompanyCategoriesClient = ZApiClient.getCompanyCategoriesClient();
const AGENT_COUNTS = [4, 7];

const LINK_SECTION_DEFAULT = (ccpUsed) => ({
  title: 'We Work Only With The Best Travel Agents',
  links: [{
    title: 'See all Agents',
    entityId: ccpUsed.id,
    entityType: 'CompanyCategory',
    uri: ccpUsed.canonicalPath,
  }]
})


const getCanonicalPath = (str) => {
  const canonical = str?.split('https://www.zicasso.com')[1];
  if (canonical) {
    return canonical
  }
  return '/';
}

const getCompanyLink = (arr = []) => {
  const res = arr.find((e) => e.entityType === "CompanyCategory");
  return res || {};
}

const getAgencies = (id) => {
  if (id) {
    return CompanyCategoriesClient.getCompanies(id)
  } else {
    return Promise.resolve([]);
  }
}

const getAgentLayout = async (id, setState, setErrorState) => {
  const agentList = await getAgencies(id);
  if (agentList.length >= AGENT_COUNTS[1]) {
    setState(`Section will show ${AGENT_COUNTS[1]} agents in a gallery view`);
    if (setErrorState) setErrorState();
    return;
  } 
  if (agentList.length >= AGENT_COUNTS[0] && agentList.length < AGENT_COUNTS[1]) {
    setState(`Section will show ${AGENT_COUNTS[0]} agents in a single list`);
    if (setErrorState) setErrorState();
    return;
  }
  if (setErrorState){
    setErrorState('Selected Agency Category Page can not be use. Change the Agency Category Page used in the subnav.');
  }
  return;
}

const getCompanyCategory = async (id, setState) => {
  if (id) {
    const result = await CompanyCategoriesClient.getCompanyCategoryById(id);
    if(!result.httpStatus){
      const {
        heroImage,
        title,
        metatags : {canonical}
      } = result;
      const res = {
        id,
        image: heroImage,
        title,
        canonicalPath: getCanonicalPath(canonical),
      }
      setState(res);
    }
  }
}

const TravelAgentsContainer = ({
  currentEntity,
  onChange,
}) => {
  const [currentCCP, setCurrentCCP] = useState();
  const [currentAgentLayout, setCurrentAgentLayout] = useState();
  const [errors, setErrors] = useState('Please add an Agency Category Page to the Sub Nav');
  const [missMatchError, setMissMatchError] = useState(false);
  const [useAgencies, setUseAgencies] = useState(false);
  const links = useMemo(() => currentEntity.navigationBar?.links || [], [currentEntity]);
  const {
    agentList,
  } = currentEntity;

  useEffect(() => {
    const companyCategory = getCompanyLink(links);
    if (agentList && companyCategory.entityId !== agentList?.links[0].entityId) {
      setMissMatchError(true);
    }
    if(!agentList){
      setMissMatchError(false);
    }
  },[agentList, links]);

  useEffect(() => {
    setUseAgencies(!!agentList);
  }, [agentList]);
  
  useEffect(() => {
    const companyCategory = getCompanyLink(links);
    getAgentLayout(companyCategory.entityId, setCurrentAgentLayout, setErrors);
    getCompanyCategory(companyCategory.entityId, setCurrentCCP);
  }, [links]);
  
  const onToggleAgencies = () => {
    const addAgentList = !useAgencies;
    if (addAgentList) {
      onChange(LINK_SECTION_DEFAULT(currentCCP));
    } else {
      onChange(undefined);
    }
  }

  const onTitleChange = (e) => {
    const {target: { value }} = e;
    if (agentList) {
      onChange({
        ...agentList,
        title: value
      });
    }
  }

  const onButtonTextChange = (e) => {
    const {target: {value}} = e;
    if (agentList) {
      const def = LINK_SECTION_DEFAULT(currentCCP);
      onChange({
        ...agentList,
        links: [{
          ...def.links[0],
          title: value,
        }]
      })
    }
  }

  return (
    <TravelAgentsView
      missMatchError={missMatchError}
      currentAgentLayout={currentAgentLayout}
      currentCCP={currentCCP}
      errors={errors}
      useAgencies={useAgencies}
      sectionTitle={agentList?.title || ''}
      onToggleAgencies={onToggleAgencies}
      onTitleChange={onTitleChange}
      onButtonTextChange={onButtonTextChange}
      buttonText={agentList?.links[0].title || ''}
    />
  )
};

export default TravelAgentsContainer;
