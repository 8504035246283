/* eslint-disable */
import React from 'react';
import styles from './info.module.scss';
import config from '../../../../config';
import { matchLevelMap } from '../../utils/companies';

const getFormattedWebsites = (arr = []) => {
  const newLine = '<br/>';
  const htlmString = arr.map((i) => {
    const aTag = `
    <a
      href=${i}
      target="_blank"
      rel="noopener noreferrer"
    >
      ${i}
    </a>`;
    return aTag;
  }).join(newLine);
  return htlmString;
};

const InfoView = ({
  name,
  id,
  nodeId,
  country,
  websites,
  priority
}) => (
  <div className={styles.company}>
    <a
      className={styles.company__name}
      href={`${config.Z3_URL}/travel-company?id=${id}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      {`(${nodeId}) ${name}`}
    </a>
    <p>{`Agency Based: ${country}`}</p>
    <p dangerouslySetInnerHTML={{__html: getFormattedWebsites(websites)}}/>
    <p>{`Matching Priority Level: ${matchLevelMap[priority]}`}</p>
  </div>
);

export default InfoView;
