import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Alert, AlertTitle } from '@mui/material';


const GoogleLoginView = ({
  onSuccess,
}) => {
  const [googleError, setGoogleError] = useState(false);

  const onFailure = () => {
    setGoogleError(true);
  };

  const handleSuccess = (googleUserToken) => {
    setGoogleError(false);
    const loginRequest = {
      googleToken: googleUserToken.credential || '',
    };
    onSuccess(loginRequest);
  };

  return (
    <>
      <GoogleLogin
        logo_alignment="left"
        onError={onFailure}
        onSuccess={handleSuccess}
        size="large"
        text="signin_with"
        width="333px"
      />
      {googleError && (
        <Alert>
          <AlertTitle>
            There was an error logging in to your Google account.
          </AlertTitle>
        </Alert>
      )}
    </>
  );
};

export default GoogleLoginView;
