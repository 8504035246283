import React from 'react';
import PropTypes from 'prop-types';
import LoginModalView from './login-view';
import useApiClient from '../../hooks/use-api-client';
import LocalStorageService from '../../utils/local-storage-service';
import { getMatchingUser, hasAuthorizedRole } from '../../utils/auth';

const LocalStorage = new LocalStorageService();

const LoginContainer = ({
  error,
  loading,
  loginError,
  loginRequest,
  loginSuccess,
  logout,
  open,
  setRoles,
  useOverlay,
 }) => {
  const apiClient = useApiClient();

  const onSubmit = (request) => {
    loginRequest();

    apiClient.authClient.login(request)
      .then(async (data) => {
        apiClient.instance.setAuthToken(data.accessToken);
        const token = await apiClient.authClient.getToken(data.accessToken);

        LocalStorage.addToLocalStorage('accessToken', data.accessToken);
        LocalStorage.addToLocalStorage('email', token.email);
        // Prevents non zicasso emails from being used
        if (!token.email.includes('zicasso')) {
          throw new Error('Not Authorized');
        }
        return apiClient.peopleClient.getTeamMember(token.email);
      })
      .then((data) => {
        const matchingUser = getMatchingUser(data.email, data.userInfo);
        if (!matchingUser || !hasAuthorizedRole(matchingUser.roles)) {
          throw new Error('Not Authorized');
        }
        setRoles(matchingUser.roles);
        loginSuccess(data.email);
      })
      .catch((e) => {
        logout();
        loginError(e);
      });
  };

  return (
    <LoginModalView
      error={error}
      loading={loading}
      onSubmit={onSubmit}
      open={open}
      useOverlay={useOverlay}
    />
  );
};
LoginContainer.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  loginError: PropTypes.func.isRequired,
  loginRequest: PropTypes.func.isRequired,
  loginSuccess: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setRoles: PropTypes.func.isRequired,
  useOverlay: PropTypes.bool,
};
LoginContainer.defaultProps = {
  error: undefined,
  useOverlay: null,
};

export default LoginContainer;
