import React from 'react';
import ZInput from '../z-input';
import ZPages from '../z-pages';
import ZPage from '../z-page';
import ZToast from '../z-toast';
import FloatingSave from '../shared/components/floating-save/floating-save-view';
import Content from './content';
import Optimization from './optimization';
import Itineraries from './itineraries/';
import HelpfulContent from './helpful-content';
import Faq from './faq-section'
import Publications from './publications';
import UsageReferences from '../usage-references';
import Versions from './versions';
import Subnav from './subnav';
import Reviews from './reviews';
import PublishedStatus from '../shared/components/published-status';
import style from './landing-page.module.scss';
import sharedStyle from '../shared/styles/shared.module.scss';
import pageStyle from '../shared/styles/page.module.scss';
import Config from '../../config';

const baseUrlWeb = `${Config.Z3_URL}`;

const LandingPageView = ({
  btnText,
  disabledMessage,
  errors,
  id,
  onSave,
  onTitleChange,
  onToastClose,
  openToast,
  readOnly,
  saved,
  saveDisabled,
  title,
  saveBtnDisabled,
  updateId,
  validFaqData,
}) => (
  <>
    <div className={style['landing-page']}>
      <h1>
        <ZInput
          error={errors && errors.title}
          label='Title'
          onUpdate={onTitleChange}
          initialValue={title}
          style={{margin: '14px 28px', fontSize: '22px'}}
          inputKey={'landingPage__title'}
        />
      </h1>
      <div className={pageStyle['landing-page__header']} style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <PublishedStatus entityId={id} entityUpdateId={updateId} />
          <h2>{ saved ? 'Saved' : 'Not Saved' }</h2>
        </div>
        {readOnly && <h3 className={sharedStyle['read-only']}>Read Only View</h3>}
        <div className={pageStyle['preview-wrapper']}>
          <a
            className={`${pageStyle['preview-button']} ${sharedStyle['button']}`}
            href={`${baseUrlWeb}/dynamic?type=LandingPage&id=${id}`}
            target='_blank'
            rel="noopener noreferrer"
          >
            Preview
          </a>
        </div>
      </div>
      <ZPages readOnly={readOnly}>
        <ZPage tabName='Creation' key={`${1}-Creation`}>
          <Content
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Itineraries' key={`${2}-Itineraries`}>
          <Itineraries
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Helpful Content' key={`${6}-Articles`}>
          <HelpfulContent
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='FAQ' key={`${6}-faq`}>
          <Faq
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Optimization' key={`${2}-Optimization`}>
          <Optimization
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Sub Navigation' key={`${3}-SubNav`}>
          <Subnav
            entityId={id}
          />
        </ZPage>
        <ZPage tabName='Usage References' key={`${4}-UsageReferences`}>
            <UsageReferences
              entityId={id}
            />
          </ZPage>
        <ZPage tabName='Versions' key={`${5}-Versions`}>
          <Versions
            entityId={id}
            onSave={onSave}
          />
        </ZPage>
        <ZPage tabName='Publications' key={`${6}-Publications`}>
          <Publications />
        </ZPage>
        <ZPage tabName='Review Preview' key={`${7}-reviews`}>
          <Reviews
            entityId={id}
          />
        </ZPage>
      </ZPages>
      <div className={style['action-container']}>
        {!title &&
          <p className={[`${sharedStyle.error} ${style['action-container__message']}`]}>
            A title is required to save.
          </p>
        }
        {!validFaqData &&
          <p className={[`${sharedStyle.error} ${style['action-container__message']}`]}>
            A title and description are required for each FAQ item.
          </p>
        }
        { disabledMessage &&
          <p className={style[`action-container__save`]}>
            {disabledMessage}
          </p>
        }
      </div>
    </div>
    <ZToast
      open={openToast}
      onClose={onToastClose}
      message={'Landing Page Saved!'}
    />
    <FloatingSave
      onClick={onSave}
      disabled={!title || saveDisabled || saveBtnDisabled || !validFaqData }
      text={btnText}
    />
  </>
);

export default LandingPageView;
