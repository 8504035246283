import React from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '../../../shared/components/breadcrumbs';
import {
  addBreadcrumbT,
  deleteBreadcrumbsT,
  deleteBreadcrumbTitleT,
  setBreadcrumbTitleT
} from "../../../../state/actions/shared/breadcrumbs";

const BreadcrumbsRedux = (props) => (
  <Breadcrumbs
    entityType="content"
    searchEntities={['landing_page', 'guide', 'content']}
    {...props}
  />
);

const mapStateToProps = (state, ownProps) => {
  const {entityId} = ownProps;
  const pageEntities = state.pages.page;
  const {editor} = pageEntities;
  const currentPage = editor[entityId]
  const { breadcrumb, breadcrumbTitle, title, updateId } = currentPage;
  return {
    breadcrumb,
    breadcrumbTitle,
    entityId,
    entityTitle: title,
    entityUpdateId: updateId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    onDeleteBreadcrumb: () => dispatch(deleteBreadcrumbsT('content', id)),
    onDeleteBreadcrumbTitle: () => dispatch(deleteBreadcrumbTitleT('content', id)),
    onSelectBreadcrumb: (breadcrumb) => dispatch(addBreadcrumbT('content', breadcrumb, id)),
    onSetBreadcrumbTitle: (breadcrumbTitle) => dispatch(setBreadcrumbTitleT('content', breadcrumbTitle, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsRedux);
