import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ZInput from '../../z-input';
import ZToggle from '../../z-toggle';
import ZButton from '../../z-button';
import ZSpinner from '../../z-spinner';
import { decodeHTMLEntities } from '../../../utils/window';
import styles from './career-editor.module.scss';

const CareerEditorView = ({
  closeModal,
  displayed,
  error,
  id,
  isSaving,
  onChangeDisplayed,
  onClickSave,
  saveDisabled,
  setTitle,
  setLocation,
  setUrl,
  title,
  location,
  url,
  onClickDelete,
  deleteError,
  urlError,
}) => (
  <div className={styles['editor']}>
    <ZToggle
      checked={displayed}
      label="Displayed"
      onChange={onChangeDisplayed}
      toggleClass={styles['editor__toggle']}
    />
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}>
      <ZInput
        inputId="title"
        label="Title"
        onChange={setTitle}
        placeholder="Enter title here"
        style={{ 
          marginBottom: '20px',
          marginRight: '14px',
          width: '100%',
        }}
        text={decodeHTMLEntities(title)}
      />
      <ZInput
        inputId="location"
        label="Location"
        onChange={setLocation}
        placeholder="Enter Job Location"
        style={{
          marginBottom: '20px',
          width: '100%',
        }}
        text={location}
      />
    </div>
    <ZInput
      inputId="uri"
      label="Url"
      onChange={setUrl}
      placeholder="Link to the HR application page"
      style={{
        marginBottom: '20px',
        width: '100%',
      }}
      text={url}
    />
    {error && (
      <p className={styles['error']}>There was an error saving this career.</p>
    )}
    {deleteError && (
      <p className={styles['error']}>There was an error deleting this career.</p>
    )}
    {urlError && (
      <p className={styles['error']}>Please add a valid url. Example: https://zicasso.bamboohr.com/jobs/view.php?id=108</p>
    )}
    <div className={styles['editor__buttons']}>
      {isSaving ? (
        <ZSpinner />
      ) : (
        <>
          <ZButton
            btnClass={styles['editor__button']}
            disabled={saveDisabled}
            onClick={onClickSave}
            text="Save"
          />
          <ZButton
            btnClass={styles['editor__button']}
            onClick={closeModal}
            text="Cancel"
          />
          {id !== 'new' && (
            <ZButton
              btnClass={styles['editor__button']}
              onClick={onClickDelete}
              text="Delete"
            />
          )}
        </>
      )}
    </div>
  </div>
);

export default CareerEditorView;
