import React from 'react';
import PropTypes from 'prop-types';
import styles from './histogram.module.scss';

const Histogram = ({
  setSelectedStarLevel,
  ratingsAggregation,
  reviewCount,
}) => (
  <div className={styles['histogram']}>
    { [100, 80, 60, 40, 20].map((rating) => (
      <div className={styles['histogram--item']} key={`rating-${rating}`}>
        <button
          className={styles['histogram__ratings-button']}
          disabled={!ratingsAggregation[rating] || ratingsAggregation[rating] === 0}
          onClick={() => setSelectedStarLevel(rating)}
          onKeyUp={(e) => { if (e.code === 'Enter' || e.code === 'NumpadEnter') setSelectedStarLevel(rating); }}
          type="button"
        >
          {rating / 20}
          &nbsp;stars
        </button>
        <ProgressBar progress={ratingsAggregation[rating] / reviewCount} />
        <span>{ratingsAggregation[rating] || 0}</span>
      </div>
    ))}
  </div>
);
Histogram.propTypes = {
  setSelectedStarLevel: PropTypes.func.isRequired,
  ratingsAggregation: PropTypes.object.isRequired,
  reviewCount: PropTypes.number.isRequired,
};

const ProgressBar = ({ progress }) => (
  <div
    className={styles['progress-bar--outer']}
  >
    { progress > 0 && (
      <div
        className={styles['progress-bar--inner']}
        style={{ width: `${progress * 100}%` }}
      />
    )}
  </div>
);
ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default Histogram;
