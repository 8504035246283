import React from 'react';
import ZTextArea from '../../z-text-area';
import PhotoPreview from '../../shared/components/content-image'
import Section from './section';
import style from './content.module.scss';
import ZDraggableGrid from '../../z-draggable-grid.js';
import ZAddButton from '../../z-button-add';
import { getImagePreviewTransformation } from '../../../utils/image/image-utils';

const ContentView = ({
  id,
  summary,
  sections,
  onSummaryChange,
  onSectionTitleChange,
  onSectionTextChange,
  onRemoveSectionClick,
  onDragEnd,
  headerPhotoId,
  addSection,
  onAddSectionClick,
  onTopArticleClick,
  targetEle,
  onChangePhoto,
  onCreateHeaderPhoto,
  onCreateSectionPhoto,
  onDeleteHeaderPhoto,
  onDeleteSectionPhoto,
  photos,
  readOnly,
}) => {
  return (
    <>
      <br/>
      <h2>Header Photo</h2>
      <div className={style['hero-image']}>
        <PhotoPreview
          entityId={id}
          photoId={headerPhotoId}
          style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
          showInfo
          onChangePhoto={onChangePhoto}
          onCreatePhoto={onCreateHeaderPhoto}
          onRemovePhoto={onDeleteHeaderPhoto}
          photo={photos[headerPhotoId]}
          transformation={getImagePreviewTransformation({ aspectRatio: 3.31 })}
        />
      </div>
      <br/>
      <ZTextArea
        allowedFormats={['LINKS']}
        label="Introduction"
        onUpdate={onSummaryChange}
        value={summary}
      />
      <div className={style['add-section']}>
        <ZAddButton onClick={onTopArticleClick}>Add Section</ZAddButton>
      </div>
      <br/>
      <ZDraggableGrid onDragEnd={onDragEnd} disabled={readOnly}>
        { sections.map( (ele, index) => (
          <Section
            key={ele.id}
            title={ele.title}
            text={ele.text}
            id={ele.id}
            order={index + 1}
            index={index}
            onSectionTitleChange={onSectionTitleChange}
            onSectionTextChange={onSectionTextChange}
            onRemoveSectionClick={onRemoveSectionClick}
            onAddSectionClick={onAddSectionClick}
            onChangePhoto={onChangePhoto}
            onCreatePhoto={onCreateSectionPhoto}
            onRemovePhoto={onDeleteSectionPhoto}
            photo={photos[ele.photoId] || {}}
        />
        ))}
      </ZDraggableGrid>
      <div ref={targetEle}/>
      <br/>
      <div className={style['add-section']}>
        <ZAddButton onClick={addSection}>Add Section</ZAddButton>
      </div>
    </>
  )
}

export default ContentView;
