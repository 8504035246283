
export const addAtIndex = (arr = [], ele, i) => {
  if(!i){
    return [...arr, ele];
  } else if (i > arr.length) {
    return [...arr, ele]
  } else {
    let res =  [...arr];
    res.splice(i, 0, ele);
    return res
  }
};

export const addIfMissing = (ele, arr = []) => {
  if(arr.indexOf(ele) === -1){
    return [...arr, ele];
  } else {
    return [...arr];
  }
};
