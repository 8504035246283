import React from 'react';
import ZModal from '../../z-modal';
import style from '../../shared/styles/pages-list.module.scss';

const ImagePreviewModal = ({gravity, previewSrc, showModal, toggleModal}) => {
  let baseUri = `https://res.cloudinary.com/zicasso/image/fetch/q_auto:low,ar_3:2,c_crop`;
  if (gravity !== 'auto') {
    baseUri += `,g_${gravity}`;
  }

  return (
  <ZModal
    closeable
    open={showModal}
    toggleModal={toggleModal}
    useOverlay
    style={{padding: '28px', display: 'flex', flexDirection: 'column'}}
  >
    <div className={style['modal__header']} style={{textAlign: 'center'}}>
      <h1>Image Preview</h1>
      <h3>Gravity: {gravity}</h3>
      <img
        alt="preview"
        src={`${baseUri}/${previewSrc}`}
        style={{maxWidth: '100%'}}
      />
    </div>
    <div className={style['modal__actions']}>
      <button
        type="button"
        className={style['pages-list__button']}
        onClick={toggleModal}>
          Close
      </button>
    </div>
  </ZModal>
  );
}

export default ImagePreviewModal;
