/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/*
 * This component contains the logic for a carousel.
 * You should only use this component with more than 1 item.
 *
 * This carousel can be used to extend custom wrapper
 * and item components. E.g:
 *
 * <CarouselProvider items={...}
 *                   startIndex={...}
 *                   numItemsToMove={...}
 *                   keyboardNavigation={...}>
 *    <CustomCarouselWrapper {...props}>
 *          <CustomCarouselItem {...props}/>
 *    </CustomCarouselWrapper>
 * </CarouselProvider>
 *
 * The expected props are : {
 *    numItemsToMove(optional): number,
 *    startIndex(optional): number,
 *    keyboardNavigation(optional): boolean,
 *    lockScroll(optional): boolean,
 *    items: [ any ],
 *    children: React component
 * }
 */

const CarouselProvider = ({
  items,
  keyboardNavigation,
  lockScroll,
  numItemsToMove,
  numItemsToDisplay,
  startIndex,
  ...props
}) => {
  const numToMove = numItemsToMove || 1;
  const numToDisplay = numItemsToDisplay || 1;

  // Duplicate some starting content to end, hides transition
  const extendedItems = [...items.slice(numToDisplay * -1), ...items, ...items.slice(0, numToDisplay)];
  const [animation, setAnimation] = useState({
    index: (!startIndex && startIndex !== 0) ? numToDisplay : startIndex,
    items: extendedItems,
    disableAnimation: false,
    leftReset: false,
    rightReset: false,
    numItemsToMove: numToMove,
  });
  // Ref to store setTimeout
  const resetTimeout = useRef(null);
  const isDeboucing = useRef(false);

  // Distance between touch start and end (for swipe)(px)
  const SWIPE_BUFFER_DISTANCE = 10;
  // Length of transition (ms)
  const ANIMATION_LENGTH = 300;
  // Starting touch position
  let startScreenX;

  const moveLeft = () => {
    if (!isDeboucing.current) {
      if (animation.index === numToMove) {
        // Move to end without animation
        setAnimation({
          ...animation,
          index: animation.index - animation.numItemsToMove,
          disableAnimation: false,
          leftReset: true,
        });
      } else if (animation.index - animation.numItemsToMove < numToMove) {
        // Only move partially
        const extraItems = (items.length % animation.numItemsToMove);
        setAnimation({
          ...animation,
          index: animation.index - extraItems,
          disableAnimation: false,
        });
      } else {
        // Continue with animation
        setAnimation({
          ...animation,
          index: animation.index - animation.numItemsToMove,
          disableAnimation: false,
        });
      }
    }
  };

  const moveRight = () => {
    if (!isDeboucing.current) {
      if (animation.items.length - animation.index - numToMove < animation.numItemsToMove) {
        // Move to beginning without animation
        setAnimation({
          ...animation,
          index: items.length + numToMove,
          disableAnimation: false,
          rightReset: true,
        });
      } else if (animation.items.length
                - (animation.index - 1) < animation.numItemsToMove) {
        // Only move partially
        const extraItems = (items.length % animation.numItemsToMove);
        setAnimation({
          ...animation,
          index: animation.index + extraItems,
          disableAnimation: false,
        });
      } else {
        // Default animation, move numItemsToMove
        setAnimation({
          ...animation,
          index: animation.index + animation.numItemsToMove,
          disableAnimation: false,
          rightReset: animation.index === items.length,
        });
      }
    }
  };

  const handleKeyboard = (event) => {
    if (event.keyCode === 37) {
      moveLeft();
    } else if (event.keyCode === 39) {
      moveRight();
    }
  };
  // After state changes
  useEffect(() => {
    if (animation.leftReset) {
      isDeboucing.current = true;
      resetTimeout.current = setTimeout(() => {
        setAnimation({
          ...animation,
          index: items.length,
          disableAnimation: true,
          leftReset: false,
        });
        isDeboucing.current = false;
      }, 300);
    }

    if (animation.rightReset) {
      isDeboucing.current = true;
      resetTimeout.current = setTimeout(() => {
        setAnimation({
          ...animation,
          index: numToMove,
          disableAnimation: true,
          rightReset: false,
        });
        isDeboucing.current = false;
      }, 300);
    }

    if (keyboardNavigation) {
      document.addEventListener('keydown', handleKeyboard);
    }

    return () => {
      if (keyboardNavigation) {
        document.removeEventListener('keydown', handleKeyboard);
      }
    };

    // Let me not pray to be sheltered from dangers, but to be fearless in facing them.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation, lockScroll, keyboardNavigation]);

  const onTouchStart = (event) => {
    startScreenX = event.touches[0].screenX;

    if (lockScroll) {
      document.ontouchmove = (e) => e.preventDefault();
    }
  };

  const onTouchEnd = (event) => {
    const endScreenX = event.changedTouches[0].screenX;
    const difference = endScreenX - startScreenX;
    if (Math.abs(difference) >= SWIPE_BUFFER_DISTANCE) {
      if (difference > 0) {
        moveLeft();
      } else {
        moveRight();
      }
    }

    if (lockScroll) {
      document.ontouchmove = (e) => e;
    }
  };

  const setIndex = (index) => {
    setAnimation({
      ...animation,
      index,
    });
  };

  // Separate children from props to de-dupe
  const { children, ...otherProps } = props;

  return (
    React.cloneElement(children, {
      allItems: animation.items,
      animationLength: ANIMATION_LENGTH,
      disableAnimation: animation.disableAnimation,
      index: animation.index,
      items,
      keyboardNavigation,
      lockScroll,
      next: () => moveRight(),
      numItemsToDisplay,
      numItemsToMove,
      prev: () => moveLeft(),
      setIndex,
      startIndex,
      touchEnd: (endEvent) => onTouchEnd(endEvent),
      touchStart: (startEvent) => onTouchStart(startEvent),
      ...otherProps,
    })
  );
};
CarouselProvider.propTypes = {
  children: PropTypes.element.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  keyboardNavigation: PropTypes.bool,
  lockScroll: PropTypes.bool,
  numItemsToMove: PropTypes.number,
  startIndex: PropTypes.number,
};
CarouselProvider.defaultProps = {
  keyboardNavigation: false,
  lockScroll: false,
  numItemsToMove: null,
  startIndex: null,
};

export default CarouselProvider;
