import { combineReducers } from 'redux';
import newsSources from './news-sources';
import newsSnippets from './news-snippets';

const news = combineReducers({
  newsSources,
  newsSnippets,
});

export default news;
