import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import PropTypes from 'prop-types';

const ZTitleFlag = ({
  isoCode,
  title,
  style,
  className,
}) => (
  <ReactCountryFlag
    countryCode={isoCode}
    className={className}
    svg
    style={{
      width: '100%',
      maxWidth: '2.5em',
      height: '100%',
      maxHeight: '2.5em',
      ...style,
    }}
    title={title}
  />
);

ZTitleFlag.propTypes = {
  isoCode: PropTypes.string.isRequired,
  title: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

ZTitleFlag.defaultProps = {
  style: {},
  className: '',
  title: '',
};

export default ZTitleFlag;
