import React from 'react';
import ZInput from '../../../z-input';
import ZSpinner from '../../../z-spinner';
import ZDeleteButton from '../../../z-button-delete';
import { ReactComponent as CopySVG } from '../../../../assets/logos/copy.svg';
import { onCopy } from '../../../../utils/window';
import style from './logo.module.scss';

const PhotoPreviewView = ({
  alt,
  fileName,
  gravity,
  info,
  isFetching,
  onChange,
  onLoad,
  logoId,
  placeHolder,
  readOnly,
  removePhoto,
  showDelete,
  showInfo,
  src,
  title,
  validUri,
}) => (
  <>
    <div className={style['photo__header']}>
      <p>Logo</p>
      <div className={style['photo__remove']}>
        {showDelete &&
          <ZDeleteButton onClick={removePhoto}>Delete Logo</ZDeleteButton>
        }
      </div>
    </div>
    <div className={style['photo']} style={style}>
      <div className={style['photo__photo']}>
        <img
          alt={alt}
          onLoad={onLoad}
          src={!!info.uri && validUri ? src : placeHolder}
          width={100}
        />
      </div>
      <div className={style['photo__input']}>
        <ZInput
          error={!validUri && { message: 'Please use an image from the Photo App.' }}
          label={!!src ? '' : 'Paste a Url'}
          style={{ width: '100%', marginBottom: '54px', wordBreak: 'break-all' }}
          text={src || ''}
          onChange={onChange('uri')}
          inputKey={`${logoId}__url`}
          readOnly={readOnly}
        />
        {isFetching && <div className={style['spinner']}><ZSpinner /></div>}
        {!isFetching && (
          <>
            <div className={style['photo__meta']}>
              <ZInput
                label='Alt Text'
                text={alt || ''}
                style={{ width: '100%', marginRight: '7px', borderLeft: '1px solid #bdbdbd' }}
                onChange={onChange('alt')}
                inputKey={`${logoId}__alt`}
              />
              <ZInput
                label='Title Text'
                text={title || ''}
                style={{ width: '100%', borderLeft: '1px solid #bdbdbd' }}
                onChange={onChange('title')}
                inputKey={`${logoId}__title`}
              />
            </div>
            {showInfo &&
              <div className={style['info']}>
                <p className={style['info__field']}>{`Date Added: ${info.createdAt || ''}`}</p>
                <p className={style['info__field']}>
                  {`Dimensions (H x W): ${info.height && info.width ? `${info.height} x ${info.width}` : ''}`}
                </p>
                <p className={`${style['info__field']}`}>
                  Source:
                  <br />
                  <a href={info.sourceUri} target="_blank" rel="noopener noreferrer">{info.sourceUri}</a>
                </p>
                {info.sourceType === 'Cloudinary' &&
                  <p className={style['info__copy']} onClick={() => onCopy(fileName)}>
                    Copy File Name <CopySVG />
                  </p>
                }
              </div>
            }
          </>
        )}
      </div>
    </div>
  </>
);

export default PhotoPreviewView;
