import React from 'react';
import { useParams } from 'react-router';
import ZPublications from '../../z-publications';

const PublicationsView = () => {
  const { id } = useParams(); 
  return (
    <ZPublications
      entityId={id}
    />
  );
};

export default PublicationsView;
