import React from 'react';
import LandingPageView from '../../../components/landing-page';

const LandingPageId = (props) => {
  return(
    <LandingPageView {...props}></LandingPageView>
  );
}

export default LandingPageId;
