import React from 'react';

const base = {
  width: 0,
  height: 0,
  cursor: 'pointer',
}
const style = {
  up: {
    ...base,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid black',
  },
  down: {
    ...base,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '15px solid black',
  },
  right: {
    ...base,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '15px solid black',
  },
  left: {
    ...base,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '15px solid black',
  }
}

const ZArrow = ({direction}) => {
  const arrowDirection = direction || 'up'
  return (
    <div
      style={style[arrowDirection]}
    />
  )
};

export default ZArrow;
