import React from 'react';
import {
  setCompany,
  removeCompany,
  getCompanies,
} from '../../../../state/actions/itinerary-editor';
import { connect } from 'react-redux';
import TravelCompanyContainer from './travel-company-container';

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const currentEntities = state.itineraries.itinerary;
  const {editor} = currentEntities;
  const { travelCompanies } = editor;
  const currentEntity = editor[id] || {};
  return {
    travelCompanies : travelCompanies || {},
    company: currentEntity.company || {},
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    getCompanies: () => dispatch(getCompanies()),
    onSelect: (company) => dispatch(setCompany(company, id)),
    onDelete: () => dispatch(removeCompany(id)),
  }
}

const TravelCompanyRedux = (props) => (
  <TravelCompanyContainer {...props}/>
)

export default connect(mapStateToProps, mapDispatchToProps)(TravelCompanyRedux);
