import React from 'react';
import AuthorView from './author-view';

const AuthorContainer = ({
  authorName,
  authorLink,
  onAuthorNameChange,
  onAuthorLinkChange,
})=>{
  return (
    <AuthorView
      authorName={authorName}
      authorLink={authorLink}
      onAuthorNameChange={onAuthorNameChange}
      onAuthorLinkChange={onAuthorLinkChange}
    />
  )
};

export default AuthorContainer;
