import { push } from 'connected-react-router';
import ContentClient from '../../../utils/client/content';
import { fetchTuid } from '../../../utils/fetch/tuid';
import {
  receivedPhotosT,
} from '../shared/photo';
import {
  receivedSectionsT
} from '../shared/section';
import {
  getCountriesT
} from "../shared/location";
import {
  fetchEntityVersion,
  addSavedEntityVersion,
  versionSuccess,
} from '../versions';
import ZApiClient from '../../../utils/client/z-api-client';
import { Content } from '@zicasso/zicasso-sdk/lib/content';

import {
  REQUEST_PAGE,
  REQUEST_NEW_PAGE,
  RECEIVED_PAGE,
  PAGE_SAVE_REQUESTED,
  PAGE_SAVED,
  REQUEST_PAGE_FAILED,
} from '../shared/similar-action-types';
import {
  SET_TYPE,
  SET_SHOW_TOC,
  SET_SHOW_DATE,
} from './action-types';

const contentClient = new ContentClient();

const requestPage = () => ({
  type: REQUEST_PAGE,
});

export const requestNewPage = () => ({
  type: REQUEST_NEW_PAGE,
});

const receivedPage = (data) => ({
  type: RECEIVED_PAGE,
  receivedAt: Date.now(),
  id: data.id,
  page: data,
});
export const saveRequested = () => ({
  type: PAGE_SAVE_REQUESTED,
});

export const pageSaved = (data) => ({
  type: PAGE_SAVED,
  data
});

export const pageRequestFailed = () => ({
  type: REQUEST_PAGE_FAILED,
});

export const setType = (type, id) => ({
  type: SET_TYPE,
  value: type,
  id,
});

// TOC short for Table of Contents
export const setShowTOC = (id, bool) => ({
  type: SET_SHOW_TOC,
  id,
  value: bool,
});

export const setShowDate = (id, bool) => ({
  type: SET_SHOW_DATE,
  id,
  value: bool,
});

export const redirectToCreate = () => {
  return dispatch => {
    dispatch(push('/create/simplePage'));
  }
};

function shouldFetchPage(state, id, updateId) {
  const {editor} = state.pages.page;
  if(!editor[id]) {
    return true;
  } else if (editor[id] && editor[id].updateId !== updateId) {
    return true;
  } else {
    return false;
  }
}

/**
 * Pass the result of content client getState method;
 * @param {object} pageState
 */
const receivePageState = (pageState) => {
  return dispatch => {
    let {id:currentId} = pageState;
    dispatch(receivedPhotosT('content', pageState.photos));
    dispatch(receivedSectionsT('content', pageState.sections));
    dispatch(receivedPage(pageState[currentId]));
  }
};

/**
 *
 * @param {string} id
 */
export const fetchPageIfNeeded = (id, updateId) => {
  return (dispatch, getState) => {
    const { countries } = getState().pages.page.editor;
    const shouldFetch = shouldFetchPage(getState(), id, updateId);

    if (!countries || countries.length === 0)  {
      dispatch(getCountriesT('content'));
    }
    if(shouldFetch){
      dispatch(fetchPage(id, updateId));
    }
  }
};

export const fetchPage = (id, updateId) => {
  return dispatch => {
    dispatch(requestPage());
    const req = !updateId
      ? ZApiClient.getContentsClient().getContent(id)
      : ZApiClient.getContentsClient().getContentVersion(id, updateId);
    return req.then((res)=>{
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return contentClient.getState(res);
    }).then((newState)=>{
      dispatch(receivePageState(newState));
    }).catch((e)=>{
      if(e.message === 'NOT_FOUND'){
        dispatch(pageRequestFailed());
      }
      console.error('Error Fetching Content', e);
    })
  };
};

export const savePage = (pageId) => {
  return (dispatch, getState) => {
    dispatch(saveRequested());
    ZApiClient
    .getContentsClient()
    .exists(pageId)
    .then((res)=>{
      const shouldCreate = !res;
      const currentState = getState();
      const {page} = currentState.pages;
      const pageEntity = contentClient.getContent(pageId, page);
      return createOrUpdateContent(pageEntity, shouldCreate);
    }).then((result)=>{
      dispatch(pageSaved());
      const { id, updateId, userId, updatedAt } = result;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return contentClient.getState(result);
    }).then((newState)=>{
      dispatch(receivePageState(newState));
    }).catch((e)=>{
      console.error('Error Saving Content', e);
      console.error(e.stack);
    });
  };
};

export const makeLatest = (id, updateId) => (dispatch) => {
  dispatch(requestPage());
  return ZApiClient
  .getContentsClient()
  .getContentVersion(id, updateId)
    .then((res) => {
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return createOrUpdateContent(res, false);
    })
    .then((res) => {
      const { id, updateId, userId, updatedAt } = res;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return contentClient.getState(res);
    })
    .then((newState) => {
      dispatch(receivePageState(newState));
    })
    .catch((e) => {
      if(e.message === 'NOT_FOUND'){
        dispatch(pageRequestFailed());
      }
      console.error('Error Fetching Page', e);
    });
};

const createOrUpdateContent = (data, create = true) =>{
  if(create){
    return ZApiClient.getContentsClient().createContent(new Content(data));
  } else {
    return ZApiClient.getContentsClient().updateContent(new Content(data));
  }
};


export const createNewPage = () => {
  return dispatch => {
    dispatch(requestNewPage());
    return fetchTuid()
      .then(val => {
        let {id} = val;
        dispatch(receivedPage({
          id : id,
          title: null,
          summary: '',
          type: 'EVERGREEN',
          sectionIds: [],
          metatags: {},
        }));
        dispatch(push(`/simple-pages/${id}`));
      })
  }
};

export const fetchPageVersion = (id, versionId) => (dispatch) => {
  dispatch(fetchEntityVersion('content', id, versionId))
    .then(() => {
      dispatch(versionSuccess(id, versionId));
    })
    .catch(console.error);
};
