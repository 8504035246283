import {
  REQUEST_PAGES,
  RECEIVED_PAGES,
  REQUEST_GUIDES,
  RECEIVED_GUIDES,
  REQUEST_ARTICLES,
  RECEIVED_ARTICLES,
  REQUEST_ITINS,
  RECEIVED_ITINS,
  REQUEST_LTLS,
  RECEIVED_LTLS,
  RECEIVED_COUNTRY_LIST,
  SET_SEARCH_TERM,
  RECEIVED_COMPANY_CATEGORIES,
  REQUEST_COMPANY_CATEGORIES,
  RECEIVED_LANDING_PAGE_CATEGORIES,
  REQUEST_LANDING_PAGE_CATEGORIES,
} from '../actions/list-pages';

function entities(
  state = {
    searchTerm: '',
    receivedAll: false,
    isFetching: false,
    entities: [],
  },
  action
){
  switch (action.type) {
    case REQUEST_PAGES:
    case REQUEST_GUIDES:
    case REQUEST_ARTICLES:
    case REQUEST_ITINS:
    case REQUEST_LTLS:
    case REQUEST_COMPANY_CATEGORIES:
    case REQUEST_LANDING_PAGE_CATEGORIES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVED_ARTICLES:
    case RECEIVED_GUIDES:
    case RECEIVED_LTLS:
    case RECEIVED_ITINS:
    case RECEIVED_PAGES:
    case RECEIVED_COMPANY_CATEGORIES:
    case RECEIVED_LANDING_PAGE_CATEGORIES:
      return{
        ...state,
        isFetching: false,
        lastUpdate: action.receivedAt,
        entities: [...state.entities, ...action.entities],
        receivedAll: action.receivedAll,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.search,
      };
    default:
      return state;
  }
}

export default (
  state = {},
  action
) => {
  switch (action.type) {
    case REQUEST_PAGES:
    case RECEIVED_PAGES:
    case REQUEST_GUIDES:
    case RECEIVED_GUIDES:
    case REQUEST_ARTICLES:
    case RECEIVED_ARTICLES:
    case REQUEST_ITINS:
    case RECEIVED_ITINS:
    case REQUEST_LTLS:
    case RECEIVED_LTLS:
    case SET_SEARCH_TERM:
    case RECEIVED_COMPANY_CATEGORIES:
    case REQUEST_COMPANY_CATEGORIES:
    case RECEIVED_LANDING_PAGE_CATEGORIES:
    case REQUEST_LANDING_PAGE_CATEGORIES:
      return {
        ...state,
        [action.entityType] : entities(state[action.entityType], action),
      };
    case RECEIVED_COUNTRY_LIST:
      return {
        ...state,
        countries: action.list,
      };
    default:
      return state;
  }
}

