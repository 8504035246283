import React from 'react';
import PropTypes from 'prop-types';
import styles from './show-more-button.module.scss';

const ShowMoreButton = ({ loadMoreReviews }) => (
  <div
    className={styles['load-more']}
    onClick={loadMoreReviews}
    onKeyUp={loadMoreReviews}
    role="button"
    tabIndex={0}
  >
    <div className={styles['load-more-inner']} tabIndex={-1}>
      Show More Reviews
    </div>
  </div>
);
ShowMoreButton.propTypes = {
  loadMoreReviews: PropTypes.func.isRequired,
};

export default ShowMoreButton;
