import React from 'react';
import PropTypes from 'prop-types';
import ZImageView from '../z-image-view';

const ZLazyImage = ({
  alt,
  className,
  cloudinaryTransformProps,
  cloudinarySrc,
  gatsbyImage,
  height,
  id,
  inputKey,
  onClick,
  onError,
  onLoad,
  onStartLoad,
  src,
  style,
  title,
  useCloudinary,
  useGatsby,
  width,
}) => (
  <ZImageView
    alt={alt}
    className={className}
    cloudinaryTransformProps={cloudinaryTransformProps}
    cloudinarySrc={cloudinarySrc}
    gatsbyImage={gatsbyImage}
    height={height}
    id={id}
    inputKey={inputKey || `z-image-${cloudinarySrc || src}`}
    loading="lazy"
    onClick={onClick}
    onError={onError}
    onLoad={onLoad}
    onStartLoad={onStartLoad}
    src={src}
    style={style}
    title={title}
    useCloudinary={useCloudinary}
    useGatsby={useGatsby}
    width={width}
  />
);
ZLazyImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  cloudinaryTransformProps: PropTypes.shape({
    aspectRatio: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    crop: PropTypes.string,
    effect: PropTypes.string,
    gravity: PropTypes.string,
    quality: PropTypes.string,
  }),
  cloudinarySrc: PropTypes.string,
  gatsbyImage: PropTypes.object,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string,
  inputKey: PropTypes.string,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  onStartLoad: PropTypes.func,
  src: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  useCloudinary: PropTypes.bool,
  useGatsby: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
ZLazyImage.defaultProps = {
  alt: null,
  className: null,
  cloudinaryTransformProps: null,
  cloudinarySrc: null,
  gatsbyImage: null,
  height: null,
  id: null,
  inputKey: null,
  onClick: null,
  onError: null,
  onLoad: null,
  onStartLoad: null,
  src: null,
  style: null,
  title: null,
  useCloudinary: null,
  useGatsby: null,
  width: null,
};

export default ZLazyImage;
