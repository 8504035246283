import React from 'react';
import { connect } from 'react-redux';
import CountriesContainer from './countries-container';
import {addCountryT, setCountriesT} from "../../../../state/actions/shared/location";

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const editorEntities = state.itineraries.itinerary;
  const { editor } = editorEntities;
  const { countries:countriesList } = editor;
  const currentEntity = editor[id];
  const { countries } = currentEntity;
  return {
    countriesList,
    countries
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    onCountrySelect: (country) => dispatch(addCountryT('itinerary', country, id)),
    onCountryDelete: (countryList) => dispatch(setCountriesT('itinerary', countryList, id)),
  }
}

const CountriesRedux = (props) => (
  <CountriesContainer {...props} /> 
);

export default connect(mapStateToProps, mapDispatchToProps)(CountriesRedux);
