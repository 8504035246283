import React, { useState, useEffect, useRef } from 'react'
import DayView from './day-view';
import ZApiClient from '../../../../utils/client/z-api-client';

const defualtIncluded = {
  Transfer: false,
  Cruise: false,
  Flight: false,
  Activity: false,
  Accommodation: false,
  Tour: false
};
const defaultMeals = {
  breakfast: false,
  lunch: false,
  dinner: false
};

const getSelectValues = (list, map) => {
  const ret = {...map};
  for(const item of list ){
    ret[item] = true;
  }
  return ret;
}


const DayContainer = ({
  day,
  index,
  onUpdate,
  addDay,
  removeDay,
  onDurationChange,
}) => {
  const [whatsIncluded, setWhatsIncluded] = useState({...defualtIncluded});
  const [meals, setMeals] = useState({...defaultMeals});
  const [showConfirmation, setShowConfirmation ] = useState(false);
  const locationsRef = useRef(day.locations || []);

  useEffect(()=> {
    setWhatsIncluded(getSelectValues(day.included, defualtIncluded));
    setMeals(getSelectValues(day.meals, defaultMeals));
    locationsRef.current = day.locations || [];
  }, [day]);

  const autoComplete = (text) => {
    if (text) {
      return ZApiClient.getLocationsClient().getNamesByFragment(text, {
        contains: true
      });
    }
  }

  const onSelect = (val) => {
    if (Object.values(val)[0]) {
      const id = Object.keys(val)[0];
      const newLocation = {
        id,
        name: val[id]
      }
      const locationList = locationsRef.current;
      const index = locationList.findIndex(location => location.name === val[id]);
      if (index < 0) {
        onUpdate({
          id: day.id,
          locations: [...locationList, newLocation]
        });
      }
    }
  }

  const onTitleChange = (val) => {
    onUpdate({
      id: day.id,
      title: val,
    });
  }

  const onDescriptionChange = (val) => {
    onUpdate({
      id: day.id,
      description: val,
    })
  }

  const onWhatsIncludedChange = (e) => {
    const val = e.target.value;
    const includesValue = day.included.includes(val);
    let ret = [...day.included];
    if(includesValue){ 
      ret = ret.filter((ele)=> ele !== val);
    } else {
      ret.push(val);
    }
    onUpdate({
      id: day.id,
      included: ret,
    });
  }

  const onMealsChange = (e) => {
    const val = e.target.value;
    const includesValue = day.meals.includes(val);
    let ret = [...day.meals];
    if(includesValue){
      ret = ret.filter((ele)=> ele !== val);
    } else {
      ret.push(val);
    }
    onUpdate({
      id: day.id,
      meals: ret
    });
  }

  const durationChange = (e) => {
    const val = e.currentTarget.value;
    const ret = {
      ...day,
      duration: parseInt(val, 10),
    }
    onDurationChange(ret, index);
  }

  const onChipChange = (list) => {
    const updatedList = list.map(ele => {
      delete ele.label;
      return ele;
    });
    onUpdate({
      id: day.id,
      locations: updatedList
    });
  }

  const toggleConfirmation = () => {
    setShowConfirmation((state)=> !state);
  }

  const onDeleteClick = () => {
    removeDay(index);
  }

  const onAddClick = () => {
    addDay(index + 1);
  }

  return (
    <DayView
      id={day.id}
      dayNumber={day.startDay}
      duration={day.duration}
      title={day.title}
      description={day.description}
      whatsIncluded={whatsIncluded}
      locations={day.locations}
      meals={meals}
      autoComplete={autoComplete}
      locationSelect={onSelect}
      onTitleChange={onTitleChange}
      onDescriptionChange={onDescriptionChange}
      onWhatsIncludedChange={onWhatsIncludedChange}
      onMealsChange={onMealsChange}
      onDurationChange={durationChange}
      onChipChange={onChipChange}
      showConfirmation={showConfirmation}
      toggleConfirmation={toggleConfirmation}
      onDeleteClick={onDeleteClick}
      onAddClick={onAddClick}
    />
  )
}

export default DayContainer;
