
import {
  SET_HELPFUL_INFO
} from "./helpful-info-action-types";

export const setHelpfulInfoT = (T, text, id) => ({
  type: SET_HELPFUL_INFO(T),
  value: text,
  id
});
