import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import LandingPageCategoryContainer from './landing-page-category-container';
import { toggleReadOnly } from '../../state/actions/versions';
import { validateTitleT } from '../../state/actions/shared/error';
import {
  fetchLandingPageCategoryIfNeeded,
  saveLandingPageCategory,
} from '../../state/actions/landing-page-category-editor';

const mapStateToProps = (state, ownProps) => {
  const { landingPageCategories, versions } = state;
  const { id, updateId: paramUpdateId } = ownProps?.match?.params;
  const { editor, errors } = landingPageCategories;
  const {
    isFetching,
    notFound,
    saved,
    saveDisabled,
    disabledMessage,
  } = editor;

  return {
    currentUpdateId: editor[id]?.updateId,
    disabledMessage,
    entity: editor[id],
    errors: errors[id],
    id,
    isFetching,
    notFound,
    paramUpdateId,
    readOnly: versions.readOnly,
    saved,
    saveDisabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match } = ownProps;
  const { params } = match;
  const { id } = params;
  return {
    onCreateNew: () => dispatch(push('/create/landingPageCategory')),
    onLoad: (updateId) => dispatch(fetchLandingPageCategoryIfNeeded(id, updateId)),
    onSave: () => dispatch(saveLandingPageCategory(id)),
    onTitleChange : text => dispatch(validateTitleT('landing_page_category', text, id)),
    onToggleReadOnly: () => dispatch(toggleReadOnly()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageCategoryContainer);
