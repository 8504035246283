import React from 'react';
import { connect } from 'react-redux';
import OptimizationContainer from './optimization-container';
import {addUpstreamFooterSectionsT, createSectionT} from "../../../state/actions/shared/section";

const mapSectionIdsToSection = (ids, sections) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = sections[ele];
      return section;
    });
  }
}

const OptimizationRedux = (props) => (
  <OptimizationContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {guideId:id} = ownProps;
  const guideEntities = state.guides.guide || {};
  const {sections, editor} = guideEntities;

  const currentEntity = editor[id];
  const {title, footerSectionIds } = currentEntity;
  return {
    id,
    title,
    footerSections: mapSectionIdsToSection(footerSectionIds, sections),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {guideId:id} = ownProps;
  return {
    onCreateSection: () => dispatch(createSectionT('guide', id, false, 'FOOTER')),
    onFooterSearch: (sections) => dispatch(addUpstreamFooterSectionsT('guide', id, sections)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptimizationRedux);
