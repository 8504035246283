import React from 'react';
// import ZInput from '../../z-input';
import ZTextArea from '../../z-text-area';
import ZDraggableGrid from '../../z-draggable-grid.js';
import ZAutoComplete from '../../z-autocomplete';
import SampleArticle from './sample-article';
import PhotoPreview from '../../shared/components/content-image';
import { Stack, Typography, TextField } from '@mui/material';

const ContentView = ({
  id,
  headerPhotoId,
  autoComplete,
  autoCompleteOnSelect,
  onChangePhoto,
  onCreateHeaderPhoto,
  onDeleteHeaderPhoto,
  sections,
  summary,
  onSummaryChange,
  onRemoveSectionClick,
  onDragEnd,
  readOnly,
  photos,
  essentialInfo,
  onChangeEssentialInfo,
  helpfulInfo,
  onChangeHelpfulInfo,
}) => {
  return (
    <>
      <h2>Header Photo</h2>
      <div>
        {/** @TODO Resolve Errors */}
        {/* {props.errors.heroImage &&
          <p className={sharedStyle['error']}>{props.errors.heroImage.message}</p>
        } */}
        <PhotoPreview
          entityId={id}
          photoId={headerPhotoId}
          style={{marginBottom: '14px', borderBottom: '1px solid rgba(255, 145, 0,0.5)', paddingBottom: '7px'}}
          showInfo
          onChangePhoto={onChangePhoto}
          onCreatePhoto={onCreateHeaderPhoto}
          onRemovePhoto={onDeleteHeaderPhoto}
          photo={photos[headerPhotoId]}
        />
      </div>
      <ZTextArea
        allowedFormats={['LINKS']} 
        label="Introduction" 
        onUpdate={onSummaryChange}
        value={summary}
      />
      <Stack direction='column' spacing={1} sx={{marginBottom: '24px'}}>
        <Typography variant='h3'>Essential Information</Typography>
        <Typography variant='subtitle2' sx={{marginBottom: '8px'}}>
          Section will show top 4 articles from article grid
        </Typography> 
        <TextField
          sx={{ marginBottom: '8px', maxWidth: '50%' }}
          fullWidth
          variant="standard" 
          id="button-title"
          label="Customize Title"
          value={essentialInfo || ''}
          onChange={onChangeEssentialInfo}
        />
        <Typography variant='h3'>Helpful Travel Information</Typography>
        <Typography variant='subtitle2' sx={{marginBottom: '8px'}}>
          Section will show the remaining articles from article grid
        </Typography> 
        <TextField
          sx={{ marginBottom: '8px', maxWidth: '50%' }}
          fullWidth
          variant="standard" 
          id="button-title"
          label="Customize Title"
          value={helpfulInfo || ''}
          onChange={onChangeHelpfulInfo}
        />
      </Stack>
      <ZAutoComplete
        autoComplete={autoComplete}
        onSelect={autoCompleteOnSelect}
        returnsObject={true}
        label='Add an Article by Name'
        style={{marginBottom: '14px'}}
        error={/*errors.articles ? props.errors.articles :*/ null}
      />
      <br/>
      <ZDraggableGrid onDragEnd={onDragEnd} isGrid disabled={readOnly}>
        { sections.map((ele, index) => (
          <SampleArticle 
            article={ele}
            articlesLength={sections.length}
            index={index}
            key={ele.id}
            onChangePosition={onDragEnd}
            removeArticle={onRemoveSectionClick}
          />
      ))}
      </ZDraggableGrid>
      <br/>
    </>
  )
}

export default ContentView;
