/* eslint-disable no-undef, react/no-danger */
import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styles from './review-item.module.scss';
import Stars from '../stars';
import { ReactComponent as CalendarSVG } from '../../../assets/inline/calendar.svg';
import { ReactComponent as PinpointSVG } from '../../../assets/inline/pinpoint.svg';
import {ReactComponent as TravelCompanyCommentSVG} from '../../../assets/inline/travel_company_comment.svg';
import Destinations from '../destination-flags-list';
import PhotoGallery from './photo-gallery';
import { decodeHTMLEntities } from '../../../utils/utils';

const getFormattedDate = (date) => {
  if (!date) return '';
  const dateParts = new Date(date).toDateString().split(' ');
  const month = dateParts[1];
  const day = dateParts[2];
  const year = dateParts[3];
  return `${month} ${day}, ${year}`;
};

const getAbbreviatedName = (name) => (
  name ? name.split(' ').map((w) => w.charAt(0).toUpperCase()).join('') : ''
);

//  H2 and H3 can have same style depending on context (isSnippet)
const Title = ({
  h1,
  h2,
  h3,
  isSnippet,
  text,
}) => {
  if (h1) {
    return (
      <h1>
        {text}
      </h1>
    );
  }
  if (h2) {
    return (
      <h2
        className={isSnippet ? styles['snippet-h2'] : styles['non-snippet-h2']}
      >
        {text}
      </h2>
    );
  }
  if (h3) {
    return (
      <h3
        className={isSnippet ? styles['snippet-h3'] : ''}
      >
        {text}
      </h3>
    );
  }
  return text;
};

const ReviewItem = ({
  review,
  hideTCLink,
  isSnippet,
}) => {
  const images = review.images ? review.images.filter((image) => !image.stock) : null;

  return (
    <div className={styles['review']}>
      <Stars rating={review.rating} />
      <Title
        h1={!isSnippet}
        h2={isSnippet}
        isSnippet={isSnippet}
        text={decodeHTMLEntities(review.title)}
      />
      <div
        className={`
          ${styles['review__info']}
          ${isSnippet && styles['review__info--snippet']}
        `}
      >
        <div
          className={`
            ${styles['review__info--item']}
            ${isSnippet && styles['review__info--item--snippet']}
          `}
        >
          <div className={styles['avatarCircle']}>
            <p>
              {getAbbreviatedName(review.reviewer)}
            </p>
          </div>
          <span className={styles['review__info--item--name']}>
            {review.reviewer}
          </span>
        </div>
        <div
          className={`
            ${styles['review__info--item']}
            ${isSnippet && styles['review__info--item--snippet']}
          `}
        >
          <CalendarSVG />
          <span className={styles['review__info--item--date']}>
            {getFormattedDate(review.reviewedAt)}
          </span>
        </div>
        {!hideTCLink && (
          <div
            className={`
            ${styles['review__info--item']}
            ${isSnippet && styles['review__info--item--snippet']}
          `}
          >
            {!isSnippet && (
              <PinpointSVG />
            )}
          </div>
        )}
      </div>
      {!isSnippet && (
        <div className={styles.review__infoItem}>
          <span className={styles.review__infoItemCountryInfo}>Countries Visited</span>
          <Destinations
            className={styles.review__infoItemCountry}
            destinations={Object.entries(review.flagCountryCodes).map(([name, isoCode]) => ({
              id: isoCode,
              isoCode,
              name,
            }))}
          />
        </div>
      )}
      {images && images.length > 0 && isSnippet && (
        <PhotoGallery
          images={images}
        />
      )}
      { isSnippet && (
        <ReviewText review={review} isSnippet />
      )}
      { !isSnippet && (
        <ReviewText review={review} />
      )}
    </div>
  );
};
ReviewItem.propTypes = {
  review: PropTypes.object.isRequired,
  hideTCLink: PropTypes.bool,
  isSnippet: PropTypes.bool,
};
ReviewItem.defaultProps = {
  hideTCLink: false,
  isSnippet: false,
};

const ReviewText = ({
  review,
  isSnippet,
}) => {
  const [isExpanded, setExpanded] = useState(!isSnippet);

  const ExpandButton = () => useMemo(() => (
    <span
      aria-expanded={isExpanded}
      className={styles['review__left--summary-text--read-more']}
      onClick={() => setExpanded((i) => !i)}
      onKeyDown={(e) => e.code === 'Enter' && setExpanded((i) => !i)}
      role="button"
      tabIndex={0}
    >
      { isExpanded ? 'Show Less' : ' Show More' }
    </span>
  ), []);

  return (
    <>
      {review.summary && isSnippet && !isExpanded && (
        <>
          <div
            className={`
              ${styles['review__left--summary-text']}
              ${styles['review__left--summary-text--short']}
            `}
            dangerouslySetInnerHTML={{
              __html: review.summary,
            }}
          />
          { !isExpanded && (
            <ExpandButton />
          )}
        </>
      )}
      {review.mainReview && isExpanded && (
        <>
          { !isSnippet && (
            <Title
              h2
              text="Your overall comments on the trip and the travel company:"
            />
          )}
          <div
            className={styles['review__left--summary-text']}
            dangerouslySetInnerHTML={{
              __html: review.mainReview,
            }}
          />
        </>
      )}
      {review.bestParts && isExpanded && (
        <>
          <Title
            h2={!isSnippet}
            h3={isSnippet}
            isSnippet={isSnippet}
            text="What were the most enjoyable or memorable parts of your trip?"
          />
          <div
            className={styles['review__left--summary-text']}
            dangerouslySetInnerHTML={{
              __html: review.bestParts,
            }}
          />
        </>
      )}
      {review.doDifferently && isExpanded && (
        <>
          <Title
            h2={!isSnippet}
            h3={isSnippet}
            isSnippet={isSnippet}
            text="If you could take your trip again, what would you do differently?"
          />
          <div
            className={styles['review__left--summary-text']}
            dangerouslySetInnerHTML={{
              __html: review.doDifferently,
            }}
          />
        </>
      )}
      {review.travelCompanyFeedback && isExpanded && (
        <div className={styles['review__left--company-comments']}>
          <div className={styles['review__left--company-comments--title-wrapper']}>
            <TravelCompanyCommentSVG />
            <Title
              h2={!isSnippet}
              h3={isSnippet}
              isSnippet={isSnippet}
              text="Travel Company Comments"
            />
          </div>
          <div
            className={styles['review__left--summary-text']}
            dangerouslySetInnerHTML={{
              __html: review.travelCompanyFeedback,
            }}
          />
        </div>
      )}
      { isExpanded && isSnippet && (
        <ExpandButton />
      )}
    </>
  );
};
ReviewText.propTypes = {
  review: PropTypes.object.isRequired,
  isSnippet: PropTypes.bool,
};
ReviewText.defaultProps = {
  isSnippet: false,
};

export default ReviewItem;
