import ZApiClient from '../../../utils/client/z-api-client';
import {
    CLEAR_TITLE_ERROR,
    SET_TITLE_ERROR,
} from './error-action-types';
import {
    setTitleT
} from "./title";

export const setTitleErrorT = (T, id, error) => ({
    type: SET_TITLE_ERROR(T),
    id,
    error,
});

export const clearTitleErrorT = (T, id) => ({
    type: CLEAR_TITLE_ERROR(T),
    id,
});

export const validateTitleT = (T, text, id) => (dispatch) => {
    if (!!text) {
        ZApiClient.getClientFromType(T).getIdsByIndexParams({title: text})
            .then((data) => {
                const hasUniqueTitle = data && data.length <= 1 && ((data[0] && data[0] === id) || !data[0]);
                return (!hasUniqueTitle) ?
                    {
                        message: 'Please enter a unique title. Page with this title:',
                        data: data.filter((upstreamId) => upstreamId !== id)
                            .map((upstreamId) => ({type: T, id: upstreamId}))
                    }
                    : undefined;
            })
            .then((validationError) => {
                dispatch(setTitleT(T, text, id));
                if (!!validationError) dispatch(setTitleErrorT(T, id, validationError));
                else dispatch(clearTitleErrorT(T, id));
            })
            .catch(console.error)
    } else {
        dispatch(setTitleT(T, text, id));
        dispatch(clearTitleErrorT(T, id));
    }
};
