/* eslint-disable max-len */
import React, { createContext } from 'react';
import { 
  mapCompanyArrayToObject,
  getCompanySortValue,
} from '../../../utils/companies';
import {
  setIsFetching,
  setAllCompanies,
  setError,
  clearSearch,
  setCompanyById,
  setCompaniesById,
  setSearchResults,
  setSearchResultsById,
  setCompanyFilters,
  setSearchTerm,
  setSortOrder,
  setPhoto,
  setDescription,
  setFirstName,
  setProfileStatus,
  setFirstNameExists,
  setDescriptionExists,
  setPhotoExists,
  setPrimaryLocations,
  setSecondaryLocations,
  setActivityLevels,
} from './actions';
import useCompaniesReducer from './use-companies-reducer';

export const CompaniesStore = createContext({});

export const CompaniesProvider = ({ children }) => {
  const [companies, dispatchCompanies] = useCompaniesReducer();

  const setAllCompanyResults = (results, offset) => {
    dispatchCompanies(setAllCompanies(results, offset));
    dispatchCompanies(setCompaniesById(mapCompanyArrayToObject(results)));
  };

  const setCompanySearchResults = (results) => {
    const {
      fullTextSearch,
      sortOrders,
    } = results;
    // Remove fuzzy character before persisting
    const term = fullTextSearch?.replace(/~/g, '');
    dispatchCompanies(setSearchTerm(term));
    dispatchCompanies(setSortOrder(getCompanySortValue(sortOrders[0])));
    dispatchCompanies(setSearchResults(results));
    dispatchCompanies(setSearchResultsById(mapCompanyArrayToObject(results.companies)));
  };

  const value = {
    ...companies,
    clearSearchResults: () => dispatchCompanies(clearSearch()),
    setAllCompanyResults: (results, offset) => setAllCompanyResults(results, offset),
    setAllCompanies: (result) => dispatchCompanies(setAllCompanies(result, '')),
    setCompanyById: (id, company) => dispatchCompanies(setCompanyById(id, company)),
    setCompanySearchResults: (results) => setCompanySearchResults(results),
    setError: (bool) => dispatchCompanies(setError(bool)),
    setIsFetching: (bool) => dispatchCompanies(setIsFetching(bool)),
    // Search, filters, and sorting
    setCompanyFilters: (map) => dispatchCompanies(setCompanyFilters(map)),
    setSearchTerm: (str) => dispatchCompanies(setSearchTerm(str)),
    setSortOrder: (str) => dispatchCompanies(setSortOrder(str)),
    setProfileStatus: (bool) => dispatchCompanies(setProfileStatus(bool)),
    setFirstNameExists: (bool) => dispatchCompanies(setFirstNameExists(bool)),
    setDescriptionExists: (bool) => dispatchCompanies(setDescriptionExists(bool)),
    setPhotoExists: (bool) => dispatchCompanies(setPhotoExists(bool)),
    setPrimaryLocations: (arr) => dispatchCompanies(setPrimaryLocations(arr)),
    setSecondaryLocations: (arr) => dispatchCompanies(setSecondaryLocations(arr)),
    setActivityLevels: (arr) => dispatchCompanies(setActivityLevels(arr)),
    // Company edits
    setPhoto: (id, photo) => dispatchCompanies(setPhoto(id, photo)),
    setDescription: (id, description) => dispatchCompanies(setDescription(id, description)),
    setFirstName: (id, name) => dispatchCompanies(setFirstName(id, name)),
  };

  return (
    <CompaniesStore.Provider value={value}>
      {children}
    </CompaniesStore.Provider>
  );
};
