import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import style from './z-draggable-grid.module.scss';

const SortableItem = SortableElement(({isGrid, value}) => (
  <div className={isGrid 
    ? style['draggable-grid__grid-item']
    : style['draggable-grid__list-item']}
  >
    {value}
  </div>
));

const SortableList = SortableContainer(({isGrid, items, disabled}) => {
  return (
    <ul className={style['draggable-grid__wrapper']}>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.key}`}
          index={index}
          isGrid={isGrid}
          value={value}
          disabled={disabled}
        />
      ))}
    </ul>
  );
});

/**
 * Children must have an accessable id prop.
 * @param {function} onDragEnd
 * @param {React Element} children
 * @param {boolean} isGrid
 */
const ZDraggableGridView = ({
  onDragEnd,
  children,
  isGrid,
  disabled,
}) => {
  return (
    <SortableList
      axis={isGrid ? 'xy' : 'y'}
      disabled={disabled}
      distance={2}
      isGrid={isGrid}
      items={children}
      onSortEnd={onDragEnd}
    />
  )
};

export default ZDraggableGridView;
