import {
  PUBLISHED_ERROR,
  PUBLISHED_REQUEST,
  PUBLISHED_STATUS_SUCCESS,
  PUBLISHED_CURRENT_SUCCESS,
  UNPUBLISHED_SUCCESS,
} from '../actions/published';

const published = (state = {
  isFetching: true,
  error: false,
}, action) => {
  const {
    currentPublication,
    entityId,
    publicationStatus,
    publishedVersionId,
  } = action;
  switch(action.type) {
    case PUBLISHED_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
      case PUBLISHED_STATUS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          error: false,
          [entityId]: {
            ...state[entityId],
            status: {
              publishedVersionId,
              publicationStatus,
            }
          },
        };
      case PUBLISHED_CURRENT_SUCCESS:
        return {
          ...state,
          isFetching: false,
          error: false,
          [entityId]: {
            ...state[entityId],
            current: currentPublication,
          },
        };
      case UNPUBLISHED_SUCCESS:
        const stateCopy = { ...state };
        delete stateCopy[entityId];
        return {
          ...stateCopy,
          isFetching: false,
          error: false,
        };
      case PUBLISHED_ERROR:
        return {
          ...state,
          isFetching: false,
          error: true,
        };
    default:
        return state;
  }
};

export default published;
