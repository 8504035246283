const Validate = {
  email(email) {
    const trimmedEmail = email.trim();
    if (trimmedEmail.indexOf('@') === -1) return false;

    const testArr = trimmedEmail.split('@');
    if (testArr.length > 2) return false;
    if (testArr[1].indexOf('.') === -1 || testArr[1].endsWith('.')) return false;
    return true;
  },
  length(str, minLength) { return str.trim().length >= minLength; },
  notEmpty(text) { return text.trim().length > 0; },
  reCaptcha(id) { return id !== null; },
  textInput(text, validationRegExp) {
    if (!text || text.trim().length < 1) return false;

    const validationPattern = RegExp(validationRegExp);
    if (validationPattern && validationPattern.test(text)) return true;
    return false;
  },
  imageUrl(str) {
    const uploadSource =  'res.cloudinary.com/zicasso/image/upload/';
    const fetchSource = 'res.cloudinary.com/zicasso/image/fetch/';
    const editSrc = '.zicasso.com/';

    if (str.includes(uploadSource) || str.includes(editSrc) || str.includes(fetchSource)) {
      const validUrlRegex =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return validUrlRegex.test(str);
    } else {
      return false;
    }
  },
};

export default Validate;
