import React from 'react';
import { connect } from 'react-redux';
import UsageReferencesContainer from './usage-references-container';
import { getUsageReferences, clearUsageReferences } from '../../state/actions/usage-references';
import { getPublicationReferences, clearPublicationReferences } from '../../state/actions/publication-references';

const UsageReferencesRedux = ({
  entityId,
  error,
  loading,
  onClearUsageReferences,
  onClearPublicationReferences,
  onFetchUsageReferences,
  onFetchPublicationReferences,
  publicationReferences,
  usageReferences,
}) => (
  <UsageReferencesContainer
    entityId={entityId}
    error={error}
    loading={loading}
    onClearUsageReferences={onClearUsageReferences}
    onClearPublicationReferences={onClearPublicationReferences}
    onFetchUsageReferences={onFetchUsageReferences}
    onFetchPublicationReferences={onFetchPublicationReferences}
    publicationReferences={publicationReferences}
    usageReferences={usageReferences}
  />
);

const mapStateToProps = (state, ownProps) => ({
  publicationReferences: state.publicationReferences[ownProps.entityId],
  usageReferences: state.usageReferences[ownProps.entityId],
  loading: state.usageReferences.isFetching,
  error: state.usageReferences.error,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchUsageReferences: (id) => dispatch(getUsageReferences(id)),
  onFetchPublicationReferences: (id) => dispatch(getPublicationReferences(id)),
  onClearUsageReferences: (id) => dispatch(clearUsageReferences(id)),
  onClearPublicationReferences: (id) => dispatch(clearPublicationReferences(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageReferencesRedux);
