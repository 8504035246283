import{
  SET_META_DATA,
  SET_META_DESCRIPTION,
  SET_META_TITLE,
  SET_NOFOLLOW,
  SET_NOINDEX,
  SET_CANONICAL,
} from '../actions/meta-tags';

const metaData = (
  state = {
    title: '',
    description: '',
    nofollow: false,
    noindex: false,
    canonical: ''
  },
  action
) => {
  switch (action.type) {
    case SET_META_DATA:
      return {
        ...action.value,
      };
    case SET_META_DESCRIPTION:
      return {
        ...state,
        description: action.value,
      };
    case SET_META_TITLE:
      return {
        ...state,
        title: action.value,
      };
    case SET_NOFOLLOW:
      return {
        ...state,
        nofollow: action.value,
      };
    case SET_NOINDEX: 
      return {
        ...state,
        noindex: action.value,
      };
    case SET_CANONICAL:
      return {
        ...state,
        canonical: action.value,
      };
    default:
      return state;
  }
};

export default metaData;
