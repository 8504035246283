import React from 'react';
import SampleItinerary from './sample-itinerary';
import ZAutoComplete from '../../z-autocomplete';
import ZDraggableGrid from '../../z-draggable-grid.js';

const CustomItineraryView = (props) => {
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Itineraries</h2>
        <p style={{paddingRight: '16px', fontSize: '18px'}}>{`Total: ${props.itineraryCount}`}</p>
      </div>
      <ZAutoComplete
        autoComplete={props.autoComplete}
        onSelect={props.autoCompleteOnSelect}
        returnsObject={true}
        label='Add an Itinerary by Name'
        style={{marginBottom: '14px'}}
        error={props.errors.itineraries ? props.errors.itineraries : null}
      />
      {props.itineraries && props.itineraries.length !== 0 &&
        <ZDraggableGrid onDragEnd={props.onDragEnd} disabled={props.readOnly}>
          {props.itineraries.length > 0 && props.itineraries.map((ele, i) => (
            <SampleItinerary 
              key={`${ele.id}-${i}`}
              itinerary={{...ele}} 
              itinerariesLength={props.itineraries.length}
              index={i} 
              onUpdate={props.onUpdate}
              onChangePosition={props.onChangePosition}
              inputKey={`${ele.id}-${i}`}
              addDay={props.addDay}
              removeItinerary={props.removeItinerary}
              onClickPreview={props.onClickPreview(ele.id)}
              onClickEdit={props.onClickEdit(ele.id)}
            />
          ))}
        </ZDraggableGrid>
      }
    </>
  )
}

export default CustomItineraryView;
