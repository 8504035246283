import React, { useMemo } from 'react';
import ZInputRadio from '../z-input-radio';
import { capitalizeAll } from '../../utils/utils';
import style from './z-radio-group.module.scss';

const FilterDisplayedView = ({
  children,
  className,
  name,
  onChange,
  options,
  value,
}) => {
  const formattedOptions = useMemo(() => (
    options.map((option) => {
      if (typeof option === 'string') {
        return ({
          label: capitalizeAll(option),
          value: option,
        });
      }
      return option;
    })
  ), [options]);

  return (
    <div className={`${style['radio-group']} ${className}`}>
      {children}
      {formattedOptions.map((option) => (
        <ZInputRadio
          checked={value === option.value}
          className={style['radio-group__radio']}
          id={`${name}-${option.value}`}
          key={`${name}-${option.value}`}
          label={option.label}
          name={name}
          onChange={onChange}
          valid
          value={option.value}
        />
      ))}
    </div>
  );
};

export default FilterDisplayedView;
