import React from 'react';
import ZModal from '../../z-modal';
import style from '../../shared/styles/pages-list.module.scss';

const ErrorModal = ({id, onCreateNew}) => (
  <ZModal
    open
    style={{padding: '14px', display: 'flex', flexDirection: 'column'}}
  >
    <div className={style['modal__header']} style={{textAlign: 'center'}}>
      <h1>Guide {id} Not Found!</h1>
      <p>This is likely because the guide doesn't exist</p>
    </div>
    <div className={style['modal__actions']}>
      <button type="button" className={style['pages-list__button']} onClick={onCreateNew}>Create a Guide</button>
    </div>
  </ZModal>
);

export default ErrorModal;
