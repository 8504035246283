import React from 'react';
import ZSpinner from '../z-spinner';
import ZTable from '../z-table';
import ZPublicationsRow from './z-publications-row';
import styles from './z-publications.module.scss';
import sharedStyles from '../shared/styles/shared.module.scss';

const ZPublicationsView = ({
  items,
  loading,
  offset,
  onClickLoadMore,
}) => (
  <div className={styles.publications}>
    <h2 className={styles.title}>Publications</h2>
    {!!items && (
      <ZTable headers={[ 'published version id', 'user id', 'comment', 'canonical alias', 'significant', 'published', 'status', 'created at',]} rowWrapperStyle={{height: '70vh'}}>
        {items.map((item, i) => (
          <ZPublicationsRow
            data={item}
            index={i}
            key={item.publicationId}
            legend={[ 'entityUpdateId', 'userId', 'comment', 'canonicalAlias', 'significant', 'publish', 'status', 'createdAt',]}
          />
        ))}     
      </ZTable>
    )}
    {(!loading && (!items || items.length === 0)) && (
      <p>No publications yet.</p>
    )}
    {loading && (
      <div className={styles.centered}>
        <ZSpinner />
      </div>
    )}
    <div className={styles.centered}>
      {(!loading && !!offset) && (
        <button className={sharedStyles.button} onClick={onClickLoadMore} type="button">Load More</button>
      )}
    </div>
  </div>
);

export default ZPublicationsView;
