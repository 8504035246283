import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';
import store, { history } from './state/store';
import './assets/sass/reset.scss';
import './assets/sass/fonts.scss';
import App from './components/app';
import { unregister } from './serviceWorker';
import config from './config';

ReactDOM.render(
  <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </GoogleOAuthProvider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
