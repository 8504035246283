import React from 'react';
import PropTypes from 'prop-types';
import Stars from '../stars';
import styles from './average-large.module.scss';

const AverageVerticalView = ({
  averageRating,
  reviewCount,
}) => (
  <div className={styles['average']}>
    <p className={styles['average__rating']}>
      {(averageRating / 20).toFixed(1)}
    </p>
    <div className={styles['average__text-wrapper']}>
      <Stars rating={averageRating} className={styles['average__stars']} />
      <p className={styles['average__review-count']}>
        based on&nbsp;
        {reviewCount}
        &nbsp;reviews
      </p>
    </div>
  </div>
);
AverageVerticalView.propTypes = {
  averageRating: PropTypes.number,
  reviewCount: PropTypes.number,
};
AverageVerticalView.defaultProps = {
  averageRating: 4.9,
  reviewCount: 1000,
};

export default AverageVerticalView;
