import React from 'react';
import PropTypes from 'prop-types';
import styles from './z-input-subtext.module.scss';

const ZInputSubtext = ({ style, children, className }) => (
  <p className={`${styles['subtext']} ${className}`} style={style}>
    {children}
  </p>
);
ZInputSubtext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};
ZInputSubtext.defaultProps = {
  className: '',
  style: null,
};

export default ZInputSubtext;
