import {
  SET_ERRORS 
} from '../actions/errors';

const error = (
  state = {
    isValid: true,
  },
  action
) => {
  switch(action.type){
    case SET_ERRORS:
      return{
        ...state,
        ...action.errors,
      };
    default: 
      return state
  }
};

const errors = (
  state = {},
  action 
) => {
  switch(action.type){
    case SET_ERRORS:
      return {
        ...state,
        [action.id] : error(state[action.id], action)
      };
    default:
      return state
  }
};

export default errors
