import React, {useEffect, useRef} from 'react';
import TravelCompanyView from './travel-company-view';
import Config from '../../../../config';

const oryxUrl = Config.ORYX_ENDPOINT;

const TravelCompanyContainer = ({
  company,
  getCompanies,
  travelCompanies,
  onSelect,
  onDelete,
}) => {
  const travelCompaniesRef = useRef({});

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(()=> {
    travelCompaniesRef.current = travelCompanies;
  }, [travelCompanies])

  const searchCompanies = (text) => {
    const tcMap = travelCompaniesRef.current;
    text = text.toLowerCase();
    const results = {};
    Object.keys(tcMap).forEach(ele => {
      if (tcMap[ele].toLowerCase().includes(text)) {
        results[ele] = tcMap[ele];
      }
    });
    return new Promise((resolve) => resolve(results));
  };

  const onSelectCompany = (data) => {
    const [newCompanyId] = Object.keys(data);
    const travelCompany = {
      id: newCompanyId,
      name: data[newCompanyId]
    }
    onSelect(travelCompany);
  }

  return (
    <TravelCompanyView
      tcLink={`${oryxUrl}/companies/${company.id}`}
      tcName={company.name}
      autoComplete={searchCompanies}
      onSelect={onSelectCompany}
      onDelete={onDelete}
    />
  )
}

export default TravelCompanyContainer;
