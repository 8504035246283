export const DISABLE_SAVE = 'DISABLE_SAVE';
export const ENABLE_SAVE = 'ENABLE_SAVE';
export const SET_DEPARTURE_NOTES = 'SET_DEPARTURE_NOTES';
export const ADD_PLACE_VISITED = 'ADD_PLACE_VISITED';
export const SET_PLACES_VISITED = 'SET_PLACES_VISITED';
export const SET_HIGHLIGHTS = 'SET_HIGHLIGHTS';
export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const SET_PRICE = 'SET_PRICE';
export const SET_PRICE_COMMENTS = 'SET_PRICE_COMMENTS';
export const SET_INCLUDED_NOTES = 'SET_INCLUDED_NOTES';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANY = 'SET_COMPANY';
export const UNSET_COMPANY = 'UNSET_COMPANY';
export const SET_DAYS = 'SET_DAYS';
export const REMOVE_DAY = 'REMOVE_DAY';
export const ADD_DAY = 'ADD_DAY';
export const SET_SHOW_PRICE = 'ITIN_SET_SHOW_PRICE';
