import React, { useEffect, useState } from 'react';
import TeamMemberEditorView from './team-member-editor-view';
import { createImage } from '../../../utils/image/image-utils';

const TeamMemberEditorContainer = ({
  closeModal,
  id,
  teamMember: {
    displayed,
    error,
    isSaving,
    description,
    title,
    image,
    name,
  },
  onClickEdit,
  saveTeamMember,
  setDisplayed,
  setDescription,
  setName,
  setTitle,
  setPhoto,
  deleteTeamMember,
  fetchTeamMembers,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [deleteError, setDeleteError] = useState(false);

  useEffect(() => {
    setDisabled(!name || !title || !description);
  }, [name, title, description, image]);

  const handleChangeDisplayed = (e) => {
    setDisplayed(e.target.checked);
  };

  const handleClickSave = () => {
    saveTeamMember();
  };

  const onRemovePhoto = () => {
    setPhoto({});
  }

  const onCreatePhoto = (teamMemberId, photo) => {
    createImage(photo)
      .then((res) => {
        setPhoto(res);
      })
      .catch((e) => console.error('error creating image: ', e));
  }

  const onChangePhoto = (photo) => {
    setPhoto(photo);
  }

  const handleClickDelete = () => {
    if (window.confirm('Are you sure you want to delete this team member?')) {
      deleteTeamMember()
        .then((res) => {
          if (res) {
            setDeleteError(true);
          } else {
            setDeleteError(false);
            closeModal();
            fetchTeamMembers();
          }
        });
    }
  }

  return (
    <TeamMemberEditorView
      closeModal={closeModal}
      saveDisabled={disabled}
      displayed={displayed}
      error={error}
      id={id}
      isSaving={isSaving}
      onChangeDisplayed={handleChangeDisplayed}
      onClickEdit={onClickEdit}
      onClickSave={handleClickSave}
      setDescription={setDescription}
      setTitle={setTitle}
      setName={setName}
      description={description}
      title={title}
      image={image}
      onRemovePhoto={onRemovePhoto}
      onCreatePhoto={onCreatePhoto}
      onChangePhoto={onChangePhoto}
      onClickDelete={handleClickDelete}
      name={name}
      deleteError={deleteError}
    />
  );
};

export default TeamMemberEditorContainer;
