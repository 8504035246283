import React, { useState } from 'react';
import styles from './oryx-tooltip.module.scss';

const defaultProps = {
  contentClassName: '',
};

const TooltipView = ({ children, contentClassName }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={styles.tooltip}>
      <div
        className={Array.isArray(children)
          ? styles.tooltip__iconNoBackground
          : styles.tooltip__icon}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        { /* render icon if provided */ }
        {Array.isArray(children) && children?.length > 1 && children[1]}
      </div>
      {showTooltip && (
        <div className={`${styles.tooltip__content} ${contentClassName}`}>
          {Array.isArray(children) ? children[0] : children}
        </div>
      )}
    </div>
  );
};

TooltipView.defaultProps = defaultProps;

export default TooltipView;
