import React from 'react';
import DatesView from './dates-view';

const DatesContainer = ({
  onUpdateDeparture,
  departureNotesError,
  departureNotes,
}) => {

  const updateDeparture = (val) => {
    if(val !== '<br>'){
      onUpdateDeparture(val);
    }
  }

  return (
    <DatesView
      onUpdateDeparture={updateDeparture}
      departureNotesError={departureNotesError}
      departureNotes={departureNotes}
    />
  )
}

export default DatesContainer;
