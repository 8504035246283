import {
  SECTION_ACTION_NAMES,
} from '../actions/shared/section-action-types';
import {
  PHOTO_ACTION_NAMES
} from "../actions/shared/photo-action-types";

const addAtIndex = (arr = [], ele, i, replace = false) => {
  if(!i){
    return [...arr, ele];
  } else if (i > arr.length) {
    return [...arr, ele];
  } else {
    let res =  [...arr];
    res.splice(i, replace ? 1: 0, ele);
    return res;
  }
};

const updateLinkById = (arr, id, label, text) => {
  return arr.map((ele)=> {
    if(ele.id === id){
      ele[label] = text;
      return {
        ...ele,
      };
    } else {
      return {...ele}
    }
  });
};

const sectionsT = (T) => (
  state = {},
  action 
) => {
  switch(action.type){
    case SECTION_ACTION_NAMES[T.toUpperCase()].RECEIVED_FOOTER_SECTIONS:
    case SECTION_ACTION_NAMES[T.toUpperCase()].RECEIVED_SECTIONS:
      return {
        ...state,
        ...action.sections,
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].ADD_SECTION:
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_SECTION_TITLE:
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_SECTION_TEXT:
    case SECTION_ACTION_NAMES[T.toUpperCase()].ADD_FOOTER_SECTION:
    case SECTION_ACTION_NAMES[T.toUpperCase()].ADD_SECTION_LINK:
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_SECTION_LINKS:
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_LINK_TITLE:
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_LINK_URI:
    case SECTION_ACTION_NAMES[T.toUpperCase()].REMOVE_SECTION_LINK:
    case SECTION_ACTION_NAMES[T.toUpperCase()].UPDATE_SECTION:
      return {
        ...state,
        [action.id] : sectionT(T, state[action.id], action),
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_SECTION_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].REMOVE_SECTION_PHOTO:
      return {
        ...state,
        [action.sectionId] : sectionT(T, state[action.sectionId], action),
      };
    default: {
      return state;
    }
  }
};

const sectionT = (
  T,
  state = {
    title: null,
    text: null
  },
  action 
) => {
  switch(action.type){
    case SECTION_ACTION_NAMES[T.toUpperCase()].ADD_SECTION:
    case SECTION_ACTION_NAMES[T.toUpperCase()].ADD_FOOTER_SECTION:
      const { id, entityId, data } = action;
      return {
        ...state,
        ...data,
        id,
        entityId,
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_SECTION_TITLE:
      return {
        ...state,
        title: action.value
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_SECTION_TEXT:
      return {
        ...state,
        text: action.value,
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_SECTION_PHOTO:
      return {
        ...state,
        photoId : action.photo.id,
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].REMOVE_SECTION_PHOTO:
      let { photoId, image, ...rest} = state;
      return {
        ...rest,
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].ADD_SECTION_LINK:
      return {
        ...state,
        links : addAtIndex(state.links, action.link),
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_SECTION_LINKS:
      return {
        ...state,
        links : [...action.list],
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].REMOVE_SECTION_LINK:
      return {
        ...state,
        links : state.links.filter(ele=> ele.id !== action.linkId),
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_LINK_TITLE:
      return {
        ...state,
        links: updateLinkById(state.links, action.linkId, 'title', action.value),
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].SET_LINK_URI:
      return {
        ...state,
        links: updateLinkById(state.links, action.linkId, 'uri', action.value),
      };
    case SECTION_ACTION_NAMES[T.toUpperCase()].UPDATE_SECTION:
      return {
        ...state,
        ...action.section,
      };
    default: 
      return state;
  }
};

export default sectionsT;
