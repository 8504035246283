import React from 'react';
import PropTypes from 'prop-types';

const h2 = ({
  children, className, style,
}) => {
  const breakpoint = 'desktop';
  const presetStyle = {
    allSizes: {
      fontWeight: '300',
      textAlign: 'center',
      color: '#343434',
      marginTop: 0,
    },
    mobile: { fontSize: '28px', lineHeight: '30px' },
    tablet: { fontSize: '36px', lineHeight: '38px' },
    desktop: { fontSize: '36px', lineHeight: '38px' },
  };
  return (
    <h2 className={className} style={{ ...presetStyle.allSizes, ...presetStyle[breakpoint], ...style }}>{children}</h2>
  );
};

h2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  breakpoint: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};
h2.defaultProps = {
  children: null,
  breakpoint: 'mobile',
  className: null,
  style: {},
};

export default h2;
