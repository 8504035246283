import { connect } from 'react-redux';
import ParentContainer from './parent-container';
import {
  fetchParentLandingPageSnippet,
} from '../../../state/actions/company-category-editor';
import {deleteBreadcrumbT, setBreadcrumbT} from "../../../state/actions/shared/breadcrumbs";

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    breadcrumb: state.companyCategories.editor[id]?.breadcrumb,
    landingPageSnippet: state.companyCategories.editor[id].landingPageSnippet,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { id } = ownProps;
  return {
    deleteBreadcrumb: () => dispatch(deleteBreadcrumbT('company_category', id)),
    fetchParentLandingPageSnippet: (landingPageId) => dispatch(fetchParentLandingPageSnippet(landingPageId, id)),
    setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumbT('company_category', breadcrumb, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentContainer);
