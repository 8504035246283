import {
  CLEAR_USAGE,
  USAGE_REQUEST,
  USAGE_SUCCESS,
  USAGE_ERROR,
} from '../actions/usage-references';

const usageReferences = (state = {
  isFetching: false,
  error: false,
}, action) => {
  switch(action.type) {
    case USAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
      case USAGE_SUCCESS:
        return {
          ...state,
          isFetching: false,
          error: false,
          [action.entityId]: action.references,
        };
      case USAGE_ERROR:
        return {
          ...state,
          isFetching: false,
          error: true,
        };
      case CLEAR_USAGE:
        return {
          [action.entityId]: null,
        };
    default:
        return state;
  }
};

export default usageReferences;

