import { openLoginModal } from './users';
import { fetchEntityPublications } from './publications';
import ZApiClient from '../../utils/client/z-api-client';

export const PUBLISHED_REQUEST = 'PUBLISHED_REQUEST';
export const publishRequest = () => ({
  type: PUBLISHED_REQUEST,
});

export const PUBLISHED_CURRENT_SUCCESS = 'PUBLISHED_CURRENT_SUCCESS';
export const publishCurrentSuccess = (entityId, currentPublication) => ({
  type: PUBLISHED_CURRENT_SUCCESS,
  entityId,
  currentPublication,
});

export const PUBLISHED_STATUS_SUCCESS = 'PUBLISHED_STATUS_SUCCESS';
export const publishStatusSuccess = (entityId, publicationStatus, publishedVersionId) => ({
  type: PUBLISHED_STATUS_SUCCESS,
  entityId,
  publicationStatus,
  publishedVersionId,
});

export const UNPUBLISHED_SUCCESS = 'UNPUBLISHED_SUCCESS';
export const unpublishSuccess = (entityId) => ({
  type: UNPUBLISHED_SUCCESS,
  entityId,
});

export const PUBLISHED_ERROR = 'PUBLISHED_ERROR';
export const publishError = () => ({
  type: PUBLISHED_ERROR,
});

export const getPublishedStatus = (publicationOrPublications, entityId, entityUpdateId) => (dispatch) => {
  let publicationStatus;
  let publication = publicationOrPublications;

  if (Array.isArray(publicationOrPublications)) {
    publication = publicationOrPublications[0]
  }

  // Is unpublished only if it's never, ever been published?
  if (!publication || !publication.publish) {
    publicationStatus = "Unpublished";
  } else if (publication.entityUpdateId === entityUpdateId) {
    //  The published version is the most current version
    publicationStatus = "Published";
  } else {
    //  The published version is not the most current version
    publicationStatus = "Draft";
  }

  dispatch(
    publishStatusSuccess(
      entityId,
      publicationStatus,
      publication ? publication.entityUpdateId : null
    )
  );
}

export const fetchPublication = (entityId) => async (dispatch) => {
  dispatch(publishRequest());

  try {
    const currentPublication = await ZApiClient
      .getPublicationsClient()
      .readPublications({
        current: true,
        entity: entityId,
      });
    if (currentPublication.httpStatus >= 400 && currentPublication.httpStatus !== 404) throw new Error();

    if (currentPublication.length > 0) {
      dispatch(publishCurrentSuccess(entityId, currentPublication[0]));
    } else {
      //  There are no publications
      dispatch(publishCurrentSuccess(entityId, null));
    }
  } catch {
    dispatch(publishError());
  }
};

export const publishEntityVersion = ({
  entityId,
  entityType,
  entityUpdateId,
  title,
  comment,
  significant
}) => (dispatch) => {
  dispatch(publishRequest());

  ZApiClient
  .getPublicationsClient()
  .createPublication({
    entityId,
    entityUpdateId,
    entityType,
    title,
    comment,
    significant,
    publish: true,
  })
    .then((res) => {
      if (res.httpStatus === 401) {
        dispatch(openLoginModal());
      } else if ((res && res.entityId !== entityId) || res.httpStatus >= 400) {
        throw new Error();
      } else {
        dispatch(publishCurrentSuccess(entityId, res));
      }
    })
    .catch(() => dispatch(publishError()))
    .finally(() => {
      dispatch(fetchEntityPublications(entityId));
    });
};

export const unpublishEntity = ({
  entityId,
  entityType,
  entityUpdateId,
  title,
  comment,
}) => (dispatch) => {
  dispatch(publishRequest());

  ZApiClient
  .getPublicationsClient()
  .createPublication({
    entityId,
    entityUpdateId,
    entityType,
    title,
    comment,
    publish: false,
  })
    .then((res) => {
      if (res.httpStatus === 401) {
        dispatch(openLoginModal());
      } else if ((res && res.entityId !== entityId) || res.httpStatus >= 400) {
        throw new Error();
      } else {
        dispatch(unpublishSuccess(entityId));
      }
    })
    .catch(() => dispatch(publishError()))
    .finally(() => {
      dispatch(fetchEntityPublications(entityId));
    });
};
