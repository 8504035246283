import React from 'react';
import styles from './utils.module.scss';

const defaultProps = {
  className: '',
  optional: false,
};

const OryxInputLabel = ({
  children,
  className,
  optional,
  inputName,
}) => (
  <label
    className={`${styles.label} ${className}`}
    htmlFor={inputName}
  >
    {children}
    {optional && <span className={styles['label-italic']}> (optional)</span>}
  </label>
);

OryxInputLabel.defaultProps = defaultProps;

const wrapperDefaultProps = {
  className: '',
  style: {},
};

const OryxInputWrapper = ({
  children,
  className,
  style,
}) => (
  <div className={`${styles['input-wrapper']} ${className}`} style={style}>
    {children}
  </div>
);

OryxInputWrapper.defaultProps = wrapperDefaultProps;

const errorDefaultProps = {
  className: '',
  style: {},
};

const OryxInputError = ({
  children,
  className,
  style,
}) => (
  <p className={`${styles.error} ${className}`} style={style}>
    {children}
  </p>
);

OryxInputError.defaultProps = errorDefaultProps;

export { OryxInputLabel, OryxInputWrapper, OryxInputError };
