import { combineReducers } from 'redux'
import sectionsT from './sections';
import photosT from './photos';
import metatags from './meta-data';
import { addAtIndex, addIfMissing } from '../utils';
import {
  RECEIVED_GUIDE,
  REQUEST_GUIDE,
  REQUEST_GUIDE_FAILED,
  REQUEST_NEW_GUIDE,
  GUIDE_SAVED,
  GUIDE_SAVE_REQUESTED,
} from '../actions/shared/similar-action-types';
import{
  SET_META_DATA,
  SET_META_DESCRIPTION,
  SET_META_TITLE,
  SET_NOFOLLOW,
  SET_NOINDEX,
  SET_CANONICAL,
} from '../actions/meta-tags';
import {
  PHOTO_ACTION_NAMES,
} from '../actions/shared/photo-action-types';
import {
  BREADCRUMB_ACTION_NAMES
} from "../actions/shared/breadcrumb-action-types";
import {
  LOCATION_ACTION_NAMES
} from '../actions/shared/location-action-types';
import {
  SECTION_ACTION_NAMES,
} from '../actions/shared/section-action-types';
import {
  TITLE_ACTION_NAMES
} from '../actions/shared/title-action-types';
import {
  ESSENTIAL_INFO_ACTION_NAMES
} from '../actions/shared/essential-info-action-types';
import {
  HELPFUL_INFO_ACTION_NAMES
} from '../actions/shared/helpful-info-action-types';

const guide = (
  state = {
    title: null,
    primaryLinksTitle: '',
    helpfulContentTitle: '',
    summary: '',
    sectionIds: [],
    footerSectionIds: [],
  },
  action
) => {
  switch (action.type) {
    case RECEIVED_GUIDE:
      return {
        ...state,
        ...action.guide,
      };
    case PHOTO_ACTION_NAMES.GUIDE.SET_HEADER_PHOTO:
      return {
        ...state,
        headerPhotoId: action.photo.id,
      };
    case PHOTO_ACTION_NAMES.GUIDE.REMOVE_HEADER_PHOTO:
      delete state.headerPhotoId;
      return {
        ...state,
      };
    case TITLE_ACTION_NAMES.GUIDE.SET_TITLE:
      return {
        ...state,
        title: action.value,
      };
    case TITLE_ACTION_NAMES.GUIDE.SET_SUMMARY:
      return {
        ...state,
        summary: action.value,
      };
    case ESSENTIAL_INFO_ACTION_NAMES.GUIDE.SET_ESSENTIAL_INFO:
      return {
        ...state,
        primaryLinksTitle: action.value,
      };
      
    case HELPFUL_INFO_ACTION_NAMES.GUIDE.SET_HELPFUL_INFO:
      return {
        ...state,
        helpfulContentTitle: action.value,
      };
    case SECTION_ACTION_NAMES.GUIDE.ADD_SECTION:
      return {
        ...state,
        sectionIds: addAtIndex(state.sectionIds, action.id, action.index),
      };
    case SECTION_ACTION_NAMES.GUIDE.REMOVE_SECTION:
      return {
        ...state,
        sectionIds: state.sectionIds.filter(ele => ele !== action.id),
      };
    case SECTION_ACTION_NAMES.GUIDE.REORDER_SECTIONS:
      return {
        ...state,
        sectionIds : [...action.sectionIds],
      };
    case LOCATION_ACTION_NAMES.GUIDE.SET_LOCATIONS:
      return {
        ...state,
        locations: [...action.list],
      };
    case LOCATION_ACTION_NAMES.GUIDE.ADD_LOCATION:
      return {
        ...state,
        locations: addIfMissing(action.value, state.locations),
      };
    case SET_META_DATA:
    case SET_META_TITLE:
    case SET_META_DESCRIPTION:
    case SET_NOFOLLOW:
    case SET_NOINDEX:
    case SET_CANONICAL:
      return {
        ...state,
        metatags: metatags(state.metatags, action)
      };
    case BREADCRUMB_ACTION_NAMES.GUIDE.ADD_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.data
      };
    case BREADCRUMB_ACTION_NAMES.GUIDE.SET_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle : action.title,
      };
    case BREADCRUMB_ACTION_NAMES.GUIDE.DELETE_BREADCRUMBS:
      return {
        ...state,
        breadcrumb: null
      };
    case BREADCRUMB_ACTION_NAMES.GUIDE.DELETE_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle : null,
      };
    case SECTION_ACTION_NAMES.GUIDE.ADD_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds: addAtIndex(state.footerSectionIds, action.id, action.index)
      };
    case SECTION_ACTION_NAMES.GUIDE.REMOVE_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds : state.footerSectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.GUIDE.REORDER_FOOTER_SECTIONS:
      return {
        ...state,
        footerSectionIds : [...action.list],
      };
    default:
      return state;
  }
};

const editor = (
  state = {
    isFetching: true,
    isSaving: false,
    saved: true,
    notFound: false,
  },
  action ) => {
    switch(action.type){
      case REQUEST_GUIDE:
        return {
          ...state,
          isFetching: true,
        };
      case REQUEST_GUIDE_FAILED:
        return {
          ...state,
          isFetching: false,
          notFound: true,
        };
      case REQUEST_NEW_GUIDE:
        return{
          ...state,
          isFetching: true,
          saved: false,
        };
      case GUIDE_SAVE_REQUESTED:
        return {
          ...state,
          isSaving: true,
          saved: false,
        };
      case GUIDE_SAVED:
        return {
          ...state,
          isSaving: false,
          saved: true,
        };
      case LOCATION_ACTION_NAMES.GUIDE.SET_COUNTRY_LIST:
        return {
          ...state,
          countries: action.list
        };
      case RECEIVED_GUIDE:
        return{
          ...state,
          isFetching: false,
          notFound: false,
          lastUpdate: action.receivedAt,
          [action.id] : guide({
            ...state[action.id],
            ...action.guide
          }, action),
        };
      case TITLE_ACTION_NAMES.GUIDE.SET_TITLE:
      case TITLE_ACTION_NAMES.GUIDE.SET_SUMMARY:
      case ESSENTIAL_INFO_ACTION_NAMES.GUIDE.SET_ESSENTIAL_INFO:
      case HELPFUL_INFO_ACTION_NAMES.GUIDE.SET_HELPFUL_INFO:
      case LOCATION_ACTION_NAMES.GUIDE.SET_LOCATIONS:
      case LOCATION_ACTION_NAMES.GUIDE.ADD_LOCATION:
      case SET_META_DATA:
      case SET_META_TITLE:
      case SET_META_DESCRIPTION:
      case SET_NOFOLLOW:
      case SET_NOINDEX:
      case BREADCRUMB_ACTION_NAMES.GUIDE.DELETE_BREADCRUMBS:
      case BREADCRUMB_ACTION_NAMES.GUIDE.ADD_BREADCRUMB:
      case BREADCRUMB_ACTION_NAMES.GUIDE.DELETE_BREADCRUMB_TITLE:
      case BREADCRUMB_ACTION_NAMES.GUIDE.SET_BREADCRUMB_TITLE:
        return {
          ...state,
          saved: false,
          [action.id] : guide(state[action.id], action),
        };
      case SECTION_ACTION_NAMES.GUIDE.ADD_SECTION:
      case SECTION_ACTION_NAMES.GUIDE.REMOVE_SECTION:
      case SECTION_ACTION_NAMES.GUIDE.ADD_FOOTER_SECTION:
      case SECTION_ACTION_NAMES.GUIDE.REMOVE_FOOTER_SECTION:
      case SECTION_ACTION_NAMES.GUIDE.REORDER_SECTIONS:
      case SECTION_ACTION_NAMES.GUIDE.REORDER_FOOTER_SECTIONS:
      case PHOTO_ACTION_NAMES.GUIDE.SET_HEADER_PHOTO:
      case PHOTO_ACTION_NAMES.GUIDE.REMOVE_HEADER_PHOTO:
        return {
          ...state,
          saved: false,
          [action.entityId] : guide(state[action.entityId], action)
        };
      default:
        return state;
    }
};

const guideEditor = combineReducers({
  editor,
  sections: sectionsT('guide'),
  photos: photosT('guide'),
});
export default guideEditor;
