import React from 'react';

const OryxSelectOption = ({ children, value }) => (
  <option
    disabled={!value}
    value={value}
  >
    {children}
  </option>
);

export default OryxSelectOption;
