import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import config from '../../../config';
import ZImage from '../../z-image';

const PLACEHOLDER_URL = config.IMAGES_ENDPOINT;

const LightboxItem = memo(({
  image,
  index,
  className,
  setIndex,
  zoom,
}) => {
  const [error, setError] = useState(false);

  return (
    <div
      className={className}
      onClick={() => setIndex(index)}
      onKeyDown={(e) => e.code === 'Enter' && setIndex(index)}
      role="button"
      tabIndex="0"
      style={{ transform: zoom ? `scale(${zoom})` : 'scale(1)' }}
    >
      <ZImage
        alt={image && image.alt ? image.alt : null}
        cloudinarySrc={error ? PLACEHOLDER_URL : image.uri}
        crop="fill"
        gravity={image && image.gravity ? image.gravity : 'custom'}
        onError={() => setError(true)}
        title={image && image.title ? image.title : null}
      />
    </div>
  );
});
LightboxItem.propTypes = {
  className: PropTypes.string.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    gravity: PropTypes.string,
    title: PropTypes.string,
    uri: PropTypes.string.isRequired,
  }),
  index: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
  zoom: PropTypes.number,
};
LightboxItem.defaultProps = {
  image: null,
  zoom: 1,
};
LightboxItem.displayName = 'LightboxItem';

export default LightboxItem;
