import React from 'react';
import ZInput from '../../../z-input';
import ZTextArea from '../../../z-text-area';
import ZChips from '../../../z-chips';
import ZAutoComplete from '../../../z-autocomplete';
import ZAddButton from '../../../z-button-add';
import style from './day.module.scss';
import { decodeHTMLEntities } from '../../../../utils/window';
import ZDeleteButton from '../../../z-button-delete';

const DayView = ({
  id,
  showConfirmation,
  dayNumber,
  duration,
  onDurationChange,
  autoComplete,
  locationSelect,
  onTitleChange,
  focusTitle,
  onDescriptionChange,
  description,
  title,
  toggleConfirmation,
  whatsIncluded,
  onWhatsIncludedChange,
  meals,
  onMealsChange,
  locations,
  onChipChange,
  onDeleteClick,
  onAddClick,
}) => {
  return (
    <div className={`${style['day']}`}>
      {!showConfirmation && 
        <>
          <div style={{width: '95%', maxWidth: '95%'}}>
            <div className={style['day-number']}>
              <p>Day Number:</p>
              <p>{dayNumber}</p>
            </div>
            <div className={style['row']}>
              <div className={`${style['duration-wrapper']}`}>
                <label>Duration</label>
                {/* {(!duration && props.errors.duration) && 
                  <p className={sharedStyle['duration']}>{props.errors.duration.message}</p>
                } */}
                <select 
                  style={{marginRight: '14px', width: '180px', maxWidth:'180px', flex: '1 1 auto'}} 
                  label='Duration' 
                  onChange={onDurationChange} 
                  value={duration}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </select>
              </div>
              <ZAutoComplete 
                label='Add a Location' 
                autoComplete={autoComplete} 
                onSelect={locationSelect} 
                style={{width:'200px'}}
                returnsObject={true} 
                inputKey={`${id}__dayLocation`}
              />
              { (locations && locations.length > 0) &&
                <ZChips 
                  data={locations.map((ele)=>{return{...ele, label: ele.name}})}  
                  onChange={onChipChange} 
                  style={{marginLeft: '14px'}} 
                />
              }
            </div>
            <ZInput 
              style={{marginBottom: '28px'}} 
              label='Title' 
              className='day-title' 
              onChange={onTitleChange} 
              focus={focusTitle} 
              text={decodeHTMLEntities(title)} 
              // error={!title && props.errors.title ? props.errors.title : undefined}
              inputKey={`${id}__title`}/>
            <ZTextArea 
              allowedFormats={['ITALICS', 'LINKS']} 
              label="Description" 
              onUpdate={onDescriptionChange} 
              value={description} 
              // error={!description && props.errors.description ? props.errors.description : undefined}
              inputKey={`${id}__description`}></ZTextArea>
            <div className={style['whats-included']}>
              <p>{"What's Included:"}</p>
              <div className={`${style['checkbox-wrapper']}`}>
                {Object.keys(whatsIncluded).map(key => {
                  return (
                    <div 
                      className={`${style['checkbox-item']}`} 
                      key={`${id}-${key}`}
                    >
                      <input 
                        type='checkbox' 
                        value={key} 
                        checked={whatsIncluded[key]} 
                        id={`${id}-${key}`} 
                        onChange={onWhatsIncludedChange}
                      />
                      <label 
                        htmlFor={`${id}-${key}`}
                      >
                        {key}
                      </label>
                    </div>
                  )
                })}
                </div>
                <p>Meals:</p>
                <div className={`${style['checkbox-wrapper']}`}>
                {Object.keys(meals).map(key => {
                  return (
                    <div 
                      className={`${style['checkbox-item']}`} 
                      key={`${id}-${key}`}
                    >
                      <input 
                        type='checkbox'
                        value={key} 
                        checked={meals[key]} 
                        id={`${id}-${key}`} 
                        onChange={onMealsChange}
                      />  
                      <label 
                        htmlFor={`${id}-${key}`}
                      >
                        {key.charAt(0).toUpperCase() + key.substring(1)}
                      </label>
                    </div>
                  )
                })}
                </div>
                {/* {(props.otherIncluded && props.otherIncluded.length > 0) && 
                  <>
                  <p>Other:</p>
                  <ZChips data={props.otherIncluded.map(ele=>{return{label: ele}})} onChange={props.onChipChange('included')} style={{marginLeft: '14px'}} />
                  </>
                } */}
            </div>
          </div>
          <div 
            style={{
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              width: '5%', 
              maxWidth: '5%'
            }}
          >
            <ZDeleteButton onClick={toggleConfirmation} />
            <ZAddButton onClick={onAddClick} />
          </div>
        </>
      }
      {showConfirmation && 
        <div>
          <h3 className={style['confirmation']}>Are you sure?</h3>
          <button 
            type="button" 
            onClick={onDeleteClick} 
            className={style['confirmation__button']}
          >
            Delete
          </button>
          <button 
            type="button" 
            onClick={toggleConfirmation} 
            className={style['confirmation__button']}
          >
            Cancel
          </button>
        </div>
      }
    </div>
  )
} 

export default DayView;
