import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import reducers from './reducers';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

const initialState = {};
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
];

if (process.env.REACT_APP_STAGE !== 'prod') {
  middleware.push(createLogger({ collapsed: true, diff: true }));
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

export default store
