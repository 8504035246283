import React from 'react';
import { connect } from 'react-redux';
import FooterLinksView from './footer-links-view';
import {reorderFooterSectionsT} from "../../../../state/actions/shared/section";

const mapSectionIdsToSection = (ids, sections) => {
  if(!ids){
    return [];
  } else {
    return ids.map((ele) => {
      const section = sections[ele];
      return section;
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const currentEntities = state.companyCategories;
  const {sections} = currentEntities;
  const {editor} = currentEntities;
  const currentEntity = editor[id]
  const { footerSectionIds } = currentEntity;
  return {
    sections: mapSectionIdsToSection(footerSectionIds, sections),
    entityId: id,
    readOnly: state.versions.readOnly,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    onSectionOrderChange: (list) => {
      const sectionIds = list.map(ele=>ele.id);
      dispatch(reorderFooterSectionsT('company_category', id, sectionIds));
    }
  }
}

const FooterLinkRedux = (props) => (
  <FooterLinksView {...props}/>
);

export default connect(mapStateToProps, mapDispatchToProps)(FooterLinkRedux);
