import React from 'react';
import ZInput from '../../z-input';
import ZButton from '../../z-button';
import ZSpinner from '../../z-spinner';
import Logo from './logo';
import { decodeHTMLEntities } from '../../../utils/window';
import style from './news-source-editor.module.scss';

const NewsSourceEditorView = ({
  closeModal,
  createLogo,
  error,
  isFetching,
  logo,
  name,
  newsSourceId,
  onClickSave,
  saveDisabled,
  deleteLogo,
  setName,
  updateLogo,
}) => (
  <div className={style['editor']}>
    <Logo
      createLogo={createLogo}
      deleteLogo={deleteLogo}
      logo={logo}
      logoId={logo.id}
      newsSourceId={newsSourceId}
      showInfo
      updateLogo={updateLogo}
    />
    <ZInput
      inputId="name"
      label="Name"
      onChange={(val) => setName(newsSourceId, val)}
      placeholder="Enter name here"
      style={{ margin: '20px 0' }}
      text={decodeHTMLEntities(name)}
    />
    {error && (
      <p className={style['error']}>There was an error saving this news source.</p>
    )}
    <div className={style['editor__buttons']}>
      {isFetching ? (
        <ZSpinner />
      ) : (
        <>
          <ZButton
            btnClass={style['editor__button']}
            disabled={saveDisabled}
            onClick={onClickSave}
            text="Save"
          />
          <ZButton
            btnClass={style['editor__button']}
            onClick={closeModal}
            text="Cancel"
          />
        </>
      )}
    </div>
  </div>
);

export default NewsSourceEditorView;
