import ZApiClient from '../../utils/client/z-api-client';
import { decodeHTMLEntities } from "../../utils/window";
import {getCountriesT} from "./shared/location";

/**
 * shared
 */
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const RECEIVED_COUNTRY_LIST = 'RECEIVED_COUNTRY_LIST';
/** */

/**
 * Simple pages
 */
export const REQUEST_PAGES = 'REQUEST_PAGES';
export const RECEIVED_PAGES = 'RECEIVED_PAGES';
/** */

/**
 * Guides
 */
export const REQUEST_GUIDES = 'REQUEST_GUIDES';
export const RECEIVED_GUIDES = 'RECEIVED_GUIDES';
/** */

/**
 * Articles
 */
export const REQUEST_ARTICLES = 'REQUEST_ARTICLES';
export const RECEIVED_ARTICLES = 'RECEIVED_ARTICLES';
/** */

/**
 * Itinerary
 */
export const REQUEST_ITINS = 'REQUEST_ITINS';
export const RECEIVED_ITINS = 'RECEIVED_ITINS';
/** */

/**c
 * landing pages
 */
export const REQUEST_LTLS = 'REQUEST_LTLS';
export const RECEIVED_LTLS = 'RECEIVED_LTLS';
/** */

/**
 * company category
 */
export const REQUEST_COMPANY_CATEGORIES = 'REQUEST_COMPANY_CATEGORIES';
export const RECEIVED_COMPANY_CATEGORIES = 'RECEIVED_COMPANY_CATEGORIES';

/**
 * landing page category aka Hub Page
 */
export const REQUEST_LANDING_PAGE_CATEGORIES = 'REQUEST_LANDING_PAGE_CATEGORIES';
export const RECEIVED_LANDING_PAGE_CATEGORIES = 'RECEIVED_LANDING_PAGE_CATEGORIES';

export const setSearchTerm = (text, entityType) => {
  return (dispatch) => {
    switch (entityType) {
      case 'articles':
      case 'article':
        dispatch({
          type: SET_SEARCH_TERM,
          entityType: 'articles',
          search: text,
        });
        break;
      case 'guides':
      case 'guide':
        dispatch({
          type: SET_SEARCH_TERM,
          entityType: 'guides',
          search: text,
        });
        break;
      case 'itineraries':
      case 'itinerary':
        dispatch({
          type: SET_SEARCH_TERM,
          entityType: 'itineraries',
          search: text,
        });
        break;
      case 'landingPages':
      case 'landingPage':
        dispatch({
          type: SET_SEARCH_TERM,
          entityType: 'landingPages',
          search: text,
        });
        break;
      case 'simplePages':
      case 'simplePage':
      case 'content':
        dispatch({
          type: SET_SEARCH_TERM,
          entityType: 'simplePages',
          search: text,
        });
        break;
      case 'companyCategory':
      case 'companyCategories':
        dispatch({
          type: SET_SEARCH_TERM,
          entityType: 'companyCategories',
          search: text,
        });
        break;
      case 'landingPageCategory':
        case 'landingPageCategories':
          dispatch({
            type: SET_SEARCH_TERM,
            entityType: 'landingPageCategories',
            search: text,
          });
          break;
      default:
        break;
    }
  }
};

const requestEntities = (entityType) => {
  return (dispatch) => {
    switch (entityType) {
      case 'articles':
      case 'article':
        dispatch({
          type: REQUEST_ARTICLES,
          entityType: 'articles',
        });
        break;
      case 'guides':
      case 'guide':
        dispatch({
          type: REQUEST_GUIDES,
          entityType: 'guides',
        });
        break;
      case 'itineraries':
      case 'itinerary':
        dispatch({
          type: REQUEST_ITINS,
          entityType: 'itineraries',
        });
        break;
      case 'landingPages':
      case 'landingPage':
        dispatch({
          type: REQUEST_LTLS,
          entityType: 'landingPages',
        });
        break;
      case 'simplePages':
      case 'simplePage':
      case 'content':
        dispatch({
          type: REQUEST_PAGES,
          entityType: 'simplePages',
        });
        break;
      case 'companyCategory':
      case 'companyCategories':
        dispatch({
          type: REQUEST_COMPANY_CATEGORIES,
          entityType: 'companyCategories'
        });
        break;
      case 'landingPageCategory':
      case 'landingPageCategories':
        dispatch({
          type: REQUEST_LANDING_PAGE_CATEGORIES,
          entityType: 'landingPageCategories'
        });
        break;
      default:
        break;
    }
  }

};

const receivedEntities = (type, entities, receivedAll = false) => {
  return (dispatch) => {
    switch (type) {
      case 'articles':
      case 'article':
        dispatch({
          type: RECEIVED_ARTICLES,
          entities,
          entityType: 'articles',
          receivedAt: Date.now(),
          receivedAll,
        });
        break;
      case 'guides':
      case 'guide':
        dispatch({
          type: RECEIVED_GUIDES,
          entities,
          entityType: 'guides',
          receivedAt: Date.now(),
          receivedAll,
        });
        break;
      case 'itineraries':
      case 'itinerary':
        dispatch({
          type: RECEIVED_ITINS,
          entities,
          entityType: 'itineraries',
          receivedAt: Date.now(),
          receivedAll,
        });
        break;
      case 'landingPages':
      case 'landingPage':
      case 'landing_pages':
      case 'landing_page':
        dispatch({
          type: RECEIVED_LTLS,
          entities,
          entityType: 'landingPages',
          receivedAt: Date.now(),
          receivedAll,
        });
        break;
      case 'simplePages':
      case 'simplePage':
      case 'content':
        dispatch({
          type: RECEIVED_PAGES,
          entities,
          entityType: 'simplePages',
          receivedAt: Date.now(),
          receivedAll,
        });
        break;
      case 'companyCategory':
      case 'companyCategories':
        dispatch({
          type: RECEIVED_COMPANY_CATEGORIES,
          entities,
          entityType: 'companyCategories',
          receivedAt: Date.now(),
          receivedAll,
        });
        break;
      case 'landingPageCategory':
        case 'landingPageCategories':
          dispatch({
            type: RECEIVED_LANDING_PAGE_CATEGORIES,
            entities,
            entityType: 'landingPageCategories',
            receivedAt: Date.now(),
            receivedAll,
          });
          break;
      default:
        return {};
    }
  }
};

export const asyncGetAllSnippets = (
  entityType,
  action,
  limit = 1000,
  offset,
) => {
  return dispatch => {
    const client = ZApiClient.getClientFromType(entityType);
    const params = {
      format: 'json',
      limit,
      ...offset && { offset: offset }
    };
    // Company Categories don't have snippets
    // So client.getSnippets() wont work for companyCategories
    if (entityType !== 'companyCategories' || entityType !== 'landingPageCategories') {
      client.getSnippets(params)
      .then((json) => {
        const receivedAll = json.length < limit;
        const cleanList = json.map((ele)=> {
          const current = {...ele};
          return {
            ...current,
            title: decodeHTMLEntities(current.title),
          }
        });
        dispatch(action(entityType, cleanList, receivedAll));
        if(!receivedAll){
          let lastTuid = json[json.length - 1].id;
          dispatch(asyncGetAllSnippets(entityType, action, limit, lastTuid));
        }
      });
    } else if (entityType === 'companyCategories') {
      client.getCompanyCategories(params)
      .then((json) => {
        const receivedAll = json.length < limit;
        const cleanList = json.map((ele)=> {
          const current = {...ele};
          return {
            ...current,
            title: decodeHTMLEntities(current.title),
          }
        });
        dispatch(action(entityType, cleanList, receivedAll));
        if(!receivedAll){
          let lastTuid = json[json.length - 1].id;
          dispatch(asyncGetAllSnippets(entityType, action, limit, lastTuid));
        }
      });
    } else {
      client.getLandingPageCategories(params)
      .then((json) => {
        const receivedAll = json.length < limit;
        const cleanList = json.map((ele)=> {
          const current = {...ele};
          return {
            ...current,
            title: decodeHTMLEntities(current.title),
          }
        });
        dispatch(action(entityType, cleanList, receivedAll));
        if(!receivedAll){
          let lastTuid = json[json.length - 1].id;
          dispatch(asyncGetAllSnippets(entityType, action, limit, lastTuid));
        }
      });
    }
  }
};

/**
 *
 * state = {
 *  landingPages : {
 *    receivedAll
 *    entities : []
 *  }
 * }
 */
export const loadEntities = (entityType) => {
  return (dispatch, getState) => {
    if(!getState().entities[entityType] || !getState().entities[entityType].receivedAll){
      dispatch(requestEntities(entityType));
      dispatch(getCountriesT(entityType));
      dispatch(asyncGetAllSnippets(entityType, receivedEntities, 1000));
    }
  }
};
