import { connect } from 'react-redux';
import TeamMemberEditorContainer from './team-member-editor-container';
import {
  saveTeamMember,
  setDisplayed,
  setTeamMemberText,
  setJobTitle,
  setTeamMemberPhoto,
  setTeamMemberName,
  deleteTeamMember,
  fetchTeamMembers,
} from '../../../state/actions/team-editor';

const mapStateToProps = (state, ownProps) => ({
    teamMember: state.teamMembers[ownProps.id] || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  saveTeamMember: () => dispatch(saveTeamMember(ownProps.id, ownProps.closeModal)),
  setDisplayed: (val) => dispatch(setDisplayed(ownProps.id, val)),
  setDescription: (val) => dispatch(setTeamMemberText(ownProps.id, val)),
  setName: (val) => dispatch(setTeamMemberName(ownProps.id, val)),
  setTitle: (val) => dispatch(setJobTitle(ownProps.id, val)),
  setPhoto: (photo) => dispatch(setTeamMemberPhoto(ownProps.id, photo)),
  deleteTeamMember: () => deleteTeamMember(ownProps.id),
  fetchTeamMembers: () => dispatch(fetchTeamMembers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberEditorContainer);
