import {
  PHOTO_ACTION_NAMES,
} from '../actions/shared/photo-action-types';

const photoT = (
  T,
  state = {
    error: false,
  },
  action,
) => {
  switch(action.type){
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_HEADER_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_MOBILE_HEADER_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_SECTION_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].UPDATE_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_GALLERY_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].ADD_HEADER_THUMB:
      return {
        ...state,
        ...action.photo,
        isFetching: false,
        error: false,
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].PHOTO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].PHOTO_RECEIVED:
      return {
        ...state,
        isFetching: false,
        error: false,
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].PHOTO_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
};

const photosT = (T) => (
  state = {},
  action,
) => {
  switch(action.type){
    case PHOTO_ACTION_NAMES[T.toUpperCase()].RECEIVED_PHOTOS:
      return {
        ...state,
        ...action.photos,
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_HEADER_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_SECTION_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_MOBILE_HEADER_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].UPDATE_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].SET_GALLERY_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].ADD_HEADER_THUMB:
      return {
        ...state,
        [action.photo.id] : photoT(T, state[action.photo.id], action),
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].PHOTO_ERROR:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].PHOTO_RECEIVED:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].PHOTO_REQUEST:
      return {
        ...state,
        [action.photoId]: photoT(T, state[action.photoId], action),
      };
    case PHOTO_ACTION_NAMES[T.toUpperCase()].REMOVE_HEADER_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].REMOVE_SECTION_PHOTO:
    case PHOTO_ACTION_NAMES[T.toUpperCase()].REMOVE_GALLERY_PHOTO:
      const photos = { ...state };
      delete photos[action.photoId];
      delete photos[undefined];
      return photos;

    default:
      return state;
  }
};

export default photosT;
