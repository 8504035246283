import { fetchEntityVersions as fevs, fetchEntityVersion as fev } from '../../utils/versions/versions-utils';

export const VERSIONS_REQUEST = 'VERSIONS_REQUEST';
export const versionsRequest = () => ({
  type: VERSIONS_REQUEST,
});

export const VERSIONS_SUCCESS = 'VERSIONS_SUCCESS';
export const versionsSuccess = (entityId, json, offset) => ({
  type: VERSIONS_SUCCESS,
  entityId,
  json,
  offset,
  receivedAt: Date.now(),
});

export const VERSIONS_ERROR = 'VERSIONS_ERROR';
export const versionsError = () => ({
  type: VERSIONS_ERROR,
});

export const VERSIONS_LOAD_MORE_SUCCESS = 'VERSIONS_LOAD_MORE_SUCCESS';
export const versionsLoadMoreSuccess = (entityId, json, offset) => ({
  type: VERSIONS_LOAD_MORE_SUCCESS,
  entityId,
  json,
  offset,
  receivedAt: Date.now(),
});


export const VERSION_REQUEST = 'VERSION_REQUEST';
export const versionRequest = (entityId) => ({
  type: VERSION_REQUEST,
  entityId,
});

export const VERSION_SUCCESS = 'VERSION_SUCCESS';
export const versionSuccess = (entityId, updateId, errors) => ({
  type: VERSION_SUCCESS,
  entityId,
  updateId,
  errors,
  receivedAt: Date.now(),
});

export const VERSION_ERROR = 'VERSION_ERROR';
export const versionError = (entityId) => ({
  type: VERSION_ERROR,
  entityId,
});

export const ADD_SAVED_ENTITY_VERSION = 'ADD_SAVED_ENTITY_VERSION';
export const addSavedEntityVersion = (entityId, newVersion) => ({
  type: ADD_SAVED_ENTITY_VERSION,
  entityId,
  newVersion,
});

export const TOGGLE_READ_ONLY = 'TOGGLE_READ_ONLY';
export const toggleReadOnly = () => ({
  type: TOGGLE_READ_ONLY,
});

export const fetchEntityVersions = (entityType, entityId, limit = 50) => (dispatch, getState) => {
  const { versions } = getState();
  const offset = versions[entityId] ? versions[entityId].offset : null;
 
  dispatch(versionsRequest(entityId));
  return fevs(entityType, entityId, offset, limit)
    .then((json) => {
      if (json.httpStatus >= 400) dispatch(versionsError(entityId));
      else {
        const { length } = json;
        const { updateId, id } = json[length - 1];
        let newOffset = length === limit && id !== updateId ? updateId : null;
        if (!offset) dispatch(versionsSuccess(entityId, json, newOffset));
        if (!!offset) dispatch(versionsLoadMoreSuccess(entityId, json, newOffset));
      }
    })
    .catch((e) => {
      console.error(e);
      dispatch(versionsError(entityId));
    });
};

export const fetchEntityVersion = (entityType, entityId, versionId) => (dispatch) => {
  dispatch(versionRequest(entityId));
  return fev(entityType, entityId, versionId)
    .then((res) => {
      if (res.httpStatus >= 400 || res.error) dispatch(versionError(entityId));
      else return res;
    })
    .catch((e) => {
      console.error(e);
      dispatch(versionError(entityId));
    });
};

