import { push } from 'connected-react-router';
import LandingPageClient from '../../../utils/client/landing-page';
import { fetchTuid } from '../../../utils/fetch/tuid';
import getLink from '../../../utils/fetch/links';
import {
  fetchEntityVersion,
  addSavedEntityVersion,
  versionSuccess,
} from '../versions';
import ZApiClient from '../../../utils/client/z-api-client';
import { LandingPage } from '@zicasso/zicasso-sdk/lib/landing';
import {getCountriesT} from "../shared/location";
import {receivedPhotosT} from "../shared/photo";
import {receivedSectionsT} from "../shared/section";

import {
  REQUEST_LANDING_PAGE,
  REQUEST_NEW_LANDING_PAGE,
  RECEIVED_LANDING_PAGE,
  LANDING_PAGE_SAVE_REQUESTED,
  LANDING_PAGE_SAVED,
  REQUEST_LANDING_PAGE_FAILED,
} from '../shared/similar-action-types';
import {
  ENABLE_SAVE,
  DISABLE_SAVE,
  SET_TYPE,
  SET_SUB_NAV,
  DELETE_SUB_NAV,
  SET_ITINERARIES,
  SET_HCS_SNIPPETS,
  SET_HCS_TITLE,
  SET_TITLE_FLAG,
  SET_HEADER_LINKS,
  SET_GALLERY_LINKS,
  SET_GALLERY_LINKS_TITLE,
  DELETE_GALLERY_LINKS_SECTION,
  SET_AGENT_LIST,
  SET_FAQ_TITLE,
  SET_FAQ_SECTIONS,
} from './action-types';

const landingPageClient = new LandingPageClient();

export const disableSave = (message = null) => ({
  type: DISABLE_SAVE,
  message,
});

export const enableSave = () => ({
  type: ENABLE_SAVE,
});

const requestLandingpage = () => ({
  type: REQUEST_LANDING_PAGE,
});

export const requestNewLandingPage = () => ({
  type: REQUEST_NEW_LANDING_PAGE,
});

const receivedLandingPage = (data) => ({
  type: RECEIVED_LANDING_PAGE,
  receivedAt: Date.now(),
  id: data.id,
  entity: data,
});


export const saveRequested = () => ({
  type: LANDING_PAGE_SAVE_REQUESTED,
});

export const landingPageSaved = (data) => ({
  type: LANDING_PAGE_SAVED,
  data
});

export const landingPageRequestFailed = () => ({
  type: REQUEST_LANDING_PAGE_FAILED,
});

export const setType = (type, id) => ({
  type: SET_TYPE,
  value: type,
  id,
});

export const setSubNav = (id, subNavData) => ({
  type: SET_SUB_NAV,
  id,
  data: subNavData,
});

export const deleteSubNav = (id) => ({
  type: DELETE_SUB_NAV,
  id,
});

export const setItineraries = (id, itinList) => ({
  type: SET_ITINERARIES,
  id,
  data: itinList,
});


export const setHCSSnippets = (id, snippets) => ({
  type: SET_HCS_SNIPPETS,
  id,
  data: snippets,
});

export const setHCSTitle = (id, title) => ({
  type: SET_HCS_TITLE,
  id,
  data: title,
});

export const setFAQSections = (id, sections) => ({
  type: SET_FAQ_SECTIONS,
  id,
  data: sections,
});

export const setFAQTitle = (id, title) => ({
  type: SET_FAQ_TITLE,
  id,
  data: title,
});

export const setTitleFlag = (id, isoCode) => ({
  type: SET_TITLE_FLAG,
  id,
  data: isoCode,
});

export const setHeaderLinks = (id, headerLinks) => ({
  type: SET_HEADER_LINKS,
  id,
  data: headerLinks,
});

export const setGalleryLinks = (id, links) => ({
  type: SET_GALLERY_LINKS,
  id,
  links,
});

export const setGalleryLinksTitle = (id, title) => ({
  type: SET_GALLERY_LINKS_TITLE,
  id,
  title,
});

export const deleteGalleryLinkSection = (id) => ({
  type: DELETE_GALLERY_LINKS_SECTION,
  id,
});

export const setAgentList = (id, agentList) => ({
  type: SET_AGENT_LIST,
  id,
  data: agentList,
});

const getOrCreateSnippet = async (snippets, navLink) => {
  const snippet = snippets.find((ele) => ele.id === navLink.entityId);
  return snippet ? snippet : await getLink(navLink.entityId, navLink.entityType);
};

const getNavSnippets = async (snippets, navLinks) => {
  const navSnippets = navLinks.reduce((acc, ele) => {
    const valid = ele.entityType === 'Article' || ele.entityType === 'Guide';
    return valid ? [...acc, ele] : acc;
  },[]);
  const custom = snippets.reduce((acc, snippet) => {
    const exists = navSnippets.find(ele => ele.entityId === snippet.entityId);
    return !exists ? [...acc, snippet] : acc;
  },[]);
  const nav = await Promise.all(navSnippets.map(ele => getOrCreateSnippet(snippets, ele)));
  return [nav, custom];
};

const getCompanyLink = (arr = []) => {
  const res = arr.find((e) => e.entityType === "CompanyCategory");
  return res || {};
}

export const updateSubnav =(entityId, subNavData) => {
  return async (dispatch, getState) => {
    const {editor} = getState().landingPages.landingPage;
    dispatch(setSubNav(entityId, subNavData));
    const {
      helpfulContent,
      agentList
    } = editor[entityId];
    const navLinks = [...subNavData];
    const snippets = helpfulContent.links;
    const [nav, custom] = await getNavSnippets(snippets, navLinks);
    const newHCS = [...nav, ...custom];
    const ccpUsed = getCompanyLink(navLinks);
    // Update agent list with the new CCP
    if (agentList && ccpUsed.entityId){
      const updatedAgentList = {...agentList}
      updatedAgentList.links[0].entityId = ccpUsed.entityId;
      updatedAgentList.links[0].uri = ccpUsed.uri;
      dispatch(setAgentList(entityId, updatedAgentList));
    }

    // Only update helpful content if it has already been created.
    // This is to prevent the recreation of a generated helpful content section, once it has been removed.
    if (helpfulContent.links.length !== 0) {
      dispatch(setHCSSnippets(entityId, newHCS));
    }
  }
};

export const redirectToCreate = () => {
  return dispatch => {
    dispatch(push('/create/landingPage'));
  }
};

function shouldFetchLandingPage(state, id, updateId) {
  const {editor} = state.landingPages.landingPage;
  if (!editor[id]) {
    return true
  } else if (editor[id] && editor[id].updateId !== updateId) {
    return true
  } else {
    return false;
  }
}

/**
 * Pass the result of article client getState method;
 * @param {object} landingPageState
 */
const receiveLandingPageState = (landingPageState) => {
  return dispatch => {
    let {id:currentId} = landingPageState;
    dispatch(receivedPhotosT('landing_page', landingPageState.photos));
    dispatch(receivedSectionsT('landing_page', landingPageState.sections));
    dispatch(receivedLandingPage(landingPageState[currentId]));
  }
};

/**
 *
 * @param {string} id
 */
export const fetchLandingPageIfNeeded = (id, updateId) => {
  return (dispatch, getState) => {
    const { countries } = getState().landingPages.landingPage.editor;
    const shouldFetch = shouldFetchLandingPage(getState(), id, updateId);

    if (!countries || countries.length === 0)  {
      dispatch(getCountriesT('landing_page'));
    }
    if(shouldFetch){
      dispatch(fetchLandingPage(id, updateId));
    }
  }
};

export const fetchLandingPage = (id, updateId) => {
  return dispatch => {
    dispatch(requestLandingpage());
    const req = !updateId
      ? ZApiClient.getLandingPagesClient().getLandingPage(id)
      : ZApiClient.getLandingPagesClient().getLandingPageVersion(id, updateId);
    return req.then((res)=>{
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return landingPageClient.getState(res);
    }).then((newState)=>{
      dispatch(receiveLandingPageState(newState));
    }).catch((e)=>{
      if(e.message === 'NOT_FOUND'){
        dispatch(landingPageRequestFailed());
      }
      console.error('Error Fetching Landing Page', e);
    })
  };
};

export const saveLandingPage = (landingPageId) => {
  return (dispatch, getState) => {
    dispatch(saveRequested());
    ZApiClient
    .getLandingPagesClient()
    .exists(landingPageId)
    .then((res)=>{
      const shouldCreate = !res;
      const currentState = getState();
      const {landingPage} = currentState.landingPages;
      const entity = landingPageClient.getLandingPage(landingPageId, landingPage);
      return createOrUpdateLandingPage(entity, shouldCreate);
    }).then((result)=>{
      dispatch(landingPageSaved());
      const { id, updateId, userId, updatedAt } = result;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return landingPageClient.getState(result);
    }).then((newState)=>{
      dispatch(receiveLandingPageState(newState));
    }).catch((e)=>{
      console.error('Error Saving Landing Page', e);
      console.error(e.stack);
    });
  };
};

const createOrUpdateLandingPage = (data, create = true) =>{
  if(create){
    return ZApiClient.getLandingPagesClient().createLandingPage(new LandingPage(data));
  } else {
    return ZApiClient.getLandingPagesClient().updateLandingPage(new LandingPage(data));
  }
};

export const makeLatest = (id, updateId) => (dispatch) => {
  dispatch(requestLandingpage());
  return ZApiClient
  .getLandingPagesClient()
  .getLandingPageVersion(id, updateId)
    .then((res) => {
      if(res.httpStatus && res.httpStatus === 404){
        throw new Error('NOT_FOUND');
      }
      return createOrUpdateLandingPage(res, false);
    })
    .then((res) => {
      const { id, updateId, userId, updatedAt } = res;
      const newVersion = { id, updateId, userId, updatedAt };
      dispatch(addSavedEntityVersion(id, newVersion));
      return landingPageClient.getState(res);
    })
    .then((newState) => {
      dispatch(receiveLandingPageState(newState));
    })
    .catch((e) => {
      if(e.message === 'NOT_FOUND'){
        dispatch(landingPageRequestFailed());
      }
      console.error('Error Fetching Landing Page', e);
    });
};

export const createNewLandingPage = () => {
  return dispatch => {
    dispatch(requestNewLandingPage());
    return fetchTuid()
      .then(val => {
        let {id} = val;
        dispatch(receivedLandingPage({
          id : id,
          title: null,
          summary: '',
          sectionIds: [],
          metatags: {},
        }));
        dispatch(push(`/landing-pages/${id}`));
      })
  }
};

export const fetchLandingPageVersion = (id, versionId) => (dispatch) => (
  dispatch(fetchEntityVersion('landingPages', id, versionId))
    .then((res) => {
      dispatch(versionSuccess(id, versionId));
      return res;
    })
    .catch(console.error)
);

export const validateEntityVersion = (entityId, versionId) => (dispatch, getState) => {
  const paths = getState().paths[entityId];
  return dispatch(fetchLandingPageVersion(entityId, versionId))
    .then((res) => {
      const entityToValidate = {
        ...res,
        ...paths && { paths }
      };
      return landingPageClient.validateForPublish(entityToValidate);
    })
};
