import {
    SET_CTA_STEPS,
    SET_CTA_TITLE
} from './teaser-action-types';

export const setCTATitleT = (T, text, id) => ({
    type: SET_CTA_TITLE(T),
    value: text,
    id
});

export const setCTAStepsT = (T, data, id) => ({
    type: SET_CTA_STEPS(T),
    value: data,
    id
});
