import React from 'react';
import SampleSnippet from './sample-snippet';
import ZAutoComplete from '../../z-autocomplete';
import ZInput from '../../z-input';
import ZDraggableGrid from '../../z-draggable-grid.js/index.js';
import style from './helpful-content.module.scss';

const HelpfulContentView = ({
  navBarSnippets,
  snippets,
  snippetCount,
  snippetLimit,
  autoComplete,
  autoCompleteOnSelect,
  contentSectionTitle,
  errors,
  onChangePosition,
  onClickEdit,
  onClickPreview,
  onDragEnd,
  onUpdate,
  onUpdateTitle,
  readOnly,
  removeAllSnippets,
  removeSnippet,
  addDay,
  generateNavSnippets
}) => {
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Helpful Content Section</h2>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <button
            type="button"
            onClick={generateNavSnippets}
            className={style['hcs__delete-snippets']}
          >
            Generate Snippets
          </button>
          <button
            type="button"
            onClick={removeAllSnippets}
            className={style['hcs__delete-snippets']}
          >
            Delete Snippets
          </button>
        </div>
      </div>
      <div>
        <ZInput
          initialValue={contentSectionTitle}
          label='Helpful Content Section Title'
          style={{marginBottom: '14px'}}
          onUpdate={onUpdateTitle}
        />
      </div>
      { (navBarSnippets && navBarSnippets.length > 0) && 
        <div>
          <h3 className={style['text']}>Subnavigation Snippets (Auto Generated)</h3>
          {navBarSnippets.map((ele, i) => (
            <SampleSnippet 
              className={style['hcs__preview-snippets']}
              key={`${ele.id}-${i}`}
              snippet={{...ele}} 
              snippetLength={snippets.length}
              index={i} 
              inputKey={`${ele.id}-${i}`}
              onClickPreview={() => onClickPreview(ele.id, ele.entityType)}
              onClickEdit={() => onClickEdit(ele.id, ele.entityType)}
              previewView
            />
          ))}
        </div>
      }
      <p 
        style={{
          paddingRight: '16px',
          fontSize: '18px',
          textAlign: 'right',
          margin: '28px 0 0',
          color: snippetCount >= snippetLimit ? 'red' : 'inherit'
        }}
      >
        {snippetCount >= snippetLimit ? 'Limit Reached. Remove Snippets to Add More ' : ''}
        {`Total: ${snippetCount}/${snippetLimit}`}
      </p>
      <ZAutoComplete
        disabled={snippetCount >= snippetLimit}
        autoComplete={autoComplete}
        onSelect={autoCompleteOnSelect}
        returnsObject={true}
        label='Add an Article/Guide by Name'
        style={{marginBottom: '14px'}}
        error={errors.articles ? errors.articles : null}
      />
      {snippets && snippets.length !== 0 &&
        <ZDraggableGrid onDragEnd={onDragEnd} disabled={readOnly}>
          {snippets.length > 0 && snippets.map((ele, i) => (
            <SampleSnippet 
              key={`${ele.id}-${i}`}
              snippet={{...ele}} 
              snippetLength={snippets.length}
              index={i} 
              onUpdate={onUpdate}
              onChangePosition={onChangePosition}
              inputKey={`${ele.id}-${i}`}
              addDay={addDay}
              removeSnippet={removeSnippet}
              onClickPreview={() => onClickPreview(ele.id, ele.entityType)}
              onClickEdit={() => onClickEdit(ele.id, ele.entityType)}
            />
          ))}
        </ZDraggableGrid>
      }
    </>
  )
}

export default HelpfulContentView;
