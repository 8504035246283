import React, { useState } from "react";
import Validate from '../../../../utils/validate';
import ZImage from '../../../z-image';
 
const buttonStyle = {
  maxHeight: '40px',
  height: '40px',
  marginLeft: '50px',
  backgroundColor: '#faa02f',
  borderColor: '#ed9300',
  textAlign: 'center',
  borderRadius: '2px',
  cursor: 'pointer',
  boxShadow: '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)'
}

const EditInput = ({
  value,
  id,
  label,
  onChange,
  style,
}) => (
  <div
    style={{
      position: 'relative',
      ...style
    }}
  >
    <label
      style={{
        left: 0,
      }}
      htmlFor={id}
    >
      {label}
    </label>
    <input
      style={{
        borderWidth: '0 0 1px 0',
        borderColor: '#bdbdbd',
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        width: '100%',
        outline: 'none',
      }}
      value={value}
      type="text"
      id={id}
      onChange={onChange}
      autoComplete="off"
    />
  </div>
)

const PhotoEdit = ({
  onSubmitNewImage,
  onToggleModal,
}) => {
  const [submitActive, setSubmitActive] = useState(false);
  const [newPhoto, setNewPhoto] = useState({
    alt : '',
    gravity: '',
    title : '',
    uri : '',
  });

  const onUriChange = (e) => {
    const uri = e.target.value;
    const validImage = uri && Validate.imageUrl(uri);
    setSubmitActive(validImage);
    setNewPhoto((state) => ({
      ...state,
      uri,
    }));
  };

  const onTitleChange = (e) => {
    const title = e.target.value;
    setNewPhoto((state) => ({
      ...state,
      title,
    }));
  };

  const onAltChange = (e) => {
    const alt = e.target.value;
    setNewPhoto((state) => ({
      ...state,
      alt,
    }));
  };

  const onSubmit = () => {
    onSubmitNewImage(newPhoto);
  }

  return (
    <>
      <h2 style={{color: '#ed9300'}}>Add a photo</h2>
      <p style={{marginBottom: '20px'}}>Paste a Url Below</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '300px',
          marginBottom: '14px',
          paddingBottom: '7px'
        }}
      >
        <div
          style={{
            width: '300px',
            maxWidth: '300px',
            minWidth: '300px',
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            boxShadow: '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)',
          }}
        >
          <ZImage
            alt={newPhoto.alt}
            style={{
              width: '100%',
              objectFit: 'scale-down',
            }}
            cloudinarySrc={newPhoto.uri}
            gravity={newPhoto.gravity}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <EditInput
            label="Paste A Url"
            id="edit-uri"
            value={newPhoto.uri}
            onChange={onUriChange}
            style={{width: '100%', marginBottom: '54px'}}
          />
          <div
            style={{
              display: 'flex',
              justifyContent:' space-between',
              width: '100%',
            }}
          >
            <EditInput
              label='Alt Text'
              id="edit-alt"
              value={newPhoto.alt}
              onChange={onAltChange}
              style={{width: '100%', marginRight: '7px', borderLeft: '1px solid #bdbdbd'}}
            />
            <EditInput
              id="edit-title"
              label='Title Text'
              value={newPhoto.title}
              onChange={onTitleChange}
              style={{width: '100%', borderLeft: '1px solid #bdbdbd'}}
            />
          </div>
        </div>
       
      </div>
      <div style={{display: 'flex', justifyContent: 'space-around'}}>
        <button type="button"
          disabled={!submitActive}
          style={{...buttonStyle, cursor: `${(submitActive) ? 'pointer' : 'not-allowed' }`}}
          onClick={onSubmit}
        >
          Submit
        </button>
        <button
          type={"button"}
          style={buttonStyle}
          onClick={onToggleModal}
        >
          Cancel
        </button>
      </div>
    </>
  )
};

export default PhotoEdit;
