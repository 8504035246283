import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ZInput from '../../z-input';
import ZTextArea from '../../z-text-area';
import ZToggle from '../../z-toggle';
import ZButton from '../../z-button';
import ZSpinner from '../../z-spinner';
import NewsSource from './news-source';
import { decodeHTMLEntities } from '../../../utils/window';
import style from './news-snippet-editor.module.scss';

const NewsSnippetEditorView = ({
  closeModal,
  displayed,
  error,
  id,
  isSaving,
  onChangeDate,
  onChangeDisplayed,
  onClickEdit,
  onClickSave,
  publicationDate,
  saveDisabled,
  setEditNewsSourceId,
  setTeaser,
  setTitle,
  setUri,
  teaser,
  title,
  uri,
  validUri,
}) => (
  <div className={style['editor']}>
    <ZToggle
      checked={displayed}
      label="Displayed"
      onChange={onChangeDisplayed}
      toggleClass={style['editor__toggle']}
    />
    <NewsSource newsSnippetId={id} onClickEdit={onClickEdit} setEditNewsSourceId={setEditNewsSourceId} />
    <label className={style['editor__date-label']} htmlFor='publicationDate'>
      Publication date:&nbsp;
    </label>
    <DatePicker
      className={style['editor__date']}
      dateFormat="MMMM d, yyyy"
      id="publicationDate"
      name="publicationDate"
      onChange={onChangeDate}
      placeholderText="Publication Date"
      popperClassName={style['editor__date-popper']}
      selected={publicationDate ? new Date(`${publicationDate}T00:00:00`) : ''}
    />
    <ZInput
      inputId="title"
      label="Title"
      onChange={setTitle}
      placeholder="Enter title here"
      style={{ marginBottom: '20px' }}
      text={decodeHTMLEntities(title)}
    />
    <ZTextArea
       allowedFormats={['LINKS', 'BOLD', 'ITALICS']}
       label="Teaser"
       onUpdate={setTeaser}
       placeholder="Enter teaser here"
       style={{ marginBottom: '20px' }}
       textAreaStyle={{ maxHeight: '200px', overflow: 'scroll' }}
       value={teaser}
    />
    <ZInput
      error={validUri === false && { message: 'Please enter a valid uri '}}
      inputId="uri"
      label="Link to Article"
      onChange={setUri}
      placeholder="Enter link to article here"
      style={{ marginBottom: '40px' }}
      text={uri}
    />
    {error && (
      <p className={style['error']}>There was an error saving this news article.</p>
    )}
    <div className={style['editor__buttons']}>
      {isSaving ? (
        <ZSpinner />
      ) : (
        <>
          <ZButton
            btnClass={style['editor__button']}
            disabled={saveDisabled}
            onClick={onClickSave}
            text="Save"
          />
          <ZButton
            btnClass={style['editor__button']}
            onClick={closeModal}
            text="Cancel"
          />
        </>
      )}
    </div>
  </div>
);

export default NewsSnippetEditorView;
