import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './stars.module.scss';

const Stars = memo(({
  className,
  rating,
}) => (
  <div className={`${styles['stars__wrapper']} ${className}`}>
    <div
      aria-label={`${rating} stars`}
      className={styles['stars']}
      role="img"
      style={{ width: `${rating}%` }}
    />
  </div>
));
Stars.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number.isRequired,
};
Stars.defaultProps = {
  className: '',
};
Stars.displayName = 'Stars';

export default Stars;
