import React, { useState, useCallback, useRef, useEffect } from 'react';
import ZInput from '../../z-input';
import ZAutoComplete from '../../z-autocomplete';
import Button from '../../z-button';

const SubnavTab = ({
  index,
  title,
  shortTitle,
  currentEntity,
  onSearch,
  onSelect,
  inputPlaceholder,
  onTitleChange,
  onAddClick,
  onDeleteClick,
  onUpdateClick,
  disableAdd,
  deleteBtn,
  isHost,
  onEntityChange,
}) => (
  <div
    style={{
      marginTop: '28px',
      backgroundColor: index === 0 ? '#f5f5f5' : '#fff',
      padding: '14px',
    }}
  >
    <h3>
      {index === 0
        ? `Host Tab - Position ${index + 1} - Location / Theme Tours`
        : `Tab - Position ${index + 1}`
      }
    </h3>
    <div>
    {index !== 4 && (
      <>
        <div style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
          <p>Desktop: </p>
          <ZInput
            onChange={(t)=> onTitleChange(t, 'desktop')}
            text={title}
            style={{flexGrow:'2', padding: '0 14px', maxWidth: '900px'}}
            placeholder='Add Tab Text (20 characters max)'
            inputKey={'admin__subnavTitle'}
          />
        </div>
        {isHost && deleteBtn && (title === '') && <p style={{color: 'red', margin: '0px'}}>Required</p>}
        <div style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
          <p>Mobile: </p>
          <ZInput
            onChange={(t)=> onTitleChange(t, 'mobile')}
            text={shortTitle}
            style={{flexGrow:'2', padding: '0 14px', maxWidth: '900px'}}
            placeholder='Add Tab Text (20 characters max)'
            inputKey={'admin__subnavShortTitle'}
          />
        </div>
        {isHost && deleteBtn && (shortTitle === '') && <p style={{color: 'red', margin: '0px'}}>Required</p>}
      </>
      )}
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: index !== 0 ? 'space-between' : 'flex-end',
      }}>
      { (index !== 0 && index !== 4) &&
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <select
            style={{width:'250px', marginRight: '28px'}}
            label='Entity'
            value={currentEntity}
            onChange={onEntityChange}
          >
            <option value={'guide'}>Travel Guide</option>
            <option value={'article'}>Magazine Article</option>
          </select>
          <ZAutoComplete
            autoComplete={ onSearch }
            onSelect={ onSelect }
            style={{marginRight: '28px', width: '450px'}}
            placeholder={inputPlaceholder}
            inputKey={'entity_search'}
            returnsObject
          />
        </div>
      }
      { index === 4 &&
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <select
            style={{width:'250px', marginRight: '28px'}}
            label='Entity'
            value={currentEntity}
            onChange={onEntityChange}
          >
            <option value={'company_categories'}>Travel Company Landing Page</option>
          </select>
          <ZAutoComplete
            autoComplete={ onSearch }
            onSelect={ onSelect }
            style={{marginRight: '28px', width: '450px'}}
            placeholder={inputPlaceholder}
            inputKey={'entity_search'}
            returnsObject
          />
        </div>
      }
      { (deleteBtn && isHost && (title !== '' && shortTitle !== "")) &&
        <Button
          onClick={onUpdateClick}
          text={'UPDATE'}
          btnStyle={{
            width:'90px',
            maxHeight: '30px',
            fontSize: '14px',
            margin: '0 100px 0 0',
          }}
      />
      }
      { !deleteBtn &&
        <TooltipButton
          onClick={onAddClick}
          disabled={disableAdd}
        />
      }
      { (deleteBtn && !isHost) &&
        <Button
          onClick={onDeleteClick}
          text={'DELETE'}
          btnStyle={{
            width:'90px',
            maxHeight: '30px',
            fontSize: '14px',
            margin: '0 100px 0 0',
          }}
        />
      }
      </div>
    </div>
  </div>
);

const TooltipButton = ({
  onClick,
  disabled,
}) => {
  const [hoverState, setHoverState] = useState(false);

  const autoHideTimer = useRef();

  const setHideTimer = useCallback(() => {
    if(!disabled){
      return null;
    };
    clearTimeout(autoHideTimer.current);
    autoHideTimer.current = setTimeout(()=>{
      setHoverState(false);
    }, 1000);
  }, [disabled]);

  useEffect(()=>{
    if(hoverState){
      setHideTimer();
    }
    return () =>{
      clearTimeout(autoHideTimer.current);
    }
  }, [setHideTimer, hoverState]);

  const mouseEnter = () => {
    setHoverState(true);
  }
  const mouseLeave = () => {
    setHoverState(false);
  }

  return (
    <div
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      style={{
        position: 'relative',
      }}
    >
      <Button
        onClick={onClick}
        text={'ADD'}
        disabled={disabled}
        btnStyle={{
          width:'90px',
          maxHeight: '30px',
          fontSize: '14px',
          margin: '0 100px 0 0',
          cursor: disabled ? 'not-allowed' : 'inherit',
        }}
      />
      { (disabled && hoverState) &&
        <div
          style={{
            display: 'block',
            position: 'absolute',
            backgroundColor: 'black',
            borderRadius: '3px',
            fontSize: '16px',
            color: 'white',
            opacity: '0.3',
            width: '150px',
            bottom: '-25px'
          }}
        >
          Add Host Tab First
        </div>
      }
    </div>
  )
}

export default SubnavTab;
