import ZApiClient from "../client/z-api-client";

const EntitySearchClient = ZApiClient.getPublishableEntitySearchesClient();
const EntitiesClient = ZApiClient.getPublishableEntitiesClient();
const LIMIT = 20;

export const createFilterObject = (values, field) => ({
  field,
  values: values || [],
});

export const getValueFromFilter = (filterObject) => {
  const {field, values} = filterObject;
  return [values, field];
}

export const createCompanyTermSearch = (phrase, sortOrders = [], tags = undefined, filters = [], limit = LIMIT) => {
  const tagFilter = {field: 'tags', values: [tags]};
  const entityFilter = { field: "entityType", values: ["CompanyCategory"]};
  const filterParams = [
    ...filters,
    entityFilter,
  ];
  if (tags) filterParams.push(tagFilter);
  const searchParams = {
    phrase,
    limit,
    filters: filterParams,
    sortOrders,
  };
  return EntitySearchClient.createSearch(searchParams);
};

export const createLandingPageCategoryTermSearch = (phrase, sortOrders = [], tags = undefined, filters = [], limit = LIMIT) => {
  const tagFilter = {field: 'tags', values: [tags]};
  const entityFilter = { field: "entityType", values: ["LandingPageCategory"]};
  const filterParams = [
    ...filters,
    entityFilter,
  ];
  if (tags) filterParams.push(tagFilter);
  const searchParams = {
    phrase,
    limit,
    filters: filterParams,
    sortOrders,
  };
  return EntitySearchClient.createSearch(searchParams);
};

export const createArticleTermSearch = (phrase, sortOrders = [], tags = undefined, filters = [], limit = LIMIT) => {
  const tagFilter = {field: 'tags', values: [tags]};
  const entityFilter = { field: "entityType", values: ["Article"]};
  const filterParams = [
    ...filters,
    entityFilter,
  ];
  if (tags) filterParams.push(tagFilter);
  const searchParams = {
    phrase,
    limit,
    filters: filterParams,
    sortOrders,
  };
  return EntitySearchClient.createSearch(searchParams);
};

export const createGuideTermSearch = (phrase, sortOrders = [], tags = undefined, filters = [], limit = LIMIT) => {
  const tagFilter = {field: 'tags', values: [tags]};
  const entityFilter = { field: "entityType", values: ["Guide"]};
  const filterParams = [
    ...filters,
    entityFilter,
  ];
  if (tags) filterParams.push(tagFilter);
  const searchParams = {
    phrase,
    limit,
    filters: filterParams,
    sortOrders,
  };
  return EntitySearchClient.createSearch(searchParams);
};

export const createItineraryTermSearch = (phrase, sortOrders = [], tags = undefined, filters = [], limit = LIMIT) => {
  const tagFilter = {field: 'tags', values: [tags]};
  const entityFilter = { field: "entityType", values: ["Itinerary"]};
  const filterParams = [
    ...filters,
    entityFilter,
  ];
  if (tags) filterParams.push(tagFilter);
  const searchParams = {
    phrase,
    limit,
    filters: filterParams,
    sortOrders,
  };
  return EntitySearchClient.createSearch(searchParams);
};

export const createLandingPageTermSearch = (phrase, sortOrders = [], tags = undefined, filters = [], limit = LIMIT) => {
  const tagFilter = {field: 'tags', values: [tags]};
  const entityFilter = { field: "entityType", values: ["LandingPage"]};
  const filterParams = [
    ...filters,
    entityFilter,
  ];
  if (tags) filterParams.push(tagFilter);
  const searchParams = {
    phrase,
    limit,
    filters: filterParams,
    sortOrders,
  };
  return EntitySearchClient.createSearch(searchParams);
};

export const createContentTermSearch = (phrase, sortOrders = [], tags = undefined, filters = [], limit = LIMIT) => {
  const tagFilter = {field: 'tags', values: [tags]};
  const entityFilter = { field: "entityType", values: ["Content"]};
  const filterParams = [
    ...filters,
    entityFilter,
  ];
  if (tags) filterParams.push(tagFilter);
  const searchParams = {
    phrase,
    limit,
    filters: filterParams,
    sortOrders,
  };
  return EntitySearchClient.createSearch(searchParams);
};

export const createGenericSearchTerm = (entityType, phrase, sortOrders = [], tags = undefined, filters = [], limit = LIMIT) => {
  if (entityType) {
    switch(entityType.toLowerCase()) {
      case 'article':
        return createArticleTermSearch(phrase, sortOrders, tags, filters, limit);
      case 'guide':
        return createGuideTermSearch(phrase, sortOrders, tags, filters, limit);
      case 'itinerary':
        return createItineraryTermSearch(phrase, sortOrders, tags, filters, limit);
      case 'landingpage':
        return createLandingPageTermSearch(phrase, sortOrders, tags, filters, limit);
      case 'companycategory':
        return createCompanyTermSearch(phrase, sortOrders, tags, filters, limit);
      case 'landingpagecategory':
        return createLandingPageCategoryTermSearch(phrase, sortOrders, tags, filters, limit);
      case 'content':
        return createContentTermSearch(phrase, sortOrders, tags, filters, limit);
      default:
        return null;
    }
  }
  return null;
};

export const createGenericSearchTermCounts = (phrase, filters = [], limit = LIMIT) => {
  const searchParams = {
    phrase,
    limit,
    filters,
    countField: 'entityType',
  };
  return EntitySearchClient.createCountSearch(searchParams);
};

export const getSearch = (id, offset, limit = LIMIT) => {
  const searchParams = {
    limit,
    offset,
  };
  return EntitySearchClient.getSearch(id, searchParams);
};

export const getTags = (params = {}) => {
  return EntitiesClient.getEntityTags(params);
};

export const getArticleTags = () => {
  return getTags({entity: 'Article'});
};

export const getGuideTags = () => {
  return getTags({entity: 'Guide'});
};

export const getLandingPageTags = () => {
  return getTags({entity: 'LandingPage'});
}

export const getItineraryTags = () => {
  return getTags({entity: 'Itinerary'});
}

export const getCompanyTags = () => {
  return getTags({entity: 'CompanyCategory'});
}

export const getLandingPageCategoryTags = () => {
  return getTags({entity: 'LandingPageCategory'});
}

export const getContentTags = () => {
  return getTags({entity: 'Content'});
}
