import React from 'react';
import SubnavTab from './subnav-tab-container';
import ZButton from '../../z-button';

const SubnavView = ({
  tabLinks,
  onAdd,
  onDelete,
  navErrors,
  showDelete,
  deleteSubnav
}) => (
  <>
    <div style={{margin: '14px', backgroundColor: '#fff', padding: '14px'}}>
      {tabLinks.map((ele, i) =>
        <SubnavTab
          isHost={i === 0}
          key={ele.entityId ? ele.entityId : `tablink-${i}`}
          index={i}
          tab={ele}
          onAdd={onAdd}
          navErrors={navErrors}
          onDelete={onDelete}
        />
      )}
    </div>
    { showDelete && 
      <div style={{marginTop: '58px'}}>
        <ZButton
          text={'Remove Sub Nav from LTL'}
          onClick={deleteSubnav}
        />
      </div>
    }
  </>
);




export default SubnavView;
