import React from 'react';
import styles from './button.module.scss';

const defaultProps = {
  disabled: false,
  style: {},
  className: '',
};

const Button = ({
  children,
  disabled,
  onClick,
  style,
  className,
}) => (
  <button
    className={`${styles.button} ${className}`}
    disabled={disabled}
    onClick={onClick}
    onKeyDown={(e) => { if (e.nativeEvent.code === 'Enter' && !!onClick) onClick(e); }}
    style={style}
    type="button"
    tabIndex={0}
  >
    <span
      className={styles.button__inner}
      tabIndex={-1}
    >
      {children}
    </span>
  </button>
);

Button.defaultProps = defaultProps;

export default Button;
