import config from '../../../config';
import { getJSON, postJSON, putJSON, deleteJSON } from "../../../utils/fetch/fetchJson";

const apiUrl = config.API_URL;

export const TEAM_MEMBERS_REQUEST = 'TEAM_MEMBERS_REQUEST';
const teamMembersRequest = (isFetching) => ({
  type: TEAM_MEMBERS_REQUEST,
  isFetching,
});

export const TEAM_MEMBERS_ERROR = 'TEAM_MEMBERS_ERROR';
const teamMembersError = (error) => ({
  type: TEAM_MEMBERS_ERROR,
  error,
});

export const TEAM_MEMBERS_RECEIVED = 'TEAM_MEMBERS_RECEIVED';
const teamMembersReceived = (allTeamMembers) => ({
  type: TEAM_MEMBERS_RECEIVED,
  receivedAt: Date.now(),
  allTeamMembers,
});

export const TEAM_MEMBER_SAVE_REQUEST = 'TEAM_MEMBER_SAVE_REQUEST';
export const saveTeamMemberRequest = (id, isSaving) => ({
  type: TEAM_MEMBER_SAVE_REQUEST,
  id,
  isSaving,
});

export const SET_TEAM_MEMBER = 'SET_TEAM_MEMBER';
export const setTeamMember = (teamMember) => ({
  type: SET_TEAM_MEMBER,
  teamMember,
});

export const TEAM_MEMBER_SAVE_ERROR = 'TEAM_MEMBER_SAVE_ERROR';
export const saveError = (id, error) => ({
  type: TEAM_MEMBER_SAVE_ERROR,
  id,
  error,
});

export const SET_DISPLAYED = 'SET_TEAM_MEMBER_DISPLAYED';
export const setDisplayed = (id, displayed) => ({
  type: SET_DISPLAYED,
  id,
  displayed,
});


export const SET_JOB_TITLE = 'SET_JOB_TITLE';
export const setJobTitle = (id, title) => ({
  type: SET_JOB_TITLE,
  id, 
  title,
});

export const SET_TEAM_MEMBER_NAME = 'SET_TEAM_MEMBER_NAME';
export const setTeamMemberName = (id, name) => ({
  type: SET_TEAM_MEMBER_NAME,
  id,
  name,
});

export const SET_TEAM_MEMBER_TEXT = 'SET_TEAM_MEMBER_TEXT';
export const setTeamMemberText = (id, text) => ({
  type: SET_TEAM_MEMBER_TEXT,
  id,
  text,
});

export const SET_TEAM_MEMBER_PHOTO = 'SET_TEAM_MEMBER_PHOTO';
export const setTeamMemberPhoto = (id, photo) => ({
  type: SET_TEAM_MEMBER_PHOTO,
  id,
  photo,
});

export const CREATE_NEW_TEAM_MEMBER = 'CREATE_NEW_TEAM_MEMBER';
export const createNew = () => ({
  type: CREATE_NEW_TEAM_MEMBER,
});

export const fetchTeamMembers = () => (dispatch) => {
  dispatch(teamMembersRequest(true));
  getJSON(`${apiUrl}/team_members?sorted=true&limit=1000`)
    .then((res) => {
      if (res.httpStatus){
        throw new Error(res.rootCause);
      }
      dispatch(teamMembersReceived(res));
    })
    .catch(() => dispatch(teamMembersError(true)))
    .finally(() => dispatch(teamMembersRequest(false)));
};

export const saveTeamMember = (teamMemberId, onSaveSuccess) => {
  return (dispatch, getState) => {
    dispatch(saveTeamMemberRequest(teamMemberId, true));
    const shouldCreate = teamMemberId === 'new';
    const { teamMembers } = getState();
    const teamMemberEntity = teamMembers[teamMemberId];
    createOrUpdateTeamMember(teamMemberEntity, shouldCreate)
      .then((res) => {
        if (res.httpStatus){
          throw new Error(res.rootCause);
        }
        dispatch(setTeamMember(res));
      })
      .then(() => {
        dispatch(fetchTeamMembers());
        if (onSaveSuccess) onSaveSuccess();
      })
      .catch((e) => {
        console.error('Error Saving Team Member', e);
        console.error(e.stack);
        dispatch(saveError(teamMemberId, true));
      })
      .finally(() => dispatch(saveTeamMemberRequest(teamMemberId, false)));
  };
};

const createOrUpdateTeamMember = (data, create = true) => {
  if (create) { 
    return postJSON(`${apiUrl}/team_members`, data);
  } else {
    return putJSON(`${apiUrl}/team_members/${data.id}`, data);
  }
};

export const deleteTeamMember = (id) => {
  return deleteJSON(`${apiUrl}/team_members/${id}`)
    .then((res) => {
      if (res.httpStatus) {
        return 'team member doesnt exist';
      }
      return null;
    })
    .catch((e) => {
      console.error(e);
    });
};
