import React from 'react';
import ZVersions from '../../z-versions';
import ZPublish from '../../z-publish';

const VersionsView = ({
  actions, id, modalState, published, onCloseModal,
  onClickUnpublish, saved, showModal, usageReferences,
}) => (
  <>
  <ZVersions
    actions={actions}
    entityId={id}
    entityType="articles"
    onClickUnpublish={onClickUnpublish}
    published={published}
    saved={saved}
  />
  {showModal && (
    <ZPublish
      entityId={id}
      entityType="Article"
      modalState={modalState}
      onCloseModal={onCloseModal}
      showModal={showModal}
      usageReferences={usageReferences}
      usageReferencesError={(
        <>
          <h2>This article is being used.</h2>
          <p>
            Please go to the Usage References tab.
            <br/>
            Visit the pages listed and remove it from the list of articles or the navigation bar.
          </p>
        </>
      )}
    />
  )}
  </>
);

export default VersionsView;
