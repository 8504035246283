import React from 'react';
import ZSpinner from '../z-spinner';
import ZTable from '../z-table';
import ZVersionsRow from './z-versions-row';
import styles from './z-versions.module.scss';
import sharedStyles from '../shared/styles/shared.module.scss';

const ZVersionsView = ({
  actions,
  items,
  loading,
  offset,
  onActionClick,
  onClickLoadMore,
  onClickUnpublish,
  saved,
}) => (
  <div className={styles.versions}>
    <h2 className={styles.title}>Versions</h2>
    {!saved && (
      <h3 className={styles['unsaved-warning']}>
        You have unsaved changes. Please save to publish your most recent work.
      </h3>
    )}
    {!!items && (
      <ZTable headers={['version', 'status', 'date saved', 'saved by', 'actions']} rowWrapperStyle={{height: '70vh'}}>
        {items.map((item, i) => (
          <ZVersionsRow
            actions={actions}
            data={item}
            index={i}
            key={item.version}
            legend={[ 'version', 'published', 'timestamp', 'user']}
            onActionClick={onActionClick}
            onClickUnpublish={onClickUnpublish}
            selectFieldIndex={1}
          />
        ))}     
      </ZTable>
    )}
    {loading && (
      <div className={styles.centered}>
        <ZSpinner />
      </div>
    )}
    <div className={styles.centered}>
      {(!loading && !!offset) && (
        <button className={sharedStyles.button} onClick={onClickLoadMore} type="button">Load More</button>
      )}
    </div>
  </div>
);

export default ZVersionsView;
