/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from './body-scroll-lock';
import ZModalView from './z-modal-view';

const ZModal = ({
  children, closeable, disableScrollLock, maxHeight, open,
  portalContainer, style, toggleModal, useOverlay, usePortal,
}) => {

  useEffect(() => {
    if (!disableScrollLock) {
      const targetElement = document.getElementById('targetElement');
      if (open) {
        disableBodyScroll(targetElement);
      }
      return () => {
        enableBodyScroll(targetElement);
        clearAllBodyScrollLocks();
      }
    }
  }, [disableScrollLock, open]);

  const onOverlayClick = (e) => {
    if (e.target.id === 'z-modal-overlay') {
      if (closeable) toggleModal();
    }
  };

  return open ? (
    <ZModalView
      maxHeight={maxHeight}
      onOverlayClick={onOverlayClick}
      open={open}
      useOverlay={useOverlay}
      portalContainer={portalContainer}
      style={style}
      usePortal={usePortal}
    >
      {children}
    </ZModalView>
  ) : null;
};

ZModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array,
  ]).isRequired,
  closeable: PropTypes.bool,
  disableScrollLock: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  portalContainer: PropTypes.object,
  style: PropTypes.object,
  toggleModal: PropTypes.func,
  useOverlay: PropTypes.bool,
  usePortal: PropTypes.bool,
};

ZModal.defaultProps = {
  closeable: false,
  disableScrollLock: false,
  portalContainer: null,
  style: {},
  toggleModal: () => {},
  useOverlay: false,
  usePortal: false,
};

export default ZModal;
