import React from 'react';
import PropTypes from 'prop-types';
import styles from './selected-rating.module.scss';

const SelectedRatingView = ({
  clearSelectedRating,
  selectedRating,
}) => (
  <div className={styles['selected']}>
    <p className={styles['selected__rating']}>
      Showing&nbsp;
      <span>
        {selectedRating / 20}
        &nbsp;star
      </span>
      &nbsp;reviews
    </p>
    &bull;
    <button
      className={styles['selected__clear']}
      onClick={clearSelectedRating}
      onKeyUp={(e) => { if (e.code === 'Enter' || e.code === 'NumpadEnter') clearSelectedRating(); }}
      type="button"
    >
      Show all reviews
    </button>
  </div>
);
SelectedRatingView.propTypes = {
  clearSelectedRating: PropTypes.func.isRequired,
  selectedRating: PropTypes.number.isRequired,
};

export default SelectedRatingView;
