import { connect } from 'react-redux';
import NewsSourceContainer from './news-source-container';
import { setSource } from '../../../../state/actions/news-editor/news-snippets';
import {
  fetchNewsSourceNameByFragment,
  fetchNewsSource,
  createNew,
  setNewsSource,
} from '../../../../state/actions/news-editor/news-sources';

const mapStateToProps = (state, ownProps) => ({
  newsSource: state.news.newsSnippets[ownProps.newsSnippetId]?.newsSource || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createNew: () => dispatch(createNew()),
  fetchNewsSourceNames: (fragment) => dispatch(fetchNewsSourceNameByFragment(fragment)),
  fetchNewsSource: (id) => dispatch(fetchNewsSource(id)),
  setNewsSource: (newsSource) => dispatch(setNewsSource(newsSource)),
  setSnippetSource: (val) => dispatch(setSource(ownProps.newsSnippetId, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsSourceContainer);
