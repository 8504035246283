import React, { useEffect, useRef} from 'react';
import ZApiClient from '../../../utils/client/z-api-client';
import ContentView from './content-view';


const ContentContainer = ({
  currentEntity,
  onChangePhoto,
  onCreateHeaderPhoto,
  onDeleteHeaderPhoto,
  onSummaryChange,
  onTitleFlagChange,
  photos,
}) => {
  const isoCodesRef = useRef({});

  useEffect(()=> {
    const getIsoCodes = () => {
      return ZApiClient
      .getGeoNamesClient()
      .getCountries({ sorted: true, fields: ['country,isoCode'] })
      .then((res) => {
        const countries = res.reduce(
          (countriesMap, fullCountry) => {
            const { isoCode, country } = fullCountry;
            if (isoCode && country) {
              // eslint-disable-next-line no-param-reassign
              countriesMap[isoCode] = country;
            }
            return countriesMap;
          },
          {},
        );
        return countries;
      })
    }

    async function setCodes () {
      const isCodeMap = await getIsoCodes();
      isoCodesRef.current = isCodeMap;
    }

    setCodes();
  }, []);

  const autoComplete = (text) => {
    return new Promise((resolve) => {
      const allOptions = isoCodesRef.current;
      let options = {}
      for (const option in allOptions) {
        if (allOptions.hasOwnProperty(option)) {
          const test = allOptions[option].toLowerCase();
          if (test.includes(text.toLowerCase())) {
            options[option] = allOptions[option];
          }
        }
      }
      return resolve(options);
    })
  }

  const onTitleFlagSelect = (option) => {
    const [isoCode] = Object.keys(option);
    onTitleFlagChange(isoCode);
  }

  return(
    <ContentView
      autoComplete={autoComplete}
      flagCode={currentEntity.flagCountryCode}
      headerPhotoId={currentEntity.headerPhotoId}
      id={currentEntity.id}
      onChangePhoto={onChangePhoto}
      onCreateHeaderPhoto={onCreateHeaderPhoto}
      onDeleteHeaderPhoto={onDeleteHeaderPhoto}
      onSummaryChange={onSummaryChange}
      onTitleFlagChange={onTitleFlagChange}
      onTitleFlagSelect={onTitleFlagSelect}
      photos={photos}
      summary={currentEntity.summary}
    />
  );
}

export default ContentContainer;
