import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ZTitleFlag from '../../z-title-flag';
import { ReactComponent as GlobeIcon } from '../../../assets/inline/globe.svg';
import styles from './destination-flags.module.scss';

const DestinationFlagsView = ({
  className,
  destinations,
  maxDestinations,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`${styles['destinations']} ${className}`}>
      {useMemo(() => (
        destinations.slice(0, (!open && maxDestinations) || destinations.length).map((country) => (
          <div className={styles['destination']} key={country.isoCode}>
            <div className={styles['destination__flag--wrapper']}>
              {country.isoCode ? (
                <ZTitleFlag
                  className={styles['destination__flag']}
                  isoCode={country.isoCode}
                  title={country.name || 'country flag'}
                />
              ) : (
                <GlobeIcon />
              )}
            </div>
            {country.name}
          </div>
        ))
      ), [destinations, maxDestinations, open])}
      { (!open && destinations.length > maxDestinations) && (
        <span
          onClick={() => setOpen(!open)}
          onKeyDown={(e) => e.code === 'Enter' && setOpen(!open)}
          role="button"
          tabIndex={0}
        >
          +&nbsp;
          { destinations.length - maxDestinations }
          &nbsp;
          More
        </span>
      )}
    </div>
  );
};
DestinationFlagsView.propTypes = {
  className: PropTypes.string,
  destinations: PropTypes.array.isRequired,
  maxDestinations: PropTypes.number,
};
DestinationFlagsView.defaultProps = {
  className: '',
  maxDestinations: 16,
};

export default DestinationFlagsView;
