import React, { useRef, useEffect, useCallback } from 'react';
import ZToastView from './z-toast-view';

const AutoHideDuration = 1000;

const ZToastContainer  = ({ 
  message, 
  open, 
  onClose,
}) => {
  const autoHideTimer = useRef();

  const setHideTimer = useCallback(() => {
    if(!onClose){
      return null;
    };
    clearTimeout(autoHideTimer.current);
    autoHideTimer.current = setTimeout(()=>{
      onClose();
    },AutoHideDuration);
  }, [onClose]);

  useEffect(()=>{
    if(open){
      setHideTimer();
    }
    return () =>{
      clearTimeout(autoHideTimer.current);
    }
  }, [open, setHideTimer]);

  
  if(!open){
    return null
  }
  return(
    <ZToastView
      message={message}
    />
  );
};

export default ZToastContainer;
