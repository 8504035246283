import React from 'react';
import style from './appearance.module.scss';

const AppearanceView = ({
  showAuthor,
  onShowAuthorChange,
  showDate,
  onShowDateChange,
  includeToC,
  onIncludeToCChange,
}) => (
  <>
    <br/>
    <h3>Appearance</h3>
    <div className={style['appearance-wrapper']}>
      <div className={style['checkbox-item']}>
        <label 
          htmlFor='show-author'
        >
          Show Author
        </label>
        <input 
          type='checkbox'
          id='show-author' 
          className={style['checkbox']} 
          value='showAuthor'
          name='type'
          onChange={()=>{onShowAuthorChange(!showAuthor)}}
          checked={showAuthor}
        />
      </div>
      <div className={style['checkbox-item']}>
        <label 
          htmlFor='show-date'
        >
          Show Published Date
        </label>
        <input 
          type='checkbox'
          id='show-date' 
          className={style['checkbox']} 
          value='showDate'
          name='type'
          onChange={()=>{onShowDateChange(!showDate)}}
          checked={showDate}
        />
      </div>
      <div className={style['checkbox-item']}>
        <label 
          htmlFor='show-ToC'
        >
          Show Table of Contents
        </label>
        <input 
          type='checkbox'
          id='show-ToC' 
          className={style['checkbox']} 
          value='includeToC'
          name='type'
          onChange={()=>{onIncludeToCChange(!includeToC)}}
          checked={includeToC}
        />
      </div>
    </div>
  </>
);

export default AppearanceView;
