import React, { useState } from 'react';
import sharedStyle from '../shared/styles/pages-list.module.scss';
import ZSelect from '../z-select';
import ImagePreviewModal from './image-preview-modal';

const GravityDropdownView = ({
  gravity,
  imgSrc,
  onChange,
  photoId,
  readOnly,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <>
      <ZSelect
        label='Gravity'
        initialValue={gravity}
        onChange={onChange('gravity')}
        inputKey={`${photoId}__gravity`}
        readOnly={readOnly && readOnly.gravity ? readOnly.gravity : false}
        options={[
          'auto',
          'north',
          'north_east',
          'east',
          'south_east',
          'south',
          'south_west',
          'west',
          'north_west',
          'center',
        ]}
        style={{marginLeft: 8}}
      />
      <div 
        type="button"
        className={sharedStyle['pages-list__button']}
        style={{marginLeft: 8, padding:10}}
        onClick={togglePreview}
      >
        Preview
      </div>
      <ImagePreviewModal
        gravity={gravity}
        previewSrc={imgSrc}
        showModal={showPreview}
        toggleModal={togglePreview}
      />
    </>
  );
};

export default GravityDropdownView;
