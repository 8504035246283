import React, { useEffect, useState } from 'react';
import urlRegex from '../../../utils/validate/urlRegex';
import CareerEditorView from './career-editor-view';

const CareerEditorContainer = ({
  closeModal,
  id,
  career: {
    displayed,
    error,
    isSaving,
    title,
    url,
    location,
  },
  onClickEdit,
  saveCareer,
  setDisplayed,
  setLocation,
  setUrl,
  setTitle,
  deleteCareer,
  fetchCareers,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [deleteError, setDeleteError] = useState(false);
  const [urlError, setUrlError] = useState(false);

  useEffect(() => {
    const disabled = !location || !title || url ? !urlRegex().test(url) : false;
    setDisabled(disabled);
    if( !url || !urlRegex().test(url) ) {
      setDisplayed(false);
    }
  }, [location, title, url, setDisplayed]);

  useEffect(() => {
    const validUrl = urlRegex().test(url)
    if (!url) {
      setUrlError(false);
    }
    if (url && !validUrl) {
      setUrlError(true);
    }
    if (url && validUrl) {
      setUrlError(false);
    }
  }, [url]);

  const handleChangeDisplayed = (e) => {
    if( url && urlRegex().test(url) ) {
      setDisplayed(e.target.checked);
    }
  };

  const handleClickSave = () => {
    saveCareer();
  };

  const handleClickDelete = () => {
    if (window.confirm('Are you sure you want to delete this career?')) {
      deleteCareer()
        .then((res) => {
          if (res) {
            setDeleteError(true);
          } else {
            setDeleteError(false);
            closeModal();
            fetchCareers();
          }
        });
    }
  }

  return (
    <CareerEditorView
      closeModal={closeModal}
      saveDisabled={disabled}
      displayed={displayed}
      error={error}
      id={id}
      isSaving={isSaving}
      onChangeDisplayed={handleChangeDisplayed}
      onClickEdit={onClickEdit}
      onClickSave={handleClickSave}
      setTitle={setTitle}
      setLocation={setLocation}
      setUrl={setUrl}
      title={title}
      location={location}
      url={url}
      onClickDelete={handleClickDelete}
      deleteError={deleteError}
      urlError={urlError}
    />
  );
};

export default CareerEditorContainer;
