import React from 'react';
import { connect } from 'react-redux';
import {
  setDepartureNotes,
  setPrice,
  setPriceComments,
  setIncludedNotes,
  setShowPrice,
} from '../../../state/actions/itinerary-editor';
import DatesPriceView from './dates-price-view';

const mapStateToProps = (state, ownProps) => {
  const {entityId: id} = ownProps;
  const { errors } = state;
  const { editor } = state.itineraries.itinerary;
  const currentEntity = editor[id] || {};
  const currentErrors = errors[id] || {};
  const {
    priceComments: priceCommentsErrors,
    includedNotes: includedNotesErrors,
    departureNotes: departureNotesError,
  } = currentErrors;
  const {departureNotes} = currentEntity;
  return {
    startingPrice: currentEntity.price || '',
    priceComments: currentEntity.priceComments,
    includedNotes: currentEntity.includedNotes,
    showPrice: currentEntity.showPrice,
    priceCommentsErrors,
    includedNotesErrors,
    departureNotesError,
    departureNotes,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId: id} = ownProps;
  return {
    onUpdateStatingPrice : (price) => dispatch(setPrice(price, id)),
    onUpdatePriceComments: (text) => dispatch(setPriceComments(text, id)),
    onUpdateIncludedNotes: (text) => dispatch(setIncludedNotes(text, id)), 
    onUpdateDeparture: (text) => dispatch(setDepartureNotes(text,id)),
    onChangeShowPrice: (value) => dispatch(setShowPrice(value, id)),
  }
}

const DatesPriceRedux = ({
  onUpdateDeparture,
  departureNotesError,
  departureNotes,
  startingPrice,
  priceComments,
  includedNotes,
  showPrice,
  priceCommentsErrors,
  includedNotesErrors,
  onUpdateStatingPrice,
  onUpdatePriceComments,
  onUpdateIncludedNotes,
  onChangeShowPrice
}) => {
  return (
    <DatesPriceView
      onUpdateDeparture={onUpdateDeparture}
      departureNotesError={departureNotesError}
      departureNotes={departureNotes}
      startingPrice={startingPrice}
      priceComments={priceComments}
      includedNotes={includedNotes}
      showPrice={showPrice}
      priceCommentsErrors={priceCommentsErrors}
      includedNotesErrors={includedNotesErrors}
      onUpdateStatingPrice={onUpdateStatingPrice}
      onUpdatePriceComments={onUpdatePriceComments}
      onUpdateIncludedNotes={onUpdateIncludedNotes}
      onChangeShowPrice={onChangeShowPrice}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DatesPriceRedux);
