import React from 'react';
import { connect } from 'react-redux';
import {
  setAuthorName,
  setAuthorLink
} from '../../../../state/actions/article-editor';
import AuthorContainer from './author-container';

const AuthorRedux = (props) => (
  <AuthorContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {articleId:id} = ownProps;
  const articleEntities = state.articles.article;
  const {editor} = articleEntities;
  const currentArticle = editor[id]
  const { authorName, authorLink } = currentArticle;
  return({
    authorName,
    authorLink
  });
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {articleId:id} = ownProps;
  return({
    onAuthorNameChange: (authorName) => dispatch(setAuthorName(authorName, id)),
    onAuthorLinkChange: (authorLink) => dispatch(setAuthorLink(authorLink, id)),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorRedux);
