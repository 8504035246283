import React, { useEffect, useState } from 'react';
import ZSpinner from '../z-spinner';
import TrfSettingsView from './trf-settings-view';
import styles from './trf-settings.module.scss';
import { getTripRequestAnnouncement, postTripRequestAnnouncement } from '../../utils/trip-requests';

const TrfSettingsContainer = () => {
  const [shouldSave, setShouldSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [announcementText, setAnnouncementText] = useState('');
  const [trfSettings, setTrfSetting] = useState();

  useEffect(() => {
    getTripRequestAnnouncement()
      .then((res) => {
        const {announcement, active} = res;
        setAnnouncementText(announcement);
        setShowAnnouncement(active);
        setTrfSetting(res);
      });
  }, []);

  const onTextChange = (text) => {
    setAnnouncementText(text);
    setShouldSave(true);
  };

  const onCheckboxChange = (e) => {
    const {
      target: {checked}
    } = e;
    setShowAnnouncement(checked);
    setShouldSave(true);
  };

  const onSave = () => {
    setIsSaving(true);
    const body = {
      announcement: announcementText,
      active: showAnnouncement,
    };
    postTripRequestAnnouncement(body)
      .then(() => {
        setIsSaving(false);
        setShouldSave(false);
      });
  }

  if (!trfSettings) {
    return (
      <div className={styles['page-spinner']}>
        <ZSpinner />
      </div>
    )
  }

  return (
    <TrfSettingsView
      announcementText={announcementText}
      showAnnouncement={showAnnouncement}
      shouldSave={shouldSave}
      isSaving={isSaving}
      onCheckboxChange={onCheckboxChange}
      onTextChange={onTextChange}
      onSave={onSave}
    />
  );
}

export default TrfSettingsContainer;

