import ZApiClient from '../../../utils/client/z-api-client';
import { NewsSnippet } from '@zicasso/zicasso-sdk/lib/news';

export const NEWS_SNIPPETS_REQUEST = 'NEWS_SNIPPETS_REQUEST';
const newsSnippetsRequest = (isFetching) => ({
  type: NEWS_SNIPPETS_REQUEST,
  isFetching,
});

export const NEWS_SNIPPETS_ERROR = 'NEWS_SNIPPETS_ERROR';
const newsSnippetsError = (error) => ({
  type: NEWS_SNIPPETS_ERROR,
  error,
});

export const NEWS_SNIPPETS_RECEIVED = 'NEWS_SNIPPETS_RECEIVED';
const newsSnippetsReceived = (allNewsSnippets) => ({
  type: NEWS_SNIPPETS_RECEIVED,
  receivedAt: Date.now(),
  allNewsSnippets,
});

export const NEWS_SNIPPET_SAVE_REQUEST = 'NEWS_SNIPPET_SAVE_REQUEST';
export const saveRequest = (id, isSaving) => ({
  type: NEWS_SNIPPET_SAVE_REQUEST,
  id,
  isSaving,
});

export const SET_NEWS_SNIPPET = 'SET_NEWS_SNIPPET';
export const setNewsSnippet = (newsSnippet) => ({
  type: SET_NEWS_SNIPPET,
  newsSnippet,
});

export const NEWS_SNIPPET_SAVE_ERROR = 'NEWS_SNIPPET_SAVE_ERROR';
export const saveError = (id, error) => ({
  type: NEWS_SNIPPET_SAVE_ERROR,
  id,
  error,
});

export const SET_TITLE = 'SET_TITLE';
export const setTitle = (id, title) => ({
  type: SET_TITLE,
  id,
  title,
});

export const SET_TEASER = 'SET_TEASER';
export const setTeaser = (id, teaser) => ({
  type: SET_TEASER,
  teaser,
  id
});

export const SET_DISPLAYED = 'SET_NEWS_DISPLAYED';
export const setDisplayed = (id, displayed) => ({
  type: SET_DISPLAYED,
  id,
  displayed,
});

export const SET_PUBLICATION_DATE = 'SET_PUBLICATION_DATE';
export const setPublicationDate = (id, publicationDate) => ({
  type: SET_PUBLICATION_DATE,
  id,
  publicationDate,
});

export const SET_URI = 'SET_URI';
export const setUri = (id, uri) => ({
  type: SET_URI,
  id,
  uri,
});

export const SET_SOURCE = 'SET_SOURCE';
export const setSource = (id, newsSource) => ({
  type: SET_SOURCE,
  id,
  newsSource,
});

export const CREATE_NEW = 'CREATE_NEW_SNIPPET';
export const createNew = () => ({
  type: CREATE_NEW,
});

export const fetchNewsSnippets = () => (dispatch) => {
  dispatch(newsSnippetsRequest(true));
  ZApiClient.getNewsSnippetsClient()
    .getNewsSnippets({ limit: 1000 })
    .then((res) => {
      for (let i = 0, len = res.length; i < len; i += 1) {
        // eslint-disable-next-line no-param-reassign
        res[i].sortTime = new Date(res[i].publicationDate).getTime();
      }
      const sortedByPublicationDate = res.sort((a, b) => b.sortTime - a.sortTime);
      dispatch(newsSnippetsReceived(sortedByPublicationDate));
    })
    .catch(() => dispatch(newsSnippetsError(true)))
    .finally(() => dispatch(newsSnippetsRequest(false)));
};

export const saveNewsSnippet = (newsSnippetId, onSaveSuccess) => {
  return (dispatch, getState) => {
    dispatch(saveRequest(newsSnippetId, true));
    const shouldCreate = newsSnippetId === 'new';
    const { newsSnippets } = getState().news;
    const newsSnippetEntity = newsSnippets[newsSnippetId];

    createOrUpdateNewsSnippet(newsSnippetEntity, shouldCreate)
      .then((response) => {
        if (response.httpStatus) {
          throw new Error (response.rootCause);
        }
        dispatch(setNewsSnippet(response));
      })
      .then(() => {
        dispatch(fetchNewsSnippets());
        if (onSaveSuccess) onSaveSuccess();
      })
      .catch((e)=>{
        console.error('Error Saving News Snippet', e);
        console.error(e.stack);
        dispatch(saveError(newsSnippetId, true));
      })
      .finally(() => dispatch(saveRequest(newsSnippetId, false)));
  };
};

const createOrUpdateNewsSnippet = (data, create = true) =>{
  if(create){
    return ZApiClient.getNewsSnippetsClient().createNewsSnippet(new NewsSnippet(data));
  } else {
    return ZApiClient.getNewsSnippetsClient().updateNewsSnippet(new NewsSnippet(data));
  }
};
