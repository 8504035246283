import React, { useEffect } from 'react';
import UsageReferencesView from './usage-references-view';
import Config from '../../config';
import { navigate } from '../../utils/window';
const baseUrlWeb = Config.Z3_URL;

const adminEntity = {
  Article: 'articles',
  CompanyCategory: 'company-categories',
  Guide: 'guides',
  Itinerary: 'itineraries',
  LandingPage: 'landing-pages',
};

const UsageReferencesContainer = ({
  entityId,
  error,
  loading,
  onClearUsageReferences,
  onClearPublicationReferences,
  onFetchUsageReferences,
  onFetchPublicationReferences,
  publicationReferences,
  usageReferences,
}) => {
  useEffect(() => {
    if (!usageReferences) onFetchUsageReferences(entityId);
    if (!publicationReferences) onFetchPublicationReferences(entityId);
  }, [entityId,
    onFetchPublicationReferences,
    onFetchUsageReferences,
    publicationReferences,
    usageReferences]);

  const onClickPreviewUsage = (data) => {
    window.open(`${baseUrlWeb}/dynamic?type=${data.entityType}&id=${data.entityId}`);
  };

  const onClickEditUsage = (data) => {
    navigate(`${adminEntity[data.entityType]}/${data.entityId}`);
  };

  const onClickPreviewPublicationReference = (data) => {
    window.open(`${baseUrlWeb}/dynamic?type=${data.parentEntityType}&id=${data.parentEntityId}`);
  };

  const onClickEditPublicationReference = (data) => {
    navigate(`${adminEntity[data.parentEntityType]}/${data.parentEntityId}`);
  };

  const onClickRefresh = () => {
    onClearUsageReferences(entityId);
    onClearPublicationReferences(entityId);
  }

  const usageReferenceActions = {
    edit: onClickEditUsage,
    preview: onClickPreviewUsage,
  };

  const publicationReferenceActions = {
    edit: onClickEditPublicationReference,
    preview: onClickPreviewPublicationReference,
  };

  const mapTypeToName = (type) => {
    switch(type) {
      case 'Article': return 'Magazine Article';
      case 'Guide': return 'Travel Guide';
      case 'LandingPage': return 'Landing Page';
      case 'Itinerary': return 'Itinerary';
      case 'CompanyCategory': return 'Company Category';
      default: break;
    }
  };

  const getModifiedUsageReferences = () => {
    if (usageReferences) {
      return usageReferences.map(usage => {
        usage.entityName = mapTypeToName(usage.entityType);
        return usage;
      })
    }

    return [];
  };

  const getModifiedPublicationReferences = () => {
    if (publicationReferences) {
      return publicationReferences.map(publicationReference => {
        publicationReference.entityName = mapTypeToName(publicationReference.parentEntityType);
        return publicationReference;
      })
    }

    return [];
  };

  return (
    <UsageReferencesView
      publicationReferenceActions={publicationReferenceActions}
      usageReferenceActions={usageReferenceActions}
      error={error}
      loading={loading}
      onClickRefresh={onClickRefresh}
      publicationReferences={getModifiedPublicationReferences()}
      usageReferences={getModifiedUsageReferences()}
    />
  );
};

export default UsageReferencesContainer;
