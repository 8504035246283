import { combineReducers } from 'redux';
import sectionsT from './sections';
import photosT from './photos';
import metatags from './meta-data';
import {
  SET_TYPE,
  SET_SHOW_DATE,
  SET_SHOW_TOC,
} from '../actions/simple-editor/action-types';
import {
  RECEIVED_PAGE,
  REQUEST_PAGE,
  REQUEST_PAGE_FAILED,
  REQUEST_NEW_PAGE,
  PAGE_SAVED,
  PAGE_SAVE_REQUESTED,
} from '../actions/shared/similar-action-types';
import{
  SET_META_DATA,
  SET_META_DESCRIPTION,
  SET_META_TITLE,
  SET_NOFOLLOW,
  SET_NOINDEX,
  SET_CANONICAL,
} from '../actions/meta-tags';
import {
  BREADCRUMB_ACTION_NAMES
} from "../actions/shared/breadcrumb-action-types";
import {
  ERROR_ACTION_NAMES,
} from '../actions/shared/error-action-types';
import {
  EXPIRATION_ACTION_NAMES
} from '../actions/shared/expiration-action-types';
import {
  LOCATION_ACTION_NAMES
} from '../actions/shared/location-action-types';
import {
  PHOTO_ACTION_NAMES,
} from '../actions/shared/photo-action-types';
import {
  SECTION_ACTION_NAMES,
} from '../actions/shared/section-action-types';
import {
  TEASER_ACTION_NAMES,
} from '../actions/shared/teaser-action-types';
import {
  TITLE_ACTION_NAMES
} from '../actions/shared/title-action-types';

// add item at specific index
const addAtIndex = (arr = [], ele, i) => {
  if(!i){
    return [...arr, ele];
  } else if (i > arr.length) {
    return [...arr, ele];
  } else {
    let res =  [...arr];
    res.splice(i, 0, ele);
    return res;
  }
};

const addIfMissing = (ele, arr = []) => {
  if(arr.indexOf(ele) === -1){
    return [...arr, ele];
  } else {
    return [...arr];
  }
};

const page = (
  state = {
    title: null,
    summary: '',
    type: 'EVERGREEN',
    sectionIds: [],
    metatags: {},
    footerSectionIds: [],
  },
  action
) => {
  switch (action.type) {
    case RECEIVED_PAGE:
      return {
        ...state,
        ...action.page,
      };
    case TITLE_ACTION_NAMES.CONTENT.SET_TITLE:
      return {
        ...state,
        title: action.value,
      };
    case TITLE_ACTION_NAMES.CONTENT.SET_SUMMARY:
      return {
        ...state,
        summary: action.value,
      };
    case SECTION_ACTION_NAMES.CONTENT.ADD_SECTION:
      return {
        ...state,
        sectionIds: addAtIndex(state.sectionIds, action.id, action.index),
      };
    case SECTION_ACTION_NAMES.CONTENT.REMOVE_SECTION:
      return {
        ...state,
        sectionIds : state.sectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.CONTENT.REORDER_SECTIONS:
      return {
        ...state,
        sectionIds : [...action.sectionIds],
      };
    case SECTION_ACTION_NAMES.CONTENT.ADD_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds: addAtIndex(state.footerSectionIds, action.id, action.index)
      };
    case SECTION_ACTION_NAMES.CONTENT.REMOVE_FOOTER_SECTION:
      return {
        ...state,
        footerSectionIds : state.footerSectionIds.filter(ele => ele !== action.id)
      };
    case SECTION_ACTION_NAMES.CONTENT.REORDER_FOOTER_SECTIONS:
      return {
        ...state,
        footerSectionIds : [...action.list],
      };
    case PHOTO_ACTION_NAMES.CONTENT.SET_HEADER_PHOTO:
      return {
        ...state,
        headerPhotoId : action.photo.id
      };
    case PHOTO_ACTION_NAMES.CONTENT.REMOVE_HEADER_PHOTO:
      let {headerPhotoId, heroImage, ...rest} = state;
      return {
        ...rest
      };
    case SET_META_DATA:
    case SET_META_TITLE:
    case SET_META_DESCRIPTION:
    case SET_NOFOLLOW:
    case SET_NOINDEX:
    case SET_CANONICAL:
      return {
        ...state,
        metatags: metatags(state.metatags, action)
      };
    case TEASER_ACTION_NAMES.CONTENT.SET_CTA_TITLE:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          title: action.value,
        },
      };
    case TEASER_ACTION_NAMES.CONTENT.SET_CTA_STEPS:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          steps: action.value,
        },
      };
    case LOCATION_ACTION_NAMES.CONTENT.ADD_LOCATION:
      return {
        ...state,
        locations: addIfMissing(action.value, state.locations),
      };
    case LOCATION_ACTION_NAMES.CONTENT.SET_LOCATIONS:
      return {
        ...state,
        locations: [...action.list]
      };
    case SET_TYPE:
      return {
        ...state,
        type: action.value,
      };
    case EXPIRATION_ACTION_NAMES.CONTENT.SET_EXPIRATION_DATE:
      return {
        ...state,
        expirationDate: action.value,
      };
    case BREADCRUMB_ACTION_NAMES.CONTENT.SET_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle : action.title,
      };
    case BREADCRUMB_ACTION_NAMES.CONTENT.ADD_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.data
      };
    case BREADCRUMB_ACTION_NAMES.CONTENT.DELETE_BREADCRUMBS:
      return {
        ...state,
        breadcrumb: null
      };
    case BREADCRUMB_ACTION_NAMES.CONTENT.DELETE_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle : null,
      };
    case SET_SHOW_DATE:
      return {
        ...state,
        showDate: action.value,
      };
    case SET_SHOW_TOC:
      return {
        ...state,
        includeToC: action.value,
      };
    default:
      return state;
  }
};

const editor = (
  state = {
    isFetching: true,
    isSaving: false,
    saved: true,
    notFound: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_PAGE:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_PAGE_FAILED:
      return {
        ...state,
        isFetching: false,
        notFound: true,
      };
    case REQUEST_NEW_PAGE:
      return{
        ...state,
        isFetching: true,
        saved: false,
      };
    case PAGE_SAVE_REQUESTED:
      return {
        ...state,
        isSaving: true,
        saved: false,
      };
    case PAGE_SAVED:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };
    case LOCATION_ACTION_NAMES.CONTENT.SET_COUNTRY_LIST:
      return {
        ...state,
        countries: action.list,
      };
    case RECEIVED_PAGE:
      return{
        ...state,
        isFetching: false,
        notFound: false,
        lastUpdate: action.receivedAt,
        disabledSaveForDate: false,
        [action.id] : page({
          ...state[action.id],
          ...action.page
        }, action),
      };
    case TITLE_ACTION_NAMES.CONTENT.SET_TITLE:
    case TITLE_ACTION_NAMES.CONTENT.SET_SUMMARY:
    case TEASER_ACTION_NAMES.CONTENT.SET_CTA_TITLE:
    case TEASER_ACTION_NAMES.CONTENT.SET_CTA_STEPS:
    case SET_META_DATA:
    case SET_META_TITLE:
    case SET_META_DESCRIPTION:
    case SET_NOFOLLOW:
    case SET_NOINDEX:
    case BREADCRUMB_ACTION_NAMES.CONTENT.DELETE_BREADCRUMBS:
    case BREADCRUMB_ACTION_NAMES.CONTENT.ADD_BREADCRUMB:
    case BREADCRUMB_ACTION_NAMES.CONTENT.DELETE_BREADCRUMB_TITLE:
    case BREADCRUMB_ACTION_NAMES.CONTENT.SET_BREADCRUMB_TITLE:
    case SET_CANONICAL:
    case LOCATION_ACTION_NAMES.CONTENT.ADD_LOCATION:
    case LOCATION_ACTION_NAMES.CONTENT.SET_LOCATIONS:
    case SET_TYPE:
    case EXPIRATION_ACTION_NAMES.CONTENT.SET_EXPIRATION_DATE:
    case SET_SHOW_DATE:
    case SET_SHOW_TOC:
      return {
        ...state,
        saved: false,
        [action.id] : page(state[action.id], action),
      };
    case SECTION_ACTION_NAMES.CONTENT.ADD_SECTION:
    case SECTION_ACTION_NAMES.CONTENT.REMOVE_SECTION:
    case SECTION_ACTION_NAMES.CONTENT.REORDER_SECTIONS:
    case SECTION_ACTION_NAMES.CONTENT.ADD_FOOTER_SECTION:
    case SECTION_ACTION_NAMES.CONTENT.REMOVE_FOOTER_SECTION:
    case SECTION_ACTION_NAMES.CONTENT.REORDER_FOOTER_SECTIONS:
    case PHOTO_ACTION_NAMES.CONTENT.SET_HEADER_PHOTO:
    case PHOTO_ACTION_NAMES.CONTENT.REMOVE_HEADER_PHOTO:
      return {
        ...state,
        saved: false,
        [action.entityId] : page(state[action.entityId], action)
      };
    case EXPIRATION_ACTION_NAMES.CONTENT.EXPIRATION_DATE_IS_VALID:
      return {
        ...state,
        disabledSaveForDate: action.value,
      };
    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch(action.type) {
    case ERROR_ACTION_NAMES.CONTENT.SET_TITLE_ERROR:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          title: action.error,
        }
      };
    case ERROR_ACTION_NAMES.CONTENT.CLEAR_TITLE_ERROR:
      if (state[action.id]) {
        const { title, ...remaining } = state[action.id];
        return {
          ...state,
          [action.id]: remaining,
        }
      }
      return state;
    default:
      return state;
  }
};

const simpleEditor = combineReducers({
  editor,
  sections: sectionsT('content'),
  photos: photosT('content'),
  errors,
});
export default simpleEditor;
