import {
  PUBLICATIONS_REQUEST,
  PUBLICATIONS_SUCCESS,
  PUBLICATIONS_ERROR,
  PUBLICATIONS_LOAD_MORE_SUCCESS,
  PUBLICATION_ERROR,
  PUBLICATION_REQUEST,
  PUBLICATION_SUCCESS,
  TOGGLE_READ_ONLY,
} from '../actions/publications';
import { PUBLISHED_CURRENT_SUCCESS, PUBLISHED_STATUS_SUCCESS } from '../actions/published';

const publication = (state = {
  isFetching: false,
  error: false,
}, action) => {
  switch(action.type) {
    case PUBLICATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        errors: {},
      };
    case PUBLICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        updateId: action.updateId,
        errors: action.errors,
        lastUpdated: action.receivedAt,
      };
    case PUBLICATION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case PUBLISHED_CURRENT_SUCCESS:
    case PUBLISHED_STATUS_SUCCESS:
      return {
        isFetching: false,
        error: false,
      };
    default:
      return state;
  }
};

const publications = (state = {
  isFetching: false,
  error: false,
  readOnly: false,
}, action) => {
  const { entityId, json, receivedAt, offset } = action;
  switch(action.type) {
    case PUBLICATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case PUBLICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        [entityId]: {
          items: [...json],
          offset,
          lastUpdated: receivedAt,
        }
      };
    case PUBLICATIONS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        [entityId]: {
          ...state[entityId],
          items: [...state[entityId].items, ...json],
          offset: offset,
          lastUpdated: receivedAt,
        },
      };
    case PUBLICATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case PUBLICATION_REQUEST:
    case PUBLICATION_SUCCESS:
    case PUBLICATION_ERROR:
      return {
        ...state,
        [entityId]: {
          ...state[entityId],
        publicationToPublish: publication(state[entityId].publicationToPublish, action),
        }
      };
    case TOGGLE_READ_ONLY:
      return {
        ...state,
        readOnly: !state.readOnly
      };
    default:
      return state;
  }
};

export default publications;
