import React from 'react';
import { Redirect } from 'react-router-dom';
import useLogout from '../../hooks/use-logout';

const Logout = ({ dispatch }) => {
  useLogout(dispatch);

  return <Redirect to="/login" />;
};

export default Logout;
