import ZApiClient from "../client/z-api-client";

const EntitiesClient = ZApiClient.getPublishableEntitiesClient();

/**
 * Used to create an s3 link to a csv of the specified entityType
 * @param {string} entityType 
 * @param {string} sort 
 * @returns Promise response {link: uri}
 */
const getDownloadableFile = (entityType, sort = 'newToOld') => {
  const params = {
    export: 'true',
    entity: entityType,
    sort,
  };
  return EntitiesClient.getEntities(params);
};

const downloadTextFile = (downloadExtension) => {
  if (typeof document !== 'undefined' && document) {
    let ele = document.createElement('a');
    ele.setAttribute('href', downloadExtension);
    ele.setAttribute('download', downloadExtension);
    ele.style.display = 'none';
    document.body.appendChild(ele);
    ele.click();
    document.body.removeChild(ele);
  }
};

export { downloadTextFile, getDownloadableFile };
