import { connect } from 'react-redux';
import {
  createPhotoT, updatePhotoT, removeHeaderPhotoT,
} from '../../../state/actions/shared/photo';
import ContentContainer from './content-container';
import {setSubtitleT, setSummaryT} from "../../../state/actions/shared/title";

const mapStateToProps = (state, ownProps) => {
  const { entityId } = ownProps;
  return {
    currentEntity: state.landingPageCategories.editor[entityId],
    photos: state.landingPageCategories.photos,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityId:id } = ownProps;
  return {
    onChangePhoto: (imageData) => dispatch(updatePhotoT('landing_page_category', imageData)),
    onCreateHeaderPhoto: (entityId, imageData) => dispatch(createPhotoT('landing_page_category', entityId, 'header', imageData)),
    onDeleteHeaderPhoto: (entityId, headerPhotoId) => dispatch(removeHeaderPhotoT('landing_page_category', entityId, headerPhotoId)),
    onSubtitleChange: (subtitle) => dispatch(setSubtitleT('landing_page_category', subtitle, id)),
    onSummaryChange: (text) => dispatch(setSummaryT('landing_page_category', text, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
