import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ShowMoreButton = ({
  expanded,
  onClick,
}) => (
  <Stack onClick={onClick} alignItems="center">
    <Typography variant="subtitle2">{expanded ? 'Show Less' : 'See Advanced'}</Typography>
    <ExpandMore
      sx={{padding: 0}}
      expand={expanded}
      aria-expanded={expanded}
      aria-label="show more"
    >
      <ExpandMoreIcon />
    </ExpandMore>
  </Stack>
);

const AdvancedAccordian = ({
  primaryInputs,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          "&.Mui-focusVisible" : {
            backgroundColor: 'transparent',
          }
        }}
      >
        <Stack direction="column" spacing={1} marginBottom={1} sx={{width: '100%', alignItems: 'center'}}>
          {primaryInputs}
          <ShowMoreButton
            expanded={expanded}
            onClick={(e) => setExpanded(!expanded)}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
};

const AdvancedSearchInputs = ({
  primaryInputs,
  children
}) => {

  if (!children) {
    return (primaryInputs);
  }

  return (
    <AdvancedAccordian
      primaryInputs={primaryInputs}
    >
      {children}
    </AdvancedAccordian>
  );
};

export default AdvancedSearchInputs;
