import React from 'react';
import LocationsView from './locations-view';
import ZApiClient from '../../../../utils/client/z-api-client';

const LocationsContainer = ({
  addLocation,
  locations,
  setLocations,
}) => {
  const locationAutoComplete = (text) => {
    return ZApiClient
    .getLocationsClient()
    .getNamesByFragment(text, { limit: 10 });
  }

  const onLocationSelect = (location) => {
    addLocation(location);
  }

  const onLocationDelete = (arr, x) => {
    const newLocations = arr.map((loc) => loc.label);
    setLocations(newLocations);
  }

  return (
    <LocationsView
      locationAutoComplete={locationAutoComplete}
      onLocationSelect={onLocationSelect}
      onLocationDelete={onLocationDelete}
      locations={locations}
    />
  )
}

export default LocationsContainer;
