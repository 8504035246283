import React from 'react';
import { connect } from 'react-redux';
import CountriesContainer from './countries-container';
import {addLocationT, setLocationsT} from "../../../../state/actions/shared/location";

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;
  const editorEntities = state.landingPages.landingPage;
  const { editor } = editorEntities;
  const { countries } = editor;
  const currentEntity = editor[id];
  const { locations } = currentEntity;
  return {
    countries,
    locations
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    onCountrySelect: (country) => dispatch(addLocationT('landing_page', country, id)),
    onCountryDelete: (countryList) => dispatch(setLocationsT('landing_page', countryList, id)),
  }
}

const CountriesRedux = (props) => (
  <CountriesContainer {...props} /> 
);

export default connect(mapStateToProps, mapDispatchToProps)(CountriesRedux);
