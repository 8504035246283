import { connect } from 'react-redux';
import Breadcrumbs from '../../../shared/components/breadcrumbs';
import {
  addBreadcrumbT,
  deleteBreadcrumbT,
  deleteBreadcrumbTitleT,
  setBreadcrumbTitleT
} from "../../../../state/actions/shared/breadcrumbs";

const BreadcrumbsRedux = (props) => (
  <Breadcrumbs
    entityType="itinerary"
    searchEntities={['landing_page']}
    {...props}
  />
);

const mapStateToProps = (state, ownProps) => {
  const { entityId } = ownProps;
  const pageEntities = state.itineraries.itinerary;
  const {editor} = pageEntities;
  const currentPage = editor[entityId]
  const { breadcrumb, breadcrumbTitle, title, updateId } = currentPage;
  return {
    breadcrumb,
    breadcrumbTitle,
    entityId,
    entityTitle: title,
    entityUpdateId: updateId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {entityId:id} = ownProps;
  return {
    onDeleteBreadcrumb: () => dispatch(deleteBreadcrumbT('itinerary', id)),
    onDeleteBreadcrumbTitle: () => dispatch(deleteBreadcrumbTitleT('itinerary', id)),
    onSelectBreadcrumb: (breadcrumb) => dispatch(addBreadcrumbT('itinerary', breadcrumb, id)),
    onSetBreadcrumbTitle: (breadcrumbTitle) => dispatch(setBreadcrumbTitleT('itinerary', breadcrumbTitle, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsRedux);
