import React, {useState, forwardRef} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import ZTitleFlag from '../../../z-title-flag';
import { Link, Stack, Typography, Box, Button, TextField } from '@mui/material';
import {
  closestCenter,
  DndContext, 
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import LinkEditor from './link-editor';
import config from '../../../../config';

const baseUrlWeb = config.Z3_URL;

const CountryCard = ({
  title,
  flagCountryCode,
  uri,
  hideButtons,
  onClickEdit,
}) => (
  <Card sx={{width: '200px', height: '104px', backgroundColor: '#FAFAFA'}}>
    <CardContent>
      <Typography sx={{maxHeight: '28px', overflow: 'hidden'}}>{title}</Typography>
      <Stack spacing={1} direction="row" alignItems="end">
        <ZTitleFlag
          isoCode={flagCountryCode}
          style={{ 
            marginRight: '16px',
            height: '2.5em',
            width: '2.5em',
          }}
        />
        <Link target="_blank" href={`${baseUrlWeb}${uri}`} color="secondary">Link</Link>
        { !hideButtons && (
          <IconButton 
            aria-label="Edit" 
            onClick={onClickEdit}
            type="button"
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
    </CardContent>
  </Card>
);

const GridItem = forwardRef(({children, ...props}, ref) => (
  <Grid item xs={2} ref={ref} {...props}>
    {children}
  </Grid>
));

const SortableItem = (props) => {
  const {
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <GridItem ref={setNodeRef} style={style} {...listeners} {...props}/>
  );
}

const HeaderLinksView = ({
  onLinkChange,
  onOpenNew,
  onLinkOrderChange,
  onClickEdit,
  onDeleteItem,
  showModal,
  toggleModal,
  onCancel,
  modalState,
  links,
  sectionTitle,
  onTitleChnage,
  onRemoveAll,
}) => {
  const [activeItem, setActiveItem] = useState({})
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
  );

  const handleDragStart = (event) => {
    const {active} = event;
    const item = links.find((e) => e.id === active.id);
    setActiveItem(item);
    setActiveId(active.id);
  }
  
  const handleDragEnd = (event) => {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      const oldIndex = links.findIndex((e) => e.id === active.id);
      const newIndex = links.findIndex((e) => e.id === over.id);
      const newArray = arrayMove(links, oldIndex, newIndex);
      onLinkOrderChange(newArray);
    }
    
    setActiveId(null);
    setActiveItem(null);
  }

  return (
    <div style={{marginBottom: '40px'}}>
      <Box sx={{marginBottom: '16px'}}>
        <Typography variant='h3'>Header Links</Typography>
        <Typography variant='subtitle2'>Country Links at the top of the LTL</Typography>
      </Box>
      {(Array.isArray(links) && !!links.length) && (
        <TextField
          fullWidth
          sx={{marginBottom: '16px', width: '400px'}}
          label="Edit Section Title"
          value={sectionTitle}
          onChange={onTitleChnage}
          variant='standard'
        />
      )}
      <Stack direction="row" spacing={2} sx={{marginBottom: '16px'}} alignItems="center">
        <Button 
          variant="contained"
          size="small"
          color="secondary"
          type="button"
          onClick={onOpenNew}
          disabled={links?.length === 18}
        >
          Add New Link
        </Button>
        {!!(links?.length === 18) && (
          <Typography variant='subtitle2' color="error">A Max of 18 Countries are allowed</Typography>
        )}
        {!!(links?.length) && (
          <Button 
            variant="contained"
            size="small"
            color="secondary"
            type="button"
            onClick={onRemoveAll}
          >
            Remove All
          </Button>
        )}
      </Stack>
      {(Array.isArray(links) && !!links.length) && (
        <Box sx={{marginBottom: '16px'}}>
          <DndContext
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <SortableContext 
              items={links}
            >
              <Grid container rowSpacing={1} columnSpacing={1}>
                {links.map((e) => (
                  <SortableItem key={e.id} id={e.id}>
                    <CountryCard
                      id={e.id}
                      title={e.title}
                      uri={e.uri}
                      flagCountryCode={e.flagCountryCode}
                      onClickEdit={() => onClickEdit(e.id)}
                    />
                  </SortableItem>
                ))}
              </Grid>
            </SortableContext>
            <DragOverlay>
              {activeId ? 
                <CountryCard hideButtons id={activeId} title={activeItem.title} uri={activeItem.uri} flagCountryCode={activeItem.flagCountryCode}/> 
                : null
              }
            </DragOverlay>
          </DndContext>
        </Box>
      )}
      {(Array.isArray(links) && !!links.length) && (
        <Typography variant='h4'>Drag and drop cards to customize the order of the items</Typography>
      )}
      <Modal
        open={showModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 14,
          p: 4,
        }}>
          <LinkEditor
            editorState={modalState}
            onLinkChange={onLinkChange}
            onClose={toggleModal}
            onCancel={onCancel}
            onDelete={onDeleteItem}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default HeaderLinksView;
