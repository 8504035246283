import React, { useEffect, useState } from 'react';
import CareersView from './careers-view';

const CareersContainer = ({
  isFetching,
  careers,
  onLoad,
  createNew,
  setCareer,
}) => {
  const [filteredCareers, setFilteredCareers] = useState();
  const [editCareerId, setEditCareerId] = useState();
  const [displayed, setDisplayed] = useState('all');

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    if (displayed === 'all') {
      setFilteredCareers(careers);
    } else {
      const filtered = careers.filter((career) => {
        const matchesDisplayed = displayed === 'all' || career.displayed === (displayed === 'yes');
        return matchesDisplayed;
      });
      setFilteredCareers(filtered);
    }
  }, [displayed, careers]);

  const onClickDisplayed = (e) => {
    const { value } = e.target;
    setDisplayed(value)
  };

  const onClickEdit = (career) => {
    setEditCareerId(career.id);
    setCareer(career);
  };

  const closeModal = () => {
    setEditCareerId();
  };

  const onClickCreateCareer = () => {
    setEditCareerId('new');
    createNew();
  }

  return (
    <CareersView
      closeModal={closeModal}
      displayed={displayed}
      editCareerId={editCareerId}
      isFetching={isFetching}
      careers={filteredCareers}
      onClickCreateCareer={onClickCreateCareer}
      onClickDisplayed={onClickDisplayed}
      onClickEdit={onClickEdit}
    />
  );
}

export default CareersContainer;
