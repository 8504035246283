import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import style from './z-input-radio.module.scss';

const ZInputRadioView = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function zInputRadio({
    checked,
    className,
    disabled,
    id,
    label,
    name,
    onChange,
    onClick,
    onFocus,
    inlineStyle,
    valid,
    value,
  }, ref) {
    return (
      <div className={`${style['radio']} ${className}`} style={inlineStyle}>
        <input
          checked={checked}
          className={style['radio-input']}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          ref={ref}
          type="radio"
          value={value}
        />
        <label
          className={`
            ${style['radio-label']}
            ${valid ? '' : style['radio-label__invalid']}
          `}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    );
  },
);
ZInputRadioView.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  style: PropTypes.object,
  valid: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};
ZInputRadioView.defaultProps = {
  disabled: false,
  onClick: () => {},
  onFocus: () => {},
  style: null,
};

export default ZInputRadioView;
