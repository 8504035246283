import React from 'react';
import { connect } from 'react-redux';
import VersionsContainer from './versions-container';
import {
  fetchLandingPageVersion,
  makeLatest,
  validateEntityVersion,
 } from '../../../state/actions/landing-page-editor';

const VersionsRedux = (props) => (
  <VersionsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {entityId:id} = ownProps;

  return{
    saved: state.landingPages.landingPage.editor.saved,
    published: state.published[id] && state.published[id].current,
  }
};

const mapDispatchToProps = (dispatch) => ({
  makeLatest: (id, version) => dispatch(makeLatest(id, version)),
  onFetchVersion: (id, version) => dispatch(fetchLandingPageVersion(id, version)),
  onValidateEntityVersion: (id, versionId) => dispatch(validateEntityVersion(id, versionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionsRedux);
