import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';

export const defaultSort = {label: 'Relevance'}

const sortOrders = [
  {
    label: 'New to Old',
    field: 'updatedAt',
    order: 'DESC',
  },
  {
    label: 'Old to New',
    field: 'updatedAt',
    order: 'ASC',
  },
  {
    label: 'Photo Count: Low to high',
    field: 'imageCount',
    order: 'ASC',
  },
  {
    label: 'Photo Count: High to low',
    field: 'imageCount',
    order: 'DESC',
  },
  {
    label: 'Word Count: Low to high',
    field: 'wordCount',
    order: 'ASC',
  },
  {
    label: 'Word Count: High to low',
    field: 'wordCount',
    order: 'DESC',
  },
  defaultSort,
];

export const publicationStatuses = [
  "PUBLISHED",
  "DRAFT",
  "UNPUBLISHED",
];


export const MultipleSelectDropdown = ({
  id,
  label,
  value,
  options = [],
  onChange,
}) => {

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
        <InputLabel id={`${label}-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-label`}
          id={id || label}
          multiple
          value={value}
          onChange={onChange}
          renderValue={(selected) => selected.join(', ')}
          label={label}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
               <Checkbox checked={value.indexOf(option) > -1} />
               <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


/**
 * Renders the input for sort options.
 * @param {*} props
 * @returns An autocomplete single selected dropdown.
 */
export const SortDropdown = ({
  value,
  onChange,
  ...rest
}) => {
  const defaultProps = {
    options: sortOrders,
  }

  return (
    <Autocomplete
      sx={{ m: 1, width: 300 }}
      {...defaultProps}
      {...rest}
      fullWidth
      id="sort-orders"
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Sort by" variant="standard" />
      )}
    />
  );
}

/**
 * Maps object keys to sort options.
 * Used for taking tag counts and getting autocomplete options.
 * @param {*} object 
 */
export const getOptionsFromKeys = (object = {}) => {
  const res = [];
  Object.keys(object).forEach((ele) => res.push(ele));
  return res;
};
