import React, { useEffect, useState } from 'react';
import styles from './oryx-multiselect.module.scss';
import ZInput from '../oryx-input/text';

const MultiSelectDropdown = ({
  onClick,
  label,
  checkedValues,
}) => (
  <div
    className={`${styles.multiselect__label}`}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyUp={
      (e) => { if (e.nativeEvent.code === 'Enter') onClick(); }
    }
  >
    <p>{label}</p>
    <div className={`${styles.multiselect__control}`}>
      <p>
        {checkedValues.join(', ')}
      </p>
    </div>
  </div>
);

const MultiSelectTypeahead = ({
  onChange,
  onFocus,
  onClickDropdown,
  value,
  label,
  checkedValues,
}) => (
  <div
    className={`
      ${styles.multiselect__label}
      ${styles.multiselect__typeahead}
    `}
  >
    <p>{label}</p>
    <ZInput
      autoComplete="off"
      name={`typeAhead-control-${label}`}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      placeholder={checkedValues.join(', ')}
      style={{ margin: '0 0 8px 0' }}
    />
    <div
      aria-label="Clear search"
      className={styles['multiselect__dropdown-button']}
      onClick={onClickDropdown}
      onKeyUp={(e) => { if (e.nativeEvent.code === 'Enter') onClickDropdown(); }}
      role="button"
      tabIndex={0}
    />
  </div>
);

const ZMultiSelectItem = ({
  displayValue,
  name,
  value,
  checked,
  onChange,
  type,
  group,
  onToggleGroup,
  activeGroups,
  index,
  className,
}) => {
  const hidden = (!type && group && activeGroups.includes(group));
  return (
    <div
      className={
        `${styles.multiselect__item} 
        ${group && styles['multiselect__item-grouped']} 
        ${type === 'GROUP_LABEL' && styles['multiselect__group-label']}
        ${hidden && styles.hidden}
        ${className}`
      }
    >
      { type === 'GROUP_LABEL' && (
        <span
          className={styles['multiselect__group-label-control']}
          // @ts-ignore
          data-group={value}
          role="button"
          tabIndex={0}
          onKeyUp={
            (e) => { if (e.nativeEvent.code === 'Enter') onToggleGroup(e); }
          }
          onClick={onToggleGroup}
        >
          {
            // @ts-ignore
            activeGroups.includes(value) ? '+' : '-'
          }
        </span>
      )}
      <input
        type="checkbox"
        id={`${value}--id${type ? `--${type}` : ''}`}
        name={name}
        // @ts-ignore
        value={value}
        // @ts-ignore
        data-group={group}
        // @ts-ignore
        data-type={type}
        // @ts-ignore
        data-index={index}
        onChange={onChange}
        checked={checked}
      />
      <label
        htmlFor={`${value}--id${type ? `--${type}` : ''}`}
      >
        {displayValue}
      </label>
    </div>
  );
};

const getSelectedNames = (arr) => (
  arr.reduce((acc, cur) => {
    if (cur.checked && !cur.type) {
      return [...acc, cur.name];
    }
    return acc;
  }, [])
);

const getResultGroups = (str, attribute = 'value', options) => {
  const groupMap = {};
  const activeGroups = new Set();
  const searchString = str.toLowerCase();
  for (let i = 0; i < options.length; i += 1) {
    const curOption = options[i];
    const curValue = curOption[attribute].toLowerCase();
    if (curOption.type === 'GROUP_LABEL') {
      groupMap[curOption.value] = [curOption];
    }
    if (!groupMap[curOption.group]) groupMap[curOption.group] = [];
    if (curOption.group && curValue.startsWith(searchString)) {
      activeGroups.add(curOption.group);
      groupMap[curOption.group].push(curOption);
    }
  }
  let results = [];
  activeGroups.forEach((ele) => {
    results = [...results, ...groupMap[ele]];
  });
  return results;
};

const ZMultiSelect = ({
  label,
  options,
  onChange,
  typeahead,
  className,
  itemClass,
  radio,
}) => {
  const [showDropdown, setDropdown] = useState(false);
  const [checkedValues, setCheckedValues] = useState(getSelectedNames(options));
  const [activeGroups, setActiveGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [resultOptions, setResultOptions] = useState([]);

  useEffect(() => {
    setCheckedValues(getSelectedNames(options));
  }, [options]);

  useEffect(() => {
    if (!searchTerm) setResultOptions([]);
    if (searchTerm) {
      const res = getResultGroups(searchTerm, typeahead, options);
      setResultOptions(res);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const toggleDropdown = () => (
    setDropdown((state) => !state)
  );

  const onItemChange = (e) => {
    const currentObj = {
      name: e.target.name,
      value: e.target.value,
      checked: e.target.checked,
    };
    const currentIndex = Number(e.target.getAttribute('data-index'));
    const retOptions = options.map((option, i) => {
      if(radio){
        if (i === currentIndex) {
          return { ...option, ...currentObj };
        }
        return {...option, checked: false}
      } else {
        if (i === currentIndex) {
          return { ...option, ...currentObj };
        }
        return { ...option };
      }
    });
    onChange(retOptions);
  };

  const onGroupChange = (e) => {
    const selectedGroup = e.target.value;
    const currentIndex = Number(e.target.getAttribute('data-index'));
    const currentObj = {
      name: e.target.name,
      value: e.target.value,
      checked: e.target.checked,
    };
    const { checked } = e.target;
    const retOptions = options.map((option, i) => {
      if (option.group === selectedGroup) {
        return { ...option, checked };
      }
      if (i === currentIndex) {
        return { ...option, ...currentObj };
      }
      return { ...option };
    });
    onChange(retOptions);
  };

  const onSelect = (e) => {
    if (e.target.getAttribute('data-type')) {
      onGroupChange(e);
    } else {
      onItemChange(e);
    }
  };

  const onTypeaheadSelect = (e) => {
    const currentIndex = Number(e.target.getAttribute('data-index'));
    if (e.target.getAttribute('data-type')) {
      const selectedGroup = e.target.value;
      const currentObj = {
        name: e.target.name,
        value: e.target.value,
        checked: e.target.checked,
      };
      const { checked } = e.target;
      const retOptions = resultOptions.map((option, i) => {
        if (option.group === selectedGroup) {
          return { ...option, checked };
        }
        if (i === currentIndex) {
          return { ...option, ...currentObj };
        }
        return { ...option };
      });
      setResultOptions(retOptions);
      onGroupChange(e);
    } else {
      const currentObj = {
        name: e.target.name,
        value: e.target.value,
        checked: e.target.checked,
      };
      const retOptions = resultOptions.map((option, i) => {
        if (i === currentIndex) {
          return { ...option, ...currentObj };
        }
        return { ...option };
      });
      setResultOptions(retOptions);
      onItemChange(e);
    }
  };

  const onToggleGroup = (e) => {
    const currentGroup = e.target.getAttribute('data-group');
    setActiveGroups((groups) => {
      const index = groups.indexOf(currentGroup);
      if (index === -1) {
        return [...groups, currentGroup];
      }
      return [...groups.slice(0, index), ...groups.slice(index + 1)];
    });
  };

  const onTypeaheadFocus = () => {
    setDropdown(true);
  };

  const onClickDropdown = () => {
    if (showDropdown && searchTerm) {
      setSearchTerm('');
      setResultOptions([]);
      setActiveGroups([]);
    }
    setDropdown((state) => !state);
  };

  return (
    <div className={`${styles.multiselect} ${className}`}>
      {typeahead && (
        <MultiSelectTypeahead
          onClickDropdown={onClickDropdown}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={onTypeaheadFocus}
          value={searchTerm}
          label={label}
          checkedValues={checkedValues}
        />
      )}
      {!typeahead && (
        <MultiSelectDropdown
          onClick={toggleDropdown}
          checkedValues={checkedValues}
          label={label}
        />
      )}
      <div
        className={`${styles.multiselect__dropdown}
          ${showDropdown
          ? styles['multiselect__dropdown--open']
          : styles['multiselect__dropdown--colapsed']}
        `}
      >
        { (!searchTerm && !resultOptions.length) && (options.map((option, i) => (
          <ZMultiSelectItem
            key={`${option.value}--${option.group ? 'group' : ''}-${option.type}`}
            displayValue={option.displayValue}
            checked={option.checked}
            name={option.name}
            value={option.value}
            type={option.type}
            group={option.group}
            index={i}
            activeGroups={activeGroups}
            onChange={onSelect}
            onToggleGroup={onToggleGroup}
            className={itemClass}
          />
        )))}
        { (searchTerm && resultOptions.length) && (resultOptions.map((option, i) => (
          <ZMultiSelectItem
            key={`${option.value}--${option.group ? 'group' : ''}-${option.type}`}
            displayValue={option.displayValue}
            checked={option.checked}
            name={option.name}
            value={option.value}
            type={option.type}
            group={option.group}
            index={i}
            activeGroups={activeGroups}
            onChange={onTypeaheadSelect}
            onToggleGroup={onToggleGroup}
            className={itemClass}
          />
        )))}
      </div>
    </div>
  );
};
ZMultiSelect.defaultProps = {
  typeahead: false,
  className: '',
};
export default ZMultiSelect;
