import {
    SET_SUB_TITLE,
    SET_SUMMARY,
    SET_TITLE
} from "./title-action-types";

export const setTitleT = (T, text, id) => ({
    type: SET_TITLE(T),
    value: text,
    id
});

export const setSubtitleT = (T, text, id) => ({
    type: SET_SUB_TITLE(T),
    value: text,
    id
});

export const setSummaryT = (T, text, id) => ({
    type: SET_SUMMARY(T),
    value: text,
    id
});
