import React, { memo } from 'react';
import style from './team-member.module.scss';

const TeamMemberView = memo(({
  onClick,
  openInEditor,
  name,
  title,
  image,
  description,
}) => (
  <div className={style['card']} onClick={onClick} aria-selected={openInEditor}>
    <div className={style['card__image']}>
      {image.uri && (
        <img
          alt={image.alt}
          src={image.uri}
          width={140}
        />
      )}
    </div>
    <p className={style['card__name']}>{name}</p>
    <h2 className={style['card__title']}>{title}</h2>
    <div className={style['card__description']} dangerouslySetInnerHTML={{ __html: description }} />
  </div>
));

export default TeamMemberView;
