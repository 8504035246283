import React from 'react';
import style from './travel-company.module.scss';
import ZAutoComplete from '../../../z-autocomplete';

/**
 *
 * @TOOD Resolve errors
 *  - company error
 */

const TravelCompanyView = ({
  tcLink,
  tcName,
  autoComplete,
  onDelete,
  onSelect,
}) => (
  <>
    <h3>Travel Company</h3>
    <div className={style['company']}>
      {tcName &&
        <>
          <p>
            Travel Company:&nbsp;
            <a
              href={tcLink}
              rel="noreferrer"
              target="_blank"
            >
              {tcName}
            </a>
          </p>
          <button
            type='button'
            onClick={onDelete}
            className={style['button']}
          >
            Clear
          </button>
        </>
      }
    </div>
    <ZAutoComplete
      label={'Search active travel companies'}
      autoComplete={autoComplete}
      onSelect={onSelect}
      returnsObject
      // error={props.errors.company ? props.errors.company : undefined}
      style={{marginBottom: '28px'}}
      inputKey={'admin__company'}
    />
  </>
);

export default TravelCompanyView;
