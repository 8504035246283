import React, { forwardRef } from 'react';
import styles from './text.module.scss';

const defaultProps = {
  autoComplete: 'on',
  className: '',
  disabled: false,
  placeholder: '',
  spellCheck: true,
  style: {},
  tabIndex: 0,
  valid: true,
  onFocus: () => {},
  onBlur: () => {},
};

const ZInputTextView = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function zInputText({
    autoComplete,
    className,
    disabled,
    name,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    spellCheck,
    style,
    tabIndex = 0,
    valid,
    value,
  }, ref) {
    return (
      <input
        aria-label={name}
        autoComplete={autoComplete}
        className={`
          ${styles.input}
          ${className}
          ${valid ? '' : styles['input-invalid']}
        `}
        disabled={disabled}
        id={name}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        ref={ref}
        spellCheck={spellCheck}
        style={style}
        tabIndex={tabIndex}
        type="text"
        value={value}
      />
    );
  },
);

ZInputTextView.defaultProps = defaultProps;

export default ZInputTextView;
