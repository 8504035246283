import React from 'react';
import Dates from './dates';
import Price from './price';

const DatesPricView = ({
  onUpdateDeparture,
  departureNotesError,
  departureNotes,
  startingPrice,
  priceComments,
  includedNotes,
  showPrice,
  priceCommentsErrors,
  includedNotesErrors,
  onUpdateStatingPrice,
  onUpdatePriceComments,
  onUpdateIncludedNotes,
  onChangeShowPrice,
}) => (
  <>
    <br/>
    <Dates
      onUpdateDeparture={onUpdateDeparture}
      departureNotesError={departureNotesError}
      departureNotes={departureNotes}
    />
    <br/>
    <Price
      startingPrice={startingPrice}
      priceComments={priceComments}
      includedNotes={includedNotes}
      priceCommentsErrors={priceCommentsErrors}
      includedNotesErrors={includedNotesErrors}
      onUpdateStatingPrice={onUpdateStatingPrice}
      onUpdatePriceComments={onUpdatePriceComments}
      onUpdateIncludedNotes={onUpdateIncludedNotes}
      showPrice={showPrice}
      onChangeShowPrice={onChangeShowPrice}
    />
  </>
);

export default DatesPricView;
