import React, {useEffect, useRef, useState} from 'react';
import HelpfulContentView from './helpful-content-view';
import { decodeHTMLEntities, navigate } from '../../../utils/window';
import Config from '../../../config';
import ZApiClient from '../../../utils/client/z-api-client';
import getLink from '../../../utils/fetch/links';
const baseUrlWeb = Config.Z3_URL;
const SNIPPET_LIMIT = 10;

const getOrCreateSnippet = async (snippets, navLink) => {
  const snippet = snippets.find((ele) => ele.id === navLink.entityId);
  return snippet ? snippet : await getLink(navLink.entityId, navLink.entityType);
}

const HelpfulContentContainer = ({
  snippets,
  snippetCount,
  contentSectionTitle,
  updateSnippets,
  updateSnippetSectionTitle,
  errors,
  readOnly,
  subnavLinks,
}) => {
  const snippetRef = useRef(snippets);
  const resultRef = useRef({});
  const [navBarSnippets, setNavBarSnippets] = useState([]);
  const [customSnippets, setCustomSnippets] = useState([]);

  // Add the subnav snippets to the custom snippets
  const getNavSnippets = async (snippets, navLinks) => {
    const navSnippets = navLinks.reduce((acc, ele) => {
      const valid = ele.entityType === 'Article' || ele.entityType === 'Guide';
      return valid ? [...acc, ele] : acc;
    },[]);
    const custom = snippets.reduce((acc, snippet) => {
      const exists = navSnippets.find(ele => ele.entityId === snippet.entityId);
      return !exists ? [...acc, snippet] : acc;
    },[]);
    const ret = await Promise.all(navSnippets.map(ele => getOrCreateSnippet(snippets, ele)));
    setCustomSnippets(custom);
    setNavBarSnippets(ret);

    return [...ret, ...custom];
  }

  // Generate the subnav snippets if they don't already exist
  const generateNavSnippets = async () => {
    const newSnippets = await getNavSnippets(snippets, subnavLinks);
    updateSnippets(newSnippets);
  };
  
  useEffect(() => {
    snippetRef.current = snippets;
  }, [snippets]);

  // Renders the subnav snippets in helpful content editor if they already exist 
  useEffect(() => {
    if (subnavLinks && snippetCount > 0) getNavSnippets(snippets, subnavLinks);
  }, [snippetCount, snippets, subnavLinks]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    let items = reorder(
      customSnippets,
      result.oldIndex,
      result.newIndex
    );
    updateSnippets([...navBarSnippets, ...items]);
  }

  const onUpdate = (snippet, index) => {
    let clone = [...customSnippets];
    clone[index] = snippet;
    updateSnippets([...navBarSnippets, ...clone]);
  }

  const onChangePosition = (oldIndex, newIndex) => {    
    let clone = [...customSnippets];
    let element = clone[oldIndex];
    clone.splice(oldIndex, 1);
    clone.splice(newIndex, 0, element);
    updateSnippets([...navBarSnippets, ...clone]);
  }

  const removeSnippet = (index) => {
    if (window && window.confirm('Are you sure you want to remove this section?')) {
      let clone = [...customSnippets];
      clone.splice(index, 1);
      updateSnippets([...navBarSnippets, ...clone]);
    }
  }

  const getArticles = async (text) => {
    const results = await ZApiClient
                          .getArticlesClient()
                          .getAllTitlesWithIdsByNameFragment(text, { limit: 10, published: true });
    const filteredRes = Object.keys(results).filter(key => {
      for (let i = 0; i < snippetRef.current.length; i++) {
        if ( snippetRef.current[i].id === key ) {
          return false;
        }
      }
      return key;}
    ).reduce((obj, key) => {
      obj.results[key] = decodeHTMLEntities(results[key]);
      obj.ids[key] = 'Article';
      return obj;
    }, {
      results: {},
      ids: {},
    });
    resultRef.current = {...resultRef.current, ...filteredRes.ids}
    return filteredRes.results;
  }

  const getGuides = async (text) => {
    const results = await ZApiClient
                          .getGuidesClient()
                          .getAllTitlesWithIdsByNameFragment(text, { limit: 10, published: true });
    const filteredRes = Object.keys(results).filter(key => {
      for (let i = 0; i < snippetRef.current.length; i++) {
        if ( snippetRef.current[i].id === key ) {
          return false;
        }
      }
      return key;}
    ).reduce((obj, key) => {
      obj.results[key] = decodeHTMLEntities(results[key]);
      obj.ids[key] = 'Guide';
      return obj;
    }, {
      results: {},
      ids: {},
    });
    resultRef.current = {...resultRef.current, ...filteredRes.ids}
    return filteredRes.results;
  }

  const autoComplete = async (text) => {
    const [articleResuls, guideResults] = await Promise.all([getArticles(text), getGuides(text)]);
    const res = {
      ...articleResuls,
      ...guideResults,
    }
    return res;
  }

  const autoCompleteOnSelect = (val) => {
    if (Object.keys(val ? val : {}).length > 0) {
      const [id,] = Object.keys(val);
      getLink(id, resultRef.current[id])
      .then((res)=> {
        let clone = [...snippetRef.current];
        clone.push(res);
        updateSnippets(clone);
      });
    }
  }

  const onClickPreview = (id, entity) => {
    switch (entity) {
      case "Article":
      case "article":
      case "ArticleSnippet":
        window.open(`${baseUrlWeb}/dynamic?type=Article&id=${id}`);
        break;
      case "Guide":
      case "guide":
      case "GuideSnippet":
        window.open(`${baseUrlWeb}/dynamic?type=Guide&id=${id}`);
        break;
      default:
        break;
    }
  }

  const onClickEdit = (id, entity) => {
    switch (entity) {
      case "Article":
      case "article":
      case "ArticleSnippet":
        navigate(`articles/${id}`);
        break;
      case "Guide":
      case "guide":
      case "GuideSnippet":
        navigate(`guides/${id}`);
        break;
      default:
        break;
    }
  }

  const removeAllSnippets = () => {
    if (window && window.confirm('Are you sure you want to remove all helpful content?')) {
      updateSnippets([]);
      setCustomSnippets([]);
      setNavBarSnippets([]);
    }
  }

  return (
    <HelpfulContentView
      navBarSnippets={navBarSnippets}
      autoComplete={autoComplete}
      autoCompleteOnSelect={autoCompleteOnSelect}
      snippets={customSnippets} 
      contentSectionTitle={contentSectionTitle}
      onUpdate={onUpdate}
      onUpdateTitle={updateSnippetSectionTitle}
      onChangePosition={onChangePosition}
      onDragEnd={onDragEnd}
      removeSnippet={removeSnippet}
      errors={errors}
      snippetCount={snippetCount}
      snippetLimit={SNIPPET_LIMIT}
      onClickEdit={onClickEdit}
      onClickPreview={onClickPreview}
      readOnly={readOnly}
      removeAllSnippets={removeAllSnippets}
      generateNavSnippets={generateNavSnippets}
    />
  );
}

export default HelpfulContentContainer;
