import React, {useEffect, useRef} from 'react';
import ItinerariesView from './itineraries-view';
import { decodeHTMLEntities, navigate } from '../../../utils/window';
import Config from '../../../config';
import ZApiClient from '../../../utils/client/z-api-client';
const baseUrlWeb = Config.Z3_URL;

const ItinerariesContainer = ({
  itineraries,
  itineraryCount,
  updateItineraries,
  errors,
  readOnly,
}) => {
  const itinerariesRef = useRef(itineraries);

  useEffect(()=> {
    itinerariesRef.current = itineraries;
  }, [itineraries]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    let items = reorder(
      itineraries,
      result.oldIndex,
      result.newIndex
    );
    updateItineraries([...items]);
  }

  const onUpdate = (itinerary, index) => {
    let clone = [...itineraries];
    clone[index] = itinerary;
    updateItineraries(clone);
  }

  const onChangePosition = (oldIndex, newIndex) => {
    let clone = [...itineraries];
    let element = clone[oldIndex];
    clone.splice(oldIndex, 1);
    clone.splice(newIndex, 0, element);
    updateItineraries(clone);
  }

  const removeItinerary = (itineraryIndex) => e => {
    if (window && window.confirm('Are you sure you want to remove this itinerary?')) {
      let clone = [...itineraries];
      clone.splice(itineraryIndex, 1);
      updateItineraries(clone);
    }
  }

  const autoComplete = (text) => {
    return ZApiClient
      .getItinerariesClient()
      .getAllTitlesWithIdsByNameFragment(text, { limit: 10, published: true })
        .then((res)=> Object.keys(res).filter(key => {
          for (let i = 0; i < itinerariesRef.current.length; i++) {
            if ( itinerariesRef.current[i].id === key ) {
              return false;
            }
          }
          return key;}
        ).reduce((obj, key) => {
          obj[key] = decodeHTMLEntities(res[key]);
          return obj;
        }, {}));
  }

  const autoCompleteOnSelect = (val) => {
    if (Object.keys(val ? val : {}).length > 0) {
      ZApiClient
      .getItinerariesClient()
      .getSnippet(Object.keys(val)[0])
        .then((res)=> {
          let clone = [...itinerariesRef.current];
          clone.unshift(res);
          updateItineraries(clone);
        });
    }
  }

  const onClickPreview = (id) => () => {
    window.open(`${baseUrlWeb}/dynamic?type=Itinerary&id=${id}`);
  }

  const onClickEdit = (id) => () => {
    navigate(`itineraries/${id}`);
  }

  return (
    <ItinerariesView
      autoComplete={autoComplete}
      autoCompleteOnSelect={autoCompleteOnSelect}
      itineraries={itineraries}
      onUpdate={onUpdate}
      onChangePosition={onChangePosition}
      onDragEnd={onDragEnd}
      removeItinerary={removeItinerary}
      errors={errors}
      itineraryCount={itineraryCount}
      onClickEdit={onClickEdit}
      onClickPreview={onClickPreview}
      readOnly={readOnly}
    />
  );
}

export default ItinerariesContainer;
