import React from 'react';
import PropTypes from 'prop-types';
import styles from './reviews-page.module.scss';
import ReviewsList from './reviews-list';
import AverageStars from './average-large';
import ReviewsSort from './reviews-sort';
import Histogram from './histogram';
import SelectedRating from './selected-rating';
import ShowMoreButton from './show-more-button';
import ZButton from '../z-button/z-button-view';

const ReviewsPageView = ({
  setSelectedStarLevel,
  changeSortOrder,
  clearRating,
  filteredSearches,
  loadMoreReviews,
  offset,
  reviewSearch = {
    reviews: [],
    ratingsAggregation: [],
  },
  selectedStarLevel,
  showShowMoreButton,
  sortOrder,
  initalSortValue,
  onClickRefresh,
}) => (
    <div className={styles['review__outer-wrapper']}>
      <div className={styles['review__inner-wrapper']}>
        <div className={styles['review__left']}>
          {reviewSearch && (
            <>
              <h1>Related Reviews</h1>
              <div className={styles['review__header']}>
                <div className={styles['review__header--left']}>
                  <ZButton
                    btnStyle={{ marginLeft: 'auto'}}
                    text='Refresh'
                    onClick={onClickRefresh}
                  />
                </div>
                <AverageStars
                  averageRating={reviewSearch?.averageRating}
                  reviewCount={reviewSearch?.reviewCount}
                />
                <Histogram
                  ratingsAggregation={reviewSearch?.ratingsAggregation}
                  reviewCount={Object.values(reviewSearch?.ratingsAggregation).reduce((a, b) => a + b)}
                  setSelectedStarLevel={setSelectedStarLevel}
                />
              </div>
              <div className={styles['review__sort--wrapper']}>
                <ReviewsSort
                  key={sortOrder}
                  initalValue={initalSortValue}
                  changeSortOrder={changeSortOrder}
                />
                <div>
                  {selectedStarLevel && (
                    <SelectedRating
                      clearSelectedRating={clearRating}
                      selectedRating={selectedStarLevel}
                    />
                  )}
                </div>
              </div>
              <div>
                {!(reviewSearch.loading && offset === 0) && (
                  <ReviewsList
                    reviews={
                      (reviewSearch.postSearchRatingFilter
                        && filteredSearches[reviewSearch.postSearchRatingFilter]?.reviews)
                      || reviewSearch.reviews
                    }
                  />
                )}
                { showShowMoreButton && (
                  <ShowMoreButton
                    loadMoreReviews={loadMoreReviews}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
);
ReviewsPageView.propTypes = {
  changeSortOrder: PropTypes.func.isRequired,
  clearRating: PropTypes.func.isRequired,
  filteredSearches: PropTypes.object.isRequired,
  loadMoreReviews: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  reviewSearch: PropTypes.object.isRequired,
  selectedStarLevel: PropTypes.number,
  showShowMoreButton: PropTypes.bool.isRequired,
};
ReviewsPageView.defaultProps = {
  selectedStarLevel: null,
};

export default ReviewsPageView;
