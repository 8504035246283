import React from 'react';
import LandingPageView from '../../../components/landing-pages';

const LandingPages = (props) => {
  return(
    <LandingPageView {...props}></LandingPageView>
  );
}

export default LandingPages;
