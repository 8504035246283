import React from 'react';
import ZInput from '../z-input';
import ZPages from '../z-pages';
import ZPage from '../z-page';
import ZToast from '../z-toast';
import FloatingSave from '../shared/components/floating-save/floating-save-view';
import Content from './content';
import CustomItinerary from './custom-itinerary';
import DatesPrice from './date-price';
import Details from './details';
import UsageReferences from '../usage-references';
import Optimization from './optimization';
import Publications from './publications';
import Versions from './versions';
import PublishedStatus from '../shared/components/published-status';
import style from '../shared/styles/page.module.scss';
import sharedStyle from '../shared/styles/shared.module.scss';
import { decodeHTMLEntities } from '../../utils/window';
import Config from '../../config';

const baseUrlWeb = `${Config.Z3_URL}`;

const ItineraryView = ({
  btnText,
  disabledMessage,
  id,
  isValid,
  onSave,
  onTitleChange,
  onToastClose,
  openToast,
  readOnly,
  saved,
  saveDisabled,
  title,
  titleErrors,
  saveBtnDisabled,
  updateId,
}) =>  (
  <>
    <div className={style['itinerary-wrapper']}>
      <div className={style['itinerary']}>
        <h1>
          <ZInput
            label='Title'
            onChange={onTitleChange}
            text={decodeHTMLEntities(title)}
            style={{margin: '14px 28px', fontSize: '22px'}}
            inputKey={'itinerary__title'}
            error={titleErrors && !titleErrors.valid ?
              titleErrors :
              false
            }
          />
        </h1>
        <div className={style['itinerary__header']}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <PublishedStatus entityId={id} entityUpdateId={updateId} />
            <h2>{ saved ? 'Saved' : 'Not Saved' }</h2>
          </div>
          {readOnly && <h3 className={sharedStyle['read-only']}>Read Only View</h3>}
          {!isValid &&
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
              <p style={{margin: '14px 0 0 0'}} className={sharedStyle['error']}>
                Itineray is not able to be published
              </p>
              <p style={{margin: '14px 0 0 0'}} className={sharedStyle['error']}>
                To published fix the highlighted errors
              </p>
            </div>
          }
          <div className={style['preview-wrapper']}>
            <a
              className={`${style['preview-button']} ${sharedStyle['button']}`}
              href={`${baseUrlWeb}/dynamic?type=Itinerary&id=${id}`}
              target='_blank'
              rel="noopener noreferrer"
            >
              Preview
            </a>
          </div>
        </div>
        <ZPages readOnly={readOnly}>
          <ZPage tabName='Creation' key={`${1}-Create`}>
            <Content
              entityId={id}
            />
          </ZPage>
          <ZPage tabName='Customizable Itinerary' key={`${2}-CustomizableItinerary`}>
            <CustomItinerary
              entityId={id}
            />
          </ZPage>
          <ZPage tabName='Highlights' key={`${3}-Details`}>
            <Details
              entityId={id}
            />
          </ZPage>
          <ZPage tabName='Dates & Pricing' key={`${3}-dates-price`}>
            <DatesPrice
              entityId={id}
            />
          </ZPage>
          <ZPage tabName='Optimization' key={`${7}-Optimization`}>
            <Optimization
              entityId={id}
              title={title}
            />
          </ZPage>
          <ZPage tabName='Usage References' key={`${6}-UsageReferences`}>
            <UsageReferences
              entityId={id}
            />
          </ZPage>
          <ZPage tabName='Versions' key={`${8}-Versions`}>
            <Versions
              entityId={id}
              onSave={onSave}
            />
          </ZPage>
          <ZPage tabName='Publications' key={`${9}-Publications`}>
            <Publications />
          </ZPage>
        </ZPages>
        {!title &&
          <p className={[`${sharedStyle.error} ${style.itinerary__save}`]}>
            A title is required to save.
          </p>
        }
        { disabledMessage &&
          <p className={style[`itinerary__save`]}>
            {disabledMessage}
          </p>
        }
      </div>
    </div>
    <ZToast
      open={openToast}
      onClose={onToastClose}
      message={'Itinerary Saved!'}
    />
    <FloatingSave
      onClick={onSave}
      disabled={!title || saveDisabled || saveBtnDisabled}
      text={btnText}
    />
  </>
);

export default ItineraryView;
