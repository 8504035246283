import React from 'react';
import { connect } from 'react-redux';
import VersionsContainer from './versions-container';
import { fetchGuideVersion, makeLatest } from '../../../state/actions/guide-editor';

const VersionsRedux = (props) => (
  <VersionsContainer {...props}/>
);

const mapStateToProps = (state, ownProps) => {
  const {guideId:id} = ownProps;
  const pathMap = state.guides.guide.paths || {};
  const currentPaths = pathMap[id] || [];
  return {
    paths: currentPaths,
    saved: state.guides.guide.editor.saved,
    published: state.published[id] && state.published[id].current,
  }
};

const mapDispatchToProps = (dispatch) => ({
  makeLatest: (id, version) => dispatch(makeLatest(id, version)),
  onFetchVersion: (id, version) => dispatch(fetchGuideVersion(id, version)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionsRedux);
